import React, { useEffect, useState } from 'react';
import styles from './SepiaMainComponent.module.scss';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';

interface SepiaMainComponentProps {
  SepiaMainComponentTypographyLogo?: string;
  SepiaMainComponentCoupleName?: string;
  SepiaMainComponentMainImage?: string;
}

const defaultValues = {
  typographyLogo: '/templates/sepia/mainComponentTypographyLogo.svg',
  mainImage: '/templates/sepia/mainComponentMainImage.png',
  coupleName: undefined,
};

const SepiaMainComponent = ({
  SepiaMainComponentTypographyLogo,
  SepiaMainComponentCoupleName,
  SepiaMainComponentMainImage,
}: SepiaMainComponentProps): JSX.Element => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [blob, setBlob] = useState('');

  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob(result);
  };

  useEffect(() => {
    if (SepiaMainComponentMainImage) {
      fetchPostImage(SepiaMainComponentMainImage);
    }
  }, [SepiaMainComponentMainImage]);

  return (
    <section
      id={`SepiaMainComponent`}
      className={styles.sepiaMainComponent}
      style={{
        width: '100%',
        backgroundImage: `url("${blob ? blob : defaultValues.mainImage}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          // background: 'red',
          background: '#00000030',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        {SepiaMainComponentCoupleName ? (
          <h4 className={styles.typographyLogo}>{SepiaMainComponentCoupleName}</h4>
        ) : (
          <img
            src={SepiaMainComponentTypographyLogo ? SepiaMainComponentTypographyLogo : defaultValues.typographyLogo}
            className={styles.typographyLogo}
            alt="typography logo"
          />
        )}
      </div>
    </section>
  );
};
export default SepiaMainComponent;
