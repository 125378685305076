import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { selectRsvpEvents, selectWedding, setRsvpEventsReducer } from '../../../app/redux/wedding.slice.recuder';
import { useDispatch, useSelector } from 'react-redux';
import { selectJwt, selectProfile, user } from '../../../app/redux/authorization.slice.reducer';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { IonContent, IonHeader, IonImg, IonPage } from '@ionic/react';
import styles from './Rsvp.module.scss';
import jwt_decode from 'jwt-decode';
import {
  AllowedVisitor,
  Answer,
  ConnectedAccountRef,
  CreateOrUpdateAttendanceForAllowedVisitorRequest,
  GetInvitedVisitorByPhoneOrEmailResponse,
  ListQuestionsResponse,
  RSVPEvent,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { EventQuestions } from './EventQuestions';
import { Avatar } from '../../../component/dashboard/table/Table';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import GenericConfirmModal from '../../../component/modals/GenericConfirmModal';
import Lottie from 'react-lottie';
import location from '../../../loader-animation.json';
import { logger } from '../../../config/Logger';
import Icon from '../../../shared/Icon';
import GetUI from '../../../utils/uiContent';
import { EventType } from '../../../utils/uiContent';
import Image from '../../../shared/Image/Image';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const RsvpMain = (): JSX.Element => {
  //GET WEDDING IMAGE
  const wedding = useSelector(selectWedding);
  const rsvpEvents = useSelector(selectRsvpEvents);
  const userToken = useSelector(selectJwt);
  const profile = useSelector(selectProfile);
  const [eventsState, setEventsState] = useState<RSVPEvent[]>([]);
  const [fileId, setFileId] = useState<string | undefined>('');
  const [blobId, setBlobId] = useState<string>('');
  const [daysToWedding, setDaysToWedding] = useState(0);
  const [invited, setInvited] = useState<GetInvitedVisitorByPhoneOrEmailResponse | undefined>(undefined);
  const [questionsList, setQuestionsList] = useState<Map<string, ListQuestionsResponse | undefined>>(new Map());
  const [answers, setAnswers] = useState<Map<string, Answer[] | undefined>>(new Map());
  const [attend, setAttend] = useState<Map<string, 0 | 1 | 2>>(new Map());
  const [plus1, setPlus1] = useState<Map<string, number>>(new Map());
  const [isHover, setIsHover] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [completed, setCompleted] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [completedStyle, setCompletedStyle] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (wedding?.mainEvent?.icon) setFileId(wedding.mainEvent.icon);
    if (wedding?.mainEvent?.startTimestamp)
      setDaysToWedding(
        Math.ceil(
          (new Date(wedding?.mainEvent.startTimestamp).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
        ),
      );
    getRsvpEvents();
    getInvitedVisitor();
  }, []);

  useEffect(() => {
    const tempEventsArray: RSVPEvent[] = JSON.parse(
      JSON.stringify(rsvpEvents, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
    );
    const sorted = tempEventsArray.sort(
      (a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime(),
    );
    setEventsState(sorted);
  }, [rsvpEvents]);

  useEffect(() => {
    if (fileId !== '' && fileId !== undefined) fetchPostImage(fileId);
  }, [fileId]);

  // get image
  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlobId(result);
  };

  const getRsvpEvents = async () => {
    const rsvpEvents = await BrainHelper.listRSVPEvents(userToken, wedding.id);
    dispatch(setRsvpEventsReducer(rsvpEvents?.rsvpEvents));
    const temp: Map<string, ListQuestionsResponse | undefined> = new Map<string, ListQuestionsResponse | undefined>(
      questionsList,
    );
    if (rsvpEvents)
      for (const e of rsvpEvents.rsvpEvents) {
        const res = await getQuestions(e);
        temp.set(e.id, res);
      }
    setQuestionsList(temp);
  };

  const getInvitedVisitor = async () => {
    const jwtDecode: user = jwt_decode(userToken);
    const invitedVisitor = await BrainHelper.getInvitedVisitorByPhoneOrEmail(
      userToken,
      wedding.id,
      jwtDecode?.phoneNumber,
      jwtDecode?.email ? jwtDecode.email : '',
    );
    const attendTemp: Map<string, 0 | 1 | 2> = new Map<string, 0 | 1 | 2>(attend);
    const plus1Temp: Map<string, number> = new Map<string, number>(plus1);
    invitedVisitor?.allowedVisitors[0]?.attendToRsvpEvents.forEach((i) => {
      if (i.rsvpEvent?.id) {
        attendTemp.set(i.rsvpEvent?.id, i.attend ? 1 : 2);
        plus1Temp.set(i.rsvpEvent?.id, Number(i.plusOnes));
      }
    });
    setAttend(attendTemp);
    setPlus1(plus1Temp);
    setInvited(invitedVisitor);
  };

  const getQuestions = async (event: RSVPEvent) => {
    return await BrainHelper.listQuestions(userToken, event.id);
  };

  const completeRSVP = async () => {
    setCompleted(true);
    setCompletedStyle({
      height: 0,
      top: (formRef?.current?.offsetTop || 0) - 5,
      left: formRef?.current?.offsetLeft || 0,
      width: formRef?.current?.offsetWidth || 0,
    });
    try {
      const allowedVisitor: AllowedVisitor = JSON.parse(
        JSON.stringify(invited?.allowedVisitors[0], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      for (const e of rsvpEvents) {
        if (attend.get(e.id) != 0 && attend.get(e.id) != undefined) {
          const attendRequest = new CreateOrUpdateAttendanceForAllowedVisitorRequest();
          attendRequest.id =
            allowedVisitor.attendToRsvpEvents.find(
              (a) => a.allowedVisitor?.id === allowedVisitor.id && a.rsvpEvent?.id === e.id,
            )?.id || '';
          attendRequest.allowedVisitorId = allowedVisitor.id;
          attendRequest.rsvpEventId = e.id;
          attendRequest.responded = true;
          attendRequest.attend = attend.get(e.id) == 1;
          attendRequest.allowedPlusOnes = (plus1.get(e.id) || 0) as unknown as bigint;
          await BrainHelper.createOrUpdateAttendanceForAllowedVisitor(userToken, attendRequest);
        }
      }
      answers.forEach(async (a) => {
        if (a)
          for (const answer of a) {
            if (invited && invited?.allowedVisitors[0]?.id)
              if (answer?.id)
                await BrainHelper.updateAnswer(
                  userToken,
                  answer?.id,
                  wedding.id,
                  invited?.allowedVisitors[0]?.id,
                  answer?.questionId,
                  answer?.freeText,
                  answer?.singleOptionChoices,
                );
              else
                await BrainHelper.createAnswer(
                  userToken,
                  invited?.allowedVisitors[0]?.id,
                  wedding.id,
                  answer?.questionId,
                  answer?.freeText,
                  answer?.singleOptionChoices,
                );
          }
      });
      toast('RSVP updated successfully!', {
        style: { backgroundColor: '#A3F5E6' },
      });
      setTimeout(() => {
        setCompleted(false);
        navigate('/main');
      }, 5000);
    } catch (e) {
      toast('Something went wrong. Try again later!', {
        icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
        style: { backgroundColor: '#F8AAB8' },
      } as ToastOptions);
      setTimeout(() => {
        setCompleted(false);
      }, 5000);
      logger.error({ message: e, functionName: 'Rsvp.completeRSVP' });
    }
  };

  return (
    <IonPage>
      <IonHeader className="header">
        <img loading="lazy" className="headerLogo" src="/assets/logos/whiteclover-logo1.svg" alt="logo" />
        <button onClick={() => navigate('/profile')}>
          <Avatar
            query={''}
            name={`${profile.accountFirstName} ${profile.accountLastName}`}
            userToken={userToken}
            connectedAccountDetails={
              new ConnectedAccountRef({
                accountFirstName: profile.accountFirstName,
                accountLastName: profile.accountLastName,
                imageProfileUrl: profile.imageProfileUrl,
              })
            }
          />
        </button>
      </IonHeader>
      <IonContent>
        <div className={styles.Main} ref={formRef}>
          <div className={styles.imgWrapper}>
            <Image src={blobId} alt={'Social Event'} />
            <h1 className={'fridayH1'}>{wedding.name}</h1>
            <div>
              <img src={GetUI(wedding.socialEventType as EventType).icon} alt="ceremony" width={32} />
              <p>{daysToWedding} Days to go</p>
            </div>
          </div>
          <div>
            <div className={styles.eventsWrapper} style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
              {eventsState.map((e, key) => {
                const index = invited?.allowedVisitors[0]?.invitedToRsvpEvents.findIndex((i) => i.id === e.id);
                return (index !== undefined && index > -1) || !e.isPrivate ? (
                  <EventQuestions
                    key={key}
                    e={e}
                    questionsList={questionsList}
                    setQuestionsList={setQuestionsList}
                    setAttend={setAttend}
                    setPlus1={setPlus1}
                    plus1={plus1}
                    attend={attend}
                    invited={invited?.allowedVisitors[0]}
                    answers={answers}
                    setAnswers={setAnswers}
                    isExperienceApp={true}
                    hasHeader={true}
                    questionsOnly={true}
                    isDropdown={true}
                  />
                ) : (
                  ''
                );
              })}
              <button
                className={'btn btnMain btnMainWithImg'}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={async () => {
                  let questionsCount = 0;
                  questionsList.forEach((value, key) => {
                    questionsCount = questionsCount + (value?.questions.length || 0);
                  });
                  if (attend.size < questionsList.size || answers.size < questionsCount) setIsCompleteModalOpen(true);
                  else completeRSVP();
                }}
              >
                <img src={'/rsvp/rsvp' + (isHover ? '-light' : '') + '.svg'} alt="rsvp" />
                Complete RSVP
              </button>
            </div>
          </div>
        </div>
      </IonContent>
      <div className="footerSectionMainPlain">
        <button
          className={'website'}
          style={!wedding.emailInvitationWebsite ? { opacity: '.5' } : { cursor: 'pointer' }}
          onClick={(e) => {
            if (e.button === 2) return;
            if (wedding.emailInvitationWebsite) setIsModalOpen(true);
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-browser.svg" alt={'website'} />
          <p>Website</p>
        </button>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/schedule');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-calendar.svg" alt={'schedule'} />
          <p>Schedule</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/main');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-apps.svg" alt={'main'} />
          <p>Main</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/rsvp');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-filled-envelope-marker.svg" alt={'rsvp'} />
          <p>RSVP</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/home');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-camera.svg" alt={'photo app'} />
          <p>Photo App</p>
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        style={{ borderRadius: 8, minWidth: 'fit-content', padding: 16 }}
        icon={<img src={'/assets/icons/icon-accept.svg'} alt={'accept'} style={{ height: 16, width: 16 }} />}
        position="bottom-center"
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      {isModalOpen && (
        <GenericConfirmModal
          setShow={setIsModalOpen}
          title={'Confirm Action'}
          text={"You are about to leave this app and open a new tab to continue on the couple's website."}
          confirmBtnFunction={() => {
            setIsModalOpen(false);
            window.open(
              (window.location.href.includes('uk')
                ? process.env.REACT_APP_NEXTJS_URL_UK
                : process.env.REACT_APP_NEXTJS_URL_GR) +
                'm/' +
                wedding.path,
              '_blank', // <- This is what makes it open in a new window.
            );
          }}
          confirmBtnText={'Continue'}
          confirmBtnIcon={''}
          // html={t}
        />
      )}
      {isCompleteModalOpen && (
        <GenericConfirmModal
          setShow={setIsCompleteModalOpen}
          title={'Confirm Action'}
          text={
            "Your RSVP isn't complete yet. Don't forget to finish it whenever you get a chance. \n" +
            'Are you certain you want to move forward?'
          }
          confirmBtnFunction={() => {
            setIsCompleteModalOpen(false);
            completeRSVP();
          }}
          confirmBtnText={'Save & Close'}
          confirmBtnIcon={'save'}
          // html={t}
        />
      )}
      {completed && (
        <div
          className="loadingView"
          style={{
            position: 'absolute',
            top: completedStyle.top,
            left: completedStyle.left,
            width: completedStyle.width,
            height: completedStyle.height,
            backdropFilter: 'blur(1px)',
          }}
        >
          <Lottie options={defaultOptions1} height={300} width={300} />
        </div>
      )}
    </IonPage>
  );
};
