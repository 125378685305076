export const validateEmail = (email: string) => {
  return Boolean(
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ),
  );
};

export const validatePhone = (phone: string) => {
  return /^[+]?[(]?[0-9]{3}[)]?[-|.]?[0-9]{3}[-|.]?[0-9]{4,8}$/im.test(phone);
};
