import React, { useEffect, useState } from 'react';
import templateStyles from '../TheArtist.module.scss';
import styles from './TheArtistThanksComponent.module.scss';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';
import { Imagehelper } from '../../../app/helper/Imagehelper';

enum ItsAllThanksToImagesLayout {
  layout_2_images = 0,
  layout_2_cols_3_images = 1,
  layout_2_cols_4_images = 2,
}

type ItsAllThanksToComponentProps = {
  title?: string;
  imagesLayout?: ItsAllThanksToImagesLayout;
  image1?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  fields?: Array<{ title: string; description: string }>;
};

const defaultValues: ItsAllThanksToComponentProps = {
  title: `It's all thanks to`,
  imagesLayout: ItsAllThanksToImagesLayout.layout_2_images,

  image1: '/templates/TheArtist/ItsAllThanksToImage1.png',
  image2: '/templates/TheArtist/ItsAllThanksToImage2.png',
  image3: '/templates/TheArtist/ItsAllThanksToImage3.png',
  image4: '/templates/TheArtist/ItsAllThanksToImage4.png',

  fields: [],
};

const TheArtistThanksComponent = ({
  title,
  imagesLayout = ItsAllThanksToImagesLayout.layout_2_images,
  image1,
  image2,
  image3,
  image4,
  fields,
}: ItsAllThanksToComponentProps): JSX.Element => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [blob1, setBlob1] = useState('');
  const [blob2, setBlob2] = useState('');
  const [blob3, setBlob3] = useState('');
  const [blob4, setBlob4] = useState('');

  const fetchPostImage1 = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob1(result);
  };
  const fetchPostImage2 = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob2(result);
  };
  const fetchPostImage3 = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob3(result);
  };
  const fetchPostImage4 = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob4(result);
  };

  useEffect(() => {
    if (image1 && image1 != defaultValues.image1) {
      fetchPostImage1(image1);
    }
  }, [image1]);

  useEffect(() => {
    if (image2 && image2 != defaultValues.image1) {
      fetchPostImage2(image2);
    }
  }, [image2]);

  useEffect(() => {
    if (image3 && image3 != defaultValues.image1) {
      fetchPostImage3(image3);
    }
  }, [image3]);

  useEffect(() => {
    if (image4 && image4 != defaultValues.image1) {
      fetchPostImage4(image4);
    }
  }, [image4]);

  return (
    <section
      id={`TheArtistThanksComponent`}
      className={styles.ItsAllThanksToComponent}
      style={{
        backgroundImage: 'url("/templates/TheArtist/snow.png")',
      }}
    >
      {imagesLayout === ItsAllThanksToImagesLayout.layout_2_images && (
        <div className={styles.layout_2_images}>
          <img src={blob1 ? blob1 : defaultValues.image1} alt="small logo" />
          <img src={blob2 ? blob2 : defaultValues.image2} alt="small logo" />
        </div>
      )}
      {imagesLayout === ItsAllThanksToImagesLayout.layout_2_cols_3_images && (
        <div className={styles.layout_2_cols_3_images}>
          <img src={blob1 ? blob1 : defaultValues.image1} alt="small logo" />

          <img src={blob2 ? blob2 : defaultValues.image2} alt="small logo" />
          <img src={blob3 ? blob3 : defaultValues.image3} alt="small logo" />
        </div>
      )}
      {imagesLayout === ItsAllThanksToImagesLayout.layout_2_cols_4_images && (
        <div className={styles.layout_2_cols_4_images}>
          <img src={blob1 ? blob1 : defaultValues.image1} alt="small logo" />
          <img src={blob2 ? blob2 : defaultValues.image2} alt="small logo" />
          <img src={blob3 ? blob3 : defaultValues.image3} alt="small logo" />
          <img src={blob4 ? blob4 : defaultValues.image4} alt="small logo" />
        </div>
      )}
      <div className={styles.ImageContentWrapper}>
        <img src="/templates/TheArtist/bonsai.svg" alt="bonsai" />
        <div className={styles.fields}>
          <h2>{String(title ? title : defaultValues.title)}</h2>
          <div>
            {fields?.map((q, index) => {
              if (q.title !== '') {
                return (
                  <div key={index}>
                    <h5>{q.title}</h5>
                    <p>{q.description}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default TheArtistThanksComponent;
