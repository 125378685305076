/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import templateStyles from '../Baptism2Template.module.scss';

import { Imagehelper } from '../../../app/helper/Imagehelper';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP, selectMarriageCode } from '../../../app/redux/authorization.slice.reducer';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { selectComponentProps } from '../../../app/redux/websiteBuilder.slice.reducer';
import { useDropzone } from 'react-dropzone';
import styles from '../../../component/dashboard/planning/Planning.module.scss';
import { IonSpinner } from '@ionic/react';

type InputComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

const InputBaptism2MainComponent = ({
  // componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: InputComponentProps): any => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const wedding = useSelector(selectWedding);
  // const marriageCode = useSelector(selectMarriageCode);
  const componentPropsStore = useSelector(selectComponentProps);

  const [loading, setLoading] = useState<boolean>(false);
  //IMAGE HELPER
  const [fileUrl, setFileurl] = useState<string>('');
  const [fileId, setFileId] = useState<string | undefined>('');
  const [blobId, setBlobId] = useState<string>('');
  const [completedFile, setCompletedFile] = useState<boolean>(true);
  const [percentage, setPercentage] = useState<number>(0);

  const [editing, setEditing] = useState<boolean>(false);
  const [isDropzoneActive, setIsDropzoneActive] = useState<boolean>(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 20000000,
    accept: {
      'image/*': [],
      'image/heif': ['.heic', '.heif'],
    },
    onDragEnter: (dragEvent) => {
      setIsDropzoneActive(true);
    },
    onDragLeave: (dragEvent) => {
      setIsDropzoneActive(false);
    },
    onDropAccepted: async (fileAccepted) => {
      await Imagehelper.handleCompressedImageUpload(
        fileAccepted[0],
        userToken,
        wedding.path,
        setPercentage,
        percentage,
        setLoading,
        setFileurl,
        setFileId,
        setCompletedFile,
        true,
      );
    },
    onDropRejected: (fileRejections) => {
      console.log(
        fileRejections[0].errors[0].message +
          '. Please only Upload one image of jpg/jpeg/png/gif format of max size 10Mb.',
      );
    },
  });

  useEffect(() => {
    //TODO: MUST BE FIXED
    if (fileId !== '' && fileId !== undefined) fetchPostImage(fileId);
  }, [fileId]);

  // get image
  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlobId(result);
  };

  const emptyComponentValues = {
    Baptism2MainComponentTypographyLogo: '',
    Baptism2MainComponentCoupleName: '',
    Baptism2MainComponentMainImage: '',
  };
  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(componentPropsStore) && componentPropsStore.length === 0) {
      let tempProps = componentProps[componentIndex];
      if (tempProps === undefined) {
        tempProps = emptyComponentValues;
        updateComponentProps(tempProps, componentIndex);
        autoSave();
      }
    }
  }, [componentProps[componentIndex]]);

  useEffect(() => {
    if (fileId !== '') {
      updateProps(fileId, 'Baptism2MainComponentMainImage');
      setLoading(false);
    }
  }, [blobId]);

  const removeImage = () => {
    updateProps('', 'Baptism2MainComponentMainImage');
  };

  const updateProps = (input: any, key: string) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      tempProps[componentIndex][key] = input;
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex][key] = input;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  let files = acceptedFiles.map((file: any) => <p key={file.path}>{file.path} Drop or browse to replace image</p>);
  useEffect(() => {
    setShowSpinner(true);
    files = acceptedFiles.map((file: any) => <p key={file.path}>{file.path} Drop or browse to replace image</p>);
    if (files.length > 0) {
      updateProps(acceptedFiles[0].name, 'Baptism2MainComponentMainImageName');
    }
  }, [acceptedFiles]);
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    if (percentage === 0) {
      setShowSpinner(false);
    }

    if (percentage === 100)
      setInterval(() => {
        setShowSpinner(false);
      }, 5000);
  }, [percentage]);

  if (!componentProps[componentIndex]) {
    return null;
  }
  return (
    <section style={{ marginBottom: '64px' }}>
      <h2 className={templateStyles.didacticH2}>Main Section</h2>
      <p className={templateStyles.didacticP} style={{ paddingBottom: '24px' }}>
        Add your image and tell your love story. This will be your main image so pick a good one!
      </p>
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
        Your Main Image*
      </h3>
      {loading && <p>loading...</p>}
      <div className={templateStyles.dragNDrop1}>
        <div
          role={'button'}
          tabIndex={0}
          onMouseDown={() => setIsDropzoneActive(true)}
          onMouseUp={() => setIsDropzoneActive(false)}
          className={templateStyles.dropzone1}
          {...getRootProps()}
          style={isDropzoneActive ? { border: 'dotted 1px #00C9A5' } : {}}
        >
          <input {...getInputProps()} />
          {files?.length > 0 || componentProps[componentIndex]['Baptism2MainComponentMainImageName'] !== undefined ? (
            <Fragment>
              {showSpinner ? (
                <span
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <IonSpinner name="lines" />
                  {percentage > 0 && percentage < 100 ? <p>{percentage.toFixed(2)} %</p> : ''}
                </span>
              ) : (
                <img src="/rsvp/image-default.svg" alt="uploaded" />
              )}
              <p className={'didacticP'} style={isDropzoneActive ? { color: '#00C9A5!important' } : {}}>
                {files?.length > 0 ? (
                  <Fragment>{files}</Fragment>
                ) : (
                  <Fragment>
                    <p key={componentProps[componentIndex]['Baptism2MainComponentMainImageName']}>
                      {componentProps[componentIndex]['Baptism2MainComponentMainImageName']} Drop or browse to replace
                      image
                    </p>
                  </Fragment>
                )}
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <img src={'/rsvp/upload' + (isDropzoneActive ? '-hovered' : '') + '.svg'} alt="upload" />
              <p className={'didacticP'} style={isDropzoneActive ? { color: '#00C9A5' } : {}}>
                Drag & drop your photo here, or{' '}
                <span className={styles.highlighted} style={isDropzoneActive ? { color: '#00C9A5' } : {}}>
                  browse
                </span>{' '}
                your files
              </p>
            </Fragment>
          )}
        </div>
      </div>
      <button className={'btn'} style={{ marginBottom: '12px' }} onClick={removeImage}>
        Clear Image
      </button>
      {/*<input style={{ marginBottom: '16px', width: '100%' }} type="file" onChange={uploadImage} />*/}
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
        Baptism Name*
      </h3>
      <input
        style={{ width: '100%' }}
        className={templateStyles.input}
        placeholder={"Your Kid's Baptism Name"}
        value={componentProps[componentIndex]['Baptism2MainComponentCoupleName']}
        onChange={(e) => updateProps(e.target.value, 'Baptism2MainComponentCoupleName')}
      />
    </section>
  );
};

export default InputBaptism2MainComponent;
