import ScheduleEditor from './ScheduleInputEditor';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import templateStyles from '../sepiaTemplate.module.scss';
import 'react-quill/dist/quill.snow.css';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';

type InputComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

//INPUT FOR TEMPLATE COMPONENTS
const InputSepiaScheduleComponent = ({
  // componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: InputComponentProps): any => {
  const emptyComponentValues = {
    description: '',
  };
  const wedding = useSelector(selectWedding);
  const userToken = useSelector(selectAccessTokenAfterOTP);

  const updateScheduleFromBE = () => {
    console.log('updateScheduleFromBE');
    console.log(wedding.id);
    console.log(userToken);
    const rsvpEventCall = BrainHelper.listRSVPEvents(userToken, wedding.id);
    rsvpEventCall
      .then((rsvpEvents: any) => {
        if (rsvpEvents?.rsvpEvents) {
          const events = rsvpEvents.rsvpEvents.filter((ev: any) => ev.isPrivate === false);
          console.log();
          updateProps(events, 'schedule');
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (wedding) updateProps(wedding.mainEvent?.startTimestamp ? wedding.mainEvent?.startTimestamp : '', 'mainEvent');
  }, [wedding]);

  // useEffect(() => {
  //   if (rsvpEvents1 && rsvpEvents1.length > 0) updateProps(rsvpEvents1, 'schedule');
  // }, [rsvpEvents1]);

  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
      updateScheduleFromBE();
    } else {
      updateScheduleFromBE();
    }
  }, []);

  const updateProps = (input: any, key: string) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      tempProps[componentIndex][key] = input;
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex][key] = input;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  if (!componentProps[componentIndex]) {
    return null;
  }
  return (
    <section style={{ marginBottom: '64px' }}>
      <h2 className={templateStyles.didacticH2}>Schedule Section</h2>
      <p className={templateStyles.didacticP} style={{ paddingBottom: '24px' }}>
        This part will be filled when your planning is completed. Every public event you create will appear here.
      </p>
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
        Section Title*
      </h3>
      <input
        style={{ marginBottom: '24px', width: '100%' }}
        className={templateStyles.input}
        defaultValue={'Schedule'}
        value={componentProps[componentIndex]['title']}
        onChange={(e) => updateProps(e.target.value, 'title')}
      />
      <ScheduleEditor
        updateScheduleFromBE={updateScheduleFromBE}
        value={componentProps[componentIndex]['description']}
        updateProps={(e) => updateProps(e, 'description')}
      />
    </section>
  );
};

export default InputSepiaScheduleComponent;
