/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as tus from 'tus-js-client';
import { retry } from 'ts-retry';
import imageCompression from 'browser-image-compression';
import { logger } from '../../config/Logger';

let __SUPPORTED_IMAGE_TYPE__: string;

export class Imagehelper {
  // get image
  public static async fetchImage(fileUrl: string | null | undefined, setFileId: any, userToken: string, width: number) {
    const newFile = await this.getFileByUrl(fileUrl, userToken, width);
    setFileId(newFile);
  }
  // get image
  public static async returnFetchImage(
    fileUrl: string | null | undefined,
    userToken: string | undefined,
    width: number,
  ) {
    const newFile = await this.getFileByUrl(fileUrl, userToken, width);
    return newFile;
  }

  public static async handleImageUpload(
    event: any,
    userToken: string,
    marriageCode: string,
    setPercentage: any,
    percentage: any,
    setLoading: any,
    setFileurl: any,
    setFileId: any,
    setCompletedFile: any,
    isPublic: boolean,
  ) {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 20, // (default: Number.POSITIVE_INFINITY)

      maxWidthOrHeight: 500, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
      // Please check the Caveat part for details.
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      await Imagehelper.onDrop(
        [compressedFile],
        userToken,
        marriageCode,
        setPercentage,
        percentage,
        setLoading,
        setFileurl,
        setFileId,
        setCompletedFile,
        isPublic,
      ); // theos voithos -> gia sena RIOL mas
    } catch (error) {
      console.log(error);
    }
  }

  public static async handleCompressedImageUpload(
    file: File,
    userToken: string,
    marriageCode: string,
    setPercentage: any,
    percentage: any,
    setLoading: any,
    setFileurl: any,
    setFileId: any,
    setCompletedFile: any,
    isPublic: boolean,
  ) {
    const options = {
      maxSizeMB: 20, // (default: Number.POSITIVE_INFINITY)
      initialQuality: 0.8,
      maxIteration: 5,
      alwaysKeepResolution: true,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      await Imagehelper.onDrop2(
        [compressedFile],
        userToken,
        marriageCode,
        setPercentage,
        percentage,
        setLoading,
        setFileurl,
        setFileId,
        setCompletedFile,
        isPublic,
      ); // theos voithos -> gia sena RIOL mas
    } catch (error) {
      console.log(error);
    }
  }

  public static async getFileByUrl(fileUrl: string | null | undefined, userToken: string | undefined, width: number) {
    const tp = await Imagehelper.supportedImageType();
    return await fetch(`${fileUrl}?w=${width}&t=${tp}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.blob())
      .then((data) => URL.createObjectURL(data));
  }

  public static async onDrop(
    picture: any,
    userToken: string,
    marriageCode: string,
    setPercentage: any,
    percentage: any,
    setLoading: any,
    setFileurl: any,
    setFileId: any,
    setCompletedFile: any,
    isPublic: boolean,
  ) {
    const file: File = picture[0];
    if (!'image/jpeg, image/png, image/tiff, image/heic, image/heif'.includes(file?.type)) {
      // console.log(file?.type);
      // console.log('error');
      return;
    }
    const endpoint =
      process.env.REACT_APP_REST !== '' ? process.env.REACT_APP_REST : 'http://rest-filestore.localhost/files/';
    // Create a new tus upload
    const upload = new tus.Upload(file, {
      endpoint: endpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      chunkSize: 5000000, // 5mb
      fingerprint: async (file, options) => `${file.name}${file.size}${file.lastModified}`,
      storeFingerprintForResuming: true,
      removeFingerprintOnSuccess: true,
      addRequestId: true,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      metadata: {
        filename: file.name,
        filetype:
          file?.type === ''
            ? file.name.toLocaleLowerCase().includes('heic')
              ? 'image/heic'
              : 'image/heif'
            : file?.type,
        fileLabel: 'post description here',
        remoteLocation: marriageCode, //marriage name here
        visibility: isPublic ? 'public' : 'private',
      },
      onError: function (error) {
        console.log('Failed because: ' + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        setPercentage((bytesUploaded / bytesTotal) * 100);
        console.log(bytesUploaded, bytesTotal, percentage + '%');
      },
      onSuccess: function () {
        console.log('on success', upload.file, upload.url);
        if ('name' in upload.file) {
          let fileUrl;
          if (upload.url) {
            fileUrl = new URL(upload.url);
          } else {
            throw new Error(`wrong url: ${upload.url}`);
          }
          const paths = fileUrl.pathname.split('/');
          let fID = '';
          if (paths.length > 1) {
            fID = paths[2];
          }
          console.log('Download %s from %s', upload.file.name, upload.url);
          if (upload.url !== '') {
            picture.value = '';
            setTimeout(() => {
              setLoading(false);
              setFileurl(upload.url || '');
              if (setCompletedFile) setCompletedFile(true);
              Imagehelper.fetchImage(upload?.url, setFileId, userToken, window.innerHeight);
            }, 1500);
          }
        }
      },
    });

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  }

  public static async onDrop2(
    picture: any,
    userToken: string,
    marriageCode: string,
    setPercentage: any,
    percentage: any,
    setLoading: any,
    setFileurl: any,
    setFileId: any,
    setCompletedFile: any,
    isPublic: boolean,
  ) {
    const file: File = picture[0];
    if (!'image/jpeg, image/png, image/tiff, image/heic, image/heif'.includes(file?.type)) {
      console.log('error');
      return;
    }
    const endpoint =
      process.env.REACT_APP_REST !== '' ? process.env.REACT_APP_REST : 'http://rest-filestore.localhost/files/';
    // Create a new tus upload
    const upload = new tus.Upload(file, {
      endpoint: endpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      chunkSize: 5000000, // 5mb
      fingerprint: async (file, options) => `${file.name}${file.size}${file.lastModified}`,
      storeFingerprintForResuming: true,
      removeFingerprintOnSuccess: true,
      addRequestId: true,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      metadata: {
        filename: file.name,
        filetype:
          file?.type === ''
            ? file.name.toLocaleLowerCase().includes('heic')
              ? 'image/heic'
              : 'image/heif'
            : file?.type,
        fileLabel: 'post description here',
        remoteLocation: marriageCode, //marriage name here
        visibility: isPublic ? 'public' : 'private',
      },
      onError: function (error) {
        console.log('Failed because: ' + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        setPercentage((bytesUploaded / bytesTotal) * 100);
        console.log(bytesUploaded, bytesTotal, percentage + '%');
      },
      onSuccess: function () {
        console.log('on success', upload.file, upload.url);
        if ('name' in upload.file) {
          let fileUrl;
          if (upload.url) {
            fileUrl = new URL(upload.url);
          } else {
            throw new Error(`wrong url: ${upload.url}`);
          }
          const paths = fileUrl.pathname.split('/');
          let fID = '';
          if (paths.length > 1) {
            fID = paths[2];
          }
          console.log('Download %s from %s', upload.file.name, upload.url);
          if (upload.url !== '') {
            picture.value = '';
            setTimeout(() => {
              setLoading(false);
              setFileurl(upload.url || '');
              if (setCompletedFile) setCompletedFile(true);
              setFileId(fID);
            }, 1500);
          }
        }
      },
    });

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  }

  public static async getFileFromFilestoreId(fileID: string, userToken: string, width: number) {
    if (!fileID) return;
    try {
      const tp = await Imagehelper.supportedImageType();
      console.log('tp', tp);
      const result = await retry(
        async () => {
          const response = await fetch(
            `${
              process.env.REACT_APP_REST !== '' ? process.env.REACT_APP_REST : 'http://rest-filestore.localhost/files/'
            }${fileID}?w=${width}&t=${tp}`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            },
          );
          if (!response || !response.ok) throw new Error();
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
        { delay: 1500, maxTry: 30 },
      );
      return result;
    } catch (e) {
      logger.error({ message: e, functionName: 'Imagehelper.getFileFromFilestoreId' });
      return '';
    }
  }

  public static async supportedImageType(): Promise<string> {
    if (!__SUPPORTED_IMAGE_TYPE__) {
      try {
        const image = new Image();
        image.src =
          'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
        await loadImage(image);
      } catch (e) {
        if (!__SUPPORTED_IMAGE_TYPE__) {
          __SUPPORTED_IMAGE_TYPE__ = 'original';
        }
      }
    }

    const expression = /(Mac|iPhone|iPod|iPad)/i;

    if (expression.test(navigator.userAgent) && __SUPPORTED_IMAGE_TYPE__ === 'avif') {
      // logger.log({
      //   message: 'Apple device detected! force change type from avif to webp. Thanks to iOS 16',
      //   functionName: 'ImageHelper.supportedImageType',
      // });
      __SUPPORTED_IMAGE_TYPE__ = 'webp';
    }

    return __SUPPORTED_IMAGE_TYPE__;
  }

  public static windowSize(): number {
    const width = window.innerWidth;
    if (width >= 0 && width <= 400) return 450;
    else if (width > 400 && width <= 600) return 600;
    else if (width > 600 && width <= 800) return 800;
    else return 1000;
  }
}

const loadImage = async (image: HTMLImageElement) => {
  return new Promise((resolve, reject) => {
    image.onerror = async () => {
      try {
        logger.log({
          message: 'ONERROR1__SUPPORTED_IMAGE_TYPE__' + __SUPPORTED_IMAGE_TYPE__,
          functionName: 'ImageHelper.loadImage',
        });
        const image = new Image();
        image.src =
          'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';
        await loadImage2(image);
      } catch (e) {
        if (!__SUPPORTED_IMAGE_TYPE__) {
          __SUPPORTED_IMAGE_TYPE__ = 'original';
        }

        logger.log({
          message: 'CATCHERROR__SUPPORTED_IMAGE_TYPE__' + __SUPPORTED_IMAGE_TYPE__,
          functionName: 'ImageHelper.loadImage',
        });
        resolve(true);
        return;
      }
      resolve(true);
    };
    image.onload = async () => {
      __SUPPORTED_IMAGE_TYPE__ = 'avif';

      logger.log({
        message: 'ONLOAD1__SUPPORTED_IMAGE_TYPE__' + __SUPPORTED_IMAGE_TYPE__,
        functionName: 'ImageHelper.loadImage',
      });
      resolve(true);
      return;
    };
  });
};

const loadImage2 = async (image: HTMLImageElement) => {
  return new Promise((resolve, reject) => {
    image.onerror = async () => {
      __SUPPORTED_IMAGE_TYPE__ = 'original';

      logger.log({
        message: 'ONERROR2__SUPPORTED_IMAGE_TYPE__' + __SUPPORTED_IMAGE_TYPE__,
        functionName: 'ImageHelper.loadImage2',
      });
      resolve(true);
      return;
    };
    image.onload = async () => {
      __SUPPORTED_IMAGE_TYPE__ = 'webp';

      logger.log({
        message: 'ONLOAD2__SUPPORTED_IMAGE_TYPE__' + __SUPPORTED_IMAGE_TYPE__,
        functionName: 'ImageHelper.loadImage2',
      });
      resolve(true);
      return;
    };
  });
};
