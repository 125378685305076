import styles from './Custom404.module.css';

import React, { useEffect, useState } from 'react';
import Header from '../dashboard/header/Header';
import { ScreenType, useTypeOfScreen } from '../../hooks';

export default function Custom404(): JSX.Element {
  const [homeUrl, setHomeUrl] = useState<string>('/');
  const typeOfScreen = useTypeOfScreen();
  useEffect(() => {
    setHomeUrl(window.location.pathname.includes('/dashboard') ? '/dashboard' : '/');
  });

  return (
    <div>
      <Header />
      <div
        className={styles.notFound}
        style={
          typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
            ? { flexDirection: 'column', gap: 8 }
            : {}
        }
      >
        <div className={styles.notFoundImage}>
          <img
            src="/error404.svg"
            alt="error 404"
            style={typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop ? { width: 150 } : {}}
          />
        </div>
        <div
          className={styles.notFoundText}
          style={
            typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyItems: 'center',
                  width: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1
            style={
              typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                ? { fontSize: 61, textAlign: 'center' }
                : {}
            }
          >
            404
          </h1>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <h2
            style={
              typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                ? { fontSize: 51, textAlign: 'center' }
                : {}
            }
          >
            Oh, no! Something is missing
          </h2>
          <h4
            style={
              typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                ? { fontSize: 36, textAlign: 'center' }
                : {}
            }
          >
            Let{"'"}s go back and try elsewhere, shall we?
          </h4>
          <a href={homeUrl}>
            <div className={styles.backBtn}>Back Home</div>
          </a>
        </div>
      </div>
    </div>
  );
}
