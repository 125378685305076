/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import templateStyles from '../TheArtist.module.scss';

import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import LayoutSelector from './LayoutSelector';
import ImageDragNDrop from './ImageDragNDrop';
import { CustomButtonImgAndIcon } from '../../../shared';

type InputTheArtistThanksComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

const InputTheArtistThanksComponent = ({
  componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: InputTheArtistThanksComponentProps): any => {
  const emptyComponentValues = {
    imagesLayout: 0,
    image1: '/templates/TheArtist/ItsAllThanksToImage1.png',
    image2: '/templates/TheArtist/ItsAllThanksToImage1.png',
    image3: '/templates/TheArtist/ItsAllThanksToImage1.png',
    image4: '/templates/TheArtist/ItsAllThanksToImage1.png',
    fields: [
      {
        title: 'Parents',
        description: 'Philip & Josephine',
      },
      {
        title: 'Groomsmen',
        description: 'George & Peter',
      },
      {
        title: 'Bridesmaids',
        description: 'Alexia & Sophie',
      },
    ],
  };
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const wedding = useSelector(selectWedding);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [loading3, setLoading3] = useState<boolean>(false);
  const [loading4, setLoading4] = useState<boolean>(false);
  //IMAGE HELPER
  const [fileUrl1, setFileurl1] = useState<string>('');
  const [fileUrl2, setFileurl2] = useState<string>('');
  const [fileUrl3, setFileurl3] = useState<string>('');
  const [fileUrl4, setFileurl4] = useState<string>('');

  const [fileId1, setFileId1] = useState<string | undefined>('');
  const [fileId2, setFileId2] = useState<string | undefined>('');
  const [fileId3, setFileId3] = useState<string | undefined>('');
  const [fileId4, setFileId4] = useState<string | undefined>('');

  const [completedFile1, setCompletedFile1] = useState<boolean>(true);
  const [completedFile2, setCompletedFile2] = useState<boolean>(true);
  const [completedFile3, setCompletedFile3] = useState<boolean>(true);
  const [completedFile4, setCompletedFile4] = useState<boolean>(true);

  const [percentage1, setPercentage1] = useState<number>(0);
  const [percentage2, setPercentage2] = useState<number>(0);
  const [percentage3, setPercentage3] = useState<number>(0);
  const [percentage4, setPercentage4] = useState<number>(0);

  const [isDropzone1Active, setIsDropzone1Active] = useState<boolean>(false);
  const [isDropzone2Active, setIsDropzone2Active] = useState<boolean>(false);
  const [isDropzone3Active, setIsDropzone3Active] = useState<boolean>(false);
  const [isDropzone4Active, setIsDropzone4Active] = useState<boolean>(false);

  const [selectedLayout, setSelectedLayout] = useState(0);

  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    } else {
      setSelectedLayout(componentProps[componentIndex]['imagesLayout']);
    }
  }, []);

  useEffect(() => {
    if (fileId1 !== '') {
      updateProps(fileId1, 'image1');
      setLoading1(false);
    }
  }, [fileId1]);

  useEffect(() => {
    if (fileId2 !== '') {
      updateProps(fileId2, 'image2');
      setLoading2(false);
    }
  }, [fileId2]);

  useEffect(() => {
    if (fileId3 !== '') {
      updateProps(fileId3, 'image3');
      setLoading3(false);
    }
  }, [fileId3]);

  useEffect(() => {
    if (fileId4 !== '') {
      updateProps(fileId4, 'image4');
      setLoading4(false);
    }
  }, [fileId4]);

  const updateProps = (input: any, key: string) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      tempProps[componentIndex][key] = input;
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex][key] = input;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  const updateProps1 = (input: any, key: string, index: number) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex] && tempProps[componentIndex]['fields']) {
      if (tempProps[componentIndex]['fields'][index]) {
        tempProps[componentIndex]['fields'][index][key] = input;
      } else {
        tempProps[componentIndex]['fields'][index] = new Object();
        tempProps[componentIndex]['fields'][index][key] = input;
      }
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex]['fields'] = new Array<any>();
      tempProps[componentIndex]['fields'].push({ title: '', description: '' });
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  const removeFieldWithIndex = (index: number) => {
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['fields']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      if (index <= tempProps['fields'].length) {
        tempProps['fields'].splice(index, 1);
      }
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  };

  const moveDown = (index: number) => {
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['fields']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const element = tempProps['fields'][index];
      tempProps['fields'].splice(index, 1);
      tempProps['fields'].splice(index + 1, 0, element);
      updateComponentProps(tempProps, componentIndex);
      autoSave(true);
    }
  };
  const moveUp = (index: number) => {
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['fields']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const element = tempProps['fields'][index];
      tempProps['fields'].splice(index, 1);
      tempProps['fields'].splice(index - 1, 0, element);
      updateComponentProps(tempProps, componentIndex);
      autoSave(true);
    }
  };
  const createFieldWithIndex = (index: number) => {
    console.log(componentProps);
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['fields']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      tempProps['fields'].splice(index, 0, { title: '', description: '' });
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  };
  const createField = () => {
    console.log(componentProps);
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['fields']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      tempProps['fields'].push({ title: '', description: '' });
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    } else {
      updateProps1('', 'title', componentProps);
      updateProps1('', 'description', componentProps);
    }
  };

  const swapImages = ({ fromIndex, toIndex }: { fromIndex: number; toIndex: number }) => {
    if (
      componentProps &&
      componentProps[componentIndex] &&
      componentProps[componentIndex][`image${fromIndex}Name`] &&
      componentProps[componentIndex][`image${toIndex}Name`] &&
      componentProps[componentIndex][`image${fromIndex}`] &&
      componentProps[componentIndex][`image${toIndex}`]
    ) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );

      const element1 = tempProps[`image${fromIndex}Name`];
      const element2 = tempProps[`image${toIndex}Name`];
      const image1 = tempProps[`image${fromIndex}`];
      const image2 = tempProps[`image${toIndex}`];
      tempProps[`image${fromIndex}Name`] = element2;
      tempProps[`image${toIndex}Name`] = element1;
      tempProps[`image${fromIndex}`] = image2;
      tempProps[`image${toIndex}`] = image1;
      updateComponentProps(tempProps, componentIndex);
      autoSave(true);
    }
  };

  if (!componentProps[componentIndex]) {
    return null;
  }
  return (
    <section style={{ marginBottom: '64px' }}>
      <h2 className={templateStyles.didacticH2}>Parents & Friends Section</h2>
      <p className={templateStyles.didacticP} style={{ marginBottom: '24px' }}>
        There’s always place for the people who helped you plan, organize, (pay) and will always be part of you. Find
        some good shots with them and keep your memory forever.
      </p>
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
        Section Title*
      </h3>
      <input
        style={{ marginBottom: '24px', width: '100%' }}
        className={templateStyles.input}
        defaultValue={'Thanks to'}
        value={componentProps[componentIndex]['title']}
        onChange={(e) => updateProps(e.target.value, 'title')}
      />
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
        Select Image Layout*
      </h3>
      {/*SELECT LAYOUT*/}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '24px' }}>
        <LayoutSelector
          selectedLayout={Number(componentProps[componentIndex]['imagesLayout'])}
          setSelectedLayout={(i: number) => {
            updateProps(i, 'imagesLayout');
            setSelectedLayout(i);
          }}
        />
      </div>
      <div>
        <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
          Upload Image 1*
        </h3>
        <ImageDragNDrop
          selectedLayout={selectedLayout}
          imageNumber={1}
          isDropzoneActive={isDropzone1Active}
          setIsDropzoneActive={setIsDropzone1Active}
          userToken={userToken}
          weddingPath={wedding.path}
          percentage={percentage1}
          setCompletedFile={setCompletedFile1}
          loading={loading1}
          setPercentage={setPercentage1}
          setLoading={setLoading1}
          setFileUrl={setFileurl1}
          setFileId={setFileId1}
          updateProps={updateProps}
          componentProps={componentProps}
          componentIndex={componentIndex}
        />
        <div style={{ paddingBottom: '16px', display: 'flex', justifyContent: 'space-between', gap: 8 }}>
          <CustomButtonImgAndIcon
            title=""
            icon="arrow_down"
            callback={() => swapImages({ fromIndex: 1, toIndex: 2 })}
            isSmall
          />
        </div>
      </div>
      <div>
        <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
          Upload Image 2*
        </h3>
        <ImageDragNDrop
          selectedLayout={selectedLayout}
          imageNumber={2}
          isDropzoneActive={isDropzone2Active}
          setIsDropzoneActive={setIsDropzone2Active}
          userToken={userToken}
          weddingPath={wedding.path}
          percentage={percentage2}
          setCompletedFile={setCompletedFile2}
          setPercentage={setPercentage2}
          loading={loading2}
          setLoading={setLoading2}
          setFileUrl={setFileurl2}
          setFileId={setFileId2}
          updateProps={updateProps}
          componentProps={componentProps}
          componentIndex={componentIndex}
        />
        <div style={{ paddingBottom: '16px', display: 'flex', justifyContent: 'space-between', gap: 8 }}>
          <CustomButtonImgAndIcon
            title=""
            icon="arrow_up"
            callback={() => swapImages({ fromIndex: 2, toIndex: 1 })}
            isSmall
          />
          {selectedLayout > 0 && (
            <CustomButtonImgAndIcon
              title=""
              icon="arrow_down"
              callback={() => swapImages({ fromIndex: 2, toIndex: 3 })}
              isSmall
            />
          )}
        </div>
      </div>
      {selectedLayout > 0 && (
        <div>
          <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
            Upload Image 3*
          </h3>
          <ImageDragNDrop
            selectedLayout={selectedLayout}
            imageNumber={3}
            isDropzoneActive={isDropzone3Active}
            setIsDropzoneActive={setIsDropzone3Active}
            userToken={userToken}
            weddingPath={wedding.path}
            percentage={percentage3}
            setPercentage={setPercentage3}
            loading={loading3}
            setCompletedFile={setCompletedFile3}
            setLoading={setLoading3}
            setFileUrl={setFileurl3}
            setFileId={setFileId3}
            updateProps={updateProps}
            componentProps={componentProps}
            componentIndex={componentIndex}
          />
          <div style={{ paddingBottom: '16px', display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <CustomButtonImgAndIcon
              title=""
              icon="arrow_up"
              callback={() => swapImages({ fromIndex: 3, toIndex: 2 })}
              isSmall
            />
            {selectedLayout === 2 && (
              <CustomButtonImgAndIcon
                title=""
                icon="arrow_down"
                callback={() => swapImages({ fromIndex: 3, toIndex: 4 })}
                isSmall
              />
            )}
          </div>
        </div>
      )}
      {selectedLayout > 1 && (
        <div>
          <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
            Upload Image 4*
          </h3>
          <ImageDragNDrop
            selectedLayout={selectedLayout}
            imageNumber={4}
            isDropzoneActive={isDropzone4Active}
            setIsDropzoneActive={setIsDropzone4Active}
            userToken={userToken}
            weddingPath={wedding.path}
            percentage={percentage4}
            setPercentage={setPercentage4}
            loading={loading4}
            setCompletedFile={setCompletedFile4}
            setLoading={setLoading4}
            setFileUrl={setFileurl4}
            setFileId={setFileId4}
            updateProps={updateProps}
            componentProps={componentProps}
            componentIndex={componentIndex}
          />
          <div style={{ paddingBottom: '16px', display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <CustomButtonImgAndIcon
              title=""
              icon="arrow_up"
              callback={() => swapImages({ fromIndex: 4, toIndex: 3 })}
              isSmall
            />
          </div>
        </div>
      )}
      {componentProps &&
        componentProps[componentIndex] &&
        componentProps[componentIndex]['fields'] &&
        componentProps[componentIndex]['fields'].map((e: any, index: number) => {
          return (
            <div key={index} style={{ paddingBottom: '16px', paddingTop: '16px' }}>
              <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
                Title #{String(Number(String(index)) + 1)}
              </h3>
              <input
                onChange={(e) => {
                  updateProps1(e.target.value, 'title', index);
                }}
                value={componentProps[componentIndex]['fields'][index]['title']}
                style={{ marginBottom: '8px' }}
                className={templateStyles.input + ' ' + templateStyles.wfill}
              />
              <h3 className={templateStyles.fridayH3}>Decription #{String(Number(String(index)) + 1)}</h3>
              <input
                onChange={(e) => {
                  updateProps1(e.target.value, 'description', index);
                }}
                value={componentProps[componentIndex]['fields'][index]['description']}
                className={templateStyles.input + ' ' + templateStyles.wfill}
              />
              <div style={{ paddingTop: '16px', display: 'flex', justifyContent: 'space-between', gap: 8 }}>
                <CustomButtonImgAndIcon
                  title="New Title"
                  icon="plus"
                  callback={() => createFieldWithIndex(index + 1)}
                />
                {index > 0 && (
                  <CustomButtonImgAndIcon title="" icon="arrow_up" callback={() => moveUp(index)} isSmall />
                )}
                {index < componentProps[componentIndex]['fields'].length - 1 && (
                  <CustomButtonImgAndIcon title="" icon="arrow_down" callback={() => moveDown(index)} isSmall />
                )}

                <CustomButtonImgAndIcon
                  title={`Remove QA #${index + 1}`}
                  icon="trash"
                  callback={() => removeFieldWithIndex(index)}
                />
              </div>
            </div>
          );
        })}
      {componentProps &&
        componentProps[componentIndex] &&
        componentProps[componentIndex]['fields'] &&
        componentProps[componentIndex]['fields'].length == 0 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <CustomButtonImgAndIcon title="New Title" icon="plus" callback={() => createField()} />
          </div>
        )}
    </section>
  );
  return null;
};

export default InputTheArtistThanksComponent;
