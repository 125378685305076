import React, { Fragment, useEffect, useReducer, useRef, useState } from 'react';
import { AllowedVisitor, RSVPEvent, TaxonomyOption } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import Icon from '../../../../shared/Icon';
import styles from './GuestModal.module.scss';
import Checkbox from '../../../../shared/Checkbox/CheckboxFilled/CheckboxFilled';
import InputWithLabel from '../../../../shared/inputWithLabel/InputWithLabel';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import {
  addInvitedVisitors,
  selectInvitedVisitors,
  selectTaxonomyOptions,
  selectWedding,
} from '../../../../app/redux/wedding.slice.recuder';
import Taxonomy from '../taxonomy/Taxonomy';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { logger, toJsonString } from '../../../../config/Logger';
import { toast } from 'react-toastify';
import { validateEmail, validatePhone } from '../../../../shared/Validate';
import moment from 'moment-timezone';
import timezoneData from 'moment-timezone/data/meta/latest.json';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { useTypeOfScreen } from '../../../../hooks';
import { selectProfile } from '../../../../app/redux/authorization.slice.reducer';
import UserInfo from '../userInfo/UserInfo';

type GuestModalProps = {
  jwt: string;
  guest?: AllowedVisitor;
  rsvpEvents: RSVPEvent[];
  showGuestModal: boolean;
  setShowGuestModal: any;
};

function reducer(state: { events: RSVPEvent[]; isAll: boolean }, action: { payload: RSVPEvent[]; type: string }) {
  const stateTemp = state.events;
  switch (action.type) {
    case 'set':
      if (state.isAll) return { events: [], isAll: false };
      else return { events: action.payload, isAll: true };
    case 'add':
      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = stateTemp.findIndex((p) => p?.id === newElement?.id || p?.name === newElement?.name);
        // if exists then replace previous value with new one
        if (index > -1) {
          stateTemp[index] = newElement;
        } else {
          // append
          stateTemp.push(newElement);
        }
      }
      return { ...state, events: stateTemp };
    case 'remove':
      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = stateTemp.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          stateTemp.splice(index, 1);
        }
      }
      return { ...state, events: stateTemp };
    default:
      throw new Error();
  }
}

const GuestModal = ({ jwt, guest, rsvpEvents, showGuestModal, setShowGuestModal }: GuestModalProps) => {
  const isEditMode = guest !== undefined;
  const wedding = useSelector(selectWedding);
  const allVisitors = useSelector(selectInvitedVisitors);
  const profile = useSelector(selectProfile);
  const taxonomyOptions = useSelector(selectTaxonomyOptions);
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [checkPhone, setCheckPhone] = useState('');
  const [phoneData, setPhoneData] = useState<CountryData | Record<string, unknown> | undefined>(undefined);
  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [phoneInputCountry, setPhoneInputCountry] = useState<string>('gr');
  const [invitationTaxonomyList, setInvitationTaxonomyList] = useState<TaxonomyOption[]>([]);
  const [isHost, setIsHost] = useState(false);
  const [allowPlusOne, setAllowPlusOne] = useState(false);
  const [maxAllowedPlus1s, setMaxAllowedPlus1s] = useState<number | undefined>(0);
  const [isHover, setIsHover] = useState(false);
  const [isAllPublicEventsInvited, setIsAllPublicEventsInvited] = useState(false);
  const [rsvpEventsReducer, rsvpEventsReducerDispatch] = useReducer(reducer, {
    events: !isEditMode ? rsvpEvents.filter((event) => wedding.mainEvent?.id === event.id) : [],
    isAll: false,
  });
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isGuestLinkCheck, setIsGuestLinkCheck] = useState(false);
  const [sendQuery, setSendQuery] = useState('');
  const [guestList, setGuestList] = useState(Array<AllowedVisitor>());
  const [selectedGuest, setSelectedGuest] = useState<AllowedVisitor | undefined>(undefined);
  const filteredArrayToShow = guestList.filter((item) => selectedGuest?.id !== item.id);

  const dispatch = useDispatch();
  const guestModalRef = useRef(null);
  const typeOfScreen = useTypeOfScreen();

  useEffect(() => {
    if (sendQuery.length > 0) {
      if (sendQuery.trim() !== '') {
        setGuestList(
          allVisitors.filter(
            (item) =>
              item.firstName.toLowerCase().includes(sendQuery.toLowerCase()) ||
              item.lastName.toLowerCase().includes(sendQuery.toLowerCase()) ||
              (item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase()).includes(sendQuery.toLowerCase()),
          ),
        );
      } else {
        setGuestList([]);
      }
    } else {
      setGuestList([]);
    }
  }, [sendQuery]);

  useEffect(() => {
    if (isEditMode && guest) {
      setIsHost(guest.isHost);
      setAllowPlusOne(Number(guest?.maxAllowedPlus1 || 0) > 0);
      setMaxAllowedPlus1s(Number(guest?.maxAllowedPlus1 || 0));
      setEmail(guest.email);
      setFullName(`${guest.firstName} ${guest.lastName}`);
      setPhone(guest.phone);
      setCheckPhone(guest.phone);
      const timeZoneToCountry = new Map<string, string>();
      const countryName = moment.tz.guess(true);
      Object.values(timezoneData.zones).forEach((z) => {
        timeZoneToCountry.set(z.name, z.countries[0]);
      });
      const countryCode = timeZoneToCountry.get(countryName);
      setPhoneInputCountry(countryCode?.toLowerCase() || 'gr');

      const guestRsvpEvents = rsvpEvents.filter((event) =>
        guest.invitedToRsvpEvents.some((invitedEvent) => invitedEvent.id === event.id),
      );
      rsvpEventsReducerDispatch({ payload: guestRsvpEvents, type: 'add' });
      setInvitationTaxonomyList(guest.taxonomyOptions);

      setIsGuestLinkCheck(!guest.isParent && guest.groupWithVisitorIds.length > 0);
      if (guest.groupWithVisitorIds.length > 0 && !guest.isParent) {
        setSelectedGuest(allVisitors.filter((v) => guest.groupWithVisitorIds.includes(v.id))[0]);
      }
      // Set other relevant states
    }
  }, [guest, isEditMode]);

  useEffect(() => {
    const allPublicEvents = rsvpEvents.filter((event) => !event.isPrivate && wedding.mainEvent?.id !== event.id);
    const includedPublicEvents = rsvpEventsReducer.events.filter(
      (event) => !event.isPrivate && wedding.mainEvent?.id !== event.id,
    );

    const areAllPublicEventsIncluded = allPublicEvents.every((publicEvent) =>
      includedPublicEvents.some((event) => event.id === publicEvent.id),
    );

    setIsAllPublicEventsInvited(areAllPublicEventsIncluded);
  }, [rsvpEventsReducer]);

  const handleAllPublicEventsCheckboxChange = () => {
    if (isAllPublicEventsInvited) {
      const publicEventIdsToRemove = rsvpEvents.filter(
        (event) => !event.isPrivate && wedding.mainEvent?.id !== event.id,
      );
      rsvpEventsReducerDispatch({ type: 'remove', payload: publicEventIdsToRemove });
    } else {
      const publicEventsToAdd = rsvpEvents.filter(
        (event) =>
          !event.isPrivate &&
          wedding.mainEvent?.id !== event.id &&
          !rsvpEventsReducer.events.some((reducerEvent) => reducerEvent.id === event.id),
      );
      rsvpEventsReducerDispatch({ type: 'add', payload: publicEventsToAdd });
    }
  };

  useOutsideClick(
    guestModalRef,
    () => {
      if (showGuestModal) {
        setShowGuestModal(false);
        clearState();
      }
    },
    'guestModal',
  );

  const clearState = () => {
    setEmail('');
    setFullName('');
    setPhone('');
    setPhoneData(undefined);
    setErrorName('');
    setErrorEmail('');
    setErrorPhone('');
    setPhoneInputCountry('gr');
    setInvitationTaxonomyList([]);
    rsvpEventsReducerDispatch({ payload: [], type: 'set' });
  };

  const toggleItem = (taxonomyOption: TaxonomyOption) => {
    const temp = invitationTaxonomyList.slice();
    const index = temp.findIndex((i) => i?.id === taxonomyOption.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(taxonomyOption);
    }
    setInvitationTaxonomyList(temp);
  };

  const onCreateVisitor = async () => {
    let errors = 0;
    if (fullName.length < 1) {
      setErrorName('Full name cannot be empty\n');
      errors++;
    }
    if (email.length < 3 || !validateEmail(email)) {
      setErrorEmail('Email is invalid\n');
      errors++;
    }

    if (phone.length > 0) {
      if (
        phone.length < 4 ||
        !validatePhone(phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''))
      ) {
        setErrorPhone('Phone is invalid\n');
        errors++;
      }
      if (
        !phoneData ||
        !phoneData.dialCode ||
        String(phoneData?.dialCode)?.trim() === '' ||
        (phoneData &&
          typeof phoneData.dialCode === 'string' &&
          phone.slice(0, phoneData.dialCode.length + 1) !== `+${phoneData?.dialCode}`)
      ) {
        setErrorPhone("Phones' country code is invalid or missing\n");
        errors++;
      }
    }

    if (errors > 0) {
      return;
    }
    let firstName = '';
    let lastName = '';
    const words = fullName.split(' ');
    if (words.length > 0) {
      firstName = words[0];
    }
    if (words.length > 1) {
      for (let i = 1; i < words.length; i++) {
        if (lastName !== '') {
          lastName += ` ${words[i]}`;
        } else {
          lastName += words[i];
        }
      }
    }
    try {
      const groupedWithVisitorsIds: string[] = selectedGuest && isGuestLinkCheck ? [selectedGuest.id] : [];
      const newVisitor = await BrainHelper.createAllowedVisitor(
        jwt,
        wedding.id,
        email.trim(),
        phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
        firstName,
        lastName,
        '',
        '',
        maxAllowedPlus1s || 0,
        invitationTaxonomyList.map((e) => e.id),
        rsvpEventsReducer.events.map((e) => e.id),
        false,
        false,
        groupedWithVisitorsIds,
        dispatch,
        addInvitedVisitors,
      );
      if (
        typeof newVisitor === 'string' &&
        toJsonString(newVisitor).includes('email') &&
        toJsonString(newVisitor).includes('already exists')
      ) {
        toast('Email or phone is being used by another user. Please use something else', {
          icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
          style: { backgroundColor: '#F8AAB8' },
        });
        return false;
      } else {
        setShowGuestModal(false);
        clearState();
        return true;
      }
    } catch (e) {
      logger.error({ message: e, functionName: 'GuestModal.onCreateVisitor.createAllowedVisitor' });
    }
  };

  const onUpdateVisitor = async () => {
    let errors = 0;
    if (fullName.length < 1) {
      setErrorName('Full name cannot be empty\n');
      errors++;
    }
    if (
      phone !== checkPhone &&
      (phone.length < 4 ||
        !validatePhone(phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '')))
    ) {
      setErrorPhone('Phone is invalid\n');
      errors++;
    }
    if (
      phone !== checkPhone &&
      (!phoneData ||
        !phoneData.dialCode ||
        String(phoneData?.dialCode)?.trim() === '' ||
        (phoneData &&
          typeof phoneData.dialCode === 'string' &&
          phone.slice(0, phoneData.dialCode.length + 1) !== `+${phoneData?.dialCode}`))
    ) {
      setErrorPhone("Phones' country code is invalid or missing\n");
      errors++;
    }

    if (errors > 0) {
      return;
    }

    if (guest?.email === profile.email) setIsHost(guest.isHost);

    if (guest) {
      let firstName = '';
      let lastName = '';
      const words = fullName.split(' ');
      if (words.length > 0) {
        firstName = words[0];
      }
      if (words.length > 1) {
        for (let i = 1; i < words.length; i++) {
          if (lastName !== '') {
            lastName += ` ${words[i]}`;
          } else {
            lastName += words[i];
          }
        }
      }
      let groupedWithVisitorsIds: string[] = [];
      if (isGuestLinkCheck) {
        groupedWithVisitorsIds = guest.isParent
          ? [...guest.groupWithVisitorIds]
          : selectedGuest
          ? [selectedGuest.id]
          : [];
      }

      try {
        const updateAllowedVisitor = await BrainHelper.updateAllowedVisitor(
          jwt,
          guest.id,
          wedding.id,
          firstName,
          lastName,
          email.trim(),
          phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
          maxAllowedPlus1s || 0,
          rsvpEventsReducer.events.map((e) => e.id),
          invitationTaxonomyList.map((tax) => tax.id),
          isHost,
          guest.isParent,
          groupedWithVisitorsIds,
          dispatch,
          addInvitedVisitors,
        );
        if (updateAllowedVisitor.id === guest.id) {
          const rsvpEventsIds: string[] = [];
          for (const invited of guest.invitedToRsvpEvents) {
            if (rsvpEventsReducer.events.map((e) => e.id).filter((id) => id === invited.id).length <= 0) {
              rsvpEventsIds.push(invited.id);
            }
          }
          await BrainHelper.detachRSVPEventFromAllowedVisitors(jwt, guest.id, rsvpEventsIds);

          const taxonomyIds: string[] = taxonomyOptions
            .map((tax: TaxonomyOption) => tax.id)
            .filter((option: string) => !invitationTaxonomyList.map((tax) => tax.id).includes(option));
          await BrainHelper.detachTaxonomyOptionsFromAllowedVisitors(
            jwt,
            wedding.id,
            guest.id,
            taxonomyIds,
            dispatch,
            addInvitedVisitors,
          );
          setShowGuestModal(false);
          clearState();
        }
      } catch (e: any) {
        if (toJsonString(e).includes('cannot edit guest with linked real user')) {
          toast('Cannot change email or phone after guest confirmation', {
            icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
            style: { backgroundColor: '#F8AAB8' },
          });
        }
        if (toJsonString(e).includes('email')) {
          toast('Email or phone is being used by another user. Please use something else', {
            icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
            style: { backgroundColor: '#F8AAB8' },
          });
        }
      }
    }
  };

  return (
    <div
      ref={guestModalRef}
      className={showGuestModal ? styles.wrapper + ' ' + styles[`wrapper${typeOfScreen}`] : styles.hidden}
    >
      <div className={styles.flexColumn}>
        <div className={styles.flex}>
          <div className={styles.flex}>
            <Icon name={isEditMode ? 'write_user' : 'add_user'} color={'#281B24'} size={'s'} />
            <h3 className={'fridayH3'}>{isEditMode ? 'Edit Guest' : 'Add to Guest List'}</h3>
          </div>
          <button
            className={styles.cancelBtn}
            onClick={() => {
              clearState();
              setShowGuestModal(false);
            }}
          >
            Cancel
            <Icon name={'close'} size={'s'} color={'#281B24'} />
          </button>
        </div>
        <hr className={styles.hr} />
        {!isEditMode && (
          <p className={'didacticSpan'} style={{ maxWidth: 443 }}>
            By selecting {'"'}Add to Guest List{'"'}, you are adding a guest to your guest list. This action does not
            send them an invitation to any events yet. They will be included in your list, allowing you to manage your
            guest list efficiently.
          </p>
        )}
      </div>
      <div className={styles.informationWrapper}>
        <div className={styles.guestInformation}>
          <h4 className={'fridayH4'}>Guest Information</h4>
          <div>
            {errorName !== '' && <p className={styles.errorText + ' didacticP'}>{errorName}</p>}
            <InputWithLabel
              label={'Full Name*'}
              value={fullName}
              setValue={setFullName}
              hasError={errorName !== ''}
              setHasError={setErrorName}
            />
          </div>
          <div>
            {errorEmail !== '' && <p className={styles.errorText + ' didacticP'}>{errorEmail}</p>}
            <InputWithLabel
              label={'Email*'}
              value={email}
              setValue={setEmail}
              hasError={errorEmail !== ''}
              setHasError={setErrorEmail}
            />
          </div>
          <div>
            {errorPhone !== '' && <p className={styles.errorText + ' didacticP'}>{errorPhone}</p>}
            <PhoneInput
              country={phoneInputCountry}
              placeholder={'Phone Number'}
              value={phone}
              onMount={(value, data) => setPhoneData(data)}
              onChange={(value, data, event, formattedValue) => {
                setErrorPhone('');
                setPhone(formattedValue);
              }}
              onBlur={(event, data: CountryData | Record<string, unknown>) => {
                if (data && event.target.value.split(' ')[0] !== `+${data.dialCode}`) {
                  setPhone(`+${data.dialCode} ${event.target.value.slice(1, event.target.value.length)}`);
                }
                setPhoneData(data);
              }}
              containerStyle={{
                border: errorPhone !== '' ? 'border: 1px solid red;' : '1px solid grey',
                borderRadius: '8px',
                width: '98%',
              }}
            />
            <p className={styles.smallText}>
              Phone number is not mandatory, but users without it cannot use the ExperienceApp
            </p>
          </div>
        </div>
        <div className={styles.guestInformation}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              disabled={guest?.isParent}
              light={true}
              value={isGuestLinkCheck}
              onClick={() => {
                if (isGuestLinkCheck) {
                  setSelectedGuest(undefined);
                  setSendQuery('');
                }
                setIsGuestLinkCheck((prevState) => !prevState);
              }}
            />
            <p className={'didacticP'}>Link Guests</p>
          </div>
          <span className={'didacticSpan'}>Connect this guest as a plus one to an existing one.</span>
          {isGuestLinkCheck && (
            <div>
              <div className={styles.searchBar + ' ' + (isSearchActive ? styles.searchBarActive : '')}>
                <img src={'/rsvp/guests/searchIcon' + (isSearchActive ? '-highlighted' : '') + '.svg'} alt="Search" />
                <input
                  disabled={guest?.isParent}
                  style={{ width: '100%' }}
                  value={
                    sendQuery !== ''
                      ? sendQuery
                      : selectedGuest
                      ? selectedGuest?.firstName + ' ' + selectedGuest?.lastName
                      : ''
                  }
                  placeholder="Full Name"
                  onFocus={() => setIsSearchActive(true)}
                  onBlur={() => setIsSearchActive(false)}
                  onChange={(e) => {
                    setSelectedGuest(undefined);
                    setSendQuery(e.target.value);
                  }}
                />
              </div>
              {guestList.length > 0 && true ? (
                <div className={styles.searchResults} id={'guestList'}>
                  {filteredArrayToShow.length > 0 ? (
                    filteredArrayToShow.map((g) => {
                      if (g.id !== guest?.id)
                        return (
                          <button
                            key={g.id}
                            id={'guestList' + g.id}
                            className={styles.searchResultsButton}
                            onClick={(e) => {
                              setSelectedGuest(g);
                              setGuestList([]);
                              setSendQuery('');
                            }}
                          >
                            <UserInfo
                              userToken={jwt}
                              firstName={g.firstName}
                              lastName={g.lastName}
                              imageSrc={g.connectedAccountDetails?.imageProfileUrl}
                              setSelectedGuest={() => console.log()}
                            />
                          </button>
                        );
                    })
                  ) : (
                    <p className={'didacticP'}>No matches left</p>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
        <div className={styles.guestInformation}>
          <h4 className={'fridayH4'}>Add plus 1</h4>
          <div className={styles.checkboxWrapper}>
            <Checkbox light={true} value={allowPlusOne} onClick={() => setAllowPlusOne((prevState) => !prevState)} />
            <p className={'didacticP'}>Allowed +1</p>
          </div>
          {allowPlusOne && (
            <input
              className={'input inputMedium'}
              type={'number'}
              value={maxAllowedPlus1s}
              min={0}
              onChange={(e) => {
                if (Number(e.target.value) < 0) setMaxAllowedPlus1s(0);
                else setMaxAllowedPlus1s(Number(e.target.value) || undefined);
              }}
            />
          )}
          <span className={'didacticSpan'}>Add how many +1 are allowed for the user</span>
        </div>
        <div className={styles.guestInformation}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              light={true}
              value={rsvpEventsReducer.events.findIndex((event) => wedding.mainEvent?.id === event.id) > -1 || false}
              onClick={() =>
                rsvpEventsReducerDispatch({
                  payload: rsvpEvents.filter((event) => wedding.mainEvent?.id === event.id),
                  type:
                    rsvpEventsReducer.events.findIndex((event) => wedding.mainEvent?.id === event.id) > -1
                      ? 'remove'
                      : 'add',
                })
              }
            />
            <p className={'didacticP'}>{wedding.mainEvent?.name || 'Ceremony'}</p>
          </div>
          <span className={'didacticSpan'}>
            By default all users are added to your {wedding.mainEvent?.name || 'Ceremony'}
          </span>
        </div>
        <div className={styles.guestEvents}>
          <div>
            <h4 className={'fridayH4'}>Add to Event</h4>
            <span className={'didacticSpan'}>
              The guests you add to the events are not automatically invited by you when you do so. Make sure you send
              them an invitation first.
            </span>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox value={isAllPublicEventsInvited} onClick={handleAllPublicEventsCheckboxChange} />
            <p className={'didacticP'}>Add user to all public events</p>
          </div>
          <hr className={styles.hr} />
          <div className={styles.rsvpEvents}>
            {[...rsvpEvents]
              .sort((a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime())
              .map((rsvpEvent) => {
                if (wedding.mainEvent?.id === rsvpEvent.id) return null;
                const checked = rsvpEventsReducer.events.findIndex((r) => r.id === rsvpEvent.id) > -1;
                return (
                  <div className={styles.checkboxWrapper + ' ' + styles.item} key={rsvpEvent.id}>
                    <Checkbox
                      value={checked}
                      onClick={async (e: any) => {
                        rsvpEventsReducerDispatch({ payload: [rsvpEvent], type: checked ? 'remove' : 'add' });
                      }}
                    />
                    <p className={'didacticP'}>{rsvpEvent.name}</p>
                  </div>
                );
              })}
          </div>
        </div>
        <div className={styles.guestEvents}>
          <h4 className={'fridayH4'}>Add Host</h4>
          <span className={'didacticSpan'}>Select if you want the user to be a host too</span>
          <div className={styles.checkboxWrapper}>
            <Checkbox light={true} value={isHost} onClick={() => setIsHost((prevState) => !prevState)} />
            <p className={'didacticP'}>Host</p>
          </div>
        </div>
        <div className={styles.guestEvents}>
          <div>
            <h4 className={'fridayH4'}>Tags</h4>
            <span className={'didacticSpan'}>
              This is optional. You can add tags to categorize your guests. This action can also be done later within
              the guest table.
            </span>
          </div>
          <div className={styles.tags} style={{ justifyContent: 'unset' }}>
            {taxonomyOptions.map((t) => {
              const isSelected = invitationTaxonomyList.findIndex((i) => i.id === t.id) > -1;
              return (
                <Fragment key={t.id}>
                  <Taxonomy
                    taxonomyText={t.key}
                    isSelected={isSelected}
                    isSelectable={true}
                    selectedCallback={() => toggleItem(t)}
                  />
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <hr className={styles.hr} />
      <button
        className={'btn btnMain btnMainWithImg'}
        onClick={() => (isEditMode ? onUpdateVisitor() : onCreateVisitor())}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Icon name={isEditMode ? 'save' : 'add_user_filled'} size={'s'} color={!isHover ? '#63435B' : '#FDF6EB'} />
        {isEditMode ? 'Save Changes' : 'Add to Guest List'}
      </button>
    </div>
  );
};

export default GuestModal;
