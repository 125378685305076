import React, { Fragment, useEffect, useState } from 'react';
import DefaultQuestions from './DefaultQuestions';
import styles from './Questions.module.scss';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { selectQuestions } from '../../../../app/redux/wedding.slice.recuder';
import { ListQuestionsResponse, RSVPEvent, Wedding } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import ExtraQuestion from './Question';
import { useSelector } from 'react-redux';

const Questions = (props: { event: RSVPEvent; userToken: string; wedding: Wedding }): JSX.Element => {
  const emptyAnswers = ['', ''];
  const questions = useSelector(selectQuestions);
  const [questionsList, setQuestionsList] = useState<ListQuestionsResponse | null>();
  const [addQHover, setAddQHover] = useState<boolean>(false);
  const [isNewQuestion, setIsNewQuestion] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>('');
  const [isCheckbox, setIsCheckbox] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAllowMultiple, setIsAllowMultiple] = useState<boolean>(false);
  const [isFreeText, setIsFreeText] = useState<boolean>(false);
  const [singleAnswers, setSingleAnswers] = useState<string[]>(emptyAnswers);
  const [canCreateQuestion, setCanCreateQuestion] = useState<boolean>(false);

  useEffect(() => {
    getQuestions();
  }, [questions]);

  useEffect(() => {
    if (question !== '' && ((isCheckbox && singleAnswers.length > 0 && !singleAnswers.includes('')) || isFreeText))
      setCanCreateQuestion(true);
    else setCanCreateQuestion(false);
  }, [question, isCheckbox, isFreeText, singleAnswers]);

  const getQuestions = async () => {
    const questionsList = await BrainHelper.listQuestions(props.userToken, props.event.id);
    setQuestionsList(questionsList);
  };

  const createQuestion = async () => {
    const t = await BrainHelper.createQuestion(
      props.userToken,
      props.event.id,
      props.wedding.id,
      question,
      isAllowMultiple,
      false,
      isFreeText,
      singleAnswers,
    );
    if (t?.question) {
      setQuestion('');
      setIsCheckbox(false);
      setIsFreeText(false);
      setSingleAnswers(emptyAnswers);
      setIsNewQuestion(false);
      const questions = JSON.parse(JSON.stringify(questionsList, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
      questions.questionsList.push(t?.question);
      setQuestionsList(questions);
    }
  };

  return (
    <div style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
      <DefaultQuestions event={props.event} disabled={true} />
      {questionsList?.questions.map((question, k) => {
        return (
          <ExtraQuestion
            question={question}
            disabled={true}
            questionsList={questionsList}
            setQuestionsList={setQuestionsList}
            isEditable={true}
            key={k}
            wedding={props.wedding}
          />
        );
      })}
      {!isNewQuestion ? (
        <button
          className={'btn btnMain btnRounded btnMainWithImg'}
          onMouseEnter={() => {
            setAddQHover(true);
          }}
          onMouseLeave={() => {
            setAddQHover(false);
          }}
          onClick={() => {
            setIsNewQuestion(!isNewQuestion);
          }}
        >
          <img src={'/rsvp/plus' + (!addQHover ? '-dark' : '') + '.svg'} alt="rsvp" />
          Add Question
        </button>
      ) : (
        <div className={styles.addQuestion}>
          <h4 className={'didacticH4'} style={{ marginBottom: '8px' }}>
            Type a Question*
          </h4>
          <input
            type="text"
            className={'input inputLarge'}
            placeholder={'Type here'}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <h4 className={'didacticH4'} style={{ marginTop: '24px' }}>
            Answer Type*
          </h4>
          <div className={styles.questionType}>
            <input
              type="checkbox"
              className={'checkbox'}
              name={'checkbox'}
              value={'checkbox'}
              checked={isCheckbox}
              onChange={() => {
                setIsCheckbox(!isCheckbox);
                setIsFreeText(false);
              }}
            />
            <label htmlFor="checkbox">Checkbox</label>
            <input
              type="checkbox"
              className={'checkbox'}
              name={'freetext'}
              value={'freetext'}
              checked={isFreeText}
              onChange={() => {
                setIsFreeText(!isFreeText);
                setIsCheckbox(false);
              }}
            />
            <label htmlFor="{'freetext'}">Free Text</label>
          </div>
          {isCheckbox || isFreeText ? (
            <div>
              {isCheckbox ? (
                <Fragment>
                  <h4 className={'didacticH4'} style={{ marginTop: '32px' }}>
                    Create Answers*
                  </h4>
                  <div className={styles.singleAnswers}>
                    {singleAnswers.map((answer, k) => {
                      return (
                        <div key={k} className={styles.answer}>
                          <input type="checkbox" className={'checkbox'} name={answer} value={answer} />
                          {/*<label htmlFor={emptyAnswer}>{emptyAnswer || 'type here'}</label>*/}
                          <input
                            type="text"
                            className={'input inputNoBorder'}
                            placeholder={'Type here'}
                            value={answer}
                            onChange={(e) => {
                              const temp = JSON.parse(
                                JSON.stringify(singleAnswers, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
                              );
                              temp[k] = e.target.value;
                              setSingleAnswers(temp);
                            }}
                          />
                          <button
                            className={'btn btnSecondary'}
                            style={{ margin: 0 }}
                            onClick={() => {
                              const temp = JSON.parse(
                                JSON.stringify(singleAnswers, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
                              );
                              temp.splice(k, 1);
                              setSingleAnswers(temp);
                            }}
                          >
                            <img
                              style={{ alignSelf: 'center', marginLeft: 'auto', cursor: 'pointer' }}
                              src="/rsvp/delete.svg"
                              alt="edit"
                            />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className={'btn btnSecondary btnSecondaryWithImg'}
                    style={{ margin: 0, paddingLeft: 0 }}
                    onClick={() => {
                      const temp = JSON.parse(
                        JSON.stringify(singleAnswers, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
                      );
                      temp.push('');
                      setSingleAnswers(temp);
                    }}
                  >
                    <img src={'/rsvp/plus-dark.svg'} alt="rsvp" />
                    Add Answer
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <input type="text" className={'input'} style={{ marginTop: '32px' }} />
                </Fragment>
              )}
            </div>
          ) : (
            ''
          )}
          <div>
            <button className={'btn btnMain'} disabled={!canCreateQuestion} onClick={() => createQuestion()}>
              Create Question
            </button>
            <button className={'btn btnSecondary'} onClick={() => setIsNewQuestion(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
      <hr />
    </div>
  );
};

export default Questions;
