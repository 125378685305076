import React from 'react';
import Navbar from '../navbar/Navbar';
import { useSelector } from 'react-redux';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { ScreenType, useTypeOfScreen } from '../../../hooks';

const DashboardLayout = (props: { children: any }): JSX.Element => {
  const wedding = useSelector(selectWedding);
  const typeOfScreen = useTypeOfScreen();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: !(typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop)
          ? 'column-reverse'
          : 'row',
        height: '100svh',
        width: '100svw',
        maxWidth: '100svw',
        overflow: 'hidden',
      }}
    >
      <Navbar
        options={[
          {
            id: 'overviewMenuItem',
            title: 'Overview',
            link: `/dashboard/w/${wedding.path}/overview`,
            icon: '/rsvp/navbar/overview.svg',
          },
          {
            id: 'guestMenuItem',
            title: 'Guests',
            link: `/dashboard/w/${wedding.path}/guests`,
            icon: '/rsvp/navbar/guests.svg',
          },
          {
            id: 'planningMenuItem',
            title: 'Planning',
            link: `/dashboard/w/${wedding.path}/planning`,
            icon: '/rsvp/navbar/planning.svg',
          },
          {
            id: 'websiteMenuItem',
            title: 'Website',
            link: `/dashboard/w/${wedding.path}/website`,
            icon: '/rsvp/navbar/website.svg',
          },
          {
            id: 'rsvpMenuItem',
            title: 'RSVP',
            link: `/dashboard/w/${wedding.path}/rsvp`,
            icon: '/rsvp/navbar/rsvp.svg',
          },
          { id: 'appMenuItem', title: 'App', link: '/main', icon: '/rsvp/navbar/app.svg' },
          {
            id: 'profileMenuItem',
            title: 'Profile',
            link: `/dashboard/w/${wedding.path}/profile`,
            icon: '/rsvp/navbar/profile.svg',
          },
        ]}
        currentPage={0}
        initialsOptions={{
          initials: 'T & M',
          link: `/dashboard/w/${wedding.path}/overview`,
        }}
        logoLink={`/dashboard/w/${wedding.path}/overview`}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100svh',
          flex: '90%',
          padding: '0px',
        }}
      >
        {React.cloneElement({ ...props.children }, { wedding: 1 })}
      </div>
    </div>
  );
};

export default DashboardLayout;
