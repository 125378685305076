import { useState, useEffect } from 'react';

function useMobileCheck(): boolean {
  const [isMobile, setIsMobile] = useState(false);
  // choose the screen size
  const handleResize = (): void => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('onload', handleResize);
  });
  useEffect(() => {
    handleResize();
  });

  return isMobile;
}

export default useMobileCheck;
