export type Theme = {
  bg: string;
  primary: string;
  nodeBg: string;
  nodeColor: string;
  nodeBorder: string;
  minimapMaskBg: string;
  controlsBg: string;
  controlsBgHover: string;
  controlsColor: string;
  controlsBorder: string;
  accountNodeColor: string;
  connectedAccountNodeColor: string;
  weddingNodeColor: string;
};
export const lightTheme: Theme = {
  bg: '#fff',
  primary: '#ff0072',

  nodeBg: '#f2f2f5',
  nodeColor: '#222',
  nodeBorder: '#222',

  minimapMaskBg: '#f2f2f5',

  controlsBg: '#fefefe',
  controlsBgHover: '#eee',
  controlsColor: '#222',
  controlsBorder: '#ddd',

  accountNodeColor: '#534a2e',
  connectedAccountNodeColor: '#3d5b55',
  weddingNodeColor: '#725d51',
};

export const darkTheme: Theme = {
  bg: '#281B24',
  primary: '#ff0072',

  nodeBg: '#281B24',
  nodeColor: '#FDF6EB',
  nodeBorder: '#F9AC80',

  minimapMaskBg: '#343435',

  controlsBg: '#555',
  controlsBgHover: '#676768',
  controlsColor: '#dddddd',
  controlsBorder: '#676768',

  accountNodeColor: '#fae28f',
  connectedAccountNodeColor: '#a3f5e6',
  weddingNodeColor: '#fbccb1',
};
