/* eslint-disable jsx-a11y/no-autofocus */

import { useEffect, useRef, useState } from 'react';
import styles from './OtpInput.module.scss';

interface OtpInputProps {
  onChange: (e: any) => void;
  autofocus?: boolean;
  value: any;
  onPaste: (e: any) => void;
}

const OtpInput = (props: OtpInputProps): JSX.Element => {
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);
  const input5Ref = useRef<HTMLInputElement>(null);
  const input6Ref = useRef<HTMLInputElement>(null);

  const [prevValueLength, setPrevValueLength] = useState(props.value.length);

  useEffect(() => {
    if (props.value.length < 6) {
      switch (props.value.length) {
        case 0:
          input1Ref?.current?.focus();
          break;
        case 1:
          if (prevValueLength >= props.value.length) input1Ref?.current?.focus();
          else input2Ref?.current?.focus();
          break;
        case 2:
          if (prevValueLength >= props.value.length) input2Ref?.current?.focus();
          else input3Ref?.current?.focus();
          break;
        case 3:
          if (prevValueLength >= props.value.length) input3Ref?.current?.focus();
          else input4Ref?.current?.focus();
          break;
        case 4:
          if (prevValueLength >= props.value.length) input4Ref?.current?.focus();
          else input5Ref?.current?.focus();
          break;
        case 5:
          if (prevValueLength >= props.value.length) input5Ref?.current?.focus();
          else input6Ref?.current?.focus();
          break;
        case 6:
          input6Ref?.current?.focus();
          break;
      }
    }
  }, [props.value]);

  function setCharAt(str: string, index: number, chr: string) {
    console.log('setCharAt', str, index, chr);
    if (index > str.length - 1) return str + chr;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  const handleChange = (input: string, index: number) => {
    if (input.length === 1 || input.length === 6 || input === '')
      if ((!isNaN(parseFloat(input)) && isFinite(Number(input))) || input === '') {
        setPrevValueLength(props.value.length);
        props.onChange(setCharAt(props.value, index, input));
      }
  };

  return (
    <div className={styles.OtpInput}>
      <input
        ref={input1Ref}
        value={props.value[0] ?? ''}
        onChange={(e) => handleChange(e.target.value, 0)}
        type="text"
        autoFocus={props.autofocus ?? false}
        maxLength={6}
        onPaste={(e) => props.onPaste(e)}
      />
      <input
        ref={input2Ref}
        value={props.value[1] ?? ''}
        onChange={(e) => handleChange(e.target.value, 1)}
        type="text"
        maxLength={6}
        onPaste={(e) => props.onPaste(e)}
      />
      <input
        ref={input3Ref}
        value={props.value[2] ?? ''}
        onChange={(e) => handleChange(e.target.value, 2)}
        type="text"
        maxLength={6}
        onPaste={(e) => props.onPaste(e)}
      />
      <input
        ref={input4Ref}
        value={props.value[3] ?? ''}
        onChange={(e) => handleChange(e.target.value, 3)}
        type="text"
        maxLength={6}
        onPaste={(e) => props.onPaste(e)}
      />
      <input
        ref={input5Ref}
        value={props.value[4] ?? ''}
        onChange={(e) => handleChange(e.target.value, 4)}
        type="text"
        maxLength={6}
        onPaste={(e) => props.onPaste(e)}
      />
      <input
        ref={input6Ref}
        value={props.value[5] ?? ''}
        onChange={(e) => handleChange(e.target.value, 5)}
        type="text"
        maxLength={6}
        onPaste={(e) => props.onPaste(e)}
      />
    </div>
  );
};

export default OtpInput;
