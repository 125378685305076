import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import styles from './AutocompleteTaxonomy.module.scss';
import Taxonomy from '../taxonomy/Taxonomy';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectJwt } from '../../../../app/redux/authorization.slice.reducer';
import { addTaxonomyOptions, selectTaxonomyOptions, selectWedding } from '../../../../app/redux/wedding.slice.recuder';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { Icon } from '../../../../shared';
import { TaxonomyOption } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';

type AutocompleteProps = {
  sx?: any;
  selected: TaxonomyOption[];
  callback?: any;
  deletable?: boolean;
  addTagIsButton?: boolean;
  filled?: boolean;
  onCreate?: any;
  maxHeight?: number;
};

function AutocompleteTaxonomy({
  selected,
  callback,
  deletable,
  addTagIsButton = false,
  filled = false,
  onCreate,
  maxHeight,
}: AutocompleteProps): JSX.Element {
  // STORE
  const dispatch = useDispatch();
  const storedTaxonomyOptions = useSelector(selectTaxonomyOptions);
  const jwtToken = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);
  //STATES
  const [isFocused, setIsFocused] = useState(false);

  const [value, setValue] = useState<Array<string>>([]);
  const [open, toggleOpen] = useState(false);

  const createTaxonomyOption = (tagName: string) => {
    // console.log(`create Taxonomy Option ${tagName}`);
    const color = '';
    const icon = '';
    const description = '';
    const weddingId = wedding.id;
    const res = BrainHelper.createTaxonomyOption(
      wedding.defaultTaxonomyId,
      tagName,
      color,
      icon,
      description,
      jwtToken,
      weddingId,
      dispatch,
      addTaxonomyOptions,
    );
    res.then((res) => {
      // console.log(res);
      if (onCreate) {
        onCreate(res);
        toggleOpen(false);
      }
    });
  };

  const handleChange = (value?: string[]) => {
    if (value) {
      if (callback) callback(storedTaxonomyOptions.filter((o) => value.includes(o.key)));
    }
  };

  const handleDeleteTaxonomy = (newValue: string) => {
    let tempValue: Array<string> = value;
    tempValue = tempValue.filter((val) => val !== newValue);
    setValue(tempValue);
  };

  const handleCancel = (newValue: string) => {
    let tempValue: Array<string> = value;
    tempValue = tempValue.filter((val) => val !== newValue);
    setValue(tempValue);
    handleClose();
  };

  const handleClose = () => {
    setDialogValue({
      name: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    name: '',
  });

  const dialogSubmit = (newValue: string) => {
    // console.log('Submit', newValue);
    createTaxonomyOption(newValue);
    handleClose();
  };

  useEffect(() => {
    if (selected) {
      setValue(selected.map((tag) => tag.key));
    }
  }, [selected]);

  const onFocusOut = () => {
    if (value.length === 0 && open === false) {
      setIsFocused(false);
    }
  };

  return (
    <Fragment>
      {isFocused === false && selected.length === 0 ? (
        <button
          className={'didacticP btnMain2nd'}
          style={
            addTagIsButton
              ? {
                  background: '#F9AC80',
                  borderRadius: '80px',
                  alignSelf: 'center',
                  justifySelf: 'center',
                  padding: '4px 8px',
                  color: '#63435B',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                }
              : {
                  border: 'none',
                  background: 'none',
                  alignSelf: 'center',
                  justifySelf: 'center',
                  paddingLeft: '8px',
                }
          }
          onClick={() => setIsFocused(true)}
        >
          {addTagIsButton && <Icon name="label" color="#63435B" size="s" />}
          Add Tag
        </button>
      ) : (
        <Fragment>
          {storedTaxonomyOptions && (
            <Autocomplete
              placeholder="Add Tag"
              openOnFocus
              className={styles.autocomplete + ' ' + 'didacticP'}
              onBlur={onFocusOut}
              freeSolo={true}
              multiple={true}
              disablePortal
              disableClearable
              value={value.filter((value) => storedTaxonomyOptions.map((o) => value === o.key).length > 0)}
              sx={{
                zIndex: 100,
                maxHeight: maxHeight ? maxHeight : 'none',
                border: 'none',
                width: '98%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '0',
                  padding: '0',
                  gap: 1,
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              onChange={(event, newValue: string[]) => {
                if (newValue !== null && newValue !== undefined) {
                  if (
                    newValue &&
                    newValue[newValue.length - 1] &&
                    !storedTaxonomyOptions.map((option) => option.key).includes(newValue[newValue.length - 1])
                  ) {
                    setTimeout(() => {
                      toggleOpen(true);
                      setDialogValue({
                        name: newValue[newValue.length - 1],
                      });
                    });
                  } else {
                    handleChange(newValue);
                  }
                }
              }}
              id="combo-box-demo"
              onEmptied={() => handleChange([])}
              options={storedTaxonomyOptions.map((option) => option.key)}
              renderTags={(value1: string[], getTagProps, state) => {
                if (state.focused === true) {
                  return [
                    value1.map((option: string, index) => (
                      <span key={option + '_' + index} style={{ marginBottom: 2 }}>
                        <Taxonomy
                          taxonomyText={option}
                          taxonomyId={wedding.defaultTaxonomyId}
                          isSelected={filled}
                          weddingId={wedding.id}
                          userToken={jwtToken}
                          deletable={deletable ?? false}
                          deleteCallback={() => {
                            handleDeleteTaxonomy(option);
                          }}
                        />
                      </span>
                    )),
                  ];
                } else {
                  return [
                    value1.map((option: string, index) => (
                      <span key={option + '_' + index} style={{ marginBottom: 2 }}>
                        <Taxonomy
                          taxonomyText={option}
                          taxonomyId={wedding.defaultTaxonomyId}
                          isSelected={filled}
                          weddingId={wedding.id}
                          userToken={jwtToken}
                          deletable={deletable ?? false}
                          deleteCallback={() => {
                            handleDeleteTaxonomy(option);
                          }}
                        />
                      </span>
                    )),
                    <p
                      key="Add Tag"
                      style={{
                        alignSelf: 'center',
                        justifySelf: 'center',
                        paddingLeft: '4px',
                        paddingBottom: '0px',
                        cursor: 'pointer',
                        display: state.focused ? 'none' : 'block',
                      }}
                    >
                      <Icon name="plus" size="s" />
                    </p>,
                  ];
                }
              }}
              renderInput={(params) => (
                <TextField
                  style={{ border: '1px solid black' }}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={selected.length === 0}
                  {...params}
                  sx={{ border: 'none', borderRadius: 0 }}
                  className={styles.autocomplete}
                />
              )}
            />
          )}
          <Dialog open={open} onClose={handleClose} color="red">
            <DialogTitle>Add a new tag</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>Create your own tag!</DialogContentText> */}
              <TextField
                sx={{ border: `none` }}
                margin="dense"
                id="name"
                value={dialogValue.name}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    name: event.target.value,
                  })
                }
                label="Tag name"
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                className={styles.CancelButton + ' didacticP'}
                onClick={() => handleCancel(String(dialogValue.name))}
              >
                Cancel
              </Button>
              <Button
                className={styles.AddButton + ' didacticP'}
                onClick={() => {
                  dialogSubmit(String(dialogValue.name));
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  );
}

export default AutocompleteTaxonomy;
