import React, { Fragment, useRef, useState } from 'react';
import styles from './FiltersModal.module.scss';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Icon from '../../../../shared/Icon';
import Checkbox from '../../../../shared/Checkbox/CheckboxFilled/CheckboxFilled';
import { useSelector } from 'react-redux';
import { selectTaxonomyOptions } from '../../../../app/redux/wedding.slice.recuder';
import Taxonomy from '../taxonomy/Taxonomy';
import { TaxonomyOption } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';

type FiltersModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  eventSelector: string;
  filters: {
    attendance: 0 | 1 | 2 | 3;
    status: 0 | 1 | 2 | 3 | 4;
    selectedTaxonomies: TaxonomyOption[];
  };
  setFilters: (filters: {
    attendance: 0 | 1 | 2 | 3;
    status: 0 | 1 | 2 | 3 | 4;
    selectedTaxonomies: TaxonomyOption[];
  }) => void;
};

const FiltersModal = ({ isOpen, setIsOpen, eventSelector, filters, setFilters }: FiltersModalProps) => {
  const taxonomyOptions = useSelector(selectTaxonomyOptions);
  const [attendance, setAttendance] = useState<0 | 1 | 2 | 3>(filters.attendance);
  const [status, setStatus] = useState<0 | 1 | 2 | 3 | 4>(filters.status);
  const [selectedTaxonomies, setSelectedTaxonomies] = useState<TaxonomyOption[]>(filters.selectedTaxonomies);
  const [isHover, setIsHover] = useState(false);
  const filtersModalRef = useRef(null);
  useOutsideClick(
    filtersModalRef,
    () => {
      if (isOpen) {
        setIsOpen(false);
        // clearState();
      }
    },
    'filters',
  );

  const toggleItem = (taxonomyOption: TaxonomyOption) => {
    const temp = selectedTaxonomies.slice();
    const index = temp.findIndex((i) => i?.id === taxonomyOption.id);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(taxonomyOption);
    }
    setSelectedTaxonomies(temp);
  };

  return (
    <div ref={filtersModalRef} className={styles.wrapper + (isOpen && ' ' + styles.show)}>
      <div className={styles.flex}>
        <div className={styles.flex}>
          <Icon name={'filters'} color={'#281B24'} size={'sPlus'} />
          <h3 className={'fridayH3'}>Add Filters</h3>
        </div>
        <button
          className={'btn btnSecondary btnSecondaryWithImg'}
          style={{ width: 'fit-content', padding: 0 }}
          onClick={() => {
            // clearState();
            // setIsOpen(false);
          }}
        >
          <Icon name={'trash'} size={'s'} color={'#281B24'} />
          Clear All Filters
        </button>
      </div>
      <hr className={styles.hr} />
      <div className={styles.flexColumn} style={{ gap: 16 }}>
        <div className={styles.flex}>
          <div className={styles.attendance + (eventSelector === 'all' ? ' ' + styles.attendanceDisabled : '')}>
            <h4 className={'didacticH4'}>Attendance</h4>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                disabled={eventSelector === 'all'}
                value={attendance === 3}
                onClick={() => eventSelector !== 'all' && setAttendance((prevState) => (prevState === 3 ? 0 : 3))}
                light={true}
              />
              <p className={'didacticP'}>Attending</p>
            </div>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                value={attendance === 2}
                onClick={() => eventSelector !== 'all' && setAttendance((prevState) => (prevState === 2 ? 0 : 2))}
                light={true}
              />
              <p className={'didacticP'}>Not Attending</p>
            </div>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                value={attendance === 1}
                onClick={() => eventSelector !== 'all' && setAttendance((prevState) => (prevState === 1 ? 0 : 1))}
                light={true}
              />
              <p className={'didacticP'}>Not Responding</p>
            </div>
          </div>
          <div className={styles.status}>
            <h4 className={'didacticH4'}>Status</h4>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                value={status === 4}
                onClick={() => setStatus((prevState) => (prevState === 4 ? 0 : 4))}
                light={true}
              />
              <p className={'didacticP'}>RSVP Completed</p>
            </div>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                value={status === 3}
                onClick={() => setStatus((prevState) => (prevState === 3 ? 0 : 3))}
                light={true}
              />
              <p className={'didacticP'}>Save the Date Sent</p>
            </div>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                value={status === 2}
                onClick={() => setStatus((prevState) => (prevState === 2 ? 0 : 2))}
                light={true}
              />
              <p className={'didacticP'}>Invitation Sent</p>
            </div>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                value={status === 1}
                onClick={() => setStatus((prevState) => (prevState === 1 ? 0 : 1))}
                light={true}
              />
              <p className={'didacticP'}>Not Invited</p>
            </div>
          </div>
        </div>
        <div className={styles.tags}>
          <h4 className={'didacticH4'}>Tags</h4>
          <div className={styles.flex} style={{ justifyContent: 'unset' }}>
            {taxonomyOptions.map((t) => {
              const isSelected = selectedTaxonomies.findIndex((i) => i.id === t.id) > -1;
              return (
                <Fragment key={t.id}>
                  <Taxonomy
                    taxonomyText={t.key}
                    isSelected={isSelected}
                    isSelectable={true}
                    selectedCallback={() => toggleItem(t)}
                  />
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <button
        className={'btn btnMain btnMainWithImg'}
        style={{ marginTop: 'auto' }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          setFilters({ attendance, status, selectedTaxonomies });
          // setIsOpen(false);
        }}
      >
        <Icon name={'accept'} size={'s'} color={isHover ? '#ffffff' : '#63435B'} />
        Filter Guests
      </button>
    </div>
  );
};

export default FiltersModal;
