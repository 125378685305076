import React, { useEffect, useState } from 'react';
import { google, outlook, yahoo, ics } from 'calendar-link';
import styles from './DownloadICS.module.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Icon } from '../../../shared';

const DownloadICS = (): JSX.Element => {
  const [event, setEvent] = useState<{ title: any; description: any; start: any; end: any; location: any } | undefined>(
    undefined,
  );
  const [microsoftHover, setMicrosoftHover] = useState<boolean>(false);
  const [yahooHover, setYahooHover] = useState<boolean>(false);
  const [googleHover, setGoogleHover] = useState<boolean>(false);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('title') && query.get('startdate') && query.get('enddate')) {
      setEvent({
        title: b64DecodeUnicode(query.get('title') || ''),
        description: b64DecodeUnicode(query.get('desc') || ''),
        start: atob(query.get('startdate') || ''),
        end: atob(query.get('enddate') || ''),
        location: b64DecodeUnicode(query.get('location')?.replaceAll(' ', '+') || ''),
      });
    }
  }, []);

  function b64DecodeUnicode(str: string) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
  }

  return (
    <div className={styles.downloadICSPageWrapper}>
      <nav role="presentation" style={{ marginBottom: '24px' }}>
        <img width={'250'} src="/logo/logo-full-colored.svg" alt="whiteclover logo" />
      </nav>
      {event && (
        <div className={styles.card}>
          <div>
            <div>
              <span className={`fridayH3 ${styles.dateTitles}`} style={{ fontSize: '50.5px' }}>
                {dayjs(event.start).format('DD MM')}
              </span>
              <span className={`fridayH3 ${styles.dateTitles}`} style={{ fontSize: '50.5px' }}>
                {dayjs(event.start).format('YYYY')}
              </span>
            </div>

            <svg width="2" height="112" viewBox="0 0 2 112" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 0L1 112" stroke="black" />
            </svg>

            <div>
              <div className={`fridayH3 ${styles.dateTitles}`} style={{ fontSize: '50.5px' }}>
                Save
              </div>
              <div className={`fridayH3 ${styles.dateTitles}`} style={{ fontSize: '50.5px' }}>
                our date
              </div>
            </div>
          </div>
          <div>
            <div className="didacticH3" style={{ textAlign: 'center' }}>
              {event.title}
            </div>
            <div className="didacticH3" style={{ textAlign: 'center' }}>
              You have been invited to our event. Please save this date.
            </div>
          </div>
          <div>
            <div className="didacticH4" style={{ color: '#5a5a5a' }}>
              {event.location}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <div>Add to your calendar</div>
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
              <a
                href={`${google(event)}`}
                target="_blank"
                rel="noreferrer"
                className="btnMain"
                style={{
                  borderRadius: '8px',
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '12px 21px',
                  color: !googleHover ? '#63435B' : '#fff',
                }}
                onTouchStart={() => setGoogleHover(true)}
                onMouseEnter={() => setGoogleHover(true)}
                onTouchEnd={() => setGoogleHover(false)}
                onMouseLeave={() => setGoogleHover(false)}
              >
                <Icon name="google" color={!googleHover ? '#63435B' : '#fff'} size="s" />
                Google
              </a>
              <a
                href={`${outlook(event)}`}
                target="_blank"
                rel="noreferrer"
                className="btnMain"
                style={{
                  borderRadius: '8px',
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '12px 21px',
                  color: !microsoftHover ? '#63435B' : '#fff',
                }}
                onTouchStart={() => setMicrosoftHover(true)}
                onMouseEnter={() => setMicrosoftHover(true)}
                onTouchEnd={() => setMicrosoftHover(false)}
                onMouseLeave={() => setMicrosoftHover(false)}
              >
                <Icon name="microsoft" color={!microsoftHover ? '#63435B' : '#fff'} size="s" />
                Outlook
              </a>
              <a
                href={`${yahoo(event)}`}
                target="_blank"
                rel="noreferrer"
                className="btnMain"
                style={{
                  borderRadius: '8px',
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '12px 21px',
                  color: !yahooHover ? '#63435B' : '#fff',
                }}
                onTouchStart={() => setYahooHover(true)}
                onMouseEnter={() => setYahooHover(true)}
                onTouchEnd={() => setYahooHover(false)}
                onMouseLeave={() => setYahooHover(false)}
              >
                <Icon name="yahoo" color={!yahooHover ? '#63435B' : '#fff'} size="s" />
                Yahoo
              </a>
            </div>
            <div>or</div>
            <a
              href={`${ics(event)}`}
              target="_self"
              rel="noreferrer"
              className="btnSecondary btnBordered"
              style={{
                borderRadius: '50px',
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 16px',
                color: '#63435B',
              }}
            >
              <Icon name="calendar" color="#63435B" size="s" />
              Download .ics file
            </a>
            {/* <ICalendarLink {...{ event, className: 'calendar', filename: event?.title }}></ICalendarLink> */}
          </div>
          <div style={{ color: '#5a5a5a' }}>
            An ICS file is a calendar file saved in a universal calendar format used by several email and calendar
            programs, including Microsoft Outlook, Google Calendar, and Apple Calendar.
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadICS;
