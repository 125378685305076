import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';

type InputComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

const InputImYoursAppPromoteComponent = ({
  // componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: // autoSave,
InputComponentProps): JSX.Element => {
  const emptyComponentValues = {
    marriageCode: '',
  };
  const wedding = useSelector(selectWedding);

  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  }, []);

  useEffect(() => {
    if (wedding.path)
      updateProps('https://app.whiteclover.gr/?code=' + wedding.path, 'ImYoursAppComponentCustomMarriageCode');
  }, [wedding]);

  const updateProps = (input: any, key: string) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      tempProps[componentIndex][key] = input;
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex][key] = input;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  if (!componentProps[componentIndex]) {
    return <></>;
  }
  return <section style={{ display: 'block' }}></section>;
};

export default InputImYoursAppPromoteComponent;
