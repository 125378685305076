/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { AllowedVisitor, TaxonomyOption } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { useEffect } from 'react';

import AutocompleteTaxonomy from '../autocompleteTaxonomy/AutocompleteTaxonomy';
import { useDispatch, useSelector } from 'react-redux';
import {
  addInvitedVisitors,
  selectTaxonomyOptions,
  setInvitedVisitors,
} from '../../../../app/redux/wedding.slice.recuder';
import styles from './TaxonomyMultipleSelect.module.scss';

export default function TaxonomyMultipleSelect({
  weddingId,
  userToken,
  guest,
  pageRefresh,
  maxHeight,
}: {
  weddingId: string;
  userToken: string;
  guest: AllowedVisitor;
  pageRefresh?: () => void;
  maxHeight?: number;
}): JSX.Element {
  const storedTaxonomyOptions = useSelector(selectTaxonomyOptions);
  const dispatch = useDispatch();

  const [selectedTags, setSelectedTags] = React.useState<Array<TaxonomyOption>>(guest?.taxonomyOptions);

  useEffect(() => {
    // console.log('selectedTags', selectedTags);
    if (guest?.taxonomyOptions) {
      setSelectedTags(guest?.taxonomyOptions);
    }
  }, [guest]);

  const updateVisitorHelper = async (value: TaxonomyOption[]) => {
    const taxonomyIds: string[] = storedTaxonomyOptions
      .map((tax: TaxonomyOption) => tax.id)
      .filter((option: string) => !value.map((tax) => tax.id).includes(option));
    try {
      await BrainHelper.attachTaxonomyOptionsToAllowedVisitor(
        value.map((tax) => tax.id),
        guest.id,
        userToken,
        weddingId,
        dispatch,
        addInvitedVisitors,
      );
    } catch (e) {
      console.log(e);
    }
    try {
      await BrainHelper.detachTaxonomyOptionsFromAllowedVisitors(
        userToken,
        weddingId,
        guest.id,
        taxonomyIds,
        dispatch,
        addInvitedVisitors,
      );
    } catch (e) {
      console.log(e);
    }
    // if (pageRefresh) pageRefresh();
  };

  return (
    <div className={styles.taxonomyWrapper}>
      <AutocompleteTaxonomy
        selected={selectedTags}
        onCreate={(newTaxonmy: TaxonomyOption) => {
          updateVisitorHelper([...selectedTags, newTaxonmy]);
        }}
        callback={updateVisitorHelper}
        maxHeight={maxHeight}
      />
    </div>
  );
}
