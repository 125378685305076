// import Logger from 'logdna';

// const log = Logger.createLogger('5b8daaeb6fa0bc407ca4294e24ba53c7', {
//   hostname: 'app.whiteclover',
//   app: 'app.whiteclover',
//   index_meta: true,
// });

// const log = console;

import { AuthHelper } from '../pkg/apiHelpers/authHelper';
import { LogLevel, PublicRemoteLoggerRequest } from '../pkg/protobuf/v2/auth/auth_types_pb';
import { Struct } from '@bufbuild/protobuf';

interface MessageParams {
  message: any;
  functionName?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface LogParams {
  message: any;
  params: any;
}

export class LoggerClass {
  async debug({ message, functionName }: MessageParams): Promise<void> {
    const messageJsonStr = toJsonString(message);
    if (process.env.NODE_ENV == 'development') {
      if (functionName) console.log(`functionName:${functionName}`);
      console.log(messageJsonStr);
    }
    AuthHelper.publicRemoteLog(
      new PublicRemoteLoggerRequest({
        message: new Struct().fromJsonString(messageJsonStr),
        functionName: functionName,
        logLevel: LogLevel.LogLevelDebug,
      }),
    );
  }

  async info({ message, functionName }: MessageParams): Promise<void> {
    const messageJsonStr = toJsonString(message);
    if (process.env.NODE_ENV == 'development') {
      if (functionName) console.log(`functionName:${functionName}`);
      console.log(messageJsonStr);
    }
    AuthHelper.publicRemoteLog(
      new PublicRemoteLoggerRequest({
        message: new Struct().fromJsonString(messageJsonStr),
        functionName: functionName,
        logLevel: LogLevel.LogLevelInfo,
      }),
    );
  }

  async warn({ message, functionName }: MessageParams): Promise<void> {
    const messageJsonStr = toJsonString(message);
    if (process.env.NODE_ENV == 'development') {
      if (functionName) console.log(`functionName:${functionName}`);
      console.log(messageJsonStr);
    }
    AuthHelper.publicRemoteLog(
      new PublicRemoteLoggerRequest({
        message: new Struct().fromJsonString(messageJsonStr),
        functionName: functionName,
        logLevel: LogLevel.LogLevelWarn,
      }),
    );
  }

  async error({ message, functionName }: MessageParams): Promise<void> {
    const messageJsonStr = toJsonString(message);
    if (process.env.NODE_ENV == 'development') {
      if (functionName) console.log(`functionName:${functionName}`);
      console.log(messageJsonStr);
    }
    AuthHelper.publicRemoteLog(
      new PublicRemoteLoggerRequest({
        message: new Struct().fromJsonString(messageJsonStr),
        functionName: functionName,
        logLevel: LogLevel.LogLevelError,
      }),
    );
  }

  //params can be simple string for logging level or a json {level: 'WARN', app: 'APPNAME'}
  async log({ message, functionName }: MessageParams): Promise<void> {
    const messageJsonStr = toJsonString(message);
    if (process.env.NODE_ENV == 'development') {
      if (functionName) console.log(`functionName:${functionName}`);
      console.log(messageJsonStr);
    }
    AuthHelper.publicRemoteLog(
      new PublicRemoteLoggerRequest({
        message: new Struct().fromJsonString(messageJsonStr),
        functionName: functionName,
        logLevel: LogLevel.LogLevelInfo,
      }),
    );
  }
}

export const logger = new LoggerClass();

// export default { log };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const printStackTrace = (error: Error) => {
  const stackTemp = error?.stack;
  if (stackTemp) {
    const stack = stackTemp
      .split('\n')
      .slice(2)
      .map((line) => line.replace(/\s+at\s+/, ''))
      .join('\n');
    if (process.env.NODE_ENV == 'development') console.log(stack);

    AuthHelper.publicRemoteLog(
      new PublicRemoteLoggerRequest({
        message: new Struct().fromJsonString(toJsonString(stack)),
        functionName: '',
        logLevel: LogLevel.LogLevelError,
      }),
    );
  } else {
    AuthHelper.publicRemoteLog(
      new PublicRemoteLoggerRequest({
        message: new Struct().fromJsonString(toJsonString('No available stack trace')),
        functionName: '',
        logLevel: LogLevel.LogLevelError,
      }),
    );
  }
};

export const toJsonString = (message: any): string => {
  let value: string;
  try {
    if (typeof message === 'object') {
      value = JSON.stringify(message, (_, v) => (typeof v === 'bigint' ? v.toString() : v));
    } else {
      value = `{ "message": "${message}" }`;
    }
  } catch (e) {
    value = `{ "message": "${message}" }`;
  }
  return value;
};
