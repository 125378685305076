import { useEffect, useState } from 'react';
import styles from './OverviewEventModal.module.scss';
import {
  AllowedVisitor,
  Question,
  RSVPEvent,
  TotalAnswersResponse,
  TotalAttendanceResponse,
} from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { dateTimeFormat } from '../../../../config/dateFormats';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { useSelector } from 'react-redux';
import { selectJwt } from '../../../../app/redux/authorization.slice.reducer';
import { selectWedding } from '../../../../app/redux/wedding.slice.recuder';
import Icon from '../../../../shared/Icon';
import { Avatar } from '../../table/Table';
import { formatLocation } from '../../../../shared/Functions/location';
import { useTypeOfScreen } from '../../../../hooks';

const OverviewEventModal = (props: {
  rsvpEvent: RSVPEvent;
  setSelectedRsvpEvent: any;
  questions: Question[];
  answers: Map<string, TotalAnswersResponse | undefined>;
  showModal: boolean;
  setShowModal: any;
  color: string;
  totalAttendance: TotalAttendanceResponse | undefined;
  navigate: any;
}): JSX.Element => {
  const typeOfScreen = useTypeOfScreen();
  const userToken = useSelector(selectJwt);
  const MINTOSHOW = 5;
  const [rsvpEvent, setRsvpEvent] = useState<RSVPEvent | undefined>();
  const [visitorsToShow, setVisitorsToShow] = useState<number>(MINTOSHOW);
  const [visitorsToShowForDetailsPlusOnes, setVisitorsToShowForDetailsPlusOnes] = useState<number>(MINTOSHOW);
  const [invited, setInvited] = useState<AllowedVisitor[]>([]);
  const [invitedForDetailsPlusOnes, setInvitedForDetailsPlusOnes] = useState<AllowedVisitor[]>([]);
  const [totalPlusOnesInvited, setTotalPlusOnesInvited] = useState<number>(0);
  const [totalInvited, setTotalInvited] = useState<number>(0);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const wedding = useSelector(selectWedding);

  useEffect(() => {
    if (!rsvpEvent || rsvpEvent.id !== props.rsvpEvent.id) {
      resetInvited();
    }
    if (props.rsvpEvent) {
      listInvitedUsers(props.rsvpEvent.id, 0, true, true);
    }
  }, [props.rsvpEvent]);

  const resetInvited = () => {
    setRsvpEvent(props.rsvpEvent);
    setVisitorsToShow(MINTOSHOW);
    setVisitorsToShowForDetailsPlusOnes(MINTOSHOW);
    setInvited([]);
    setInvitedForDetailsPlusOnes([]);
  };

  async function listInvitedUsers(rsvpId: string, page: number, fillFirst?: boolean, fillSecond?: boolean) {
    const invitedList = await BrainHelper.getAllowedVisitors(
      userToken,
      '',
      '',
      undefined,
      false,
      rsvpId,
      page * MINTOSHOW,
      MINTOSHOW,
    );
    if (fillFirst) setInvited((prev) => [...prev, ...invitedList.allowedVisitors]);
    if (fillSecond) setInvitedForDetailsPlusOnes((prev) => [...prev, ...invitedList.allowedVisitors]);
    setTotalInvited(Number(invitedList.total));
    const totalPlusOnesInvited = invitedList.allowedVisitors.reduce((accumulator, value) => {
      return accumulator + Number(value.maxAllowedPlus1);
    }, 0);
    setTotalPlusOnesInvited(totalPlusOnesInvited);
  }

  return (
    <div
      className={
        props.showModal
          ? styles.overviewEventWrapper + ' ' + styles[`overviewEventWrapper${typeOfScreen}`]
          : styles.hidden
      }
    >
      {!showDetails ? (
        <div>
          <div className={styles.overviewEventModal}>
            <div className={styles.header}>
              <div>
                <div
                  className={styles.eventIcon}
                  style={{
                    backgroundColor: props.color || '#6C7699',
                  }}
                >
                  <img src="/assets/icons/icon-calendar-white.svg" alt="calendar" />
                </div>
                <h3 className={'didacticH3'} style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
                  {props.rsvpEvent.name}
                </h3>
              </div>
              <button
                style={{ border: 'none', background: 'none' }}
                onClick={() => {
                  if (props.setShowModal) props.setShowModal(false);
                  if (props.setSelectedRsvpEvent) props.setSelectedRsvpEvent(undefined);
                }}
              >
                <img src="/assets/icons/icon-cancel.svg" alt="exit" width={32} />
              </button>
            </div>
            {!isNaN(new Date(props.rsvpEvent.startTimestamp).getTime()) &&
            !isNaN(new Date(props.rsvpEvent.endTimestamp).getTime()) ? (
              <p className={'didacticP'}>{`${dateTimeFormat({
                timeZone: props.rsvpEvent.location?.externalQuickCode,
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(props.rsvpEvent.startTimestamp))} - ${dateTimeFormat({
                timeZone: props.rsvpEvent.location?.externalQuickCode,
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(props.rsvpEvent.endTimestamp))}`}</p>
            ) : (
              ''
            )}
            <div className={styles.flexRow}>
              <img
                src={'/assets/icons/icon-calendar-check' + (props.rsvpEvent.isPrivate ? '-private' : '') + '.svg'}
                alt="calendar check"
              />
              <p className={'didacticP ' + (props.rsvpEvent.isPrivate ? styles.private : styles.public)}>
                {props.rsvpEvent.isPrivate ? 'Private' : 'Public'} event
              </p>
            </div>
            <div className={styles.flexRow}>
              <img src="/rsvp/map.svg" alt="map" />
              <a
                className={'didacticP ' + styles.highlighted}
                style={{
                  wordWrap: 'break-word',
                  width: '250px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                target="_blank"
                href={`https://maps.google.com/?q=${props.rsvpEvent?.location?.point?.latitude},${props.rsvpEvent?.location?.point?.longitude}`}
                rel="noreferrer"
              >
                {props.rsvpEvent.location && formatLocation(props.rsvpEvent.location)}
              </a>
            </div>
            <p className={'didacticP'} style={{ wordWrap: 'break-word' }}>
              {props.rsvpEvent.description}
            </p>
            <button
              className={'btn btnMain btnMainWithImg'}
              onClick={() => props.navigate(`/dashboard/w/${wedding.path}/planning?eventId=${props.rsvpEvent.name}`)}
              onMouseEnter={() => setIsActive(true)}
              onMouseLeave={() => setIsActive(false)}
            >
              <Icon name={'edit'} size={'s'} color={isActive ? '#FFFFFF' : '#63435B'} />
              Edit Event
            </button>
            <div className={styles.invitedGuests}>
              <p>Invited Guests</p>
              <div className={styles.invited}>
                {invited?.map((v, index) => {
                  const attending =
                    v.attendToRsvpEvents.filter((a) => a.rsvpEvent?.id === props.rsvpEvent.id && a.attend).length > 0;
                  const notAttending =
                    v.attendToRsvpEvents.filter((a) => a.rsvpEvent?.id === props.rsvpEvent.id && !a.attend).length > 0;
                  return (
                    <div key={v.id + index}>
                      <div>
                        <Avatar
                          name={`${v.firstName} ${v.lastName}`}
                          userToken={String(userToken)}
                          connectedAccountDetails={v.connectedAccountDetails}
                          query={''}
                          _id={'overviewModalAvatar' + v.id}
                        />
                        <p className={'didacticP'}>{v.firstName + ' ' + v.lastName}</p>
                      </div>
                      <p
                        className={'didacticP'}
                        style={{ color: attending ? '#00C9A5' : notAttending ? '#D54F68' : '#A9A4A7' }}
                      >
                        {attending ? 'Attending' : notAttending ? 'Not Attending' : 'Not RSVPed yet'}
                      </p>
                    </div>
                  );
                })}
              </div>
              <button
                className={'btn btnSecondary btnSmall'}
                onClick={() => {
                  if (totalInvited <= visitorsToShow) {
                    setInvited([]);
                  }
                  listInvitedUsers(
                    props.rsvpEvent.id,
                    (totalInvited >= visitorsToShow ? visitorsToShow + MINTOSHOW : MINTOSHOW) / MINTOSHOW - 1,
                    true,
                  );
                  setVisitorsToShow(totalInvited >= visitorsToShow ? visitorsToShow + MINTOSHOW : MINTOSHOW);
                }}
              >
                {totalInvited === 0 || totalInvited == visitorsToShow ? (
                  ''
                ) : (
                  <div>
                    {totalInvited > visitorsToShow ? (
                      <p>+ {totalInvited - visitorsToShow + ' more'}</p>
                    ) : (
                      visitorsToShow > MINTOSHOW && '- Show less'
                    )}
                  </div>
                )}
              </button>
            </div>
            <div className={styles.eventQuestions}>
              <div className={styles.header}>
                <p className={'didacticP'}>RSVP Results</p>
                <div
                  role={'button'}
                  tabIndex={0}
                  className={styles.imgWrapper}
                  onMouseDown={(e) => {
                    if (e.button === 2) return;
                    resetInvited();
                    listInvitedUsers(props.rsvpEvent.id, 0, true, true);
                    setShowDetails(true);
                    setVisitorsToShow(MINTOSHOW);
                  }}
                >
                  <img
                    src="/assets/icons/icon-people-poll.svg"
                    style={{ backgroundColor: '#F9AC80' }}
                    alt="poll"
                    width={16}
                    height={16}
                  />
                </div>
              </div>
              <div className={styles.questions}>
                <div className={styles.flexRow}>
                  <h4 className={'didacticH4'} style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
                    Will you be joining the {props.rsvpEvent.name}?
                  </h4>
                  <div className={styles.flexRow}>
                    <h4
                      className={'didacticH4'}
                      style={{ color: Number(props.totalAttendance?.attendingCount) > 0 ? '#00C9A5' : '#A9A4A7' }}
                    >
                      {Number(props.totalAttendance?.attendingCount || 0)}
                    </h4>
                    <h4
                      className={'didacticH4'}
                      style={{ color: Number(props.totalAttendance?.notAttendingCount) > 0 ? '#D54F68' : '#A9A4A7' }}
                    >
                      {Number(props.totalAttendance?.notAttendingCount || 0)}
                    </h4>
                    {/*<h4 className={'didacticH4'} style={{ color: '#A9A4A7' }}>*/}
                    {/*  {totalInvited -*/}
                    {/*    Number(props.totalAttendance?.attendingCount) -*/}
                    {/*    Number(props.totalAttendance?.notAttendingCount)}*/}
                    {/*</h4>*/}
                  </div>
                </div>
                <div className={styles.flexRow}>
                  <h4 className={'didacticH4'}>How many plus ones?</h4>
                  <div className={styles.flexRow}>
                    <h4
                      className={'didacticH4'}
                      style={{ color: Number(props.totalAttendance?.plus1Sum) > 0 ? '#00C9A5' : '#A9A4A7' }}
                    >
                      {Number(props.totalAttendance?.plus1Sum || 0)}
                    </h4>
                  </div>
                </div>
                {props.questions?.map((question) => {
                  return (
                    <div key={question.id} className={styles.flexRow}>
                      <h4 className={'didacticH4'}>{question.question}</h4>
                      <div className={styles.flexRow}>
                        <h4
                          className={'didacticH4'}
                          style={{ color: Number(props.answers.get(question.id)?.count) > 0 ? '#00C9A5' : '#A9A4A7' }}
                        >
                          {Number(props.answers.get(question.id)?.count || 0)}
                        </h4>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.overviewEventModal}>
            <div className={styles.header}>
              <div>
                <div
                  className={styles.eventIcon}
                  style={{
                    backgroundColor: props.color || '#6C7699',
                  }}
                >
                  <img src="/assets/icons/icon-calendar-white.svg" alt="calendar" />
                </div>
                <div>
                  <h3 className={'didacticH3'}>{props.rsvpEvent.name}</h3>
                  <p>RSVP Results</p>
                </div>
              </div>
              <button
                style={{ border: 'none', background: 'none' }}
                onClick={() => {
                  setShowDetails(false);
                  resetInvited();
                  listInvitedUsers(props.rsvpEvent.id, 0, true, true);
                }}
              >
                <img src="/assets/icons/icon-arrow-small-left.svg" alt="left" width={32} />
              </button>
            </div>
            <div className={styles.invitedGuests}>
              <div className={styles.flexRow} style={{ justifyContent: 'space-between' }}>
                <p>Will you be joining the {props.rsvpEvent.name}?</p>
                <p>
                  {Number(props.totalAttendance?.attendingCount || 0) + Number(props.totalAttendance?.plus1Sum || 0)}/
                  {totalInvited + totalPlusOnesInvited}
                </p>
              </div>
              <div className={styles.invited}>
                {invited.map((i) => {
                  const attending =
                    i.attendToRsvpEvents.filter((a) => a.rsvpEvent?.id === props.rsvpEvent.id && a.attend).length > 0;
                  const notAttending =
                    i.attendToRsvpEvents.filter((a) => a.rsvpEvent?.id === props.rsvpEvent.id && !a.attend).length > 0;
                  return (
                    <div key={i.id} className={styles.flexRow}>
                      <div>
                        <Avatar
                          name={`${i.firstName} ${i.lastName}`}
                          userToken={String(userToken)}
                          connectedAccountDetails={i.connectedAccountDetails}
                          query={''}
                          _id={'overviewModalAvatarAttending' + i.id}
                        />
                        <p className={'didacticP'}>{i.firstName + ' ' + i.lastName}</p>
                      </div>
                      <p
                        className={'didacticP'}
                        style={{ color: attending ? '#00C9A5' : notAttending ? '#D54F68' : '#A9A4A7' }}
                      >
                        {attending ? 'Attending' : notAttending ? 'Not Attending' : 'Not RSVPed yet'}
                      </p>
                    </div>
                  );
                })}
              </div>
              <button
                className={'btn btnSecondary btnSmall'}
                onClick={() => {
                  if (totalInvited <= visitorsToShow) {
                    setInvited([]);
                  }
                  listInvitedUsers(
                    props.rsvpEvent.id,
                    (totalInvited >= visitorsToShow ? visitorsToShow + MINTOSHOW : MINTOSHOW) / MINTOSHOW - 1,
                    true,
                    false,
                  );
                  setVisitorsToShow(totalInvited >= visitorsToShow ? visitorsToShow + MINTOSHOW : MINTOSHOW);
                }}
              >
                {totalInvited === 0 || totalInvited == visitorsToShow ? (
                  ''
                ) : (
                  <div>
                    {totalInvited > visitorsToShow ? (
                      <p>+ {totalInvited - visitorsToShow + ' more'}</p>
                    ) : (
                      visitorsToShow > MINTOSHOW && '- Show less'
                    )}
                  </div>
                )}
              </button>
            </div>
            <div className={styles.invitedGuests}>
              <div className={styles.flexRow} style={{ justifyContent: 'space-between' }}>
                <p>How many plus ones</p>
                <p>
                  {Number(props.totalAttendance?.attendingCount || 0) + Number(props.totalAttendance?.plus1Sum || 0)}/
                  {totalInvited + totalPlusOnesInvited}
                </p>
              </div>
              <div className={styles.invited}>
                {invitedForDetailsPlusOnes.map((i) => {
                  return (
                    <div key={i.id} className={styles.flexRow}>
                      <div>
                        <Avatar
                          name={`${i.firstName} ${i.lastName}`}
                          userToken={String(userToken)}
                          connectedAccountDetails={i.connectedAccountDetails}
                          query={''}
                          _id={'overviewModalAvatarPlusOnes' + i.id}
                        />
                        <p className={'didacticP'}>{i.firstName + ' ' + i.lastName}</p>
                      </div>
                      <p className={'didacticP'}>
                        {Number(
                          i.attendToRsvpEvents.filter((a) => a.rsvpEvent?.id === props.rsvpEvent.id && a.attend)[0]
                            ?.plusOnes || 0,
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
              <button
                className={'btn btnSecondary btnSmall'}
                onClick={() => {
                  if (totalInvited <= visitorsToShowForDetailsPlusOnes) {
                    setInvitedForDetailsPlusOnes([]);
                  }
                  listInvitedUsers(
                    props.rsvpEvent.id,
                    (totalInvited >= visitorsToShowForDetailsPlusOnes
                      ? visitorsToShowForDetailsPlusOnes + MINTOSHOW
                      : MINTOSHOW) /
                      MINTOSHOW -
                      1,
                    false,
                    true,
                  );
                  setVisitorsToShowForDetailsPlusOnes(
                    totalInvited >= visitorsToShowForDetailsPlusOnes
                      ? visitorsToShowForDetailsPlusOnes + MINTOSHOW
                      : MINTOSHOW,
                  );
                }}
              >
                {totalInvited === 0 || totalInvited == visitorsToShowForDetailsPlusOnes ? (
                  ''
                ) : (
                  <div>
                    {totalInvited > visitorsToShowForDetailsPlusOnes ? (
                      <p>+ {totalInvited - visitorsToShowForDetailsPlusOnes + ' more'}</p>
                    ) : (
                      visitorsToShowForDetailsPlusOnes > MINTOSHOW && '- Show less'
                    )}
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverviewEventModal;
