import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectJwt } from '../../../../app/redux/authorization.slice.reducer';
import {
  addTaxonomyOptions,
  selectTaxonomyOptions,
  selectWedding,
  setTaxonomyOptions,
} from '../../../../app/redux/wedding.slice.recuder';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import styles from './TaxonomyListWithSelection.module.scss';
import Taxonomy from '../taxonomy/Taxonomy';
import Icon from '../../../../shared/Icon';
import { TaxonomyOption } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';

const TaxonomyListWithSelection = (props: {
  selected: Array<TaxonomyOption>;
  weddingId: string;
  callback?: any;
  wrap?: boolean;
  updateCallback?: any;
}): JSX.Element => {
  //Store
  const jwtToken = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);
  const storedTaxonomyOptions = useSelector(selectTaxonomyOptions);
  const dispatch = useDispatch();
  //States
  const [ListState, setListState] = useState<Array<TaxonomyOption>>([]);
  const [showAddNewTaxonomy, setShowAddNewTaxonomy] = useState(false);
  const [newTaxonomy, setNewTaxonomy] = useState<string>('');
  const toggleItem = (id: TaxonomyOption) => {
    let temp = props.selected.slice();
    const thirdTemp: Array<TaxonomyOption> = [];
    if (temp.includes(id)) {
      const secondTemp: Array<TaxonomyOption> = [];
      for (const tempElement of temp) {
        if (tempElement != id) {
          secondTemp.push(tempElement);
        } else thirdTemp.push(tempElement);
      }
      temp = secondTemp;
    } else {
      temp.push(id);
    }
    setNewTaxonomy('');
    props.callback(temp, thirdTemp);
  };

  const AddNewTag = () => {
    if (newTaxonomy.trim() !== '') {
      BrainHelper.createTaxonomyOption(
        wedding.defaultTaxonomyId,
        newTaxonomy,
        '',
        '',
        '',
        String(jwtToken),
        wedding.id,
        dispatch,
        addTaxonomyOptions,
      ).then((res: TaxonomyOption) => {
        setNewTaxonomy(''); //Clear the input field
        setShowAddNewTaxonomy(!showAddNewTaxonomy);
        toggleItem(Object(res));
      });
    } else {
      setShowAddNewTaxonomy(!showAddNewTaxonomy);
      setNewTaxonomy('');
    }
  };

  useEffect(() => {
    console.log('Selected:', props.selected);
  }, [props.selected]);

  const CancelAddingNewTag = () => {
    setShowAddNewTaxonomy(!showAddNewTaxonomy);
    setNewTaxonomy('');
  };

  const helper = async () => {
    if (storedTaxonomyOptions) {
      const res2 = storedTaxonomyOptions;
      setListState(res2);
    } else {
      const res2 = await BrainHelper.listTaxonomyOptions(
        wedding.defaultTaxonomyId,
        jwtToken,
        dispatch,
        setTaxonomyOptions,
      );
      if (res2) setListState(res2);
    }
  };

  useEffect(() => {
    helper();
  }, [props.selected, storedTaxonomyOptions]);

  return (
    <ul
      className={!props.wrap ? styles.taxonomyList : styles.taxonomyListWrap}
      style={{ overflowX: props.wrap ? 'hidden' : 'scroll' }}
    >
      <li>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
          {showAddNewTaxonomy ? (
            <div className={styles.addTagInput}>
              <input
                style={{ border: 'none' }}
                value={newTaxonomy}
                onChange={(e) => setNewTaxonomy(e.target.value)}
                onKeyUp={(e) => {
                  e.key === 'enter' && e.preventDefault();
                  e.key === 'enter' && AddNewTag();
                }}
              />
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: '4px',
                }}
                onClick={() => AddNewTag()}
              >
                <Icon name="accept" size="s" />
              </button>
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: '4px',
                }}
                onClick={() => CancelAddingNewTag()}
              >
                <Icon name="cancel" size="s" />
              </button>
            </div>
          ) : (
            <button
              className={styles.addTaxonomy}
              style={{ width: '120px' }}
              onClick={() => {
                setShowAddNewTaxonomy(!showAddNewTaxonomy);
              }}
            >
              <Icon name="plus" size="s" />
              <p>Create Tag</p>
            </button>
          )}
        </div>
      </li>
      {ListState.map((a: TaxonomyOption) => {
        return (
          <li key={a.id}>
            <Taxonomy
              taxonomyId={a.id}
              taxonomyText={a.key}
              taxonomyOptionId={a.id}
              isSelectable={true}
              selectedCallback={() => toggleItem(a)}
              isSelected={props.selected.includes(a)}
              deletable
              deleteCallback={() => {
                helper();
                props.updateCallback();
              }}
              weddingId={props.weddingId}
              userToken={jwtToken}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default TaxonomyListWithSelection;
