interface Baptism2PaletteComponentProps {
  Palette?: number;
}

const Baptism2PaletteComponent = ({ Palette }: Baptism2PaletteComponentProps): JSX.Element => {
  const colorPalette = Palette === 2 ? 'Girl' : Palette === 1 ? 'Boy' : 'Neutral';
  console.log(colorPalette);
  return <section id={`Baptism2PaletteComponent`}></section>;
};
export default Baptism2PaletteComponent;
