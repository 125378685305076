import { Fragment } from 'react';

const LayoutSelector = ({
  selectedLayout,
  setSelectedLayout,
}: {
  selectedLayout: number;
  setSelectedLayout: (num: number) => void;
}): JSX.Element => {
  return (
    <Fragment>
      <button
        style={{
          borderRadius: '10px',
          background: 'none',
          cursor: 'pointer',
          border: selectedLayout == 0 ? '1px solid #F78C50' : '1px solid #DAD8D9',
          width: '136px',
          height: '126px',
          margin: 4,
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => setSelectedLayout(0)}
      >
        <svg width="148" height="138" viewBox="0 0 148 138" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="16" y="33" width="56" height="72" fill="#DAD8D9" />
          <g clipPath="url(#clip0_4065_2278)">
            <path
              d="M48.6667 61H39.3333C38.4496 61.0011 37.6024 61.3526 36.9775 61.9775C36.3526 62.6024 36.0011 63.4496 36 64.3333V73.6667C36.0011 74.5504 36.3526 75.3976 36.9775 76.0225C37.6024 76.6474 38.4496 76.9989 39.3333 77H48.6667C49.5504 76.9989 50.3976 76.6474 51.0225 76.0225C51.6474 75.3976 51.9989 74.5504 52 73.6667V64.3333C51.9989 63.4496 51.6474 62.6024 51.0225 61.9775C50.3976 61.3526 49.5504 61.0011 48.6667 61ZM39.3333 62.3333H48.6667C49.1971 62.3333 49.7058 62.544 50.0809 62.9191C50.456 63.2942 50.6667 63.8029 50.6667 64.3333V73.6667C50.6655 73.9637 50.5971 74.2565 50.4667 74.5233L44.358 68.4147C44.0485 68.105 43.681 67.8594 43.2765 67.6919C42.872 67.5243 42.4385 67.438 42.0007 67.438C41.5629 67.438 41.1293 67.5243 40.7249 67.6919C40.3204 67.8594 39.9529 68.105 39.6433 68.4147L37.3333 70.724V64.3333C37.3333 63.8029 37.544 63.2942 37.9191 62.9191C38.2942 62.544 38.8029 62.3333 39.3333 62.3333Z"
              fill="#A9A4A7"
            />
            <path
              d="M46.6673 68C47.1288 68 47.5799 67.8632 47.9636 67.6068C48.3474 67.3504 48.6464 66.986 48.823 66.5596C48.9996 66.1332 49.0458 65.6641 48.9558 65.2115C48.8658 64.7588 48.6436 64.3431 48.3172 64.0168C47.9909 63.6904 47.5752 63.4682 47.1225 63.3782C46.6699 63.2881 46.2008 63.3344 45.7744 63.511C45.348 63.6876 44.9836 63.9866 44.7272 64.3703C44.4708 64.7541 44.334 65.2052 44.334 65.6667C44.334 66.2855 44.5798 66.879 45.0174 67.3166C45.455 67.7542 46.0485 68 46.6673 68Z"
              fill="#A9A4A7"
            />
          </g>
          <rect x="76" y="33" width="56" height="72" fill="#DAD8D9" />
          <g clipPath="url(#clip1_4065_2278)">
            <path
              d="M108.667 61H99.3333C98.4496 61.0011 97.6024 61.3526 96.9775 61.9775C96.3526 62.6024 96.0011 63.4496 96 64.3333V73.6667C96.0011 74.5504 96.3526 75.3976 96.9775 76.0225C97.6024 76.6474 98.4496 76.9989 99.3333 77H108.667C109.55 76.9989 110.398 76.6474 111.023 76.0225C111.647 75.3976 111.999 74.5504 112 73.6667V64.3333C111.999 63.4496 111.647 62.6024 111.023 61.9775C110.398 61.3526 109.55 61.0011 108.667 61ZM99.3333 62.3333H108.667C109.197 62.3333 109.706 62.544 110.081 62.9191C110.456 63.2942 110.667 63.8029 110.667 64.3333V73.6667C110.666 73.9637 110.597 74.2565 110.467 74.5233L104.358 68.4147C104.048 68.105 103.681 67.8594 103.276 67.6919C102.872 67.5243 102.438 67.438 102.001 67.438C101.563 67.438 101.129 67.5243 100.725 67.6919C100.32 67.8594 99.9529 68.105 99.6433 68.4147L97.3333 70.724V64.3333C97.3333 63.8029 97.544 63.2942 97.9191 62.9191C98.2942 62.544 98.8029 62.3333 99.3333 62.3333Z"
              fill="#A9A4A7"
            />
            <path
              d="M106.667 68C107.129 68 107.58 67.8632 107.964 67.6068C108.347 67.3504 108.646 66.986 108.823 66.5596C109 66.1332 109.046 65.6641 108.956 65.2115C108.866 64.7588 108.644 64.3431 108.317 64.0168C107.991 63.6904 107.575 63.4682 107.123 63.3782C106.67 63.2881 106.201 63.3344 105.774 63.511C105.348 63.6876 104.984 63.9866 104.727 64.3703C104.471 64.7541 104.334 65.2052 104.334 65.6667C104.334 66.2855 104.58 66.879 105.017 67.3166C105.455 67.7542 106.048 68 106.667 68Z"
              fill="#A9A4A7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4065_2278">
              <rect width="16" height="16" fill="white" transform="translate(36 61)" />
            </clipPath>
            <clipPath id="clip1_4065_2278">
              <rect width="16" height="16" fill="white" transform="translate(96 61)" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <button
        style={{
          borderRadius: '10px',
          background: 'none',
          cursor: 'pointer',
          border: selectedLayout == 1 ? '1px solid #F78C50' : '1px solid #DAD8D9',
          width: '136px',
          height: '126px',
          margin: 4,
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => setSelectedLayout(1)}
      >
        <svg width="148" height="138" viewBox="0 0 148 136" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="16" y="33" width="56" height="72" fill="#DAD8D9" />
          <g clipPath="url(#clip0_4065_2279)">
            <path
              d="M48.6667 61H39.3333C38.4496 61.0011 37.6024 61.3526 36.9775 61.9775C36.3526 62.6024 36.0011 63.4496 36 64.3333V73.6667C36.0011 74.5504 36.3526 75.3976 36.9775 76.0225C37.6024 76.6474 38.4496 76.9989 39.3333 77H48.6667C49.5504 76.9989 50.3976 76.6474 51.0225 76.0225C51.6474 75.3976 51.9989 74.5504 52 73.6667V64.3333C51.9989 63.4496 51.6474 62.6024 51.0225 61.9775C50.3976 61.3526 49.5504 61.0011 48.6667 61ZM39.3333 62.3333H48.6667C49.1971 62.3333 49.7058 62.544 50.0809 62.9191C50.456 63.2942 50.6667 63.8029 50.6667 64.3333V73.6667C50.6655 73.9637 50.5971 74.2565 50.4667 74.5233L44.358 68.4147C44.0485 68.105 43.681 67.8594 43.2765 67.6919C42.872 67.5243 42.4385 67.438 42.0007 67.438C41.5629 67.438 41.1293 67.5243 40.7249 67.6919C40.3204 67.8594 39.9529 68.105 39.6433 68.4147L37.3333 70.724V64.3333C37.3333 63.8029 37.544 63.2942 37.9191 62.9191C38.2942 62.544 38.8029 62.3333 39.3333 62.3333Z"
              fill="#A9A4A7"
            />
            <path
              d="M46.6673 68C47.1288 68 47.5799 67.8632 47.9636 67.6068C48.3474 67.3504 48.6464 66.986 48.823 66.5596C48.9996 66.1332 49.0458 65.6641 48.9558 65.2115C48.8658 64.7588 48.6436 64.3431 48.3172 64.0168C47.9909 63.6904 47.5752 63.4682 47.1225 63.3782C46.6699 63.2881 46.2008 63.3344 45.7744 63.511C45.348 63.6876 44.9836 63.9866 44.7272 64.3703C44.4708 64.7541 44.334 65.2052 44.334 65.6667C44.334 66.2855 44.5798 66.879 45.0174 67.3166C45.455 67.7542 46.0485 68 46.6673 68Z"
              fill="#A9A4A7"
            />
          </g>
          <rect x="76" y="33" width="56" height="34" fill="#DAD8D9" />
          <g clipPath="url(#clip1_4065_2279)">
            <path
              d="M107.5 44H100.5C99.8372 44.0008 99.2018 44.2644 98.7331 44.7331C98.2644 45.2018 98.0008 45.8372 98 46.5V53.5C98.0008 54.1628 98.2644 54.7982 98.7331 55.2669C99.2018 55.7356 99.8372 55.9992 100.5 56H107.5C108.163 55.9992 108.798 55.7356 109.267 55.2669C109.736 54.7982 109.999 54.1628 110 53.5V46.5C109.999 45.8372 109.736 45.2018 109.267 44.7331C108.798 44.2644 108.163 44.0008 107.5 44ZM100.5 45H107.5C107.898 45 108.279 45.158 108.561 45.4393C108.842 45.7206 109 46.1022 109 46.5V53.5C108.999 53.7227 108.948 53.9424 108.85 54.1425L104.268 49.561C104.036 49.3288 103.761 49.1446 103.457 49.0189C103.154 48.8932 102.829 48.8285 102.501 48.8285C102.172 48.8285 101.847 48.8932 101.544 49.0189C101.24 49.1446 100.965 49.3288 100.733 49.561L99 51.293V46.5C99 46.1022 99.158 45.7206 99.4393 45.4393C99.7206 45.158 100.102 45 100.5 45Z"
              fill="#A9A4A7"
            />
            <path
              d="M106 49.25C106.346 49.25 106.684 49.1474 106.972 48.9551C107.26 48.7628 107.484 48.4895 107.617 48.1697C107.749 47.8499 107.784 47.4981 107.716 47.1586C107.649 46.8191 107.482 46.5073 107.237 46.2626C106.993 46.0178 106.681 45.8512 106.341 45.7836C106.002 45.7161 105.65 45.7508 105.33 45.8832C105.011 46.0157 104.737 46.24 104.545 46.5278C104.353 46.8155 104.25 47.1539 104.25 47.5C104.25 47.9641 104.434 48.4092 104.763 48.7374C105.091 49.0656 105.536 49.25 106 49.25Z"
              fill="#A9A4A7"
            />
          </g>
          <rect x="76" y="71" width="56" height="34" fill="#DAD8D9" />
          <g clipPath="url(#clip2_4065_2279)">
            <path
              d="M107.5 82H100.5C99.8372 82.0008 99.2018 82.2644 98.7331 82.7331C98.2644 83.2018 98.0008 83.8372 98 84.5V91.5C98.0008 92.1628 98.2644 92.7982 98.7331 93.2669C99.2018 93.7356 99.8372 93.9992 100.5 94H107.5C108.163 93.9992 108.798 93.7356 109.267 93.2669C109.736 92.7982 109.999 92.1628 110 91.5V84.5C109.999 83.8372 109.736 83.2018 109.267 82.7331C108.798 82.2644 108.163 82.0008 107.5 82ZM100.5 83H107.5C107.898 83 108.279 83.158 108.561 83.4393C108.842 83.7206 109 84.1022 109 84.5V91.5C108.999 91.7227 108.948 91.9424 108.85 92.1425L104.268 87.561C104.036 87.3288 103.761 87.1446 103.457 87.0189C103.154 86.8932 102.829 86.8285 102.501 86.8285C102.172 86.8285 101.847 86.8932 101.544 87.0189C101.24 87.1446 100.965 87.3288 100.733 87.561L99 89.293V84.5C99 84.1022 99.158 83.7206 99.4393 83.4393C99.7206 83.158 100.102 83 100.5 83Z"
              fill="#A9A4A7"
            />
            <path
              d="M106 87.25C106.346 87.25 106.684 87.1474 106.972 86.9551C107.26 86.7628 107.484 86.4895 107.617 86.1697C107.749 85.8499 107.784 85.4981 107.716 85.1586C107.649 84.8191 107.482 84.5073 107.237 84.2626C106.993 84.0178 106.681 83.8512 106.341 83.7836C106.002 83.7161 105.65 83.7508 105.33 83.8832C105.011 84.0157 104.737 84.24 104.545 84.5278C104.353 84.8155 104.25 85.1539 104.25 85.5C104.25 85.9641 104.434 86.4092 104.763 86.7374C105.091 87.0656 105.536 87.25 106 87.25Z"
              fill="#A9A4A7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4065_2279">
              <rect width="16" height="16" fill="white" transform="translate(36 61)" />
            </clipPath>
            <clipPath id="clip1_4065_2279">
              <rect width="12" height="12" fill="white" transform="translate(98 44)" />
            </clipPath>
            <clipPath id="clip2_4065_2279">
              <rect width="12" height="12" fill="white" transform="translate(98 82)" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <button
        style={{
          borderRadius: '10px',
          background: 'none',
          cursor: 'pointer',
          border: selectedLayout == 2 ? '1px solid #F78C50' : '1px solid #DAD8D9',
          width: '136px',
          height: '126px',
          margin: 4,
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => setSelectedLayout(2)}
      >
        <svg width="148" height="140" viewBox="0 0 148 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="16" y="33" width="56" height="72" fill="#DAD8D9" />
          <g clipPath="url(#clip0_4065_2280)">
            <path
              d="M48.6667 61H39.3333C38.4496 61.0011 37.6024 61.3526 36.9775 61.9775C36.3526 62.6024 36.0011 63.4496 36 64.3333V73.6667C36.0011 74.5504 36.3526 75.3976 36.9775 76.0225C37.6024 76.6474 38.4496 76.9989 39.3333 77H48.6667C49.5504 76.9989 50.3976 76.6474 51.0225 76.0225C51.6474 75.3976 51.9989 74.5504 52 73.6667V64.3333C51.9989 63.4496 51.6474 62.6024 51.0225 61.9775C50.3976 61.3526 49.5504 61.0011 48.6667 61ZM39.3333 62.3333H48.6667C49.1971 62.3333 49.7058 62.544 50.0809 62.9191C50.456 63.2942 50.6667 63.8029 50.6667 64.3333V73.6667C50.6655 73.9637 50.5971 74.2565 50.4667 74.5233L44.358 68.4147C44.0485 68.105 43.681 67.8594 43.2765 67.6919C42.872 67.5243 42.4385 67.438 42.0007 67.438C41.5629 67.438 41.1293 67.5243 40.7249 67.6919C40.3204 67.8594 39.9529 68.105 39.6433 68.4147L37.3333 70.724V64.3333C37.3333 63.8029 37.544 63.2942 37.9191 62.9191C38.2942 62.544 38.8029 62.3333 39.3333 62.3333Z"
              fill="#A9A4A7"
            />
            <path
              d="M46.6673 68C47.1288 68 47.5799 67.8632 47.9636 67.6068C48.3474 67.3504 48.6464 66.986 48.823 66.5596C48.9996 66.1332 49.0458 65.6641 48.9558 65.2115C48.8658 64.7588 48.6436 64.3431 48.3172 64.0168C47.9909 63.6904 47.5752 63.4682 47.1225 63.3782C46.6699 63.2881 46.2008 63.3344 45.7744 63.511C45.348 63.6876 44.9836 63.9866 44.7272 64.3703C44.4708 64.7541 44.334 65.2052 44.334 65.6667C44.334 66.2855 44.5798 66.879 45.0174 67.3166C45.455 67.7542 46.0485 68 46.6673 68Z"
              fill="#A9A4A7"
            />
          </g>
          <rect x="76" y="33" width="56" height="34" fill="#DAD8D9" />
          <g clipPath="url(#clip1_4065_2280)">
            <path
              d="M107.5 44H100.5C99.8372 44.0008 99.2018 44.2644 98.7331 44.7331C98.2644 45.2018 98.0008 45.8372 98 46.5V53.5C98.0008 54.1628 98.2644 54.7982 98.7331 55.2669C99.2018 55.7356 99.8372 55.9992 100.5 56H107.5C108.163 55.9992 108.798 55.7356 109.267 55.2669C109.736 54.7982 109.999 54.1628 110 53.5V46.5C109.999 45.8372 109.736 45.2018 109.267 44.7331C108.798 44.2644 108.163 44.0008 107.5 44ZM100.5 45H107.5C107.898 45 108.279 45.158 108.561 45.4393C108.842 45.7206 109 46.1022 109 46.5V53.5C108.999 53.7227 108.948 53.9424 108.85 54.1425L104.268 49.561C104.036 49.3288 103.761 49.1446 103.457 49.0189C103.154 48.8932 102.829 48.8285 102.501 48.8285C102.172 48.8285 101.847 48.8932 101.544 49.0189C101.24 49.1446 100.965 49.3288 100.733 49.561L99 51.293V46.5C99 46.1022 99.158 45.7206 99.4393 45.4393C99.7206 45.158 100.102 45 100.5 45Z"
              fill="#A9A4A7"
            />
            <path
              d="M106 49.25C106.346 49.25 106.684 49.1474 106.972 48.9551C107.26 48.7628 107.484 48.4895 107.617 48.1697C107.749 47.8499 107.784 47.4981 107.716 47.1586C107.649 46.8191 107.482 46.5073 107.237 46.2626C106.993 46.0178 106.681 45.8512 106.341 45.7836C106.002 45.7161 105.65 45.7508 105.33 45.8832C105.011 46.0157 104.737 46.24 104.545 46.5278C104.353 46.8155 104.25 47.1539 104.25 47.5C104.25 47.9641 104.434 48.4092 104.763 48.7374C105.091 49.0656 105.536 49.25 106 49.25Z"
              fill="#A9A4A7"
            />
          </g>
          <rect x="76" y="71" width="26" height="34" fill="#DAD8D9" />
          <g clipPath="url(#clip2_4065_2280)">
            <path
              d="M92.5 82H85.5C84.8372 82.0008 84.2018 82.2644 83.7331 82.7331C83.2644 83.2018 83.0008 83.8372 83 84.5V91.5C83.0008 92.1628 83.2644 92.7982 83.7331 93.2669C84.2018 93.7356 84.8372 93.9992 85.5 94H92.5C93.1628 93.9992 93.7982 93.7356 94.2669 93.2669C94.7356 92.7982 94.9992 92.1628 95 91.5V84.5C94.9992 83.8372 94.7356 83.2018 94.2669 82.7331C93.7982 82.2644 93.1628 82.0008 92.5 82ZM85.5 83H92.5C92.8978 83 93.2794 83.158 93.5607 83.4393C93.842 83.7206 94 84.1022 94 84.5V91.5C93.9991 91.7227 93.9479 91.9424 93.85 92.1425L89.2685 87.561C89.0363 87.3288 88.7607 87.1446 88.4574 87.0189C88.154 86.8932 87.8289 86.8285 87.5005 86.8285C87.1721 86.8285 86.847 86.8932 86.5436 87.0189C86.2403 87.1446 85.9647 87.3288 85.7325 87.561L84 89.293V84.5C84 84.1022 84.158 83.7206 84.4393 83.4393C84.7206 83.158 85.1022 83 85.5 83Z"
              fill="#A9A4A7"
            />
            <path
              d="M91 87.25C91.3461 87.25 91.6845 87.1474 91.9722 86.9551C92.26 86.7628 92.4843 86.4895 92.6168 86.1697C92.7492 85.8499 92.7839 85.4981 92.7164 85.1586C92.6489 84.8191 92.4822 84.5073 92.2374 84.2626C91.9927 84.0178 91.6809 83.8512 91.3414 83.7836C91.0019 83.7161 90.6501 83.7508 90.3303 83.8832C90.0105 84.0157 89.7372 84.24 89.5449 84.5278C89.3526 84.8155 89.25 85.1539 89.25 85.5C89.25 85.9641 89.4344 86.4092 89.7626 86.7374C90.0908 87.0656 90.5359 87.25 91 87.25Z"
              fill="#A9A4A7"
            />
          </g>
          <rect x="106" y="71" width="26" height="34" fill="#DAD8D9" />
          <g clipPath="url(#clip3_4065_2280)">
            <path
              d="M122.5 82H115.5C114.837 82.0008 114.202 82.2644 113.733 82.7331C113.264 83.2018 113.001 83.8372 113 84.5V91.5C113.001 92.1628 113.264 92.7982 113.733 93.2669C114.202 93.7356 114.837 93.9992 115.5 94H122.5C123.163 93.9992 123.798 93.7356 124.267 93.2669C124.736 92.7982 124.999 92.1628 125 91.5V84.5C124.999 83.8372 124.736 83.2018 124.267 82.7331C123.798 82.2644 123.163 82.0008 122.5 82ZM115.5 83H122.5C122.898 83 123.279 83.158 123.561 83.4393C123.842 83.7206 124 84.1022 124 84.5V91.5C123.999 91.7227 123.948 91.9424 123.85 92.1425L119.268 87.561C119.036 87.3288 118.761 87.1446 118.457 87.0189C118.154 86.8932 117.829 86.8285 117.501 86.8285C117.172 86.8285 116.847 86.8932 116.544 87.0189C116.24 87.1446 115.965 87.3288 115.733 87.561L114 89.293V84.5C114 84.1022 114.158 83.7206 114.439 83.4393C114.721 83.158 115.102 83 115.5 83Z"
              fill="#A9A4A7"
            />
            <path
              d="M121 87.25C121.346 87.25 121.684 87.1474 121.972 86.9551C122.26 86.7628 122.484 86.4895 122.617 86.1697C122.749 85.8499 122.784 85.4981 122.716 85.1586C122.649 84.8191 122.482 84.5073 122.237 84.2626C121.993 84.0178 121.681 83.8512 121.341 83.7836C121.002 83.7161 120.65 83.7508 120.33 83.8832C120.011 84.0157 119.737 84.24 119.545 84.5278C119.353 84.8155 119.25 85.1539 119.25 85.5C119.25 85.9641 119.434 86.4092 119.763 86.7374C120.091 87.0656 120.536 87.25 121 87.25Z"
              fill="#A9A4A7"
            />
          </g>
          <defs>
            <clipPath id="clip0_4065_2280">
              <rect width="16" height="16" fill="white" transform="translate(36 61)" />
            </clipPath>
            <clipPath id="clip1_4065_2280">
              <rect width="12" height="12" fill="white" transform="translate(98 44)" />
            </clipPath>
            <clipPath id="clip2_4065_2280">
              <rect width="12" height="12" fill="white" transform="translate(83 82)" />
            </clipPath>
            <clipPath id="clip3_4065_2280">
              <rect width="12" height="12" fill="white" transform="translate(113 82)" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </Fragment>
  );
};

export default LayoutSelector;
