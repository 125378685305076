import React, { useEffect, useState } from 'react';
import styles from './CallMeByYourNameMainComponent.module.scss';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';

interface CallMeByYourNameMainComponentProps {
  CallMeByYourNameMainComponentTypographyLogo?: string;
  CallMeByYourNameMainComponentCoupleName?: string;
  CallMeByYourNameMainComponentMainImage?: string;
}

const defaultValues = {
  typographyLogo: '/templates/CallMeByYourName/mainComponentTypographyLogo.svg',
  mainImage: '/templates/CallMeByYourName/mainComponentMainImage.png',
  coupleName: 'Shophia&Lila',
};

const CallMeByYourNameMainComponent = ({
  CallMeByYourNameMainComponentTypographyLogo,
  CallMeByYourNameMainComponentCoupleName,
  CallMeByYourNameMainComponentMainImage,
}: CallMeByYourNameMainComponentProps): JSX.Element => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [blob, setBlob] = useState('');

  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob(result);
  };

  useEffect(() => {
    if (CallMeByYourNameMainComponentMainImage) {
      fetchPostImage(CallMeByYourNameMainComponentMainImage);
    }
  }, [CallMeByYourNameMainComponentMainImage]);

  return (
    <section
      id={`CallMeByYourNameMainComponent`}
      className={styles.CallMeByYourNameMainComponent}
      style={{
        width: '100%',
        backgroundImage: `url("${blob ? blob : defaultValues.mainImage}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
      }}
    >
      <div
        className={styles.flowers}
        style={{ position: 'relative', left: 'calc(-50% + 80px)', top: '30%', zIndex: 20 }}
      >
        <img src="/templates/CallMeByYourName/leftFlower.svg" alt="left flower" />
      </div>
      <div
        className={styles.flowers}
        style={{ position: 'relative', right: 'calc(-50% + 100px)', top: '3%', zIndex: 20 }}
      >
        <img src="/templates/CallMeByYourName/rightFlower.svg" alt="right flower" />
      </div>

      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          alignItems: 'center',
          top: '-75vh',
        }}
      >
        {
          <h4 className={styles.typographyLogo}>
            {CallMeByYourNameMainComponentCoupleName && CallMeByYourNameMainComponentCoupleName.length > 0
              ? CallMeByYourNameMainComponentCoupleName
              : defaultValues.coupleName}
          </h4>
        }
      </div>
    </section>
  );
};
export default CallMeByYourNameMainComponent;
