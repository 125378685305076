import { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';
import { selectRsvpEvents, selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { RSVPEvent, Wedding } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Icon } from '../../../shared';

const CustomToolbar = ({
  updateScheduleFromBE,
}: {
  callback: any;
  events: (RSVPEvent | undefined)[];
  updateScheduleFromBE: any;
}) => {
  return (
    <div id="toolbar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
      <p>Add a custom schedule or use the imported one from your current events</p>
      <div style={{ border: 'none', background: 'transparent' }} className="ql-customClear">
        <button style={{ border: 'none', background: 'transparent' }} onClick={updateScheduleFromBE}>
          <Icon name="calendar" size="s" />
        </button>
      </div>
    </div>
  );
};

type ScheduleEditorProps = { value: any; updateProps: (e: any) => void; updateScheduleFromBE: (e: any) => void };

const ScheduleEditor = ({ value, updateProps, updateScheduleFromBE }: ScheduleEditorProps): JSX.Element => {
  let events: RSVPEvent[] = useSelector(selectRsvpEvents);
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const wedding = useSelector(selectWedding);

  const getRSVPEvents = async (userToken: string, wedding: Wedding) => {
    const rest = BrainHelper.listRSVPEvents(userToken, wedding.id);
    return rest.then((events) => events);
  };

  if (events === undefined) {
    getRSVPEvents(userToken, wedding).then((res) => (events = res?.rsvpEvents ?? []));
  }

  const thisEditor = useRef<ReactQuill>(null);
  const publicEvents = events.map((event) => (event.isPrivate == false ? event : undefined));

  return (
    <div className="text-editor">
      <div style={{ marginBottom: '24px' }}>
        <CustomToolbar updateScheduleFromBE={updateScheduleFromBE} callback={updateProps} events={publicEvents} />
      </div>
      <ReactQuill ref={thisEditor} value={value} theme="snow" onChange={updateProps} />
    </div>
  );
};

export default ScheduleEditor;
