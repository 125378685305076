import { Icon } from '../../../shared';

import styles from './Table.module.scss';

const OutlinedButton = (props: {
  id?: string;
  title: string;
  isActive: boolean;
  setIsActive: any;
  icon?: boolean;
  _callback?: any;
}): JSX.Element => {
  return (
    <button
      id={props.id}
      className={styles.outlinedButton + ' ' + (props.isActive ? styles.outlinedButtonActive : '')}
      onClick={() => {
        props._callback();
      }}
    >
      <h4 className={'didacticH4'}>{props.title}</h4>
      {props.icon && (
        <Icon name={'arrow_down'} color={'#63435B'} rotate={props.isActive ? '180deg' : '0deg'} size={'s'} />
      )}
    </button>
  );
};

export default OutlinedButton;
