import styles from '../Planning.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { RSVPEvent } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import EventEditor from './EventEditor';
import { addRsvpEvents, selectRsvpEvents, selectWedding } from '../../../../app/redux/wedding.slice.recuder';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { dateTimeFormat } from '../../../../config/dateFormats';
import { useSearchParams } from 'react-router-dom';
import GetUI, { EventType } from '../../../../utils/uiContent';

const SecondStep = (props: { setStep: any; userToken: string; setShowPreview: any }): JSX.Element => {
  const rsvpEvents = useSelector(selectRsvpEvents);
  const wedding = useSelector(selectWedding);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [eventsState, setEventsState] = useState<Map<string, RSVPEvent[]>>(new Map());
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const onBack = () => {
    props.setStep(1);
  };

  useEffect(() => {
    if (searchParams.get('eventId') === 'NEW_EVENT') {
      setIsEdit(true);
    } else if (searchParams.get('eventId') === 'Ceremony') {
      onBack();
    }
  }, []);

  useEffect(() => {
    const tempEventsArray: RSVPEvent[] = JSON.parse(
      JSON.stringify(rsvpEvents, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
    );
    const tempMap = new Map();
    tempEventsArray
      .sort((a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime())
      .forEach((c) => {
        const date = new Date(c.startTimestamp);
        const index = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
        const temp = tempMap.get(index);
        if (temp !== undefined) {
          const findIndex = temp.findIndex((value: RSVPEvent) => value.id === c.id);
          if (findIndex < 0) temp.push(c);
        } else {
          tempMap.set(index, [c]);
        }
        setEventsState(tempMap);
      });
  }, [rsvpEvents]);

  // re-enable this if we decide to save multiple events
  // const onSaveEvents = () => {
  //   eventsArray.forEach(async (event) => {
  //     let response;
  //     if (event.id === undefined || event.id === null || event.id === '') {
  //       response = await BrainHelper.createRsvpEvent(
  //         props.userToken,
  //         event.weddingId,
  //         event.name,
  //         event.startTimestamp,
  //         event.endTimestamp,
  //         event.location as Location,
  //         event.isDefault,
  //         event.icon,
  //         event.description,
  //       );
  //     } else
  //       response = await BrainHelper.updateRsvpEvent(
  //         props.userToken,
  //         event.id,
  //         event.name,
  //         event.startTimestamp,
  //         event.endTimestamp,
  //         event.location as Location,
  //         event.isDefault,
  //         event.icon,
  //         event.description,
  //       );
  //
  //     if (
  //       response !== undefined &&
  //       response?.hasEvent() &&
  //       response?.getEvent() !== undefined &&
  //       response.getEvent()? !== undefined
  //     )
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       dispatch(addRsvpEvents([response.getEvent()]));
  //   });
  // };

  return (
    <div className={styles.left}>
      <div>
        <div className={styles.stepHeader}>
          <img src={`${GetUI(wedding.socialEventType as EventType).icon}`} alt="rings" width={32} />
          <h3 className={'didacticH3'}>Plan Your Events</h3>
        </div>
        <p className={'didactic'} style={{ width: '100%', maxWidth: '285px', fontSize: '16px' }}>
          In this step you can add all your events, set visibility public or private. Go wild!
        </p>
        <br />
      </div>
      {Array.from(eventsState).map((eventArray, key) => {
        const mainDateAsDate = new Date(eventArray[1][0].startTimestamp);
        const mainDate = eventArray[1][0].startTimestamp
          ? dayjs.tz(
              eventArray[1][0].startTimestamp,
              eventArray[1][0].location?.externalQuickCode
                ? eventArray[1][0].location?.externalQuickCode
                : dayjs.tz.guess(),
            )
          : undefined;
        return (
          <div key={key} style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '32px' }}>
            {mainDate ? (
              <h2 className={'fridayH2'}>
                {`${dateTimeFormat({
                  timeZone: eventArray[1][0].location?.externalQuickCode
                    ? eventArray[1][0].location?.externalQuickCode
                    : dayjs.tz.guess(),
                  weekday: 'short',
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                }).format(mainDateAsDate)}`}
              </h2>
            ) : (
              ''
            )}
            {eventArray[1].map((event, k) => {
              const eventDateStart = event?.location?.externalQuickCode
                ? event.startTimestamp
                  ? dayjs(event?.startTimestamp ? event?.startTimestamp : new Date())
                      .tz(event?.location?.externalQuickCode ? event?.location?.externalQuickCode : '')
                      .format('YYYY-MM-DDTHH:mm:ss')
                  : ''
                : dayjs(event?.startTimestamp || new Date()).format('YYYY-MM-DDTHH:mm:ss');
              const eventDateEnd = event?.location?.externalQuickCode
                ? event.endTimestamp
                  ? dayjs(event?.endTimestamp)
                      .tz(event?.location?.externalQuickCode ? event?.location?.externalQuickCode : '')
                      .format('YYYY-MM-DDTHH:mm:ss')
                  : ''
                : dayjs(event?.endTimestamp || new Date()).format('YYYY-MM-DDTHH:mm:ss');
              return (
                <Fragment key={k}>
                  <EventEditor
                    eventDateStart={dayjs(eventDateStart).format('YYYY-MM-DDTHH:mm')}
                    eventDateEnd={dayjs(eventDateEnd).format('YYYY-MM-DDTHH:mm')}
                    event={event}
                    userToken={props.userToken}
                    setIsEdit={setIsEdit}
                    isEdit={event.name === ''}
                    onBackCeremony={onBack}
                  />
                </Fragment>
              );
            })}
            {!isEdit ? (
              <button
                className={styles.addEventBtn}
                onClick={() => {
                  setIsEdit(!isEdit);
                  const newRsvpEvent = new RSVPEvent();
                  const sd = dayjs(eventArray[1][eventArray[1].length - 1].startTimestamp || new Date()).toJSON();
                  const ed = dayjs(eventArray[1][eventArray[1].length - 1].endTimestamp || new Date()).toJSON();
                  newRsvpEvent.startTimestamp = sd;
                  newRsvpEvent.endTimestamp = ed;
                  dispatch(addRsvpEvents([newRsvpEvent]));
                }}
              >
                <img src="/rsvp/plus-dark.svg" alt="plus" />
                Add Event
              </button>
            ) : (
              ''
            )}
          </div>
        );
      })}
      {!isEdit ? (
        <>
          <div
            className={styles.anotherEvent}
            role={'button'}
            tabIndex={0}
            onMouseDown={() => {
              setIsEdit(!isEdit);
              const newRsvpEvent = new RSVPEvent();
              dispatch(addRsvpEvents([newRsvpEvent]));
            }}
          >
            <img src="/rsvp/plus.svg" alt="plus" />
            <div>
              <p>Add another day event</p>
            </div>
          </div>
          <p className={'didacticSpan'}>Perfect for organizing your bachelor party or a full weekend of celebration</p>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default SecondStep;
