import './CommentComponent.scss';

import { IonImg } from '@ionic/react';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import ShowMoreText from 'react-show-more-text';

import {
  selectAccount,
  selectAccountsCombinedWithConnectedAccountsForMentions,
  selectJwt,
  selectProfileImages,
} from '../../../app/redux/authorization.slice.reducer';
import {
  selectCommentInteractions,
  selectWedding,
  setCommentInteractionReducer,
  setTags,
  TagDetail,
} from '../../../app/redux/wedding.slice.recuder';
import { Backend } from '../../../config/backend';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
// import { Account } from '../../../pkg/protobuf/v2/auth/auth_types_pb';
import {
  // ConnectedAccount,
  ConnectedAccountRef,
  DeleteCommentRequest,
  Tag,
  UpdateCommentRequest,
  Wedding,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { LikesModal } from '../../modals';
import { CommentLoveButton } from '../../../component/commentLoveButton/CommentLoveButton';
import { logger } from '../../../config/Logger';
import { encode } from 'punycode';
import useOutsideClick from '../../../hooks/useOutsideClick';
import AvatarProfile from '../AvatarProfile/AvatarProfile';

type CommentComponentProps = {
  account: ConnectedAccountRef;
  accounts: ConnectedAccountRef[];
  connectedAccounts: ConnectedAccountRef[];
  weddingId: string;
  comment: any;
  userToken: any;
  date: any;
  profile: ConnectedAccountRef;
  setShowEditCommentModal?: any;
  setEditCommentCallback?: any;
  setCreateCommentCallback?: any;
  setUpdateCommentCallback?: any;
  setDeleteCommentCallback?: any;
  setUpdateComment?: any;
  showModal?: any;
  wedding?: Wedding;
};
// TODO: props should have a type
const CommentComponent = (props: CommentComponentProps): JSX.Element => {
  const [commenter, setCommenter] = useState<ConnectedAccountRef>();
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState(props.comment.message);
  const connectedAccount = useSelector(selectAccount);
  const profileImages = useSelector(selectProfileImages);
  // take control over the style of a component
  const [style, setStyle] = useState({});
  const [heart, setHeart] = useState(false);
  const commentInteractions = useSelector((state: any) => {
    const allCommentInteractions = selectCommentInteractions(state);
    // all post interactions
    const currentCommentInteractions = allCommentInteractions?.filter(
      (commentInteraction) => commentInteraction?.commentId === props.comment?.id,
    );
    return currentCommentInteractions;
  });
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  // used as input data for the mention plugin
  const mentions = useSelector(selectAccountsCombinedWithConnectedAccountsForMentions);
  const jwt = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);

  // selected tags
  const [selectedTags, setSelectedTags] = useState<TagDetail[]>([]);
  // selected mentions
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedMentions, setSelectedMentions] = useState<string[]>([]);

  useEffect(() => {
    const acc = props.connectedAccounts.find(
      (ca) =>
        ca?.connectedAccountId === props.comment?.accountId || ca?.connectedAccountPhoneId === props.comment?.accountId,
    );
    setCommenter(acc);
    setMessage(props.comment.message);
  }, [props.comment, commenter]);

  //use effect to set heart svg red when there is a like connected user made
  useEffect(() => {
    setHeart(
      commentInteractions?.find(
        (interaction) =>
          interaction?.accountId === props.account?.connectedAccountId ||
          interaction?.accountId === props.account?.connectedAccountPhoneId,
      ) !== undefined,
    );
  }, [commentInteractions]);

  const updateComment = async (
    message: string,
    commentId: string,
    weddingId: string,
    postId: string,
    userToken: string,
    selectedCommentMentions: string[],
    selectedCommentTags: TagDetail[],
  ) => {
    let newContent = message;

    // TODO: uncomment the following when we will have a page per user
    // newContent = newContent.split('@@@__').join('<a href="/user/');
    // newContent = newContent.split('^^^__').join(`">@`);
    // newContent = newContent.split('@@@^^^').join('</a>');
    newContent = newContent.split('@@@__').join('<a href="#?caid=');
    newContent = newContent.split('^^^__').join(`"><span>@`);
    newContent = newContent.split('@@@^^^').join('</span></a>');

    // TODO: uncomment the following when we will have a page per tag
    // newContent = newContent.split('$$$__').join('<a href="/tag/');
    // newContent = newContent.split('~~~__').join(`">#`);
    // newContent = newContent.split('$$$~~~').join('</a>');
    newContent = newContent.split('$$$__').join('<a href="#?tagid=');
    newContent = newContent.split('~~~__').join(`">#`);
    newContent = newContent.split('$$$~~~').join('</a>');

    if (!newContent) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const body = newContent.trim();

    try {
      const updateCommentRequest = new UpdateCommentRequest();
      updateCommentRequest.id = commentId;
      updateCommentRequest.weddingId = weddingId;
      updateCommentRequest.postId = postId;
      updateCommentRequest.message = newContent;
      updateCommentRequest.mentionConnectedAccountIds = selectedCommentMentions;
      // tags request
      const tagListRequest: Tag[] = [];
      for (const tagDetail of selectedCommentTags) {
        const tagRequest = new Tag();
        tagRequest.id = tagDetail?.id;
        tagRequest.weddingId = props?.wedding?.id || props.weddingId;
        tagRequest.key = tagDetail?.display;
        tagListRequest.push(tagRequest);
      }
      updateCommentRequest.tags = tagListRequest;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await Backend.brainClientV2.updateComment(updateCommentRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'CommentComponent.updateComment' });
    }
  };

  const deleteComment = async (commentId: string, weddingId: string, userToken: string) => {
    try {
      const updateCommentRequest = new DeleteCommentRequest();
      updateCommentRequest.id = commentId;
      updateCommentRequest.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await Backend.brainClientV2.deleteComment(updateCommentRequest, { headers });
      setIsShowEdit(false);
      setEdit(false);
    } catch (e) {
      logger.error({ message: e, functionName: 'CommentComponent.deleteComment' });
    }
  };

  const wrapperRef = useRef(null);
  const wrapperRef1 = useRef(null);
  useOutsideClick(wrapperRef, () => setIsShowEdit(false));
  useOutsideClick(wrapperRef1, () => setEdit(false));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setCoordinates = (x: number, y: number) => {
    return {
      position: 'sticky',
      float: 'right',
      width: '190px',
    };
  };

  const getMessageReadyForEditing = (message: string): string => {
    // TODO: uncomment the following when we will have a page per user
    // newContent = message.split('<a href="/user/').join('@@@__');
    // newContent = message.split(`">@`).join('^^^__');
    // newContent = message.split('</a>').join('@@@^^^');
    message = message.split('<a href="#?caid=').join('@@@__');
    message = message.split(`"><span>@`).join('^^^__');
    message = message.split('</span></a>').join('@@@^^^');

    // TODO: uncomment the following when we will have a page per tag
    // newContent = newContent.split('<a href="/tag/').join('$$$__');
    // newContent = newContent.split(`">#`).join('~~~__');
    // newContent = newContent.split('</a>').join('$$$~~~');
    message = message.split('<a href="#?tagid=').join('$$$__');
    message = message.split(`">#`).join('~~~__');
    message = message.split('</a>').join('$$$~~~');

    return message;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function asyncTags(query: any, callback: any): any {
    if (!query) return [];

    const globalTags = await BrainHelper.listTags(jwt, wedding?.id, dispatch, setTags);

    const search = async () => {
      const suggestedTags: TagDetail[] = [];
      const suggestion = { id: uuid(), display: query };
      for (const tag of globalTags) {
        const regex = new RegExp(`.*${query}.*`);
        const found = tag.key.match(regex);
        if (found) suggestedTags.push({ id: tag?.id, display: tag.key });
      }
      if (suggestedTags.length > 0) {
        return suggestedTags.concat(suggestion);
      }

      return [suggestion];
    };
    search().then(callback);
  }

  return (
    <div className="postedComment">
      <div className="commentInfoSection">
        <div className="commentProfile">
          <AvatarProfile
            src={profileImages.get(commenter?.accountId || commenter?.accountPhoneId || '')}
            alt="ProfilePicture"
            accountFirstName={props?.profile?.accountFirstName}
            accountLastName={props?.profile?.accountLastName}
            size={30}
          />
          <h5
            className={'didacticP commenterFullname'}
          >{`${commenter?.accountFirstName} ${commenter?.accountLastName}`}</h5>
          <span>• {props.date}</span>
        </div>
        <div style={{ height: '0', marginTop: '-16px' }}>
          {props.comment?.accountId === connectedAccount?.connectedAccountId ||
          props.comment?.accountId === connectedAccount?.connectedAccountPhoneId ? (
            <div className="post-edit">
              <IonImg
                className="edit-btn"
                src="edit.svg"
                alt="edit"
                style={{ height: 12 }}
                onClick={(e) => {
                  props.showModal(true);
                  props.setDeleteCommentCallback(() => () => {
                    deleteComment(props.comment?.id, props.weddingId, props.userToken);
                    props.showModal(false);
                    props.setUpdateComment('');
                  });
                  props.setUpdateCommentCallback(
                    () => (input: string, selectedCommentMentions: string[], selectedCommentTags: TagDetail[]) => {
                      updateComment(
                        input,
                        props.comment?.id,
                        props?.weddingId,
                        props?.comment?.postId,
                        props?.userToken,
                        selectedCommentMentions,
                        selectedCommentTags,
                      );
                    },
                  );
                  props.setEditCommentCallback(() => () => {
                    props.setUpdateComment(message);
                    props.setShowEditCommentModal(true);
                  });
                  setEdit(false);
                  const s = setCoordinates(e.clientX, e.clientY);
                  setStyle(s);
                }}
              />
              {isShowEdit ? (
                <div className="comment-edit-dropdown" style={style} ref={wrapperRef}>
                  <button
                    onClick={() => {
                      setEdit(true);
                      setIsShowEdit(false);
                    }}
                  >
                    <img className="imgIcon" src="/icon-edit.svg" alt="edit" /> Edit Comment
                  </button>
                  <button
                    onClick={() =>
                      props.setDeleteCommentCallback(
                        /* eslint-disable */
                        () => () => deleteComment(props.comment?.id, props.weddingId, props.userToken),
                      )
                    }
                  >
                    <img className="imgIcon" src="/icon-delete.svg" alt="delete" /> Remove
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {edit ? (
        <div className="textAreaContainer" ref={wrapperRef1}>
          {/*<TextareaAutosize*/}
          {/*  className="textArea"*/}
          {/*  onChange={(event) => {*/}
          {/*    const tar: any = event.target;*/}
          {/*    setMessage(tar.value);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {message}*/}
          {/*</TextareaAutosize>*/}
          <MentionsInput
            className={'mentions textArea'}
            value={getMessageReadyForEditing(message)}
            onChange={(event: any) => {
              if (event.target.value.length > 250) return;
              setMessage(event.target.value);
            }}
            placeholder={'Say something...'}
            spellCheck={false}
          >
            <Mention
              trigger={'@'}
              data={mentions}
              markup={'@@@____id__^^^____display__@@@^^^'}
              style={{
                color: '#daf4fa',
              }}
              onAdd={(display) => {
                setSelectedMentions((mentions: any[]) => [...mentions, display]);
              }}
              appendSpaceOnAdd={true}
            />
            <Mention
              trigger={'#'}
              data={asyncTags}
              markup={'$$$____id__~~~____display__$$$~~~'}
              style={{
                color: '#daf4fa',
              }}
              onAdd={async (id, display) => {
                setSelectedTags(selectedTags.concat({ id: `${id}`, display: display }));
              }}
              appendSpaceOnAdd={true}
            />
          </MentionsInput>
          <button
            onClick={() => {
              updateComment(
                message,
                props.comment?.id,
                props?.weddingId,
                props?.comment?.postId,
                props?.userToken,
                [],
                [],
              ).then(() => setEdit(false));
              setIsShowEdit(false);
            }}
          >
            <img onContextMenu={(e) => e.preventDefault()} src={'/icon-send.svg'} alt="Send Message" />
          </button>
        </div>
      ) : (
        <div className="commentArea">
          <ShowMoreText
            lines={3}
            more="Show more"
            less="Show less"
            className="content-css"
            anchorClass="my-anchor-css-class"
            expanded={false}
            truncatedEndingComponent={'... '}
          >
            <p style={{ wordBreak: 'break-all' }}>
              {/* {props.comment.message}*/}
              {/*parse html message before the presentation*/}
              {parse(props.comment.message, {
                replace: (domNode: any) => {
                  if (domNode.name === 'a') {
                    const node = domNode.children[0];
                    let data = node?.data;
                    if (!data) data = '@';
                    return (
                      <Link to={domNode.attribs.href} className={data[0] === '#' ? 'hashtagLink' : 'mentionLink'}>
                        {data === '@' ? <span>{node?.children[0].data}</span> : node.data}
                      </Link>
                    );
                  }
                },
              })}
            </p>
          </ShowMoreText>
          <div className="commentInteraction">
            <button onClick={() => setModal(!modal)}>
              <p>
                {commentInteractions !== undefined
                  ? commentInteractions.length > 0
                    ? commentInteractions.length
                    : ''
                  : ' '}
              </p>
            </button>
            <CommentLoveButton
              heart={heart}
              onClick={async () => {
                if (heart) {
                  await BrainHelper.deleteCommentInteraction(
                    props.weddingId,
                    props.comment?.id,
                    props.account?.connectedAccountId,
                    props.account?.connectedAccountPhoneId,
                    commentInteractions,
                    props.userToken,
                    dispatch,
                    setCommentInteractionReducer,
                  );
                } else {
                  await BrainHelper.createCommentInteraction(
                    props.weddingId,
                    props.comment?.id,
                    props.account?.connectedAccountId || props.account?.connectedAccountPhoneId,
                    commentInteractions,
                    props.userToken,
                  );
                }
              }}
            />
          </div>
        </div>
      )}
      <LikesModal
        key={`likeModal-${encode(
          JSON.stringify(props.accounts, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
        )}`}
        showModal={modal}
        setShowModal={setModal}
        interactions={commentInteractions}
        accounts={props.accounts}
        connectedAccounts={props.connectedAccounts}
      />
    </div>
  );
};

export default CommentComponent;
