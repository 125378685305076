import React, { Fragment, useEffect, useState } from 'react';
import styles from './Questions.module.scss';
import {
  Answer,
  ListQuestionsResponse,
  Question,
  RSVPEventAttendance,
  SingleOptionChoice,
  Wedding,
} from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../../app/redux/authorization.slice.reducer';

const ExtraQuestion = (props: {
  event?: any;
  question: Question;
  disabled?: boolean;
  questionsList?: ListQuestionsResponse | null;
  setQuestionsList?: any;
  isEditable?: boolean;
  answer?: Answer;
  setAnswer?: any;
  attendance?: RSVPEventAttendance | undefined;
  wedding: Wedding;
  isDashboard?: boolean;
  isExperienceApp?: boolean;
}): JSX.Element => {
  const emptyAnswers: string[] = [];
  props.question.singleOptionChoices.forEach((single) => {
    emptyAnswers.push(single.option);
  });
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>(props.question.question);
  const [isCheckbox, setIsCheckbox] = useState<boolean>(props.question.allowMultiple);
  const [isFreeText, setIsFreeText] = useState<boolean>(props.question.isFreeText);
  const [singleAnswers, setSingleAnswers] = useState<string[]>(emptyAnswers);
  const [canCreateQuestion, setCanCreateQuestion] = useState<boolean>(false);
  const [answer, setAnswer] = useState<Answer | undefined>(props.answer);
  const [rsvpAttendance, setRsvpAttendance] = useState<RSVPEventAttendance | undefined>(props.attendance);

  useEffect(() => {
    if (question !== '' && ((isCheckbox && singleAnswers.length > 0 && !singleAnswers.includes('')) || isFreeText))
      setCanCreateQuestion(true);
    else setCanCreateQuestion(false);
  }, [question, isCheckbox, isFreeText, singleAnswers]);

  useEffect(() => {
    setAnswer(() => props.answer);
  }, [props.answer]);

  useEffect(() => {
    setRsvpAttendance(() => props.attendance);
  }, [props.attendance]);

  const updateQuestion = async () => {
    const t = await BrainHelper.updateQuestion(
      userToken,
      props.question.id,
      props.wedding.id,
      question,
      isCheckbox,
      false,
      isFreeText,
      singleAnswers,
      props.question.belongsToRsvpEventId,
    );
    if (t?.question) {
      const questions = JSON.parse(
        JSON.stringify(props.questionsList, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const findIndex = questions.questions.findIndex((q: any) => q.id === t?.question?.id);
      questions.questions[findIndex] = t?.question;
      questions.questions[findIndex].belongsToRsvpEventId = props.question.belongsToRsvpEventId;
      props.setQuestionsList(questions);
      setIsEdit(false);
      const answers: string[] = [];
      t?.question?.singleOptionChoices.forEach((single) => {
        answers.push(single.option);
      });
      setSingleAnswers(answers);
    }
  };

  const deleteQuestion = async () => {
    const t = await BrainHelper.deleteQuestion(userToken, props.question.id, props.wedding.id);
    if (t) {
      const questions = JSON.parse(
        JSON.stringify(props.questionsList, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const findIndex = questions.questions.findIndex((q: any) => q.id === props.question.id);
      questions.questions.splice(findIndex, 1);
      setIsEdit(false);
      props.setQuestionsList(questions);
    }
  };

  return (
    <div>
      {!isEdit ? (
        <div className={styles.questionWrapper}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', maxWidth: '892px' }}>
            <h4 className={'didacticH4'}>{props.question.question}</h4>
            {props.isEditable ? (
              <div className={styles.btns}>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img src="/rsvp/edit.svg" alt="edit" onMouseDown={() => setIsEdit(true)} />
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img src="/rsvp/delete.svg" alt="delete" onMouseDown={() => deleteQuestion()} />
              </div>
            ) : (
              ''
            )}
          </div>
          {props.question.isFreeText ? (
            <div>
              <input
                className={'input'}
                type="text"
                style={{ marginTop: '16px' }}
                disabled={props.disabled || !(rsvpAttendance && rsvpAttendance.attend)}
                value={answer?.freeText || ''}
                onChange={(e) => {
                  const temp = answer
                    ? JSON.parse(JSON.stringify(answer, (_, v) => (typeof v === 'bigint' ? v.toString() : v)))
                    : new Answer();
                  temp.freeText = e.target.value;
                  temp.questionId = props.question.id;
                  setAnswer(temp);
                  props.setAnswer(temp);
                }}
              />
            </div>
          ) : (
            <div>
              {props.question.singleOptionChoices.map((option, k1) => {
                return (
                  <div className={styles.checkbox} key={k1}>
                    <input
                      className={'input checkbox'}
                      type="checkbox"
                      name={option.option}
                      disabled={props.disabled || !(rsvpAttendance && rsvpAttendance.attend)}
                      checked={!!answer?.singleOptionChoices.find((s) => s.option === option.option)}
                      onChange={(e) => {
                        const temp = answer
                          ? JSON.parse(JSON.stringify(answer, (_, v) => (typeof v === 'bigint' ? v.toString() : v)))
                          : new Answer();
                        let singleOptionsTemp = temp.singleOptionChoices;
                        const index = singleOptionsTemp.findIndex(
                          (o: SingleOptionChoice) => o.option === e.target.name,
                        );
                        if (!props.question.allowMultiple) singleOptionsTemp = [];
                        if (index > -1) singleOptionsTemp.splice(index, 1);
                        else {
                          const newOption = new SingleOptionChoice();
                          newOption.option = e.target.name;
                          singleOptionsTemp.push(newOption);
                        }
                        temp.singleOptionChoices = singleOptionsTemp;
                        temp.questionId = props.question.id;
                        setAnswer(temp);
                        props.setAnswer(temp);
                      }}
                    />
                    <label htmlFor={option.option}>{option.option}</label>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.addQuestion}>
          <h4 className={'didacticH4'} style={{ marginBottom: '8px' }}>
            Type a Question*
          </h4>
          <input
            type="text"
            className={'input inputLarge'}
            placeholder={'Type here'}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <h4 className={'didacticH4'} style={{ marginTop: '24px' }}>
            Answer Type*
          </h4>
          <div className={styles.questionType}>
            <input
              type="checkbox"
              className={'checkbox'}
              name={'checkbox'}
              value={'checkbox'}
              checked={isCheckbox}
              onChange={() => {
                setIsCheckbox(!isCheckbox);
                setIsFreeText(false);
              }}
            />
            <label htmlFor="checkbox">Checkbox</label>
            <input
              type="checkbox"
              className={'checkbox'}
              name={'freetext'}
              value={'freetext'}
              checked={isFreeText}
              onChange={() => {
                setIsFreeText(!isFreeText);
                setIsCheckbox(false);
              }}
            />
            <label htmlFor="{'freetext'}">Free Text</label>
          </div>
          {isCheckbox || isFreeText ? (
            <div>
              {isCheckbox ? (
                <Fragment>
                  <h4 className={'didacticH4'} style={{ marginTop: '32px' }}>
                    Create Answers*
                  </h4>
                  <div className={styles.singleAnswers}>
                    {singleAnswers.map((answer, k) => {
                      return (
                        <div key={k} className={styles.answer}>
                          <input type="checkbox" className={'checkbox'} name={answer} value={answer} />
                          {/*<label htmlFor={emptyAnswer}>{emptyAnswer || 'type here'}</label>*/}
                          <input
                            type="text"
                            className={'input inputNoBorder'}
                            placeholder={'Type here'}
                            value={answer}
                            onChange={(e) => {
                              const temp = JSON.parse(
                                JSON.stringify(singleAnswers, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
                              );
                              temp[k] = e.target.value;
                              setSingleAnswers(temp);
                            }}
                          />
                          <button
                            className={'btn btnSecondary'}
                            style={{ margin: 0 }}
                            onClick={() => {
                              const temp = JSON.parse(
                                JSON.stringify(singleAnswers, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
                              );
                              temp.splice(k, 1);
                              setSingleAnswers(temp);
                            }}
                          >
                            <img
                              style={{ alignSelf: 'center', marginLeft: 'auto', cursor: 'pointer' }}
                              src="/rsvp/delete.svg"
                              alt="edit"
                            />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className={'btn btnSecondary btnSecondaryWithImg'}
                    style={{ margin: 0, paddingLeft: 0 }}
                    onClick={() => {
                      const temp = JSON.parse(
                        JSON.stringify(singleAnswers, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
                      );
                      temp.push('');
                      setSingleAnswers(temp);
                    }}
                  >
                    <img src={'/rsvp/plus-dark.svg'} alt="rsvp" />
                    Add Answer
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <input type="text" className={'input'} style={{ marginTop: '32px' }} />
                </Fragment>
              )}
            </div>
          ) : (
            ''
          )}
          <div>
            <button className={'btn btnMain'} disabled={!canCreateQuestion} onClick={() => updateQuestion()}>
              Update Question
            </button>
            <button className={'btn btnSecondary'} onClick={() => setIsEdit(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtraQuestion;
