import { IonContent, IonHeader, IonPage, IonTitle } from '@ionic/react';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ErrorBoundaryFallback = (props: { error: Error }): JSX.Element => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border header">
        <IonTitle>
          <img loading="lazy" className="headerLogo" src="/whiteclover_logo_black.png" alt="logo" />
        </IonTitle>
      </IonHeader>
      <IonContent>
        <h1>Something went wrong with our services.</h1>
        <p>
          Please click <a href="/">here</a> to redirect to our page.
        </p>
      </IonContent>
    </IonPage>
  );
};

export default ErrorBoundaryFallback;
