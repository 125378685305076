import React, { Fragment, useEffect, useState } from 'react';
import styles from './Rsvp.module.scss';
import DefaultQuestions from '../../../component/dashboard/rsvp/questionComponts/DefaultQuestions';
import ExtraQuestion from '../../../component/dashboard/rsvp/questionComponts/Question';
import {
  AllowedVisitor,
  Answer,
  ListQuestionsResponse,
  RSVPEvent,
  RSVPEventAttendance,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { dateTimeFormat } from '../../../config/dateFormats';
import { useSelector } from 'react-redux';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { selectJwt } from '../../../app/redux/authorization.slice.reducer';

export const EventQuestions = (props: {
  e: RSVPEvent;
  questionsList: Map<string, ListQuestionsResponse | undefined>;
  setQuestionsList: any;
  attend: Map<string, 0 | 1 | 2>;
  plus1: Map<string, number>;
  setAttend: any;
  setPlus1: any;
  invited?: AllowedVisitor;
  answers?: Map<string, Answer[] | undefined>;
  setAnswers?: any;
  isExperienceApp?: boolean;
  isDashboard?: boolean;
  questionsOnly?: boolean;
  hasHeader?: boolean;
  isDisabled?: boolean;
  isDropdown?: boolean;
}): JSX.Element => {
  const userToken = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);
  const [attend, setAttend] = useState<0 | 1 | 2>(props.attend.get(props.e.id) || 0);
  const [plus1, setPlus1] = useState<number>(props.plus1.get(props.e.id) || 0);
  const [answers, setAnswers] = useState<Answer[] | undefined>(undefined);
  const [answer, setAnswer] = useState<Answer | undefined>(undefined);
  const [attendance, setAttendance] = useState<RSVPEventAttendance | undefined>(
    props.invited?.attendToRsvpEvents.find((a) => a.rsvpEvent?.id === props.e.id),
  );
  let startDate = new Date();
  let endDate = new Date();
  if (wedding?.mainEvent?.startTimestamp) startDate = new Date(props.e?.startTimestamp);
  if (wedding?.mainEvent?.startTimestamp) endDate = new Date(props.e?.endTimestamp);

  useEffect(() => {
    setAttendance(props.invited?.attendToRsvpEvents.find((a) => a.rsvpEvent?.id === props.e.id));
  }, [props.invited]);

  useEffect(() => {
    getAnswers();
    setAttend(props.attend.get(props.e.id) || 0);
    setPlus1(props.plus1.get(props.e.id) || 0);
  }, [props.invited?.id, props.attend, props.plus1]);

  useEffect(() => {
    const temp = new Map(props.attend);
    temp.set(props.e.id, attend);
    props.setAttend(temp);
  }, [attend]);

  useEffect(() => {
    const temp = new Map(props.plus1);
    temp.set(props.e.id, plus1);
    props.setPlus1(temp);
  }, [plus1]);

  useEffect(() => {
    let temp = new Map();
    if (props.answers) temp = new Map(props.answers);
    const answersList: Answer[] = temp.get(props.e.id) ? temp.get(props.e.id) : new Array<Answer>();
    const index = answersList.findIndex((a) => a.questionId === answer?.questionId);
    if (answer) {
      if (index > -1) answersList[index] = answer;
      else answersList.push(answer);
      temp.set(props.e.id, answersList);
    }
    props.setAnswers(temp);
  }, [answer]);

  const getAnswers = async () => {
    if (props.invited) {
      const listAnswers = await BrainHelper.listAnswers(userToken, props.invited.id);
      setAnswers(() => listAnswers?.answers);
    }
  };

  return (
    <div className={styles.event} id={props.e.id}>
      <h3 className={'didacticH3'} style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
        {props.e.name}
      </h3>
      {props.hasHeader ? (
        <Fragment>
          {' '}
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src="/rsvp/map.svg" alt="map" />
            <a
              className={'didacticP ' + styles.highlighted}
              style={{
                wordWrap: 'break-word',
                width: '250px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              target="_blank"
              href={`https://maps.google.com/?q=${props.e?.location?.point?.latitude},${props.e?.location?.point?.longitude}`}
              rel="noreferrer"
            >
              {props.e.location?.name +
                (props.e.location?.name && props.e?.location?.name !== props.e?.location?.address ? ', ' : '') +
                (props.e?.location?.name !== props.e?.location?.address ? props.e.location?.address : '')}
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src="/rsvp/calendar.svg" alt="map" />
            <p className={'didacticP ' + styles.highlighted} style={{ marginRight: '16px' }}>
              {`${dateTimeFormat({
                timeZone: props.e.location?.externalQuickCode,
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }).format(startDate)}`}
            </p>
            <img src="/rsvp/clock-dark.svg" alt="clock" />
            <p className={'didacticP'}>
              {`${dateTimeFormat({
                timeZone: props.e.location?.externalQuickCode,
                hour: 'numeric',
                minute: 'numeric',
              }).format(startDate)}`}
            </p>
            -
            <p className={'didacticP'}>
              {`${dateTimeFormat({
                timeZone: props.e.location?.externalQuickCode,
                hour: 'numeric',
                minute: 'numeric',
              }).format(endDate)}`}
            </p>
          </div>{' '}
        </Fragment>
      ) : (
        ''
      )}
      <DefaultQuestions
        event={props.e}
        questionsOnly={props.questionsOnly}
        question={props.attend.get(props.e.id) || 0}
        setQuestion={setAttend}
        plus1={props.plus1.get(props.e.id)}
        setPlus1={setPlus1}
        invited={props.invited}
        attendance={attendance}
        setAttendance={setAttendance}
        hideOptions={props.isExperienceApp && (!props.invited || props.invited.maxAllowedPlus1 <= 0)}
        disabled={props.isDisabled}
        isDropdown={props.isDropdown}
      />
      {props.questionsList?.get(props.e.id)?.questions.map((question, k) => {
        return (
          <ExtraQuestion
            event={props.e}
            question={question}
            disabled={props.isDisabled}
            questionsList={props.questionsList?.get(props.e.id)}
            setQuestionsList={props.setQuestionsList}
            isEditable={false}
            key={k}
            answer={answers?.find((a) => a.questionId === question.id)}
            setAnswer={setAnswer}
            attendance={attendance}
            wedding={wedding}
            isDashboard={props.isDashboard}
            isExperienceApp={props.isExperienceApp}
          />
        );
      })}
    </div>
  );
};
