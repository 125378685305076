/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from './ImYoursAppPromoteComponent.module.scss';
import templateStyles from '../ImYours.module.scss';

interface ImYoursAppPromoteComponentProps {
  ImYoursAppComponentCustomImage?: string;
  ImYoursAppComponentCustomGraphics: string;
  ImYoursAppComponentCustomMarriageCode: string;
}

const defaultValues = {
  ImYoursAppComponentCustomGraphics: '/templates/ImYours/illustration-app-promo.svg',
  ImYoursAppComponentCustomImage: '/templates/ImYours/app-logo.svg',
  ImYoursAppPromoteComponentCustomGraphic2: '/templates/ImYours/app-promote-graphic.svg',
  ImYoursAppComponentCustomMarriageCode: '/home',
};

const ImYoursAppPromoteComponent = ({
  ImYoursAppComponentCustomImage,
  ImYoursAppComponentCustomGraphics,
  ImYoursAppComponentCustomMarriageCode,
}: ImYoursAppPromoteComponentProps): JSX.Element => {
  return (
    <section
      id={`ImYoursAppPromoteComponent`}
      className={styles.ImYoursAppPromoteComponent}
      style={{ backgroundImage: `url('/templates/ImYours/snow.png')` }}
    >
      <img
        src={
          ImYoursAppComponentCustomGraphics
            ? ImYoursAppComponentCustomGraphics
            : defaultValues.ImYoursAppPromoteComponentCustomGraphic2
        }
        alt="app promote graphic"
      />
      <div>
        <div>
          <img
            src={
              ImYoursAppComponentCustomGraphics
                ? ImYoursAppComponentCustomGraphics
                : defaultValues.ImYoursAppComponentCustomGraphics
            }
            alt="promote"
          />
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <img
              src={
                ImYoursAppComponentCustomImage
                  ? ImYoursAppComponentCustomImage
                  : defaultValues.ImYoursAppComponentCustomImage
              }
              alt={'logo'}
            />
            <h2 style={{ textTransform: 'uppercase' }}>Our App</h2>
          </div>
          <h3>Let’s stay connected!</h3>
          <p className={'didacticP'}>
            All our guests are invited to our own wedding app. You can see the wedding schedule, complete and update
            your RSVP and upload your photos of the wedding. You can also interact to other guests photos with likes and
            comments. As if we had our own social media platform. Don{"'"}t be left out; join us now!
          </p>
          <a
            href="#"
            className={templateStyles.btn + ' ' + styles.btn}
            style={{ textAlign: 'center', borderRadius: '8px !important' }}
            onClick={(e) => e.preventDefault()}
          >
            Join the app
          </a>
        </div>
      </div>
      <img
        src={
          ImYoursAppComponentCustomGraphics
            ? ImYoursAppComponentCustomGraphics
            : defaultValues.ImYoursAppPromoteComponentCustomGraphic2
        }
        alt="app promote graphic"
      />
    </section>
  );
};
export default ImYoursAppPromoteComponent;
