import '../CreatePostModal/CreatePostModal.scss';
import './EmailSubscriptionModal.scss';

import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectEmailSubscriptionNotified, selectJwt } from '../../../app/redux/authorization.slice.reducer';
import { logger } from '../../../config/Logger';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EmailSubscriptionModal = (props: any): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [color, setColor] = useState('#ffffff');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blobId, setBlobId] = useState<string>('');
  const [caption, setCaption] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [profilePic, setProfilePic] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const emailSubscriptionFlags = useSelector(selectEmailSubscriptionNotified);
  const jwt = useSelector(selectJwt);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, []);

  const setEmailSubscription = () => {
    const email = caption;

    logger.debug({ message: `email send to backend: ${email}` });
    BrainHelper.setNotificationDevice(jwt, '', email);

    // window.OneSignal = window.OneSignal || [];
    // const OneSignal = window.OneSignal;
    // OneSignal.push(function () {
    //   OneSignal.setEmail(email).then(function (emailPlayerId: any) {
    //     logger.debug({ message: `email send playerid to backend. PlayerID: ${emailPlayerId}` });
    //     BrainHelper.setNotificationDevice(jwt, emailPlayerId);
    //   });
    // });

    // get player id
    // send player id to backend
  };

  return (
    <div className={props.showModal ? 'show-modal' : 'default-modal'}>
      <div className={'emailModal'}>
        <div>
          <h3>Notifications</h3>
          <p>Do you want to receive notifications about loves and comments?</p>
          <br />
          <p>Please add your email below</p>
        </div>
        <div className={'floatingInputContainer'} style={{ margin: '8px 0 16px' }}>
          <input
            className={'field-input'}
            type="text"
            placeholder="Add your email"
            onChange={(event) => {
              const c: string = event.target.value;
              if (c.trim().length <= 0) event.target.value = event.target.value.trim();
              // console.log(event.target.value);
              setCaption(event.target.value);
            }}
          />
        </div>
        <div className={'buttons'}>
          <button
            className={'yes'}
            onMouseDown={() => {
              setEmailSubscription();
              props.setShowModal(false);
              setCaption('');
            }}
          >
            <img src={'assets/icons/icon-check-black.svg'} alt={'Yes'} style={{ paddingRight: 8 }} />I do
          </button>
          <button
            className={'no'}
            onMouseDown={() => {
              props.setShowModal(false);
              setCaption('');
            }}
          >
            <img src={'assets/icons/icon-exit-black.svg'} alt={'No'} style={{ paddingRight: 8 }} />I dont
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailSubscriptionModal;
