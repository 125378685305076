/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableTemplates, WeddingWebsite } from '../../pkg/protobuf/v2/brain/brain_types_pb';
import { RootState } from '../store';

export interface WebsiteBuilderState {
  selectedTemplate: AvailableTemplates;
  weddingWebsites: WeddingWebsite[];
  currentWeddingWebsite: WeddingWebsite | undefined;
  componentProps: Array<any>;
}

const initialState: WebsiteBuilderState = {
  selectedTemplate: new AvailableTemplates(),
  weddingWebsites: [],
  currentWeddingWebsite: new WeddingWebsite(),
  componentProps: [],
};

export const websiteBuilderSlice = createSlice({
  name: 'websiteBuilder',
  initialState,
  reducers: {
    setSelectedTemplate: (state, action: PayloadAction<AvailableTemplates | undefined>) => {
      state.selectedTemplate = action.payload ? action.payload : new AvailableTemplates();
    },
    removeSelectedTemplate: (state, action: PayloadAction<AvailableTemplates | undefined>) => {
      state.selectedTemplate = action.payload ? new AvailableTemplates() : new AvailableTemplates();
    },
    setCurrentWeddingWebsite: (state, action: PayloadAction<WeddingWebsite | undefined>) => {
      state.currentWeddingWebsite = action.payload ? action.payload : new WeddingWebsite();
    },
    removeCurrentWeddingWebsite: (state, action: PayloadAction<WeddingWebsite | undefined>) => {
      state.currentWeddingWebsite = undefined;
    },
    setWeddingWebsites: (state, action: PayloadAction<Array<any> | undefined>) => {
      state.weddingWebsites = action.payload ? action.payload : [];
    },
    removesetWeddingWebsites: (state, action: PayloadAction<Array<any> | undefined>) => {
      state.weddingWebsites = [];
    },
    setComponentProps: (state, action: PayloadAction<Array<any> | undefined>) => {
      state.componentProps = action.payload ? action.payload : [];
    },
    removeComponentProps: (state, action: PayloadAction<Array<any> | undefined>) => {
      state.componentProps = [];
    },
  },
});

export const {
  setSelectedTemplate,
  removeSelectedTemplate,
  setCurrentWeddingWebsite,
  removeCurrentWeddingWebsite,
  setWeddingWebsites,
  removesetWeddingWebsites,
  setComponentProps,
  removeComponentProps,
} = websiteBuilderSlice.actions;

export const selectSelectedTemplate = (state: RootState) => state.websiteBuilder.selectedTemplate;
export const selectCurrentWeddingWebsite = (state: RootState) => state.websiteBuilder.currentWeddingWebsite;
export const selectWeddingWebsites = (state: RootState) => state.websiteBuilder.weddingWebsites;
export const selectComponentProps = (state: RootState) => state.websiteBuilder.componentProps;

export default websiteBuilderSlice.reducer;
