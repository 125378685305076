import '../CreatePostModal/CreatePostModal.scss';
import './LikesModal.css';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectProfileImages } from '../../../app/redux/authorization.slice.reducer';
import {
  ConnectedAccountRef,
  PostInteraction,
  CommentInteraction,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import AvatarProfile from '../../shared/AvatarProfile/AvatarProfile';

type LikeModalProps = {
  interactions: PostInteraction[] | CommentInteraction[];
  connectedAccounts: ConnectedAccountRef[];
  accounts: ConnectedAccountRef[];
  showModal: boolean;
  setShowModal: any;
};

const LikesModal = (props: LikeModalProps): JSX.Element => {
  const [accounts, setAccounts] = useState<ConnectedAccountRef[]>([]);
  const profileImages = useSelector(selectProfileImages);
  useEffect(() => {
    // console.log(`likeModal key: ${props.key} - ${JSON.stringify(props.interactions)}`);
    if (props.interactions.length > 0) {
      const accountsList: ConnectedAccountRef[] = [];
      props.interactions.map((pi) => {
        const connectedAcc = props.connectedAccounts.find(
          (a) => a?.connectedAccountId === pi?.accountId || a?.connectedAccountPhoneId === pi?.accountId,
        );
        if (connectedAcc) accountsList.push(connectedAcc);
        setAccounts(accountsList);
      });
    } else setAccounts([]);
  }, [props.interactions]);
  return (
    <div className={props.showModal ? 'lovesModal' : 'default-modal'}>
      {accounts.length > 0 ? (
        <>
          <div style={{ height: '65%' }} tabIndex={0} role="button" onMouseDown={() => props.setShowModal(false)}></div>
          <div className={''}>
            <img
              src={'assets/graphics/line.svg'}
              alt={''}
              width={32}
              style={{ alignSelf: 'center', justifySelf: 'center', paddingBottom: 16, paddingTop: 16 }}
            />
            <div className="likesModalPeople">
              {accounts.map((acc: ConnectedAccountRef, key) => {
                return (
                  <div key={acc?.accountId || acc?.accountPhoneId} className="likesProfile">
                    <span style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <AvatarProfile
                        src={profileImages.get(acc?.accountId || acc?.accountPhoneId || '')}
                        alt="ProfilePicture"
                        accountFirstName={acc?.accountFirstName}
                        accountLastName={acc?.accountLastName}
                        size={30}
                      />
                      <p key={key} style={{ color: '#835878' }}>
                        {`${acc?.accountFirstName} ${acc?.accountLastName}`}
                      </p>
                    </span>
                    <img src={'assets/icons/icon-loved.svg'} alt={''} style={{ width: 16, height: 16 }} />
                  </div>
                );
              })}
            </div>
          </div>{' '}
        </>
      ) : (
        <>
          {' '}
          <div
            style={{ height: '85%' }}
            tabIndex={0}
            role="button"
            onMouseDown={() => props.setShowModal(false)}
          ></div>{' '}
          <div className="likesModalPeople" style={{ height: '15%', paddingLeft: 0 }}>
            <img
              src={'assets/graphics/line.svg'}
              alt={''}
              width={20}
              style={{ alignSelf: 'center', justifySelf: 'center', paddingTop: 16, paddingBottom: 16 }}
            />
            <p style={{ textAlign: 'center' }}>Nobody loves it yet...</p>
          </div>
        </>
      )}
    </div>
  );
};

export default LikesModal;
