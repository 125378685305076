import React, { Fragment, useEffect, useRef, useState } from 'react';

import Header from '../header/Header';
import styles from './StartPlanning.module.scss';
import { GenericModal } from '../../modals/GenericModal';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectJwt, selectUser, setConnectedAccount, setJwt } from '../../../app/redux/authorization.slice.reducer';
import jwt_decode from 'jwt-decode';
import { setWeddings, setWedding, setIsTutorialOn, selectWedding } from '../../../app/redux/wedding.slice.recuder';
import {
  Coupon,
  CreateRSVPEventRequest,
  Location,
  Package,
  Point,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CustomDatePicker from '../../datePicker/CustomDatePicker';
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import LocationInputComponent from './locationInput/LocationInputComponent';
import Lottie from 'react-lottie';
import location from '../../../loader-animation.json';
import { logger } from '../../../config/Logger';
import { transliterate } from 'transliteration';
import GetUI, { EventType } from '../../../utils/uiContent';
import { SocialEventCard } from '../socialEventCard/SocialEventCard';
import { useNavigate } from 'react-router-dom';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const StartPlanning = (): JSX.Element => {
  const wedding = useSelector(selectWedding);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [skipPressed, setSkipPressed] = useState<boolean>(false);
  const [couponError, setCouponError] = useState<boolean>(false);
  const [selectedEventType, setSelectedEventType] = useState<EventType | null>(null);
  const [enteredCoupon, setEnteredCoupon] = useState<Coupon | undefined>(undefined);
  const [couponInput, setCouponInput] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState<any>({});
  const userToken = useSelector(selectJwt);
  const accountUser = useSelector(selectUser);
  const [completed, setCompleted] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [partnerName, setPartnerName] = useState<string>('');
  const [weddingName, setWeddingName] = useState<string>('');
  const [disableCalendar, setDisableCalendar] = useState(false);
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [selectedDay, setSelectedDay] = useState<DayValue>(undefined);
  const [weddingLocation, setWeddingLocation] = useState<Location>(new Location());
  const [countryShort, setCountryShort] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRedirect, setIsRedirect] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redirectPath, setRedirectPath] = useState('');
  const dispatch = useDispatch();
  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [completedStyle, setCompletedStyle] = useState({ top: 0, left: 0, width: 0, height: 0 });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [completed2, setCompleted2] = useState<boolean>(false);
  const [packages, setPackages] = useState<Array<Package> | undefined>(undefined);

  const navigate = useNavigate();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    if (wedding?.id.trim() !== '' && wedding?.path.trim() !== 'no-marriage-yet')
      navigate(`/dashboard/w/${transliterate(wedding.path)}/overview`);
    BrainHelper.listAllPackages(userToken).then((response) => {
      if (response) setPackages(response.packages);
    });
  }, []);

  useEffect(() => {
    if (selectedEventType && selectedEventType !== EventType.wedding) {
      if (weddingName !== '' && weddingName.length > 2 && (selectedDay !== undefined || disableCalendar)) {
        setCompleted(true);
      }
    } else {
      if (
        (!selectedEventType || selectedEventType === EventType.wedding) &&
        firstName !== '' &&
        partnerName !== '' &&
        weddingName !== '' &&
        (selectedDay !== undefined || disableCalendar)
      )
        setCompleted(true);
      else setCompleted(false);
    }
  }, [selectedEventType, firstName, partnerName, weddingName, selectedDay, disableCalendar]);

  const onSubmitNewPlanning = async () => {
    try {
      //TODO: ERROR CHECKING
      if (completed) {
        setCompleted2(true);
        setCompletedStyle({
          top: (formRef?.current?.offsetTop || 0) - 10,
          left: (formRef?.current?.offsetLeft || 0) - 10,
          width: (formRef?.current?.offsetWidth || 0) + 10,
          height: (formRef?.current?.offsetHeight || 0) + 10,
        });

        setIsSecondButtonLoading(true);
        const decoded: any = jwt_decode(userToken);
        let createRsvpRequest = undefined;

        if ((selectedDay && !disableCalendar) || countryShort != '') {
          createRsvpRequest = new CreateRSVPEventRequest();
          createRsvpRequest.name = GetUI(selectedEventType ?? EventType.wedding).ceremonyName;
          if (selectedDay && !disableCalendar) {
            const tz = dayjs.tz(selectedDay?.year + '-' + selectedDay?.month + '-' + selectedDay?.day, '');
            createRsvpRequest.startTimestamp = tz.toJSON();
            createRsvpRequest.endTimestamp = tz.toJSON();
          }
          if (countryShort) {
            const point = new Point();
            point.longitude = weddingLocation.point?.longitude || 0;
            point.latitude = weddingLocation.point?.latitude || 0;
            const location = new Location();
            location.address = weddingLocation.address;
            location.point = point;
            location.externalQuickCode = weddingLocation.externalQuickCode;
            createRsvpRequest.location = location;
          }
          createRsvpRequest.isDefault = true;
          // createRsvpRequest.setIs(true);
        }
        const weddingCodeFromName = weddingName
          .split(/[.,/#!$%^&*;:{}=\-_`~()]/g)
          .join(' ')
          .split(/\s{2,}/g)
          .join(' ')
          .split(' ')
          .join('-');
        const weddingResponse = await BrainHelper.createWedding(
          userToken,
          weddingCodeFromName,
          weddingName,
          selectedDay?.year || new Date().getFullYear(),
          accountUser.phoneNumber,
          accountUser.email,
          createRsvpRequest,
          selectedEventType ?? undefined,
          enteredCoupon !== null ? enteredCoupon?.id : undefined,
          selectedPackage !== null ? selectedPackage.name : 'Seed',
        );

        const listWeddings = await BrainHelper.listHostAndModeratorWeddings(accountUser.accountId, userToken, '', '');
        // console.log(accountUser.accountId, listWeddings);
        dispatch(setWeddings(listWeddings.length === 0 ? [weddingResponse] : [...listWeddings, weddingResponse]));
        dispatch(setWedding(weddingResponse));
        if (weddingResponse.id === '') {
          setShowModal(true);
          setModalMessage('Something went wrong! Try again.');
          setCompleted2(false);
        } else {
          const connectedAccountResponse = await BrainHelper.connectAccount(
            userToken,
            weddingResponse.id,
            decoded.accountId,
            dispatch,
            undefined,
            setState,
            undefined,
            setConnectedAccount,
            setJwt,
          );
          if (connectedAccountResponse) {
            dispatch(setIsTutorialOn(true));
            window.scrollTo(0, 0);
            window.location.pathname = `/dashboard/w/${transliterate(weddingResponse.path)}/overview`;
          }
          setCompleted2(false);
        }
      }
      setCompleted2(false);
    } catch (e) {
      setCompleted2(false);
      setIsSecondButtonLoading(false);
      logger.error({ message: e, functionName: 'StartPlanning.onSubmitNewPlanning' });
    }
  };

  useEffect(() => {
    setCouponError(false);
  }, [couponInput]);

  if (!selectedEventType) {
    return (
      <div style={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
        <Header logout backButton />
        <div className={styles.main}>
          <div className={styles.header} style={{ fontSize: 24 }}>
            Choose your Event Type
          </div>
          <br />
          <br />
          <div
            className={styles.selectSocialEvent}
            style={{ display: 'flex', justifyContent: 'center', width: '100vw', alignItems: 'center', gap: 24 }}
          >
            <SocialEventCard
              image="/assets/socialEvents/wedding/wedding.jpg"
              onClick={() => setSelectedEventType(EventType.wedding)}
              title="Create a Wedding"
            />
            <SocialEventCard
              image="/assets/socialEvents/baptism/baptism.jpg"
              onClick={() => setSelectedEventType(EventType.baptism)}
              title="Create a Baptism"
              new
            />
            <SocialEventCard
              image="/assets/socialEvents/party/party.jpg"
              onClick={() => setSelectedEventType(EventType.party)}
              title="Create a Party"
              beta
            />
            <br />
          </div>
        </div>
      </div>
    );
  }

  if (selectedEventType && !enteredCoupon) {
    return (
      <div style={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
        <Header logout backButton />
        <div className={styles.main}>
          <div className={styles.header} style={{ fontSize: 24 }}>
            Choose your package:
          </div>
          <br />
          <br />
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: 16, alignItems: 'center' }}
            className={styles.selectSocialEvent}
          >
            {packages?.map((pkg) => {
              return (
                <Fragment key={pkg.id}>
                  <span style={selectedPackage?.name === pkg.name ? { opacity: 1 } : { opacity: 0.6 }}>
                    <SocialEventCard
                      onClick={function (): void {
                        setSelectedPackage(pkg);
                      }}
                      image={`/assets/graphics/${pkg.id}.svg`}
                      title={`${pkg.name} - ${pkg.originalPrice === 0 ? 'free' : ''}`}
                      subtitle={`${pkg.originalPrice > 0 ? pkg.originalPrice + '€' : ''}`}
                      price={` ${
                        pkg.originalPrice > 0
                          ? pkg.originalPrice -
                            pkg.originalPrice * pkg.discount +
                            '€ ' +
                            '(' +
                            -pkg.discount * 100 +
                            '%)'
                          : ''
                      }`}
                      moreDetails
                    />
                  </span>
                </Fragment>
              );
            })}
            {/*<span style={selectedPackage === 'Seed' ? { opacity: 1 } : { opacity: 0.6 }}>*/}
            {/*  <SocialEventCard*/}
            {/*    onClick={function (): void {*/}
            {/*      setSelectedPackage('Seed');*/}
            {/*    }}*/}
            {/*    image={'/assets/graphics/seed.svg'}*/}
            {/*    title={'Seed - Free'}*/}
            {/*    moreDetails*/}
            {/*  />*/}
            {/*</span>*/}
            {/*<span style={selectedPackage === 'Flower' ? { opacity: 1 } : { opacity: 0.6 }}>*/}
            {/*  <SocialEventCard*/}
            {/*    onClick={function (): void {*/}
            {/*      setSelectedPackage('Flower');*/}
            {/*    }}*/}
            {/*    image={'/assets/graphics/flower.svg'}*/}
            {/*    title={'Flower - '}*/}
            {/*    subtitle="350€"*/}
            {/*    price={' 156€ (-60%)'}*/}
            {/*    moreDetails*/}
            {/*  />*/}
            {/*</span>*/}
            {/*<span style={selectedPackage === 'Bouquet' ? { opacity: 1 } : { opacity: 0.6 }}>*/}
            {/*  <SocialEventCard*/}
            {/*    onClick={function (): void {*/}
            {/*      setSelectedPackage('Bouquet');*/}
            {/*    }}*/}
            {/*    image={'/assets/graphics/bouquet.svg'}*/}
            {/*    title={'Bouquet - '}*/}
            {/*    subtitle="790€"*/}
            {/*    price={' 300€ (-60%)'}*/}
            {/*    moreDetails*/}
            {/*  />*/}
            {/*</span>*/}
          </div>
          <br />
          <br />
          {(selectedPackage?.name === 'Flower' || selectedPackage?.name === 'Bouquet') && (
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 32 }}
              className={styles.selectSocialEvent}
            >
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 16,
                }}
              >
                <p style={{ textAlign: 'center', width: '50vw', maxWidth: 340 }}>
                  {`You can enjoy our services without any immediate payment. We'll notify you when you're close to exceeding the limits of our free service, at which point you can decide to upgrade. `}
                  <strong>If you have a coupon code, now is the time to use it for a great discount!</strong>{' '}
                  {`Feel free to reach out if you have any questions.`}
                </p>
              </span>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {showInput && (
                  <>
                    <div style={{ fontSize: 24 }}>Enter your coupon:</div>
                    {couponError && (
                      <p
                        style={{ color: 'red', textAlign: 'center' }}
                      >{`Coupon code is not valid. If you don't have a coupon code please skip this`}</p>
                    )}
                    <br />
                    <br />

                    <div
                      className={styles.selectSocialEvent}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        alignItems: 'center',
                        gap: 24,
                      }}
                    >
                      <input
                        value={couponInput}
                        onChange={(e) => setCouponInput(String(e.target.value))}
                        className="input"
                        disabled={skipPressed}
                        style={couponError ? { border: '1px solid red' } : {}}
                      />
                    </div>
                  </>
                )}
                <br />
                <br />
                <br />
                <div
                  style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', gap: 24 }}
                >
                  {!showInput ? (
                    <button
                      className="btn btnMain"
                      style={{ maxWidth: '182px' }}
                      onClick={() => {
                        setShowInput(true);
                      }}
                    >
                      Add Coupon
                    </button>
                  ) : (
                    <button
                      className="btn btnMain"
                      style={{ maxWidth: '122px' }}
                      onClick={async () => {
                        //Check if coupon is correct
                        const res = await BrainHelper.couponCheck(userToken, couponInput);
                        //if no show error
                        if (res === null || !res.isValid) {
                          setCouponError(true);
                        } else {
                          setEnteredCoupon(res.coupon);
                        }
                      }}
                    >
                      Apply
                    </button>
                  )}
                  <button
                    className="btn btnSecondary"
                    style={{ maxWidth: '122px' }}
                    onClick={() => {
                      setEnteredCoupon(new Coupon());
                      setCouponInput('-');
                    }}
                  >
                    {showInput ? 'Skip' : 'Continue'}
                  </button>
                </div>
                <br />
                <br />
              </div>
            </div>
          )}
          <br />
          <p></p>
          {selectedPackage?.name === 'Seed' && (
            <span style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ maxWidth: '142px' }}
                className="btn btnMain"
                onClick={() => {
                  setEnteredCoupon(new Coupon());
                  setCouponInput('-');
                }}
              >
                Continue
              </button>
              <br />
              <br />
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      <Header logout backButton />
      {/* <div role={'button'} tabIndex={0} className={styles.logout + ' ' + 'fridayH4'} onMouseDown={() => logout()}>
        Logout
      </div> */}
      <div className={styles.main}>
        <div className={styles.header}>
          <h1 className={'fridayH1'}>
            Let{"'"}s make a {GetUI(selectedEventType).startPlanningEventType}
          </h1>
          <p className={'fridayP'}>A few details to get you started</p>
          {enteredCoupon && enteredCoupon.id !== '' && selectedPackage !== null && (
            <>
              <br />
              <p className={'fridayH4'} style={{ fontWeight: 'bolder', color: '#006150' }}>
                * Used Coupon: {enteredCoupon.id}. Enjoy {selectedPackage.name} package for{' '}
                {selectedPackage.originalPrice -
                  selectedPackage.originalPrice * selectedPackage.discount -
                  (selectedPackage.originalPrice - selectedPackage.originalPrice * selectedPackage.discount) *
                    enteredCoupon.discount}{' '}
                *
              </p>
            </>
          )}
        </div>
        <form ref={formRef} action="#" className={styles.form} onSubmit={(e) => e.preventDefault()}>
          {!selectedEventType ||
            (selectedEventType === EventType.wedding && (
              <div>
                <h4 className={'fridayH4'}>Full Name*</h4>
                <input
                  className={'input'}
                  type="text"
                  placeholder={''}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setWeddingName(
                      (
                        (e.target.value === '' ? '' : e.target.value.split(' ')[0]) +
                        '&' +
                        (partnerName === '' ? '' : partnerName.split(' ')[0])
                      )
                        .split(/[.,/#!$@%^*;:{}=\-_`~()]/g)
                        .join(''),
                    );
                  }}
                />
              </div>
            ))}
          {!selectedEventType ||
            (selectedEventType === EventType.wedding && (
              <div>
                <h4 className={'fridayH4'}>Your Partner{"'"}s Full Name</h4>
                <input
                  className={'input'}
                  type="text"
                  placeholder={''}
                  value={partnerName}
                  onChange={(e) => {
                    setPartnerName(e.target.value);

                    setWeddingName(
                      (
                        (firstName === '' ? '' : firstName.split(' ')[0]) +
                        '&' +
                        (e.target.value === '' ? '' : e.target.value.split(' ')[0])
                      )
                        .split(/[.,/#!$@%^*;:{}=\-_`~()]/g)
                        .join(''),
                    );
                  }}
                />
              </div>
            ))}
          <div>
            <h4 className={'fridayH4'}> {GetUI(selectedEventType).startPlanningEventType} Name*</h4>
            <input
              className={'input'}
              type="text"
              placeholder={''}
              value={weddingName}
              onChange={(e) => {
                if (!selectedEventType || selectedEventType === EventType.wedding) {
                  setWeddingName(e.target.value.split(/[.,/#!@$%^*;:{}=\-_`~()]/g).join(''));
                } else {
                  setWeddingName(e.target.value);
                }
              }}
            />
          </div>
          <CustomDatePicker
            setCalendarOpened={setCalendarOpened}
            disableCalendar={disableCalendar}
            selectedDay={selectedDay}
            calendarOpened={calendarOpened}
            setSelectedDay={setSelectedDay}
            name={`${GetUI(selectedEventType as EventType).ceremonyName} Date`}
          />
          <div>
            <label htmlFor="dateChk" className={'didacticP'}>
              <input
                type="checkbox"
                name={'dateChk'}
                checked={disableCalendar}
                onChange={() => setDisableCalendar(!disableCalendar)}
              />
              No date picked yet
            </label>
          </div>
          <LocationInputComponent
            label={`${GetUI(selectedEventType as EventType).ceremonyName} Location`}
            location={weddingLocation}
            setLocation={setWeddingLocation}
            setCountryShort={setCountryShort}
            required={false}
          />

          {enteredCoupon && couponInput.length > 2 && (
            <div>
              <h4 className={'fridayH4'}>Coupon Used</h4>
              <input disabled value={enteredCoupon.id} className="input" style={{ color: 'gray' }} />{' '}
            </div>
          )}
          {selectedPackage && (
            <div>
              <h4 className={'fridayH4'}>Selected Package</h4>
              <input disabled value={selectedPackage.name} className="input" style={{ color: 'gray' }} />{' '}
              {(couponInput === '-' || !couponInput) && (
                <p style={{ textAlign: 'center', width: '50vw', maxWidth: 340, marginTop: '8px' }}>
                  {`You can enjoy our services without any immediate payment. We'll notify you when you're close to exceeding the limits of our free service, at which point you can decide to upgrade. `}
                  {`Feel free to reach out if you have any questions.`}
                </p>
              )}
            </div>
          )}
          {!isSecondButtonLoading ? (
            <button
              className={'btn btnMain'}
              style={{ marginTop: '50px' }}
              disabled={!completed}
              onClick={onSubmitNewPlanning}
            >
              Let{"'"}s Start
            </button>
          ) : (
            <Lottie options={defaultOptions1} height={50} width={200} />
          )}
        </form>
        {completed && (
          <div
            className="loadingView"
            style={{
              position: 'absolute',
              top: completedStyle.top,
              left: completedStyle.left,
              width: completedStyle.width,
              height: completedStyle.height,
              backdropFilter: 'blur(2px)',
              background: '#fbfbfb63',
              zIndex: '100',
            }}
          >
            <Lottie options={defaultOptions1} height={300} width={300} />
          </div>
        )}
      </div>
      {showModal ? (
        <GenericModal
          showModal={showModal}
          message={modalMessage}
          setShowModal={setShowModal}
          isRedirect={isRedirect}
          redirectPath={redirectPath}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default StartPlanning;
