/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import templateStyles from '../CallMeByYourNameTemplate.module.scss';
import { SVGIconName } from '../../../shared/Icon/Icons';
import { createTheme, Switch, ThemeProvider } from '@mui/material';
import ImageDragNDrop from './ImageDragNDrop';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import moment from 'moment';
import timezoneData from 'moment-timezone/data/meta/latest.json';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import ReactTooltip from 'react-tooltip';
import { Icon } from '../../../shared';

type InputComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

const InputCallMeByYourNameContactInfoComponent = ({
  componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: // autoSave,
InputComponentProps): JSX.Element => {
  const [toggle, setToggle] = useState<boolean>(false);
  const wedding = useSelector(selectWedding);
  const userToken = useSelector(selectAccessTokenAfterOTP);

  const [key, setKey] = useState(-1);

  const [hoverAdd, setHoverAdd] = useState<boolean>(false);
  const [hoverSave, setHoverSave] = useState<boolean>(false);
  const [loading1, setLoading1] = useState<boolean>(false);

  //IMAGE HELPER
  const [fileUrl1, setFileurl1] = useState<string>('');
  const [fileId1, setFileId1] = useState<string | undefined>('');
  const [completedFile1, setCompletedFile1] = useState<boolean>(true);
  const [percentage1, setPercentage1] = useState<number>(0);
  const [isDropzone1Active, setIsDropzone1Active] = useState<boolean>(false);
  const [newContact, setNewContact] = useState<
    | {
        title: string;
        name: string;
        image: string;
        email?: string;
        whatsapp?: string;
        phone?: string;
        messenger?: string;
        viber?: string;
        custom?: string;
      }
    | undefined
  >(undefined);

  const initNewContact = () => {
    setNewContact({
      title: '',
      name: '',
      image: '',
      email: undefined,
      whatsapp: undefined,
      phone: undefined,
      messenger: undefined,
      viber: undefined,
      custom: undefined,
    });
  };

  const emptyComponentValues = {
    CallMeByYourNameContactInfoComponentCustomVisible: false,
    CallMeByYourNameContactInfoComponentDetails: [],
  };

  useEffect(() => {
    if (fileId1 !== '') {
      updateNewContact(fileId1, 'image');
      setLoading1(false);
    }
  }, [fileId1]);

  const [blobs, setBlobs] = useState<Map<string, string>>(new Map());

  const setBlob = (imageBlob: string, fileId: string) => {
    const tempMap = blobs;
    tempMap.set(fileId, imageBlob);
    setBlobs(tempMap);
  };

  const fetchImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') {
      setBlob(result, fileID);
    }
  };
  useEffect(() => {
    if (
      componentProps &&
      componentProps[componentIndex] &&
      componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'] &&
      componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'].length > 0
    ) {
      componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'].map((contact: any) => {
        if (!blobs.get(contact.image)) fetchImage(contact.image);
      });
    }
  }, [componentProps]);

  useEffect(() => {
    if (
      componentProps &&
      componentProps[componentIndex] &&
      componentProps[componentIndex]['CallMeByYourNameContactInfoComponentCustomVisible']
    )
      setToggle(componentProps[componentIndex]['CallMeByYourNameContactInfoComponentCustomVisible']);
  }, [componentProps]);

  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  }, []);

  const updateProps = (input: any, key: string) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      tempProps[componentIndex][key] = input;
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex][key] = input;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  const moveUp = (index: number) => {
    if (
      componentProps &&
      componentProps[componentIndex] &&
      componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'] &&
      componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'].length > 0
    ) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const element = tempProps['CallMeByYourNameContactInfoComponentDetails'][index];
      tempProps['CallMeByYourNameContactInfoComponentDetails'].splice(index, 1);
      tempProps['CallMeByYourNameContactInfoComponentDetails'].splice(index - 1, 0, element);
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  };
  const moveDown = (index: number) => {
    if (
      componentProps &&
      componentProps[componentIndex] &&
      componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'] &&
      componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'].length > 0
    ) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const element = tempProps['CallMeByYourNameContactInfoComponentDetails'][index];
      tempProps['CallMeByYourNameContactInfoComponentDetails'].splice(index, 1);
      tempProps['CallMeByYourNameContactInfoComponentDetails'].splice(index + 1, 0, element);
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  };

  const updateDetails = (input: any, key: number, remove = false) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      const tempArray = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      if (tempArray['CallMeByYourNameContactInfoComponentDetails'] && key < 0)
        tempArray['CallMeByYourNameContactInfoComponentDetails'].push(input);
      else if (tempArray['CallMeByYourNameContactInfoComponentDetails'] && key >= 0 && remove)
        tempArray['CallMeByYourNameContactInfoComponentDetails'].splice(key, 1);
      else if (tempArray['CallMeByYourNameContactInfoComponentDetails'] && key >= 0 && !remove)
        tempArray['CallMeByYourNameContactInfoComponentDetails'][key] = input;
      else tempArray['CallMeByYourNameContactInfoComponentDetails'] = [input];
      tempProps[componentIndex] = tempArray;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };
  const removeHoverEffects = () => {
    setHoverAdd(false);
    setHoverSave(false);
  };

  const updateNewContact = (input: any, key: any) => {
    const temp = JSON.parse(JSON.stringify(newContact));
    if (key === 'phone' || key === 'whatsapp' || key === 'viber') {
      temp[key] = input?.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
    } else temp[key] = input;
    setNewContact(temp);
  };

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            height: 32,
            width: 52,
            padding: 0,
            border: '1px solid #DAD8D9',
            borderRadius: '50px',
          },
          switchBase: {
            // Controls default (unchecked) color for the thumb
            padding: 3,
          },
          thumb: {
            color: '#FFFFFF',
            width: 24,
            height: 24,
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: '.7 !important',
            height: 32,
            width: 52,
            backgroundColor: '#835978 !important',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              opacity: '1 !important',
            },
          },
        },
      },
    },
  });
  const [phoneInputCountry, setPhoneInputCountry] = useState<string>('gr');

  useEffect(() => {
    const timeZoneToCountry = new Map<string, string>();
    const countryName = moment.tz.guess(true);
    Object.values(timezoneData.zones).forEach((z) => {
      timeZoneToCountry.set(z.name, z.countries[0]);
    });
    const countryCode = timeZoneToCountry.get(countryName);
    setPhoneInputCountry(countryCode?.toLowerCase() || 'gr');
  }, []);
  if (!componentProps[componentIndex]) {
    return <></>;
  }

  return (
    <section style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div className={templateStyles.flex}>
        <h2 className={templateStyles.didacticH2}>Contact Section</h2>
        <ThemeProvider theme={theme}>
          <Switch
            checked={toggle}
            onChange={(event, checked) => {
              updateProps(checked, 'CallMeByYourNameContactInfoComponentCustomVisible');
              setToggle((prevState) => !prevState);
            }}
          />
        </ThemeProvider>
      </div>

      <p>
        Display your contact information so that your guests can get in touch with you right away. Please include your
        email address, phone number, and preferred social media channel.
      </p>
      {toggle ? (
        newContact ? (
          <Fragment>
            <div style={{ paddingTop: 24, paddingBottom: 24, gap: 8, display: 'flex', flexDirection: 'column' }}>
              <h3 className="fridayH3">Full Name*</h3>
              <input
                className="input"
                style={{ width: '100%' }}
                value={newContact.name}
                onChange={(e) => updateNewContact(e.target.value, 'name')}
              />
              <h3 className="fridayH3">Title*</h3>
              <input
                className="input"
                style={{ width: '100%' }}
                value={newContact.title}
                placeholder={'ex Bride'}
                onChange={(e) => updateNewContact(e.target.value, 'title')}
              />
              <h3 className="fridayH3">Contact Image*</h3>
              <ImageDragNDrop
                isDropzoneActive={isDropzone1Active}
                setIsDropzoneActive={setIsDropzone1Active}
                userToken={userToken}
                weddingPath={wedding.path}
                percentage={percentage1}
                setCompletedFile={setCompletedFile1}
                loading={loading1}
                setPercentage={setPercentage1}
                setLoading={setLoading1}
                setFileUrl={setFileurl1}
                setFileId={setFileId1}
                updateProps={updateNewContact}
                componentProps={newContact}
              />
              <h3 className="fridayH3">Contact method</h3>
              <p className="didacticP">Please provide one or more contact methods by clicking the buttons below.</p>
              {newContact.email !== undefined && (
                <div
                  className={templateStyles.flex}
                  style={{
                    width: '100%',
                    gap: 8,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingBottom: 16,
                  }}
                >
                  <div
                    className={templateStyles.flex}
                    style={{ gap: 8, alignItems: 'center', justifyContent: 'center' }}
                  >
                    <CircleIcon
                      hoverIconColor="#281B24"
                      hoverBackgroundColor="#FFF"
                      backgroundColor="#63435B"
                      iconColor="#FFF"
                      icon="envelope"
                      onHoverIcon="trash"
                      onClick={() => {
                        updateNewContact(undefined, 'email');
                      }}
                    />
                    <h3 className="fridayH3">Email</h3>
                  </div>
                  <input
                    className="input"
                    style={{ width: '100%' }}
                    value={newContact.email}
                    onChange={(e) => updateNewContact(e.target.value, 'email')}
                  />
                </div>
              )}
              {newContact.phone !== undefined && (
                <div
                  className={templateStyles.flex}
                  style={{ width: '100%', gap: 8, flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <div
                    className={templateStyles.flex}
                    style={{ gap: 8, alignItems: 'center', justifyContent: 'center' }}
                  >
                    <CircleIcon
                      hoverIconColor="#281B24"
                      hoverBackgroundColor="#FFF"
                      backgroundColor="#63435B"
                      iconColor="#FFF"
                      icon="phone_call"
                      onClick={() => {
                        updateNewContact(undefined, 'phone');
                      }}
                      onHoverIcon="trash"
                    />
                    <h3 className="fridayH3">Phone</h3>
                  </div>
                  <PhoneInput
                    country={phoneInputCountry}
                    value={newContact.phone}
                    onChange={(value, data, event, formattedValue) => updateNewContact(formattedValue, 'phone')}
                    onBlur={(event, data: CountryData | Record<string, unknown>) => {
                      if (data && event.target.value.split(' ')[0] !== `+${data.dialCode}`)
                        updateNewContact(
                          `+${data.dialCode} ${event.target.value.slice(1, event.target.value.length)}`,
                          'phone',
                        );
                    }}
                    // style={{ width: '100%' }}
                  />
                </div>
              )}
              {newContact.whatsapp !== undefined && (
                <div
                  className={templateStyles.flex}
                  style={{ width: '100%', gap: 8, flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <div
                    className={templateStyles.flex}
                    style={{ gap: 8, alignItems: 'center', justifyContent: 'center' }}
                  >
                    <CircleIcon
                      hoverIconColor="#281B24"
                      hoverBackgroundColor="#FFF"
                      backgroundColor="#25D366"
                      iconColor="#FFF"
                      icon="whats_app"
                      onHoverIcon="trash"
                      onClick={() => {
                        updateNewContact(undefined, 'whatsapp');
                      }}
                    />
                    <h3 className="fridayH3">WhatsApp</h3>
                  </div>
                  <PhoneInput
                    country={phoneInputCountry}
                    value={newContact.whatsapp}
                    onChange={(value, data, event, formattedValue) => updateNewContact(formattedValue, 'whatsapp')}
                    onBlur={(event, data: CountryData | Record<string, unknown>) => {
                      if (data && event.target.value.split(' ')[0] !== `+${data.dialCode}`)
                        updateNewContact(
                          `+${data?.dialCode} ${event.target.value.slice(1, event.target.value.length)}`,
                          'whatsapp',
                        );
                    }}
                    // style={{ width: '100%' }}
                  />
                </div>
              )}
              {newContact.messenger !== undefined && (
                <div
                  className={templateStyles.flex}
                  style={{
                    width: '100%',
                    gap: 8,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingBottom: 16,
                  }}
                >
                  <div
                    className={templateStyles.flex}
                    style={{ gap: 8, alignItems: 'center', justifyContent: 'center' }}
                  >
                    <CircleIcon
                      hoverIconColor="#281B24"
                      hoverBackgroundColor="#FFF"
                      backgroundColor="linear-gradient(202.14deg, #FF6968 11.92%, #A334FA 47.72%, #645BFC 72.38%, #0695FF 88.29%)"
                      iconColor="#FFF"
                      onHoverIcon="trash"
                      icon="messenger"
                      onClick={() => {
                        updateNewContact(undefined, 'messenger');
                      }}
                    />
                    <h3 className="fridayH3">Messenger</h3>
                  </div>
                  <input
                    className="input"
                    style={{ width: '100%' }}
                    value={newContact.messenger}
                    onChange={(e) => updateNewContact(e.target.value, 'messenger')}
                  />
                </div>
              )}
              {newContact.viber !== undefined && (
                <div
                  className={templateStyles.flex}
                  style={{ width: '100%', gap: 8, flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <div
                    className={templateStyles.flex}
                    style={{ gap: 8, alignItems: 'center', justifyContent: 'center' }}
                  >
                    <CircleIcon
                      hoverIconColor="#281B24"
                      hoverBackgroundColor="#FFF"
                      backgroundColor="#7360F2"
                      iconColor="#FFF"
                      onHoverIcon="trash"
                      icon="viber"
                      onClick={() => {
                        updateNewContact(undefined, 'viber');
                      }}
                    />
                    <h3 className="fridayH3">Viber</h3>
                  </div>

                  <PhoneInput
                    country={phoneInputCountry}
                    value={newContact.viber}
                    onChange={(value, data, event, formattedValue) => updateNewContact(formattedValue, 'viber')}
                    onBlur={(event, data: CountryData | Record<string, unknown>) => {
                      if (data && event.target.value.split(' ')[0] !== `+${data.dialCode}`)
                        updateNewContact(
                          `+${data.dialCode} ${event.target.value.slice(1, event.target.value.length)}`,
                          'viber',
                        );
                    }}
                    // style={{ width: '100%' }}
                  />

                  {/* <input
                    className="input"
                    style={{ width: '100%' }}
                    value={newContact.viber}
                    onChange={(e) => updateNewContact(e.target.value, 'viber')}
                  /> */}
                </div>
              )}

              <div className={templateStyles.flex} style={{ justifyContent: 'flex-start', gap: 24 }}>
                {newContact.email === undefined && (
                  <CircleIcon
                    hoverBackgroundColor="#63435B"
                    iconColor="#281B24"
                    backgroundColor="#FFF"
                    hoverIconColor="#FFF"
                    icon="envelope"
                    onClick={() => {
                      updateNewContact('', 'email');
                    }}
                  />
                )}
                {newContact.phone === undefined && (
                  <CircleIcon
                    hoverBackgroundColor="#63435B"
                    iconColor="#281B24"
                    backgroundColor="#FFF"
                    hoverIconColor="#FFF"
                    icon="phone_call"
                    onClick={() => {
                      updateNewContact('', 'phone');
                    }}
                  />
                )}
                {newContact.whatsapp === undefined && (
                  <CircleIcon
                    hoverBackgroundColor="#25D366"
                    iconColor="#281B24"
                    backgroundColor="#FFF"
                    hoverIconColor="#FFF"
                    icon="whats_app"
                    onClick={() => {
                      updateNewContact('', 'whatsapp');
                    }}
                  />
                )}
                {newContact.messenger === undefined && (
                  <CircleIcon
                    hoverBackgroundColor="linear-gradient(202.14deg, #FF6968 11.92%, #A334FA 47.72%, #645BFC 72.38%, #0695FF 88.29%)"
                    iconColor="#281B24"
                    backgroundColor="#FFF"
                    hoverIconColor="#FFF"
                    icon="messenger"
                    onClick={() => {
                      updateNewContact('', 'messenger');
                    }}
                  />
                )}
                {newContact.viber === undefined && (
                  <CircleIcon
                    hoverBackgroundColor="#7360F2"
                    hoverIconColor="#FFF"
                    backgroundColor="#FFF"
                    iconColor="#281B24"
                    icon="viber"
                    onClick={() => {
                      updateNewContact('', 'viber');
                    }}
                  />
                )}
              </div>
            </div>
            <div className={templateStyles.flex} style={{ justifyContent: 'unset', gap: 8 }}>
              <button
                data-tip="Please fill your Full Name <br /> and Title to enable saving."
                className={
                  templateStyles.btn +
                  ' ' +
                  templateStyles.btnMain +
                  ' ' +
                  templateStyles.btnMainWithImg +
                  (!(newContact.name !== '') ? ' ' + templateStyles.btnMainDisabled : '')
                }
                style={{ maxWidth: 131, padding: 12, gap: 8 }}
                onMouseEnter={() => setHoverSave(true)}
                onMouseLeave={() => setHoverSave(false)}
                onClick={() => {
                  if (newContact.name !== '') {
                    updateDetails(newContact, key);
                    setNewContact(undefined);
                    setKey(-1);
                    removeHoverEffects();
                  }
                }}
              >
                <Icon name={'save'} size={'s'} color={hoverSave ? '#FFFFFF' : '#63435B'} />
                Save
              </button>
              {!(newContact.name !== '') && (
                <ReactTooltip
                  effect={'solid'}
                  place={'top'}
                  multiline={true}
                  border={true}
                  textColor={'#63435B'}
                  backgroundColor={'#FDF6EB'}
                  borderColor={'#63435B'}
                />
              )}
              <button
                className={templateStyles.btn + ' ' + templateStyles.btnSecondary}
                style={{ maxWidth: 131, padding: 12 }}
                onClick={() => {
                  setNewContact(undefined);
                  setKey(-1);
                  removeHoverEffects();
                }}
              >
                Cancel
              </button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ paddingTop: 8, paddingBottom: 24, gap: 8, display: 'flex', flexDirection: 'column' }}>
              {componentProps &&
                componentProps[componentIndex] &&
                componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'] &&
                componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'].length > 0 &&
                componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'].map(
                  (contact: any, index: any) => {
                    return (
                      <SmallContactInfo
                        key={contact.name + index}
                        index={index}
                        onEdit={() => {
                          setKey(index);
                          setNewContact(contact);
                        }}
                        onDelete={() => {
                          updateDetails(undefined, index, true);
                        }}
                        onMoveDown={() => {
                          moveDown(index);
                        }}
                        onMoveUp={() => {
                          moveUp(index);
                        }}
                        contact={contact}
                        totalLength={
                          componentProps[componentIndex]['CallMeByYourNameContactInfoComponentDetails'].length
                        }
                        blobs={blobs}
                      />
                    );
                  },
                )}
            </div>
            <button
              className={'btnMain btnMainWithImg btnMedium'}
              onMouseEnter={() => setHoverAdd(true)}
              onMouseLeave={() => setHoverAdd(false)}
              onClick={() => initNewContact()}
              style={{ width: '234px' }}
            >
              <Icon name={'plus'} size={'s'} color={hoverAdd ? '#FFFFFF' : '#63435B'} /> Add Contact Person
            </button>
          </Fragment>
        )
      ) : (
        <></>
      )}
    </section>
  );
};

export default InputCallMeByYourNameContactInfoComponent;

const CircleIcon = ({
  icon,
  onHoverIcon,
  hoverBackgroundColor,
  backgroundColor,
  hoverIconColor,
  iconColor,
  onClick,
}: {
  icon: SVGIconName;
  onHoverIcon?: SVGIconName;
  hoverBackgroundColor: string;
  backgroundColor: string;
  hoverIconColor: string;
  iconColor: string;
  onClick: any;
}) => {
  const [hover, setHover] = useState(false);

  const renderBorder = () => {
    if (!hover) {
      return '1px solid ' + iconColor;
    } else {
      return '1px solid ' + hoverIconColor;
    }
  };
  return (
    <button
      style={{
        display: 'flex',
        border: renderBorder(),
        borderRadius: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        background: hover ? hoverBackgroundColor : backgroundColor,
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onTouchStart={() => {
        setHover(true);
      }}
      onTouchEnd={() => {
        setHover(false);
      }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <Icon
        name={onHoverIcon ? (hover ? onHoverIcon : icon) : icon}
        size="s"
        color={hover ? hoverIconColor : iconColor}
      />
    </button>
  );
};

const SmallContactInfo = ({
  index,
  contact,
  onEdit,
  onDelete,
  onMoveUp,
  onMoveDown,
  totalLength,
  blobs,
}: {
  index: number;
  totalLength: number;
  contact: any;
  onEdit: any;
  onDelete: any;
  onMoveUp?: any;
  onMoveDown?: any;
  blobs: any;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div>
      <div className={templateStyles.flex} style={{ paddingBottom: 16, justifyContent: 'space-between' }}>
        <div className={templateStyles.flex} style={{ paddingBottom: 4, justifyContent: 'flex-start', gap: 8 }}>
          {blobs.get(contact.image) ? (
            <img
              src={blobs.get(contact.image)}
              alt="Contact"
              style={{ objectFit: 'cover', borderRadius: '50px', width: 72, height: 72 }}
            />
          ) : (
            <div
              style={{
                borderRadius: '50px',
                width: 72,
                height: 72,
                backgroundColor: '#63435B',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FFF',
              }}
            >
              {contact.name[0].toUpperCase()}
            </div>
          )}
          <div>
            <h3 className="didacticH3">{contact.name}</h3>
            <h4 className="didacticH4">{contact.title}</h4>
          </div>
        </div>
        <div className={templateStyles.flex} style={{ gap: 16 }}>
          {/* !TODO ORDERING */}
          {index > 0 && (
            <button
              className={'btnXSmall btnBordered '}
              onClick={() => {
                onMoveUp();
              }}
            >
              <Icon name={'arrow_up'} size={'s'} color={'#63435B'} />
            </button>
          )}
          {index < totalLength - 1 && (
            <button
              className={'btnXSmall btnBordered '}
              onClick={() => {
                onMoveUp();
                onMoveDown();
              }}
            >
              <Icon name={'arrow_down'} size={'s'} color={'#63435B'} />
            </button>
          )}
          <button
            className={'btnXSmall btnBordered '}
            onClick={() => {
              onEdit();
            }}
          >
            <Icon name={'edit'} size={'s'} color={'#63435B'} />
          </button>
          <button
            className={'btnXSmall btnBordered '}
            onClick={() => {
              onDelete();
            }}
          >
            <Icon name={'trash'} size={'s'} color={'#63435B'} />
          </button>
        </div>
      </div>
      <div className={templateStyles.flex} style={{ justifyContent: 'flex-start', gap: 24, marginBottom: 12 }}>
        {contact.email !== undefined && (
          <CircleIcon
            hoverBackgroundColor="#63435B"
            iconColor="#281B24"
            backgroundColor="#FFF"
            hoverIconColor="#FFF"
            icon="envelope"
            onHoverIcon="edit"
            onClick={() => {
              onEdit(index);
            }}
          />
        )}
        {contact.phone !== undefined && (
          <CircleIcon
            hoverBackgroundColor="#63435B"
            iconColor="#281B24"
            backgroundColor="#FFF"
            hoverIconColor="#FFF"
            icon="phone_call"
            onHoverIcon="edit"
            onClick={() => {
              onEdit(index);
            }}
          />
        )}
        {contact.whatsapp !== undefined && (
          <CircleIcon
            hoverBackgroundColor="#25D366"
            iconColor="#281B24"
            backgroundColor="#FFF"
            hoverIconColor="#FFF"
            icon="whats_app"
            onHoverIcon="edit"
            onClick={() => {
              onEdit(index);
            }}
          />
        )}
        {contact.messenger !== undefined && (
          <CircleIcon
            hoverBackgroundColor="linear-gradient(202.14deg, #FF6968 11.92%, #A334FA 47.72%, #645BFC 72.38%, #0695FF 88.29%)"
            iconColor="#281B24"
            backgroundColor="#FFF"
            hoverIconColor="#FFF"
            icon="messenger"
            onHoverIcon="edit"
            onClick={() => {
              onEdit(index);
            }}
          />
        )}
        {contact.viber !== undefined && (
          <CircleIcon
            hoverBackgroundColor="#7360F2"
            hoverIconColor="#FFF"
            backgroundColor="#FFF"
            iconColor="#281B24"
            icon="viber"
            onHoverIcon="edit"
            onClick={() => {
              onEdit(index);
            }}
          />
        )}
      </div>
    </div>
  );
};
