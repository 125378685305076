/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, Fragment } from 'react';
import templateStyles from '../sepiaTemplate.module.scss';
import ReactQuill from 'react-quill';

type InputComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

const InputSepiaOurStoryComponent = ({
  componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: InputComponentProps): any => {
  const emptyComponentValues = {
    title: 'Our Story',
    description: '',
    graphicsImageUrl: '/templates/sepia/ourStoryComponentGraphics.svg',
  };

  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
    }
  }, []);

  const updateProps = (input: any, key: string) => {
    if (componentProps && componentProps[componentIndex]) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      if (tempProps[componentIndex]) {
        tempProps[componentIndex][key] = input;
      } else {
        tempProps[componentIndex] = new Object();
        tempProps[componentIndex][key] = input;
      }
      updateComponentProps(tempProps[componentIndex], componentIndex);
      autoSave();
    }
  };

  if (!componentProps || !componentProps[componentIndex]) {
    return <Fragment />;
  }
  return (
    <section style={{ marginBottom: '64px' }}>
      <h2 className={templateStyles.didacticH2}>Second Section</h2>
      <p className={templateStyles.didacticP} style={{ paddingBottom: '24px' }}>
        Is usually used to tell your story.
      </p>
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
        Section Title*
      </h3>
      <input
        style={{ marginBottom: '24px', width: '100%' }}
        className={templateStyles.input}
        value={componentProps[componentIndex]['title'] ?? 'Our Story'}
        onChange={(e) => updateProps(e.target.value, 'title')}
      />
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
        Description*
      </h3>
      <ReactQuill
        theme="snow"
        onChange={(e) => updateProps(e, 'description')}
        value={componentProps[componentIndex]['description'] ?? ''}
      />
    </section>
  );
};

export default InputSepiaOurStoryComponent;
