/* eslint-disable jsx-a11y/no-autofocus */
import './OTP.scss';

import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// import OtpInput from 'react-otp-input';

import {
  selectIsLoggedIn,
  selectMarriageCode,
  setAccessToken,
  setAccessTokenAfterOTP,
  setConnectedAccount,
  setJwt,
  setLoggedIn,
  setTokenExpiryDate,
  user,
} from '../../../app/redux/authorization.slice.reducer';
import { logger } from '../../../config/Logger';
import { AuthHelper } from '../../../pkg/apiHelpers/authHelper';
import { GetDefaultRolesRequest, LoginRequest, RoleDto } from '../../../pkg/protobuf/v2/auth/auth_types_pb';
import { SignInScreenComponentState } from './SignInScreenComponent';
import OtpInput from '../../shared/OtpInput/OtpInput';
import Lottie from 'react-lottie';
import location from '../../../loader-animation.json';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { setWedding } from '../../../app/redux/wedding.slice.recuder';
import { toast, ToastContainer } from 'react-toastify';
import Icon from '../../../shared/Icon';
import { FirebaseContext } from '../../../context/firebase.context';
import firebase from 'firebase';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const OTP = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const marriageCode = useSelector(selectMarriageCode);
  const defaultState: Readonly<SignInScreenComponentState> = {
    isSignedIn: false,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = React.useState(defaultState);
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otpError, setOtpError] = useState(false);
  const [role, setRole] = useState(new RoleDto());

  useEffect(() => {
    FirebaseContext.getAuth();
    if (isLoggedIn) navigate('/main');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // if (!window.confirmationResult) navigate('/');
    async function fetchStatus() {
      const requestMessage: GetDefaultRolesRequest = new GetDefaultRolesRequest();
      const resp = await AuthHelper.publicGetDefaultRolesPromise(requestMessage);
      resp?.roles.map((value) => {
        logger.debug({ message: value.name });
        if (value.name === 'defaultVisitorRoleAll') setRole(value);
      });
    }

    const query = new URLSearchParams(Object(location).search);
    onSubmitOtp(query.get('otp') || '');
    fetchStatus();
  }, []);

  const handlePaste = (e: {
    preventDefault: () => void;
    clipboardData: any;
    target: { value: React.SetStateAction<string> };
  }) => {
    e.preventDefault();
    const paste = String((e.clipboardData || window.Clipboard).getData('text'));
    // console.log('paste', paste.substring(0, 6));
    const finalInput = paste.substring(0, 6);
    if (!isNaN(parseFloat(finalInput)) && isFinite(Number(finalInput))) {
      onSubmitOtp(finalInput);
    }
  };

  const onSubmitOtp = async (value: string) => {
    if (value.length < 6) {
      setOtp(value);
    } else {
      setLoading(true);

      setOtp(value);
      const otpInput = value;
      logger.debug({ message: otpInput });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let result: any;
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const optConfirm = await window.confirmationResult;
        result = await optConfirm.confirm(otpInput);
      } catch (err: any) {
        setOtpError(true);
        logger.error({ message: err, functionName: 'OTP.onSubmitOtp catch window confirm' });
        toast('Your OTP code is either incorrect or has expired please go back and try again.', {
          icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
          style: { backgroundColor: '#F8AAB8' },
        });
        setOtp('');
        setOtpError(false);
        setLoading(false);
        throw err;
      }
      try {
        // User signed in successfully.
        const user = result.user;
        const jwtFirebaseAuthToken = await user?.getIdToken(true);
        // send auth token to backend and get another reinforced JWT token with roles, names, access_level.
        // you will be able to use the new auth token for all the endpoints but you will be able to use the firebase token only for login
        if (jwtFirebaseAuthToken) {
          const loginRequest = new LoginRequest();
          loginRequest.jwtToken = jwtFirebaseAuthToken;
          loginRequest.roleId = role.id;
          const resp = await AuthHelper.loginRegisterFirebaseUser(loginRequest);
          if (!resp?.jwtToken) {
            // TODO: react an nextjs error?
            logger.error({ message: 'empty user token', functionName: 'OTP.onSubmitOtp.loginRegisterFirebaseUser' });
            throw new Error('empty user token');
          }

          //TODO: GET ENDPOINT FOR EXISTING MARRIAGE
          // const marriageCode = useSelector(selectMarriageCode);
          // const loggedInResp = await AuthHelper.isMarriageExist(marriageCode);

          const weddingData = await BrainHelper.getWedding(
            resp.jwtToken,
            marriageCode,
            dispatch,
            null,
            null,
            setWedding,
          );
          const decoded = jwt_decode(resp.jwtToken) as user;
          setPhoneNumber(decoded.phoneNumber);
          const connectAccountResponse = await BrainHelper.connectAccount(
            resp.jwtToken,
            weddingData?.id,
            decoded.accountId,
            dispatch,
            null,
            null,
            null,
            setConnectedAccount,
            setJwt,
          );
          if (connectAccountResponse == null) {
            setLoading(false);
            setOtp('');
            toast(
              'The mobile number you entered is not associated with an account. Please make sure you are using the mobile number that you registered or were invited with.',
              {
                icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
                style: { backgroundColor: '#F8AAB8' },
              },
            );
            return;
          }
          setState({ isSignedIn: true });

          // read jwt token
          // const decoded: any = jwt_decode(resp?.jwtToken);
          const expiredIn = decoded.exp;

          // store token data in redux state
          dispatch(setLoggedIn(true));
          dispatch(setAccessToken(resp?.jwtToken));
          dispatch(setAccessTokenAfterOTP(resp?.jwtToken));
          dispatch(setTokenExpiryDate(Number(expiredIn)));
          setLoading(false);
          if (resp?.needsProfileSetup) navigate('/intro');
          else navigate('/main');
        } else {
          logger.error({ message: 'token is empty cannot login or register', functionName: 'OTP.onSubmitOtp' });
          throw new Error('token is empty cannot login or register');
        }
      } catch (error: any) {
        setOtpError(true);
        logger.error({ message: error, functionName: 'OTP.onSubmitOtp catch' });
        setOtp('');
        setOtpError(false);
        setLoading(false);
        throw error;
      }
    }
  };

  const setUpRecaptcha = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    logger.info({ message: window.recaptchaVerifier });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: async function () {
        console.log('Captcha Resolved');
        // await onSignInSubmit();
      },
      defaultCountry: 'GR',
    });
  };

  return (
    <div className="otp">
      <div className="otp-logo">
        <img src="/assets/logos/whiteclover-logo1.svg" alt="" />
      </div>
      <div className="otp-text">
        <p>Fill the one time code send to your mobile phone</p>
        <div id="divOuter">
          <div id="divInner" className="otp-code">
            {loading && <Lottie options={defaultOptions1} height={40} width={240} />}
            {!loading && <OtpInput onChange={onSubmitOtp} onPaste={handlePaste} value={otp} />}
          </div>
        </div>
        <button
          disabled={!phoneNumber}
          className={'btn btnRounded btnSmall btnAction'}
          style={{ marginTop: 32 }}
          onClick={async () => {
            if (phoneNumber) {
              setUpRecaptcha();
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const appVerifier = window.recaptchaVerifier;
              const confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              firebase.auth().window.confirmationResult = confirmationResult;
            }
          }}
        >
          Resend OTP Code
        </button>
      </div>
      <ToastContainer
        closeButton={false}
        style={{ width: 'fit-content', borderRadius: 8 }}
        icon={<Icon name={'accept'} color={'#006150'} size={'s'} />}
        position="bottom-center"
        autoClose={10000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      <div id="recaptcha-container" />
    </div>
  );
};

export default OTP;
