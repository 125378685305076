/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isTooManyTries, retryAsync } from 'ts-retry';

import success from '../../1127-success.json';
import {
  addListAccounts,
  addListConnectAccounts,
  selectAccessTokenAfterOTP,
  selectAccount,
  selectIsLoggedIn,
  selectJwt,
  selectListAccounts,
  selectListConnectAccounts,
  selectMarriageCode,
  selectProfile,
  selectProfileImages,
  selectStreamFired,
  selectUser,
  setConnectedAccount,
  setJwt,
  setListAccounts,
  setListConnectAccounts,
  setProfile,
  setProfileImages,
  // setStreamFired,
  setUser,
  user,
} from '../../app/redux/authorization.slice.reducer';
import {
  addTags,
  selectCommentInteractions,
  selectComments,
  selectNotifications,
  selectPostInteractions,
  selectPosts,
  selectWedding,
  setCommentInteractionReducer,
  setCommentsReducer,
  setHasNotification,
  setMaxNumberOfPosts,
  setNotifications,
  setPostInteractionReducer,
  setPostsReducer,
  setTags,
  setWedding,
} from '../../app/redux/wedding.slice.recuder';
import { GlobalState } from '../../experienceApp/screens/Home/Home';
import { logger } from '../../config/Logger';
import location from '../../loader-animation.json';
import { BrainHelper } from '../../pkg/apiHelpers/brainHelper';
import {
  Comment,
  CommentInteraction,
  // ConnectedAccount,
  ConnectedAccountRef,
  // GetEventStreamResponse,
  Notification,
  Post,
  PostInteraction,
  Tag,
} from '../../pkg/protobuf/v2/brain/brain_types_pb';
import { Outlet } from 'react-router-dom';
import DashboardLayout from '../../component/dashboard/dashboardLayout/DashboardLayout';
import firebase from 'firebase';
import cleanLocalStorage from '../../app/helper/CleanLocalStorage';
import { toast } from 'react-toastify';
import Icon from '../../shared/Icon';
import { lottieDefaultOptions, lottieDefaultOptions2 } from '../../shared/Options';

const ProtectedRoute = (): JSX.Element => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  // const streamFired = useSelector(selectStreamFired);
  const user = useSelector(selectUser);
  const userRef = React.useRef<user>(user);
  userRef.current = user;
  const marriageCode = useSelector(selectMarriageCode);
  const wedding = useSelector(selectWedding);
  const account = useSelector(selectAccount);
  const connectedAccounts = useSelector(selectListConnectAccounts);
  const connectedAccountsRef = React.useRef<ConnectedAccountRef[]>(connectedAccounts);
  connectedAccountsRef.current = connectedAccounts;
  const accounts = useSelector(selectListAccounts);
  const accountsRef = React.useRef<ConnectedAccountRef[]>(accounts);
  accountsRef.current = accounts;
  // const profile = useSelector(selectProfile);
  const notifications = useSelector(selectNotifications);
  const notificationsRef = React.useRef<Notification[]>(notifications);
  notificationsRef.current = notifications;
  const dispatch = useDispatch();
  const profileImages = useSelector(selectProfileImages);
  const profileImagesRef = React.useRef<Map<string, string>>(profileImages);
  profileImagesRef.current = profileImages;

  // start loading page by default
  const [intPageLoading, setInitPageLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  const defaultState: Readonly<GlobalState> = {
    status: 'failed to contact the backend',
    streamEvents: [],
    userToken,
    user,
    wedding,
    marriageCode,
    account,
    connectedAccounts: [],
  };
  const [state, setState] = React.useState(defaultState);
  // create mutable state, so we can send it to other functions
  const stateRef = React.useRef<GlobalState>(state);
  stateRef.current = state;
  const jwt = useSelector(selectJwt);
  const jwtRef = React.useRef<string>(jwt);
  jwtRef.current = jwt;
  const posts = useSelector(selectPosts);
  const comments = useSelector(selectComments);
  const postInteractions = useSelector(selectPostInteractions);
  const commentInteractions = useSelector(selectCommentInteractions);
  const postRef = React.useRef<Post[]>(posts);
  postRef.current = posts;
  const commentRef = React.useRef<Comment[]>(comments);
  commentRef.current = comments;
  const postInteractionRef = React.useRef<PostInteraction[]>(postInteractions);
  postInteractionRef.current = postInteractions;
  const commentInteractionRef = React.useRef<CommentInteraction[]>(commentInteractions);
  commentInteractionRef.current = commentInteractions;

  const navigate = useNavigate();

  let cancelStream: () => void;

  const mainFunc = async (
    userToken: string,
    marriageCode: string,
    accountID: string,
    OneSignal: any,
    postsList: Post[],
    commentsList: Comment[],
    postInteractionsList: PostInteraction[],
    commentsInteractionsList: CommentInteraction[],
    currentConnectedAccount: ConnectedAccountRef,
    stateRef: React.MutableRefObject<GlobalState>,
    setState: React.Dispatch<React.SetStateAction<Readonly<GlobalState>>>,
    userRef: React.MutableRefObject<user>,
    commentRef: React.MutableRefObject<Comment[]>,
    dispatch: any,
    setPostsReducer: any,
    setProfileImages: any,
    profileImagesRef: React.MutableRefObject<Map<string, string>>,
    setNotifications: any,
    setHasNotification: any,
    setWedding: any,
    setConnectedAccount: any,
    jwtRef: React.MutableRefObject<string>,
    setJwt: any,
    setListConnectAccounts: ActionCreatorWithOptionalPayload<ConnectedAccountRef[] | undefined, string>,
    addListConnectAccounts: ActionCreatorWithOptionalPayload<ConnectedAccountRef[] | undefined, string>,
    setListAccounts: ActionCreatorWithOptionalPayload<ConnectedAccountRef[] | undefined, string>,
    addListAccounts: ActionCreatorWithOptionalPayload<ConnectedAccountRef[] | undefined, string>,
    setProfile: any,
    setCommentsReducer: any,
    setPostInteractionReducer: any,
    connectedAccountsRef: React.MutableRefObject<ConnectedAccountRef[]>,
    accountsRef: React.MutableRefObject<ConnectedAccountRef[]>,
    postRef: React.MutableRefObject<Post[]>,
    postInteractionRef: React.MutableRefObject<PostInteraction[]>,
    notificationsRef: React.MutableRefObject<Notification[]>,
    setMaxNumberOfPosts: any,
    setCommentInteractionReducer: any,
    commentInteractionRef: React.MutableRefObject<CommentInteraction[]>,
    setTags: ActionCreatorWithOptionalPayload<Tag[] | undefined, string>,
    addTags: ActionCreatorWithOptionalPayload<Tag[] | undefined, string>,
  ) => {
    const online = window.navigator.onLine;
    if (online) {
      // if we are online then clean the redux storages
      dispatch(setPostsReducer([]));
      dispatch(setPostInteractionReducer([]));
      dispatch(setCommentInteractionReducer([]));
      dispatch(setCommentsReducer([]));
      dispatch(setNotifications([]));
    }

    try {
      logger.info({
        message: `before getWedding + marriageCode: ${marriageCode}`,
        functionName: 'ProtectedRoute.mainFunc',
      });
      const weddingData = await BrainHelper.getWedding(
        userToken,
        marriageCode,
        dispatch,
        stateRef,
        setState,
        setWedding,
      );
      logger.info({ message: `before connectAccount`, functionName: 'ProtectedRoute.mainFunc' });
      const connectAccountResponse = await BrainHelper.connectAccount(
        userToken,
        weddingData?.id,
        accountID,
        dispatch,
        stateRef,
        setState,
        userRef,
        setConnectedAccount,
        setJwt,
      );
      console.log(1, connectAccountResponse);
      dispatch(setProfile(connectAccountResponse?.connectedAccount));
      const connectedAccounts = await BrainHelper.listConnectedAccountDetails(
        connectAccountResponse?.jwtToken,
        [],
        dispatch,
        setListConnectAccounts,
      );
      logger.debug({ message: `FETCHING ACCOUNTS... ${new Date().toISOString()}` });
      await BrainHelper.collectPhotosForConnectedAccounts(
        connectAccountResponse?.jwtToken,
        connectedAccounts,
        dispatch,
        setListAccounts,
        setProfileImages,
      );
      logger.debug({ message: `FETCHING NOTIFICATIONS... ${new Date().toISOString()}` });
      BrainHelper.listNotifications(
        connectAccountResponse?.jwtToken,
        dispatch,
        setNotifications,
        setHasNotification,
      ).catch((e) => {
        logger.error({ message: e, functionName: 'ProtectedRoute.mainFunc' });
        throw e;
      });
      logger.debug({ message: `FETCHING POSTS... ${new Date().toISOString()}` });
      BrainHelper.getListPostsOrderedByUpdatedTimeDesc(
        0,
        connectAccountResponse?.jwtToken,
        weddingData.feedId,
        postsList,
        commentsList,
        postInteractionsList,
        commentsInteractionsList,
        postRef,
        dispatch,
        setPostsReducer,
        setCommentsReducer,
        setPostInteractionReducer,
        setCommentInteractionReducer,
        setMaxNumberOfPosts,
      ).catch((e) => {
        logger.error({ message: e, functionName: 'ProtectedRoute.mainFunc' });
        throw e;
      });
      logger.debug({ message: `FETCHING TAGS... ${new Date().toISOString()}` });
      BrainHelper.listTags(connectAccountResponse?.jwtToken, weddingData?.id, dispatch, setTags).catch((e) => {
        logger.error({ message: e, functionName: 'ProtectedRoute.mainFunc' });
        throw e;
      });

      // no need to await for stream events
      logger.debug({ message: 'Stream is about to be fired!' });
      cancelStream = await BrainHelper.streamEvents(
        stateRef,
        setState,
        postRef,
        commentRef,
        postInteractionRef,
        commentInteractionRef,
        dispatch,
        setPostsReducer,
        setCommentsReducer,
        setPostInteractionReducer,
        setCommentInteractionReducer,
        connectAccountResponse?.jwtToken,
        weddingData?.id,
        addListConnectAccounts,
        addListAccounts,
        connectedAccountsRef,
        accountsRef,
        setProfile,
        setHasNotification,
        setNotifications,
        notificationsRef,
        profileImagesRef,
        setProfileImages,
        addTags,
        cancelStream,
      );
    } catch (e) {
      logger.error({ message: e, functionName: 'ProtectedRoute.mainFunc' });
      throw e;
    }

    // Onesignal for android push web notification
    // setup event listener for onesignal
    OneSignal.push(function () {
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on('subscriptionChange', function (isSubscribed: boolean) {
        console.log("The user's subscription state is now:", isSubscribed);
        console.log(OneSignal);
      });
      // This event can be listened to via the `on()` or `once()` listener.

      OneSignal.on('customPromptClick', function (promptClickResult: any) {
        console.log('HTTP Pop-Up Prompt click result:', promptClickResult);
      });
      // This event can be listened to via the on() or once() listener

      OneSignal.on('popoverShown', function () {
        console.log('Slide Prompt Shown');
      });

      OneSignal.on('popoverAllowClick', async function () {
        console.log('Slide Prompt Shown ALLOWED CLICKED');
        const oneSignalPlayerID = await OneSignal.getUserId();
        const oneSignalEmailPlayerID = await OneSignal.getEmailId();
        console.log('playerIDs', oneSignalPlayerID, oneSignalEmailPlayerID);

        // wait until we have a playerID
        retryAsync(
          async () => {
            const oneSignalPlayerID: string = await OneSignal.getUserId();
            const oneSignalEmailPlayerID: string = await OneSignal.getEmailId();
            return { oneSignalPlayerID, oneSignalEmailPlayerID };
          },
          {
            delay: 1000,
            maxTry: 25,
            until: ({ oneSignalPlayerID, oneSignalEmailPlayerID }) => {
              return oneSignalPlayerID != undefined || oneSignalEmailPlayerID != undefined;
            },
          },
        )
          .then((result) => {
            // // send playerid to backend
            logger.log({ message: 'send playerid to backend', functionName: 'ProtectedRoute.mainFunc' });

            BrainHelper.setNotificationDevice(
              jwtRef.current,
              result.oneSignalEmailPlayerID || result.oneSignalPlayerID,
              '',
            ).catch((err) => {
              logger.error({ message: err, functionName: 'ProtectedRoute.OneSignal.retryAsync.setNotification' });
            });
          })
          .catch((err) => {
            if (isTooManyTries(err)) {
              logger.info({ message: err });
            } else {
              logger.error({ message: err, functionName: 'ProtectedRoute.OneSignal.retryAsync' });
            }
          });
      });
    });
  };

  useEffect(() => {
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    OneSignal.push(function () {
      OneSignal.SERVICE_WORKER_PARAM = { scope: '/push/onesignal/' };
      OneSignal.SERVICE_WORKER_PATH = 'push/onesignal/OneSignalSDKWorker.js';
      OneSignal.SERVICE_WORKER_UPDATER_PATH = 'push/onesignal/OneSignalSDKUpdaterWorker.js';
      OneSignal.init({
        appId: '471b1a28-8446-4c6b-a721-efe0a7666cf4',
      });
    });

    if (!isLoggedIn) navigate('/');

    const decoded = jwt_decode(userToken) as user;
    dispatch(setUser(decoded));
    logger.info({ message: decoded });
    setState({
      ...state,
      user: decoded,
    });

    // if (!streamFired) {
    mainFunc(
      userToken,
      marriageCode || wedding.path,
      decoded.accountId,
      OneSignal,
      posts,
      comments,
      postInteractions,
      commentInteractions,
      account,
      stateRef,
      setState,
      userRef,
      commentRef,
      dispatch,
      setPostsReducer,
      setProfileImages,
      profileImagesRef,
      setNotifications,
      setHasNotification,
      setWedding,
      setConnectedAccount,
      jwtRef,
      setJwt,
      setListConnectAccounts,
      addListConnectAccounts,
      setListAccounts,
      addListAccounts,
      setProfile,
      setCommentsReducer,
      setPostInteractionReducer,
      connectedAccountsRef,
      accountsRef,
      postRef,
      postInteractionRef,
      notificationsRef,
      setMaxNumberOfPosts,
      setCommentInteractionReducer,
      commentInteractionRef,
      setTags,
      addTags,
    )
      .then(() => {
        // close loading page
        setCompleted(true);
      })
      .catch((e) => {
        // close loading page
        setCompleted(true);

        logger.error({ message: e, functionName: 'ProtectedRoute.useEffect' });
        if (e.message.includes('token')) {
          logger.info({
            message: 'clear local storage failed mainFunc',
            functionName: 'ProtectedRoute.useEffect',
          });
          cleanLocalStorage();
          window.location.pathname = '/';
          window.location.reload();
        }
      });

    // check periodically if the token is expired then logout
    setInterval(function () {
      if (user && user.exp > 0) {
        if (user.exp * 1000 < Date.now()) {
          logger.info({ message: 'clear local storage', functionName: 'ProtectedRoute.useEffect' });
          firebase.auth().signOut();
          cleanLocalStorage();
          window.location.pathname = '/';
        }
      }
    }, 60 * 1000); // 60 * 1000 milsec

    return () => {
      try {
        logger.info({ message: 'cancelling stream', functionName: 'ProtectedRoute.useEffect' });
        if (cancelStream) cancelStream();
      } catch (e: any) {
        logger.debug({ message: e.message, functionName: 'ProtectedRoute.useEffect.cancelStream' });
      }
    };
  }, []);

  // if (!isLoggedIn) {
  //   return <Redirect noThrow to="login" />;
  // }

  return (
    <>
      {!completed ? (
        <div className="loadingView">
          {!intPageLoading ? (
            <Lottie options={lottieDefaultOptions} height={300} width={300} />
          ) : (
            <Lottie options={lottieDefaultOptions2} height={300} width={300} />
          )}
        </div>
      ) : (
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      )}
    </>
  );
};

export default ProtectedRoute;
