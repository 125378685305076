import { Location } from '../../pkg/protobuf/v2/brain/brain_types_pb';

export const formatLocation = (location: Location): string => {
  const name = location.name || '';
  const address = location.address || '';

  if (name && address && !address.includes(name)) {
    return `${name}, ${address}`;
  } else if (name && address && address.includes(name)) {
    return address;
  } else {
    return name || address;
  }
};
