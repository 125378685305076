import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import Icon from '../../../../shared/Icon';
import { SVGIconName } from '../../../../shared/Icon/Icons';
import styles from './Taxonomy.module.scss';
import { setInvitedVisitors } from '../../../../app/redux/wedding.slice.recuder';
import { useDispatch } from 'react-redux';

const Taxonomy = (props: {
  taxonomyText: string;
  taxonomyId?: string;
  taxonomyOptionId?: string;
  isSelected: boolean;
  selectedCallback?: any;
  isSelectable?: boolean;
  isNew?: boolean;
  deletable?: boolean;
  userToken?: string;
  weddingId?: string;
  deleteCallback?: any;
  icon?: SVGIconName;
  color?: string;
}): JSX.Element => {
  const [isSelected, setIsSelected] = useState(props.isSelected);
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const confirmDelete = () => {
    deleteTaxonomyOptionHelper();
  };

  const deleteTaxonomyOptionHelper = async () => {
    if (props.taxonomyOptionId === undefined && props.taxonomyId) {
      const taxonomyList = await BrainHelper.listTaxonomyOptions(props.taxonomyId, props.userToken ?? '');
      if (taxonomyList)
        for (const tag of taxonomyList) {
          if (props.taxonomyText === tag.key) {
            await BrainHelper.deleteTaxonomyOption(tag.id, props.weddingId ?? '', props.userToken ?? '');
            if (props.deleteCallback) props.deleteCallback();
          }
        }
    } else {
      await BrainHelper.deleteTaxonomyOption(
        props.taxonomyOptionId ?? '',
        props.weddingId ?? '',
        props.userToken ?? '',
      );
      if (props.deleteCallback) props.deleteCallback();
    }
    await BrainHelper.getAllowedVisitors(
      props.userToken,
      props.weddingId ?? '',
      '',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      dispatch,
      setInvitedVisitors,
    );
    handleClose();
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    setIsSelected(props.isSelected);
  }, [props.isSelected]);
  return (
    <div
      style={{ display: 'flex' }}
      className={styles.taxonomyWrapper + ' ' + (props.deletable ? styles.taxonomyWrapperAnimation : '')}
    >
      <button
        className={
          styles.taxonomy +
          ' ' +
          (props.isSelectable ? styles.isSelectable : ' ') +
          ' ' +
          (props.isNew ? styles.isNew : '') +
          ' ' +
          (isSelected ? styles.isSelected : '')
        }
        style={{ display: 'flex', justifyContent: 'center', background: props.color ?? '' }}
        onClick={() => {
          if (props.isSelectable == true) {
            setIsSelected(!isSelected);
            if (props.selectedCallback) props.selectedCallback();
          }
        }}
      >
        {/* //TODO ADD ICONS AND COLORS */}
        {props.icon && <Icon name={props.icon} size="s" />}
        <p style={{ height: '24px' }}>{props.taxonomyText}</p>
      </button>
      {props.deletable && (
        <button
          onClick={() => handleOpen()}
          style={{
            width: 16,
            height: 16,
            border: 'none',
            marginLeft: '-14px',
            marginTop: '-4px',
            background: 'transparent',
            borderRadius: '50%',
          }}
        >
          <Icon name="trash" size="s" />
        </button>
      )}
      <Dialog open={open} keepMounted onClose={() => handleClose()} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{`Delete "${props.taxonomyText}"`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you really want to delete this tag?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={styles.CancelButton + ' didacticP'} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button className={styles.AddButton + ' didacticP'} onClick={deleteTaxonomyOptionHelper}>
            Delete it
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Taxonomy;
