export const color1 = {
  Boy: '#4B4965',
  Girl: '#4B4965',
  Neutral: '#755742',
};

export const color2 = {
  Boy: '#D9D9D9',
  Girl: '#F9DCD8',
  Neutral: '#E3D2BA',
};

export const color3 = {
  Boy: '#F4F3F4',
  Girl: '#FFFBF4',
  Neutral: '#FFFEF6',
};

export const color4 = {
  Boy: '#E09E41',
  Girl: '#F0BC69',
  Neutral: '#E09E41',
};

export const color5 = {
  Boy: '#686B8A',
  Girl: '#84A6B1',
  Neutral: '#8A9172',
};

export const color6 = {
  Boy: '#393946',
  Girl: '#3C4545',
  Neutral: '#1C3924',
};
