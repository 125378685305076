import React, { Fragment, useEffect, useState } from 'react';
import {
  selectAccessTokenAfterOTP,
  selectAccount,
  selectIsLoggedIn,
  selectJwt,
  selectUser,
} from '../../app/redux/authorization.slice.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAnswers,
  addInvitedVisitors,
  addQuestions,
  addTaxonomies,
  addTaxonomyOptions,
  removeAnswers,
  removeInvitedVisitors,
  removeQuestions,
  removeTaxonomies,
  removeTaxonomyOptions,
  selectWedding,
  selectWeddings,
} from '../../app/redux/wedding.slice.recuder';
import { setMarriageCode } from '../../app/redux/authorization.slice.reducer';
import { GlobalState } from '../../experienceApp/screens/Home/Home';
import { Wedding } from '../../pkg/protobuf/v2/brain/brain_types_pb';
import { BrainHelper } from '../../pkg/apiHelpers/brainHelper';
import { logger } from '../../config/Logger';
import firebase from 'firebase';
import Custom404 from '../../component/errors/404';
import { Outlet, useNavigate } from 'react-router-dom';
import DashboardLayout from '../../component/dashboard/dashboardLayout/DashboardLayout';
import { AppProvider } from '../../component/dashboard/context/intro.context';
import cleanLocalStorage from '../../app/helper/CleanLocalStorage';
import { transliterate } from 'transliteration';

const ProtectedHostRoute = (): JSX.Element => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const jwt = useSelector(selectJwt);
  const user = useSelector(selectUser);
  const account = useSelector(selectAccount);

  const wedding: Wedding = useSelector(selectWedding);
  const weddings: Wedding[] = useSelector(selectWeddings);

  const [loggedIn, setLoggedIn] = useState<boolean>(isLoggedIn);
  const defaultState: Readonly<GlobalState> = {
    status: 'failed to contact the backend',
    streamEvents: [],
    userToken,
    user,
    wedding,
    marriageCode: transliterate(wedding.path),
    account,
    connectedAccounts: [],
  };
  const [state, setState] = React.useState(defaultState);
  // create mutable state, so we can send it to other functions
  const stateRef = React.useRef<GlobalState>(state);
  stateRef.current = state;
  const jwtRef = React.useRef<string>(jwt);
  jwtRef.current = jwt;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (wedding?.id === '') {
      navigate('/dashboard/choose-event');
    }
  }, [wedding]);

  useEffect(() => {
    let cancelStream: () => void;
    async function streamHostEvents() {
      cancelStream = await BrainHelper.streamHostEvents(
        dispatch,
        stateRef,
        setState,
        userToken,
        wedding.id,
        addInvitedVisitors,
        addTaxonomies,
        addTaxonomyOptions,
        addQuestions,
        addAnswers,
        removeInvitedVisitors,
        removeTaxonomies,
        removeTaxonomyOptions,
        removeQuestions,
        removeAnswers,
      );
    }

    const helper = async () => {
      const visitor = await BrainHelper.getInvitedVisitorByPhoneOrEmail(jwt, wedding.id, '', user.email);

      // const token: any = jwt_decode(jwt);
      if (
        loggedIn &&
        visitor &&
        account &&
        // token.roles[0].name !== 'defaultHostRoleAll' ||
        (account?.hostForWeddingId === wedding.id ||
          account?.moderatorForWeddingId === wedding.id ||
          visitor?.allowedVisitors[0]?.isHost ||
          visitor?.allowedVisitors[0]?.isModerator)
      ) {
        logger.info({
          message: `setup marriageCode: ${transliterate(wedding.path)}`,
          functionName: 'ProtectedHostRoute.useEffect',
        });
        logger.info({
          message: `windows.location.pathname: ${window.location.pathname}`,
          functionName: 'ProtectedHostRoute.useEffect',
        });
        const re = new RegExp(`${transliterate(wedding.path)}`, 'g');
        if (!re.test(window.location.pathname)) {
          setLoggedIn(false);
          logger.info({
            message: `clear local storage not logged-in or role not defaultHostRoleAll, weddingPath: ${transliterate(
              wedding.path,
            )} is not contained in path: ${window.location.pathname}`,
            functionName: 'ProtectedHostRoute.useEffect.differentMarriageCodes',
          });
          firebase.auth().signOut();
          cleanLocalStorage();
          // window.location.pathname = '/dashboard';
          return;
        }
        dispatch(setMarriageCode(transliterate(wedding.path)));

        if (userToken && userToken.trim() !== '') streamHostEvents();
      } else if (loggedIn && wedding?.path === '') {
        navigate('/dashboard/choose-event');
      } else {
        setLoggedIn(false);
        logger.info({
          message: 'clear local storage not logged-in or role not defaultHostRoleAll',
          functionName: 'ProtectedHostRoute.useEffect',
        });
        firebase.auth().signOut();
        cleanLocalStorage();
        window.location.pathname = '/dashboard';
      }
    };
    helper();

    const checkClearStorage = () => {
      if (!user || user.exp * 1000 < Date.now()) {
        logger.info({ message: 'clear local storage', functionName: 'ProtectedHostRoute.useEffect' });
        firebase.auth().signOut();
        cleanLocalStorage();
        window.location.pathname = '/dashboard';
      }
    };
    checkClearStorage();
    // check periodically if the token is expired then logout
    setInterval(function () {
      checkClearStorage();
    }, 60 * 1000); // 60 * 1000 milsec

    return () => {
      try {
        if (cancelStream) cancelStream();
      } catch (e: any) {
        logger.error({ message: e.message, functionName: 'ProtectedHostRoute.useEffect.failedToCancelStream' });
      }
    };
  }, [userToken, weddings]);

  return (
    <Fragment>
      {/*{loggedIn && (weddings.length === 0 ? <StartPlanning /> : [children])}*/}
      {loggedIn ? (
        <AppProvider>
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        </AppProvider>
      ) : (
        <Custom404 />
      )}
    </Fragment>
  );
};
export default ProtectedHostRoute;
