export const dateTimeFormat = (options: {
  localeMatcher?: string;
  weekday?: string;
  era?: string;
  year?: string;
  month?: string;
  day?: string;
  hour?: string;
  minute?: string;
  second?: string;
  timeZoneName?: string;
  formatMatcher?: string;
  hour12?: boolean;
  timeZone?: string;
}): any => {
  const newOptions = JSON.parse(JSON.stringify(options, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
  if (options.timeZone === undefined || options.timeZone === '')
    newOptions.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new Intl.DateTimeFormat('en-UK', newOptions);
};
