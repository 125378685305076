import { memo, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import { Account } from '../../../../pkg/protobuf/v2/auth/auth_types_pb';
import { Icon } from '../../../../shared';
import { ThemeContext } from 'styled-components';
import { TextField } from '@mui/material';

type StyledProps = {
  selected: boolean;
};
const Node = styled.div<StyledProps>`
  border-radius: 25px;
  background: ${(props) => props.theme.nodeBg};
  color: ${(props) => props.theme.nodeColor};
  border: 1px solid ${(props) => props.theme.accountNodeColor};
  padding: 24px;

  .react-flow__handle {
    background: ${(props) => props.theme.accountNodeColor};
    width: 8px;
    height: 10px;
    border-radius: 3px;
  }

  .nodeHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 16px;

    h3 {
      color: ${(props) => props.theme.accountNodeColor};
    }
  }

  .nodeDesign {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: ${(props) => props.theme.accountNodeColor};

    h3 {
      color: ${(props) => props.theme.accountNodeColor};
    }
    p {
      color: ${(props) => props.theme.accountNodeColor};
      font-size: 14px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.accountNodeColor} !important;
  }

  .MuiFilledInput-root {
    border-bottom: 1px solid ${(props) => props.theme.accountNodeColor} !important;
  }

  .MuiInput-root {
    border-bottom: 1px solid ${(props) => props.theme.accountNodeColor} !important;
  }

  .Mui-focused {
    color: ${(props) => props.theme.accountNodeColor};
  }

  .MuiInputLabel-root {
    color: ${(props) => props.theme.accountNodeColor} !important;
    font-family: 'DidactGothic-Regular';
  }
`;

type AccountNodeProps = {
  data: Account;
  selected: boolean;
};
const AccountNode = ({ data, selected }: AccountNodeProps) => {
  const themeContext = useContext(ThemeContext);

  // useEffect(() => {
  //   console.log('AccountNode', { data, selected });
  // }, []);

  return (
    <Node selected={selected}>
      <Handle type="target" position={Position.Left} />
      <div className={'nodeHeader'}>
        <Icon name={'profile_filled'} size={'m'} color={themeContext?.accountNodeColor} />
        <h3>Auth Account</h3>
      </div>
      <div className={'nodeDesign'}>
        {data && data.fullName && data.fullName != ' ' ? (
          <TextField
            value={data?.fullName}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.accountNodeColor,
                borderColor: themeContext?.accountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'Fullname'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
        {data && data.id ? (
          <TextField
            value={data?.id}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.accountNodeColor,
                borderColor: themeContext?.accountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'AccountID'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
        {data && data.email ? (
          <TextField
            value={data?.email}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.accountNodeColor,
                borderColor: themeContext?.accountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'Email'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
        {data && data.phoneNumber ? (
          <TextField
            value={data?.phoneNumber}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.accountNodeColor,
                borderColor: themeContext?.accountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'Phone'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
      </div>
      <Handle type="source" position={Position.Right} />
    </Node>
  );
};
export const MemoizedAccountNode = memo(AccountNode);
