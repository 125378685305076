import React, { useEffect, useState } from 'react';
import styles from './WeddingCardList.module.scss';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { Wedding } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { setWedding } from '../../../app/redux/wedding.slice.recuder';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setMarriageCode } from '../../../app/redux/authorization.slice.reducer';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { WeddingCard } from '../weddingCardList/WeddingCard';
import { logger } from '../../../config/Logger';
import location from '../../../loader-animation.json';
import Lottie from 'react-lottie';
import { transliterate } from 'transliteration';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WeddingCardList = (props: any) => {
  const [weddings, setWeddings] = useState<Wedding[]>([]);
  const [weddingsImages, setWeddingsImages] = useState<Map<string, string>>(new Map<string, string>());
  const dispatch = useDispatch();
  const [state, setState] = useState<any>({});
  const user = useSelector(selectUser);
  const stateRef = React.useRef<any>();
  const userRef = React.useRef<any>(user);
  // loading while search
  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(false);
  userRef.current = user;
  stateRef.current = state;

  const listWeddingHelper = async () => {
    const res = await BrainHelper.listHostAndModeratorWeddings(
      props.accountId,
      props.userToken,
      user.phoneNumber,
      user.email,
    );
    setWeddings(res);
  };

  const fetchWeddingsImages = async (weddingId: string, fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, props.userToken, 1000);
    if (typeof result === 'string') {
      const tempWeddingsImages = weddingsImages;
      tempWeddingsImages.set(weddingId, result);
      setWeddingsImages(tempWeddingsImages);
    }
    return result;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setTheWedding = async (weddingPath: string) => {
    const weddingData = await BrainHelper.getWedding(
      props.userToken,
      weddingPath,
      dispatch,
      stateRef,
      setState,
      setWedding,
    );
    dispatch(setWedding(weddingData));
    logger.info({
      message: `setup marriageCode: ${weddingData.path}`,
      functionName: 'WeddingCardList.setTheWedding',
    });
    dispatch(setMarriageCode(transliterate(weddingData.path)));
  };

  useEffect(() => {
    for (const r of weddings) {
      if (r.mainEvent) fetchWeddingsImages(r.id, r.mainEvent?.icon);
    }
  }, [weddings]);

  useEffect(() => {
    setIsSecondButtonLoading(true);
    listWeddingHelper()
      .then(() => setIsSecondButtonLoading(false))
      .catch((e) => logger.error({ message: e, functionName: 'WeddingCardList.useEffect' }));
  }, []);

  return (
    <div className={styles.weddingCardListWrapper}>
      {!isSecondButtonLoading && weddings ? (
        weddings.map((wedding) => {
          return <WeddingCard key={wedding.id} wedding={wedding} userToken={props.userToken} />;
        })
      ) : (
        <div className={styles.loadingBox}>
          <Lottie options={defaultOptions1} height={250} width={250} />
        </div>
      )}
    </div>
  );
};

export default WeddingCardList;
