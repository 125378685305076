import { TooltipRenderProps } from 'react-joyride';
import { mainBackgroundAccent, secondaryStrongAccent } from '../../../styles/colors';
import Icon from '../../../shared/Icon';
import React from 'react';
import useMobileCheck from '../../../hooks/useMobileChjeck';

export const Tooltip = ({
  backProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
  size,
}: TooltipRenderProps): JSX.Element => {
  const isMobile = useMobileCheck();
  return (
    <div className={'tutorialDiv'} style={{ backgroundColor: secondaryStrongAccent }}>
      <div>
        {!isLastStep && (
          <button
            {...skipProps}
            style={{
              float: 'right',
              background: 'none',
              color: 'inherit',
              border: 'none',
              padding: '0',
              font: 'inherit',
              cursor: 'pointer',
              outline: 'inherit',
            }}
            id="skip"
          >
            <Icon name={'cancel'} size={'s'} color={mainBackgroundAccent} />
          </button>
        )}
      </div>
      <div {...tooltipProps}>
        <div style={{ marginBottom: '16px' }}>{step.content}</div>
        <div>
          <button
            {...primaryProps}
            className={'btn btnMainTutorial'}
            style={{
              width: isMobile ? '124px' : '',
              padding: isMobile ? '12px 4px' : '',
              marginRight: '24px',
              marginBottom: '12px',
            }}
            id={!isLastStep ? 'next' : 'close'}
          >
            {!isLastStep ? `Next ${index + 1}/${size}` : 'Close'}
          </button>
          {index > 0 && (
            <button
              {...backProps}
              style={{
                width: isMobile ? '124px' : '',
                padding: isMobile ? '12px 4px' : '',
              }}
              className={'btn btnSecondaryTutorial btnSecondaryTutorialWithHoverEffect'}
              id="back"
            >
              Back
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
