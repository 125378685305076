import { Fragment, useEffect, useState } from 'react';
import { selectIsLoggedIn, selectJwt, setLogout } from '../../app/redux/authorization.slice.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '../../config/Logger';
import firebase from 'firebase';
import Custom404 from '../../component/errors/404';
import { Outlet, useNavigate } from 'react-router-dom';
import DashboardLayout from '../../component/dashboard/dashboardLayout/DashboardLayout';
import cleanLocalStorage from '../../app/helper/CleanLocalStorage';
import { BrainHelper } from '../../pkg/apiHelpers/brainHelper';
import jwt_decode from 'jwt-decode';
import { selectWedding } from '../../app/redux/wedding.slice.recuder';

const ProtectedLoggedInHostRoute = (): JSX.Element => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const wedding = useSelector(selectWedding);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loggedIn, setLoggedIn] = useState<boolean>(isLoggedIn);
  const jwt = useSelector(selectJwt);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const helper = async () => {
      const status = await BrainHelper.statusFunc(jwt);
      if (loggedIn && status) {
        return;
      } else if (loggedIn && wedding?.path === '') {
        navigate('/dashboard/choose-event');
      } else {
        logger.info({
          message: 'clear local storage not logged-in or role not defaultHostRoleAll',
          functionName: 'ProtectedLoggedInHostRoute.useEffect',
        });
        firebase.auth().signOut();
        cleanLocalStorage();
        window.location.pathname = '/dashboard';
      }
    };
    const checkClearStorage = () => {
      const token: any = jwt_decode(jwt);
      if (new Date().getTime() > token.exp * 1000) {
        logger.info({ message: 'clear local storage', functionName: 'ProtectedLoggedInHostRoute.useEffect' });
        dispatch(setLogout(null));
        firebase.auth().signOut();
        cleanLocalStorage();
        window.location.pathname = '/dashboard';
      }
    };
    setInterval(function () {
      checkClearStorage();
    }, 60 * 1000); // 60 * 1000 milsec
    checkClearStorage();
    helper();
  }, []);

  return (
    <Fragment>
      {loggedIn ? (
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      ) : (
        <Custom404 />
      )}
    </Fragment>
  );
};
export default ProtectedLoggedInHostRoute;
