import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import './Intro.scss';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';
import { IonContent, IonPage } from '@ionic/react';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { logger } from '../../../config/Logger';
import { selectJwt } from '../../../app/redux/authorization.slice.reducer';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';

const Intro = (): JSX.Element => {
  //GET WEDDING IMAGE
  const wedding = useSelector(selectWedding);
  const jwt = useSelector(selectJwt);
  const [weddingImage, setWeddingImage] = useState<string>('');
  const [swiper, setSwiper] = useState<SwiperCore | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  const fetchWeddingImage = async (weddingId: string, fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, jwt, Imagehelper.windowSize());
    if (typeof result === 'string') {
      setWeddingImage(result);
    }
    return result;
  };

  useEffect(() => {
    if (wedding?.mainEvent)
      fetchWeddingImage(wedding?.id, wedding?.mainEvent?.icon).catch((e) => {
        logger.error({ message: e, functionName: 'Intro.useEffect' });
      });
  }, [wedding]);

  const [currentStep, setCurrentStep] = useState(0);

  SwiperCore.use([Navigation, Pagination, A11y]);
  return (
    <IonPage>
      <IonContent>
        <div className={'Introduction'}>
          <img src={'assets/logos/whiteclover-logo-white.svg'} height={40} alt={'WhiteClover'} />
          <div className={'carousel'}>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              // onSlideNextTransitionEnd={() => setCurrentStep(currentStep + 1)}
              // onSlidePrevTransitionEnd={() => setCurrentStep(currentStep - 1)}
              grabCursor={true}
              allowTouchMove={true}
              slideToClickedSlide={true}
              mousewheel={true}
              onSwiper={(swiper) => setSwiper(swiper)}
              onSlideChange={(e) => {
                if (swiper) {
                  setCurrentStep(e.activeIndex);
                  swiper.activeIndex = e.activeIndex;
                  swiper.updateProgress();
                  swiper.updateSlides();
                  swiper.update();
                }
              }}
            >
              {/*<SwiperSlide style={{ display: currentStep === 0 ? 'block' : 'none' }}>*/}
              <SwiperSlide>
                <div id={'step1'} className={currentStep === 0 ? 'step' : 'step stepHidden'}>
                  <img src={weddingImage ? weddingImage : ''} alt={'Couple'} style={{ objectFit: 'contain' }} />
                  <br />
                  <h3 className={'intro-title'}>{wedding ? wedding.name : 'Title'}</h3>

                  <p className={'intro-description'}>
                    {' '}
                    Have invited you to their wedding! A few things to get you started with their app.
                  </p>
                </div>
              </SwiperSlide>
              {/*<SwiperSlide style={{ display: currentStep === 1 ? 'block' : 'none' }}>*/}
              <SwiperSlide>
                <div id={'step2'} className={currentStep === 1 ? 'step' : 'step stepHidden'}>
                  <img src={'assets/graphics/intro-image-1.png'} alt={'Couple'} style={{ objectFit: 'contain' }} />
                  <p className={'intro-description'}>Easy access to the wedding schedule and info</p>
                </div>
              </SwiperSlide>
              {/*<SwiperSlide style={{ display: currentStep === 2 ? 'block' : 'none' }}>*/}
              <SwiperSlide>
                <div id={'step3'} className={currentStep === 2 ? 'step' : 'step stepHidden'}>
                  <img src={'assets/graphics/intro-image-2.png'} alt={'Couple'} style={{ objectFit: 'contain' }} />
                  <p className={'intro-description'}>
                    Keep them updated! Manage in real time your attendance and your preferences with the RSVP system
                  </p>
                </div>
              </SwiperSlide>
              {/*<SwiperSlide style={{ display: currentStep === 3 ? 'block' : 'none' }}>*/}
              <SwiperSlide>
                <div id={'step4'} className={currentStep === 3 ? 'step' : 'step stepHidden'}>
                  <img
                    src={'assets/graphics/intro-image-3.png'}
                    alt={'Couple'}
                    style={{ objectFit: 'contain', background: '' }}
                  />
                  <p className={'intro-description'}>
                    Share your moments with the couple and the guests with our experience app. A private space to share
                    the moments with the ones that matter.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          {currentStep === 0 ? (
            <span
              role="presentation"
              onClick={() => {
                if (swiper) swiper.slideTo(1);
              }}
            >
              <img src={'assets/graphics/intro-bullets-1.svg'} alt="1" />
            </span>
          ) : (
            ''
          )}
          {currentStep === 1 ? (
            <span
              role="presentation"
              onClick={() => {
                if (swiper) swiper.slideTo(2);
              }}
            >
              <img src={'assets/graphics/intro-bullets-2.svg'} alt="2" />
            </span>
          ) : (
            ''
          )}
          {currentStep === 2 ? (
            <span
              role="presentation"
              onClick={() => {
                if (swiper) swiper.slideTo(3);
              }}
            >
              <img src={'assets/graphics/intro-bullets-3.svg'} alt="3" />
            </span>
          ) : (
            ''
          )}

          {currentStep === 3 ? (
            <span
              role="presentation"
              onClick={() => {
                if (swiper) swiper.slideTo(0);
              }}
            >
              <img src={'assets/graphics/intro-bullets-4.svg'} alt="4" />
            </span>
          ) : (
            ''
          )}

          {currentStep !== 3 ? (
            <button
              style={{ background: 'none', border: 'none', height: '54px' }}
              onClick={() => {
                if (!swiper) return;
                if (currentStep === 3) {
                  swiper.slideTo(0);
                } else swiper.slideNext();
              }}
            >
              <img src={'assets/graphics/intro-next-step.svg'} alt={''} width={24} />
            </button>
          ) : (
            <button className={'buttonIntro didacticH3'} onClick={() => (window.location.pathname = '/finish')}>
              Sweet, lets go!
            </button>
          )}

          <img src={'assets/graphics/intro-1.svg'} alt={''} style={{ position: 'absolute', bottom: 0, left: 0 }} />
          <img src={'assets/graphics/intro-2.svg'} alt={''} style={{ position: 'absolute', bottom: 0, right: 0 }} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Intro;
