import styles from './Toolbox.module.scss';
import TaxonomyListWithSelection from '../taxonomyListWithSelection/TaxonomyListWithSelection';
import { AllowedVisitor, RSVPEvent, TaxonomyOption } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { ScreenType, useTypeOfScreen } from '../../../../hooks';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Icon from '../../../../shared/Icon';
import FlatButton from '../../table/FlatButton';
import { useDispatch, useSelector } from 'react-redux';
import { addInvitedVisitors, selectRsvpEvents, selectWedding } from '../../../../app/redux/wedding.slice.recuder';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import OutlinedButton from '../../table/OutlinedButton';
import DropdownButton from '../../table/DropdownButton';

type ToolboxProps = {
  selectedRows: Map<string, AllowedVisitor>;
  selectedTaxonomyOptions: Array<TaxonomyOption>;
  setSelectedTaxonomyOptions: any;
  addTags: any;
  deleteRows: any;
  editRows: any;
  importRows: any;
  showImportedGuestsModal: boolean;
  weddingId: string;
  userToken: string;
  query: string;
  setShowFiltersModal: any;
  showGuestModal: boolean;
  showFiltersModal: boolean;
  showAddTag: boolean;
  setShowAddTag: any;
};

const Toolbox = ({
  selectedRows,
  selectedTaxonomyOptions,
  setSelectedTaxonomyOptions,
  addTags,
  editRows,
  deleteRows,
  importRows,
  showImportedGuestsModal,
  weddingId,
  userToken,
  query,
  setShowFiltersModal,
  showGuestModal,
  showFiltersModal,
  showAddTag,
  setShowAddTag,
}: ToolboxProps) => {
  const rsvpEvents = useSelector(selectRsvpEvents);
  const wedding = useSelector(selectWedding);
  const [showShareGuestsModal, setShowShareGuestsModal] = useState(false);
  const [showShareGuestsDropdown, setShowShareGuestsDropdown] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<RSVPEvent | undefined>(undefined);
  const [dashboardURL, setDashboardURL] = useState('https://app.whiteclover.uk/dashboard');

  const modalRef = useRef(null);
  const dropdownRef = useRef(null);

  const dispatch = useDispatch();
  const typeOfScreen = useTypeOfScreen();

  useEffect(() => {
    setDashboardURL(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard'
        : `https://${window.location.host}/dashboard`,
    );
  }, []);

  useOutsideClick(
    modalRef,
    () => {
      setShowShareGuestsModal(false);
      setSelectedEvent(undefined);
    },
    'shareListButton',
  );
  useOutsideClick(dropdownRef, () => setShowShareGuestsDropdown(false), 'shareGuestsDropdown');

  const toggleShareModal = () => {
    setShowShareGuestsModal((prevState) => !prevState);
  };

  const downloadList = async () => {
    const fileData = await BrainHelper.downloadGuestList(userToken, wedding.id, query, 'csv');
    if (fileData) {
      const blob = new Blob([fileData.content], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileData.fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      if (fileData?.fileName && fileData.fileName.trim() !== '') link.click();
      else toast('No file to download');

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    }
  };

  const updateVisitorsHelper = async (value: string[]) => {
    const attatchTaxonomies = value;
    selectedRows.forEach(async (row, index) => {
      await BrainHelper.attachTaxonomyOptionsToAllowedVisitor(
        attatchTaxonomies,
        index,
        userToken,
        weddingId,
        dispatch,
        addInvitedVisitors,
      );
    });
  };
  useEffect(() => {
    if (!showAddTag) {
      setSelectedTaxonomyOptions([]);
    }
  }, [showAddTag]);
  useEffect(() => {
    console.log('selectedRows', selectedRows);
  }, [selectedRows]);

  return (
    <div className={styles.toolbox + ' ' + styles[`toolbox${typeOfScreen}`]} style={{ zIndex: 99 }}>
      <div>
        {/*<FlatButton*/}
        {/*  // disabled={selectedRows?.length == 0}*/}
        {/*  extraClass={showFiltersModal ? styles.isSelected : ''}*/}
        {/*  title={'Filters'}*/}
        {/*  iconColor={showFiltersModal ? '#ffffff' : '#63435B'}*/}
        {/*  icon={'filters'}*/}
        {/*  _callback={() => setShowFiltersModal((prev: boolean) => !prev)}*/}
        {/*  id={'filters'}*/}
        {/*/>*/}
        {/*<FlatButton*/}
        {/*  title={typeOfScreen !== ScreenType.mobile ? 'Add to Event' : ''}*/}
        {/*  icon="add"*/}
        {/*  iconColor={selectedRows?.size == 0 ? '#C7ADC1' : '#63435B'}*/}
        {/*  // _callback={() => editRows()}*/}
        {/*  disabled={selectedRows?.size <= 0}*/}
        {/*/>*/}
        {typeOfScreen === 'Mobile' && showAddTag ? (
          ''
        ) : (
          <>
            {' '}
            <FlatButton
              title={typeOfScreen !== ScreenType.mobile ? 'Edit User' : ''}
              extraClass={showGuestModal ? styles.isSelected : ''}
              icon="edit"
              iconColor={selectedRows?.size == 0 ? '#C7ADC1' : showGuestModal ? '#ffffff' : '#63435B'}
              _callback={() => editRows()}
              disabled={selectedRows?.size != 1}
              id={'guestModal'}
            />
            <FlatButton
              title={typeOfScreen !== ScreenType.mobile ? 'Delete User' : ''}
              icon="trash"
              iconColor={selectedRows?.size == 0 ? '#C7ADC1' : '#63435B'}
              _callback={() => deleteRows()}
              disabled={selectedRows?.size <= 0}
            />
          </>
        )}
        <FlatButton
          disabled={selectedRows?.size == 0}
          extraClass={showAddTag ? styles.isSelected : ''}
          title={!showAddTag ? 'Add Tags' : 'Back'}
          iconColor={selectedRows?.size == 0 ? '#C7ADC1' : !showAddTag ? '#63435B' : '#ffffff'}
          icon={!showAddTag ? 'label' : 'arrow_back'}
          _callback={() => setShowAddTag(!showAddTag)}
        />
        {showAddTag ? (
          <TaxonomyListWithSelection
            selected={selectedTaxonomyOptions}
            callback={(newTaxonomies: Array<TaxonomyOption>, removedTaxonomies: Array<TaxonomyOption>) => {
              setSelectedTaxonomyOptions(newTaxonomies);
              addTags(newTaxonomies, removedTaxonomies);
              updateVisitorsHelper(newTaxonomies.map((item: TaxonomyOption) => item.id));
            }}
            updateCallback={() => {
              setShowAddTag(false);
            }}
            weddingId={weddingId}
          />
        ) : (
          <></>
        )}
      </div>
      {typeOfScreen === 'Mobile' && showAddTag ? (
        ''
      ) : (
        <div
          style={{ position: 'relative' }}
          className={styles.addToolbox + ' ' + (showAddTag ? styles.openAddTag : '')}
        >
          {typeOfScreen !== ScreenType.mobile && (
            <FlatButton
              id={'importListButton'}
              title={typeOfScreen !== ScreenType.mobile && typeOfScreen !== ScreenType.tablet ? 'Import List' : ''}
              icon={'upload'}
              iconColor={showImportedGuestsModal ? '#FDF6EB' : '#63435B'}
              extraClass={showImportedGuestsModal ? styles.highlighted : ''}
              _callback={() => importRows()}
            />
          )}
          <FlatButton
            id={'shareListButton'}
            title={typeOfScreen !== ScreenType.mobile && typeOfScreen !== ScreenType.tablet ? 'Share List' : ''}
            icon={'share'}
            iconColor={showShareGuestsModal ? '#FDF6EB' : '#63435B'}
            extraClass={showShareGuestsModal ? styles.highlighted : ''}
            _callback={() => toggleShareModal()}
          />
          <FlatButton
            id={'downloadListButton'}
            title={typeOfScreen !== ScreenType.mobile && typeOfScreen !== ScreenType.tablet ? 'Export List' : ''}
            icon={'upload'}
            rotate={'180deg'}
            iconColor={'#63435B'}
            extraClass={styles.padding}
            _callback={() => downloadList()}
          />
          {showShareGuestsModal ? (
            <div className={styles.shareListWrapper + ' ' + styles[`shareListWrapper${typeOfScreen}`]} ref={modalRef}>
              <h4 className={'didacticH4'}>Share List</h4>
              <p className={'didacticP'}>Choose the event guest-list you wish to share.</p>
              <div style={{ position: 'relative', width: '100%' }}>
                <OutlinedButton
                  id={'shareGuestsDropdown'}
                  title={selectedEvent ? selectedEvent.name : 'Choose Event'}
                  isActive={showShareGuestsDropdown}
                  setIsActive={setShowShareGuestsDropdown}
                  icon={true}
                  _callback={() => {
                    setShowShareGuestsDropdown((prevState) => !prevState);
                  }}
                />
                {showShareGuestsDropdown && (
                  <div
                    id={'sendDropdownModal'}
                    className={styles.dropdown + ' ' + (showShareGuestsDropdown ? styles.dropdownActive : '')}
                    ref={dropdownRef}
                  >
                    {rsvpEvents.map((rsvpEvent) => {
                      return (
                        <DropdownButton
                          key={'dropdown' + rsvpEvent.id}
                          title={rsvpEvent.name}
                          icon={wedding.mainEvent?.id === rsvpEvent.id}
                          _callback={() => {
                            setSelectedEvent(rsvpEvent);
                            setShowShareGuestsDropdown(false);
                          }}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              {selectedEvent && (
                <Fragment>
                  <p className={'didacticP'}>You can copy and share the link bellow. </p>
                  <CopyToClipboard
                    text={
                      selectedEvent
                        ? dashboardURL.replace('/dashboard', '') + '/share-list/' + wedding.id + '/' + selectedEvent.id
                        : ''
                    }
                    onCopy={() => {
                      toast(`Copied on clipboard successfully!`, {
                        style: { backgroundColor: '#A3F5E6', color: '#006150 !important' },
                      });
                    }}
                  >
                    <button className={styles.shareButton}>
                      <Icon name={'link'} color={'#63435B'} size={'s'} />
                      <h4 className={'didacticH4'}>
                        {selectedEvent
                          ? dashboardURL.replace('/dashboard', '') +
                            '/share-list/' +
                            wedding.id +
                            '/' +
                            selectedEvent.id
                          : ''}
                      </h4>
                      <Icon name={'copy'} color={'#63435B'} size={'sPlus'} />
                    </button>
                  </CopyToClipboard>
                </Fragment>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

export default Toolbox;
