import { createCallbackClient, createGrpcWebTransport, createPromiseClient } from '@bufbuild/connect-web';
import { AuthService } from '../pkg/protobuf/v2/auth/auth_service_connectweb';
import { BrainService } from '../pkg/protobuf/v2/brain/brain_service_connectweb';

export class Backend {
  public static authClientV2 = createPromiseClient(
    AuthService,
    createGrpcWebTransport({
      baseUrl: process.env.REACT_APP_BACKEND_HOST || 'http://grpc-web.localhost',
    }),
  );

  public static brainClientV2 = createPromiseClient(
    BrainService,
    createGrpcWebTransport({
      baseUrl: process.env.REACT_APP_BACKEND_HOST || 'http://grpc-web.localhost',
    }),
  );
  public static brainClientV2Callback = createCallbackClient(
    BrainService,
    createGrpcWebTransport({
      baseUrl: process.env.REACT_APP_BACKEND_HOST || 'http://grpc-web.localhost',
    }),
  );
}
