import React, { useEffect, useState } from 'react';
import styles from './TheArtistMainComponent.module.scss';
import '../TheArtist.module.scss';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';

interface TheArtistMainComponentProps {
  TheArtistMainComponentTypographyLogo?: string;
  TheArtistMainComponentCoupleName?: string;
  TheArtistMainComponentMainImage?: string;
}

const defaultValues = {
  mainImage: '/templates/TheArtist/mainComponentMainImage.png',
  coupleName: 'LILA & SOPHIA',
};

const TheArtistMainComponent = ({
  TheArtistMainComponentTypographyLogo,
  TheArtistMainComponentCoupleName,
  TheArtistMainComponentMainImage,
}: TheArtistMainComponentProps): JSX.Element => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [blob, setBlob] = useState('');

  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob(result);
  };

  useEffect(() => {
    if (TheArtistMainComponentMainImage) {
      fetchPostImage(TheArtistMainComponentMainImage);
    }
  }, [TheArtistMainComponentMainImage]);

  return (
    <section
      id={`TheArtistMainComponent`}
      className={styles.TheArtistMainComponent}
      style={{ backgroundImage: `url('/templates/TheArtist/snow.png')` }}
    >
      <img src={`${blob ? blob : defaultValues.mainImage}`} alt="mainComponentMainImage" />
      <h4 className={styles.typographyLogo}>
        {TheArtistMainComponentCoupleName && TheArtistMainComponentCoupleName.length > 0
          ? TheArtistMainComponentCoupleName
          : defaultValues.coupleName}
      </h4>
    </section>
  );
};

export default TheArtistMainComponent;
