import React, { Fragment, useEffect, useState } from 'react';
import templateStyles from '../RecessesOfTheSoul.module.scss';

import { Imagehelper } from '../../../app/helper/Imagehelper';
import { useDropzone } from 'react-dropzone';
import styles from '../../../component/dashboard/planning/Planning.module.scss';
import { IonSpinner } from '@ionic/react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ImageDragNDrop = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loading,
  isDropzoneActive,
  setIsDropzoneActive,
  userToken,
  weddingPath,
  percentage,
  setPercentage,
  setLoading,
  setFileUrl,
  setFileId,
  setCompletedFile,
  selectedLayout,
  imageNumber,
  updateProps,
  componentProps,
  componentIndex,
}: any): JSX.Element => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 20000000,
    accept: {
      'image/*': [],
      'image/heif': ['.heic', '.heif'],
    },
    onDragEnter: () => {
      setIsDropzoneActive(true);
    },
    onDragLeave: () => {
      setIsDropzoneActive(false);
    },
    onDropAccepted: (filesAccepted: any) => {
      console.log('---------------------------');
      console.log('ON DROP ACCEPTED: ', filesAccepted);
      for (let i = 0; i < filesAccepted.length; i++) {
        Imagehelper.handleCompressedImageUpload(
          filesAccepted[i],
          userToken,
          weddingPath,
          setPercentage,
          percentage,
          setLoading,
          setFileUrl,
          setFileId,
          setCompletedFile,
          true,
        );
      }
    },
    onDropRejected: (fileRejections: any) => {
      console.log(
        fileRejections[0].errors[0].message +
          '. Please only Upload images of jpg/jpeg/png/gif format of max size 10Mb. ',
      );
    },
  });

  let files = acceptedFiles.map((file: any) => <p key={file.path}>{file.path} Drop or browse to replace images</p>);
  useEffect(() => {
    files = acceptedFiles.map((file: any) => <p key={file.path}>{file.path} Drop or browse to replace images</p>);
    setShowSpinner(true);
    if (imageNumber === 1 && acceptedFiles.length > 0) {
      updateProps(acceptedFiles[0].name, 'image1Name');
    }
    if (imageNumber === 2 && acceptedFiles.length > 0) {
      updateProps(acceptedFiles[0].name, 'image2Name');
    }
    if (imageNumber === 3 && acceptedFiles.length > 0) {
      updateProps(acceptedFiles[0].name, 'image3Name');
    }
    if (imageNumber === 4 && acceptedFiles.length > 0) {
      updateProps(acceptedFiles[0].name, 'image4Name');
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (percentage === 0) {
      setShowSpinner(false);
    }

    if (percentage === 100)
      setInterval(() => {
        setShowSpinner(false);
      }, 5000);
  }, [percentage]);

  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          background: 'none',
          border: '1px solid #DAD8D9',
          width: '72px',
          height: '72px',
          padding: 0,
          margin: 4,
        }}
      >
        <>
          {selectedLayout === 0 && imageNumber === 1 && (
            <img src={'/assets/graphics/group2-image1.png'} alt="Selected" />
          )}
          {selectedLayout === 0 && imageNumber === 2 && (
            <img src={'/assets/graphics/group2-image2.png'} alt="Selected" />
          )}
          {selectedLayout === 1 && imageNumber === 1 && (
            <img src={'/assets/graphics/group3-image1.png'} alt="Selected" />
          )}
          {selectedLayout === 1 && imageNumber === 2 && (
            <img src={'/assets/graphics/group3-image2.png'} alt="Selected" />
          )}
          {selectedLayout === 1 && imageNumber === 3 && (
            <img src={'/assets/graphics/group3-image3.png'} alt="Selected" />
          )}
          {selectedLayout === 2 && imageNumber === 1 && (
            <img src={'/assets/graphics/group4-image1.png'} alt="Selected" />
          )}
          {selectedLayout === 2 && imageNumber === 2 && (
            <img src={'/assets/graphics/group4-image2.png'} alt="Selected" />
          )}
          {selectedLayout === 2 && imageNumber === 3 && (
            <img src={'/assets/graphics/group4-image3.png'} alt="Selected" />
          )}
          {selectedLayout === 2 && imageNumber === 4 && (
            <img src={'/assets/graphics/group4-image4.png'} alt="Selected" />
          )}
        </>
      </div>
      <div className={templateStyles.dragNDrop}>
        <div
          role={'button'}
          tabIndex={0}
          onMouseDown={() => setIsDropzoneActive(true)}
          onMouseUp={() => setIsDropzoneActive(false)}
          className={templateStyles.dropzone}
          {...getRootProps()}
          style={isDropzoneActive ? { border: 'dotted 1px #00C9A5' } : {}}
        >
          <input {...getInputProps()} />

          {files?.length > 0 || componentProps[componentIndex][`image${String(imageNumber)}Name`] !== undefined ? (
            <Fragment>
              {showSpinner ? (
                <span
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <IonSpinner name="lines" />
                  {percentage > 0 && percentage < 100 ? <p>{percentage.toFixed(2)} %</p> : ''}
                </span>
              ) : (
                <img src="/rsvp/image-default.svg" alt="uploaded" />
              )}
              <p className={'didacticP'} style={isDropzoneActive ? { color: '#00C9A5!important' } : {}}>
                {files?.length > 0 && componentProps[componentIndex][`image ${String(imageNumber)} Name`] === '' ? (
                  <Fragment>{files}</Fragment>
                ) : (
                  <Fragment>
                    <p key={componentProps[componentIndex][`image ${String(imageNumber)} Name`]}>
                      {componentProps[componentIndex][`image${String(imageNumber)}Name`]} Drop or browse to replace
                      image
                    </p>
                  </Fragment>
                )}
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <img src={'/rsvp/upload' + (isDropzoneActive ? '-hovered' : '') + '.svg'} alt="upload" height={'24px'} />
              <p className={'didacticP'} style={isDropzoneActive ? { color: '#00C9A5' } : {}}>
                Drag & drop your photo here, or{' '}
                <span className={styles.highlighted} style={isDropzoneActive ? { color: '#00C9A5' } : {}}>
                  browse
                </span>{' '}
                your files
              </p>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageDragNDrop;
