import React, { useEffect, useRef, useState } from 'react';

import styles from './Navbar.module.scss';
import firebase from 'firebase';
import { FirebaseContext } from '../../../context/firebase.context';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsTutorialOn, selectWedding, setIsTutorialOn } from '../../../app/redux/wedding.slice.recuder';
import { useNavigate, useLocation } from 'react-router-dom';
import { logger } from '../../../config/Logger';
import { motion } from 'framer-motion';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { debounce } from '@mui/material';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { selectJwt, selectProfile } from '../../../app/redux/authorization.slice.reducer';
import { useMount } from 'react-use';
import { useAppContext } from '../context/intro.context';
import Joyride, { CallBackProps } from 'react-joyride';
import Icon from '../../../shared/Icon';
import cleanLocalStorage from '../../../app/helper/CleanLocalStorage';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { brandOperationalAccent, mainBackgroundAccent, secondaryStrongAccent } from '../../../styles/colors';
import { Tooltip } from '../tutorial/TutorialComponents';
import { ScreenType, useTypeOfScreen } from '../../../hooks';
import { QRCode } from 'react-qrcode-logo';

type ActiveProp = {
  htmlElemRef: React.RefObject<HTMLDivElement> | null;
  finishAnimating: any;
  animating: any;
};
const Active = ({ htmlElemRef, finishAnimating, animating }: ActiveProp) => {
  const [{ x, top, width, height, color }, setAttributes] = React.useState({
    x: 0,
    top: 0,
    width: 72,
    height: 70,
    color: '#f9ac80',
  });

  const updateAttributes = React.useCallback(() => {
    if (htmlElemRef !== null) {
      setAttributes({
        x: (htmlElemRef?.current?.parentElement?.offsetLeft || 0) - (htmlElemRef?.current?.offsetLeft || 0),
        top: htmlElemRef?.current?.offsetTop || 0,
        width: htmlElemRef?.current?.getBoundingClientRect()?.width || 0,
        color: htmlElemRef?.current?.style?.color || '#f9ac80',
        height: htmlElemRef?.current?.getBoundingClientRect()?.height || 0,
      });
      console.log('active', htmlElemRef);
    }
  }, [htmlElemRef]);

  // Update attributes if active route changes (or refs change)
  useEffect(() => {
    updateAttributes();
  }, [htmlElemRef, updateAttributes]);

  // After window resize, recalculate
  useEffect(() => {
    const recalculateAttrs = debounce(() => {
      updateAttributes();
    }, 500);

    window.addEventListener('resize', recalculateAttrs);
    return () => {
      window.removeEventListener('resize', recalculateAttrs);
    };
  });

  return (
    <motion.div
      className="tabs-list__active"
      animate={{
        x,
        top,
        width,
        height,
      }}
      style={{
        opacity: animating ? 1 : 0,
        backgroundColor: color,
        zIndex: -1,
        position: 'absolute',
      }}
      onAnimationComplete={finishAnimating}
    />
  );
};

const Navbar = (props: {
  options: { id: string; title: string; link: string; icon: string; color?: string }[];
  currentPage: number;
  setCurrentPage?: any;
  initialsOptions: { initials: string; link: string };
  logoLink: string;
  showMobileNav?: boolean;
  setShowMobileNav?: any;
}): JSX.Element => {
  const isTutorialOn = useSelector(selectIsTutorialOn);
  const profile = useSelector(selectProfile);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageState, setPageState] = useState(props.currentPage);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showReminder, setShowReminder] = useState<boolean>(false);
  // for outline svgs on hover bottom popup
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [itemsHover, setItemsHover] = useState<Map<string, boolean>>(new Map());
  const [tabHtmlElem, setTabHtmlElem] = useState<React.RefObject<HTMLDivElement> | null>(null);
  const [animating, setAnimating] = React.useState(false);
  const [copied, setCopied] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [blobId, setBlobId] = useState<string>('');
  const [fileId, setFileId] = useState<string | undefined>('');
  const userToken = useSelector(selectJwt);
  const navigate = useNavigate();
  const location = useLocation();
  const navRef = useRef(null);
  const typeOfScreen = useTypeOfScreen();

  useOutsideClick(navRef, () => setShowPopup(false));

  useEffect(() => {
    if (wedding?.mainEvent?.icon) setFileId(wedding.mainEvent.icon);
    // window.location.reload();
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes(`dashboard/w/${wedding.path}/guests`) &&
      (profile.phoneNumber === '' || profile.phoneNumber === undefined)
    )
      setShowReminder(true);
  }, [location]);

  useEffect(() => {
    if (fileId !== '' && fileId !== undefined) fetchPostImage(fileId);
  }, [fileId]);

  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 200);
    if (typeof result === 'string') setBlobId(result);
  };

  const refElems: React.RefObject<HTMLDivElement>[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const option of props.options) {
    const refElem = useRef<HTMLDivElement>(null);
    refElems.push(refElem);
  }

  const wedding = useSelector(selectWedding);
  const ShowNavbarOnPaths = [
    `/dashboard/w/${wedding.path}/guests`,
    `/dashboard/w/${wedding.path}/planning`,
    `/dashboard/w/${wedding.path}/rsvp`,
    `/dashboard/w/${wedding.path}/overview`,
    `/dashboard/w/${wedding.path}/website/choose-template`,
    `/dashboard/w/${wedding.path}/website`,
    `/dashboard/w/${wedding.path}/profile`,
  ];

  let initials =
    wedding?.path[0]?.toUpperCase() +
    '&' +
    (wedding.path?.split('-')[1]
      ? wedding.path?.split('-')[1][0]?.toUpperCase()
      : wedding.path?.split('&')[1]
      ? wedding.path?.split('&')[1][0]?.toUpperCase()
      : '');
  if (wedding.socialEventType !== 'wedding') {
    initials = initials[0];
  }
  const IsActiveOnNavbar = (page: string) => {
    if (location.pathname.includes(page)) return styles.active;
    else return '';
  };

  // const marriageCode = useSelector(selectMarriageCode);
  useEffect(() => {
    // for outline svgs on hover bottom popup
    const itemsToHover = new Map();
    itemsToHover.set('choose', false);
    itemsToHover.set('logout', false);
    setItemsHover(itemsToHover);
    FirebaseContext.getAuth();
  }, []);

  const logout = () => {
    logger.info({ message: 'clear local storage', functionName: 'Navbar.logout' });
    firebase.auth().signOut();
    cleanLocalStorage();
    window.location.pathname = '/dashboard';
  };

  if (!ShowNavbarOnPaths.includes(location.pathname)) {
    return <></>;
  }

  const {
    setState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: { run, stepIndex, steps },
  } = useAppContext();

  useMount(() => {
    setState({
      steps: [
        {
          content: (
            <>
              <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Hey
              </h3>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Welcome to your Planner App. I'm Liva, and I'll be your personal assistant throughout the app. You can
                call me at any time by clicking <b style={{ color: brandOperationalAccent }}>HELP</b> or
                <b style={{ color: brandOperationalAccent }}> TUTORIAL</b> in the lower right corner. The app contains
                everything you need to successfully plan your dream day (s). We understand that there is a lot{' '}
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                to take in, but don't worry; we're here to assist you and guide you step by step.
              </p>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                We promise you that by the end of all this chaos, it will resolve into a happily ever after. So, take a
                deep breath and let’s dive into the WhiteClover experience.
              </p>
            </>
          ),
          placement: 'center',
          target: 'body',
          disableBeacon: true,
        },
        {
          content: (
            <>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Here is a video tutorial for all the basic features of the WhiteClover Planner. Make sure to watch it as
                it will get you started immediately.
              </p>
              <iframe
                className={'tutorialIframe'}
                src="https://www.youtube.com/embed/PD-ca_01toY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </>
          ),
          placement: 'center',
          target: 'body',
          disableBeacon: true,
        },
        {
          content: (
            <>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                You can also preview how your guest will interact with your app.
              </p>
              <span style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <iframe
                  className={'tutorialIframeMobile'}
                  src="https://www.youtube.com/embed/wxNsIe1DcXo"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </span>
            </>
          ),
          placement: 'center',
          target: 'body',
          disableBeacon: true,
        },
        {
          target: '#planningMenuItem',
          // target: 'body',
          content: (
            <>
              <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Planning page
              </h3>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                You can setup your main event and main image. After the setup of the main event you can continue setting
                all other events like Bachelor, Dinner, Other etc.
              </p>
            </>
          ),
          disableBeacon: true,
          placement: 'right',
        },
        {
          target: '#guestMenuItem',
          // target: 'body',
          content: (
            <>
              <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Guest page
              </h3>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Here you can import your guest details, organise them by using tags, plan plus1 per guest and invite
                them to your events. Later you can inform your guest via email by selecting them and clicking SEND
                button. You can send them Save The Date invitation early on and later you can send them RSVP invitation
                to collect their answers and their attendance.
              </p>
            </>
          ),
          placement: 'right',
        },
        {
          target: typeOfScreen === ScreenType.desktop ? '#websiteMenuItem' : 'body',
          // target: 'body',
          content:
            typeOfScreen === ScreenType.desktop ? (
              <>
                <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                  Website builder page
                </h3>
                <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                  Build your website fast and easy with our preconfigured themes. As soon as you publish your website
                  then you will be able to share it with your friends. If you need different theme please contact us.
                </p>
              </>
            ) : (
              <>
                <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                  Website builder page
                </h3>
                <p
                  className={'didacticP'}
                  style={{ color: mainBackgroundAccent, fontWeight: 'bold', marginBottom: '16px' }}
                >
                  Currently works only on bigger screens!
                </p>
                <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                  Build your website fast and easy with our preconfigured themes. As soon as you publish your website
                  then you will be able to share it with your friends. If you need different theme please contact us.
                </p>
              </>
            ),
          placement: typeOfScreen === ScreenType.desktop ? 'right' : 'center',
        },
        {
          target: '#rsvpMenuItem',
          // target: 'body',
          content: (
            <>
              <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                RSVP builder page
              </h3>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Write a small paragraph for the invitation email and setup questions that you would like to get answers
                from your guests.
              </p>
            </>
          ),
          placement: 'right',
        },
        {
          target: '#overviewMenuItem',
          // target: 'body',
          content: (
            <>
              <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Overview page
              </h3>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Overall details about your event planning. You can see easily how many answers you received, how many
                accepted your invitation but you can also use our checklist to organise other tasks.
              </p>
            </>
          ),
          placement: 'right',
        },
        {
          target: '#appMenuItem',
          // target: 'body',
          content: (
            <>
              <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Experience app
              </h3>
              <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
                Here is your photo app. All your guest will get access to your app where they can answer your questions
                but the most important action that they can do it to upload their photos and build your unique day from
                their view. It like a modern photo booth. Have fun !!!
              </p>
            </>
          ),
          placement: 'right',
        },
      ],
    });
  });

  const getLinkPerStep = (index: number) => {
    switch (index) {
      case 0:
      case 1:
      case 2:
        return '';
      case 3:
        return `/dashboard/w/${wedding.path}/planning`;
      case 4:
        return `/dashboard/w/${wedding.path}/guests`;
      case 5:
        if (typeOfScreen === ScreenType.mobile || typeOfScreen === ScreenType.tablet) return;
        return `/dashboard/w/${wedding.path}/website`;
      case 6:
        return `/dashboard/w/${wedding.path}/rsvp`;
      case 7:
        return `/dashboard/w/${wedding.path}/overview`;
    }
  };
  const handleCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;

    if (action === 'close') {
      dispatch(setIsTutorialOn(false));
      setState({ run: false, stepIndex: 0, tourActive: false });
      return;
    }

    if (type === 'step:after') {
      if (action === 'next') {
        setState({ run: true, stepIndex: index + 1 });
        const link = getLinkPerStep(index + 1);
        if (link) navigate(link);
      } else {
        setState({ run: true, stepIndex: index - 1 });
        const link = getLinkPerStep(index - 1);
        if (link) navigate(link);
      }
      return;
    }

    if (type === 'tour:end' || action === 'reset' || lifecycle === 'complete') {
      setState({ run: false, stepIndex: 0, tourActive: false });
      dispatch(setIsTutorialOn(false));
      return;
    }
  };

  const handleClickStart = () => {
    setState({ run: true, tourActive: true });
    dispatch(setIsTutorialOn(true));
    // navigate(`/dashboard/w/${wedding.path}/planning`);
  };

  const downloadCode = () => {
    const canvas = document.getElementById('QR') as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `whiteclover_app.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        style={{
          width: 40,
          position: 'relative',
          zIndex: 10,
          display: 'flex',
        }}
        className={styles[`navbar${typeOfScreen}`]}
      >
        <div className={styles.top}>
          <a href={props.logoLink ? props.logoLink : '#'}>
            <img style={{ width: '100%' }} src="/assets/logos/logo-emblem-purple-beta.svg" alt={'logo'} />
          </a>
        </div>
        <div className={styles.middle}>
          <div className={styles[`routes${typeOfScreen}`]}>
            <Active htmlElemRef={tabHtmlElem} finishAnimating={() => setAnimating(false)} animating={animating} />
            {props.options.map((page, key) => {
              if (page.title === 'Profile') {
                return (
                  <div
                    style={{
                      display:
                        typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop ? 'block' : 'none',
                    }}
                    id={page.id}
                    ref={refElems[key]}
                    key={key}
                    role={'button'}
                    tabIndex={0}
                    className={IsActiveOnNavbar(page.link)}
                    onMouseDown={() => {
                      if (typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop) setAnimating(true);
                      setTabHtmlElem(refElems[key]);
                      navigate(page.link);
                    }}
                  >
                    <img src={page.icon} alt={page.title} />
                    <a>{page.title}</a>
                  </div>
                );
              }
              if (page.title !== 'App') {
                return (
                  <div
                    id={page.id}
                    ref={refElems[key]}
                    key={key}
                    role={'button'}
                    tabIndex={0}
                    className={IsActiveOnNavbar(page.link)}
                    onMouseDown={() => {
                      if (typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop) setAnimating(true);
                      setTabHtmlElem(refElems[key]);
                      navigate(page.link);
                    }}
                  >
                    <img src={page.icon} alt={page.title} />
                    <a>{page.title}</a>
                  </div>
                );
              }

              return (
                <div
                  id={page.id}
                  key={key}
                  role={'button'}
                  tabIndex={0}
                  className={styles.app}
                  onMouseDown={() => {
                    window.location.pathname = `${page.link}`;
                  }}
                  style={
                    typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop ? { height: 20 } : {}
                  }
                >
                  <img
                    src={
                      typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                        ? '/rsvp/navbar/app_green.svg'
                        : page.icon
                    }
                    alt={page.title}
                  />

                  <a>{page.title}</a>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.bottom} ref={navRef}>
          <div
            // ref={popupRef}
            role={'button'}
            tabIndex={0}
            className={styles.bubbleCoupleInitials + ' ' + styles.pointerClick + ' ' + (showPopup && styles.popupOpen)}
            style={{ backgroundImage: `url(${blobId})`, backgroundSize: 'cover' }}
            onMouseDown={(e) => {
              if (e.button === 2) return;
              setShowPopup((prevState) => !prevState);
            }}
          >
            <p>{blobId ? '' : initials}</p>
          </div>
          {showPopup && (
            <div
              style={{ position: 'fixed', zIndex: 2050 }}
              className={styles.bottomPopup + ' ' + (showPopup && styles.bottomPopupShow)}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  outline: 'inherit',
                }}
              >
                <p className={'didacticP'}>Download QR code for RSVP</p>
                <button
                  key={'qrCode'}
                  onClick={() => downloadCode()}
                  style={{
                    background: 'none',
                    color: 'inherit',
                    border: 'none',
                  }}
                >
                  <QRCode
                    value={`https://app.whiteclover.io?code=${wedding.path}&type=qrcode`}
                    size={230} // the dimension of the QR code (number)
                    logoImage="/assets/logos/purple_simple_logo.svg"
                    removeQrCodeBehindLogo={true}
                    logoHeight={50}
                    logoWidth={50}
                    logoOpacity={1}
                    enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
                    qrStyle="dots" // type of qr code, wether you want dotted ones or the square ones
                    eyeRadius={25} // radius of the promocode eye
                    id={'QR'}
                    fgColor={'#63435b'}
                  />
                </button>
              </div>
              <div className={styles.weddingPathCode + ' ' + styles.bottomPopupItemCode + ' ' + styles.pointerClick}>
                <svg viewBox="0 0 12.32 9.33" width={16}>
                  <g>
                    <line className="st1" x1="7.6" y1="8.9" x2="7.6" y2="6.9"></line>
                    <rect width="1.9" height="1.9"></rect>
                    <rect x="1.9" y="1.9" width="1.9" height="1.9"></rect>
                    <rect x="3.7" y="3.7" width="1.9" height="1.9"></rect>
                    <rect x="1.9" y="5.6" width="1.9" height="1.9"></rect>
                    <rect y="7.5" width="1.9" height="1.9"></rect>
                  </g>
                </svg>
                <CopyToClipboard text={wedding.path} onCopy={() => setCopied(true)}>
                  <span>{wedding.path}</span>
                </CopyToClipboard>

                {copied ? <span style={{ color: '#835978', marginLeft: '10px' }}>Copied.</span> : null}
              </div>
              <div
                role={'button'}
                tabIndex={0}
                className={styles.bottomPopupItem + ' ' + styles.pointerClick}
                onMouseDown={async (e) => {
                  if (e.button === 2) return;
                  //TODO
                  // dispatch(removeCurrentWeddingWebsite());
                  setShowPopup(false);
                  window.location.pathname = '/dashboard/choose-event';
                }}
              >
                <Icon name={'change_wedding'} size={'s'} color={'#281B24'} />
                <p className={'didacticP'}>Change Social Event</p>
              </div>
              <div
                role={'button'}
                tabIndex={0}
                className={styles.bottomPopupItem + ' ' + styles.pointerClick}
                onMouseDown={async (e) => {
                  if (e.button === 2) return;
                  setShowPopup(false);
                  navigate(`/dashboard/w/${wedding.path}/profile`);
                }}
              >
                <Icon name={'profile'} size={'s'} color={'#281B24'} />
                <p className={'didacticP'}>My Profile</p>
              </div>
              <div
                role={'button'}
                tabIndex={0}
                className={styles.bottomPopupItem + ' ' + styles.pointerClick}
                onMouseDown={(e) => {
                  if (e.button === 2) return;
                  setShowPopup(false);
                  logout();
                }}
              >
                <Icon name={'sign_out'} size={'s'} color={'#281B24'} />
                <p className={'didacticP'}>Logout</p>
              </div>
            </div>
          )}
        </div>
      </motion.div>
      {showReminder && (typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop) && (
        <div className={styles.reminder}>
          <div>
            <Icon name={'reminder'} color={'#281B24'} size={'sPlus'} />
          </div>
          <p className={'didacticP'}>
            Don’t forget to finish your profile. Add your image, add more hosts to manage your events and your phone
            number so you can access the experience app.{' '}
            <button
              className={'btnLink didacticP'}
              onClick={() => {
                setShowReminder(false);
                navigate(`/dashboard/w/${wedding.path}/profile`);
              }}
            >
              Click me
            </button>
            .
          </p>
          <button className={'btnXSmall btnBorderless'} onClick={() => setShowReminder(false)}>
            <Icon name={'cancel'} color={'#281B24'} size={'xs'} />
          </button>
        </div>
      )}
      <button
        onClick={handleClickStart}
        style={{
          position: 'fixed',
          bottom: typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop ? '20px' : '150px',
          right: typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop ? '91px' : '2px',
          // height: '60px',
          // width: '60px',
          borderRadius: '28px',
          zIndex: '1000',
          backgroundColor: '#c7adc1',
          borderWidth: 'none',
          borderStyle: 'none',
          paddingRight: '16px',
          paddingLeft: '16px',
          paddingTop: '16px',
          paddingBottom: '16px',
          lineHeight: '16px',
          color: '#281b24',
        }}
      >
        <span>Tutorial</span>
      </button>
      {steps && (
        <Joyride
          callback={handleCallback}
          continuous
          run={isTutorialOn}
          stepIndex={stepIndex}
          showProgress={true}
          steps={steps}
          tooltipComponent={Tooltip}
          styles={{
            options: {
              arrowColor: secondaryStrongAccent,
              backgroundColor: secondaryStrongAccent,
              primaryColor: brandOperationalAccent,
              textColor: mainBackgroundAccent,
            },
          }}
        />
      )}
    </>
  );
};

export default Navbar;
