import { ReactComponent as apple } from './apple.svg';
import { ReactComponent as accept } from './accept.svg';
import { ReactComponent as accept_box } from './accept-box.svg';
import { ReactComponent as add_filled } from './add-filled.svg';
import { ReactComponent as add } from './add.svg';
import { ReactComponent as add_user } from './add-user.svg';
import { ReactComponent as add_user_filled } from './add-user-filled.svg';
import { ReactComponent as angle_square_right } from './angle-square-right.svg';
import { ReactComponent as angle_square_left } from './angle-square-left.svg';
import { ReactComponent as apps } from './apps.svg';
import { ReactComponent as apps_filled } from './apps_filled.svg';
import { ReactComponent as apps_sort } from './apps-sort.svg';
import { ReactComponent as arrow_back } from './arrow-back.svg';
import { ReactComponent as arrow_down } from './arrow-down.svg';
import { ReactComponent as arrow_right } from './arrow-right.svg';
import { ReactComponent as arrow_up } from './arrow-up.svg';
import { ReactComponent as at_filled } from './at-filled.svg';
import { ReactComponent as at } from './at-outline.svg';
import { ReactComponent as bank } from './bank.svg';
import { ReactComponent as boy } from './boy.svg';
import { ReactComponent as checkbox } from './checkbox.svg';
import { ReactComponent as checklist } from './checklist.svg';
import { ReactComponent as check_circle } from './check-circle.svg';
import { ReactComponent as columns_edit } from './columns-edit.svg';
import { ReactComponent as crown } from './crown.svg';
import { ReactComponent as browser } from './browser.svg';
import { ReactComponent as browser_filled } from './browser-filled.svg';
import { ReactComponent as briefcase } from './briefcase.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as calendar_filled } from './calendar-filled.svg';
import { ReactComponent as carrot } from './carrot.svg';
import { ReactComponent as cancel } from './cancel.svg';
import { ReactComponent as cancel_box } from './cancel-box.svg';
import { ReactComponent as ceremony } from './ceremony.svg';
import { ReactComponent as chair } from './chair.svg';
import { ReactComponent as clock } from './clock.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as clock_filled } from './clock-filled.svg';
import { ReactComponent as comments_filled } from './comments-filled.svg';
import { ReactComponent as comments } from './comments-outline.svg';
import { ReactComponent as copy } from './copy.svg';
import { ReactComponent as desktop } from './desktop.svg';
import { ReactComponent as dot_menu } from './dot-menu.svg';
import { ReactComponent as edit } from './edit.svg';
import { ReactComponent as edit_filled } from './edit-filled.svg';
import { ReactComponent as envelope } from './envelope.svg';
import { ReactComponent as event } from './event.svg';
import { ReactComponent as envelope_marker } from './envelope-marker.svg';
import { ReactComponent as envelope_marker_filled } from './envelope-marker-filled.svg';
import { ReactComponent as envelope_open } from './envelope-open.svg';
import { ReactComponent as eye } from './eye-outline.svg';
import { ReactComponent as eye_filled } from './eye-filled.svg';
import { ReactComponent as emoji_filled } from './emoji-filled.svg';
import { ReactComponent as emoji } from './emoji-outline.svg';
import { ReactComponent as facebook } from './facebook.svg';
import { ReactComponent as file_check } from './file-check.svg';
import { ReactComponent as file_open } from './file-open.svg';
import { ReactComponent as filters } from './filters.svg';
import { ReactComponent as filters_filled } from './filters-filled.svg';
import { ReactComponent as gift } from './gift.svg';
import { ReactComponent as girl } from './girl.svg';
import { ReactComponent as google_logo } from './google-logo.svg';
import { ReactComponent as google } from './google.svg';
import { ReactComponent as home } from './home.svg';
import { ReactComponent as image } from './image.svg';
import { ReactComponent as image_filled } from './image-filled.svg';
import { ReactComponent as info } from './info.svg';
import { ReactComponent as instagram } from './instagram.svg';
import { ReactComponent as label } from './label.svg';
import { ReactComponent as language } from './language.svg';
import { ReactComponent as link } from './link.svg';
import { ReactComponent as love_filled } from './love-filled.svg';
import { ReactComponent as love } from './love-outline.svg';
import { ReactComponent as lock } from './lock.svg';
import { ReactComponent as location } from './location.svg';
import { ReactComponent as location2 } from './location2.svg';
import { ReactComponent as location_filled } from './location-filled.svg';
import { ReactComponent as map } from './map.svg';
import { ReactComponent as microsoft } from './microsoft.svg';
import { ReactComponent as notifications_filled } from './notifications-filled.svg';
import { ReactComponent as notifications } from './notifications-outline.svg';
import { ReactComponent as plus } from './plus.svg';
import { ReactComponent as paypal } from './paypal.svg';
import { ReactComponent as profile_filled } from './profile-filled.svg';
import { ReactComponent as profile } from './profile-outline.svg';
import { ReactComponent as reminder } from './reminder.svg';
import { ReactComponent as remove_user } from './remove-user.svg';
import { ReactComponent as revolut } from './revolut.svg';
import { ReactComponent as save } from './save.svg';
import { ReactComponent as search } from './search.svg';
import { ReactComponent as send } from './send.svg';
import { ReactComponent as send_filled } from './send-filled.svg';
import { ReactComponent as share } from './share.svg';
import { ReactComponent as sign_out } from './sign-out.svg';
import { ReactComponent as smartphone } from './smartphone.svg';
import { ReactComponent as spreadsheet_file } from './spreadsheet-file.svg';
import { ReactComponent as star } from './star.svg';
import { ReactComponent as star_filled } from './star-filled.svg';
import { ReactComponent as tick } from './tick.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as trash_filled } from './trash-filled.svg';
import { ReactComponent as thunder } from './thunder.svg';
import { ReactComponent as users } from './users.svg';
import { ReactComponent as users_filled } from './users-filled.svg';
import { ReactComponent as question } from './question.svg';
import { ReactComponent as save_the_date } from './save-the-date.svg';
import { ReactComponent as upload } from './upload.svg';
import { ReactComponent as upload_cloud } from './upload-cloud.svg';
import { ReactComponent as change_wedding } from './change-wedding.svg';
import { ReactComponent as magic_wand } from './magic-wand.svg';
import { ReactComponent as yahoo_logo } from './yahoo-logo.svg';
import { ReactComponent as yahoo } from './yahoo.svg';
import { ReactComponent as whats_app } from './whats-app.svg';
import { ReactComponent as messenger } from './messenger.svg';
import { ReactComponent as viber } from './viber.svg';
import { ReactComponent as phone_call } from './phone-call.svg';
import { ReactComponent as write_user } from './write-user.svg';
import { ReactComponent as burger } from './burger.svg';
export const Icons: {
  [key in SVGIconName | string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  apple,
  accept,
  accept_box,
  add,
  add_filled,
  add_user,
  add_user_filled,
  angle_square_left,
  angle_square_right,
  apps,
  apps_filled,
  apps_sort,
  arrow_back,
  arrow_down,
  arrow_right,
  arrow_up,
  at_filled,
  at,
  bank,
  boy,
  browser,
  browser_filled,
  briefcase,
  calendar,
  calendar_filled,
  carrot,
  cancel,
  cancel_box,
  ceremony,
  chair,
  checkbox,
  crown,
  checklist,
  check_circle,
  columns_edit,
  clock,
  clock_filled,
  close,
  comments_filled,
  comments,
  copy,
  desktop,
  dot_menu,
  edit,
  edit_filled,
  envelope,
  envelope_marker,
  envelope_marker_filled,
  envelope_open,
  event,
  eye,
  eye_filled,
  emoji_filled,
  emoji,
  facebook,
  file_check,
  file_open,
  filters,
  filters_filled,
  gift,
  girl,
  google_logo,
  google,
  home,
  image,
  image_filled,
  info,
  instagram,
  label,
  language,
  link,
  location,
  location2,
  location_filled,
  love_filled,
  love,
  lock,
  map,
  magic_wand,
  microsoft,
  notifications_filled,
  notifications,
  plus,
  paypal,
  profile_filled,
  profile,
  reminder,
  remove_user,
  revolut,
  save,
  send,
  send_filled,
  share,
  sign_out,
  search,
  smartphone,
  spreadsheet_file,
  star,
  star_filled,
  tick,
  trash,
  trash_filled,
  thunder,
  upload,
  users,
  users_filled,
  question,
  save_the_date,
  upload_cloud,
  yahoo,
  change_wedding,
  yahoo_logo,
  whats_app,
  messenger,
  viber,
  phone_call,
  write_user,
  burger,
};

export declare type SVGIconName =
  | 'apple'
  | 'accept'
  | 'accept_box'
  | 'add'
  | 'add_filled'
  | 'add_user'
  | 'add_user_filled'
  | 'angle_square_left'
  | 'angle_square_right'
  | 'apps'
  | 'apps_filled'
  | 'apps_sort'
  | 'arrow_back'
  | 'arrow_down'
  | 'arrow_up'
  | 'arrow_right'
  | 'at_filled'
  | 'at'
  | 'bank'
  | 'boy'
  | 'browser'
  | 'browser_filled'
  | 'briefcase'
  | 'calendar'
  | 'cancel'
  | 'cancel_box'
  | 'calendar_filled'
  | 'carrot'
  | 'ceremony'
  | 'chair'
  | 'checkbox'
  | 'crown'
  | 'checklist'
  | 'check_circle'
  | 'clock'
  | 'clock_filled'
  | 'close'
  | 'columns_edit'
  | 'comments_filled'
  | 'comments'
  | 'copy'
  | 'desktop'
  | 'dot_menu'
  | 'edit'
  | 'edit_filled'
  | 'envelope'
  | 'envelope_marker'
  | 'envelope_marker_filled'
  | 'envelope_open'
  | 'eye'
  | 'eye_filled'
  | 'event'
  | 'emoji_filled'
  | 'emoji'
  | 'facebook'
  | 'file_check'
  | 'file_open'
  | 'filters'
  | 'filters_filled'
  | 'gift'
  | 'girl'
  | 'google_logo'
  | 'google'
  | 'home'
  | 'image'
  | 'image_filled'
  | 'info'
  | 'instagram'
  | 'label'
  | 'language'
  | 'link'
  | 'location'
  | 'location2'
  | 'location_filled'
  | 'love_filled'
  | 'love'
  | 'lock'
  | 'map'
  | 'magic_wand'
  | 'microsoft'
  | 'notifications_filled'
  | 'notifications'
  | 'paypal'
  | 'plus'
  | 'profile_filled'
  | 'profile'
  | 'reminder'
  | 'remove_user'
  | 'revolut'
  | 'save'
  | 'search'
  | 'send'
  | 'send_filled'
  | 'share'
  | 'sign_out'
  | 'smartphone'
  | 'spreadsheet_file'
  | 'star'
  | 'star_filled'
  | 'tick'
  | 'trash'
  | 'trash_filled'
  | 'thunder'
  | 'users'
  | 'users_filled'
  | 'question'
  | 'save_the_date'
  | 'upload'
  | 'upload_cloud'
  | 'yahoo'
  | 'change_wedding'
  | 'yahoo_logo'
  | 'whats_app'
  | 'messenger'
  | 'viber'
  | 'phone_call'
  | 'write_user'
  | 'burger';
