import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import styles from './Table.module.scss';
import { useTypeOfScreen } from '../../../../hooks';
import Lottie from 'react-lottie';
import location from '../../../../loader-animation.json';
import {
  AllowedVisitor,
  RSVPEvent,
  RSVPEventAttendance,
  TaxonomyOption,
  Wedding,
} from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Table as VTable, Column, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import UserInfo from '../userInfo/UserInfo';
import TaxonomyMultipleSelect from '../taxonomyMultipleSelect/TaxonomyMultipleSelect';
import Icon from '../../../../shared/Icon';
import { StatusEnum } from '../../../../shared/Enums';
import ReactTooltip from 'react-tooltip';

interface TableProps {
  data: AllowedVisitor[];
  userToken: string;
  wedding: Wedding;
  selectedGuestsObjects: Map<string, AllowedVisitor>;
  setSelectedGuestsObjects: any;
  selectedGuest: AllowedVisitor | undefined;
  setSelectedGuest: any;
  eventSelector: string;
}

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Table: React.FC<TableProps> = ({
  data,
  userToken,
  wedding,
  selectedGuestsObjects,
  setSelectedGuestsObjects,
  selectedGuest,
  setSelectedGuest,
  eventSelector,
}) => {
  const [itemsToShow, setItemsToShow] = useState<AllowedVisitor[]>([]);
  const [lastFromGroup, setLastFromGroup] = useState<string[]>([]);

  const [sortField, setSortField] = useState<'name' | 'status' | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [isHeaderChecked, setIsHeaderChecked] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef: React.MutableRefObject<any> = useRef(null); // Assuming this is the ref for the scrolling container

  const SCROLL_AMOUNT = 100; // This value can be adjusted based on your needs

  const smoothScroll = (element: { scrollLeft: any }, start: number, end: number, duration: number) => {
    let startTimestamp = 0;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      element.scrollLeft = start + progress * (end - start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        // Update the scroll position state after the animation is complete
        setScrollPosition(end);
      }
    };
    window.requestAnimationFrame(step);
  };

  const scrollTable = (direction: string) => {
    const element = containerRef.current;
    if (element) {
      const currentScroll = element.scrollLeft;
      const maxScrollLeft = element.scrollWidth - element.clientWidth;
      const newScrollPosition =
        direction === 'left'
          ? Math.max(currentScroll - SCROLL_AMOUNT, 0)
          : Math.min(currentScroll + SCROLL_AMOUNT, maxScrollLeft);

      smoothScroll(element, currentScroll, newScrollPosition, 500); // Scroll over 500ms
    }
  };

  const canScrollLeft = scrollPosition > 0;
  const canScrollRight = containerRef.current
    ? scrollPosition < containerRef.current.scrollWidth - containerRef.current.clientWidth
    : false;

  const tableRef = useRef(null);

  const typeOfScreen = useTypeOfScreen();

  const arrangeVisitors = (data: AllowedVisitor[]) => {
    const result: AllowedVisitor[] = [];
    setLastFromGroup(() => []);

    // Get all group visitor IDs
    const groupVisitorIds = data.reduce((ids, visitor) => {
      if (visitor?.isParent) {
        ids.push(...visitor.groupWithVisitorIds);
      }
      return ids;
    }, [] as string[]);

    data.forEach((visitor) => {
      // If the visitor is a parent, find its group visitors and add them to the result array
      if (visitor?.isParent) {
        result.push(visitor);
        const groupVisitors = data.filter((v) => visitor?.groupWithVisitorIds.includes(v?.id));
        //put the last from groupVisitors in the lastFromGroup array
        setLastFromGroup((prev) => {
          const newPrev = [...prev];
          newPrev.push(groupVisitors[groupVisitors.length - 1]?.id);
          return newPrev;
        });
        result.push(...groupVisitors);
      } else if (!groupVisitorIds.includes(visitor?.id)) {
        // If the visitor is not a parent and not a group visitor of any parent, add them to the result array
        result.push(visitor);
      }
    });

    return result;
  };

  const getSortedData = (field: 'name' | 'status' | null, sortD: 'asc' | 'desc' | undefined): AllowedVisitor[] => {
    const sortedData = [...data];
    if (field === 'name' && sortD) {
      sortedData.sort((a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`;
        const nameB = `${b.firstName} ${b.lastName}`;
        return nameA.localeCompare(nameB) * (sortD === 'asc' ? 1 : -1);
      });
    } else if (field === 'status' && sortD) {
      sortedData.sort((a, b) => (a.status - b.status) * (sortD === 'asc' ? 1 : -1));
    }
    return arrangeVisitors(sortedData);
  };

  const handleSort = (field: 'name' | 'status') => {
    // Toggle the sorting direction if the same field is clicked again
    let sortD: 'asc' | 'desc' | undefined = 'asc';
    if (sortField === field) {
      sortD = sortDirection ? (sortDirection === 'asc' ? 'desc' : undefined) : 'asc';
      setSortDirection((prev) => sortD);
    } else {
      setSortField(field);
      sortD = 'asc';
      setSortDirection('asc'); // Default to ascending when switching fields
    }

    // Set the sorted data
    const sortedData = getSortedData(field, sortD);
    setItemsToShow(sortedData);
  };

  const _rowGetter = ({ index }: any) => itemsToShow[index];

  const _noRowsRenderer = () => {
    return <Lottie options={defaultOptions1} height={100} width={100} />;
  };

  const getRowClassName = ({ index }: any) => {
    let classNameStyles = styles.rowClass;
    // Check if the current row index is in the selected rows set
    if (selectedRows?.has(itemsToShow[index]?.id) || (selectedGuest && itemsToShow[index]?.id === selectedGuest?.id)) {
      classNameStyles = classNameStyles + ' ' + styles.rowClassSelected;
    }
    if (
      itemsToShow[index]?.isParent &&
      itemsToShow[index]?.groupWithVisitorIds.some((item) => lastFromGroup.includes(item)) &&
      itemsToShow.some((item) => itemsToShow[index]?.groupWithVisitorIds.includes(item.id))
    ) {
      classNameStyles = classNameStyles + ' ' + styles.rowClassParent;
    } else if (
      !itemsToShow[index]?.isParent &&
      itemsToShow[index]?.groupWithVisitorIds.length > 0 &&
      !lastFromGroup.includes(itemsToShow[index]?.id)
    ) {
      classNameStyles = classNameStyles + ' ' + styles.rowClassParent;
    }
    return classNameStyles;
  };

  useEffect(() => {
    setItemsToShow(getSortedData(sortField, sortDirection));
  }, [data]);

  useEffect(() => {
    const allIds = Array.from(selectedGuestsObjects.keys());
    setSelectedRows(new Set(allIds));
    if (selectedGuestsObjects.size === data.length) setIsHeaderChecked(true);
    else setIsHeaderChecked(false);
  }, [selectedGuestsObjects]);

  const handleCheckBoxChange = (rowId: string, rowData: AllowedVisitor) => {
    const updatedSelection = new Set(selectedRows);
    const updatedMap = new Map<string, AllowedVisitor>(selectedGuestsObjects);

    if (updatedSelection.has(rowId)) {
      updatedSelection.delete(rowId);
      updatedMap.delete(rowId);
    } else {
      updatedSelection.add(rowId);
      updatedMap.set(rowId, rowData);
    }

    setSelectedRows(updatedSelection);
    setSelectedGuestsObjects(() => updatedMap);

    if (updatedSelection.size === 1) {
      setSelectedGuest(updatedMap.values().next().value);
    } else {
      setSelectedGuest(undefined);
    }
  };

  const renderGroupRow = (row: AllowedVisitor, isParent: boolean, length: number) => {
    // const mainEventAttend = row.attendToRsvpEvents.find((a) => a.rsvpEvent?.name === wedding.mainEvent?.name);
    const secondCheck = row.groupWithVisitorIds.length === 0 && row.maxAllowedPlus1 > 0;
    return (
      <Fragment>
        {((row.isParent && row.groupWithVisitorIds.length > 0) || secondCheck) && (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 100,
            }}
          >
            <button
              className={styles.groupButton}
              onClick={() => {
                if (secondCheck) return;
                if (itemsToShow.some((item) => row.groupWithVisitorIds.includes(item?.id))) {
                  //if it is expanded, then remove the group from itemsToShow
                  const newItemsToShow = itemsToShow.filter((item) => !row.groupWithVisitorIds.includes(item?.id));
                  setItemsToShow(newItemsToShow);
                  return;
                } else {
                  //if it is collapsed, then add the group to itemsToShow
                  const groupItemsToShow = data.filter((item) => row.groupWithVisitorIds.includes(item?.id));
                  setItemsToShow(arrangeVisitors([...itemsToShow, ...groupItemsToShow]));
                  return;
                }
              }}
            >
              <span className={'didacticSpan'}>
                +{secondCheck ? Number(row.maxAllowedPlus1 || 0) : row.groupWithVisitorIds.length}
              </span>
            </button>
            <div style={{ borderLeft: '2px solid #DAD8D9', height: '29px', position: 'absolute', marginTop: 50 }} />
          </div>
        )}
        {!isParent && length > 0 && (
          <Fragment>
            <div
              style={{
                borderLeft: '2px solid #DAD8D9',
                height: 80,
                width: 2,
                marginLeft: 14,
                marginTop: -80,
                position: 'absolute',
                zIndex: 99,
              }}
            />
            <div
              style={{
                borderTop: '2px solid #DAD8D9',
                height: 2,
                width: 25,
                position: 'absolute',
                marginLeft: 15,
              }}
            />
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <div className={styles.tableContainer}>
      {canScrollLeft && (
        <button className={styles.scrollLeft} onClick={() => scrollTable('left')}>
          <Icon name={'arrow_back'} size={'s'} color={'#63435B'} />
        </button>
      )}
      {canScrollRight && (
        <button className={styles.scrollRight} onClick={() => scrollTable('right')}>
          <Icon name={'arrow_right'} size={'s'} color={'#63435B'} />
        </button>
      )}
      <div
        className={styles.tableWrapper + ' ' + (typeOfScreen === 'Mobile' && styles.tableWrapperMobile)}
        ref={containerRef}
      >
        <AutoSizer>
          {({ height, width }) => (
            <VTable
              ref={tableRef}
              className={styles.reactVisualizedTable}
              gridClassName={styles.gridClass}
              headerClassName={styles.headerClass + ' didacticH4'}
              rowClassName={getRowClassName}
              width={width > 1294 ? width : 1294}
              height={height}
              headerHeight={typeOfScreen === 'Mobile' ? 32 : 56}
              rowHeight={80}
              rowCount={itemsToShow.length}
              rowGetter={_rowGetter}
              noRowsRenderer={_noRowsRenderer}
            >
              <Column
                dataKey="checkbox"
                width={32}
                minWidth={32}
                headerRenderer={({ label }) => (
                  <div style={{ textAlign: 'center' }}>
                    <input
                      style={{ verticalAlign: 'middle' }}
                      type="checkbox"
                      className={'input checkbox'}
                      checked={isHeaderChecked}
                      onChange={() => {
                        setSelectedGuest(undefined);
                        if (isHeaderChecked) {
                          // if it was checked before, then uncheck all
                          setSelectedRows(new Set());
                          setSelectedGuestsObjects(new Map());
                        } else {
                          const newMap = new Map<string, AllowedVisitor>();
                          // check all rows
                          const allIds = data.map((row) => {
                            newMap.set(row.id, row);
                            return row.id;
                          });
                          setSelectedGuestsObjects(newMap);
                          setSelectedRows(new Set(allIds));
                        }
                        setIsHeaderChecked(!isHeaderChecked); // toggle the state
                      }}
                    />
                  </div>
                )}
                cellRenderer={({ rowData }) => (
                  <Fragment>
                    <div style={{ textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        style={{ verticalAlign: 'middle' }}
                        className="input checkbox"
                        checked={selectedRows.has(rowData.id)}
                        onChange={() => handleCheckBoxChange(rowData.id, rowData)}
                      />
                    </div>
                  </Fragment>
                )}
              />
              <Column
                dataKey={'nothing'}
                width={32}
                minWidth={32}
                cellRenderer={({ rowData }) => (
                  <Fragment>{renderGroupRow(rowData, rowData.isParent, rowData.groupWithVisitorIds.length)}</Fragment>
                )}
              />
              <Column
                label="Name"
                dataKey="name"
                width={width * 0.2 - 50}
                minWidth={300}
                headerRenderer={({ dataKey, label }) => (
                  <button
                    className={'didacticH4 btnFlat'}
                    style={{ width: 'fit-content' }}
                    onClick={() => handleSort('name')}
                  >
                    {label}{' '}
                    {sortField === 'name' &&
                      (sortDirection ? (
                        <Icon name={sortDirection === 'asc' ? 'arrow_up' : 'arrow_down'} size={'s'} color={'black'} />
                      ) : (
                        ''
                      ))}
                  </button>
                )}
                cellRenderer={({ rowData }) => (
                  <Fragment>
                    {
                      <UserInfo
                        firstName={rowData.firstName}
                        lastName={rowData.lastName}
                        email={rowData.email}
                        imageSrc={rowData.connectedAccountDetails?.imageProfileUrl}
                        userToken={userToken}
                        setSelectedGuest={() =>
                          setSelectedGuest((prev: any) => {
                            if (prev !== undefined && prev.id === rowData.id) return undefined;
                            else return rowData;
                          })
                        }
                        isHost={rowData.isHost}
                      />
                    }
                  </Fragment>
                )}
              />
              <Column
                label="Phone Number"
                dataKey={'phone'}
                width={width * 0.2 - 50}
                minWidth={130}
                headerRenderer={({ dataKey, label }) => <h4 className={'didacticH4'}>{label}</h4>}
                cellRenderer={({ rowData }) => {
                  if (rowData?.phone) return <div>{rowData.phone}</div>;
                  else
                    return (
                      <div className={styles.flex}>
                        <Icon name={'info'} size={'s'} color={'#D54F68'} />
                        <p className={styles.smallText}>User will not be able to answer</p>
                      </div>
                    );
                }}
              />
              <Column
                label="Tags"
                dataKey="tags"
                width={width * 0.4 - 50}
                minWidth={500}
                headerRenderer={({ dataKey, label }) => <span className={'didacticH4'}>{label}</span>}
                cellRenderer={({ rowData }) => {
                  return (
                    <Fragment>
                      <TaxonomyMultipleSelect
                        weddingId={wedding.id}
                        userToken={userToken}
                        guest={rowData}
                        maxHeight={56}
                      />
                    </Fragment>
                  );
                }}
              />
              <Column
                label="Status"
                dataKey="status"
                width={width * 0.2 - 50}
                minWidth={200}
                headerRenderer={({ dataKey, label }) => (
                  <button
                    className={'didacticH4 btnFlat'}
                    style={{ width: 'fit-content' }}
                    onClick={() => handleSort('status')}
                  >
                    {label}{' '}
                    {sortField === 'status' &&
                      (sortDirection ? (
                        <Icon name={sortDirection === 'asc' ? 'arrow_up' : 'arrow_down'} size={'s'} color={'black'} />
                      ) : (
                        ''
                      ))}
                  </button>
                )}
                cellRenderer={({ rowData }) => (
                  <Fragment>
                    <p
                      className={styles.status + ' didacticP'}
                      style={{
                        backgroundColor: StatusEnum[rowData.status].backgroundColor,
                        color: StatusEnum[rowData.status].color,
                      }}
                    >
                      {StatusEnum[rowData.status].name}
                    </p>
                  </Fragment>
                )}
              />
              {eventSelector !== 'all' && (
                <Column
                  label="Attendance"
                  dataKey="attendance"
                  width={100}
                  minWidth={100}
                  headerRenderer={({ dataKey, label }) => <h4 className={'didacticH4'}>{label}</h4>}
                  cellRenderer={({ rowData, rowIndex }) => {
                    ReactTooltip.rebuild();
                    const index = rowData.attendToRsvpEvents?.findIndex(
                      (r: RSVPEventAttendance) => r.rsvpEvent?.id === eventSelector,
                    );
                    const isAttended = index > -1 && rowData.attendToRsvpEvents[index].attend;
                    return (
                      <Fragment>
                        <button
                          className={styles.attendanceStatus}
                          data-tip={index > -1 ? (isAttended ? 'Attending' : 'Not Attending') : 'Not Responded'}
                        >
                          <p style={{ width: 'fit-content' }}>
                            {index > -1 ? (
                              <Icon
                                name={isAttended ? 'check_circle' : 'close'}
                                size={'s'}
                                color={isAttended ? '#006150' : '#98243A'}
                              />
                            ) : (
                              <Icon name={'clock'} size={'s'} color={'#B48F08'} />
                            )}
                          </p>
                        </button>
                      </Fragment>
                    );
                  }}
                />
              )}
            </VTable>
          )}
        </AutoSizer>
        <ReactTooltip
          className={styles.toolTip}
          effect={'solid'}
          place={'top'}
          border={true}
          textColor={'#281B24'}
          backgroundColor={'#FDF6EB'}
          borderColor={'#63435B'}
        />
      </div>
    </div>
  );
};

export default Table;
