/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from './SepiaAppPromoteComponent.module.scss';
import templateStyles from '../sepiaTemplate.module.scss';

interface SepiaAppPromoteComponentProps {
  SepiaAppComponentCustomImage?: string;
  SepiaAppComponentCustomGraphics: string;
  SepiaAppComponentCustomMarriageCode: string;
}

const defaultValues = {
  SepiaAppComponentCustomGraphics: '/templates/sepia/illustration-app-promo.svg',
  SepiaAppComponentCustomImage: '/templates/sepia/app-logo.png',
  SepiaAppComponentCustomMarriageCode: '/home',
};

const SepiaAppPromoteComponent = ({
  SepiaAppComponentCustomImage,
  SepiaAppComponentCustomGraphics,
  SepiaAppComponentCustomMarriageCode,
}: SepiaAppPromoteComponentProps): JSX.Element => {
  return (
    <section id={`SepiaAppPromoteComponent`} className={styles.sepiaAppPromoteComponent}>
      <div>
        <div>
          <img
            src={
              SepiaAppComponentCustomGraphics
                ? SepiaAppComponentCustomGraphics
                : defaultValues.SepiaAppComponentCustomGraphics
            }
            alt="promote"
          />
        </div>
        <div>
          <div style={{ gap: 16 }} className={templateStyles.flex}>
            <img
              src={
                SepiaAppComponentCustomImage ? SepiaAppComponentCustomImage : defaultValues.SepiaAppComponentCustomImage
              }
              alt={'logo'}
            />
            <h2 className={templateStyles.fridayH2}>Our App</h2>
          </div>
          <h3 className={templateStyles.didacticH3}>Let’s get connected!</h3>
          <p className={templateStyles.didacticP}>
            All our guests are invited to our own wedding app. You can see the wedding schedule, complete and update
            your RSVP and upload your photos of the wedding. You can also interact to other guests photos with likes and
            comments. As if we had our own social media platform. Don{"'"}t be left out; join us now!
          </p>
          <a
            href="#"
            className={templateStyles.btn + ' ' + templateStyles.btnMain}
            style={{ textAlign: 'center' }}
            onClick={(e) => e.preventDefault()}
          >
            Join the app
          </a>
        </div>
      </div>
    </section>
  );
};
export default SepiaAppPromoteComponent;
