/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const utilSave = (
  currentWeddingWebsite: any,
  componentProps1Ref: any,
  tempComponentPropsRef: any,
  setIsSaving: any,
  setTempComponentProps1: any,
  justSave: any,
  save: any,
  isAutoSave = true,
  forceSave = false,
): void => {
  console.log('==========================');
  console.log('check if autoSaving...');

  console.log({
    currentWeddingWebsite,
    componentProps1Ref,
    tempComponentPropsRef,
    setIsSaving,
    setTempComponentProps1,
    justSave,
    save,
    isAutoSave,
    forceSave,
  });
  if (forceSave) {
    setIsSaving(true);
    justSave(componentProps1Ref?.current, isAutoSave);
    setTempComponentProps1(
      JSON.parse(JSON.stringify(componentProps1Ref?.current, (_, v) => (typeof v === 'bigint' ? v.toString() : v))),
    );
    return;
  }

  if (
    currentWeddingWebsite !== undefined && // TODO: ref here
    JSON.stringify(componentProps1Ref?.current, (_, v) => (typeof v === 'bigint' ? v.toString() : v)) !==
      JSON.stringify(tempComponentPropsRef?.current, (_, v) => (typeof v === 'bigint' ? v.toString() : v))
  ) {
    // console.log('autosaving...');
    setIsSaving(true);
    justSave(componentProps1Ref?.current, isAutoSave);
    setTempComponentProps1(
      JSON.parse(JSON.stringify(componentProps1Ref?.current, (_, v) => (typeof v === 'bigint' ? v.toString() : v))),
    );
  } else if (
    currentWeddingWebsite === undefined &&
    JSON.stringify(componentProps1Ref?.current, (_, v) => (typeof v === 'bigint' ? v.toString() : v)) !==
      JSON.stringify(tempComponentPropsRef?.current, (_, v) => (typeof v === 'bigint' ? v.toString() : v))
  ) {
    console.log('autosaving...');
    setIsSaving(true);
    save(true);
  } else {
    // console.log('no autosaving...');
    setIsSaving(false);
  }
  console.log('==========================');
};
