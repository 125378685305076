import { Fragment, useEffect, useState } from 'react';

import styles from './Header.module.scss';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../../../context/firebase.context';
import firebase from 'firebase';
import { logger } from '../../../config/Logger';
import cleanLocalStorage from '../../../app/helper/CleanLocalStorage';
import { Icon } from '../../../shared';
import useMobileCheck from '../../../hooks/useMobileChjeck';
import { AnimatePresence, motion } from 'framer-motion';
import { useTypeOfScreen } from '../../../hooks';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Header = (props: any): JSX.Element => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMobile = useMobileCheck();
  const typeOfScreen = useTypeOfScreen();

  useEffect(() => {
    // alert(location.pathname);
    FirebaseContext.getAuth();
  }, []);

  const logout = () => {
    logger.info({ message: 'clear local storage', functionName: 'Header.logout' });
    firebase.auth().signOut();
    cleanLocalStorage();
    window.location.pathname = '/dashboard';
  };
  const navigate = useNavigate();

  if (props.withChildren && props.title) {
    return (
      <div className={styles.header}>
        <div role="presentation" onClick={() => navigate(-1)}>
          <h1>{props.title}</h1>
        </div>
        <div>{props.withChildren}</div>
      </div>
    );
  }

  if (props.withChildren) {
    return (
      <div className={styles.header}>
        <div role="presentation" onClick={() => navigate(-1)}>
          <img src="/logo/logo-full-colored.svg" alt="whiteclover logo" />
        </div>
        <div>{props.withChildren}</div>
      </div>
    );
  }

  if (props.logout) {
    return (
      <div className={styles.header} style={isMobile ? { paddingRight: '4px', paddingLeft: '4px' } : {}}>
        <div role="presentation">
          <img src="/logo/logo-full-colored.svg" alt="whiteclover logo" />
        </div>
        <span style={{ display: 'flex', justifyContent: 'flex-end', gap: 32 }}>
          {props.backButton && (
            <button
              style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: isMobile ? '0.9em' : '1.1em' }}
              onClick={() => history.back()}
            >
              Back
            </button>
          )}
          <button
            style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: isMobile ? '0.9em' : '1.1em' }}
            onClick={() => logout()}
          >
            Log out
          </button>
        </span>
      </div>
    );
  }

  if (props.simple) {
    return (
      <div className={styles.header}>
        <div role="presentation" onClick={() => navigate(-1)}>
          <img src="/logo/logo-full-colored.svg" alt="whiteclover logo" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.header + ' ' + typeOfScreen}>
      <div>
        <img src="/logo/logo-full-colored.svg" alt="whiteclover logo" />
      </div>
      {isMobile && (
        <Fragment>
          <button
            onClick={() => setMobileMenuOpen(true)}
            className="btnMain"
            style={{ padding: '2px', border: 'none', background: 'transparent' }}
          >
            <Icon color={mobileMenuOpen ? 'transparent' : '#63435B'} name="burger" size="sPlus" />
          </button>

          <AnimatePresence>
            {mobileMenuOpen && (
              <motion.div
                initial={{
                  display: mobileMenuOpen ? 'flex' : 'none',
                  backgroundColor: '#63435B',
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  width: '0px',
                  height: '0px',
                  borderBottomLeftRadius: '100%',
                  zIndex: 100,
                  overflow: 'scroll',
                  maxHeight: '110vh',
                }}
                animate={{
                  width: 'calc(100vh + 100vw)',
                  height: 'calc(100vh + 100vw)',
                  transition: { duration: 0.5, ease: 'easeInOut' },
                }}
                exit={{
                  width: '0px',
                  height: '0px',
                  transition: { duration: 0.5, ease: 'easeInOut' },
                }}
              >
                <button
                  onClick={() => setMobileMenuOpen(false)}
                  className="btnMain"
                  style={{
                    position: 'absolute',
                    right: 24,
                    top: 30,
                    padding: '2px',
                    border: 'none',
                    background: 'transparent',
                    zIndex: 103,
                  }}
                >
                  <Icon color={mobileMenuOpen ? 'white' : '#63435B'} name="cancel" size="sPlus" />
                </button>
                <motion.ul
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.3, ease: 'easeInOut' } }}
                  exit={{ opacity: 0 }}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100svw',
                    height: '100svh',
                  }}
                  className={`${styles.list} ${styles.mobileList}`}
                >
                  <li>
                    <button
                      style={{ border: 'none', background: 'transparent', zIndex: 10, color: 'white' }}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <img src="/assets/logos/whiteclover-logo-white.svg" alt="logo white" />
                    </button>
                  </li>
                  <li>
                    <a
                      style={{ fontSize: 21, border: 'none', background: 'transparent', zIndex: 10, color: 'white' }}
                      href={'https://whiteclover.io'}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ fontSize: 21, border: 'none', background: 'transparent', zIndex: 10, color: 'white' }}
                      href={'https://app.whiteclover.io'}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Experience App
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ fontSize: 21, border: 'none', background: 'transparent', zIndex: 10, color: 'white' }}
                      href={'/dashboard'}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Blog
                    </a>
                  </li>
                  {props.login ? (
                    <li
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <a
                        style={{ fontSize: 21, border: 'none', background: 'transparent', zIndex: 10, color: 'white' }}
                        href={'/dashboard/create-account'}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        Create account
                      </a>
                      <a
                        style={{ fontSize: 21, zIndex: 10, cursor: 'not-allowed' }}
                        className={styles.letsStartButtonDisabled}
                      >
                        {"Let's get started"}
                      </a>
                    </li>
                  ) : (
                    <li
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <a
                        style={{ fontSize: 21, zIndex: 10, color: 'white' }}
                        href={'/dashboard/login'}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        Log In
                      </a>
                      <a
                        style={{ fontSize: 21, zIndex: 10 }}
                        href={'/dashboard'}
                        className={styles.letsStartButton}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {"Let's get started"}
                      </a>
                    </li>
                  )}
                </motion.ul>
              </motion.div>
            )}
          </AnimatePresence>
        </Fragment>
      )}
      {!isMobile && (
        <Fragment>
          <div>
            <a href={'https://whiteclover.io'}>Home</a>
            <a href={'https://app.whiteclover.io'}>Experience App</a>
            <a href={'/dashboard'}>Blog</a>

            {props.login ? (
              <>
                <a href={'/dashboard/create-account'}>Create account</a>
                <a className={styles.letsStartButtonDisabled}>{"Let's get started"}</a>
              </>
            ) : (
              <>
                <a href={'/dashboard/login'}>Log In</a>
                <a href={'/dashboard'} className={styles.letsStartButton}>
                  {"Let's get started"}
                </a>
              </>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Header;
