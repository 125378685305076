/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import templateStyles from '../Baptism2Template.module.scss';

import { Imagehelper } from '../../../app/helper/Imagehelper';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP, selectMarriageCode } from '../../../app/redux/authorization.slice.reducer';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { selectComponentProps } from '../../../app/redux/websiteBuilder.slice.reducer';
import { useDropzone } from 'react-dropzone';
import styles from '../../../component/dashboard/planning/Planning.module.scss';
import { IonSpinner } from '@ionic/react';

type InputComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

const InputBaptism2PaletteComponent = ({
  // componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: InputComponentProps): any => {
  const componentPropsStore = useSelector(selectComponentProps);

  const emptyComponentValues = {
    Palette: 0,
  };
  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(componentPropsStore) && componentPropsStore.length === 0) {
      let tempProps = componentProps[componentIndex];
      if (tempProps === undefined) {
        tempProps = emptyComponentValues;
        updateComponentProps(tempProps, componentIndex);
        autoSave();
      }
    }
  }, [componentProps[componentIndex]]);

  const updateProps = (input: any, key: string) => {
    console.log('=========================kouk========================');
    console.log(componentProps);
    Object.entries(componentProps).map((_x: any, i: number) => updateAllProps(i, input, key));
    console.log('=========================kouk========================');
  };

  const updateAllProps = (componentNumb: number, input: any, key: string) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentNumb]) {
      tempProps[componentNumb][key] = input;
    } else {
      tempProps[componentNumb] = new Object();
      tempProps[componentNumb][key] = input;
    }
    console.log(tempProps[componentNumb]);
    updateComponentProps(tempProps[componentNumb], componentNumb);
    autoSave();
  };

  if (!componentProps[componentIndex]) {
    return null;
  }
  return (
    <section style={{ marginBottom: '64px' }}>
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px', paddingTop: '16px' }}>
        Palette*
      </h3>
      <select
        style={{
          background: 'transparent',
          border: '1px solid white',
          borderRadius: '50px',
          width: '100%',
          textAlign: 'left',
          color: 'black',
          fontSize: 20,
        }}
        name="select"
        onChange={(e) => updateProps(Number(e.target.value), 'Palette')}
      >
        {[0, 1, 2].map(function (n: any) {
          return (
            <option
              style={{
                color: n === 1 ? 'blue' : n === 2 ? 'pink' : 'black',
                background: 'transparent',
                fontSize: '20px',
              }}
              key={n}
              value={n}
              selected={Number(componentProps[componentIndex]['Palette']) === n}
            >
              {n === 0 && 'Neutral'}
              {n === 1 && 'Boy'}
              {n === 2 && 'Girl'}
            </option>
          );
        })}
      </select>
    </section>
  );
};

export default InputBaptism2PaletteComponent;
