import '@ionic/react/css/core.css';
import './FinishProfile.scss';

import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';

import success from '../../../1127-success.json';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { selectJwt, selectMarriageCode, selectProfile } from '../../../app/redux/authorization.slice.reducer';
import { Backend } from '../../../config/backend';
import location from '../../../loader-animation.json';
import { logger } from '../../../config/Logger';
import { useNavigate } from 'react-router-dom';
import { UpdateConnectedAccountRequest } from '../../../pkg/protobuf/v2/brain/brain_types_pb';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const FinishProfile = (): JSX.Element => {
  const inputFile = useRef<HTMLInputElement>(null);
  const userToken = useSelector(selectJwt);
  const user = useSelector(selectProfile);
  const marriageCode = useSelector(selectMarriageCode);
  const [fileUrl, setFileurl] = useState<string>('');
  const [fileId, setFileId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(true);
  const [percentage, setPercentage] = useState<number>(0);
  const [fullName, setFullName] = useState<string>();
  const [fullNameError, setFullNameError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phoneNumber);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [email, setEmail] = useState<string>(user.email);

  const navigate = useNavigate();

  // start loading bullets by default
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initFileLoading, setInitFileLoading] = useState<boolean>(false);
  const [completedFile, setCompletedFile] = useState<boolean>(true);

  useEffect(() => {
    // if (user.accountFirstName.trim() !== '' && user.imageProfileUrl.trim() !== '') navigate('/main');
    if (user.accountFirstName.trim() !== '') navigate('/main');
  }, []);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current?.click();
    updateImageProfileOnly(userToken);
  };

  const updateProfile = async (jwt: string) => {
    try {
      if (fullName !== undefined && fullName.trim() !== '') {
        const profileRequest = new UpdateConnectedAccountRequest();
        profileRequest.id = user.connectedAccountId || user.connectedAccountPhoneId;
        profileRequest.accountId = user.accountId || user.accountPhoneId;
        profileRequest.weddingId = user.weddingId;
        if (fullName !== undefined && fullName.trim() !== '') profileRequest.fullname = fullName;
        profileRequest.imageProfileUrl = fileUrl === '' ? ' ' : fileUrl;
        profileRequest.phoneNumber = user.phoneNumber;
        const headers = new Headers();
        headers.set('Authorization', `Bearer ${jwt}`);
        await Backend.brainClientV2.updateConnectedAccount(profileRequest, { headers });

        navigate(-2);
      } else setFullNameError(true);
    } catch (e) {
      logger.error({ message: e, functionName: 'FinishProfile.updateProfile' });
    }
  };

  const updateImageProfileOnly = async (jwt: string) => {
    try {
      // console.log(fileUrl);
      if (!fileUrl) return;
      const profileRequest = new UpdateConnectedAccountRequest();
      profileRequest.id = user.connectedAccountId || user.connectedAccountPhoneId;
      profileRequest.accountId = user.accountId || user.accountPhoneId;
      profileRequest.weddingId = user.weddingId;
      profileRequest.fullname = fullName || ' ';
      profileRequest.imageProfileUrl = fileUrl === '' ? ' ' : fileUrl;
      profileRequest.phoneNumber = user.phoneNumber;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      await Backend.brainClientV2.updateConnectedAccount(profileRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'FinishProfile.updateImageProfileOnly' });
    }
  };

  return (
    <IonPage>
      <IonHeader className="header">
        <img className="headerLogo" src="/assets/logos/whiteclover-logo1.svg" alt="logo" />
      </IonHeader>
      <IonContent>
        <div className={'title-container didacticH2'} style={{ transform: 'translateY(-20px)' }}>
          <h2 style={{ fontWeight: 400 }}>Finish your Profile</h2>
        </div>
        <div className="finish">
          <div className="finishProfile">
            <button className={'addImageButton'} onClick={() => onButtonClick()}>
              <div className="addImage" style={{ backgroundImage: fileId === '' ? '' : `url(${fileId})` }}>
                {!completedFile ? (
                  <div className="loadingFinishProfileImage">
                    {!initFileLoading ? (
                      <Lottie options={defaultOptions1} height={60} width={60} />
                    ) : (
                      <Lottie options={defaultOptions2} height={60} width={60} />
                    )}
                  </div>
                ) : (
                  <img className={fileId !== '' ? 'addedImg' : ''} src={'/icon-avatar.svg'} alt="add" />
                )}
              </div>
              {fileId ? <p>Add your image profile</p> : <p>Change picture</p>}
              <p>Would be nice to be recognizable amongst the guests, don’t you agree?</p>
            </button>
            <input
              id="file"
              type="file"
              ref={inputFile}
              accept="image/jpeg, image/png, image/tiff, image/heic, image/heif"
              multiple={false}
              style={{ display: 'none' }}
              onChange={(event) => {
                Imagehelper.handleImageUpload(
                  event,
                  userToken,
                  marriageCode,
                  setPercentage,
                  percentage,
                  setLoading,
                  setFileurl,
                  setFileId,
                  setCompletedFile,
                  false,
                );
                setCompletedFile(false);
              }}
            />
          </div>
          <div className={'floatingInputContainer' + (fullNameError ? ' error' : '')}>
            <input
              className="field-input"
              id="inputName"
              name="inputName"
              type="text"
              placeholder=" "
              onChange={(event) => {
                setFullName(event.target.value);
                setFullNameError(false);
              }}
              value={fullName}
              style={{ width: '58vw' }}
            />
            <label className="floatingInputNewLabel" htmlFor="inputName">
              Full name*
            </label>
          </div>
          <div className="floatingInputContainer">
            <input
              className="field-input"
              id="inputPhone"
              name="inputPhone"
              type="text"
              placeholder=" "
              disabled={true}
              value={phoneNumber}
              style={{ width: '58vw' }}
            />
            <label className="floatingInputNewLabel" htmlFor="inputPhone">
              Phone*
            </label>
          </div>
          <button
            className="btnMain"
            style={{ height: '56px', borderRadius: 8, width: '67vw' }}
            disabled={fullName === undefined || fullName.trim() === ''}
            onClick={() => updateProfile(userToken)}
          >
            Finish
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FinishProfile;
