import clsx from 'clsx';

import { Icons, SVGIconName } from './Icons';
import styles from './Icon.module.scss';
import { ReactElement } from 'react';

export const ICON_SIZES = ['xs', 's', 'sPlus', 'm', 'mPlus', 'l'] as const;

type IconProps = {
  id?: string;
  name: SVGIconName;
  color?: string;
  size?: string;
  rotate?: string;
  filled?: boolean;
};

const Icon = ({
  id,
  name,
  filled,
  color = 'black',
  size = 'm',
  rotate = '0deg',
}: IconProps): ReactElement<any, any> | null => {
  let SVG = Icons[name];

  if (filled && !name.includes('filled') && Icons[name + '_filled']) {
    SVG = Icons[name + '_filled'];
  }

  if (!SVG) {
    return null;
  }

  return <SVG id={id} className={clsx(styles.Icon, styles[size])} style={{ fill: color, rotate: rotate }} />;
};

export default Icon;
