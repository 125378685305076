/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConnectedAccountRef } from '../../pkg/protobuf/v2/brain/brain_types_pb';
import { RootState } from '../store';
import { transliterate } from 'transliteration';

export interface AuthorizationState {
  loggedIn: boolean;
  streamFired: boolean;
  accessToken: string;
  accessTokenAfterOTP: string;
  tokenExpiryDate: string;
  marriageCode: string;
  user: user;
  account: ConnectedAccountRef;
  jwt: string;
  connectedAccounts: ConnectedAccountRef[];
  accounts: ConnectedAccountRef[];
  profile: ConnectedAccountRef;
  emailSubscriptionNotified: boolean;
  //connectedAccountId, blob
  profileImages: Map<string, string>;
}

const initialState: AuthorizationState = {
  loggedIn: false,
  streamFired: false,
  accessToken: '',
  accessTokenAfterOTP: '',
  tokenExpiryDate: '',
  marriageCode: '',
  user: {
    accountId: '',
    apiId: '',
    connectedAccountId: '',
    email: '',
    exp: 0,
    firstName: '',
    lastName: '',
    nickName: '',
    phoneNumber: '',
    roles: [],
    tenantName: '',
  },
  account: new ConnectedAccountRef(),
  jwt: '',
  connectedAccounts: [],
  accounts: [],
  profile: new ConnectedAccountRef(),
  emailSubscriptionNotified: false,
  profileImages: new Map<string, string>(),
};

interface permissions {
  admin: string;
  affiliate: string;
  connectedService: string;
  host: string;
  superTenantAdmin: boolean;
  visitor: string[];
}

interface roles {
  archived: boolean;
  id: string;
  name: string;
  permissions: permissions[];
}

export interface user {
  accountId: string;
  apiId: string;
  connectedAccountId: string;
  email: string;
  exp: number;
  firstName: string;
  lastName: string;
  nickName: string;
  phoneNumber: string;
  roles: roles[];
  tenantName: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initialUserState: user = {
  accountId: '',
  apiId: '',
  connectedAccountId: '',
  email: '',
  exp: 0,
  firstName: '',
  lastName: '',
  nickName: '',
  phoneNumber: '',
  roles: [],
  tenantName: '',
};

export interface Mentions {
  // connectedAccountId
  id: string;
  // accountName
  display: string;
}

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setStreamFired: (state, action: PayloadAction<boolean>) => {
      state.streamFired = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setAccessTokenAfterOTP: (state, action: PayloadAction<string>) => {
      state.accessTokenAfterOTP = action.payload;
    },
    setTokenExpiryDate: (state, action: PayloadAction<number>) => {
      const date = new Date();
      date.setSeconds(date.getSeconds() + action.payload);
      state.tokenExpiryDate = date.toISOString();
    },
    setMarriageCode: (state, action: PayloadAction<string>) => {
      state.marriageCode = transliterate(action.payload);
    },
    setUser: (state, action: PayloadAction<user>) => {
      state.user = action.payload;
    },
    setConnectedAccount: (state, action: PayloadAction<ConnectedAccountRef | undefined>) => {
      state.account = action.payload ? action.payload : new ConnectedAccountRef();
    },
    setJwt: (state, action: PayloadAction<string | undefined>) => {
      state.jwt = action.payload ? action.payload : '';
    },
    setListConnectAccounts: (state, action: PayloadAction<ConnectedAccountRef[] | undefined>) => {
      state.connectedAccounts = action.payload ? action.payload : [];
    },
    addListConnectAccounts: (state, action: PayloadAction<ConnectedAccountRef[] | undefined>) => {
      const currentState = state.connectedAccounts;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.connectedAccountId === newElement?.connectedAccountId);
        // if exists then replace previous value with new one
        if (index > 0) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.concat(newElement);
        }
      }

      state.connectedAccounts = currentState;
    },
    setListAccounts: (state, action: PayloadAction<ConnectedAccountRef[] | undefined>) => {
      state.accounts = action.payload ? action.payload : [];
    },
    addListAccounts: (state, action: PayloadAction<ConnectedAccountRef[] | undefined>) => {
      const currentState = state.accounts;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }
      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.connectedAccountId === newElement?.connectedAccountId);
        // if exists then replace previous value with new one
        if (index > 0) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.concat(newElement);
        }
      }

      state.accounts = currentState;
    },
    setProfileImages: (state, action: PayloadAction<Map<string, string>>) => {
      state.profileImages = action.payload ? action.payload : new Map<string, string>();
    },
    setProfile: (state, action: PayloadAction<ConnectedAccountRef | undefined>) => {
      state.profile = action.payload ? action.payload : new ConnectedAccountRef();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setLogout: (state, action) => {
      state.loggedIn = false;
      state.profile = new ConnectedAccountRef();
      state.accounts = [];
      state.connectedAccounts = [];
      state.jwt = '';
      state.marriageCode = '';
    },
    setEmailSubscriptionFlag: (state, action: PayloadAction<boolean>) => {
      state.emailSubscriptionNotified = action.payload;
    },
  },
});

export const {
  setLoggedIn,
  setStreamFired,
  setAccessToken,
  setAccessTokenAfterOTP,
  setTokenExpiryDate,
  setMarriageCode,
  setUser,
  setConnectedAccount,
  setJwt,
  setListConnectAccounts,
  addListConnectAccounts,
  setListAccounts,
  addListAccounts,
  setProfileImages,
  setProfile,
  setLogout,
  setEmailSubscriptionFlag,
} = authorizationSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.authorization.loggedIn;
export const selectStreamFired = (state: RootState) => state.authorization.streamFired;
export const selectAccessToken = (state: RootState) => state.authorization.accessToken;
export const selectAccessTokenAfterOTP = (state: RootState) => state.authorization.accessTokenAfterOTP;
export const selectTokenExpiryDate = (state: RootState) => state.authorization.tokenExpiryDate;
export const selectMarriageCode = (state: RootState) => state.authorization.marriageCode;
export const selectUser = (state: RootState) => state.authorization.user;
export const selectAccount = (state: RootState) => state.authorization.account;
export const selectJwt = (state: RootState) => state.authorization.jwt;
export const selectListConnectAccounts = (state: RootState) => state.authorization.connectedAccounts;
export const selectListAccounts = (state: RootState) => state.authorization.accounts;
export const selectProfileImages = (state: RootState) => state.authorization.profileImages;
export const selectProfile = (state: RootState) => state.authorization.profile;
export const selectEmailSubscriptionNotified = (state: RootState) => state.authorization.emailSubscriptionNotified;
export const selectAccountsCombinedWithConnectedAccountsForMentions = (state: RootState) => {
  const mentionList: Mentions[] = [];
  for (const account of state.authorization.accounts) {
    if (!account) continue;
    mentionList.push({
      id: account?.connectedAccountId,
      display: `${account?.accountFirstName} ${account?.accountLastName}`,
    });
  }
  return mentionList;
};

export default authorizationSlice.reducer;
