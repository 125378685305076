/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import './Home.scss';

import { IonContent, IonHeader, IonPage } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { isIOS, isIOS13 } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import * as tus from 'tus-js-client';

import {
  selectAccessTokenAfterOTP,
  selectAccount,
  selectAccountsCombinedWithConnectedAccountsForMentions,
  selectEmailSubscriptionNotified,
  selectJwt,
  selectListAccounts,
  selectListConnectAccounts,
  selectMarriageCode,
  selectProfile,
  selectUser,
  setEmailSubscriptionFlag,
  user,
} from '../../../app/redux/authorization.slice.reducer';
import {
  selectCommentInteractions,
  selectComments,
  selectHasNotification,
  selectMaxNumberOfPosts,
  selectNotifications,
  selectPostInteractions,
  selectPosts,
  selectWedding,
  setCommentInteractionReducer,
  setCommentsReducer,
  setHasNotification,
  setMaxNumberOfPosts,
  setNotifications,
  setPostInteractionReducer,
  setPostReducer,
  setPostsReducer,
  setTags,
  TagDetail,
} from '../../../app/redux/wedding.slice.recuder';
import { Backend } from '../../../config/backend';
import { logger } from '../../../config/Logger';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import {
  Comment,
  CommentInteraction,
  ConnectedAccountRef,
  DeleteNotificationRequest,
  Notification,
  Post,
  PostInteraction,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { CreatePostModal, EmailSubscriptionModal, LikesModal, MobileBottomModal } from '../../modals';
import { PostComponent, CommentsList } from '../../shared';

import { Mention, MentionsInput } from 'react-mentions';
import { v4 as uuid } from 'uuid';
import { getPostMessageReadyForEditing } from '../../lib';
import { useNavigate } from 'react-router-dom';
import { encode } from 'punycode';
import cleanLocalStorage from '../../../app/helper/CleanLocalStorage';

export interface GlobalState {
  status: string;
  streamEvents: any[];
  userToken: string;
  user: user;
  wedding: any;
  marriageCode: string;
  account: ConnectedAccountRef;
  connectedAccounts: ConnectedAccountRef[];
}

declare global {
  interface Window {
    OneSignal: any;
  }
}

const Home = (): JSX.Element => {
  const navigate = useNavigate();
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const user = useSelector(selectUser);
  const userRef = React.useRef<user>(user);
  userRef.current = user;
  const marriageCode = useSelector(selectMarriageCode);
  const wedding = useSelector(selectWedding);
  const account = useSelector(selectAccount);
  const connectedAccounts = useSelector(selectListConnectAccounts);
  const connectedAccountsRef = React.useRef<ConnectedAccountRef[]>(connectedAccounts);
  connectedAccountsRef.current = connectedAccounts;
  const accounts = useSelector(selectListAccounts);
  const accountsRef = React.useRef<ConnectedAccountRef[]>(accounts);
  accountsRef.current = accounts;
  const profile = useSelector(selectProfile);
  const notifications = useSelector(selectNotifications);
  const notificationsRef = React.useRef<Notification[]>(notifications);
  notificationsRef.current = notifications;
  const dispatch = useDispatch();

  const defaultState: Readonly<GlobalState> = {
    status: 'failed to contact the backend',
    streamEvents: [],
    userToken,
    user,
    wedding,
    marriageCode,
    account,
    connectedAccounts: [],
  };

  const [state, setState] = React.useState(defaultState);
  // create mutable state, so we can send it to other functions
  const stateRef = React.useRef<GlobalState>(state);
  stateRef.current = state;
  const jwt = useSelector(selectJwt);
  const inputFile = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState<number>(0);
  const [fileUrl, setFileurl] = useState<string>('');
  const [fileId, setFileId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newComment, setNewComment] = useState<string>('');
  //========================================= POSTS =======================================================
  const [currentPost, setCurrentPost] = useState<any>({});
  //============================================ MODALS =======================================================
  const [showBottomModalPost, setShowBottomModalPost] = useState<boolean>(false);
  const [showBottomModalComment, setShowBottomModalComment] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showBottomModalNotifications, setShowBottomModalNotifications] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [comingSoonModal, setComingSoonModal] = useState<boolean>(false);
  const [postInteractions1, setPostInteractions1] = useState<any>({});
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [showEditDeletePostModal, setShowEditDeletePostModal] = useState<boolean>(false);
  const [showCommentsModal, setShowCommentsModal] = useState<boolean>(false);
  const [showEditCommentModal, setShowEditCommentModal] = useState<boolean>(false);
  const [commentsForModal, setCommentsForModal] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editPostCallback, setEditPostCallback] = useState<any>(() => (x: string) => {
    logger.error({ message: 'empty editPostCallback()', functionName: 'Home.editPostCallback' });
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deletePostCallback, setDeletePostCallback] = useState<any>(() => (x: string) => {
    logger.error({ message: 'empty deletePostCallback() ', functionName: 'Home.deletePostCallback' });
  });

  //comments
  const [showEditDeleteCommentModal, setShowEditDeleteCommentModal] = useState<boolean>(false);
  const [likesAndCommentsCounters, setLikesAndCommentsCounters] = useState<any>([0, 0]);
  const [createCommentCallback, setCreateCommentCallback] = useState<any>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    () => (x: string, selectedCommentMentions: string[], selectedCommentTags: TagDetail[]) => {
      logger.log({ message: 'empty createCommentCallback()', functionName: 'Home.createCommentCallback' });
    },
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editCommentCallback, setEditCommentCallback] = useState<any>(() => (x: string) => {
    logger.log({ message: 'empty editCommentCallback()', functionName: 'Home.editCommentCallback' });
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateCommentCallback, setUpdateCommentCallback] = useState<any>(() => (x: string) => {
    logger.log({ message: 'empty editCommentCallback()', functionName: 'Home.updateCommentCallback' });
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteCommentCallback, setDeleteCommentCallback] = useState<any>(() => (x: string) => {
    logger.log({ message: 'empty deleteCommentCallback()', functionName: 'Home.deleteCommentCallback' });
  });
  //============================================ MODALS =======================================================
  const [showLikesModal, setShowLikesModal] = useState<boolean>(false);
  const hasNotifications = useSelector(selectHasNotification);
  const [loading, setLoading] = useState<boolean>(true);
  const [percentage, setPercentage] = useState<number>(0);
  const posts = useSelector(selectPosts);
  const maxNumberOfPosts = useSelector(selectMaxNumberOfPosts);
  const comments = useSelector(selectComments);
  const postInteractions = useSelector(selectPostInteractions);
  const commentInteractions = useSelector(selectCommentInteractions);
  const postRef = React.useRef<Post[]>(posts);
  postRef.current = posts;
  const commentRef = React.useRef<Comment[]>(comments);
  commentRef.current = comments;
  const postInteractionRef = React.useRef<PostInteraction[]>(postInteractions);
  postInteractionRef.current = postInteractions;
  const commentInteractionRef = React.useRef<CommentInteraction[]>(commentInteractions);
  commentInteractionRef.current = commentInteractions;
  const emailSubscriptionNotified = useSelector(selectEmailSubscriptionNotified);
  const [showEmailSubscriptionModal, setShowEmailSubscriptionModal] = useState<boolean>(false);
  //================================================================================================
  //TAGS AND MENTIONS
  const [reply, setReply] = useState('');
  const mentions = useSelector(selectAccountsCombinedWithConnectedAccountsForMentions);
  // selected tags
  const [selectedCommentTags, setSelectedCommentTags] = useState<TagDetail[]>([]);
  // selected mentions
  const [selectedCommentMentions, setSelectedCommentMentions] = useState<string[]>([]);
  //================================================================================================
  // end init
  React.useEffect(() => {
    const abortController = new AbortController();
    async function fetchStatus(stateRef: React.MutableRefObject<any>, setState: any) {
      try {
        const statValue = await BrainHelper.statusFunc(userToken);
        setState({
          status: statValue ? 'still alive' : 'oops backend is down',
          streamEvents: stateRef.current.streamEvents,
        });
      } catch (e: any) {
        logger.error({ message: e, functionName: 'Home.useEffect.fetchStatus' });
        if (e.message.includes('token')) {
          logger.info({ message: 'clear local storage', functionName: 'Home.useEffect' });
          cleanLocalStorage();
          navigate('/');
        }
      }
    }

    // fetch status
    fetchStatus(stateRef, setState);

    // wait 30 sec and then show modal for ios users to get their email for notifications
    const nonusefulcheck = emailSubscriptionNotified === false && (isIOS || isIOS13);
    logger.debug({
      message: `should ask for email: ${nonusefulcheck}, emailSubscriptionNotified: ${emailSubscriptionNotified}, iosCheck:${
        isIOS || isIOS13
      }`,
    });
    if (emailSubscriptionNotified === false && (isIOS || isIOS13)) {
      setTimeout(() => {
        dispatch(setEmailSubscriptionFlag(true));
        setShowEmailSubscriptionModal(true);
      }, 30000);
    }

    return () => {
      abortController.abort();
    };
  }, [userToken]);

  const clearNotifications = () => {
    notifications.map((not) => deleteNotification(not?.id, jwt));
    dispatch(setNotifications([]));
    dispatch(setHasNotification(false));
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function asyncTags(query: any, callback: any): any {
    if (!query) return [];

    const globalTags = await BrainHelper.listTags(jwt, wedding?.id, dispatch, setTags);

    const search = async () => {
      const suggestedTags: TagDetail[] = [];
      const suggestion = { id: uuid(), display: query };
      for (const tag of globalTags) {
        const regex = new RegExp(`.*${query}.*`);
        const found = tag.key.match(regex);
        if (found) suggestedTags.push({ id: tag?.id, display: tag.key });
      }
      if (suggestedTags.length > 0) {
        return suggestedTags.concat(suggestion);
      }

      return [suggestion];
    };
    search().then(callback);
  }

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current?.click();
  };

  const onDrop = (picture: any, isPublic: boolean) => {
    const file: File = picture.files[0];
    if (!'image/jpeg, image/png, image/tiff, image/heic, image/heif'.includes(file.type)) {
      return;
    }
    const endpoint =
      process.env.REACT_APP_REST !== '' ? process.env.REACT_APP_REST : 'http://rest-filestore.localhost/files/';
    // Create a new tus upload
    setShowModal(true);
    const upload = new tus.Upload(file, {
      endpoint: endpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      chunkSize: 5000000, // 5mb
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fingerprint: async (file, options) => `${file.name}${file.size}${file.lastModified}`,
      storeFingerprintForResuming: true,
      removeFingerprintOnSuccess: true,
      addRequestId: true,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      metadata: {
        filename: file.name,
        filetype:
          file.type === '' ? (file.name.toLocaleLowerCase().includes('heic') ? 'image/heic' : 'image/heif') : file.type,
        fileLabel: 'post description here',
        remoteLocation: marriageCode, //marriage name here
        visibility: isPublic ? 'public' : 'private',
      },
      onError: function (error) {
        setShowModal(false);
        setFileurl('');
        setFileId('');
        logger.error({ message: 'Failed because: ' + error, functionName: 'Home.onDrop' });
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        setPercentage((bytesUploaded / bytesTotal) * 100);
      },
      onSuccess: function () {
        if ('name' in upload.file) {
          let fileUrl;
          if (upload.url) {
            fileUrl = new URL(upload.url);
          } else {
            throw new Error(`wrong url: ${upload.url}`);
          }
          const paths = fileUrl.pathname.split('/');
          let fID = '';
          if (paths.length > 1) {
            fID = paths[2];
          }
          if (upload.url !== '') {
            setTimeout(() => {
              setLoading(false);
              setFileurl(upload.url || '');
              // setFileId(upload.url?.replace(endpoint || '', '') || '');
              setFileId(fID);
              picture.value = '';
            }, 1500);
          }
        }
      },
    });

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  };

  const postRender = (posts: Post[]) => {
    if (Array.isArray(posts) && posts.length > 0) {
      return posts.map((post: Post, i: number) => {
        return (
          <PostComponent
            key={i}
            post={post}
            wedding={wedding}
            userToken={jwt}
            account={account}
            profile={profile}
            connectedAccounts={connectedAccounts}
            accounts={accounts}
            showPostEditDeleteModal={(e: any) => setShowEditDeletePostModal(e)}
            setEditPostCallback={(e: any) => setEditPostCallback(e)}
            setDeletePostCallback={(e: any) => setDeletePostCallback(e)}
            setEditCommentCallback={(e: any) => setEditCommentCallback(e)}
            setCreateCommentCallback={setCreateCommentCallback}
            createCommentCallback={createCommentCallback}
            setDeleteCommentCallback={(e: any) => setDeleteCommentCallback(e)}
            setShowLikesModal={() => setShowLikesModal(true)}
            setPostInteractions={(e: any) => setPostInteractions1(e)}
            showEditDeleteCommentModal={(e: any) => setShowEditDeleteCommentModal(e)}
            setShowEditCommentModal={(e: any) => setShowEditCommentModal(e)}
            setLikesAndCommentsCounters={(e: any) => setLikesAndCommentsCounters(e)}
            setUpdateCommentCallback={setUpdateCommentCallback}
            setUpdateComment={(e: any) => setReply(e)}
            showCommentsModal={() => {
              setShowCommentsModal(true);
              setReply('');
            }}
            setCommentsForModal={(c: any) => setCommentsForModal(c)}
            setCurrentPost={(post: any) => setCurrentPost(post)}
          />
        );
      });
    }
  };

  const deleteNotification = async (id: string, jwt: string) => {
    const request = new DeleteNotificationRequest();
    request.id = id;
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${jwt}`);
    await Backend.brainClientV2.deleteNotification(request, { headers });
  };

  const setSinglePostAndRedirect = async (id: string) => {
    const post = posts.find((p) => p?.id === id);
    dispatch(setPostReducer(post));
    navigate('/post');
  };

  return (
    <>
      <IonPage>
        <IonHeader className="header">
          <div style={{ display: 'contents' }} role={'presentation'} onMouseDown={() => navigate('/home')}>
            <img
              loading="lazy"
              className="headerLogo"
              src="/assets/logos/whiteclover-logo1.svg"
              alt="logo"
              height={32}
            />
          </div>
          <button onClick={() => setShowNotifications(!showNotifications)}>
            <div className="notification" style={{ display: 'contents' }}>
              {hasNotifications ? (
                <img src="/assets/icons/icon-has-notifications.svg" alt="notification" height={24} />
              ) : (
                <img src="/assets/icons/icon-notification.svg" alt="notification" height={24} />
              )}
            </div>
          </button>
        </IonHeader>

        <IonContent className={'divWrapperAboveInfiniteScroll'}>
          <InfiniteScroll
            className={`mainWrapper`}
            height={50}
            dataLength={posts.length} //This is important field to render the next data
            next={() => {
              const p = page + 10;
              setPage(p);
              const recentPosts = BrainHelper.getListPostsOrderedByUpdatedTimeDesc(
                p,
                jwt,
                wedding.feedId,
                posts,
                comments,
                postInteractions,
                commentInteractions,
                postRef,
                dispatch,
                setPostsReducer,
                setCommentsReducer,
                setPostInteractionReducer,
                setCommentInteractionReducer,
                setMaxNumberOfPosts,
              );
              return recentPosts;
            }}
            hasMore={(posts.length as number) < (maxNumberOfPosts as number)}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            // below props only if you need pull down functionality
            refreshFunction={() => {
              const online = window.navigator.onLine;
              if (online) {
                // if we are online then clean the redux storages
                dispatch(setPostsReducer([]));
                dispatch(setPostInteractionReducer([]));
                dispatch(setCommentsReducer([]));
                dispatch(setNotifications([]));
              }
              setPage(0);
              BrainHelper.getListPostsOrderedByUpdatedTimeDesc(
                0,
                jwt,
                wedding.feedId,
                posts,
                comments,
                postInteractions,
                commentInteractions,
                postRef,
                dispatch,
                setPostsReducer,
                setCommentsReducer,
                setPostInteractionReducer,
                setCommentInteractionReducer,
                setMaxNumberOfPosts,
              );
            }}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>}
            // releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>}
          >
            {posts ? postRender(posts) : <></>}
          </InfiniteScroll>
        </IonContent>
        {/*-- Footer without a border  --*/}
        <div className="footerSection">
          <input
            id="file"
            type="file"
            ref={inputFile}
            accept="image/jpeg, image/png, image/tiff, image/heic, image/heif"
            multiple={false}
            style={{ display: 'none' }}
            onChange={(event) => onDrop(event.target, false)}
          />
          <div className="capture" tabIndex={0} role="button" onMouseDown={() => onButtonClick()}>
            <img src="/assets/icons/icon-plus.svg" alt="capture" />
          </div>
          <div
            className="profileImg"
            role={'button'}
            tabIndex={0}
            onMouseDown={(e) => {
              if (e.button === 2) return;
              if (profile?.accountId !== '' || profile?.accountPhoneId !== '') navigate('/profile');
            }}
          >
            <img src="/assets/icons/icon-avatar.svg" alt={'profile'} />
            <p>Profile</p>
          </div>
          <div
            role={'button'}
            tabIndex={0}
            className="feedImg"
            onMouseDown={(e) => {
              if (e.button === 2) return;
              navigate('/main');
            }}
          >
            <img src="/assets/icons/icon-more.svg" alt={'main'} />
            <p>Main</p>
          </div>
        </div>
      </IonPage>
      {/*=======================*/}
      {/*        MODALS         */}
      {/*=======================*/}
      {showLikesModal && (
        <LikesModal
          key={`likeModal-${encode(JSON.stringify(accounts, (_, v) => (typeof v === 'bigint' ? v.toString() : v)))}`}
          showModal={showLikesModal}
          setShowModal={setShowLikesModal}
          interactions={postInteractions1}
          accounts={accounts}
          connectedAccounts={connectedAccounts}
        />
      )}
      {showEditDeletePostModal && (
        <div className={'editDeletePostModal'}>
          <button onClick={() => setShowEditDeletePostModal(false)}></button>
          <div>
            <img
              src={'assets/graphics/line.svg'}
              alt={''}
              width={32}
              style={{ alignSelf: 'center', justifySelf: 'center', paddingBottom: 16 }}
            />
            <button
              onClick={() => {
                editPostCallback();
              }}
            >
              <img src={'assets/icons/icon-edit.svg'} alt={'edit'} width={32} style={{ marginRight: 8 }} />
              Edit Post Caption
            </button>
            <button
              onClick={() => {
                setShowBottomModalPost(true);
              }}
            >
              <img src={'assets/icons/icon-trash.svg'} alt={'delete'} width={32} style={{ marginRight: 8 }} />
              Delete Post
            </button>
          </div>
        </div>
      )}

      {showEditDeleteCommentModal && (
        <div className={'editDeletePostModal'}>
          <button
            onClick={() => {
              setShowEditDeleteCommentModal(false);
            }}
          ></button>
          <div>
            <img
              src={'assets/graphics/line.svg'}
              alt={''}
              width={32}
              style={{ alignSelf: 'center', justifySelf: 'center', paddingBottom: 16 }}
            />
            <button
              onClick={() => {
                editCommentCallback();
              }}
            >
              <img src={'assets/icons/icon-edit.svg'} alt={'edit'} width={32} style={{ marginRight: 8 }} />
              Edit Comment
            </button>
            <button
              onClick={() => {
                setShowBottomModalComment(true);
              }}
            >
              <img src={'assets/icons/icon-trash.svg'} alt={'delete'} width={32} style={{ marginRight: 8 }} />
              Delete Comment
            </button>
          </div>
        </div>
      )}
      {showEditCommentModal && (
        <div className={'notification-modal'} style={{ zIndex: '100' }}>
          {' '}
          <button
            style={{ height: '70%', width: '100%', opacity: 0 }}
            onClick={() => {
              setShowEditCommentModal(false);
            }}
          ></button>
          <div style={{ height: '30%', paddingLeft: 16, paddingRight: 8, display: 'flex', justifyContent: 'center' }}>
            <img
              src={'assets/graphics/line.svg'}
              alt={''}
              width={32}
              style={{ alignSelf: 'center', paddingBottom: 8 }}
            />
            <div className={'inputCommentContainer'}>
              <MentionsInput
                allowSuggestionsAboveCursor={true}
                className={'mentions inputComment editComment'}
                value={getPostMessageReadyForEditing(reply)}
                onChange={(event: any) => {
                  if (event.target.value.length >= 250) return;
                  setReply(event.target.value);
                }}
                placeholder={'Say Something...'}
                spellCheck={false}
              >
                <Mention
                  trigger={'@'}
                  data={mentions}
                  markup={'@@@____id__^^^____display__@@@^^^'}
                  style={{
                    backgroundColor: '#daf4fa',
                  }}
                  onAdd={(display) => {
                    setSelectedCommentMentions((mentions: any[]) => [...mentions, display]);
                  }}
                  appendSpaceOnAdd={true}
                />
                <Mention
                  trigger={'#'}
                  data={asyncTags}
                  markup={'$$$____id__~~~____display__$$$~~~'}
                  style={{
                    backgroundColor: '#daf4fa',
                  }}
                  onAdd={async (id, display) => {
                    setSelectedCommentTags(selectedCommentTags.concat({ id: `${id}`, display: display }));
                  }}
                  appendSpaceOnAdd={true}
                />
              </MentionsInput>
              <button
                onClick={() => {
                  updateCommentCallback(reply, selectedCommentMentions, selectedCommentTags);
                  setReply('');
                  setSelectedCommentMentions([]);
                  setSelectedCommentTags([]);

                  setShowEditDeleteCommentModal(false);
                  setShowEditCommentModal(false);
                }}
              >
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={'/assets/icons/icon-send-post.svg'}
                  alt="Send Message"
                />
              </button>
            </div>
          </div>
        </div>
      )}
      {showCommentsModal && commentsForModal && (
        <div className={'notification-modal'} style={{ zIndex: '10' }}>
          <button
            style={{ height: commentsForModal.length > 0 ? '25%' : '45%', width: '100%', opacity: 0 }}
            onClick={() => {
              setShowCommentsModal(false);
            }}
          ></button>
          <div style={{ height: commentsForModal.length > 0 ? '75%' : '55%', padding: 16 }}>
            <div className="interaction" style={{ borderBottom: '1px solid #E5E5E5', paddingBottom: 16, margin: 0 }}>
              <div>
                <button>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={
                      'assets/icons/icon-' +
                      (Object.keys(postInteractions1).length !== 0 &&
                      postInteractions1?.find(
                        (interaction: any) => interaction.accountId === account?.connectedAccountId,
                      ) !== undefined
                        ? 'filled-'
                        : '') +
                      'heart.svg'
                    }
                    width={25}
                    height={25}
                    alt="like"
                  />
                </button>
                <button>
                  <p
                    className={
                      Object.keys(postInteractions1).length !== 0 &&
                      postInteractions1?.find(
                        (interaction: any) => interaction.accountId === account?.connectedAccountId,
                      ) !== undefined
                        ? 'loves-loved'
                        : 'loves'
                    }
                  >
                    {likesAndCommentsCounters[0]} Loves
                  </p>
                </button>
              </div>
              <div>
                <button style={{ display: 'flex' }}>
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    loading="lazy"
                    src={'assets/icons/icon-comments-filled.svg'}
                    width={24}
                    height={24}
                    alt="comment"
                  />
                  <p>{likesAndCommentsCounters[1]} Comments</p>
                </button>
              </div>
            </div>

            <CommentsList
              setShowEditCommentModal={setShowEditCommentModal}
              setEditCommentCallback={setEditCommentCallback}
              setCreateCommentCallback={setCreateCommentCallback}
              setUpdateCommentCallback={setUpdateCommentCallback}
              setDeleteCommentCallback={setDeleteCommentCallback}
              setShowEditDeleteCommentModal={setShowEditDeleteCommentModal}
              setReply={setReply}
              account={account}
              accounts={accounts}
              connectedAccounts={connectedAccounts}
              wedding={wedding}
              jwt={jwt}
              userToken={userToken}
              profile={profile}
              commentsForModal={commentsForModal}
              post={currentPost}
            />

            <div
              style={{
                marginTop: 8,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderTop: '1px solid #E5E5E5',
              }}
            >
              <div style={{ width: '100%', paddingTop: 8, paddingBottom: 8 }}>
                <div className={'inputCommentContainer'}>
                  <MentionsInput
                    allowSuggestionsAboveCursor={true}
                    className={'mentions inputComment editComment'}
                    value={reply}
                    onChange={(event: any) => {
                      if (event.target.value.length >= 250) return;
                      setReply(event.target.value);
                    }}
                    placeholder={'Say Something...'}
                    spellCheck={false}
                  >
                    <Mention
                      trigger={'@'}
                      data={mentions}
                      markup={'@@@____id__^^^____display__@@@^^^'}
                      style={{
                        backgroundColor: '#daf4fa',
                      }}
                      onAdd={(display) => {
                        setSelectedCommentMentions((mentions: any[]) => [...mentions, display]);
                      }}
                      appendSpaceOnAdd={true}
                    />
                    <Mention
                      trigger={'#'}
                      data={asyncTags}
                      markup={'$$$____id__~~~____display__$$$~~~'}
                      style={{
                        backgroundColor: '#daf4fa',
                      }}
                      onAdd={async (id, display) => {
                        setSelectedCommentTags(selectedCommentTags.concat({ id: `${id}`, display: display }));
                      }}
                      appendSpaceOnAdd={true}
                    />
                  </MentionsInput>
                  <button
                    onClick={() => {
                      createCommentCallback(reply, selectedCommentMentions, selectedCommentTags);
                      setReply('');
                      setSelectedCommentMentions([]);
                      setSelectedCommentTags([]);
                      setShowCommentsModal(false);
                    }}
                  >
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={'/assets/icons/icon-send-post.svg'}
                      alt="Send Message"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <CreatePostModal
          wedding={wedding}
          percentage={percentage}
          userToken={jwt}
          showModal={showModal}
          loading={loading}
          setShowModal={setShowModal}
          fileId={fileId}
          setFileId={setFileId}
          fileUrl={fileUrl}
          profile={profile}
        />
      )}
      {showEmailSubscriptionModal && (
        <EmailSubscriptionModal
          userToken={jwt}
          showModal={showEmailSubscriptionModal}
          loading={loading}
          setShowModal={setShowEmailSubscriptionModal}
        />
      )}
      {comingSoonModal && (
        <div className={'notification-modal'}>
          <button
            style={{ height: '85%', width: '100%', opacity: 0 }}
            onClick={() => {
              setComingSoonModal(false);
            }}
          ></button>
          <div style={{ height: '15%', display: 'flex', justifyContent: 'center' }}>
            <div className={'inputCommentContainer'} style={{ display: 'flex', justifyContent: 'center' }}>
              Coming Soon
            </div>
          </div>
        </div>
      )}
      {showNotifications && (
        <div className="notification-modal">
          <button
            style={{ height: '50%', width: '100%', opacity: 0 }}
            onClick={() => setShowNotifications(false)}
          ></button>

          {notifications.length > 0 ? (
            <div style={{ height: '50%', width: '100%' }}>
              <img
                src={'assets/graphics/line.svg'}
                alt={''}
                width={32}
                style={{ alignSelf: 'center', justifySelf: 'center', paddingBottom: 16, paddingTop: 16 }}
              />
              <button
                className={'notifications-clear'}
                onClick={() => {
                  clearNotifications();
                }}
              >
                <p>Clear Notifications</p>
              </button>
              <div className={'notifications'}>
                {notifications.map((not, key) => (
                  <button
                    className={'singe-notification'}
                    key={key}
                    onClick={() => {
                      deleteNotification(not?.id, jwt).then(() => {
                        const notLists = notifications.filter((notif) => notif?.id !== not?.id);
                        dispatch(setNotifications(notLists));
                        if (notLists.length <= 0) dispatch(setHasNotification(false));
                        setSinglePostAndRedirect(not.postId);
                      });
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: not.message }} />
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <div className="notifications-list-hidden">
              <img
                src={'assets/graphics/line.svg'}
                alt={''}
                width={32}
                style={{ alignSelf: 'center', justifySelf: 'center', paddingBottom: 16 }}
              />
              <p>No notifications yet</p>
            </div>
          )}
        </div>
      )}
      {showBottomModalPost && (
        <MobileBottomModal
          title={'Are you sure you want to delete this post?'}
          callback={() => {
            deletePostCallback();
            setShowEditDeletePostModal(false);
            setShowBottomModalPost(false);
          }}
          exitCallback={() => {
            setShowBottomModalPost(false);
          }}
        />
      )}
      {showBottomModalComment && (
        <MobileBottomModal
          title={'Are you sure you want to delete this comment?'}
          callback={() => {
            deleteCommentCallback();
            setShowEditDeleteCommentModal(false);
            setShowBottomModalComment(false);
          }}
          exitCallback={() => {
            setShowBottomModalComment(false);
          }}
        />
      )}
      {/*{showBottomModalNotifications && (*/}
      {/*  <MobileBottomModal*/}
      {/*    title={'Are you sure you want to clear the notifications?'}*/}
      {/*    callback={() => {*/}
      {/*      clearNotifications();*/}
      {/*      setShowBottomModalNotifications(false);*/}
      {/*    }}*/}
      {/*    exitCallback={() => {*/}
      {/*      setShowBottomModalNotifications(false);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default Home;
