import { Fragment, useState } from 'react';
import { FirebaseContext } from '../../../context/firebase.context';
import styles from './Firebase.module.scss';
import Header from '../header/Header';
import { Link } from 'react-router-dom';
import { logger } from '../../../config/Logger';

const ResetPageComponent = (): JSX.Element => {
  const [message, setMessage] = useState<string | undefined>();
  const [errorState, setErrorState] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();

  const validateEmail = (email: string) => {
    return Boolean(
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
    );
  };

  const sendResetPasswordLink = (email: string) => {
    if (validateEmail(email)) {
      const auth = FirebaseContext.getAuth();
      auth
        .sendPasswordResetEmail(email)
        .then((resp) => {
          logger.log({ message: resp });
          setMessage('Link has been sent to: ' + email);
        })
        .catch((error) => {
          setMessage(undefined);
          setErrorState(error.message);
          setTimeout(() => setErrorState(undefined), 1500);
          logger.error({ message: error, functionName: 'ResetPageComponent.sendResetPasswordLink' });
        });
    } else {
      setMessage(undefined);
      setErrorState('Email is invalid');
      setTimeout(() => setErrorState(undefined), 1500);
    }
  };
  return (
    <Fragment>
      <div className={styles.main}>
        <Header login={true} />
        <div className={styles.wrapper}>
          <div className={styles.form}>
            <h2 className={'fridayH2'}>Reset your Password</h2>
            <p className={'didacticP'} style={{ marginBottom: '36px' }}>
              Enter your email.
            </p>
            <div>
              <label htmlFor="email" className={'didacticH4'}>
                Email
              </label>
              <input
                type="text"
                name={'email'}
                className={'input'}
                placeholder={'your@email.com'}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <p className={'didacticP ' + styles.error}>{errorState && errorState}</p>
            <p className={'didacticP ' + styles.success}>{message && message}</p>
            <button
              className={'btn btnMain'}
              disabled={Boolean(!email)}
              onClick={() => email && sendResetPasswordLink(email)}
            >
              Reset
            </button>
            <h4 className={'didacticH4'}>
              Go to <Link to={'/dashboard'}>login</Link>
            </h4>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPageComponent;
