import '../CreatePostModal/CreatePostModal.scss';

import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TooManyTriesModal = (props: any): JSX.Element => {
  return (
    <div className={props.showModal ? 'show-modal' : 'default-modal'}>
      <div className={'create-post'}>
        <div>
          <p>{props.message}</p>
        </div>

        <div>
          <button
            className={'post-btn'}
            onMouseDown={() => {
              props.setShowModal(false);
              if (props.isRedirect) {
                //TODO: CHANGE TO OVERVIEW
                window.location.pathname = props.redirectPath;
              }
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default TooManyTriesModal;
