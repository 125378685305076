import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ShareList.module.scss';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { PublicAllowedVisitor, PublicListAllowedVisitorsResponse } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import Icon from '../../../shared/Icon';
import { Avatar } from '../table/Table';
import { transliterate } from 'transliteration';

const ShareList = (): JSX.Element => {
  const [visitors, setVisitors] = useState<PublicListAllowedVisitorsResponse | null>(null);
  const [renderedVisitors, setRenderedVisitors] = useState<PublicAllowedVisitor[] | undefined>(undefined);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [sendQuery, setSendQuery] = useState('');
  const [selected, setSelected] = useState<0 | 1 | 2>(2);
  const { weddingId, eventId } = useParams();
  const [innerWidthState, setInnerWidthState] = useState(800);
  const [sort, setSort] = useState<'name' | 'table'>('name');

  const fetchVisitors = async (weddingId: string, query: string) => {
    const allowedVisitors = await BrainHelper.getPublicAllowedVisitors(weddingId, query, eventId);
    switch (sort) {
      case 'name':
        allowedVisitors?.allowedVisitors.sort((a, b) => {
          const aName = transliterate(`${a?.firstName} ${a?.lastName}`);
          const bName = transliterate(`${b?.firstName} ${b?.lastName}`);
          return aName.localeCompare(bName);
        });
        break;
      case 'table':
        allowedVisitors?.allowedVisitors.sort((a, b) =>
          a?.taxonomyOptions[0]?.key.localeCompare(b?.taxonomyOptions[0]?.key),
        );
        break;
    }

    setVisitors(allowedVisitors);
    return allowedVisitors;
  };

  useEffect(() => {
    function handleResize() {
      // Set boolean's state
      setInnerWidthState(window.innerWidth);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (weddingId) fetchVisitors(weddingId, '');
  }, [weddingId]);

  useEffect(() => {
    if (sendQuery.length > 0) {
      const timer = setTimeout(() => {
        if (sendQuery && weddingId) {
          fetchVisitors(weddingId, sendQuery).then((visitors) => {
            switch (sort) {
              case 'name':
                visitors?.allowedVisitors.sort((a, b) => {
                  const aName = transliterate(`${a?.firstName} ${a?.lastName}`);
                  const bName = transliterate(`${b?.firstName} ${b?.lastName}`);
                  return aName.localeCompare(bName);
                });
                break;
              case 'table':
                visitors?.allowedVisitors.sort((a, b) =>
                  a?.taxonomyOptions[0]?.key.localeCompare(b?.taxonomyOptions[0]?.key),
                );
                break;
            }
            if (selected === 2) setRenderedVisitors(visitors?.allowedVisitors);
            else if (selected === 1)
              setRenderedVisitors(visitors?.allowedVisitors?.filter((visitor) => visitor.arrival));
            else if (selected === 0)
              setRenderedVisitors(visitors?.allowedVisitors?.filter((visitor) => !visitor.arrival));
          });
        }
      }, 600);

      return () => clearTimeout(timer);
    } else {
      if (weddingId)
        fetchVisitors(weddingId, '').then((visitors) => {
          switch (sort) {
            case 'name':
              visitors?.allowedVisitors.sort((a, b) => {
                const aName = transliterate(`${a?.firstName} ${a?.lastName}`);
                const bName = transliterate(`${b?.firstName} ${b?.lastName}`);
                return aName.localeCompare(bName);
              });
              break;
            case 'table':
              visitors?.allowedVisitors.sort((a, b) =>
                a?.taxonomyOptions[0]?.key.localeCompare(b?.taxonomyOptions[0]?.key),
              );
              break;
          }
          if (selected === 2) setRenderedVisitors(visitors?.allowedVisitors);
          else if (selected === 1) setRenderedVisitors(visitors?.allowedVisitors?.filter((visitor) => visitor.arrival));
          else if (selected === 0)
            setRenderedVisitors(visitors?.allowedVisitors?.filter((visitor) => !visitor.arrival));
        });
    }
  }, [selected, sendQuery, sort]);

  const onUpdateArrival = async (visitor: PublicAllowedVisitor) => {
    if (eventId) {
      const updatedVisitor = await BrainHelper.updatePublicArrivalForVisitor(visitor.id, eventId, !visitor.arrival);
      setVisitors((prevState) => {
        if (prevState) {
          const temp = JSON.parse(JSON.stringify(prevState));
          if (temp) temp.allowedVisitors.find((v: any) => v.id === visitor.id).arrival = updatedVisitor.arrival;
          return temp;
        }
        return prevState;
      });

      setRenderedVisitors((prevState) => {
        if (prevState) {
          const temp = JSON.parse(JSON.stringify(prevState));
          if (temp) temp.find((v: any) => v.id === visitor.id).arrival = updatedVisitor.arrival;
          return temp;
        }
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <div className={styles.wrapper}>
      <div className={styles.bgColor}></div>
      <center>
        <img loading="lazy" className={styles.logo} src="/assets/logos/whiteclover-logo1.svg" alt="logo" height={42} />
      </center>
      <section className={styles.board}>
        <div className={styles.header}>
          {visitors?.weddingImg ? (
            <img
              src={
                (process.env.REACT_APP_REST !== ''
                  ? process.env.REACT_APP_REST
                  : 'http://rest-filestore.localhost/files/') + visitors?.weddingImg
              }
              alt={'wedding'}
            />
          ) : (
            <img src={'assets/graphics/chs_wedding-placeholder-Planning.png'} alt="Couple" />
          )}
        </div>
        <div style={{ width: '100%' }}>
          <h2 className={'fridayH2'}>{visitors?.weddingName}</h2>
          <h4 className={'fridayH4'}>{visitors?.rsvpEventName} List</h4>
        </div>
        <div className={styles.sticky} style={{ width: '100%', paddingTop: '24px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '40px',
              gap: '12px',
            }}
          >
            <div className={styles.searchBar + ' ' + (isSearchActive ? styles.searchBarActive : '')}>
              <img src={'/rsvp/guests/searchIcon' + (isSearchActive ? '-highlighted' : '') + '.svg'} alt="Search" />
              <input
                value={sendQuery}
                placeholder="Search user name"
                onFocus={() => setIsSearchActive(true)}
                onBlur={() => setIsSearchActive(false)}
                onChange={(e) => {
                  setSendQuery(e.target.value);
                }}
              />
            </div>
            <div style={{ width: '150px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
              Sort by:
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  key={'sortByName'}
                  className={styles.buttonSort}
                  style={{ cursor: 'pointer' }}
                  onMouseDown={() => setSort('name')}
                >
                  Name
                </button>
                <button
                  key={'sortByTable'}
                  className={styles.buttonSort}
                  style={{ cursor: 'pointer' }}
                  onMouseDown={() => setSort('table')}
                >
                  Table
                </button>
              </div>
            </div>
          </div>
          <section className={styles.tableHeader}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <h4
              className={'didacticH4 ' + (selected === 0 && styles.selected)}
              style={{ cursor: 'pointer' }}
              onMouseDown={(e) => {
                if (e.button === 2) return;
                setSelected(0);
              }}
            >
              {visitors?.allowedVisitors?.filter((v) => !v.arrival).length} Awaiting
            </h4>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <h4
              className={'didacticH4 ' + (selected === 1 && styles.selected)}
              style={{ cursor: 'pointer' }}
              onMouseDown={(e) => {
                if (e.button === 2) return;
                setSelected(1);
              }}
            >
              {visitors?.allowedVisitors?.filter((v) => v.arrival).length} Arrived
            </h4>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <h4
              className={'didacticH4 ' + (selected === 2 && styles.selected)}
              style={{ cursor: 'pointer' }}
              onMouseDown={(e) => {
                if (e.button === 2) return;
                setSelected(2);
              }}
            >
              {visitors?.allowedVisitors?.length} Total Guests
            </h4>
          </section>
          <hr />
        </div>
        <div className={styles.tableWrapper}>
          {innerWidthState >= 550 ? (
            <table
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <tbody style={{ width: '100%' }}>
                <tr
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                  }}
                >
                  <th style={{ width: '60px', textAlign: 'left' }}></th>
                  <th style={{ width: '35%', textAlign: 'left', cursor: 'pointer' }} onClick={() => setSort('name')}>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      Name <Icon size={'sPlus'} name="arrow_down" />
                    </span>
                  </th>
                  <th style={{ width: '15%', textAlign: 'center' }}>Plus Ones</th>
                  <th style={{ width: '25%', textAlign: 'center', cursor: 'pointer' }} onClick={() => setSort('table')}>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      Table <Icon size={'sPlus'} name="arrow_down" />
                    </span>
                  </th>
                  <th style={{ width: '25%', textAlign: 'right' }}>Arrival</th>
                </tr>
              </tbody>
              {renderedVisitors && renderedVisitors?.length > 0 ? (
                <tbody style={{ width: '100%' }}>
                  {renderedVisitors?.map((visitor, index) => {
                    return (
                      <tr
                        key={visitor.id + '_' + index}
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        className={styles.trRow}
                      >
                        <td style={{ width: '60px' }}>
                          <Avatar
                            name={`${visitor.firstName} ${visitor.lastName}`}
                            userToken={''}
                            connectedAccountDetails={undefined}
                            query={''}
                            _id={'shareListAvatar' + visitor.id}
                          />
                        </td>
                        <td style={{ width: '35%', textAlign: 'left' }}>
                          {visitor.firstName + ' ' + visitor.lastName}
                        </td>
                        <td style={{ width: '15%' }}>
                          <div className={styles.tag}>
                            <Icon name={'add_user'} size={'s'} color={'#281B24'} />
                            {visitor.plus1?.toString()}
                          </div>
                        </td>
                        <td
                          style={{
                            width: '25%',
                            maxWidth: '25%',
                            display: 'flex',
                            flexWrap: 'nowrap',
                            overflowX: 'auto',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                          }}
                        >
                          {visitor.taxonomyOptions?.map((option) => {
                            if (option.key.toLowerCase().includes('table'))
                              return (
                                <div className={`${styles.tag} ${styles.tagTable}`}>
                                  <Icon name={'chair'} size={'s'} color={'#281B24'} />
                                  {option.key.replace('table', '').replace(' ', '')}
                                </div>
                              );
                          })}
                        </td>
                        <td style={{ width: '25%', display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                          <input
                            type="checkbox"
                            className={'input checkbox'}
                            checked={visitor.arrival}
                            onChange={async () => onUpdateArrival(visitor)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody className={styles.empty} style={{ width: '100%' }}>
                  <Icon name={'search'} size={'m'} />
                  <h4 className={'fridayH4'}>No guest found</h4>
                  <p className={'didacticP'}>Please check your search for any typos and try again.</p>
                </tbody>
              )}
            </table>
          ) : (
            <ul>
              {renderedVisitors?.map((visitor) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <li
                    key={visitor.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      marginBottom: '26px',
                    }}
                  >
                    <div style={{ flexGrow: '1', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                      <Avatar
                        name={`${visitor.firstName} ${visitor.lastName}`}
                        userToken={''}
                        connectedAccountDetails={undefined}
                        query={''}
                        _id={'shareListAvatar' + visitor.id}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flexGrow: '14',
                        gap: '8px',
                      }}
                    >
                      <div style={{ textAlign: 'left' }}>{visitor.firstName + ' ' + visitor.lastName}</div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <div
                          style={{
                            // width: '25%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {visitor.taxonomyOptions?.map((option) => {
                            if (option.key.toLowerCase().includes('table'))
                              return (
                                <div className={`${styles.tag} ${styles.tagTable}`}>
                                  <Icon name={'chair'} size={'s'} color={'#281B24'} />
                                  {option.key}
                                </div>
                              );
                          })}
                        </div>
                        <div className={styles.tag}>
                          <Icon name={'add_user'} size={'s'} color={'#281B24'} />
                          {visitor.plus1?.toString()}
                        </div>
                      </div>
                    </div>
                    <div style={{ flexGrow: '1', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                      <input
                        type="checkbox"
                        className={'input checkbox'}
                        checked={visitor.arrival}
                        onChange={async () => onUpdateArrival(visitor)}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </section>
    </div>
  );
};

export default ShareList;
