import React, { Fragment, useEffect, useState } from 'react';
import templateStyles from '../TheArtist.module.scss';

import { Imagehelper } from '../../../app/helper/Imagehelper';
import { useDropzone } from 'react-dropzone';
import styles from '../../../component/dashboard/planning/Planning.module.scss';
import { IonSpinner } from '@ionic/react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ImageDragNDrop = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loading,
  isDropzoneActive,
  setIsDropzoneActive,
  userToken,
  weddingPath,
  percentage,
  setPercentage,
  setLoading,
  setFileUrl,
  setFileId,
  setCompletedFile,
  // updateProps,
  componentProps,
}: any): JSX.Element => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 20000000,
    accept: {
      'image/*': [],
      'image/heif': ['.heic', '.heif'],
    },
    onDragEnter: () => {
      setIsDropzoneActive(true);
    },
    onDragLeave: () => {
      setIsDropzoneActive(false);
    },
    onDropAccepted: (filesAccepted: any) => {
      console.log('---------------------------');
      console.log('ON DROP ACCEPTED: ', filesAccepted);
      for (let i = 0; i < filesAccepted.length; i++) {
        Imagehelper.handleCompressedImageUpload(
          filesAccepted[i],
          userToken,
          weddingPath,
          setPercentage,
          percentage,
          setLoading,
          setFileUrl,
          setFileId,
          setCompletedFile,
          true,
        );
      }
    },
    onDropRejected: (fileRejections: any) => {
      console.log(
        fileRejections[0].errors[0].message +
          '. Please only Upload images of jpg/jpeg/png/gif format of max size 10Mb. ',
      );
    },
  });

  let files = acceptedFiles.map((file: any) => <p key={file.path}>{file.path} Drop or browse to replace images</p>);

  useEffect(() => {
    files = acceptedFiles.map((file: any) => <p key={file.path}>{file.path} Drop or browse to replace images</p>);
    setShowSpinner(true);
  }, [acceptedFiles]);

  useEffect(() => {
    if (percentage === 0) {
      setShowSpinner(false);
    }

    if (percentage === 100)
      setInterval(() => {
        setShowSpinner(false);
      }, 5000);
  }, [percentage]);

  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        className={templateStyles.dragNDrop}
        style={{
          width: '100%',
        }}
      >
        <div
          role={'button'}
          tabIndex={0}
          onMouseDown={() => setIsDropzoneActive(true)}
          onMouseUp={() => setIsDropzoneActive(false)}
          className={templateStyles.dropzone}
          {...getRootProps()}
          style={isDropzoneActive ? { border: 'dotted 1px #00C9A5', width: '100%' } : { width: '100%' }}
        >
          <input {...getInputProps()} />

          {files?.length > 0 || componentProps['image'] !== undefined ? (
            <Fragment>
              {showSpinner ? (
                <span
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <IonSpinner name="lines" />
                  {percentage > 0 && percentage < 100 ? <span>{percentage.toFixed(2)} %</span> : ''}
                </span>
              ) : (
                <img src="/rsvp/image-default.svg" alt="uploaded" />
              )}
              <p className={'didacticP'} style={isDropzoneActive ? { color: '#00C9A5!important' } : {}}>
                {files?.length > 0 && componentProps['image'] === '' ? (
                  <Fragment>{files}</Fragment>
                ) : (
                  <Fragment>
                    <span key={componentProps['image']}>{componentProps['image']} Drop or browse to replace image</span>
                  </Fragment>
                )}
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <img src={'/rsvp/upload' + (isDropzoneActive ? '-hovered' : '') + '.svg'} alt="upload" height={'24px'} />
              <p className={'didacticP'} style={isDropzoneActive ? { color: '#00C9A5' } : {}}>
                Drag & drop your photo here, or{' '}
                <span className={styles.highlighted} style={isDropzoneActive ? { color: '#00C9A5' } : {}}>
                  browse
                </span>{' '}
                your files
              </p>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageDragNDrop;
