import './CreatePostModal.scss';

import { css } from '@emotion/react';
import { IonSpinner } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Imagehelper } from '../../../app/helper/Imagehelper';
import {
  selectAccountsCombinedWithConnectedAccountsForMentions,
  selectJwt,
} from '../../../app/redux/authorization.slice.reducer';
import { selectWedding, setTags, TagDetail } from '../../../app/redux/wedding.slice.recuder';
import { Backend } from '../../../config/backend';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import {
  ConnectedAccountRef,
  CreatePostRequest,
  File,
  Tag,
  Wedding,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import AvatarProfile from '../../shared/AvatarProfile/AvatarProfile';

type CreatePostModal = {
  fileId: string;
  profile: ConnectedAccountRef;
  userToken: string;
  wedding: Wedding;
  fileUrl: string;
  showModal: boolean;
  setShowModal: any;
  setFileId: any;
  percentage: any;
  loading: any;
};

const CreatePostModal = (props: CreatePostModal): JSX.Element => {
  const [posting, setPosting] = useState<boolean>(false);
  const [blobId, setBlobId] = useState<string>('');
  const [caption, setCaption] = useState<string>('');
  const [profilePic, setProfilePic] = useState('');
  const dispatch = useDispatch();
  const myInput = useRef<any>();

  // used as input data for the mention plugin
  const mentions = useSelector(selectAccountsCombinedWithConnectedAccountsForMentions);
  const jwt = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);

  // selected tags
  const [selectedTags, setSelectedTags] = useState<TagDetail[]>([]);
  // selected mentions
  const [selectedMentions, setSelectedMentions] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  useEffect(() => {
    if (props.fileId !== '') fetchPostImage(props.fileId);
    // TODO: get image from map
    Imagehelper.fetchImage(props.profile.imageProfileUrl, setProfilePic, props.userToken, 150);
  }, [props.fileId]);

  // get image
  const fetchPostImage = async (fileID: string) => {
    const newFile = await Imagehelper.getFileFromFilestoreId(fileID, props.userToken, Imagehelper.windowSize());
    if (typeof newFile === 'string') setBlobId(newFile);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function asyncTags(query: any, callback: any): any {
    if (!query) return [];

    const globalTags = await BrainHelper.listTags(jwt, wedding?.id, dispatch, setTags);

    const search = async () => {
      const suggestedTags: TagDetail[] = [];
      const suggestion = { id: uuid(), display: query };
      for (const tag of globalTags) {
        const regex = new RegExp(`.*${query}.*`);
        const found = tag.key.match(regex);
        if (found) suggestedTags.push({ id: tag?.id, display: tag.key });
      }
      if (suggestedTags.length > 0) {
        return suggestedTags.concat(suggestion);
      }

      return [suggestion];
    };
    search().then(callback);
  }

  const createPost = async () => {
    let newContent = caption;

    // TODO: uncomment the following when we will have a page per user
    // newContent = newContent.split('@@@__').join('<a href="/user/');
    // newContent = newContent.split('^^^__').join(`">@`);
    // newContent = newContent.split('@@@^^^').join('</a>');
    newContent = newContent.split('@@@__').join('<a href="#?caid=');
    newContent = newContent.split('^^^__').join(`"><span>@`);
    newContent = newContent.split('@@@^^^').join('</span></a>');

    // TODO: uncomment the following when we will have a page per tag
    // newContent = newContent.split('$$$__').join('<a href="/tag/');
    // newContent = newContent.split('~~~__').join(`">#`);
    // newContent = newContent.split('$$$~~~').join('</a>');
    newContent = newContent.split('$$$__').join('<a href="#?tagid=');
    newContent = newContent.split('~~~__').join(`">#`);
    newContent = newContent.split('$$$~~~').join('</a>');

    const body = newContent.trim();

    try {
      const createPostRequest = new CreatePostRequest();
      createPostRequest.weddingId = props.wedding?.id;
      createPostRequest.feedId = props.wedding?.feedId;
      createPostRequest.message = body;
      createPostRequest.mentionConnectedAccountIds = selectedMentions;
      // tags request
      const tagListRequest: Tag[] = [];
      for (const tagDetail of selectedTags) {
        const tagRequest = new Tag();
        tagRequest.id = tagDetail?.id;
        tagRequest.weddingId = props.wedding?.id;
        tagRequest.key = tagDetail.display;
        tagListRequest.push(tagRequest);
      }
      createPostRequest.tags = tagListRequest;
      const files = [];
      const f = new File();
      f.url = props.fileUrl;
      if (props.fileUrl.trim() === '' || props.fileUrl === null || props.fileUrl === undefined) return;
      if (props.fileId.trim() === '' || props.fileId === null || props.fileId === undefined) return;
      f.id = props.fileId;
      files.push(f);
      createPostRequest.files = files;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${props.userToken}`);
      await Backend.brainClientV2.createPost(createPostRequest, { headers });
      props.setShowModal(false);
      setBlobId('');
      props.setFileId('');
      setCaption('');
      setPosting(false);
    } catch (e) {
      console.error(e);
      setBlobId('');
      props.setFileId('');
      setCaption('');
      setPosting(false);
    }
  };

  return (
    <div className={props.showModal ? 'show-modal' : 'default-modal'}>
      <div className={'create-post'}>
        <div className="modalProfileContainer">
          <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <AvatarProfile
              src={profilePic}
              alt="ProfilePicture"
              classNames="create-post-profile"
              accountFirstName={props?.profile?.accountFirstName}
              accountLastName={props?.profile?.accountLastName}
            />
            <p style={{ paddingLeft: 8 }}>{`${props?.profile?.accountFirstName} ${props?.profile?.accountLastName}`}</p>
          </span>
          <button
            className={'cancel-btn'}
            onMouseDown={() => {
              props.setShowModal(false);
              setBlobId('');
              props.setFileId('');
              setCaption('');
            }}
          >
            Cancel
          </button>
        </div>
        <div className="modalCaption" style={{ marginLeft: 4 }}>
          <MentionsInput
            inputRef={myInput}
            className={'mentions'}
            value={caption}
            onChange={(event: any) => {
              setCaption(event.target.value);
            }}
            placeholder={'Say something...'}
            spellCheck={false}
          >
            <Mention
              trigger={'@'}
              data={mentions}
              markup={'@@@____id__^^^____display__@@@^^^'}
              style={{
                backgroundColor: '#daf4fa',
              }}
              onAdd={(display) => {
                setSelectedMentions((mentions: any[]) => [...mentions, display]);
              }}
              appendSpaceOnAdd={true}
            />
            <Mention
              trigger={'#'}
              data={asyncTags}
              markup={'$$$____id__~~~____display__$$$~~~'}
              style={{
                backgroundColor: '#daf4fa',
              }}
              onAdd={async (id, display) => {
                setSelectedTags(selectedTags.concat({ id: `${id}`, display: display }));
              }}
              appendSpaceOnAdd={true}
            />
          </MentionsInput>
        </div>
        <div className={'image-post-div'}>
          {props.loading || blobId === '' ? (
            <div
              style={{
                height: 250,
                background: '#E5E5E5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonSpinner name="lines" />
              {props.percentage <= 99.5 && <p>{props.percentage.toFixed(2) + '%'}</p>}
            </div>
          ) : (
            <img loading="lazy" className={'image-post-img'} src={blobId} alt="PostPicture" />
          )}
        </div>
        <div>
          {!blobId ? (
            <button className={'post-btn'} style={{ color: '#A9A4A7' }} disabled={true}>
              Post
              <img src={'assets/icons/icon-send-post.svg'} alt={'Send'} />
            </button>
          ) : (
            <button
              className={'post-btn'}
              onMouseDown={() => {
                setPosting(true);
                createPost();
              }}
              disabled={posting}
            >
              Post
              <img src={'assets/icons/icon-filled-send-post.svg'} alt={'Send'} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatePostModal;
