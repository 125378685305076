import React from 'react';

import './MobileBottomModal.scss';

const MobileBottomModal = (props: { title: string; callback: any; exitCallback: any }): JSX.Element => {
  return (
    <div className={'overlay'}>
      <div className={'MobileBottomModal'}>
        <img src={'assets/graphics/line.svg'} alt={''} width={32} style={{ alignSelf: 'center', paddingBottom: 8 }} />
        <h2>{props.title}</h2>
        <br />
        <button className={'yes'} onClick={() => props.callback()}>
          <img src={'assets/icons/icon-check-black.svg'} alt={'Yes'} style={{ paddingRight: 10 }} />
          Yeap!
        </button>
        <button className={'no'} onClick={() => props.exitCallback()}>
          <img src={'assets/icons/icon-exit-black.svg'} alt={'No'} style={{ paddingRight: 10 }} />
          Nah, I changed my mind.
        </button>
      </div>
    </div>
  );
};

export default MobileBottomModal;
