import React from 'react';
import Icon from '../../../../shared/Icon';
import { SVGIconName } from '../../../../shared/Icon/Icons';
import styles from './Card.module.scss';

interface CardProps {
  title: string;
  body: any;
  svgIcon: SVGIconName;
  active: boolean;
  fn?: any;
}
const Card = ({ title, body, svgIcon, active, fn }: CardProps) => {
  return (
    <div>
      <button
        className={styles.card}
        style={{ backgroundColor: active ? '#FBCCB1' : '#FDF6EB', borderColor: active ? '#F78C50' : '#DAD8D9' }}
        onClick={() => fn && fn()}
      >
        <Icon name={svgIcon} color={'#281B24'} size={'m'} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p className={'didacticP'}>{title}</p>
          {body}
        </div>
      </button>
    </div>
  );
};

export default Card;
