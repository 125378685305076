import React, { useEffect, useState } from 'react';
import Preview from '../../../component/dashboard/planning/preview/Preview';
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { selectWedding, setRsvpEventsReducer } from '../../../app/redux/wedding.slice.recuder';
import {
  selectAccessTokenAfterOTP,
  selectJwt,
  selectProfile,
  user,
} from '../../../app/redux/authorization.slice.reducer';
import { dateTimeFormat } from '../../../config/dateFormats';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { IonContent, IonHeader, IonImg, IonPage } from '@ionic/react';
import { useNavigate } from 'react-router-dom';
import {
  ConnectedAccountRef,
  GetInvitedVisitorByPhoneOrEmailResponse,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import jwt_decode from 'jwt-decode';
import { Avatar } from '../../../component/dashboard/table/Table';
import GenericConfirmModal from '../../../component/modals/GenericConfirmModal';

const Schedule = (): JSX.Element => {
  const wedding = useSelector(selectWedding);
  const jwt = useSelector(selectJwt);
  const profile = useSelector(selectProfile);
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [invited, setInvited] = useState<GetInvitedVisitorByPhoneOrEmailResponse | undefined>(undefined);
  const [selectedDay, setSelectedDay] = useState<DayValue>(undefined);
  const [blobId, setBlobId] = useState<string>('');
  const [fileId, setFileId] = useState<string | undefined>('');
  const [countryShort, setCountryShort] = useState<string>(wedding?.mainEvent?.location?.externalQuickCode || '');
  const defaultTimeStamp = '2020-01-01 12:00';
  const [startDate, setStartDate] = React.useState<Date | null>(
    new Date(
      dateTimeFormat({
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(wedding?.mainEvent?.startTimestamp || defaultTimeStamp)),
    ),
  );
  const [endDate, setEndDate] = React.useState<Date | null>(
    new Date(
      dateTimeFormat({
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(wedding?.mainEvent?.endTimestamp || defaultTimeStamp)),
    ),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getRsvpEvents = async () => {
    const rsvpEvents = await BrainHelper.listRSVPEvents(userToken, wedding.id);
    dispatch(setRsvpEventsReducer(rsvpEvents?.rsvpEvents));
  };

  const getInvitedVisitor = async () => {
    const jwtDecode: user = jwt_decode(userToken);
    const invitedVisitor = await BrainHelper.getInvitedVisitorByPhoneOrEmail(
      userToken,
      wedding.id,
      jwtDecode?.phoneNumber,
      jwtDecode?.email ? jwtDecode.email : '',
    );
    setInvited(invitedVisitor);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const query = new URLSearchParams(location.search);
    if (wedding?.mainEvent?.startTimestamp !== '' && wedding?.mainEvent?.startTimestamp !== undefined) {
      const startDate = new Date(wedding.mainEvent.startTimestamp);
      setSelectedDay({ year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() });
      setStartDate(startDate);
      console.log(startDate);
    }
    if (wedding?.mainEvent?.endTimestamp !== '' && wedding?.mainEvent?.endTimestamp !== undefined) {
      const endDate = new Date(wedding.mainEvent.endTimestamp);
      setEndDate(endDate);
      console.log(endDate);
    }
    if (wedding?.mainEvent?.icon) setFileId(wedding.mainEvent.icon);
    if (wedding?.mainEvent?.location?.externalQuickCode)
      setCountryShort(wedding?.mainEvent?.location?.externalQuickCode);
    getInvitedVisitor();
    getRsvpEvents();
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    //TODO: MUST BE FIXED
    if (fileId !== '' && fileId !== undefined) fetchPostImage(fileId);
  }, [fileId]);

  // get image
  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, jwt, 1000);
    if (typeof result === 'string') setBlobId(result);
  };

  return (
    <IonPage>
      <IonHeader className="header">
        <img loading="lazy" className="headerLogo" src="/assets/logos/whiteclover-logo1.svg" alt="logo" />
        <button onClick={() => navigate('/profile')}>
          <Avatar
            query={''}
            name={`${profile.accountFirstName} ${profile.accountLastName}`}
            userToken={userToken}
            connectedAccountDetails={
              new ConnectedAccountRef({
                accountFirstName: profile.accountFirstName,
                accountLastName: profile.accountLastName,
                imageProfileUrl: profile.imageProfileUrl,
              })
            }
          />
        </button>
      </IonHeader>
      <IonContent>
        {startDate && endDate && (
          <Preview
            selectedDay={selectedDay}
            blobId={blobId}
            weddingName={wedding.name}
            startDate={startDate.toString()}
            endDate={endDate.toString()}
            countryShort={countryShort}
            weddingLocation={wedding?.mainEvent?.location}
            description={wedding?.mainEvent?.description}
            isExperienceApp={true}
            visitor={invited?.allowedVisitors[0]}
            loading={false}
            percentage={0}
          />
        )}
      </IonContent>
      <div className="footerSectionMainPlain">
        <button
          className={'website'}
          style={!wedding.emailInvitationWebsite ? { opacity: '.5' } : { cursor: 'pointer' }}
          onClick={(e) => {
            if (e.button === 2) return;
            if (wedding.emailInvitationWebsite) setIsModalOpen(true);
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-browser.svg" alt={'website'} />
          <p>Website</p>
        </button>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/schedule');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-filled-calendar.svg" alt={'schedule'} />
          <p>Schedule</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/main');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-apps.svg" alt={'main'} />
          <p>Main</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/rsvp');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-envelope-marker.svg" alt={'rsvp'} />
          <p>RSVP</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/home');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-camera.svg" alt={'photo app'} />
          <p>Photo App</p>
        </div>
      </div>
      {isModalOpen && (
        <GenericConfirmModal
          setShow={setIsModalOpen}
          title={'Confirm Action'}
          text={"You are about to leave this app and open a new tab to continue on the couple's website."}
          confirmBtnFunction={() => {
            setIsModalOpen(false);
            window.open(
              (window.location.href.includes('uk')
                ? process.env.REACT_APP_NEXTJS_URL_UK
                : process.env.REACT_APP_NEXTJS_URL_GR) +
                'm/' +
                wedding.path,
              '_blank', // <- This is what makes it open in a new window.
            );
          }}
          confirmBtnText={'Continue'}
          confirmBtnIcon={''}
          // html={t}
        />
      )}
    </IonPage>
  );
};

export default Schedule;
