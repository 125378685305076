import React, { Fragment, useEffect, useState } from 'react';
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import CustomDatePicker from '../../../datePicker/CustomDatePicker';
import styles from './Questions.module.scss';
import { AllowedVisitor, Location, RSVPEventAttendance } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { useSelector } from 'react-redux';
import { selectJwt } from '../../../../app/redux/authorization.slice.reducer';
import { selectWedding } from '../../../../app/redux/wedding.slice.recuder';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { logger } from '../../../../config/Logger';
import { MenuItem, Select } from '@mui/material';

const DefaultQuestions = (props: {
  event: any;
  questionsOnly?: boolean;
  disabled?: boolean;
  question?: 0 | 1 | 2;
  setQuestion?: any;
  plus1?: number;
  setPlus1?: any;
  invited?: AllowedVisitor;
  attendance?: RSVPEventAttendance;
  setAttendance?: any;
  hideOptions?: boolean;
  isDropdown?: boolean;
}): JSX.Element => {
  const userToken = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disableCalendar, setDisableCalendar] = useState(false);
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [selectedDay, setSelectedDay] = useState<DayValue>(() => {
    if (props.event.kindlyReminder !== '' && props.event.kindlyReminder !== undefined) {
      const kindlyReminder = new Date(props.event.kindlyReminder);
      return {
        year: kindlyReminder.getFullYear(),
        month: kindlyReminder.getMonth() + 1,
        day: kindlyReminder.getDate(),
      };
    } else return undefined;
  });
  const [rsvpAttendance, setRsvpAttendance] = useState<RSVPEventAttendance | undefined>(props.attendance);
  const [plus1, setPlus1] = useState<number | undefined>(props?.plus1);
  const [question, setQuestion] = useState<number | undefined>(props?.question);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    setPlus1(props.plus1);
  }, [props.plus1]);

  useEffect(() => {
    setQuestion(props.question);
  }, [props.question]);

  useEffect(() => {
    setRsvpAttendance(props.attendance);
  }, [props.attendance]);

  useEffect(() => {
    if (!props.questionsOnly && selectedDay) {
      console.log(1);
      const startHours = '23:59:59';
      const kindlyReminder = dayjs.tz(
        selectedDay?.year + '-' + selectedDay?.month + '-' + selectedDay?.day + ' ' + startHours,
        props.event.location.externalQuickCode || '',
      );
      new Date(props.event.kindlyReminder).getTime() !== new Date(kindlyReminder.toJSON()).getTime() &&
        BrainHelper.updateRsvpEvent(
          userToken,
          props.event.id,
          wedding.id,
          props.event.name,
          props.event.startTimestamp,
          props.event.endTimestamp,
          props.event.location as Location,
          props.event.isDefault,
          props.event.isPrivate,
          props.event.icon,
          props.event.description,
          kindlyReminder.toJSON(),
        ).catch((e) => logger.error({ message: e, functionName: 'DefaultQuestions.updateRsvpEvent' }));
    }
  }, [selectedDay]);

  return (
    <div className={styles.questions}>
      {!props.questionsOnly ? (
        <Fragment>
          <h3 className={'didacticH3'} style={{ maxWidth: '80%', wordWrap: 'break-word' }}>
            {props.event.name}
          </h3>
          <h4 className={'didacticH4'}>Kindly, reply until*</h4>
          <CustomDatePicker
            setCalendarOpened={setCalendarOpened}
            disableCalendar={disableCalendar}
            selectedDay={selectedDay}
            calendarOpened={calendarOpened}
            setSelectedDay={setSelectedDay}
            name={''}
          />
        </Fragment>
      ) : (
        ''
      )}
      <div className={styles.questionWrapper}>
        <h4 className={'didacticH4'}>Will you be joining the {props.event.name}?</h4>
        <Fragment>
          <div className={styles.checkbox}>
            <input
              id={'attend'}
              className={'checkbox'}
              type="checkbox"
              value={1}
              name={'attendance'}
              disabled={props.disabled}
              checked={question == 1}
              onChange={(e) => {
                if (e.target.checked && props.setQuestion !== undefined) {
                  props.setQuestion(1);
                  setQuestion(1);
                  let temp = props.invited?.attendToRsvpEvents.find((a) => a.rsvpEvent?.id === props.event.id);
                  if (!temp) {
                    temp = new RSVPEventAttendance();
                    temp.rsvpEvent = props.event;
                    temp.allowedVisitor = props.invited;
                  }
                  temp.attend = true;
                  setRsvpAttendance(temp);
                  props.setAttendance(temp);
                }
              }}
            />
            <label htmlFor={'attend'}>{"Couldn't miss it!"}</label>
          </div>
          <div className={styles.checkbox}>
            <input
              id={'miss'}
              className={'checkbox'}
              type="checkbox"
              value={2}
              name={'attendance'}
              disabled={props.disabled}
              checked={question == 2}
              onChange={(e) => {
                if (e.target.checked && props.setQuestion !== undefined) {
                  props.setQuestion(2);
                  setQuestion(2);
                  let temp = props.invited?.attendToRsvpEvents.find((a) => a.rsvpEvent?.id === props.event.id);
                  if (!temp) {
                    temp = new RSVPEventAttendance();
                    temp.rsvpEvent = props.event;
                    temp.allowedVisitor = props.invited;
                  }
                  temp.attend = false;
                  setRsvpAttendance(temp);
                  props.setAttendance(temp);
                }
              }}
            />
            <label htmlFor={'miss'}>{"Sadly, I won't be able to."}</label>
          </div>
        </Fragment>
      </div>
      {!props.hideOptions && (
        <div className={styles.questionWrapper}>
          <h4 className={'didacticH4'}>How many plus ones?</h4>
          <p className={'didacticP'}>(Max allowed {Number(props?.invited?.maxAllowedPlus1 || 0)})</p>
          {props.isDropdown ? (
            <Select
              style={{
                width: 332,
                borderRadius: 8,
                background:
                  props.disabled || !(rsvpAttendance?.attend && props.invited && props.invited.maxAllowedPlus1 > 0)
                    ? 'none'
                    : 'white',
              }}
              disabled={
                props.disabled || !(rsvpAttendance?.attend && props.invited && props.invited.maxAllowedPlus1 > 0)
              }
              value={plus1 || 0}
              onChange={(e) => {
                const plusone =
                  Number(e.target.value) > Number(props?.invited?.maxAllowedPlus1.toString() || 0)
                    ? Number(props?.invited?.maxAllowedPlus1.toString() || 0)
                    : e.target.value;
                props.setPlus1(plusone);
                setPlus1(Number(plusone));
              }}
            >
              {[...new Array(Number(props?.invited?.maxAllowedPlus1.toString() || 0) + 1)].map((_, index) => {
                return (
                  <MenuItem key={props.event.id + index} value={index}>
                    {index}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <input
              className={'input'}
              type="number"
              min={0}
              max={Number(props?.invited?.maxAllowedPlus1.toString() || 0)}
              step={1}
              disabled={
                props.disabled || !(rsvpAttendance?.attend && props.invited && props.invited.maxAllowedPlus1 > 0)
              }
              value={plus1 || 0}
              onChange={(e) => {
                const plusone =
                  Number(e.target.value) > Number(props?.invited?.maxAllowedPlus1.toString() || 0)
                    ? Number(props?.invited?.maxAllowedPlus1.toString() || 0)
                    : e.target.value;
                props.setPlus1(plusone);
                setPlus1(Number(plusone));
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DefaultQuestions;
