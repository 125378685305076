import React, { useEffect, useRef, useState } from 'react';
import styles from './LocationInput.module.scss';
import { Location, Point } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { FirebaseContext } from '../../../../context/firebase.context';
import { getCountry } from 'countries-and-timezones';
import { formatLocation } from '../../../../shared/Functions/location';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GoogleMapReact from 'google-map-react';
import Icon from '../../../../shared/Icon';
import { logger } from '../../../../config/Logger';
import { ScreenType, useTypeOfScreen } from '../../../../hooks';

const LocationInputComponent = (props: {
  label: string;
  location: Location | undefined;
  setLocation: any;
  setCountryShort: any;
  required: boolean;
  setError?: any;
}): JSX.Element => {
  const [value, setValue] = useState(props.location && formatLocation(props.location));
  const autoCompleteRef = useRef();
  const inputRef = useRef(null);
  const options = {
    fields: ['address_components', 'formatted_address', 'geometry', 'name'],
  };
  useEffect(() => {
    FirebaseContext.getAuth();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.google) autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    autoCompleteRef.current.addListener('place_changed', async function () {
      handlePlaceSelect(autoCompleteRef.current);
    });
  }, []);

  async function handlePlaceSelect(autoComplete: any) {
    const addressObject = autoComplete.getPlace();

    const countryCode = addressObject.address_components.find((c: any) => {
      return c.types[0] === 'country';
    }).short_name;
    const timeZ = getCountry(countryCode)?.timezones[0];
    props.setCountryShort(timeZ);
    const point = new Point();
    point.longitude = addressObject.geometry.location.lng();
    point.latitude = addressObject.geometry.location.lat();
    const query = new Location();
    query.address = addressObject.formatted_address;
    query.externalQuickCode = timeZ;
    query.point = point;
    query.name = addressObject.name;
    setValue(formatLocation(query));
    props.setLocation(query);
    if (props.setError) props.setError(false);
  }

  const onMapDragEnd = async (lat: number, lng: number) => {
    try {
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        const countryCode = data.results[0].address_components.find((c: any) => {
          return c.types[0] === 'country';
        }).short_name;
        const timeZ = getCountry(countryCode)?.timezones[0];
        props.setCountryShort(timeZ);
        const point = new Point();
        point.longitude = data.results[0].geometry.location.lng;
        point.latitude = data.results[0].geometry.location.lat;
        const query = new Location();
        query.address = data.results[0].formatted_address;
        query.externalQuickCode = timeZ;
        query.point = point;
        query.name = data.results[0].name;
        setValue(formatLocation(query));
        props.setLocation(query);
      } else {
        logger.error({ message: 'Reverse Geocoding failed.', functionName: 'LocationInputComponent.onMapDragEnd' });
      }
    } catch (error) {
      logger.error({ message: 'Error fetching data:' + error, functionName: 'LocationInputComponent.onMapDragEnd' });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const PinComponent = ({ text }: any) => (
    <div className={styles.marker} style={{ borderRadius: '100%!important' }}>
      <Icon name={'location_filled'} color={'#98243A'} size={'m'} />
    </div>
  );
  const typeOfScreen = useTypeOfScreen();
  return (
    <div>
      <div className={styles.input}>
        <p className={'didacticH4'}>{props.label}</p>
        <input
          className={'input'}
          style={{
            width: typeOfScreen === ScreenType.mobile ? 'calc(100vw - 16px)' : '',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: '48px',
          }}
          type="text"
          ref={inputRef}
          placeholder="Enter Location"
          value={value && value.trim() !== '' ? value : ''}
          onChange={(e) => {
            if (props.setError) props.setError(true);
            setValue(e.target.value);
          }}
        />
        <img
          src="/rsvp/location-dark.svg"
          alt="location"
          style={{ position: 'absolute', right: '16px', bottom: '20px' }}
        />
      </div>
      {props.location && props.location.point && (
        <div className={styles.map}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
            defaultCenter={{
              lat: props.location.point.latitude,
              lng: props.location.point.longitude,
            }}
            center={{
              lat: props.location.point.latitude,
              lng: props.location.point.longitude,
            }}
            defaultZoom={11}
            onDragEnd={(test: any) => {
              onMapDragEnd(test.center.lat(), test.center.lng());
              // console.log(test.getPlace());
            }}
            draggable={false}
          >
            <PinComponent lat={props.location.point.latitude} lng={props.location.point.longitude} />
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

export default LocationInputComponent;
