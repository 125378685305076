import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './GuestHeader.module.scss';
import Icon from '../../../../shared/Icon';
import { ScreenType, useTypeOfScreen } from '../../../../hooks';
import DropdownButton from '../../table/DropdownButton';
import {
  InvitationOptionInvite,
  InvitationOptionReminder,
  InvitationOptionSaveTheDate,
} from '../../../../constants/invitation.constants';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { Wedding } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

interface HeaderProps {
  setSearch: any;
  setIsActiveSendModal: any;
  setSendOption: any;
  wedding: Wedding;
  setShowGuestModal: any;
  setSelectedGuest: any;
}

const GuestHeader = ({
  setSearch,
  setIsActiveSendModal,
  setSendOption,
  wedding,
  setShowGuestModal,
  setSelectedGuest,
}: HeaderProps) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSentActive, setIsSentActive] = useState(false);
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  const [showInviteGuestsDropdown, setShowInviteGuestsDropdown] = useState<boolean>(false);
  const [isAddGuestsDropdown, setIsAddGuestsDropdown] = useState(false);
  const [isAddUserActive, setIsAddUserActive] = useState(false);
  const [maxAllowed, setMaxAllowed] = useState<number>(-1);
  const [dashboardURL, setDashboardURL] = useState('https://app.whiteclover.uk/dashboard');
  const [hoveredIconIndex, setHoveredIconIndex] = useState<number | null>(null);

  const sendDropdownRef = useRef(null);
  const addGuestDropdownRef = useRef(null);
  const showInviteGuestsDropdownRef = useRef(null);

  const typeOfScreen = useTypeOfScreen();

  useOutsideClick(sendDropdownRef, () => setIsActiveDropdown(false));
  useOutsideClick(addGuestDropdownRef, () => setIsAddGuestsDropdown(false));
  useOutsideClick(showInviteGuestsDropdownRef, () => setShowInviteGuestsDropdown(false));

  useEffect(() => {
    setDashboardURL(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard'
        : `https://${window.location.host}/dashboard`,
    );
  }, []);

  return (
    <div id={'guestHeader'} className={styles.guestsPage + ' ' + styles[`guestsPage${typeOfScreen}`]}>
      <div
        className={`${styles.searchBar}  ${isSearchActive && styles.searchBarActive}`}
        style={
          typeOfScreen === ScreenType.mobile || typeOfScreen === ScreenType.tablet
            ? { width: '100%' }
            : { marginRight: '10px' }
        }
      >
        <Icon name={'search'} color={'#281B24'} size={'s'} />
        <input
          placeholder="Search"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          style={
            typeOfScreen === ScreenType.mobile || typeOfScreen === ScreenType.tablet
              ? { width: '100%' }
              : { marginRight: '10px' }
          }
          onFocus={() => setIsSearchActive(true)}
          onBlur={() => setIsSearchActive(false)}
        />
      </div>
      <div id={'actionButtons'} className={styles.headBtns}>
        <div>
          <button
            id={'inviteGuestButton'}
            className={
              styles.maxContentBtn +
              ' btn btnMain4th btnMain4thWithImg' +
              (isAddGuestsDropdown ? ' btnMain4thActive' : '')
            }
            onMouseEnter={() => setIsAddUserActive(true)}
            onMouseLeave={() => setIsAddUserActive(false)}
            onClick={() => setIsAddGuestsDropdown((prevState) => !prevState)}
          >
            <Icon
              name={'add_user'}
              color={isAddUserActive || isAddGuestsDropdown ? '#FDF6EB' : '#63435B'}
              size={'sPlus'}
              filled={true}
            />
            Add to Guest List
          </button>
          {isAddGuestsDropdown && (
            <div
              id={'sendDropdownModal'}
              className={
                styles.sendDropdown +
                ' ' +
                (isAddGuestsDropdown
                  ? styles.sendDropdownActive + ' ' + styles[`sendDropdownActive${typeOfScreen}`]
                  : '')
              }
              ref={addGuestDropdownRef}
            >
              <DropdownButton
                title={'Add manually'}
                _callback={() => {
                  setIsAddGuestsDropdown(false);
                  setShowGuestModal(true);
                  setSelectedGuest(undefined);
                }}
                iconName="write_user"
              />
              <DropdownButton
                title={'Share link'}
                _callback={() => {
                  setIsAddGuestsDropdown(false);
                  setShowInviteGuestsDropdown(true);
                }}
                iconName="share"
              />
            </div>
          )}
          {showInviteGuestsDropdown && (
            <div
              id={'sendDropdownModal'}
              className={
                styles.dropdown +
                ' ' +
                (showInviteGuestsDropdown ? styles.dropdownActive + ' ' + styles[`dropdownActive${typeOfScreen}`] : '')
              }
              ref={showInviteGuestsDropdownRef}
            >
              <Fragment>
                <p className={'didacticP'}>Share Link. </p>
                <p className={'didacticP'}>
                  Copy the link below and send it to the people you want to add to your wedding.{' '}
                </p>{' '}
                <p>
                  Please keep in mind that this is not an invitation to the wedding festivities. This will assist you in
                  adding your guests to the platform. Then you can select the events to which you want to invite them.
                </p>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '12px' }}>
                  <p>Max Plus Ones:</p>
                  <select
                    style={{
                      background: 'transparent',
                      border: '1px solid white',
                      borderRadius: '50px',
                      width: '40px',
                      textAlign: 'center',
                      color: 'black',
                    }}
                    name="select"
                    onChange={(e) => setMaxAllowed(Number(e.target.value))}
                  >
                    {[-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(function (n: any) {
                      return (
                        <option
                          style={{ color: 'red', background: '#63435B' }}
                          key={n}
                          value={n}
                          selected={maxAllowed === n}
                        >
                          {n === -1 && '♾️'}
                          {n !== -1 && n}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <CopyToClipboard
                  text={
                    `${wedding.name} are excited to invite you to their wedding.\nPlease add your details if you are interested to join and Save the Date of the ceremony.\nMore details will follow in the coming weeks.\nPlease stay tuned and check your email for updates.\n` +
                    dashboardURL.replace('/dashboard', '') +
                    '/invitation/' +
                    wedding.id +
                    '/' +
                    maxAllowed
                  }
                  onCopy={() => {
                    toast(`Copied on clipboard successfully!`, {
                      style: { backgroundColor: '#A3F5E6', color: '#006150 !important' },
                    });
                  }}
                >
                  <button>
                    <Icon name={'link'} color={'#281B24'} size={'m'} />
                    <h4 className={'didacticH4'} style={{ color: 'black' }}>
                      {dashboardURL.replace('/dashboard', '') + '/invitation/' + wedding.id + '/' + maxAllowed}
                    </h4>
                    <Icon name={'copy'} color={'#63435B'} size={'sPlus'} />
                  </button>
                </CopyToClipboard>
              </Fragment>
            </div>
          )}
        </div>
        <div>
          <button
            id={'sendGuestButton'}
            className={'btn btnMain btnMainWithImg ' + (isActiveDropdown && ' btnMain4thActive')}
            onMouseEnter={() => setIsSentActive(true)}
            onMouseLeave={() => setIsSentActive(false)}
            onClick={() => setIsActiveDropdown((prevState) => !prevState)}
          >
            <Icon name={'send_filled'} color={isSentActive || isActiveDropdown ? '#FDF6EB' : '#63435B'} size={'s'} />
            Send
            <Icon
              name={isActiveDropdown ? 'arrow_down' : 'arrow_up'}
              color={isSentActive || isActiveDropdown ? '#FDF6EB' : '#63435B'}
              size={'s'}
            />
          </button>
          {isActiveDropdown && (
            <div
              id={'sendDropdownModal'}
              className={
                styles.sendDropdown +
                ' ' +
                (isActiveDropdown ? styles.sendDropdownActive + ' ' + styles[`sendDropdownActive${typeOfScreen}`] : '')
              }
              style={{ background: 'white', zIndex: 2000, marginLeft: -140 }}
              ref={sendDropdownRef}
            >
              <div>
                {[
                  {
                    title: 'Save the Date',
                    disabled: !wedding.mainEvent,
                    callback: () => {
                      setIsActiveDropdown(false);
                      setIsActiveSendModal(true);
                      setSendOption(InvitationOptionSaveTheDate);
                    },
                    iconName: 'save_the_date',
                    link: `/dashboard/w/${wedding.path}/planning`,
                    condition: !wedding.mainEvent,
                    tooltip_text: 'Click here to set up Planning',
                  },
                  {
                    title: 'Invitation & RSVP',
                    disabled: !wedding.mainEvent || !wedding.emailInvitationWebsite,
                    callback: () => {
                      setIsActiveDropdown(false);
                      setIsActiveSendModal(true);
                      setSendOption(InvitationOptionInvite);
                    },
                    iconName: 'envelope_marker',
                    link: `/dashboard/w/${wedding.path}/rsvp`,
                    condition: !wedding.mainEvent || !wedding.emailInvitationWebsite,
                    tooltip_text: 'Click here to set up Invitation & RSVP',
                  },
                  {
                    title: 'Reminder',
                    disabled: !wedding.mainEvent || !wedding.emailInvitationWebsite,
                    callback: () => {
                      setIsActiveDropdown(false);
                      setIsActiveSendModal(true);
                      setSendOption(InvitationOptionReminder);
                    },
                    iconName: 'reminder',
                    link: `/dashboard/w/${wedding.path}/rsvp`,
                    condition: !wedding.mainEvent || !wedding.emailInvitationWebsite,
                    tooltip_text: 'Click here to set up Invitation & RSVP',
                  },
                ].map((item, index) => (
                  <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                    <DropdownButton
                      title={item.title}
                      disabled={item.disabled}
                      _callback={item.callback}
                      iconName={item.iconName}
                    />
                    {item.condition && (
                      <a
                        href={item.link}
                        style={{ color: 'white' }}
                        onMouseEnter={() => setHoveredIconIndex(index)}
                        onMouseLeave={() => setHoveredIconIndex(null)}
                      >
                        <Icon name={'angle_square_right'} size={'s'} />
                        <span
                          className={styles.tooltip}
                          style={{
                            display: hoveredIconIndex === index ? 'inline' : 'none',
                          }}
                        >
                          {item.tooltip_text}
                        </span>
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestHeader;
