import React, { useState } from 'react';
import styles from './InputWithLabel.module.scss';

type InputWithLabelProps = {
  label: string;
  value?: string;
  setValue?: any;
  hasError?: boolean;
  setHasError?: any;
};

const InputWithLabel = ({ label, value, setValue, hasError, setHasError }: InputWithLabelProps) => {
  const [isFocused, setFocused] = useState(false);

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  return (
    <div
      className={
        styles.floatingInputContainer +
        (hasError ? ' ' + styles.floatingInputContainerError : ' ') +
        (isFocused || value !== '' ? ' ' + styles.floatingInputContainerFocused : '')
        // (!nameEditing ? styles.floatingInputContainerDisabled : '')
      }
    >
      <input
        className={styles.fieldInput}
        id={'id' + label}
        name={'name' + label}
        type="text"
        placeholder=" "
        onFocus={handleFocus}
        onBlur={handleBlur}
        // disabled={!nameEditing}
        onChange={(event) => {
          setValue(event.target.value);
          setHasError('');
        }}
        value={value}
      />
      <label
        htmlFor={'name' + label}
        className={
          styles.floatingInputPlaceholder +
          ' didacticSpan' +
          (isFocused || value !== '' ? ' ' + styles.floatingInputPlaceholderFocused : '')
        }
      >
        {label}
      </label>
      {/*{!nameEditing && (*/}
      {/*  <div className={styles.floatingInputEdit}>*/}
      {/*    <button className={styles.bordered} onClick={() => setNameEditing(true)}>*/}
      {/*      <Icon name={'edit'} size={'s'} color={'#281B24'} />*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default InputWithLabel;
