import styles from './SocialEventCard.module.scss';
import { motion } from 'framer-motion';
import Image from '../../../shared/Image/Image';

export const SocialEventCard = (props: {
  onClick: () => void;
  image: string;
  title: string;
  subtitle?: string;
  price?: string;
  description?: string;
  new?: boolean;
  beta?: boolean;
  moreDetails?: boolean;
}): JSX.Element => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      className={styles.weddingCard}
      onClick={props.onClick}
      role="presentation"
    >
      {props.new && (
        <div className={styles.ribbon + ' ' + styles.ribbonTopRight}>
          <span style={{ background: '#63435B' }}>New</span>
        </div>
      )}
      {props.beta && (
        <div className={styles.ribbon + ' ' + styles.ribbonTopRight}>
          <span style={{ background: '#F9AC80' }}>Beta</span>
        </div>
      )}
      {!props.moreDetails ? (
        <Image
          src={props.image}
          alt={props.title}
          fixedSize
          classNames={styles.weddingImage}
          onErrorComponent={<img src={'assets/graphics/chs_wedding-placeholder-img.png'} alt="Couple" />}
        />
      ) : (
        <Image
          src={props.image}
          alt={props.title}
          fixedSize
          onErrorComponent={<img src={'assets/graphics/chs_wedding-placeholder-img.png'} alt="Couple" />}
          style={{ minWidth: '100px', paddingRight: 32, objectFit: 'contain' }}
        />
      )}
      <div>
        <h4 className={'fridayH4'} style={{ textAlign: 'center', marginTop: '16px', height: 50 }}>
          {props.title}
          <s className={'fridayH4'} style={{ textAlign: 'center', marginTop: '16px' }}>
            {props.subtitle}
          </s>
          {props.price}
        </h4>
        {props.description && <p style={{ textAlign: 'center' }}>{props.description}</p>}
        {props.moreDetails && (
          <span style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 16 }}>
            <a
              style={{ textAlign: 'center', textDecoration: 'underline' }}
              href="https://whiteclover.io/pricing"
              target="_blank"
              rel="noreferrer"
            >
              More Details
            </a>
          </span>
        )}
      </div>
    </motion.div>
  );
};
