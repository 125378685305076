import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './RSVP.module.scss';
import stylesEx from '../../../experienceApp/screens/rsvp/Rsvp.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  addInvitedVisitors,
  selectInvitedVisitors,
  selectQuestions,
  selectRsvpEvents,
  selectWedding,
  setRsvpEventsReducer,
  setWedding,
} from '../../../app/redux/wedding.slice.recuder';
import { dateTimeFormat } from '../../../config/dateFormats';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import {
  selectAccessTokenAfterOTP,
  selectJwt,
  selectProfile,
  selectUser,
} from '../../../app/redux/authorization.slice.reducer';
import DefaultQuestions from './questionComponts/DefaultQuestions';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import Questions from './questionComponts/Questions';
import {
  AllowedVisitor,
  Answer,
  ConnectedAccountRef,
  CreateOrUpdateAttendanceForAllowedVisitorRequest,
  InvitationStatus,
  ListQuestionsResponse,
  ListRSVPEventsResponse,
  RSVPEvent,
  RSVPResponded,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import ExtraQuestion from './questionComponts/Question';
import Icon from '../../../shared/Icon';
import { IonImg } from '@ionic/react';
import { Avatar } from '../table/Table';
import { EventQuestions } from '../../../experienceApp/screens/rsvp/EventQuestions';
import { mainBackgroundAccent, secondaryStrongAccent } from '../../../styles/colors';
import FlatButton from '../table/FlatButton';
import { toast } from 'react-toastify';
import { formatLocation } from '../../../shared/Functions/location';
import GenericConfirmModal from '../../modals/GenericConfirmModal';
import { ScreenType, useTypeOfScreen } from '../../../hooks';
import GetUI, { EventType } from '../../../utils/uiContent';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useSearchParams } from 'react-router-dom';

const Rsvp = (): JSX.Element => {
  const typeOfScreen = useTypeOfScreen();
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const wedding = useSelector(selectWedding);
  const events = useSelector(selectRsvpEvents);
  const questions = useSelector(selectQuestions);
  const profile = useSelector(selectProfile);
  const [eventsState, setEventsState] = useState<RSVPEvent[]>([]);
  const [blobId, setBlobId] = useState<string>('');
  const [daysToWedding, setDaysToWedding] = useState(0);
  const [mainDate, setMainDate] = useState(new Date());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endDate, setEndDate] = useState(new Date());
  const [isHoverBack, setIsHoverBack] = useState(false);
  const [isHoverSave, setIsHoverSave] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [description, setDescription] = useState<string>(
    wedding.emailInvitationWebsite?.description ? wedding.emailInvitationWebsite?.description : '',
  );
  const [isStep, setIsStep] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const viewRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [divWidth, setDivWidth] = useState<number>(imageRef?.current?.width || 0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const stepString = ['Email Invitation', 'Create RSVP'];

  const dispatch = useDispatch();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    if (wedding?.mainEvent?.startTimestamp) setMainDate(new Date(wedding?.mainEvent?.startTimestamp));
    if (wedding?.mainEvent?.endTimestamp) setMainDate(new Date(wedding?.mainEvent?.endTimestamp));
    if (wedding?.mainEvent?.icon) {
      fetchPostImage(wedding.mainEvent.icon);
    }
    getRsvpEvents();
    if (wedding?.mainEvent?.startTimestamp)
      setDaysToWedding(
        Math.ceil(
          (new Date(wedding?.mainEvent.startTimestamp).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
        ),
      );

    document.body.style.overflow = 'hidden';

    document.addEventListener('resize', () => {
      if (imageRef.current) setDivWidth(imageRef.current.width - 1);
    });

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    getRsvpEvents();
  }, [questions]);

  useEffect(() => {
    const tempEventsArray: RSVPEvent[] = JSON.parse(
      JSON.stringify(events, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
    );
    const sorted = tempEventsArray.sort(
      (a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime(),
    );
    setEventsState(sorted);
  }, [events]);

  // get image
  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlobId(result);
  };

  const getRsvpEvents = async () => {
    const rsvpEvents = await BrainHelper.listRSVPEvents(userToken, wedding.id);
    const tempEvents = new ListRSVPEventsResponse();
    if (rsvpEvents && rsvpEvents && rsvpEvents.rsvpEvents)
      for (const rsvpEvent of rsvpEvents.rsvpEvents) {
        const questions = await getQuestions(rsvpEvent);
        if (questions?.questions) rsvpEvent.questions = questions?.questions;
        tempEvents.rsvpEvents.push(rsvpEvent);
      }
    dispatch(setRsvpEventsReducer(tempEvents.rsvpEvents));
  };

  const getQuestions = async (event: any) => {
    const questionsList = await BrainHelper.listQuestions(userToken, event.id);
    return questionsList;
  };

  const stepDiv = [
    <div key={0}>
      <div
        style={
          typeOfScreen === ScreenType.desktop
            ? {
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                marginBottom: 16,
              }
            : {
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                marginBottom: 16,
              }
        }
      >
        <Icon name={'save_the_date'} size={'s'} />

        <h3 className={'didacticH3'}>Save the date</h3>
      </div>
      <p className={'didacticP'} style={{ maxWidth: '472px' }}>
        Save the date is the first email reminder you send to your guests. It automatically takes the required
        information after you successfully added your{' '}
        {GetUI(wedding.socialEventType as EventType).startPlanningEventType} details.
      </p>
    </div>,
    <Fragment key={1}>
      <div className={styles.flexRow} style={{ alignItems: 'center' }}>
        <Icon name={'envelope'} size={'s'} />
        <h3 className={'didacticH3'}>Email Invitation</h3>
      </div>
      <p className={'didacticP'} style={{ maxWidth: '448px', marginBottom: '24px' }}>
        This is the official email invitation you’ll send to your guests. From it your guests can access the RSVP, which
        will be configured after this step.
      </p>
      <div className={styles.flexRow}>
        <Icon name={'info'} size={'s'} />
        <p className={'didacticP'} style={{ maxWidth: '448px', marginBottom: '48px' }}>
          All the information is collected automatically from the{' '}
          {GetUI(wedding.socialEventType as EventType).startPlanningEventType} details, and the image from your main
          image added on the website builder.
        </p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h4 className={'fridayH4'}>Add a custom text*</h4>
        <textarea
          className={'textarea'}
          rows={4}
          cols={10}
          placeholder={'A small paragraph...'}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <span className={'didacticSpan'}>Note: You cannot edit the text after you send your first invitation.</span>
        <button
          className={'btn btnMain btnMainWithImg' + (isHoverSave ? ' ' + styles.white : '')}
          onMouseEnter={() => setIsHoverSave(true)}
          onMouseLeave={() => setIsHoverSave(false)}
          onClick={async () => {
            setIsStep(false);
            setIsHoverSave(false);
            setIsHoverBack(false);
            const response = await BrainHelper.updateEmailInvitationWebsite(
              userToken,
              wedding?.emailInvitationWebsite?.id,
              wedding?.emailInvitationWebsite?.name,
              description,
              wedding.id,
            );
            const weddingCopy = JSON.parse(
              JSON.stringify(wedding, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
            );
            weddingCopy.emailInvitationWebsite = response;
            dispatch(setWedding(weddingCopy));
          }}
        >
          <Icon
            name={'save'}
            size={'s'}
            filled={true}
            color={isHoverSave ? mainBackgroundAccent : secondaryStrongAccent}
          />
          Save
        </button>
      </div>
    </Fragment>,
    <div key={2}>
      <div className={styles.flexRow} style={{ alignItems: 'center', marginBottom: 16 }}>
        <Icon name={'envelope_marker'} size={'s'} />
        <h3 className={'didacticH3'}>Configure the RSVP</h3>
      </div>
      <p className={'didacticP'} style={{ marginBottom: 56, maxWidth: 472 }}>
        The RSVP can be found inside the experience app. All the events you created will be listed here, so you can
        create specific RSVP questions for each individual event. The questions will be showed to only the invited
        guests of each event.
      </p>
      {eventsState.map((event, k) => {
        return (
          <Fragment key={k}>
            <Questions event={event} userToken={userToken} wedding={wedding} />
          </Fragment>
        );
      })}
    </div>,
  ];

  const stepPreview = [
    <Fragment key={'preview0'}>
      <div
        ref={viewRef}
        className={styles.view}
        onScroll={() => {
          const element: HTMLDivElement | null = viewRef.current;
          const imgEl: HTMLImageElement | null = imageRef.current;
          if (imageRef?.current) setDivWidth(imageRef?.current.width);
          if (Number(element?.scrollTop) - Number(imgEl?.height) >= 0) setIsFixed(true);
          else setIsFixed(false);
        }}
        style={
          typeOfScreen === ScreenType.desktop
            ? { maxHeight: '941px', justifyContent: 'space-between' }
            : { maxHeight: '941px', justifyContent: 'space-between' }
        }
      >
        <div style={{ padding: '80px 80px 0 80px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: typeOfScreen !== ScreenType.desktop ? '4px' : '72px',
              marginBottom: 80,
            }}
          >
            <h1
              className={'fridayH1'}
              style={{
                fontFamily: 'Georgia',
                color: '#63435B',
                maxWidth: 146,
                fontSize: 70,
                lineHeight: '70px',
                minWidth: typeOfScreen === ScreenType.mobile ? '38vw' : '',
              }}
            >
              Save our Date
            </h1>
            <img
              src="/assets/graphics/main-save-the-date-graphic.png"
              alt="main save the date"
              style={{ width: typeOfScreen === ScreenType.mobile ? '0px' : 'initial' }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
            <h1 className={'fridayH1'} style={{ textAlign: typeOfScreen === ScreenType.mobile ? 'center' : 'initial' }}>
              {wedding.name ? wedding.name : 'Name&Name'}
            </h1>
            <h2 className={'fridayH2'}>
              {mainDate
                ? `${dateTimeFormat({
                    timeZone: wedding?.mainEvent?.location?.externalQuickCode
                      ? wedding?.mainEvent?.location?.externalQuickCode
                      : dayjs.tz.guess(),
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                  }).format(mainDate)}`
                : 'DAY DD MMM, YYYY'}
            </h2>

            <button
              className={'btn btnMain btnMainWithImg'}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <Icon name={'envelope_marker'} size={'s'} color={!isHover ? '#63435B' : '#FDF6EB'} />
              Add to Calendar
            </button>
            <img
              style={{ transform: 'rotate(335deg)', width: 144, margin: '40px 0', alignSelf: 'center' }}
              src="/assets/graphics/main-email-invitation-graphic.png"
              alt="email invitation"
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '32px', justifySelf: 'end' }}>
          <img src="/assets/logos/whiteclover-logo2.svg" alt="" />
          <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
            <a href="https://www.instagram.com/whitecloverday" target="_blank" rel="noreferrer">
              <Icon name={'instagram'} color={'#63435B'} size={'sPlus'} />
            </a>
            <a href="https://www.facebook.com/whitecloverday" target="_blank" rel="noreferrer">
              <Icon name={'facebook'} color={'#63435B'} size={'sPlus'} />
            </a>
          </div>
        </div>
      </div>
    </Fragment>,
    <Fragment key={'preview1'}>
      <div
        ref={viewRef}
        className={styles.view}
        onScroll={() => {
          const element: HTMLDivElement | null = viewRef.current;
          const imgEl: HTMLImageElement | null = imageRef.current;
          if (imageRef?.current) setDivWidth(imageRef?.current.width);
          if (Number(element?.scrollTop) - Number(imgEl?.height) >= 0) setIsFixed(true);
          else setIsFixed(false);
        }}
        style={{ textAlign: 'center', borderRadius: 0 }}
      >
        <img ref={imageRef} src={blobId} alt="Wedding Couple" />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 80px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <img
              style={{ width: 94, position: 'relative', alignSelf: 'end', top: 120, right: '-80px', margin: '-20px 0' }}
              src="/assets/graphics/secondary-save-the-date.png"
              alt="secondary"
            />
            <h1 className={'fridayH1'}>{wedding.name}</h1>
            <p className={'didacticP'}>{description}</p>
            <h2 className={'fridayH2'}>
              {mainDate
                ? `${dateTimeFormat({
                    timeZone: wedding?.mainEvent?.location?.externalQuickCode
                      ? wedding?.mainEvent?.location?.externalQuickCode
                      : dayjs.tz.guess(),
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                  }).format(mainDate)}`
                : 'DAY DD MMM, YYYY'}
            </h2>
            <a
              className={'didacticH4'}
              style={{
                wordWrap: 'break-word',
                width: '250px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              target="_blank"
              href={`https://maps.google.com/?q=${wedding?.mainEvent?.location?.point?.latitude},${wedding?.mainEvent?.location?.point?.longitude}`}
              rel="noreferrer"
            >
              {wedding?.mainEvent?.location && formatLocation(wedding.mainEvent.location)}
            </a>

            <img
              style={{
                width: 94,
                position: 'relative',
                bottom: 190,
                left: -80,
                transform: 'rotate(-180deg)',
              }}
              src="/assets/graphics/secondary-save-the-date.png"
              alt="secondary"
            />
          </div>

          <button
            className={'btn btnMain btnMainWithImg'}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <img src={'/rsvp/rsvp' + (isHover ? '-light' : '') + '.svg'} alt="rsvp" />
            Respond & RSVP
          </button>
          <p style={{ marginTop: 8, marginBottom: 44 }}>
            You can respond and manage your RSVP so we can arrange our events better. Also, create a profile! There are
            many things we will do with this including a private social media photo app. See you there!
          </p>
          <img
            style={{ transform: 'rotate(335deg)', width: 144, marginBottom: 40 }}
            src="/assets/graphics/main-email-invitation-graphic.png"
            alt="email invitation"
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '32px' }}>
          <img src="/assets/logos/whiteclover-logo2.svg" alt="" />
          <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
            <a href="https://www.instagram.com/whitecloverday" target="_blank" rel="noreferrer">
              <Icon name={'instagram'} color={'#63435B'} size={'sPlus'} />
            </a>
            <a href="https://www.facebook.com/whitecloverday" target="_blank" rel="noreferrer">
              <Icon name={'facebook'} color={'#63435B'} size={'sPlus'} />
            </a>
          </div>
        </div>
      </div>
    </Fragment>,
    <Fragment key={'preview2'}>
      <div
        ref={viewRef}
        className={styles.view}
        onScroll={() => {
          const element: HTMLDivElement | null = viewRef.current;
          const imgEl: HTMLImageElement | null = imageRef.current;
          if (imageRef?.current) setDivWidth(imageRef?.current.width);
          if (Number(element?.scrollTop) - Number(imgEl?.height) >= 0) setIsFixed(true);
          else setIsFixed(false);
        }}
        style={{ maxHeight: '941px', maxWidth: '430px', justifyContent: 'space-between', borderRadius: '24px' }}
      >
        <div className="header" style={{ maxWidth: '430px', borderRadius: '24px 24px 8px 8px', padding: 20 }}>
          <img loading="lazy" className="headerLogo" src="/assets/logos/whiteclover-logo1.svg" alt="logo" />
          <Avatar
            query={''}
            name={`${profile.accountFirstName} ${profile.accountLastName}`}
            userToken={userToken}
            connectedAccountDetails={
              new ConnectedAccountRef({
                accountFirstName: profile.accountFirstName,
                accountLastName: profile.accountLastName,
                imageProfileUrl: profile.imageProfileUrl,
              })
            }
          />
        </div>
        <div>
          <div className={stylesEx.Main} style={{ margin: 0 }}>
            <div className={stylesEx.imgWrapper}>
              <img
                loading="lazy"
                style={{ width: '100%' }}
                onContextMenu={(e) => e.preventDefault()}
                src={blobId}
                alt={'wedding'}
              />
              <h1 className={'fridayH1'}>{wedding.name}</h1>
              <div>
                <img src={GetUI(wedding.socialEventType as EventType).icon} alt="ceremony" width={32} />
                <p>{daysToWedding} Days to go</p>
              </div>
            </div>
            <div>
              <div className={stylesEx.eventsWrapper}>
                {eventsState.map((event, key) => {
                  let startDate = new Date();
                  let endDate = new Date();
                  if (wedding?.mainEvent?.startTimestamp) startDate = new Date(event?.startTimestamp);
                  if (wedding?.mainEvent?.startTimestamp) endDate = new Date(event?.endTimestamp);
                  return (
                    <div
                      key={key}
                      style={
                        isFixed && key === 0
                          ? { marginTop: Number(divRef?.current?.clientHeight), padding: '8px 24px' }
                          : { padding: '8px 24px' }
                      }
                    >
                      <div>
                        <h3 className={'didacticH3'}>{event.name}</h3>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <img src="/rsvp/map.svg" alt="map" />
                          <a
                            className={'didacticP ' + stylesEx.highlighted}
                            style={{
                              wordWrap: 'break-word',
                              width: '250px',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                            target="_blank"
                            href={`https://maps.google.com/?q=${event?.location?.point?.latitude},${event?.location?.point?.longitude}`}
                            rel="noreferrer"
                          >
                            {event.location && formatLocation(event.location)}
                          </a>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <img src="/rsvp/calendar.svg" alt="map" />
                          <p className={'didacticP ' + stylesEx.highlighted} style={{ marginRight: '16px' }}>
                            {`${dateTimeFormat({
                              timeZone: event.location?.externalQuickCode,
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            }).format(startDate)}`}
                          </p>
                          <img src="/rsvp/clock-dark.svg" alt="clock" />
                          <p className={'didacticP'}>
                            {`${dateTimeFormat({
                              timeZone: event.location?.externalQuickCode,
                              hour: 'numeric',
                              minute: 'numeric',
                            }).format(startDate)}`}
                          </p>
                          -
                          <p className={'didacticP'}>
                            {`${dateTimeFormat({
                              timeZone: event.location?.externalQuickCode,
                              hour: 'numeric',
                              minute: 'numeric',
                            }).format(endDate)}`}
                          </p>
                        </div>
                        <DefaultQuestions event={event} questionsOnly={true} disabled={true} />
                        {event.questions.map((question, k1) => {
                          return <ExtraQuestion question={question} disabled={true} key={k1} wedding={wedding} />;
                        })}
                      </div>
                      <hr
                        style={{
                          width: 'calc(100% + 48px)',
                          position: 'relative',
                          left: '-24px',
                          margin: '32px 0',
                        }}
                      />
                    </div>
                  );
                })}
                <button
                  className={'btn btnMain btnMainWithImg'}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  <img src={'/rsvp/rsvp' + (isHover ? '-light' : '') + '.svg'} alt="rsvp" />
                  Complete RSVP
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="footerSectionMainPlain"
          style={{
            gap: 24,
            top: '969px',
            left: 'unset',
            right: 'unset',
            width: '430px',
            borderRadius: '0 0 24px 24px',
          }}
        >
          <div>
            <IonImg className="plainImgDefault" src="/assets/icons/icon-browser.svg" style={{ width: 16 }} />
            <p>Website</p>
          </div>
          <div>
            <IonImg className="plainImgDefault" src="/assets/icons/icon-calendar.svg" style={{ width: 16 }} />
            <p>Schedule</p>
          </div>
          <div>
            <IonImg className="plainImgDefault" src="/assets/icons/icon-apps.svg" style={{ width: 16 }} />
            <p>Main</p>
          </div>
          <div>
            <IonImg className="plainImgDefault" src="/assets/icons/icon-camera.svg" style={{ width: 16 }} />
            <p>Photo App</p>
          </div>
          <div>
            <IonImg
              className="plainImgDefault"
              src="/assets/icons/icon-filled-envelope-marker.svg"
              style={{ width: 16 }}
            />
            <p>RSVP</p>
          </div>
        </div>
      </div>
    </Fragment>,
  ];

  return (
    <div
      className={styles.main}
      style={
        typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
          ? {
              flexDirection: 'column-reverse',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              background: 'initial',
            }
          : {}
      }
    >
      {!isStep ? (
        <MainRsvpPage setIsStep={setIsStep} setStep={setStep} />
      ) : (
        <Fragment>
          <div
            className={styles.preview}
            style={
              typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                ? {
                    paddingTop: '188px',
                    flexDirection: 'column',
                    maxWidth: '100vw',
                    minWidth: '100vw',
                    width: '100%',
                    height: '100svh',
                    overflowX: 'hidden',
                  }
                : {}
            }
          >
            {stepPreview[step]}
          </div>
          {typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop ? (
            <></>
          ) : (
            <>
              <button
                className="btn btnMain"
                style={{ width: 100, position: 'absolute', left: 10, top: 10 }}
                onClick={() => setIsStep(false)}
              >
                {' '}
                Back{' '}
              </button>

              <div
                className={styles.warning}
                style={{
                  backgroundColor: 'white',
                  filter: 'drop-shadow(0px 0px 0px transparent)',
                  maxWidth: '50vw',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '24px',
                  gap: '16px',
                  background: '#FAE28F',
                  borderRadius: '16px',
                  height: 'fit-content',
                }}
              >
                <button style={{ background: 'none', border: 'none' }}>
                  <Icon name={'info'} size={'sPlus'} />
                </button>

                <p className={'didacticP'}>Edit is available on desktops and laptops only.</p>
              </div>
            </>
          )}
          <div
            className={styles.details}
            style={
              typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                ? {
                    display: 'none',
                  }
                : {}
            }
          >
            <header
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 'calc(100% - 40px)',
                minWidth: '50%',
                padding: 31,
              }}
            >
              <h1 className={'fridayH1'}>Invitation & RSVP</h1>
              <div style={{ display: 'flex', gap: '16px' }}>
                {step === 2 && (
                  <button
                    className={'btn btnSecondary btnMainWithImg'}
                    // className={'btn btnMain2nd btnMain2ndWithImg'}
                    style={{ width: '130px' }}
                    onClick={() => {
                      setIsHoverBack(false);
                      setIsStep(false);
                    }}
                  >
                    <Icon name={'arrow_back'} size={'xs'} color={'#63435B'} />
                    <p className={'didacticP'}>Back</p>
                  </button>
                )}
                <button
                  className={'btn btnMain btnMainWithImg' + (isHoverBack ? ' ' + styles.white : '')}
                  // className={'btn btnMain2nd btnMain2ndWithImg'}
                  // style={{ width: '130px' }}
                  onMouseEnter={() => setIsHoverBack(true)}
                  onMouseLeave={() => setIsHoverBack(false)}
                  onClick={() => {
                    setIsHoverBack(false);
                    setIsStep(false);
                  }}
                  style={{ width: 'fit-content', padding: '8px 8px', maxHeight: 70 }}
                >
                  <Icon
                    name={step === 2 ? 'save' : 'arrow_back'}
                    size={'s'}
                    color={isHoverBack ? '#FDF6EB' : '#63435B'}
                  />
                  <p className={'didacticP'}>{step === 2 ? 'Save RSVP' : 'Back'}</p>
                </button>
              </div>
            </header>
            <hr />

            <div className={styles.step}>{stepDiv[step]}</div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Rsvp;

const MainRsvpPage = (props: { setIsStep: any; setStep: any }) => {
  const user = useSelector(selectUser);
  const jwtToken = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);
  const storedInvitedVisitors = useSelector(selectInvitedVisitors);
  const rsvpEvents = useSelector(selectRsvpEvents);
  const [eventsState, setEventsState] = useState<RSVPEvent[]>([]);
  const [isRespondedOpen, setIsRespondedOpen] = useState(true);
  const [isNotRespondedOpen, setIsNotRespondedOpen] = useState(true);
  const [isWarningOpen, setIsWarningOpen] = useState(true);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [query, setQuery] = useState('');
  const [guestList, setGuestList] = useState(Array<AllowedVisitor>());
  const [invited, setInvited] = useState<AllowedVisitor | undefined>(undefined);
  const [questionsList, setQuestionsList] = useState<Map<string, ListQuestionsResponse | undefined>>(new Map());
  const [answers, setAnswers] = useState<Map<string, Answer[] | undefined>>(new Map());
  const [attend, setAttend] = useState<Map<string, 0 | 1 | 2>>(new Map());
  const [plus1, setPlus1] = useState<Map<string, number>>(new Map());
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rsvpResponse, setRsvpResponse] = useState<RSVPResponded>(RSVPResponded.RSVPRespondedDEFAULT);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getAllowedVisitors();
    getRsvpEvents();
  }, []);

  useEffect(() => {
    if (query.length > 0) {
      const timer = setTimeout(() => {
        if (query) {
          getAllowedVisitors();
        }
      }, 600);

      return () => clearTimeout(timer);
    } else {
      setGuestList(storedInvitedVisitors);
      if (searchParams.get('user')) {
        setIsEditActive(true);
        setInvited(storedInvitedVisitors.find((visitor) => visitor.id == searchParams.get('user')));
        setTimeout(() => {
          if (searchParams.get('rsvpEvent')) {
            const element = document.getElementById(searchParams.get('rsvpEvent') || '');
            if (element) {
              // 👇 Will scroll smoothly to the top of the next section
              element.scrollIntoView({ behavior: 'smooth' });
            }
            const userElement = document.getElementById(searchParams.get('user') || '');
            if (userElement) {
              // 👇 Will scroll smoothly to the top of the next section
              userElement.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }, 500);
      }
    }
  }, [query]);

  useEffect(() => {
    // getAllowedVisitors();
    getInvitedVisitor(invited);
    getRsvpEvents();
  }, [invited]);

  useEffect(() => {
    const tempEventsArray: RSVPEvent[] = JSON.parse(
      JSON.stringify(rsvpEvents, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
    );
    const sorted = tempEventsArray.sort(
      (a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime(),
    );
    setEventsState(sorted);
  }, [rsvpEvents]);

  const getAllowedVisitors = async () => {
    const res = await BrainHelper.getAllowedVisitors(
      jwtToken,
      wedding.id,
      query,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      dispatch,
      addInvitedVisitors,
    );
    setGuestList(res.allowedVisitors);
  };

  const getRsvpEvents = async () => {
    const rsvpEvents = await BrainHelper.listRSVPEvents(jwtToken, wedding.id);
    dispatch(setRsvpEventsReducer(rsvpEvents?.rsvpEvents));
    const temp: Map<string, ListQuestionsResponse | undefined> = new Map<string, ListQuestionsResponse | undefined>(
      questionsList,
    );
    if (rsvpEvents)
      for (const e of rsvpEvents.rsvpEvents) {
        const res = await getQuestions(e);
        temp.set(e.id, res);
      }
    setQuestionsList(temp);
  };

  const getQuestions = async (event: RSVPEvent) => {
    return await BrainHelper.listQuestions(jwtToken, event.id);
  };

  const getInvitedVisitor = async (invited: AllowedVisitor | undefined) => {
    const attendTemp: Map<string, 0 | 1 | 2> = new Map<string, 0 | 1 | 2>();
    const plus1Temp: Map<string, number> = new Map<string, number>();
    invited?.attendToRsvpEvents?.forEach((i) => {
      if (i.rsvpEvent?.id) {
        attendTemp.set(i.rsvpEvent?.id, i.attend ? 1 : 2);
        plus1Temp.set(i.rsvpEvent?.id, Number(i.plusOnes as BigInt));
      }
    });
    setAttend(attendTemp);
    setPlus1(plus1Temp);
  };

  const downloadList = async () => {
    const fileData = await BrainHelper.downloadRSVPGuestList(jwtToken, wedding.id, query, 'csv', rsvpResponse);
    if (fileData) {
      const blob = new Blob([fileData.content], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileData.fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      if (fileData?.fileName && fileData.fileName.trim() !== '') link.click();
      else toast('No file to download');

      // Clean up and remove the link
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link.parentNode.removeChild(link);
    }
    setShowModal(false);
    setRsvpResponse(RSVPResponded.RSVPRespondedDEFAULT);
  };

  const onUpdateRsvp = async () => {
    if (isEditActive) {
      const allowedVisitor: AllowedVisitor = JSON.parse(
        JSON.stringify(invited, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      let visitorResponse: AllowedVisitor | undefined = undefined;
      for (const e of rsvpEvents) {
        if (attend.get(e.id) != 0 && attend.get(e.id) != undefined) {
          const attendRequest = new CreateOrUpdateAttendanceForAllowedVisitorRequest();
          attendRequest.id =
            allowedVisitor.attendToRsvpEvents?.find(
              (a) => a.allowedVisitor?.id === allowedVisitor.id && a.rsvpEvent?.id === e.id,
            )?.id || '';
          attendRequest.allowedVisitorId = allowedVisitor.id;
          attendRequest.rsvpEventId = e.id;
          attendRequest.attend = attend.get(e.id) == 1;
          attendRequest.responded = true;
          attendRequest.allowedPlusOnes = (plus1.get(e.id) || 0) as unknown as bigint;
          visitorResponse = await BrainHelper.createOrUpdateAttendanceForAllowedVisitor(jwtToken, attendRequest);
        }
      }
      answers.forEach(async (a) => {
        if (a)
          for (const answer of a) {
            if (invited && invited?.id)
              if (answer?.id)
                await BrainHelper.updateAnswer(
                  jwtToken,
                  answer?.id,
                  wedding.id,
                  invited?.id,
                  answer?.questionId,
                  answer?.freeText,
                  answer?.singleOptionChoices,
                );
              else
                await BrainHelper.createAnswer(
                  jwtToken,
                  invited?.id,
                  wedding.id,
                  answer?.questionId,
                  answer?.freeText,
                  answer?.singleOptionChoices,
                );
          }
      });
      if (visitorResponse) {
        const guestListTemp = [...guestList];
        const index = guestListTemp.findIndex((v: AllowedVisitor) => v.id === visitorResponse?.id);
        if (index !== -1) guestListTemp[index] = visitorResponse;
        setGuestList(() => guestListTemp);
        setInvited(visitorResponse);
      }
      getRsvpEvents();
    }
    setIsEditActive((prevState) => !prevState);
  };

  const downloadFragment = (
    <Fragment>
      <p className={'didacticP'}>What category do you want to download?</p>
      <div style={{ display: 'flex', gap: 16 }}>
        <input
          id={'default'}
          className={'input checkbox'}
          type="checkbox"
          name={'default'}
          checked={rsvpResponse === RSVPResponded.RSVPRespondedDEFAULT}
          onChange={() => {
            setRsvpResponse(RSVPResponded.RSVPRespondedDEFAULT);
          }}
        />
        <label htmlFor={'default'}>All Visitors</label>
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <input
          id={'yes'}
          className={'input checkbox'}
          type="checkbox"
          name={'yes'}
          checked={rsvpResponse === RSVPResponded.RSVPRespondedYES}
          onChange={() => {
            setRsvpResponse(RSVPResponded.RSVPRespondedYES);
          }}
        />
        <label htmlFor={'yes'}>Responded Visitors</label>
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <input
          id={'no'}
          className={'input checkbox'}
          type="checkbox"
          name={'default'}
          checked={rsvpResponse === RSVPResponded.RSVPRespondedNO}
          onChange={() => {
            setRsvpResponse(RSVPResponded.RSVPRespondedNO);
          }}
        />
        <label htmlFor={'no'}>Not Responded Visitors</label>
      </div>
    </Fragment>
  );

  const typeOfScreen = useTypeOfScreen();
  return (
    <div
      className={styles.page + ' ' + styles[`page${typeOfScreen}`]}
      style={{ paddingTop: typeOfScreen !== ScreenType.laptop && typeOfScreen !== ScreenType.desktop ? 76 : 16 }}
    >
      <header>
        <h1 className={'fridayH1'}>Invitation & RSVP</h1>
      </header>
      <hr />
      <div style={{ display: 'flex', gap: 32, alignItems: 'center', flexWrap: 'wrap', maxWidth: '100vw' }}>
        <div>
          <h3 className={'didacticH3'}>Hey {user.tenantName}</h3>
          <p className={'didacticP'} style={{ width: '100%', maxWidth: '672px', marginBottom: 32 }}>
            Here you can see & manage your email invitations and configure your RSVP. Select below to start. Keep in
            mind this is the last configuration you need to do because many information are automatically filled from
            the website and planning.
          </p>
        </div>
        <div
          className={styles.warning}
          style={
            !isWarningOpen
              ? { backgroundColor: 'white', filter: 'drop-shadow(0px 0px 0px transparent)', maxWidth: '100vw' }
              : { maxWidth: '100vw' }
          }
        >
          <button onClick={() => setIsWarningOpen((prevState) => !prevState)}>
            <Icon name={'info'} size={'sPlus'} />
          </button>
          {isWarningOpen && (
            <p className={'didacticP'}>
              After the configuration, you can send these emails to your guests using the Send button in the guests
              page.
            </p>
          )}
        </div>
      </div>
      <div className={styles.emailTemplates}>
        <div>
          <div
            role={'button'}
            tabIndex={0}
            className={styles.template}
            style={{ backgroundImage: 'url("/assets/graphics/save_the_date-back.png")' }}
            onMouseDown={(e) => {
              if (e.button === 2) return;
              props.setIsStep(true);
              props.setStep(0);
            }}
          >
            <div
              className={styles.templateIcon}
              style={{
                backgroundColor: '#FBCCB1',
              }}
            >
              <img src="/assets/icons/icon-save-the-date.svg" alt="calendar" />
            </div>
            <div>
              <h3 className={'didacticH3'}>Save The Date</h3>
              <span className={'didacticSpan'}>Click to view</span>
            </div>
          </div>
          <div
            role={'button'}
            tabIndex={0}
            className={
              styles.template +
              ' ' +
              (!wedding.mainEvent || !wedding.emailInvitationWebsite ? styles.pulse + ' ' + styles.pulsePurple : '')
            }
            style={{ backgroundImage: 'url("/assets/graphics/invitation-back.png")' }}
            onMouseDown={(e) => {
              if (e.button === 2) return;
              props.setIsStep(true);
              props.setStep(1);
            }}
          >
            <div
              className={styles.templateIcon}
              style={{
                backgroundColor: '#C7ADC1',
              }}
            >
              <img src="/assets/icons/icon-envelope.svg" alt="calendar" />
            </div>
            <div>
              <h3 className={'didacticH3'}>Email Invitation</h3>
              <span className={'didacticSpan'}>Click to view</span>
            </div>
          </div>
          <div
            role={'button'}
            tabIndex={0}
            className={
              styles.template +
              ' ' +
              (!wedding.mainEvent || !wedding.emailInvitationWebsite ? styles.pulse + ' ' + styles.pulseGreen : '')
            }
            style={{ backgroundImage: 'url("/assets/graphics/rsvp-back.png")' }}
            onMouseDown={(e) => {
              if (e.button === 2) return;
              props.setIsStep(true);
              props.setStep(2);
            }}
          >
            <div
              className={styles.templateIcon}
              style={{
                backgroundColor: '#C1C6A8',
              }}
            >
              <img src="/assets/icons/icon-save-the-date.svg" alt="calendar" />
            </div>
            <div>
              <h3 className={'didacticH3'}>RSVP</h3>
              <span className={'didacticSpan'}>Click to view</span>
            </div>
          </div>
        </div>
      </div>
      <>
        <div className={styles.flex} style={{ marginRight: 40 }}>
          <div className={styles.flex} style={{ width: '100%', maxWidth: '352px' }}>
            <Icon name={'envelope_marker'} size={'s'} color={'#281B24'} />
            <h3 className={'didacticH3'}>RSVP Results</h3>
          </div>

          <FlatButton
            id={'downloadListButton'}
            title="Export Results"
            icon={'spreadsheet_file'}
            iconColor={'#63435B'}
            extraClass={styles.flatButton}
            _callback={() => setShowModal(true)}
          />
        </div>
        <div
          className={styles.rsvp}
          style={
            typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
              ? {
                  flexDirection: 'column',
                  width: 'calc(100%  )',
                  margin: 0,
                }
              : typeOfScreen === ScreenType.laptop
              ? {
                  flexDirection: 'column',
                  width: 'calc(100% - 88px - 16px)',
                }
              : {}
          }
        >
          <div
            className={styles.rsvpVisitors}
            style={
              typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                ? {
                    width: 'calc(100%)',
                    maxWidth: 'calc(100%)',
                    minWidth: 'calc(100%)',
                  }
                : typeOfScreen === ScreenType.laptop
                ? {
                    width: 'calc(100%)',
                    maxWidth: 'calc(100%)',
                    minWidth: 'calc(100%)',
                  }
                : {}
            }
          >
            <div className={styles.searchBar + ' ' + (isSearchActive ? styles.searchBarActive : '')}>
              <img src={'/rsvp/guests/searchIcon' + (isSearchActive ? '-highlighted' : '') + '.svg'} alt="Search" />
              <input
                placeholder="Search"
                onFocus={() => setIsSearchActive(true)}
                onBlur={() => setIsSearchActive(false)}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            </div>
            <div
              className={styles.guests}
              style={
                typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                  ? {
                      width: 'calc(100%)',
                      maxWidth: 'calc(100%)',
                      minWidth: 'calc(100%)',
                    }
                  : typeOfScreen === ScreenType.laptop
                  ? {
                      width: 'calc(100%)',
                      maxWidth: 'calc(100%)',
                      minWidth: 'calc(100%)',
                    }
                  : {}
              }
            >
              <div
                className={styles.header}
                role={'button'}
                tabIndex={0}
                onMouseDown={(e) => {
                  if (e.button === 2) return;
                  setIsRespondedOpen((prevState) => !prevState);
                }}
              >
                <h4 className={'didacticH4'}>
                  Responded ({guestList && guestList.filter((g) => g.status === 4).length})
                </h4>
                <Icon
                  name={
                    guestList && guestList.filter((g) => g.status === 4).length > 0 && isRespondedOpen
                      ? 'arrow_up'
                      : 'arrow_down'
                  }
                  size={'s'}
                />
              </div>
              {isRespondedOpen &&
                guestList &&
                guestList
                  .filter((g) => g.status === 4)
                  ?.map((guest) => {
                    return (
                      <div
                        id={guest.id}
                        key={'responded' + guest.id}
                        className={styles.guest + ' ' + (invited?.id === guest.id ? styles.guestActive : '')}
                        role={'button'}
                        tabIndex={0}
                        onMouseDown={(e) => {
                          if (e.button === 2) return;
                          setInvited(guest);
                          setIsEditActive(false);
                        }}
                      >
                        <Avatar
                          name={`${guest.firstName} ${guest.lastName}`}
                          userToken={String(jwtToken)}
                          connectedAccountDetails={guest.connectedAccountDetails}
                          query={''}
                        />

                        <p className={'didacticP'}>{`${guest.firstName} ${guest.lastName}`}</p>
                      </div>
                    );
                  })}
              <div
                className={styles.header}
                role={'button'}
                tabIndex={0}
                onMouseDown={(e) => {
                  if (e.button === 2) return;
                  setIsNotRespondedOpen((prevState) => !prevState);
                }}
              >
                <h4 className={'didacticH4'}>
                  Not Responded ({guestList && guestList.filter((g) => g.status !== 4).length})
                </h4>
                <Icon
                  name={
                    guestList && guestList.filter((g) => g.status !== 4).length > 0 && isNotRespondedOpen
                      ? 'arrow_up'
                      : 'arrow_down'
                  }
                  size={'s'}
                />
              </div>
              {isNotRespondedOpen &&
                guestList &&
                guestList
                  .filter((g) => g.status !== InvitationStatus.InvitationStatusRSVPCompleted)
                  ?.map((guest) => {
                    return (
                      <div
                        key={'notresponded' + guest.id}
                        id={guest.id}
                        role={'button'}
                        tabIndex={0}
                        className={styles.guest + ' ' + (invited?.id === guest.id ? styles.guestActive : '')}
                        onMouseDown={(e) => {
                          if (e.button === 2) return;
                          setInvited(guest);
                          setIsEditActive(false);
                        }}
                      >
                        <Avatar
                          name={`${guest.firstName} ${guest.lastName}`}
                          userToken={String(jwtToken)}
                          connectedAccountDetails={guest.connectedAccountDetails}
                          query={''}
                        />
                        <p className={'didacticP'}>{`${guest.firstName} ${guest.lastName}`}</p>
                      </div>
                    );
                  })}
            </div>
          </div>
          <div
            className={styles.rsvpResults}
            style={
              typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                ? {
                    position: 'absolute',
                    width: '100%',
                    height: '100svh',
                    maxHeight: '100svh',
                    paddingBottom: 66,
                    zIndex: 1000,
                    top: 0,
                    left: 0,
                    display: invited ? 'initial' : 'none',
                    border: '1px solid gray',
                    borderRadius: 0,
                  }
                : typeOfScreen === ScreenType.laptop
                ? {
                    width: 'calc(100%)',
                    maxWidth: 'calc(100%)',
                    minWidth: 'calc(100%)',
                  }
                : {}
            }
          >
            {invited ? (
              <Fragment>
                <div className={styles.flex + ' ' + styles.flexSpaceBetween + ' ' + styles.relative}>
                  <h3 className={'fridayH3'}>RSVP: {invited.firstName + ' ' + invited.lastName}</h3>
                  <button
                    style={
                      typeOfScreen !== ScreenType.desktop
                        ? { width: 'fit-content', height: '40px', position: 'relative', padding: '12px 8px' }
                        : {}
                    }
                    className={'btn btnIconSecondary btnIconSecondaryMedium  ' + styles.absoluteRight}
                    onMouseEnter={() => setIsEditHovered(true)}
                    onMouseLeave={() => setIsEditHovered(false)}
                    onClick={() => onUpdateRsvp()}
                  >
                    <Icon
                      name={isEditActive ? 'save' : 'edit'}
                      size={'s'}
                      color={isEditHovered ? '#FDF6EB' : '#63435B'}
                    />
                    {isEditActive ? 'Save' : 'Edit'}
                  </button>
                  <button
                    className="btn btnIconSecondary btnIconSecondaryMedium"
                    style={
                      typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop
                        ? {
                            position: 'absolute',
                            right: typeOfScreen === ScreenType.mobile ? 0 : 100,
                            top: typeOfScreen === ScreenType.mobile ? 58 : 0,
                            width: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            height: '40px',
                          }
                        : { display: 'none' }
                    }
                    onClick={() => setInvited(undefined)}
                  >
                    Exit
                  </button>
                </div>
                {eventsState.map((e, key) => {
                  const index = invited?.invitedToRsvpEvents.findIndex((i) => i.id === e.id);
                  return (index !== undefined && index > -1) || !e.isPrivate ? (
                    <Fragment>
                      <EventQuestions
                        key={key}
                        e={e}
                        questionsList={questionsList}
                        setQuestionsList={setQuestionsList}
                        setAttend={setAttend}
                        setPlus1={setPlus1}
                        plus1={plus1}
                        attend={attend}
                        invited={invited}
                        answers={answers}
                        setAnswers={setAnswers}
                        isExperienceApp={false}
                        isDashboard={true}
                        questionsOnly={true}
                        isDisabled={!isEditActive}
                        isDropdown={true}
                      />
                      <hr className={styles.hr} />
                    </Fragment>
                  ) : (
                    ''
                  );
                })}
              </Fragment>
            ) : (
              <div
                className={styles.rsvpResultsEmpty}
                style={
                  typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop ? { display: 'none' } : {}
                }
              >
                <Icon name={'envelope_marker'} size={'l'} color={'#A9A4A7'} />
                <h4 className={'didacticH4'}>
                  Select a guest to see his RSVP. <br /> You can also edit or complete an RSVP for your guests.
                </h4>
              </div>
            )}
          </div>
        </div>
      </>
      {showModal && (
        <GenericConfirmModal
          setShow={setShowModal}
          title={'Download csv file'}
          text={'What category do you want to download'}
          confirmBtnFunction={() => downloadList()}
          confirmBtnText={'Download'}
          confirmBtnIcon={''}
          html={downloadFragment}
        />
      )}
    </div>
  );
};
