import Icon from '../../../../shared/Icon';
import styles from './QuickActions.module.scss';
import React, { Fragment } from 'react';
import {
  InvitationOptionInvite,
  InvitationOptionReminder,
  InvitationOptionSaveTheDate,
} from '../../../../constants/invitation.constants';

type QuickActionsProps = {
  canUseActions: boolean;
  deleteRows: () => void;
  setShowAddTag: any;
  setIsActiveSendModal: any;
  setSendOption: any;
  setIsActiveAddToeEventModal: any;
};

const QuickActions = ({
  canUseActions,
  deleteRows,
  setShowAddTag,
  setIsActiveSendModal,
  setSendOption,
  setIsActiveAddToeEventModal,
}: QuickActionsProps) => {
  return (
    <Fragment>
      <div className={styles.header}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            backgroundColor: '#835978',
            borderRadius: 4,
            padding: '8px',
            color: '#ffffff',
          }}
        >
          <Icon name={'thunder'} size={'s'} color={'#ffffff'} />
          Quick Actions
        </div>
        <div style={{ color: '#A9A4A7' }}>Get started here</div>
      </div>
      <div className={styles.buttonsGrid}>
        <div>
          <button
            className={styles.iconButton}
            disabled={!canUseActions}
            onClick={() => {
              setIsActiveAddToeEventModal((prevState: boolean) => !prevState);
            }}
          >
            <Icon name={'users'} color={'#F9AC80'} size={'m'} />
          </button>
          <div className={styles.text + ' didacticSpan'}>Add Users to Event</div>
        </div>
        <div>
          <button className={styles.iconButton} onClick={() => canUseActions && deleteRows()} disabled={!canUseActions}>
            <Icon name={'remove_user'} color={'#F9AC80'} size={'m'} />
          </button>
          <div className={styles.text + ' didacticSpan'}>Remove / Delete Users</div>
        </div>
        <div>
          <button
            className={styles.iconButton}
            onClick={() => {
              setIsActiveSendModal(true);
              setSendOption(InvitationOptionReminder);
            }}
          >
            <Icon name={'notifications'} color={'#F9AC80'} size={'m'} />
          </button>
          <div className={styles.text + ' didacticSpan'}>Send Reminder</div>
        </div>
        <div>
          <button
            className={styles.iconButton}
            onClick={() => setShowAddTag((prevState: boolean) => !prevState)}
            disabled={!canUseActions}
          >
            <Icon name={'label'} color={'#F9AC80'} size={'m'} />
          </button>
          <div className={styles.text + ' didacticSpan'}>Add Tags</div>
        </div>
        <div>
          <button
            className={styles.iconButton}
            onClick={() => {
              setIsActiveSendModal(true);
              setSendOption(InvitationOptionInvite);
            }}
          >
            <Icon name={'envelope_marker'} color={'#F9AC80'} size={'m'} />
          </button>
          <div className={styles.text + ' didacticSpan'}>Send Invitation</div>
        </div>
        <div>
          <button
            className={styles.iconButton}
            onClick={() => {
              setIsActiveSendModal(true);
              setSendOption(InvitationOptionSaveTheDate);
            }}
          >
            <Icon name={'calendar'} color={'#F9AC80'} size={'m'} />
          </button>
          <div className={styles.text + ' didacticSpan'}>Send Save the Date</div>
        </div>
      </div>
    </Fragment>
  );
};

export default QuickActions;
