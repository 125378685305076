import '@ionic/react/css/core.css';
import './Profile.scss';

import { IonContent, IonHeader, IonImg, IonPage } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import success from '../../../1127-success.json';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import {
  selectIsLoggedIn,
  selectJwt,
  selectMarriageCode,
  selectProfile,
  selectProfileImages,
  setProfile,
  setProfileImages,
  setStreamFired,
} from '../../../app/redux/authorization.slice.reducer';
import { Backend } from '../../../config/backend';
import location from '../../../loader-animation.json';
import { MobileBottomModal } from '../../modals';
import { logger } from '../../../config/Logger';
import { ProfileRequest } from '../../../pkg/protobuf/v2/auth/auth_types_pb';
import cleanLocalStorage from '../../../app/helper/CleanLocalStorage';
import { UpdateConnectedAccountRequest } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import Icon from '../../../shared/Icon';
import { greenLight, secondaryStrongAccent } from '../../../styles/colors';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Profile = (): JSX.Element => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const inputFile = useRef<HTMLInputElement>(null);
  const userToken = useSelector(selectJwt);
  const user = useSelector(selectProfile);
  const wedding = useSelector(selectWedding);
  const marriageCode = useSelector(selectMarriageCode);
  const [fileUrl, setFileurl] = useState<string>('');
  const [fileId, setFileId] = useState<string | undefined>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(true);
  const [editing, setEditing] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const [fullName, setFullName] = useState<string>(`${user?.accountFirstName} ${user.accountLastName}`);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startingName, setStartingName] = useState<string>(`${user?.accountFirstName} ${user.accountLastName}`);
  const [showBottomModal, setShowBottomModal] = useState<boolean>(false);
  const [fullNameError, setFullNameError] = useState<boolean>(false);
  const [comingSoonModal, setComingSoonModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [phoneNumber, setPhoneNumber] = useState<string>(user.phoneNumber);
  const profileImages = useSelector(selectProfileImages);
  const dispatch = useDispatch();

  // start loading bullets by default
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initFileLoading, setInitFileLoading] = useState<boolean>(false);
  const [completedFile, setCompletedFile] = useState<boolean>(true);

  useEffect(() => {
    BrainHelper.listConnectedAccountDetails(
      userToken,
      [user.connectedAccountId || user.connectedAccountPhoneId],
      dispatch,
      null,
    )
      .then((profileResp) => {
        if (Array.isArray(profileResp) && profileResp.length > 0) dispatch(setProfile(profileResp[0]));
      })
      .catch((e) => {
        logger.error({ message: e, functionName: 'DashboardProfile.useEffect' });
      });
  }, []);

  useEffect(() => {
    if (!isLoggedIn) navigate('/');
    // if (profileImages.size > 0) setFileId(profileImages.get(user?.accountId || user?.accountPhoneId));
    if (user?.imageProfileUrl && userToken) Imagehelper.fetchImage(user?.imageProfileUrl, setFileId, userToken, 200);
  }, [user]);

  const onButtonClick = async () => {
    // `current` points to the mounted file input element
    inputFile.current?.click();
  };

  const updateProfile = async (jwt: string) => {
    try {
      if (fullName !== undefined && fullName.trim() !== '') {
        const profileRequest = new UpdateConnectedAccountRequest();
        profileRequest.id = user.connectedAccountId || user.connectedAccountPhoneId;
        profileRequest.accountId = user.accountId || user.accountPhoneId;
        profileRequest.weddingId = user.weddingId;
        if (fullName !== undefined && fullName.trim() !== '') profileRequest.fullname = fullName;
        profileRequest.imageProfileUrl = fileUrl === '' ? ' ' : fileUrl;
        profileRequest.phoneNumber = user.phoneNumber;
        const headers = new Headers();
        headers.set('Authorization', `Bearer ${jwt}`);
        const responseMessage = await Backend.brainClientV2.updateConnectedAccount(profileRequest, { headers });
        dispatch(setStreamFired(true));
        if (responseMessage && responseMessage.connectedAccount) {
          if (profileImages.size > 0) {
            const tempList: any = new Map();
            profileImages.forEach((value, key) => {
              tempList.set(key, value);
            });
            tempList.set(
              responseMessage.connectedAccount.accountId || responseMessage.connectedAccount.accountPhoneId,
              fileId,
            );
            dispatch(setProfileImages(tempList));
          }

          dispatch(setProfile(responseMessage.connectedAccount));
        }
        // navigate('/home');
        window.location.reload();
      } else setFullNameError(true);
    } catch (e) {
      logger.error({ message: e, functionName: 'Profile.updateProfile' });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateImageProfileOnly = async (jwt: string) => {
    if (!fileUrl) return;
    const profileRequest = new ProfileRequest();
    profileRequest.fullName = fullName || ' ';
    profileRequest.imageProfileUrl = fileUrl === '' ? ' ' : fileUrl;
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${jwt}`);
    try {
      await Backend.authClientV2.updateProfile(profileRequest, { headers });
    } catch (e) {
      console.log(e);
    }
    window.location.reload();
  };

  const logout = () => {
    logger.info({ message: 'clear local storage', functionName: 'Profile.logout' });
    cleanLocalStorage();
    window.location.pathname = '/';
  };

  useEffect(() => {
    if (fileUrl?.trim() != '') {
      updateProfile(userToken);
    }
  }, [fileUrl]);

  return (
    <>
      <IonPage>
        <IonHeader className="header">
          <div role={'presentation'} onMouseDown={() => navigate('/home')}>
            <img
              loading="lazy"
              className="headerLogo"
              src="/assets/logos/whiteclover-logo1.svg"
              alt="logo"
              height={32}
            />
          </div>
        </IonHeader>
        <IonContent>
          <div className={'title-container'}>
            <h2 className="didacticH2">My Profile</h2>
          </div>
          <div className="profile-page">
            <div className="profile-container">
              <button onClick={() => onButtonClick()}>
                <div className="addImage" style={{ backgroundImage: fileId === '' ? '' : `url(${fileId})` }}>
                  {!completedFile ? (
                    <div className="loadingFinishProfileImage">
                      {!initFileLoading ? (
                        <Lottie options={defaultOptions1} height={60} width={60} />
                      ) : (
                        <Lottie options={defaultOptions2} height={60} width={60} />
                      )}
                    </div>
                  ) : (
                    <img className={fileId !== '' ? 'addedImg' : ''} src={'/icon-avatar.svg'} alt="add" />
                  )}
                </div>
                <p>Change picture</p>
              </button>
              <input
                id="file"
                type="file"
                ref={inputFile}
                accept="image/jpeg, image/png, image/tiff, image/heic, image/heif"
                multiple={false}
                style={{ display: 'none' }}
                onChange={async (event) => {
                  await Imagehelper.handleImageUpload(
                    event,
                    userToken,
                    marriageCode,
                    setPercentage,
                    percentage,
                    setLoading,
                    setFileurl,
                    setFileId,
                    setCompletedFile,
                    false,
                  );
                  // enable loading
                  setCompletedFile(false);
                }}
              />
            </div>
            <div className={'floatingInputContainer' + (fullNameError ? ' error' : '')}>
              <input
                className="field-input"
                id="inputName"
                name="inputName"
                type="text"
                placeholder=" "
                disabled={!editing}
                onChange={(event) => {
                  setFullName(event.target.value);
                  setFullNameError(false);
                }}
                value={fullName}
              />
              <div className={'floatingInputNewLabel'}>Full Name*</div>
              {editing ? (
                startingName !== fullName ? (
                  <div className={'floatingInputEdit'}>
                    <button onClick={() => setShowBottomModal(true)}>
                      <img src={'assets/icons/icon-outlined-check-main.svg'} alt="Edit" width={24} />
                    </button>
                    <button
                      onClick={() => {
                        setFullName(startingName);
                        setEditing(false);
                      }}
                    >
                      <img src={'assets/icons/icon-outlined-exit.svg'} alt="Cancel" width={24} />
                    </button>
                  </div>
                ) : (
                  <div className={'floatingInputEdit'}>
                    <button disabled={true}>
                      <img src={'assets/icons/icon-outlined-check.svg'} alt="Edit" width={24} />
                    </button>
                    <button
                      onClick={() => {
                        setFullName(startingName);
                        setEditing(false);
                      }}
                    >
                      <img src={'assets/icons/icon-outlined-exit.svg'} alt="Cancel" width={24} />
                    </button>
                  </div>
                )
              ) : (
                <div className={'floatingInputEdit'}>
                  <button onClick={() => setEditing(true)}>
                    <img src={'assets/icons/icon-outlined-edit.svg'} alt="Edit" width={24} />
                  </button>
                </div>
              )}
            </div>
            <div className="floatingInputContainer" style={{ borderColor: '#A9A4A7' }}>
              <input
                className="field-input"
                id="inputPhone"
                name="inputPhone"
                type="text"
                placeholder=" "
                disabled={true}
                value={phoneNumber}
                style={{ color: '#A9A4A7' }}
              />
              {/*<label className="floatingInputPlaceholder" htmlFor="inputPhone">*/}
              {/*  Phone Number*/}
              {/*</label>*/}
              <div className={'floatingInputNewLabel'} style={{ color: '#A9A4A7' }}>
                Phone Number*
              </div>
            </div>
            <span className={'breakline'} />
            <h2 className={'privacy'}>Privacy</h2>
            <a href={'https://whiteclover.io/terms'} target="_blank" rel="noreferrer" className={'profileSocial'}>
              <img src={'/assets/icons/icon-lock.svg'} width={16} alt={'-'} />
              Terms & Conditions
            </a>
            <a
              href={'https://whiteclover.io/privacy-policy'}
              target="_blank"
              rel="noreferrer"
              className={'profileSocial'}
            >
              <img src={'/assets/icons/icon-lock.svg'} width={16} alt={'-'} />
              Privacy Policy
            </a>
            <span className={'breakline'} />
            <a href={'https://whiteclover.io'} target="_blank" rel="noreferrer" className={'profileSocial'}>
              <img src={'/assets/icons/icon-browser.svg'} width={16} alt={'-'} />
              WhiteClover Website
            </a>
            <a
              href={'https://www.instagram.com/whitecloverday'}
              target="_blank"
              rel="noreferrer"
              className={'profileSocial'}
            >
              <img src={'/assets/icons/icon-instagram.svg'} width={16} alt={'-'} />
              Instagram
            </a>
            <a
              href={'https://www.facebook.com/whitecloverday'}
              target="_blank"
              rel="noreferrer"
              className={'profileSocial'}
            >
              <img src={'/assets/icons/icon-facebook.svg'} width={16} alt={'-'} />
              Facebook
            </a>

            <button className="logoutBtn" onClick={() => logout()}>
              <img src={'/assets/icons/icon-sign-out.svg'} width={16} alt={'-'} />
              Log Out
            </button>
            {user?.hostForWeddingId === wedding?.id ? (
              <button
                className="logoutBtn"
                style={{ backgroundColor: greenLight }}
                onMouseDown={() => {
                  window.location.pathname = `/dashboard`;
                }}
              >
                <Icon name={'apps'} size={'s'} color={secondaryStrongAccent}></Icon>
                Host Dashboard
              </button>
            ) : (
              <></>
            )}
          </div>
        </IonContent>

        <div className="footerSectionPlain">
          <div className="profileImg">
            <IonImg src="/assets/icons/icon-filled-avatar.svg" />
            <p>Profile</p>
          </div>
          <div
            className="homeImg"
            role={'button'}
            tabIndex={0}
            onMouseDown={(e) => {
              if (e.button === 2) return;
              dispatch(setStreamFired(false));
              navigate('/home');
            }}
          >
            <img src="/assets/icons/icon-home.svg" alt={'home'} />
            <p>Home</p>
          </div>
          <div
            role={'button'}
            tabIndex={0}
            className="feedImg"
            onMouseDown={(e) => {
              if (e.button === 2) return;
              navigate('/main');
            }}
          >
            <img src="/assets/icons/icon-more.svg" alt={'main'} />
            <p>Main</p>
          </div>
        </div>
      </IonPage>
      {comingSoonModal && (
        <div className={'notification-modal'}>
          <button
            style={{ height: '85%', width: '100%', opacity: 0 }}
            onClick={() => {
              setComingSoonModal(false);
            }}
          ></button>
          <div style={{ height: '15%', display: 'flex', justifyContent: 'center' }}>
            <div className={'inputCommentContainer'} style={{ display: 'flex', justifyContent: 'center' }}>
              Coming Soon
            </div>
          </div>
        </div>
      )}
      {showBottomModal && (
        <MobileBottomModal
          title={'Are you sure you want to change your profile name?'}
          callback={() => {
            updateProfile(userToken);
            setShowBottomModal(false);
          }}
          exitCallback={() => {
            setShowBottomModal(false);
            setFullName(startingName);
            setEditing(false);
          }}
        />
      )}
    </>
  );
};

export default Profile;
