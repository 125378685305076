/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import React from 'react';

import { transliterate } from 'transliteration';
import { Imagehelper } from '../../app/helper/Imagehelper';
import { GlobalState } from '../../experienceApp/screens/Home/Home';
import { Backend } from '../../config/backend';
import { logger } from '../../config/Logger';
import firebase from 'firebase';
import {
  Account,
  ListAccountsDetailsRequest as AuthListAccountsDetailsRequest,
} from '../protobuf/v2/auth/auth_types_pb';
import {
  AllowedVisitor,
  AllowedVisitors,
  Answer,
  AttachRSVPEventToAllowedVisitorsRequest,
  AttachRSVPEventToAllowedVisitorsResponse,
  AttachTaxonomyOptionsToAllowedVisitorsRequest,
  AvailableTemplates,
  BrainStatusRequest,
  Comment,
  CommentInteraction,
  ConnectAccountRequest,
  ConnectAccountResponse,
  ConnectedAccount,
  ConnectedAccountRef,
  CouponCheckRequest,
  CouponCheckResponse,
  CreateAllowedVisitorRequest,
  CreateAnswerRequest,
  CreateAnswerResponse,
  CreateCommentInteractionRequest,
  CreateOrUpdateAttendanceForAllowedVisitorRequest,
  CreateQuestionRequest,
  CreateQuestionResponse,
  CreateRSVPEventRequest,
  CreateRSVPEventResponse,
  CreateTagRequest,
  CreateTaskRequest,
  CreateTaxonomyOptionRequest,
  CreateWeddingRequest,
  CreateWeddingWebsiteRequest,
  DeleteAllowedVisitorRequest,
  DeleteAllowedVisitorsRequest,
  DeleteCommentInteractionRequest,
  DeleteQuestionResponse,
  DeleteRSVPEventRequest,
  DeleteRSVPEventResponse,
  DeleteTaskRequest,
  DeleteTaskResponse,
  DeleteTaxonomyOptionRequest,
  DetachAllowedVisitorFromAllowedVisitorsRequest,
  DetachAllowedVisitorFromAllowedVisitorsResponse,
  DetachRSVPEventFromAllowedVisitorsRequest,
  DetachRSVPEventFromAllowedVisitorsResponse,
  DetachTaxonomyOptionsFromAllowedVisitorsRequest,
  DetachTaxonomyOptionsFromAllowedVisitorsResponse,
  DownloadGuestListRequest,
  DownloadGuestListResponse,
  DownloadRSVPGuestListRequest,
  DownloadRSVPGuestListResponse,
  Filter,
  GetAllowedVisitorRequest,
  GetEventStreamRequest,
  GetEventStreamResponse,
  GetHostEventStreamResponse,
  GetInvitedVisitorByPhoneOrEmailRequest,
  GetInvitedVisitorByPhoneOrEmailResponse,
  GetWeddingByPathRequest,
  GetWeddingRequest,
  GlobalAvailableTemplatesRequest,
  InvitationOption,
  ListAccountsDetailsRequest,
  ListAllowedVisitorsRequest,
  ListAllPackagesRequest,
  ListAllPackagesResponse,
  ListAllWeddingsRequest,
  ListAllWeddingsResponse,
  ListAnswersRequest,
  ListAnswersResponse,
  ListConnectedAccountsRequest,
  ListNotificationsRequest,
  ListPostsRequest,
  ListQuestionsRequest,
  ListQuestionsResponse,
  ListRSVPEventsRequest,
  ListRSVPEventsResponse,
  ListTagsRequest,
  ListTasksRequest,
  ListTaxonomiesRequest,
  ListTaxonomyOptionsRequest,
  ListWeddingsRequest,
  ListWeddingWebsitesRequest,
  Location,
  Notification,
  Point,
  Post,
  PostInteraction,
  PublicAllowedVisitor,
  PublicCreateAllowedVisitorRequest,
  PublicCreateAllowedVisitorResponse,
  PublicListAllowedVisitorsResponse,
  PublicUpdateArrivalForAllowedVisitorRequest,
  Question,
  RequestPagination,
  RSVPResponded,
  SendInvitationsRequest,
  SendInvitationsResponse,
  SetNotificationDeviceRequest,
  SingleOptionChoice,
  StreamObjectUpdateAction,
  Tag,
  Task,
  TaskStatus,
  Taxonomy,
  TaxonomyOption,
  TotalAllowedVisitorsRequest,
  TotalAnswersRequest,
  TotalAnswersResponse,
  TotalAttendanceRequest,
  UpdateAllowedVisitorRequest,
  UpdateAnswerRequest,
  UpdateAnswerResponse,
  UpdateEmailInvitationWebsiteRequest,
  UpdateEmailInvitationWebsiteResponse,
  UpdateQuestionRequest,
  UpdateRSVPEventRequest,
  UpdateRSVPEventResponse,
  UpdateTaskRequest,
  UpdateWeddingRequest,
  UpdateWeddingWebsitePageRequest,
  UpdateWeddingWebsiteRequest,
  Wedding,
  WeddingWebsite,
} from '../protobuf/v2/brain/brain_types_pb';
import { ConnectError } from '@bufbuild/connect-web';
import {
  InvitationOptionInvite,
  InvitationOptionReminder,
  InvitationOptionSaveTheDate,
} from '../../constants/invitation.constants';
import cleanLocalStorage from '../../app/helper/CleanLocalStorage';

export class BrainHelper {
  private static client = Backend.brainClientV2;

  public static async statusFunc(userToken: string): Promise<boolean | undefined> {
    try {
      // create a header metadata with the auth token in
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      // send the request
      const responseMessage = await BrainHelper.client.getBrainHealthStatus(new BrainStatusRequest(), { headers });
      return responseMessage?.alive;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.statusFunc' });
      return;
    }
  }

  public static async streamEvents(
    stateRef: any,
    setState: React.Dispatch<React.SetStateAction<Readonly<GlobalState>>>,
    postRef: React.MutableRefObject<Post[] | undefined>,
    commentRef: any,
    postInteractionRef: any,
    commentInteractionRef: any,
    dispatch: any,
    setPostsReducer: any,
    setCommentsReducer: any,
    setPostInteractionReducer: any,
    setCommentInteractionReducer: any,
    userToken: string | undefined,
    weddingID: string,
    addListConnectAccounts: ActionCreatorWithOptionalPayload<ConnectedAccountRef[] | undefined, string>,
    addListAccounts: ActionCreatorWithOptionalPayload<ConnectedAccountRef[] | undefined, string>,
    connectedAccountsRef: React.MutableRefObject<ConnectedAccountRef[]>,
    accountsRef: React.MutableRefObject<ConnectedAccountRef[]>,
    setProfile: any,
    setHasNotification: any,
    setNotifications: any,
    notificationsRef: any,
    profileImagesRef: React.MutableRefObject<Map<string, string>>,
    setProfileImages: any,
    addTags: ActionCreatorWithOptionalPayload<Tag[] | undefined, string>,
    cancel: any,
  ): Promise<() => void | null> {
    try {
      if (cancel) cancel();
      logger.debug({ message: 'Stream is fired!', functionName: 'brainHelper.streamEvents' });
      // create a header metadata with the auth token in
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);

      const marriage = new GetEventStreamRequest();
      marriage.weddingId = weddingID;
      // const stream = await BrainHelper.client.getEventStream(marriage, { headers });
      const cancelStream = Backend.brainClientV2Callback.getEventStream(
        marriage,
        async (rsp: GetEventStreamResponse) => {
          const value = rsp?.object?.value;
          const action = rsp?.action;
          const type = rsp?.object?.case;

          await logger.debug({
            message: `DATA inside Stream received! Type: ${type}`,
            functionName: `streamEvents`,
          });
          if (stateRef?.current.userToken === '') {
            logger.debug({ message: 'Stream is cancelled! userToke is empty' });
            cancelStream();
          }

          switch (type) {
            case 'connectedAccount': {
              const message = value as ConnectedAccountRef;
              const mapProfileImages = new Map(profileImagesRef.current);

              const account = message;
              const image = await Imagehelper.returnFetchImage(account?.imageProfileUrl, userToken, 150);
              if (account?.accountId || account?.accountPhoneId)
                mapProfileImages.set(account?.accountId || account?.accountPhoneId, image);

              dispatch(setProfileImages(mapProfileImages));
              dispatch(addListAccounts([account] || []));
              dispatch(addListConnectAccounts([account || new ConnectedAccountRef()]));
              break;
            }
            case 'post': {
              const message = value as Post;
              let currentPosts = postRef.current?.slice();
              if (Array.isArray(currentPosts))
                if (action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate)
                  currentPosts.unshift(message || new Post());
                else if (action === StreamObjectUpdateAction.StreamObjectUpdateActionUpdate) {
                  const index = currentPosts.findIndex((p) => p?.id === message?.id);
                  const oldPost = currentPosts[index];
                  const receivedPost = message || new Post();
                  // keep file urls
                  receivedPost.files = oldPost.files;
                  currentPosts[index] = receivedPost;
                } else if (action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove) {
                  currentPosts = currentPosts.filter((p) => p?.id !== message?.id);
                }
              dispatch(setPostsReducer(currentPosts));
              break;
            }
            case 'comment': {
              const message = value as Comment;
              let currentComments = commentRef.current.slice();
              if (Array.isArray(currentComments)) {
                if (action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate) {
                  currentComments.unshift(message);
                } else if (action === StreamObjectUpdateAction.StreamObjectUpdateActionUpdate) {
                  const index = currentComments.findIndex((c) => c?.id === message?.id);
                  currentComments[index] = message;
                } else if (action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove) {
                  currentComments = currentComments.filter((c) => c?.id !== message?.id);
                }
              }
              dispatch(setCommentsReducer(currentComments));
              break;
            }
            case 'postInteraction': {
              const message = value as PostInteraction;
              const currentPostInteractions = postInteractionRef.current.slice();
              if (Array.isArray(currentPostInteractions)) {
                if (action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate) {
                  currentPostInteractions.push(message);
                  dispatch(setPostInteractionReducer(currentPostInteractions));
                } else if (
                  action === StreamObjectUpdateAction.StreamObjectUpdateActionArchive ||
                  action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove
                ) {
                  const newList = currentPostInteractions.filter((x) => {
                    return x?.id != message?.id;
                  });
                  dispatch(setPostInteractionReducer(newList));
                }
              }
              break;
            }
            case 'notification': {
              const message = value as Notification;
              let nots = notificationsRef.current.slice();
              if (action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate) nots.push(message);
              else if (action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove)
                nots = nots.filter((not: Notification) => not?.id !== message?.id);
              dispatch(setHasNotification(nots.length > 0));
              // TODO: do we need the following dispatch
              dispatch(setNotifications(nots));
              break;
            }
            case 'commentInteraction': {
              const message = value as CommentInteraction;
              const currentCommentInteractions = commentInteractionRef.current.slice();
              if (Array.isArray(currentCommentInteractions)) {
                if (action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate) {
                  currentCommentInteractions.push(message);
                  dispatch(setCommentInteractionReducer(currentCommentInteractions));
                } else if (
                  action === StreamObjectUpdateAction.StreamObjectUpdateActionArchive ||
                  action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove
                ) {
                  const newList = currentCommentInteractions.filter((x) => {
                    return x?.id != message?.id;
                  });
                  dispatch(setCommentInteractionReducer(newList));
                }
              }
              break;
            }
            case 'tag': {
              const message = value as Tag;
              dispatch(addTags([message || new Tag()]));
              break;
            }
            case 'noop': {
              console.log('NOOP check');
              break;
            }
            default: {
              logger.debug({
                message: `non of our supported cases used for type: ${type}`,
                functionName: 'BrainHelper.streamEvents',
              });
              break;
            }
          }
        },
        (error: ConnectError | undefined) => {
          logger.error({
            message: `Stream END caught. Error: ${JSON.stringify(error, (_, v) =>
              typeof v === 'bigint' ? v.toString() : v,
            )}`,
          });
          // code 16 means unauthorized
          // TODO: seems like we dont need the following code?
          if (error?.code !== 16) {
            if (error?.code === 7) {
              logger.info({ message: 'clear local storage status code 7', functionName: 'BrainHelper.streamEvents' });
              firebase.auth().signOut();
              cleanLocalStorage();
              window.location.pathname = '/';
            }

            setState({
              ...stateRef.current,
              status: stateRef.current.status,
              streamEvents: stateRef.current.streamEvents.concat(`${error?.code} ${error?.details}`),
            });
          }
          // until here

          // if we got an error then run another call for stream after 10 seconds
          if (
            error &&
            JSON.stringify(error, (_, v) => (typeof v === 'bigint' ? v.toString() : v)) !== 'cancelling stream'
          )
            setTimeout(function () {
              BrainHelper.streamEvents(
                stateRef,
                setState,
                postRef,
                commentRef,
                postInteractionRef,
                commentInteractionRef,
                dispatch,
                setPostsReducer,
                setCommentsReducer,
                setPostInteractionReducer,
                setCommentInteractionReducer,
                userToken,
                weddingID,
                addListConnectAccounts,
                addListAccounts,
                connectedAccountsRef,
                accountsRef,
                setProfile,
                setHasNotification,
                setNotifications,
                notificationsRef,
                profileImagesRef,
                setProfileImages,
                addTags,
                cancelStream,
              );
            }, 10000);

          logger.debug({ message: 'Stream is cancelled!', functionName: 'BrainHelper.streamEvents' });
          cancelStream();
        },
        { headers },
      );

      return cancelStream;
    } catch (e) {
      logger.error({ message: `Stream died. Error: ${e}`, functionName: 'BrainHelper.streamEvents' });
      return () => {
        'no stream avaialble';
      };
    }
  }

  public static async streamHostEvents(
    dispatch: any,
    stateRef: any,
    setState: React.Dispatch<React.SetStateAction<Readonly<GlobalState>>>,
    userToken: string | undefined,
    weddingID: string,
    addInvitedVisitors: any,
    addTaxonomies: any,
    addTaxonomyOptions: any,
    addQuestions: any,
    addAnswers: any,
    removeInvitedVisitors: any,
    removeTaxonomies: any,
    removeTaxonomyOptions: any,
    removeQuestions: any,
    removeAnswers: any,
  ): Promise<() => void | null> {
    try {
      logger.debug({ message: 'Stream is fired!', functionName: 'brainHelper.streamHostEvents' });
      // create a header metadata with the auth token in
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);

      const marriage = new GetEventStreamRequest();
      marriage.weddingId = weddingID;
      const cancelStream = Backend.brainClientV2Callback.getHostEventStream(
        marriage,
        async (rsp: GetHostEventStreamResponse) => {
          const value = rsp?.object?.value;
          const action = rsp?.action;
          const type = rsp?.object?.case;
          await logger.debug({
            message: `DATA inside Stream received! Type: ${type}`,
            functionName: `streamHostEvents`,
          });
          if (stateRef?.current.userToken === '') {
            logger.debug({ message: 'Stream is cancelled! userToke is empty' });
            cancelStream();
          }

          switch (type) {
            case 'allowedVisitor': {
              const message = value as AllowedVisitor;
              if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionArchive
              )
                dispatch(removeInvitedVisitors([message]));
              else if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionUpdate ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate
              )
                dispatch(addInvitedVisitors([message]));
              break;
            }
            case 'allowedVisitors': {
              const message = value as AllowedVisitors;
              if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionArchive
              )
                dispatch(removeInvitedVisitors(message));
              else if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionUpdate ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate
              )
                dispatch(addInvitedVisitors(message.allowedVisitors));
              break;
            }
            case 'taxonomy': {
              const message = value as Taxonomy;
              if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionArchive
              )
                dispatch(removeTaxonomies([message]));
              else if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionUpdate ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate
              )
                dispatch(addTaxonomies([message]));
              break;
            }
            case 'taxonomyOption': {
              const message = value as TaxonomyOption;
              if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionArchive
              )
                dispatch(removeTaxonomyOptions([message]));
              else if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionUpdate ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate
              )
                dispatch(addTaxonomyOptions([message]));
              break;
            }
            case 'question': {
              const message = value as Question;
              if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionArchive
              )
                dispatch(removeQuestions([message]));
              else if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionUpdate ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate
              )
                dispatch(addQuestions([message]));
              break;
            }
            case 'answer': {
              const message = value as Answer;
              if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionRemove ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionArchive
              )
                dispatch(removeAnswers([message]));
              else if (
                action === StreamObjectUpdateAction.StreamObjectUpdateActionUpdate ||
                action === StreamObjectUpdateAction.StreamObjectUpdateActionCreate
              )
                dispatch(addAnswers([message]));
              break;
            }
            case 'noop': {
              console.log('NOOP check');
              break;
            }
            default: {
              logger.debug({
                message: `non of our supported cases used for type: ${type}`,
                functionName: 'BrainHelper.streamHostEvents',
              });
              break;
            }
          }
        },
        (error: ConnectError | undefined) => {
          logger.error({
            message: `Stream END caught. Status: ${JSON.stringify(error, (_, v) =>
              typeof v === 'bigint' ? v.toString() : v,
            )}`,
          });
          // code 16 means unauthorized
          // TODO: seems like we dont need the following code?
          if (error?.code !== 16) {
            if (error?.code === 7) {
              logger.info({
                message: 'clear local storage status code 7',
                functionName: 'BrainHelper.streamHostEvents',
              });
              firebase.auth().signOut();
              cleanLocalStorage();
              window.location.pathname = '/dashboard';
            }

            setState({
              ...stateRef.current,
              status: stateRef.current.status,
              streamEvents: stateRef.current.streamEvents.concat(`${error?.code} ${error?.details}`),
            });
          }
          // until here

          // if we got an error then run another call for stream after 10 seconds
          setTimeout(function () {
            BrainHelper.streamHostEvents(
              dispatch,
              stateRef,
              setState,
              userToken,
              weddingID,
              addInvitedVisitors,
              addTaxonomies,
              addTaxonomyOptions,
              addQuestions,
              addAnswers,
              removeInvitedVisitors,
              removeTaxonomies,
              removeTaxonomyOptions,
              removeQuestions,
              removeAnswers,
            );
          }, 10000);

          logger.debug({ message: 'Stream is cancelled!', functionName: 'BrainHelper.streamHostEvents' });
          cancelStream();
        },
        { headers },
      );

      return cancelStream;
    } catch (e) {
      logger.error({ message: `Stream died. Error: ${e}`, functionName: 'BrainHelper.streamHostEvents' });
      return () => {
        'no available host stream';
      };
    }
  }

  public static async setNotificationDevice(userToken: string, playerID: string, email: string): Promise<void> {
    try {
      // create a header metadata with the auth token in
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      // send the request
      const request = new SetNotificationDeviceRequest();
      request.devicePlayerId = playerID;
      request.email = email;
      await BrainHelper.client.setNotificationDevice(request, { headers });
      return;
    } catch (e: any) {
      logger.error({ message: e, functionName: 'BrainHelper.setNotificationDevice' });
      return;
    }
  }

  public static async getWedding(
    userToken: string,
    marriageCode: string,
    dispatch: any,
    stateRef: any,
    setState: React.Dispatch<React.SetStateAction<Readonly<GlobalState>>> | null,
    setWedding: any,
  ): Promise<Wedding> {
    try {
      const weddingByPathRequest = new GetWeddingByPathRequest();
      weddingByPathRequest.path = transliterate(marriageCode);
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.getWeddingByPath(weddingByPathRequest, { headers });
      await dispatch(setWedding(responseMessage?.wedding));
      if (setState)
        await setState({
          ...stateRef.current,
          wedding: responseMessage?.wedding,
        });
      return responseMessage?.wedding || new Wedding();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.getWedding' });
      throw e;
    }
  }

  public static async createWedding(
    userToken: string,
    marriageCode: string,
    name: string,
    year: number,
    phone: string,
    email: string,
    rsvpRequest: CreateRSVPEventRequest | undefined,
    socialEventType: string | undefined,
    coupon: string | undefined,
    packageCode: string | undefined,
  ): Promise<Wedding> {
    try {
      const weddingRequest = new CreateWeddingRequest();
      weddingRequest.path = transliterate(marriageCode);
      weddingRequest.name = name;
      weddingRequest.year = year;
      weddingRequest.phone = phone;
      weddingRequest.email = email;
      weddingRequest.socialEventType = socialEventType ?? 'wedding';
      if (coupon) weddingRequest.coupon = coupon;
      if (packageCode) weddingRequest.package = packageCode;
      if (rsvpRequest) weddingRequest.rsvpRequest = rsvpRequest;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const response = await Backend.brainClientV2.createWedding(weddingRequest, { headers });
      return response?.wedding || new Wedding();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createWedding' });
    }
    return new Wedding();
  }

  public static async updateWedding(
    userToken: string,
    weddingID: string,
    marriageCode: string,
    name: string,
    year: number,
    logoURL: string,
    smallLogoUrl: string,
    baseColor: string,
  ): Promise<Wedding> {
    try {
      const weddingRequest = new UpdateWeddingRequest();
      weddingRequest.id = weddingID;
      weddingRequest.path = transliterate(marriageCode);
      weddingRequest.name = name;
      weddingRequest.year = year;
      weddingRequest.logoUrl = logoURL;
      weddingRequest.smallLogoUrl = smallLogoUrl;
      weddingRequest.baseColor = baseColor;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const response = await Backend.brainClientV2.updateWedding(weddingRequest, { headers });
      return response?.wedding || new Wedding();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updateWedding' });
    }
    return new Wedding();
  }

  public static async getInvitedVisitorByPhoneOrEmail(
    userToken: string,
    weddingID: string,
    phone: string,
    email: string,
  ): Promise<GetInvitedVisitorByPhoneOrEmailResponse | undefined> {
    try {
      const getInvitedVisitorByPhoneOrEmailRequest = new GetInvitedVisitorByPhoneOrEmailRequest();
      getInvitedVisitorByPhoneOrEmailRequest.phone = phone;
      getInvitedVisitorByPhoneOrEmailRequest.email = email;
      getInvitedVisitorByPhoneOrEmailRequest.weddingId = weddingID;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.getInvitedVisitorByPhoneOrEmail(getInvitedVisitorByPhoneOrEmailRequest, {
        headers,
      });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.getInvitedVisitorByPhoneOrEmail' });
      // throw e;
      return undefined;
    }
  }

  public static async connectAccount(
    userToken: string,
    weddingID: string,
    accountID: string,
    dispatch: any,
    stateRef: any,
    setState: React.Dispatch<React.SetStateAction<Readonly<GlobalState>>> | null,
    userRef: any,
    setConnectedAccount: any,
    setJwt: any,
  ): Promise<ConnectAccountResponse | null> {
    try {
      const connectAccountRequest = new ConnectAccountRequest();
      connectAccountRequest.weddingId = weddingID;
      connectAccountRequest.accountId = accountID;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage: ConnectAccountResponse | null = await Backend.brainClientV2.connectAccount(
        connectAccountRequest,
        { headers },
      );
      await dispatch(setConnectedAccount(responseMessage?.connectedAccount));
      logger.debug({ message: `new token: ${responseMessage?.jwtToken}` });
      await dispatch(setJwt(responseMessage?.jwtToken));
      return responseMessage;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.connectAccount' });
    }
    return null;
  }

  public static async listRSVPEvents(
    userToken: string,
    weddingID: string,
    dispatch?: any,
    setRSVPEvents?: any,
  ): Promise<ListRSVPEventsResponse | null> {
    try {
      const listRSVPEventsRequest = new ListRSVPEventsRequest();
      listRSVPEventsRequest.weddingId = weddingID;

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage: ListRSVPEventsResponse | null = await Backend.brainClientV2.listRSVPEvents(
        listRSVPEventsRequest,
        { headers },
      );
      // await dispatch(setConnectedAccount(responseMessage?.getConnectedAccount()?));
      if (dispatch && responseMessage && responseMessage.rsvpEvents && setRSVPEvents)
        dispatch(setRSVPEvents(responseMessage.rsvpEvents));
      return responseMessage;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.listRSVPEvents' });
      throw e;
    }
    return null;
  }

  public static async createRsvpEvent(
    userToken: string,
    weddingID: string,
    eventName: string,
    startTimestamp: string,
    endTimestamp: string,
    location: Location,
    isDefault: boolean,
    isPrivate: boolean,
    icon: string,
    description: string,
  ): Promise<CreateRSVPEventResponse | null> {
    try {
      const createRSVPEventRequest = new CreateRSVPEventRequest();
      createRSVPEventRequest.weddingId = weddingID;
      createRSVPEventRequest.name = eventName;
      createRSVPEventRequest.startTimestamp = startTimestamp;
      createRSVPEventRequest.endTimestamp = endTimestamp;
      const locationRequest = new Location();
      locationRequest.address = location.address;
      const point = new Point();
      point.latitude = location.point?.latitude || 0;
      point.longitude = location.point?.longitude || 0;
      locationRequest.point = point;
      locationRequest.externalQuickCode = location.externalQuickCode;
      createRSVPEventRequest.location = locationRequest;
      createRSVPEventRequest.isDefault = isDefault;
      createRSVPEventRequest.isPrivate = isPrivate;
      createRSVPEventRequest.icon = icon;
      createRSVPEventRequest.description = description;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.createRSVPEvent(createRSVPEventRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createRsvpEvent' });
    }
    return null;
  }

  public static async updateRsvpEvent(
    userToken: string,
    id: string,
    weddingId: string,
    eventName: string,
    startTimestamp: string,
    endTimestamp: string,
    location: Location,
    isDefault: boolean,
    isPrivate: boolean,
    icon: string,
    description: string,
    kindlyReminder: string,
  ): Promise<UpdateRSVPEventResponse | null> {
    try {
      const updateRSVPEventRequest = new UpdateRSVPEventRequest();
      updateRSVPEventRequest.id = id;
      updateRSVPEventRequest.weddingId = weddingId;
      updateRSVPEventRequest.name = eventName;
      updateRSVPEventRequest.isDefault = isDefault;
      updateRSVPEventRequest.isPrivate = isPrivate;
      updateRSVPEventRequest.startTimestamp = startTimestamp;
      updateRSVPEventRequest.endTimestamp = endTimestamp;
      updateRSVPEventRequest.kindlyReminder = kindlyReminder;
      const locationRequest = new Location();
      locationRequest.address = location.address;
      const point = new Point();
      point.latitude = location.point?.latitude || 0;
      point.longitude = location.point?.longitude || 0;
      locationRequest.point = point;
      locationRequest.externalQuickCode = location.externalQuickCode;
      locationRequest.name = location.name;
      updateRSVPEventRequest.location = locationRequest;
      updateRSVPEventRequest.icon = icon;
      updateRSVPEventRequest.description = description;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.updateRSVPEvent(updateRSVPEventRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updateRsvpEvent' });
    }
    return null;
  }

  public static async deleteRsvpEvent(
    userToken: string,
    id: string,
    weddingId: string,
  ): Promise<DeleteRSVPEventResponse | null> {
    try {
      const deleteRSVPEventRequest = new DeleteRSVPEventRequest();
      deleteRSVPEventRequest.id = id;
      deleteRSVPEventRequest.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.deleteRSVPEvent(deleteRSVPEventRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.deleteRsvpEvent' });
    }
    return null;
  }

  public static async listQuestions(
    userToken: string,
    rsvpEventId: string,
  ): Promise<ListQuestionsResponse | undefined> {
    try {
      const listRSVPEventsRequest = new ListQuestionsRequest();
      listRSVPEventsRequest.rsvpEventId = rsvpEventId;

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.listQuestions(listRSVPEventsRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.listQuestions' });
    }
    return undefined;
  }

  public static async createQuestion(
    userToken: string,
    rsvpEventId: string,
    weddingId: string,
    question: string,
    allowMultiple: boolean,
    allowEdit: boolean,
    isFreeText: boolean,
    singleOptions: string[],
  ): Promise<CreateQuestionResponse | null> {
    try {
      const createQuestionRequest = new CreateQuestionRequest();
      createQuestionRequest.weddingId = weddingId;
      createQuestionRequest.belongsToRsvpEventId = rsvpEventId;
      createQuestionRequest.question = question;
      createQuestionRequest.allowMultiple = allowMultiple;
      createQuestionRequest.allowEditOptions = allowEdit;
      createQuestionRequest.isFreeText = isFreeText;
      const singleOptionChoicesList = new Array<SingleOptionChoice>();
      for (const option of singleOptions) {
        if (option !== '') {
          const singleOption = new SingleOptionChoice();
          singleOption.option = option;
          singleOption.dateCreated = new Date().toISOString();
          singleOptionChoicesList.push(singleOption);
        }
      }
      createQuestionRequest.singleOptionChoices = singleOptionChoicesList;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.createQuestion(createQuestionRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createQuestion' });
    }
    return null;
  }

  public static async updateQuestion(
    userToken: string,
    id: string,
    weddingId: string,
    question: string,
    allowMultiple: boolean,
    allowEdit: boolean,
    isFreeText: boolean,
    singleOptions: string[],
    rsvpId: string,
  ): Promise<CreateQuestionResponse | null> {
    try {
      const updateQuestionRequest = new UpdateQuestionRequest();
      updateQuestionRequest.id = id;
      updateQuestionRequest.weddingId = weddingId;
      updateQuestionRequest.question = question;
      updateQuestionRequest.allowMultiple = allowMultiple;
      updateQuestionRequest.allowEditOptions = allowEdit;
      updateQuestionRequest.isFreeText = isFreeText;
      updateQuestionRequest.belongsToRsvpEventId = rsvpId;
      const singleOptionChoicesList = new Array<SingleOptionChoice>();
      for (const option of singleOptions) {
        if (option !== '') {
          const singleOption = new SingleOptionChoice();
          singleOption.option = option;
          singleOption.dateCreated = new Date().toISOString();
          singleOptionChoicesList.push(singleOption);
        }
      }
      updateQuestionRequest.singleOptionChoices = singleOptionChoicesList;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.updateQuestion(updateQuestionRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updateQuestion' });
    }
    return null;
  }

  public static async deleteQuestion(
    userToken: string,
    id: string,
    weddingId: string,
  ): Promise<DeleteQuestionResponse | null> {
    try {
      const deleteRSVPEventRequest = new DeleteRSVPEventRequest();
      deleteRSVPEventRequest.id = id;
      deleteRSVPEventRequest.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.deleteQuestion(deleteRSVPEventRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.deleteQuestion' });
    }
    return null;
  }

  public static async listAnswers(userToken: string, invitedVisitorId: string): Promise<ListAnswersResponse | null> {
    try {
      const listAnswersRequest = new ListAnswersRequest();
      listAnswersRequest.invitedVisitorId = invitedVisitorId;

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage: ListAnswersResponse | null = await Backend.brainClientV2.listAnswers(listAnswersRequest, {
        headers,
      });
      // await dispatch(setConnectedAccount(responseMessage?.getConnectedAccount()?));
      return responseMessage;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.listAnswers' });
    }
    return null;
  }

  public static async totalAnswers(userToken: string, questionId: string): Promise<TotalAnswersResponse | undefined> {
    try {
      const totalAnswersRequest = new TotalAnswersRequest();
      totalAnswersRequest.questionId = questionId;

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage: TotalAnswersResponse | null = await Backend.brainClientV2.totalAnswers(
        totalAnswersRequest,
        { headers },
      );
      // await dispatch(setConnectedAccount(responseMessage?.getConnectedAccount()?));
      return responseMessage;
    } catch (e) {
      logger.error({ message: e });
    }
    return undefined;
  }

  public static async createAnswer(
    userToken: string,
    visitorId: string,
    weddingId: string,
    questionId: string,
    freeText: string,
    singleOptions: Array<SingleOptionChoice>,
  ): Promise<CreateAnswerResponse | null> {
    try {
      const createAnswerRequest = new CreateAnswerRequest();
      createAnswerRequest.weddingId = weddingId;
      createAnswerRequest.questionId = questionId;
      createAnswerRequest.invitedVisitorId = visitorId;
      createAnswerRequest.freeText = freeText;
      const singleOptionChoicesList = new Array<SingleOptionChoice>();
      for (const option of singleOptions) {
        if (option.option !== '') {
          const singleOption = new SingleOptionChoice();
          singleOption.option = option.option;
          singleOption.dateCreated = new Date().toISOString();
          singleOptionChoicesList.push(singleOption);
        }
      }
      createAnswerRequest.singleOptionChoices = singleOptionChoicesList;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.createAnswer(createAnswerRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createAnswer' });
    }
    return null;
  }

  public static async updateAnswer(
    userToken: string,
    id: string,
    weddingId: string,
    visitorId: string,
    questionId: string,
    freeText: string,
    singleOptions: Array<SingleOptionChoice>,
  ): Promise<UpdateAnswerResponse | null> {
    try {
      const updateAnswerRequest = new UpdateAnswerRequest();
      updateAnswerRequest.id = id;
      updateAnswerRequest.weddingId = weddingId;
      updateAnswerRequest.questionId = questionId;
      updateAnswerRequest.invitedVisitorId = visitorId;
      updateAnswerRequest.freeText = freeText;
      const singleOptionChoicesList = new Array<SingleOptionChoice>();
      for (const option of singleOptions) {
        if (option.option !== '') {
          const singleOption = new SingleOptionChoice();
          singleOption.option = option.option;
          singleOption.dateCreated = new Date().toISOString();
          singleOptionChoicesList.push(singleOption);
        }
      }
      updateAnswerRequest.singleOptionChoices = singleOptionChoicesList;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.updateAnswer(updateAnswerRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updateAnswer' });
    }
    return null;
  }

  public static async getAllowedVisitor(
    jwt: string | undefined,
    id: string,
    // dispatch: any,
  ): Promise<{ allowedVisitor: AllowedVisitor }> {
    try {
      const getAllowedVisitorRequest = new GetAllowedVisitorRequest();
      getAllowedVisitorRequest.id = id;

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.getAllowedVisitor(getAllowedVisitorRequest, { headers });
      const allowedVisitor = responseMessage?.allowedVisitor ?? new AllowedVisitor();
      logger.info({ message: responseMessage });

      return {
        allowedVisitor: allowedVisitor,
      };
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.getAllowedVisitor' });
    }
    return {
      allowedVisitor: new AllowedVisitor(),
    };
  }

  public static async getAllowedVisitors(
    jwt: string | undefined,
    weddingID: string,
    query: string,
    filters?: {
      attendance: 0 | 1 | 2 | 3;
      status: 0 | 1 | 2 | 3 | 4;
      selectedTaxonomies: TaxonomyOption[];
    },
    isHost?: boolean,
    rsvpId?: string,
    page?: number,
    limit?: number,
    dispatch?: any,
    setInvitedVisitors?: any,
  ): Promise<{ allowedVisitors: AllowedVisitor[]; total: number }> {
    try {
      const listAllowedVisitorsRequest = new ListAllowedVisitorsRequest();
      if (rsvpId !== undefined) listAllowedVisitorsRequest.rsvpEventId = rsvpId;
      else listAllowedVisitorsRequest.weddingId = weddingID;
      listAllowedVisitorsRequest.query = query;
      if (filters !== undefined) {
        listAllowedVisitorsRequest.filter = new Filter();
        listAllowedVisitorsRequest.filter.attendance = filters.attendance || 0;
        listAllowedVisitorsRequest.filter.status = filters.status || 0;
        listAllowedVisitorsRequest.filter.taxonomyOptions = filters.selectedTaxonomies.map((s) => s.key);
      }
      if (page !== undefined && limit !== undefined) {
        const paginationRequest = new RequestPagination();
        paginationRequest.currentPage = page as unknown as bigint;
        paginationRequest.pageLimit = limit as unknown as bigint;
        listAllowedVisitorsRequest.paging = paginationRequest;
      }
      if (isHost != undefined) listAllowedVisitorsRequest.isHost = isHost;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.listAllowedVisitors(listAllowedVisitorsRequest, { headers });

      const allowedVisitors: AllowedVisitor[] = [];
      responseMessage?.allowedVisitors.map((allowedVisitor) => {
        allowedVisitors.push(allowedVisitor);
      });
      if (dispatch && setInvitedVisitors && allowedVisitors.length > 0) dispatch(setInvitedVisitors(allowedVisitors));

      return {
        allowedVisitors: allowedVisitors,
        total: Number(responseMessage?.metadata?.pagination?.totalCount || 0),
      };
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.getAllowedVisitors' });
    }
    return {
      allowedVisitors: [],
      total: 0,
    };
  }

  public static async getPublicAllowedVisitors(
    weddingID: string,
    query: string,
    rsvpId?: string,
    page?: number,
    limit?: number,
    // dispatch: any,
  ): Promise<PublicListAllowedVisitorsResponse | null> {
    try {
      const listAllowedVisitorsRequest = new ListAllowedVisitorsRequest();
      if (rsvpId !== undefined) listAllowedVisitorsRequest.rsvpEventId = rsvpId;
      listAllowedVisitorsRequest.weddingId = weddingID;
      listAllowedVisitorsRequest.query = query;
      listAllowedVisitorsRequest.isPublic = true;
      if (page !== undefined && limit !== undefined) {
        const paginationRequest = new RequestPagination();
        paginationRequest.currentPage = page as unknown as bigint;
        paginationRequest.pageLimit = limit as unknown as bigint;
        listAllowedVisitorsRequest.paging = paginationRequest;
      }
      const headers = new Headers();
      const responseMessage = await Backend.brainClientV2.publicListAllowedVisitors(listAllowedVisitorsRequest, {
        headers,
      });

      logger.info({ message: responseMessage, functionName: 'BrainHelper.getPublicAllowedVisitors' });

      return responseMessage;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.getPublicAllowedVisitors' });
    }
    return null;
  }

  public static async getPublicWedding(weddingId: string, weddingPath?: string): Promise<Wedding> {
    try {
      const weddingRequest = new GetWeddingRequest();
      weddingRequest.id = weddingId;
      if (weddingPath) weddingRequest.path = weddingPath;

      const responseMessage = await Backend.brainClientV2.publicGetWedding(weddingRequest);

      return responseMessage?.wedding || new Wedding();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.getPublicWedding' });
      throw e;
    }
  }

  public static async createPublicAllowedVisitor(
    visitors: Array<CreateAllowedVisitorRequest>,
    // dispatch: any,
  ): Promise<PublicCreateAllowedVisitorResponse | null> {
    try {
      const publicAllowedVisitorRequest = new PublicCreateAllowedVisitorRequest();

      publicAllowedVisitorRequest.request = visitors;
      console.log(publicAllowedVisitorRequest.request);
      console.log(visitors);
      const responseMessage = await Backend.brainClientV2.publicCreateAllowedVisitor(publicAllowedVisitorRequest);

      return responseMessage || PublicCreateAllowedVisitorResponse;
    } catch (e) {
      console.log(e);
      logger.error({ message: e, functionName: 'BrainHelper.createPublicAllowedVisitor' });
      throw e;
    }
    return new PublicCreateAllowedVisitorResponse();
  }

  public static async createAllowedVisitor(
    jwt: string | undefined,
    weddingID: string,
    email: string,
    phone: string,
    firstName: string,
    lastName: string,
    customSaveInvitationMessage: string,
    customSaveTheDateMessage: string,
    maxAllowedPlus: number,
    taxonomyOptionsIds: Array<string>,
    RsvpEventsIdsList: Array<string>,
    isHost: boolean,
    isModerator: boolean,
    groupWithVisitorIds?: Array<string>,
    dispatch?: any,
    addInvitedVisitors?: any,
  ): Promise<AllowedVisitor | string> {
    try {
      const createAllowedVisitorRequest = new CreateAllowedVisitorRequest();
      createAllowedVisitorRequest.weddingId = weddingID;
      createAllowedVisitorRequest.email = email;
      createAllowedVisitorRequest.phone = phone;
      createAllowedVisitorRequest.firstName = firstName;
      createAllowedVisitorRequest.lastName = lastName;
      createAllowedVisitorRequest.maxAllowedPlusOnes = maxAllowedPlus as unknown as bigint;
      createAllowedVisitorRequest.customSaveInvitationMessage = customSaveInvitationMessage;
      createAllowedVisitorRequest.customSaveTheDateMessage = customSaveTheDateMessage;
      createAllowedVisitorRequest.taxonomyOptionIds = taxonomyOptionsIds;
      createAllowedVisitorRequest.rsvpEventIds = RsvpEventsIdsList;
      createAllowedVisitorRequest.isHost = isHost;
      createAllowedVisitorRequest.isModerator = isModerator;
      if (groupWithVisitorIds) createAllowedVisitorRequest.groupedAllowedVisitorsIds = groupWithVisitorIds;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.createAllowedVisitor(createAllowedVisitorRequest, {
        headers,
      });
      if (dispatch && addInvitedVisitors && responseMessage && responseMessage.allowedVisitor)
        dispatch(addInvitedVisitors(responseMessage.allowedVisitor));
      return responseMessage?.allowedVisitor || new AllowedVisitor();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createAllowedVisitor' });
      if (e) return e.toString();
    }
    return new AllowedVisitor();
  }

  public static async getConnectedAccounts(
    jwt: string | undefined,
    weddingID: string,
    dispatch: any,
    setListConnectAccounts: any,
  ): Promise<ConnectedAccount[]> {
    try {
      logger.debug({ message: `TOKEN: ${jwt}` });
      const connectAccountRequest = new ListConnectedAccountsRequest();
      connectAccountRequest.weddingId = weddingID;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.listConnectedAccounts(connectAccountRequest, { headers });
      const connectedAccounts: ConnectedAccount[] = [];
      responseMessage?.connectedAccounts.map((c) => {
        connectedAccounts.push(c);
      });
      await dispatch(setListConnectAccounts(connectedAccounts));
      return connectedAccounts;
    } catch (e) {
      logger.error({ message: e, functionName: 'getConnectedAccounts' });
      throw e;
    }
    return [];
  }

  public static async listConnectedAccountDetails(
    jwt: string | undefined,
    connectedAccountIds: string[],
    dispatch: any,
    setListConnectAccounts: any,
  ): Promise<ConnectedAccountRef[]> {
    try {
      logger.debug({ message: `TOKEN: ${jwt}` });
      const connectAccountRequest = new ListAccountsDetailsRequest();
      connectAccountRequest.connectedAccountIds = connectedAccountIds;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.listAccountsDetails(connectAccountRequest, { headers });
      const connectedAccounts: ConnectedAccountRef[] = [];
      responseMessage?.connectedAccounts.map((c) => {
        connectedAccounts.push(c);
      });
      if (setListConnectAccounts) await dispatch(setListConnectAccounts(connectedAccounts));
      return connectedAccounts;
    } catch (e) {
      logger.error({ message: e, functionName: 'getConnectedAccounts' });
      throw e;
    }
    return [];
  }

  //TODO: BRING LESS PHOTOS WITH A LOOP
  public static async getListPostsOrderedByUpdatedTimeDesc(
    page: number,
    jwt: string | undefined,
    weddingFeedID: string,
    postsList: Post[],
    commentsList: Comment[],
    postInteractionsList: PostInteraction[],
    commentsInteractionsList: CommentInteraction[],
    postRef: React.MutableRefObject<Post[] | undefined>,
    dispatch: any,
    setPostsReducer: any,
    setCommentsReducer: any,
    setPostInteractionReducer: any,
    setCommentInteractionReducer: any,
    setMaxNumberOfPosts: any,
  ): Promise<Post[]> {
    try {
      const postsRequest = new ListPostsRequest();
      postsRequest.feedId = weddingFeedID;
      postsRequest.full = true;
      const pagingRequest = new RequestPagination();
      pagingRequest.currentPage = page as unknown as bigint;
      pagingRequest.pageLimit = 10 as unknown as bigint;
      postsRequest.paging = pagingRequest;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.listPostsOrderedByUpdatedTimeDesc(postsRequest, { headers });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const tempPosts: Post[] = postsList.slice();
      const comments: Comment[] = commentsList.slice();
      const postInteractions: PostInteraction[] = postInteractionsList.slice();
      const commentInteractions: CommentInteraction[] = commentsInteractionsList.slice();
      const currentPosts = postRef.current?.slice();
      if (Array.isArray(currentPosts)) {
        responseMessage?.posts.map((p) => {
          const index = currentPosts.findIndex((po) => po?.id === p?.id);
          if (index >= 0) {
            currentPosts[index] = p;
          } else {
            currentPosts.push(p);
          }

          p?.comments.map((c) => {
            const index = comments.findIndex((co) => co?.id === c?.id);
            if (index >= 0) {
              comments[index] = c;
            } else {
              comments.push(c);
            }

            // get comment interactions
            c?.interactions.map((c) => {
              const index = commentInteractions.findIndex((co) => co?.id === c?.id);
              if (index >= 0) {
                commentInteractions[index] = c;
              } else {
                commentInteractions.push(c);
              }
            });
          });

          p?.interactions.map((c) => {
            const index = postInteractions.findIndex((co) => co?.id === c?.id);
            if (index >= 0) {
              postInteractions[index] = c;
            } else {
              postInteractions.push(c);
            }
          });
        });

        dispatch(setPostsReducer(currentPosts));
        dispatch(setCommentsReducer(comments));
        dispatch(setPostInteractionReducer(postInteractions));
        dispatch(setCommentInteractionReducer(commentInteractions));

        const totalPostsNum = responseMessage?.metadata?.pagination?.totalCount;
        dispatch(setMaxNumberOfPosts(totalPostsNum ? totalPostsNum : 0));

        return currentPosts;
      }
    } catch (e) {
      logger.error({ message: e, functionName: 'getListPostsOrderedByUpdatedTimeDesc' });
      throw e;
    }

    return [];
  }

  public static async fetchAccounts(
    jwt: string | undefined,
    connectedAccounts: ConnectedAccount[],
    dispatch: any,
    setListAccounts: any,
    setProfileImages: any,
    setProfile: any,
    accountsRef: React.MutableRefObject<Account[]>,
    currentConnectedAccount: ConnectedAccount | undefined,
  ) {
    try {
      const listAccountDetailsRequest = new AuthListAccountsDetailsRequest();
      connectedAccounts.map((ac: ConnectedAccount) => {
        listAccountDetailsRequest.accountIds.push(ac.accountId || ac.accountId);
      });
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.authClientV2.listAccountsDetails(listAccountDetailsRequest, { headers });
      const listProfileImages = new Map<string, string>();
      const length = responseMessage?.accounts.length || 0;
      for (let i = 0; i < length; i++) {
        const account = responseMessage?.accounts[i];
        // skip account that are undefined or null or ''
        if (!account) continue;

        const imageUrl = account.imageProfileUrl;
        if (imageUrl && imageUrl !== ' ') {
          const image = await Imagehelper.returnFetchImage(account.imageProfileUrl, jwt, 150);
          listProfileImages.set(account?.id, image);
        } else {
          listProfileImages.set(account?.id, '');
        }
      }
      dispatch(setProfileImages(listProfileImages));
      dispatch(setListAccounts(responseMessage?.accounts || []));
      dispatch(setProfile(responseMessage?.accounts?.find((acc) => acc?.id === currentConnectedAccount?.accountId)));
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.fetchAccounts' });
    }
  }

  public static async collectPhotosForConnectedAccounts(
    jwt: string | undefined,
    connectedAccounts: ConnectedAccountRef[],
    dispatch: any,
    setListAccounts: any,
    setProfileImages: any,
  ) {
    try {
      const listProfileImages = new Map<string, string>();
      const length = connectedAccounts.length || 0;
      for (let i = 0; i < length; i++) {
        const account = connectedAccounts[i];
        // skip account that are undefined or null or ''
        if (!account) continue;

        const imageUrl = account.imageProfileUrl;
        if (imageUrl && imageUrl !== ' ') {
          const image = await Imagehelper.returnFetchImage(account.imageProfileUrl, jwt, 150);
          listProfileImages.set(account?.accountId || account?.accountPhoneId, image);
        } else {
          listProfileImages.set(account?.accountId || account?.accountPhoneId, '');
        }
      }

      dispatch(setProfileImages(listProfileImages));
      dispatch(setListAccounts(connectedAccounts || []));
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.fetchAccounts' });
    }
  }

  public static async listNotifications(
    jwt: string | undefined,
    dispatch: any,
    setNotifications: any,
    setHasNotification: any,
  ) {
    try {
      const request = new ListNotificationsRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.listNotifications(request, { headers });
      const notifications: Notification[] = [];
      responseMessage?.notifications.map((notification) => notifications.push(notification));
      dispatch(setNotifications(notifications));
      if (notifications.length > 0) await dispatch(setHasNotification(true));
      else await dispatch(setHasNotification(false));
    } catch (e) {
      logger.error({ message: e, functionName: 'listNotifications' });
      throw e;
    }
  }

  public static async listTags(
    jwt: string | undefined,
    weddingId: string,
    dispatch: any,
    setTags: ActionCreatorWithOptionalPayload<Tag[] | undefined, string>,
  ): Promise<Tag[]> {
    try {
      const request = new ListTagsRequest();
      request.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.listTags(request, { headers });
      const tags: Tag[] = [];
      responseMessage?.tags.map((tag) => tags.push(tag));
      dispatch(setTags(tags));
      return tags;
    } catch (e) {
      logger.error({ message: e, functionName: 'listTags' });
      throw e;
    }
  }

  public static async listTagsNoRedux(jwt: string | undefined, weddingId: string): Promise<Tag[]> {
    try {
      const request = new ListTagsRequest();
      request.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.listTags(request, { headers });
      const tags: Tag[] = [];
      responseMessage?.tags.map((tag) => tags.push(tag));
      return tags;
    } catch (e) {
      logger.error({ message: e, functionName: 'listTags' });
      throw e;
    }
  }

  public static async createTag(userToken: string, weddingId: string, key: string): Promise<string> {
    try {
      const createTagRequest = new CreateTagRequest();
      createTagRequest.weddingId = weddingId;
      createTagRequest.key = key;

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.createTag(createTagRequest, { headers });
      logger.debug({ message: responseMessage });
      return responseMessage?.tag?.id || '';
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createTag' });
      return '';
    }
  }

  // comment interactions
  public static async createCommentInteraction(
    weddingId: string,
    commentId: string,
    accountId: string,
    commentInteractions: CommentInteraction[],
    userToken: string,
  ) {
    try {
      const commentInteractionRequest = new CreateCommentInteractionRequest();
      commentInteractionRequest.weddingId = weddingId;
      commentInteractionRequest.commentId = commentId;
      commentInteractionRequest.emoticon = '/heart.svg';

      logger.debug({ message: `connectedAccountId: ${accountId}` });
      logger.debug({
        message: `commentInteractions: ${JSON.stringify(commentInteractionRequest, (_, v) =>
          typeof v === 'bigint' ? v.toString() : v,
        )}`,
      });

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.createCommentInteraction(commentInteractionRequest, {
        headers,
      });
      logger.debug({ message: responseMessage });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createCommentInteraction' });
    }
  }

  public static async deleteCommentInteraction(
    weddingId: string,
    commentId: string,
    accountId: string,
    account2Id: string,
    commentInteractions: CommentInteraction[],
    userToken: string,
    dispatch: any,
    setCommentInteractionReducer: any,
  ) {
    const commentInteractionsForCurrentAccount = commentInteractions.filter(
      (interaction) => interaction.accountId === accountId || interaction.accountId === account2Id,
    );

    for (let i = 0; i < commentInteractionsForCurrentAccount.length; i++) {
      const commentInteraction = commentInteractionsForCurrentAccount[i];
      try {
        const deleteCommentInteractionRequest = new DeleteCommentInteractionRequest();
        deleteCommentInteractionRequest.weddingId = weddingId;
        deleteCommentInteractionRequest.commentId = commentId;

        deleteCommentInteractionRequest.id = commentInteraction?.id || '';

        logger.debug({ message: deleteCommentInteractionRequest });

        const headers = new Headers();
        headers.set('Authorization', `Bearer ${userToken}`);
        const responseMessage = await Backend.brainClientV2.deleteCommentInteraction(deleteCommentInteractionRequest, {
          headers,
        });
        logger.debug({ message: responseMessage });
      } catch (e) {
        // clean store from post interactions ids that fail to be deleted
        const newList = commentInteractions.filter((x) => {
          return x?.id != commentInteraction?.id;
        });
        dispatch(setCommentInteractionReducer(newList));
        logger.error({ message: e, functionName: 'BrainHelper.deleteCommentInteraction' });
      }
    }
  }

  public static async createTaxonomyOption(
    taxonomyId: string,
    key: string,
    color: string,
    icon: string,
    description: string,
    userToken: string,
    weddingId: string,
    dispatch?: any,
    addTaxonomyOptions?: any,
  ): Promise<TaxonomyOption> {
    try {
      const createTaxonomyOption = new CreateTaxonomyOptionRequest();
      createTaxonomyOption.taxonomyId = taxonomyId;
      createTaxonomyOption.key = key;
      createTaxonomyOption.color = color;
      createTaxonomyOption.icon = icon;
      createTaxonomyOption.description = description;
      createTaxonomyOption.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.createTaxonomyOption(createTaxonomyOption, { headers });
      logger.debug({ message: responseMessage });
      if (dispatch && addTaxonomyOptions && responseMessage && responseMessage.taxonomyOption) {
        dispatch(addTaxonomyOptions([responseMessage.taxonomyOption]));
      }
      return responseMessage?.taxonomyOption || new TaxonomyOption();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createTaxonomyOption' });
    }
    return new TaxonomyOption();
  }

  public static async deleteTaxonomyOption(taxonomyOptionId: string, weddingId: string, userToken: string) {
    try {
      const deleteTaxonomyOptionRequest = new DeleteTaxonomyOptionRequest();
      deleteTaxonomyOptionRequest.id = taxonomyOptionId;
      deleteTaxonomyOptionRequest.weddingId = weddingId;
      logger.debug({ message: deleteTaxonomyOptionRequest });
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.deleteTaxonomyOption(deleteTaxonomyOptionRequest, {
        headers,
      });
      logger.debug({ message: responseMessage });
      console.log({ message: responseMessage });
    } catch (e: any) {
      logger.error({ message: e, functionName: 'BrainHelper.deleteTaxonomyOption' });
    }
  }

  public static async attachTaxonomyOptionsToAllowedVisitor(
    taxonomyIds: Array<string>,
    allowedVisitorId: string,
    userToken: string,
    weddingId: string,
    dispatch?: any,
    addInvitedVisitors?: any,
  ) {
    try {
      const attachTaxonomyOptionsToAllowedVisitor = new AttachTaxonomyOptionsToAllowedVisitorsRequest();
      attachTaxonomyOptionsToAllowedVisitor.allowedVisitorId = allowedVisitorId;
      attachTaxonomyOptionsToAllowedVisitor.taxonomyOptionIds = taxonomyIds;
      attachTaxonomyOptionsToAllowedVisitor.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.attachTaxonomyOptionsToAllowedVisitors(
        attachTaxonomyOptionsToAllowedVisitor,
        { headers },
      );
      if (dispatch && addInvitedVisitors && responseMessage && responseMessage.allowedVisitor) {
        dispatch(addInvitedVisitors([responseMessage.allowedVisitor]));
      }
      return responseMessage.allowedVisitor;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.attachTaxonomyOptionsToAllowedVisitor' });
    }
  }

  public static async listHostAndModeratorWeddings(
    accountId: string,
    userToken: string,
    phone: string,
    email: string,
  ): Promise<Wedding[]> {
    try {
      if (!accountId) throw new Error('accountId is required to get supported weddings');
      const request = new ListWeddingsRequest();
      request.accountId = accountId;
      request.email = email;
      request.phone = phone;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.listWeddings(request, { headers });
      const weddings: Map<string, Wedding> = new Map();
      responseMessage?.hostWeddings.map((wedding) => weddings.set(wedding?.id, wedding));
      responseMessage?.moderatorWeddings.map((wedding) => weddings.set(wedding?.id, wedding));
      // dispatch(setTags(tags));
      return Array.from(weddings.values());
    } catch (e: any) {
      logger.error({ message: e, functionName: 'BrainHelper.listHostWeddings' });
      if (e.message.includes('not found')) return [];
      throw new Error(e.message);
    }
  }

  public static async listTaxonomies(
    weddingId: string,
    userToken: string,
    dispatch: any,
    setTaxonomies: ActionCreatorWithOptionalPayload<Taxonomy[] | undefined, string>,
  ) {
    try {
      const request = new ListTaxonomiesRequest();
      request.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.listTaxonomies(request, { headers });
      const taxonomies: Taxonomy[] = [];
      responseMessage?.taxonomies.map((taxonomy) => taxonomies.push(taxonomy));
      dispatch(setTaxonomies(taxonomies));
      return taxonomies;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.listTaxonomies' });
    }
  }

  public static async listTaxonomyOptions(
    taxonomyId: string,
    userToken: string,
    dispatch?: any,
    setTaxonomyOptions?: ActionCreatorWithOptionalPayload<TaxonomyOption[] | undefined, string>,
  ) {
    const request = new ListTaxonomyOptionsRequest();
    request.taxonomyId = taxonomyId;
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${userToken}`);
    try {
      const responseMessage = await Backend.brainClientV2.listTaxonomyOptions(request, { headers });
      const taxonomyOptions: TaxonomyOption[] = [];
      responseMessage?.taxonomyOptions.map((taxonomyOption: TaxonomyOption) => taxonomyOptions.push(taxonomyOption));
      if (setTaxonomyOptions && dispatch) dispatch(setTaxonomyOptions(taxonomyOptions));
      return taxonomyOptions;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.listTaxonomyOptions' });
    }
  }

  public static async deleteAllowedVisitor(
    weddingId: string,
    allowedVisitor: AllowedVisitor,
    userToken: string,
    dispatch?: any,
    removeInvitedVisitors?: any,
  ) {
    try {
      const deleteAllowedVisitorRequest = new DeleteAllowedVisitorRequest();
      deleteAllowedVisitorRequest.weddingId = weddingId;
      deleteAllowedVisitorRequest.id = allowedVisitor.id;
      logger.debug({ message: deleteAllowedVisitorRequest });
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.deleteAllowedVisitor(deleteAllowedVisitorRequest, {
        headers,
      });
      if (dispatch && removeInvitedVisitors) {
        dispatch(removeInvitedVisitors([allowedVisitor]));
      }
      logger.debug({ message: responseMessage });
    } catch (e: any) {
      logger.error({ message: e, functionName: 'BrainHelper.deleteAllowedVisitor' });
    }
  }

  public static async deleteAllowedVisitors(
    weddingId: string,
    allowedVisitors: Array<AllowedVisitor>,
    userToken: string,
    dispatch?: any,
    removeInvitedVisitors?: any,
  ) {
    try {
      const deleteAllowedVisitorRequest = new DeleteAllowedVisitorsRequest();
      deleteAllowedVisitorRequest.weddingId = weddingId;
      deleteAllowedVisitorRequest.ids = allowedVisitors.map((visitor) => visitor.id);
      logger.debug({ message: deleteAllowedVisitorRequest });
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.deleteAllowedVisitors(deleteAllowedVisitorRequest, {
        headers,
      });
      if (dispatch && removeInvitedVisitors) {
        dispatch(removeInvitedVisitors(allowedVisitors));
      }
      logger.debug({ message: responseMessage });
    } catch (e: any) {
      logger.error({ message: e, functionName: 'BrainHelper.deleteAllowedVisitors' });
    }
  }

  public static async updateAllowedVisitor(
    jwt: string | undefined,
    visitorId: string,
    weddingId: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    maxAllowedPlusOnes: number,
    rsvpEventIdsList: Array<string>,
    taxonomyOptionIdsList: Array<string>,
    isHost: boolean,
    isParent: boolean,
    groupWithVisitorIds?: Array<string>,
    dispatch?: any,
    addInvitedVisitors?: any,
  ): Promise<AllowedVisitor> {
    try {
      logger.debug({ message: `TOKEN: ${jwt}` });
      const updateAllowedVisitorRequest = new UpdateAllowedVisitorRequest();
      updateAllowedVisitorRequest.id = visitorId;
      updateAllowedVisitorRequest.weddingId = weddingId;
      updateAllowedVisitorRequest.email = email;
      updateAllowedVisitorRequest.phone = phone;
      updateAllowedVisitorRequest.firstName = firstName;
      updateAllowedVisitorRequest.lastName = lastName;
      maxAllowedPlusOnes && (updateAllowedVisitorRequest.maxAllowedPlusOnes = maxAllowedPlusOnes as unknown as bigint);
      updateAllowedVisitorRequest.rsvpEventIds = rsvpEventIdsList;
      updateAllowedVisitorRequest.taxonomyOptionIds = taxonomyOptionIdsList;
      updateAllowedVisitorRequest.isHost = isHost;
      updateAllowedVisitorRequest.isParent = isParent;
      if (groupWithVisitorIds) updateAllowedVisitorRequest.groupWithVisitorIds = groupWithVisitorIds;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.updateAllowedVisitor(updateAllowedVisitorRequest, {
        headers,
      });
      if (dispatch && addInvitedVisitors) {
        dispatch(addInvitedVisitors([responseMessage.allowedVisitor]));
      }
      return responseMessage?.allowedVisitor || new AllowedVisitor();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updateAllowedVisitor' });
      throw e;
    }
  }

  public static async updatePublicArrivalForVisitor(
    visitorId: string,
    rsvpEventId: string,
    arrival: boolean,
    // dispatch: any,
  ): Promise<PublicAllowedVisitor> {
    try {
      const updateAllowedVisitorRequest = new PublicUpdateArrivalForAllowedVisitorRequest();
      updateAllowedVisitorRequest.allowedVisitorId = visitorId;
      updateAllowedVisitorRequest.rsvpEventId = rsvpEventId;
      updateAllowedVisitorRequest.arrival = arrival;
      const headers = new Headers();
      const responseMessage = await Backend.brainClientV2.publicUpdateArrivalForAllowedVisitor(
        updateAllowedVisitorRequest,
        {
          headers,
        },
      );
      return responseMessage?.allowedVisitor || new PublicAllowedVisitor();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updatePublicArrivalForVisitor' });
    }
    return new PublicAllowedVisitor();
  }

  public static async updateAllowedWithRequestVisitor(
    jwt: string | undefined,
    updateRequest: UpdateAllowedVisitorRequest,
  ): Promise<AllowedVisitor> {
    try {
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.updateAllowedVisitor(updateRequest, { headers });
      return responseMessage?.allowedVisitor || new AllowedVisitor();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updateAllowedWithRequestVisitor' });
    }
    return new AllowedVisitor();
  }

  public static async createOrUpdateAttendanceForAllowedVisitor(
    jwt: string | undefined,
    request: CreateOrUpdateAttendanceForAllowedVisitorRequest,
  ): Promise<AllowedVisitor> {
    try {
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.createOrUpdateAttendanceForAllowedVisitor(request, {
        headers,
      });
      console.log(responseMessage);
      return responseMessage.allowedVisitor || new AllowedVisitor();
    } catch (e) {
      console.log('error');
      logger.error({ message: e, functionName: 'BrainHelper.updateAllowedWithRequestVisitor' });
    }
    return new AllowedVisitor();
  }

  public static async attachRSVPEventFromAllowedVisitors(
    jwt: string | undefined,
    visitorId: string,
    weddingId: string,
    rsvpEventsIds: string[],
  ): Promise<boolean> {
    try {
      const attachRSVPEventToAllowedVisitorsRequest = new AttachRSVPEventToAllowedVisitorsRequest();
      attachRSVPEventToAllowedVisitorsRequest.allowedVisitorId = visitorId;
      attachRSVPEventToAllowedVisitorsRequest.weddingId = weddingId;
      attachRSVPEventToAllowedVisitorsRequest.rsvpEventIds = rsvpEventsIds;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);

      await Backend.brainClientV2.attachRSVPEventToAllowedVisitors(attachRSVPEventToAllowedVisitorsRequest, {
        headers,
      });
      return true;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.attachRSVPEventFromAllowedVisitors' });
      return false;
    }
  }

  public static async detachRSVPEventFromAllowedVisitors(
    jwt: string | undefined,
    visitorId: string,
    rsvpEventsIds: string[],
  ): Promise<DetachRSVPEventFromAllowedVisitorsResponse> {
    try {
      const detachRSVPEventFromAllowedVisitorsRequest = new DetachRSVPEventFromAllowedVisitorsRequest();
      detachRSVPEventFromAllowedVisitorsRequest.allowedVisitorId = visitorId;
      detachRSVPEventFromAllowedVisitorsRequest.rsvpEventIds = rsvpEventsIds;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.detachRSVPEventFromAllowedVisitors(
        detachRSVPEventFromAllowedVisitorsRequest,
        { headers },
      );
      return responseMessage || new DetachRSVPEventFromAllowedVisitorsResponse();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.detachRSVPEventFromAllowedVisitors' });
    }
    return new DetachRSVPEventFromAllowedVisitorsResponse();
  }

  public static async detachTaxonomyOptionsFromAllowedVisitors(
    jwt: string | undefined,
    weddingId: string,
    visitorId: string,
    taxonomyIds: string[],
    dispatch?: any,
    addInvitedVisitors?: any, //UPDATE INVΙTED VISITOR
  ): Promise<DetachTaxonomyOptionsFromAllowedVisitorsResponse> {
    try {
      const detachRSVPEventFromAllowedVisitorsRequest = new DetachTaxonomyOptionsFromAllowedVisitorsRequest();
      detachRSVPEventFromAllowedVisitorsRequest.allowedVisitorId = visitorId;
      detachRSVPEventFromAllowedVisitorsRequest.weddingId = weddingId;
      detachRSVPEventFromAllowedVisitorsRequest.taxonomyOptionIds = taxonomyIds;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.detachTaxonomyOptionsFromAllowedVisitors(
        detachRSVPEventFromAllowedVisitorsRequest,
        { headers },
      );
      if (dispatch && addInvitedVisitors && responseMessage && responseMessage.allowedVisitor) {
        dispatch(addInvitedVisitors([responseMessage.allowedVisitor]));
      }
      return responseMessage || new DetachTaxonomyOptionsFromAllowedVisitorsResponse();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.deleteTask' });
    }
    return new DetachTaxonomyOptionsFromAllowedVisitorsResponse();
  }

  public static async updateEmailInvitationWebsite(
    jwt: string | undefined,
    id: string | undefined,
    name: string | undefined,
    description: string,
    weddingId: string,
    // dispatch: any,
  ): Promise<UpdateEmailInvitationWebsiteResponse> {
    try {
      logger.debug({ message: `TOKEN: ${jwt}` });
      const updateEmailInvitationWebsiteRequest = new UpdateEmailInvitationWebsiteRequest();
      updateEmailInvitationWebsiteRequest.id = id || '';
      updateEmailInvitationWebsiteRequest.name = name || '';
      updateEmailInvitationWebsiteRequest.description = description;
      updateEmailInvitationWebsiteRequest.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.updateEmailInvitationWebsite(
        updateEmailInvitationWebsiteRequest,
        { headers },
      );
      return responseMessage || new UpdateEmailInvitationWebsiteResponse();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updateEmailInvitationWebsite' });
    }
    return new UpdateEmailInvitationWebsiteResponse();
  }

  public static async listTasks(weddingId: string, userToken: string) {
    const request = new ListTasksRequest();
    request.weddingId = weddingId;
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${userToken}`);
    const responseMessage = await Backend.brainClientV2.listTasks(request, { headers });
    const tasks: Task[] = [];
    responseMessage?.tasks.map((taxonomyOption: Task) => tasks.push(taxonomyOption));
    // dispatch(setTags(tags));
    return tasks;
  }

  public static async createTask(
    jwt: string | undefined,
    weddingId: string,
    title: string,
    description: string,
  ): Promise<Task> {
    try {
      const createTaskRequest = new CreateTaskRequest();
      createTaskRequest.weddingId = weddingId;
      createTaskRequest.description = description;
      createTaskRequest.title = title;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.createTask(createTaskRequest, { headers });
      return responseMessage?.task || new Task();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.createTask' });
    }
    return new Task();
  }

  public static async updateTask(
    jwt: string | undefined,
    id: string,
    weddingId: string,
    title: string,
    description: string,
    status: TaskStatus,
  ): Promise<Task> {
    try {
      const updateTaskRequest = new UpdateTaskRequest();
      updateTaskRequest.id = id;
      updateTaskRequest.weddingId = weddingId;
      updateTaskRequest.description = description;
      updateTaskRequest.title = title;
      updateTaskRequest.status = status;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.updateTask(updateTaskRequest, { headers });
      return responseMessage?.task || new Task();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.updateTask' });
    }
    return new Task();
  }

  public static async deleteTask(jwt: string | undefined, id: string): Promise<DeleteTaskResponse> {
    try {
      const updateTaskRequest = new DeleteTaskRequest();
      updateTaskRequest.id = id;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.deleteTask(updateTaskRequest, { headers });
      return responseMessage || new DeleteTaskResponse();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.deleteTask' });
    }
    return new DeleteTaskResponse();
  }

  public static async totalAttendance(rsvpId: string, userToken: string) {
    try {
      const request = new TotalAttendanceRequest();
      request.rsvpEventId = rsvpId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.totalAttendance(request, { headers });
      return responseMessage;
    } catch (e) {
      logger.error({ message: e });
    }
  }

  public static async totalAllowedVisitors(weddingId: string, userToken: string) {
    try {
      const request = new TotalAllowedVisitorsRequest();
      request.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.totalAllowedVisitors(request, { headers });
      return responseMessage;
    } catch (e) {
      logger.error({ message: e });
    }
  }

  public static async listAvailableTemplates(userToken: string) {
    try {
      const request = new GlobalAvailableTemplatesRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.globalAvailableTemplates(request, { headers });
      const templates: AvailableTemplates[] = [];
      responseMessage?.availableTemplates.map((template) => templates.push(template));
      // dispatch(setTags(tags));
      return templates;
    } catch (e: any) {
      logger.error({ message: e });
      if (e.message.includes('not found')) return [];
      throw new Error(e.message);
    }
  }

  public static async listWeddingWebsites(wedding_id: string, wedding_path: string, userToken: string) {
    try {
      const request = new ListWeddingWebsitesRequest();
      request.weddingId = wedding_id;
      request.weddingPath = wedding_path;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.listWeddingWebsites(request, { headers });
      const websites: WeddingWebsite[] = [];
      responseMessage?.weddingWebsites.map((website) => websites.push(website));
      // dispatch(setTags(tags));
      return websites;
    } catch (e: any) {
      logger.error({ message: e });
      if (e.message.includes('not found')) return [];
      throw new Error(e.message);
    }
  }

  public static async createWeddingWebsite(
    weddingId: string,
    name: string,
    description: string,
    visibilityStatus: 0 | 1 | 2 | 3, //  VisibilityStatusUnknown = 0; VisibilityStatusPublic = 1;  VisibilityStatusPrivate = 2;  VisibilityStatusSharedPrivately = 3;
    path: string,
    pagesList: any[],
    userToken: string,
  ) {
    try {
      const request = new CreateWeddingWebsiteRequest();
      request.weddingId = weddingId;
      request.name = name;
      request.description = description;
      request.visibilityStatus = visibilityStatus;
      request.path = path;
      request.pages = pagesList;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.createWeddingWebsite(request, { headers });
      const weddingWebsite: WeddingWebsite | undefined = responseMessage?.weddingWebsite;
      // dispatch(setTags(tags));
      return weddingWebsite;
    } catch (e: any) {
      logger.error({ message: e });
      if (e.message.includes('not found')) return undefined;
      throw new Error(e.message);
    }
  }

  public static async updateWeddingWebsite(
    websiteId: string,
    weddingId: string,
    name: string,
    description: string,
    visibilityStatus: 0 | 1 | 2 | 3, //  VisibilityStatusUnknown = 0; VisibilityStatusPublic = 1;  VisibilityStatusPrivate = 2;  VisibilityStatusSharedPrivately = 3;
    path: string,
    archived: boolean,
    userToken: string,
    pageList: Array<UpdateWeddingWebsitePageRequest>,
  ) {
    try {
      const request = new UpdateWeddingWebsiteRequest();
      request.id = websiteId;
      request.weddingId = weddingId;
      request.name = name;
      request.description = description;
      request.visibilityStatus = visibilityStatus;
      request.path = path;
      request.archived = archived;
      request.pages = pageList;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.updateWeddingWebsite(request, { headers });
      const weddingWebsite: WeddingWebsite | undefined = responseMessage?.weddingWebsite;
      // dispatch(setTags(tags));
      return weddingWebsite ? weddingWebsite : new WeddingWebsite();
    } catch (e: any) {
      logger.error({ message: e });
      if (e.message.includes('not found')) return undefined;
      throw new Error(e.message);
    }
  }

  // public static async updateTask(
  //   jwt: string | undefined,
  //   id: string,
  //   weddingId: string,
  //   title: string,
  //   description: string,
  //   status: TaskStatusMap[keyof TaskStatusMap],
  // ): Promise<Task> {
  //   try {
  //     const updateTaskRequest = new CreateWe();
  //     updateTaskRequest.setId(id);
  //     updateTaskRequest.setWeddingId(weddingId);
  //     updateTaskRequest.setDescription(description);
  //     updateTaskRequest.setTitle(title);
  //     updateTaskRequest.setStatus(status);
  //     const metadata: grpc.Metadata = new BrowserHeaders({
  //       authorization: `Bearer ${jwt}`,
  //     });
  //     const responseMessage = await Backend.brainClientV2.updateTask(updateTaskRequest, metadata);
  //     return responseMessage?.getTask()? || new Task();
  //   } catch (e) {
  //     logger.error({ message: e, functionName: 'BrainHelper.updateTask' });
  //   }
  //   return new Task();
  // }

  public static async sendInvitations(
    userToken: string,
    weddingId: string,
    visitorIds: string[],
    option: string,
  ): Promise<SendInvitationsResponse | null> {
    try {
      const sendInvitationsRequest = new SendInvitationsRequest();
      sendInvitationsRequest.weddingId = weddingId;
      sendInvitationsRequest.invitedVisitorIds = visitorIds;
      switch (option) {
        case InvitationOptionSaveTheDate:
          sendInvitationsRequest.invitationOption = InvitationOption.InvitationOptionSaveTheDate;
          break;
        case InvitationOptionInvite:
          sendInvitationsRequest.invitationOption = InvitationOption.InvitationOptionInvite;
          break;
        case InvitationOptionReminder:
          sendInvitationsRequest.invitationOption = InvitationOption.InvitationOptionReminder;
          break;
        default:
          sendInvitationsRequest.invitationOption = InvitationOption.InvitationOptionReminder;
          break;
      }
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.sendInvitations(sendInvitationsRequest, { headers });
    } catch (e: any) {
      logger.error({ message: e, functionName: 'BrainHelper.sendInvitations' });
      throw Error(e.toString());
    }
    return null;
  }

  // ADMINS
  public static async listAllWeddings(userToken: string): Promise<ListAllWeddingsResponse | undefined> {
    try {
      const listAllWeddingsRequest = new ListAllWeddingsRequest();

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.listAllWeddings(listAllWeddingsRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.listAllWeddings' });
    }
    return undefined;
  }

  public static async downloadGuestList(
    userToken: string,
    weddingId: string,
    query: string,
    fileType: string,
  ): Promise<DownloadGuestListResponse | undefined> {
    try {
      const downloadGuestListRequest = new DownloadGuestListRequest();
      downloadGuestListRequest.fileType = fileType;
      downloadGuestListRequest.weddingId = weddingId;
      downloadGuestListRequest.query = query;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.downloadGuestList(downloadGuestListRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.listAllWeddings' });
    }
    return undefined;
  }

  public static async downloadRSVPGuestList(
    userToken: string,
    weddingId: string,
    query: string,
    fileType: string,
    rsvpResponse: RSVPResponded,
  ): Promise<DownloadRSVPGuestListResponse | undefined> {
    try {
      const downloadGuestListRequest = new DownloadRSVPGuestListRequest();
      downloadGuestListRequest.fileType = fileType;
      downloadGuestListRequest.weddingId = weddingId;
      downloadGuestListRequest.query = query;
      downloadGuestListRequest.rsvpResponded = rsvpResponse;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.downloadRSVPGuestList(downloadGuestListRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.listAllWeddings' });
    }
    return undefined;
  }

  public static async couponCheck(userToken: string, coupon: string): Promise<CouponCheckResponse | null> {
    try {
      const couponCheckRequest = new CouponCheckRequest();
      couponCheckRequest.coupon = coupon;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const res = await Backend.brainClientV2.couponCheck(couponCheckRequest, { headers });
      return res;
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.couponCheck' });
      return null;
    }
  }

  public static async listAllPackages(userToken: string): Promise<ListAllPackagesResponse | undefined> {
    try {
      const packagesRequest = new ListAllPackagesRequest();
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      return await Backend.brainClientV2.publicListAllPackages(packagesRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.couponCheck' });
    }
    return undefined;
  }

  public static async detachAllowedVisitorsFromAllowedVisitor(
    jwt: string | undefined,
    weddingId: string,
    visitorId: string,
    allowedVisitorIds: string[],
    dispatch?: any,
    addInvitedVisitors?: any, //UPDATE INVΙTED VISITOR
  ): Promise<DetachAllowedVisitorFromAllowedVisitorsResponse> {
    try {
      const detachRSVPEventFromAllowedVisitorsRequest = new DetachAllowedVisitorFromAllowedVisitorsRequest();
      detachRSVPEventFromAllowedVisitorsRequest.allowedVisitorId = visitorId;
      detachRSVPEventFromAllowedVisitorsRequest.weddingId = weddingId;
      detachRSVPEventFromAllowedVisitorsRequest.allowedVisitorIds = allowedVisitorIds;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      const responseMessage = await Backend.brainClientV2.detachAllowedVisitorsFromAllowedVisitor(
        detachRSVPEventFromAllowedVisitorsRequest,
        { headers },
      );
      if (dispatch && addInvitedVisitors && responseMessage && responseMessage.allowedVisitor) {
        dispatch(addInvitedVisitors([responseMessage.allowedVisitor]));
      }
      return responseMessage || new DetachTaxonomyOptionsFromAllowedVisitorsResponse();
    } catch (e) {
      logger.error({ message: e, functionName: 'BrainHelper.deleteTask' });
    }
    return new DetachTaxonomyOptionsFromAllowedVisitorsResponse();
  }
}
