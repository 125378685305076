import { SVGIconName } from '../Icon/Icons';

import { useState } from 'react';
import Icon from '../Icon';

const CustomButtonImgAndIcon = ({
  callback,
  icon,
  title,
  isSmall = false,
}: {
  callback: any;
  icon: SVGIconName;
  title: string;
  isSmall?: boolean;
}): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className={'btnMain' + ' ' + 'btnMainWithImg' + ' ' + (isSmall ? 'btnSmall' : 'btnMedium')}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        callback();
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon name={icon} color={isHovered ? '#FFFFFF' : '#281B24'} size="s" />
      {title}
    </button>
  );
};

export default CustomButtonImgAndIcon;
