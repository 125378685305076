import React, { ReactElement, useEffect, useState } from 'react';

import styles from './TemplatePreview.module.scss';
import { TemplateComponents } from '../../../templates';
import { ScreenType, useTypeOfScreen } from '../../../hooks';

type TemplatePreviewProps = {
  sectionComponentsList: Array<string>;
  componentProps: Array<any>;
  mode: 'compact' | 'expanded' | 'debug';
};

export const TemplatePreview = ({
  sectionComponentsList,
  mode = 'expanded',
  componentProps,
}: TemplatePreviewProps): JSX.Element => {
  const typeOfScreen = useTypeOfScreen();
  const [previewView, setPreviewView] = useState<0 | 1 | 2>(0);
  const [componentList, setComponentList] = useState<Array<ReactElement>>([]);
  useEffect(() => {
    const tempComponentList: Array<ReactElement> = [];
    // console.log(sectionComponentsList);
    sectionComponentsList?.forEach((component, index) => {
      if (component !== undefined && component[index] !== undefined) {
        if (TemplateComponents(component) !== undefined) {
          tempComponentList.push(TemplateComponents(component, { ...componentProps[index], key: index }, index));
        }
      }
    });

    setComponentList(tempComponentList);
  }, [sectionComponentsList, componentProps]);

  const getClassNameByPreviewView = () => {
    if (typeOfScreen === ScreenType.laptop) return styles.mobilePreview;
    switch (previewView) {
      case 0:
        return styles.desktopPreview;
      case 1:
        return styles.mobilePreview;
      case 2:
        return styles.invitationPreview;
      default:
        return styles.desktopPreview;
    }
  };

  if (mode === 'debug')
    return (
      <div
        className={styles.TemplatePreviewExpanded}
        id={`TemplatePreview`}
        style={{ overflowY: 'scroll', height: 'calc(100vh - 16px)' }}
      >
        {/*<div className={getClassNameByPreviewView()}>*/}
        {componentList}
        {/*</div>*/}
      </div>
    );

  if (mode === 'compact')
    return (
      <div className={styles.TemplatePreviewExpanded} id={`TemplatePreview`}>
        {/*<div className={getClassNameByPreviewView()}>*/}
        {componentList}
        {/*</div>*/}
      </div>
    );

  return (
    <div className={styles.TemplatePreview}>
      <div style={{ display: 'flex' }}>
        <button
          onClick={() => setPreviewView(0)}
          className={'btnRounded ' + (previewView === 0 ? styles.Selected : '')}
          style={typeOfScreen === ScreenType.laptop ? { display: 'none' } : {}}
        >
          <img src="/assets/icons/icon-desktop.svg" alt="i" width={16} />
        </button>
        <button
          onClick={() => setPreviewView(1)}
          className={'btnRounded ' + (previewView === 1 ? styles.Selected : '')}
        >
          <img src="/assets/icons/icon-smartphone.svg" alt="i" width={16} />
        </button>
        <button
          onClick={() => setPreviewView(2)}
          className={'btnRounded ' + (previewView === 2 ? styles.Selected : '')}
          style={typeOfScreen === ScreenType.laptop ? { display: 'none' } : {}}
        >
          <img src="/assets/icons/icon-envelope.svg" alt="i" width={16} />
        </button>
      </div>
      <p>Scroll to view full website</p>
      <div className={getClassNameByPreviewView()}>
        <div>
          <div style={{ height: '20px', overflow: 'hidden' }}>
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="2" cy="2" r="2" fill="#C7ADC1" />
              <circle cx="9" cy="2" r="2" fill="#C7ADC1" />
              <circle cx="16" cy="2" r="2" fill="#C7ADC1" />
            </svg>
          </div>
          <div
            id={`TemplatePreview`}
            style={{
              borderLeft: componentList.length === 0 && previewView < 2 ? '1px solid #FDF6EB' : 'none',
              borderRight: componentList.length === 0 && previewView < 2 ? '1px solid #FDF6EB' : 'none',
              borderBottom: componentList.length === 0 && previewView < 2 ? '1px solid #FDF6EB' : 'none',
              borderTop: componentList.length === 0 && previewView == 1 ? '1px solid #FDF6EB' : 'none',
              height: '100%',
            }}
          >
            {componentList}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatePreview;
