import { Fragment, useEffect, useState } from 'react';
import styles from '../Planning.module.scss';
import CustomDatePicker from '../../../datePicker/CustomDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import { Location, Point, RSVPEvent, Wedding } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import { addRsvpEvents, selectRsvpEvents, setWedding } from '../../../../app/redux/wedding.slice.recuder';
import { useDispatch, useSelector } from 'react-redux';
import LocationInputComponent from '../locationInput/LocationInputComponent';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import dayjs from 'dayjs';
import { transliterate } from 'transliteration';
import { TimePicker } from '@mui/x-date-pickers';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import useMobileCheck from '../../../../hooks/useMobileChjeck';
import Checkbox from '../../../../shared/Checkbox/CheckboxFilled/CheckboxFilled';
import { ScreenType, useTypeOfScreen } from '../../../../hooks';
import GetUI, { EventType } from '../../../../utils/uiContent';

const FirstStep = (props: {
  userToken: string;
  wedding: Wedding;
  weddingName: string;
  setWeddingName: any;
  setCalendarOpened: any;
  selectedDay: DayValue;
  calendarOpened: boolean;
  setSelectedDay: any;
  setWeddingLocation: any;
  countryShort: string;
  setCountryShort: any;
  fileId: string | undefined;
  weddingLocation: any;
  startDate: string;
  setStartDate: any;
  endDate: string;
  setEndDate: any;
  description: string | undefined;
  setDescription: any;
  setStep: any;
  acceptedFiles: File[];
  getRootProps: any;
  getInputProps: any;
  isDropzoneActive: boolean;
  setIsDropzoneActive: any;
  setShowPreview: any;
}): JSX.Element => {
  const [mainEventAlias, setMainEventAlias] = useState(
    props.wedding.mainEvent?.name ?? GetUI(props.wedding.socialEventType as EventType).startPlanningEventType,
  );
  const isMobile = useMobileCheck();
  const rsvpEvents = useSelector(selectRsvpEvents);
  const [startDate, setStartDate] = useState<string>(
    props.weddingLocation?.externalQuickCode
      ? dayjs
          .tz(
            props.startDate || new Date(),
            props.weddingLocation?.externalQuickCode ? props.weddingLocation.externalQuickCode : dayjs.tz.guess(),
          )
          .format('YYYY-MM-DDTHH:mm:00')
      : dayjs(props.startDate || new Date()).format('YYYY-MM-DDTHH:mm:00'),
  );
  const [endDate, setEndDate] = useState<string>(
    props.weddingLocation?.externalQuickCode
      ? dayjs
          .tz(
            props.endDate || new Date(),
            props.weddingLocation?.externalQuickCode ? props.weddingLocation.externalQuickCode : dayjs.tz.guess(),
          )
          .format('YYYY-MM-DDTHH:mm:00')
      : dayjs(props.endDate || new Date()).format('YYYY-MM-DDTHH:mm:00'),
  );
  const [datePickerStartString, setDatePickerStartString] = useState<string>(
    dayjs
      .utc(props.startDate || new Date())
      .tz(props.weddingLocation?.externalQuickCode ? props.weddingLocation?.externalQuickCode : dayjs.tz.guess())
      .format('YYYY-MM-DDTHH:mm:00'),
  );
  const [datePickerEndString, setDatePickerEndString] = useState<string>(
    dayjs
      .utc(props.endDate || new Date())
      .tz(props.weddingLocation?.externalQuickCode ? props.weddingLocation?.externalQuickCode : dayjs.tz.guess())
      .format('YYYY-MM-DDTHH:mm:00'),
  );
  const [googleApiError, setGoogleApiError] = useState(false);

  const [isAllDay, setIsAllDay] = useState<boolean>(
    props.startDate !== '' &&
      props.endDate !== '' &&
      new Date(props.endDate)?.getTime() - new Date(props.startDate)?.getTime() === 86400000 &&
      new Date(props.startDate).getHours() === 0 &&
      new Date(props.endDate).getHours() === 0,
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  let files = props.acceptedFiles.map((file: any) => (
    <p className={'didacticP'} key={file.path}>
      {file.path} <span style={{ color: '#A9A4A7' }}>Drop or browse to replace image</span>
    </p>
  ));

  useEffect(() => {
    files = props.acceptedFiles.map((file: any) => <p key={file.path}>{file.path} Drop or browse to replace image</p>);
  }, [props.acceptedFiles]);

  const onFinishFirstStep = async () => {
    const firstStep = new RSVPEvent();
    firstStep.isDefault = true;
    firstStep.isPrivate = false;
    firstStep.name = mainEventAlias;
    firstStep.weddingId = props.wedding.id;
    if (!props.weddingName) {
      setWeddingNameError('wedding name should be defined');
    } else if (googleApiError || props.weddingLocation === undefined) {
      setWeddingNameError('');
      setLocationError(
        googleApiError ? 'you need to select a location from the dropdown' : 'location should be defined',
      );
    } else if (!props.selectedDay) {
      setLocationError('');
      setDateError('date should be defined');
    } else if (!props.startDate) {
      setDateError('date should be defined');
    } else if (!props.endDate) {
      setDateError('date should be defined');
    } else if (props.fileId === undefined || props.fileId === '') {
      setDateError('');
      setImageError('image should be defined');
    } else {
      setLocationError('');
      setDateError('');
      setImageError('');

      firstStep.id = props.wedding?.mainEvent?.id || '';
      const location = new Location();
      const point = new Point();
      point.longitude = props.weddingLocation.point?.longitude || 0;
      point.latitude = props.weddingLocation.point?.latitude || 0;
      location.address = props.weddingLocation.address;
      location.externalQuickCode = props.weddingLocation?.externalQuickCode;
      props.setCountryShort(props.weddingLocation?.externalQuickCode);
      location.point = point;
      location.name = props.weddingLocation.name;
      firstStep.location = location;
      let startHours =
        (datePickerStartString !== ''
          ? datePickerStartString.split('T')[1].split(':')[0]
          : startDate.split('T')[1].split(':')[0]) +
        ':' +
        (datePickerStartString !== ''
          ? datePickerStartString.split('T')[1].split(':')[1]
          : startDate.split('T')[1].split(':')[1]) +
        ':' +
        (datePickerStartString !== ''
          ? datePickerStartString.split('T')[1].split(':')[2]
          : startDate.split('T')[1].split(':')[2]);
      let endHours =
        (datePickerEndString !== ''
          ? datePickerEndString.split('T')[1].split(':')[0]
          : endDate.split('T')[1].split(':')[0]) +
        ':' +
        (datePickerEndString !== ''
          ? datePickerEndString.split('T')[1].split(':')[1]
          : endDate.split('T')[1].split(':')[1]) +
        ':' +
        (datePickerEndString !== ''
          ? datePickerEndString.split('T')[1].split(':')[2]
          : endDate.split('T')[1].split(':')[2]);
      if (isAllDay) {
        startHours = '00:00:00';
        endHours = '00:00:00';
      }

      const tzStart = dayjs.tz(
        props.selectedDay?.year + '-' + props.selectedDay?.month + '-' + props.selectedDay?.day + ' ' + startHours,
        props.countryShort,
      );
      const tzEnd = dayjs.tz(
        props.selectedDay?.year +
          '-' +
          props.selectedDay?.month +
          '-' +
          (Number(startDate.split('T')[1].split(':')[0] + startDate.split('T')[1].split(':')[1]) >=
          Number(endDate.split('T')[1].split(':')[0] + endDate.split('T')[1].split(':')[1])
            ? props.selectedDay?.day + 1
            : props.selectedDay?.day) +
          ' ' +
          endHours,
        props.countryShort,
      );

      firstStep.startTimestamp = tzStart.toJSON();
      firstStep.endTimestamp = tzEnd.toJSON();
      firstStep.description = props.description || '';
      firstStep.icon = props.fileId;

      // update wedding
      BrainHelper.updateWedding(
        props.userToken,
        props.wedding.id,
        transliterate(props.wedding.path),
        props.weddingName,
        props.wedding.year,
        props.wedding.logoUrl,
        props.wedding.smallLogoUrl,
        props.wedding.baseColor,
      );

      if (props.wedding.mainEvent?.kindlyReminder) firstStep.kindlyReminder = props.wedding.mainEvent.kindlyReminder;
      const request = firstStep;
      if (request.id === undefined || request.id === null || request.id === '') {
        const response = await BrainHelper.createRsvpEvent(
          props.userToken,
          props.wedding.id,
          firstStep.name,
          request.startTimestamp,
          request.endTimestamp,
          request.location as Location,
          request.isDefault,
          request.isPrivate,
          request.icon,
          request.description,
        );
        if (response && response.event) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(addRsvpEvents([response.event]));
          const newWedding = JSON.parse(
            JSON.stringify(props.wedding, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
          );
          newWedding.mainEvent = response?.event;
          newWedding.name = props.weddingName;
          dispatch(setWedding(newWedding));
        }
      } else {
        const response = await BrainHelper.updateRsvpEvent(
          props.userToken,
          request.id,
          request.weddingId,
          request.name,
          request.startTimestamp,
          request.endTimestamp,
          request.location as Location,
          request.isDefault,
          request.isPrivate,
          request.icon,
          request.description,
          request.kindlyReminder,
        );
        if (response && response.event) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(addRsvpEvents([response.event]));
          const newWedding = JSON.parse(
            JSON.stringify(props.wedding, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
          );
          newWedding.mainEvent = response?.event;
          newWedding.name = props.weddingName;
          dispatch(setWedding(newWedding));
        }
      }

      const param = searchParams.get('eventId');
      if (param) {
        searchParams.delete('eventId');
        setSearchParams(searchParams);
      }
      props.setStep(2);
    }
  };
  const typeOfScreen = useTypeOfScreen();
  const [locationError, setLocationError] = useState('');
  const [imageError, setImageError] = useState('');
  const [dateError, setDateError] = useState('');
  const [weddingNameError, setWeddingNameError] = useState('');
  return (
    <div className={styles.left}>
      <div>
        <div className={styles.stepHeader}>
          <img src={GetUI(props.wedding.socialEventType as EventType).icon} alt="rings" width={32} />
          <h3 className={'didacticH3'}>Confirm Your Main Event</h3>
        </div>
        <p className={'didactic'} style={{ width: '100%', maxWidth: '285px', fontSize: '16px' }}>
          Let{"'"}s begin with finalizing the {GetUI(props.wedding.socialEventType as EventType).ceremonyName}. Then we
          can add more events.
        </p>
        <br />
        <form action="#" onSubmit={(e) => e.preventDefault()} className={styles.form}>
          {weddingNameError ? <p style={{ color: 'red' }}>{weddingNameError}</p> : <></>}
          <div>
            <h4 className={'fridayH4'}>
              {GetUI(props.wedding.socialEventType as EventType).startPlanningEventType} Name*
            </h4>
            <input
              style={typeOfScreen === ScreenType.mobile ? { width: '100%' } : {}}
              className={'input'}
              type="text"
              placeholder={''}
              value={props.weddingName}
              onChange={(e) => props.setWeddingName(e.target.value)}
            />
          </div>
          <div>
            <h4 className={'fridayH4'}>Main Event Alias:</h4>
            <input
              style={typeOfScreen === ScreenType.mobile ? { width: '100%' } : {}}
              className={'input'}
              type="text"
              placeholder={''}
              value={mainEventAlias}
              onChange={(e) => setMainEventAlias(e.target.value)}
            />
          </div>
          {dateError ? <p style={{ color: 'red' }}>{dateError}</p> : <></>}
          <CustomDatePicker
            setCalendarOpened={props.setCalendarOpened}
            disableCalendar={false}
            selectedDay={props.selectedDay}
            calendarOpened={props.calendarOpened}
            setSelectedDay={props.setSelectedDay}
            name={`${GetUI(props.wedding.socialEventType as EventType).startPlanningEventType} Date*`}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack className={styles.timeDiv}>
              <div style={{ maxWidth: '100%' }}>
                <h4 className={'fridayH4'}>From*</h4>
                <TimePicker
                  className={styles.timePickerDiv}
                  ampm={false}
                  disabled={isAllDay}
                  value={datePickerStartString !== '' ? new Date(datePickerStartString) : new Date(startDate)}
                  onChange={(newValue: any) => {
                    if (Date.parse(newValue)) {
                      setDatePickerStartString('');
                      props.setStartDate(`${startDate.split('T')[0]}T${newValue.toString().split(' ')[4]}`);
                      setStartDate(`${startDate.split('T')[0]}T${newValue.toString().split(' ')[4]}`);
                    }
                  }}
                />
              </div>
              {!isMobile && (
                <div>
                  <p style={{ color: '#ffffff' }}>-</p>
                  <p>-</p>
                </div>
              )}
              <div>
                <h4 className={'fridayH4'}>To*</h4>
                <TimePicker
                  className={styles.timePickerDiv}
                  ampm={false}
                  disabled={isAllDay}
                  value={datePickerEndString !== '' ? new Date(datePickerEndString) : new Date(endDate)}
                  onChange={(newValue: any) => {
                    if (Date.parse(newValue)) {
                      setDatePickerEndString('');
                      props.setEndDate(`${endDate.split('T')[0]}T${newValue.toString().split(' ')[4]}`);
                      setEndDate(`${endDate.split('T')[0]}T${newValue.toString().split(' ')[4]}`);
                    }
                  }}
                />
              </div>
            </Stack>
          </LocalizationProvider>
          <div style={{ display: 'flex', gap: '16px', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Checkbox value={isAllDay} onClick={() => setIsAllDay(!isAllDay)} />
            <label htmlFor='{"allDay"}'>All Day</label>
          </div>
          {locationError || googleApiError ? <p style={{ color: 'red' }}>{locationError}</p> : <></>}
          <LocationInputComponent
            label={`${GetUI(props.wedding.socialEventType as EventType).startPlanningEventType} Location*`}
            location={props.weddingLocation}
            setLocation={props.setWeddingLocation}
            setCountryShort={props.setCountryShort}
            required={true}
            setError={setGoogleApiError}
          />
          {imageError ? <p style={{ color: 'red' }}>{imageError}</p> : <></>}
          <div
            className={styles.dragNDrop}
            style={
              typeOfScreen === ScreenType.mobile ? { minWidth: 'calc(100% - 16px)', maxWidth: 'calc(100% - 16px)' } : {}
            }
          >
            <p>Image*</p>
            <div
              role={'button'}
              tabIndex={0}
              onMouseDown={() => props.setIsDropzoneActive(true)}
              onMouseUp={() => props.setIsDropzoneActive(false)}
              className={styles.dropzone}
              {...props.getRootProps()}
              style={
                props.isDropzoneActive
                  ? {
                      border: 'dotted 1px #00C9A5',
                      maxWidth: 'calc(100vw-16px)',
                      minWidth: typeOfScreen === ScreenType.mobile ? 'calc(100vw-8px)' : '288px',
                    }
                  : {
                      maxWidth: 'calc(100vw-16px)',
                      minWidth: typeOfScreen === ScreenType.mobile ? 'calc(100vw-8px)' : '288px',
                    }
              }
            >
              <input
                style={
                  typeOfScreen === ScreenType.mobile
                    ? { width: '50%', maxWidth: 'calc(100vw-16px)' }
                    : { maxWidth: 'calc(100vw-16px)' }
                }
                {...props.getInputProps()}
              />
              {files?.length > 0 ? (
                <Fragment>
                  <img src="/rsvp/image-default.svg" alt="uploaded" />
                  <p className={'didacticP'} style={props.isDropzoneActive ? { color: '#00C9A5!important' } : {}}>
                    {files}
                  </p>
                </Fragment>
              ) : (
                <Fragment>
                  <img src={'/rsvp/upload' + (props.isDropzoneActive ? '-hovered' : '') + '.svg'} alt="upload" />
                  <p
                    className={'didacticP'}
                    style={
                      props.isDropzoneActive
                        ? { color: '#00C9A5', textOverflow: 'ellipsis', maxWidth: '50%' }
                        : { textOverflow: 'ellipsis', maxWidth: '40%' }
                    }
                  >
                    Drag & drop your photo here, or{' '}
                    <span className={styles.highlighted} style={props.isDropzoneActive ? { color: '#00C9A5' } : {}}>
                      browse
                    </span>{' '}
                    your files
                  </p>
                </Fragment>
              )}
            </div>
          </div>
          <div>
            <h4 className={'fridayH4'}>Description</h4>
            <textarea
              style={
                typeOfScreen === ScreenType.mobile
                  ? { width: 'calc(100% - 16px)', minWidth: 'calc(100% - 4px)', maxWidth: '100%' }
                  : { maxWidth: '100%' }
              }
              rows={4}
              cols={10}
              className={'textarea'}
              placeholder={'A small paragraph...'}
              value={props.description}
              onChange={(e) => props.setDescription(e.target.value)}
            />
          </div>
          <div>
            <button className={'btn btnMain ' + styles.nextBtn} onClick={onFinishFirstStep}>
              Save
            </button>
            {rsvpEvents && rsvpEvents.length > 1 && (
              <button
                className={'btn btnSecondary ' + styles.nextBtn}
                onClick={() => {
                  props.setStep(2);
                  searchParams.delete('eventId');
                  setSearchParams(searchParams);
                }}
              >
                Back
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FirstStep;
