import styles from './TheArtistOurStoryComponent.module.scss';
import '../TheArtist.module.scss';

interface OurStoryComponentProps {
  title?: string;
  description?: string;
  graphicsImageUrl?: string;
}

const defaultValues = {
  title: 'Our Story',
  description:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit amet velit eget ac vulputate. Laoreet amet consequat diam id morbi a. At at aliquet orci leo in condimentum morbi ut. Tincidunt lacus pellentesque sodales elit et eget adipiscing sapien, massa. Feugiat aliquet amet praesent viverra mattis quam. Tellus massa mauris, pulvinar maecenas. Ullamcorper sit phasellus augue sapien risus. Sagittis vitae vel amet scelerisque scelerisque. Quisque tortor urna imperdiet amet feugiat vestibulum ultricies. Mauris erat ut diam turpis ut non. Sed pulvinar donec purus, diam elementum consequat vivamus. Dui sem turpis lectus elit quisque id. Suscipit risus aliquam eu ac mi et tristique elementum. Nulla in viverra massa magna auctor quis.</p>',
  graphicsImageUrl: '/templates/TheArtist/couple_holding_hands.svg',
};

const TheArtistOurStoryComponent = ({ title, description, graphicsImageUrl }: OurStoryComponentProps): JSX.Element => {
  return (
    <section
      id={`TheArtistOurStoryComponent`}
      className={styles.OurStoryComponent}
      style={{ backgroundImage: `url('/templates/TheArtist/snow.png')` }}
    >
      <img src={graphicsImageUrl ? graphicsImageUrl : defaultValues.graphicsImageUrl} alt="our story graphics" />
      <div>
        <h3>{String(title ? title : defaultValues.title)}</h3>
        <div dangerouslySetInnerHTML={{ __html: description ? description : defaultValues.description }} />
      </div>
    </section>
  );
};
export default TheArtistOurStoryComponent;
