import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Fragment } from 'react';
import { RSVPEvent } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Icon } from '../../../shared';
import styles from './RecessesOfTheSoulScheduleComponent.module.scss';

interface ScheduleComponentProps {
  title?: string;
  schedule?: Array<RSVPEvent>;
  mainEvent?: string;
  description?: string;
}

const defaultValues = {
  title: 'Schedule',
  mainEvent: '',
  description:
    'This part will be filled when your planning \n' +
    'is completed. Every public event you create will \n' +
    'appear here.',
};

const RecessesOfTheSoulScheduleComponent = ({
  title,
  schedule,
  description,
  mainEvent = '',
}: ScheduleComponentProps): JSX.Element => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const eventsGrouped = new Map<string, Array<RSVPEvent>>();
  schedule?.map((eventRSVP) => {
    const tempArray = eventsGrouped.get(dayjs(eventRSVP.startTimestamp).format('dddd, DD MMMM YYYY'));
    if (tempArray !== undefined) {
      eventsGrouped.set(
        dayjs(eventRSVP.startTimestamp)
          .tz(eventRSVP.location?.externalQuickCode ? eventRSVP.location?.externalQuickCode : dayjs.tz.guess())
          .format('dddd, DD MMMM YYYY'),
        [...tempArray, eventRSVP].sort(
          (a, b) => Number(dayjs(a.startTimestamp).format('HHmm')) - Number(dayjs(b.startTimestamp).format('HHmm')),
        ),
      );
    } else {
      eventsGrouped.set(
        dayjs(eventRSVP.startTimestamp)
          .tz(eventRSVP.location?.externalQuickCode ? eventRSVP.location?.externalQuickCode : dayjs.tz.guess())
          .format('dddd, DD MMMM YYYY'),
        [eventRSVP].sort(
          (a, b) => Number(dayjs(a.startTimestamp).format('HHmm')) - Number(dayjs(b.startTimestamp).format('HHmm')),
        ),
      );
    }
  });

  const renderDate = () => {
    let i = 0;
    let tempSchedule: JSX.Element[] = [];
    eventsGrouped.forEach((events, date) => {
      const tempEvents: JSX.Element[] = [];
      tempEvents.push(<h2 key={i + ` ` + (Math.random() % 100)}>{date}</h2>);
      events.forEach((e) => {
        tempEvents.push(renderEvent(e, date));
      });

      tempSchedule.push(
        <div
          key={i + ` ` + (Math.random() % 100)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'space-between',
            width: '100%',
          }}
        >
          {tempEvents}
        </div>,
      );
      i++;
    });
    tempSchedule = tempSchedule.sort(
      (a: JSX.Element, b: JSX.Element) =>
        Number(dayjs(String(a.key)).format('YYYYMMDD')) - Number(dayjs(String(b.key)).format('YYYYMMDD')),
    );
    return tempSchedule;
  };

  const renderEvent = (e: RSVPEvent, date: string) => {
    return (
      <Fragment key={String(e.id) + String(date)}>
        <div key={String(e.id) + String(date)}>
          <div className={styles.eventTitle}>{e.name}</div>
          <a
            key={String(date) + ` ` + (Math.random() % 100)}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px',
              paddingTop: 4,
              textOverflow: 'ellipsis',
              width: '250px',
            }}
            target="blank"
            href={`https://maps.google.com/?q=${e?.location?.point?.latitude},${e?.location?.point?.longitude}`}
          >
            <Icon name="location2" size="s" />
            <p className={styles.eventLocatioNTime}>
              {e?.location?.name ? e?.location?.name + ', ' + e?.location?.address : e?.location?.address}
            </p>
          </a>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px',
              paddingTop: 4,
              textOverflow: 'ellipsis',
              width: '250px',
            }}
          >
            <Icon name="clock" size="s" />
            <div key={String(date) + ` ` + (Math.random() % 100)} className={styles.eventLocatioNTime}>
              {dayjs(e.startTimestamp)
                .tz(e.location?.externalQuickCode ? e.location?.externalQuickCode : dayjs.tz.guess())
                .format('HH:mm')}{' '}
              -
              {dayjs(e.endTimestamp)
                .tz(e.location?.externalQuickCode ? e.location?.externalQuickCode : dayjs.tz.guess())
                .format('HH:mm')}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <section className={styles.scheduleComponent}>
      <div className={styles.scheduleComponent_Wrapper}>
        <div className={styles.ScheduleComponent_TitleImgWrapper}>
          <h2 className={styles.scheduleComponent_Title}>
            {String(title ? title : defaultValues.title)}
            {mainEvent && new Date(mainEvent).getTime() - new Date().getTime() > 0 && (
              <span className={styles.title} style={{ paddingLeft: '50px' }}>
                {Math.ceil((new Date(mainEvent).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} days to go
              </span>
            )}
          </h2>
          <img
            className={styles.scheduleComponent_Graphics}
            src="/templates/RecessesOfTheSoul/scheduleComponentGraphics.svg"
            alt="scheduleComponentGraphics"
            loading="lazy"
          />
        </div>
        <section className={styles.scheduleComponent_Schedule}>
          {schedule ? <Fragment>{renderDate()}</Fragment> : <p>{defaultValues.description}</p>}
        </section>
      </div>
      <div
        style={{ textAlign: 'center', paddingBottom: '16px', paddingTop: '16px', maxWidth: '800px', margin: 'auto' }}
        className={styles.schedule}
        dangerouslySetInnerHTML={{ __html: description ? description : '' }}
      />
    </section>
  );
};

export default RecessesOfTheSoulScheduleComponent;
