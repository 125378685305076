import { IonContent, IonHeader, IonImg, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  selectAccount,
  selectAccountsCombinedWithConnectedAccountsForMentions,
  selectJwt,
  selectListAccounts,
  selectListConnectAccounts,
  selectProfile,
} from '../../../app/redux/authorization.slice.reducer';
import { selectPost, selectWedding, setTags, TagDetail } from '../../../app/redux/wedding.slice.recuder';
import { PostComponent } from '../../shared';
import { logger } from '../../../config/Logger';
import { Mention, MentionsInput } from 'react-mentions';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { v4 as uuid } from 'uuid';
import { LikesModal } from '../../modals';
import { getPostMessageReadyForEditing } from '../../lib';
import { encode } from 'punycode';

const SinglePostPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const post = useSelector(selectPost);
  const wedding = useSelector(selectWedding);
  const jwt = useSelector(selectJwt);
  const account = useSelector(selectAccount);
  const profile = useSelector(selectProfile);
  const connectedAccounts = useSelector(selectListConnectAccounts);
  const accounts = useSelector(selectListAccounts);
  const [showLikesModal, setShowLikesModal] = useState<boolean>(false);
  const [postInteractions1, setPostInteractions1] = useState<any>({});
  const mentions = useSelector(selectAccountsCombinedWithConnectedAccountsForMentions);
  const [selectedCommentMentions, setSelectedCommentMentions] = useState<string[]>([]);
  const [reply, setReply] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showCommentsModal, setShowCommentsModal] = useState<boolean>(false);
  const [showEditCommentModal, setShowEditCommentModal] = useState<boolean>(false);
  const [showEditDeleteCommentModal, setShowEditDeleteCommentModal] = useState<boolean>(false);
  // selected tags
  const [selectedCommentTags, setSelectedCommentTags] = useState<TagDetail[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editCommentCallback, setEditCommentCallback] = useState<any>(() => (x: string) => {
    logger.log({ message: 'empty editCommentCallback()', functionName: 'SinglePostPage.editCommentCallback' });
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteCommentCallback, setDeleteCommentCallback] = useState<any>(() => (x: string) => {
    logger.log({ message: 'empty deleteCommentCallback()', functionName: 'SinglePostPage.deleteCommentCallback' });
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateCommentCallback, setUpdateCommentCallback] = useState<any>(() => (x: string) => {
    logger.log({ message: 'empty updateCommentCallback()', functionName: 'SinglePostPage.updateCommentCallback' });
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function asyncTags(query: any, callback: any): any {
    if (!query) return [];

    const globalTags = await BrainHelper.listTags(jwt, wedding?.id, dispatch, setTags);

    const search = async () => {
      const suggestedTags: TagDetail[] = [];
      const suggestion = { id: uuid(), display: query };
      for (const tag of globalTags) {
        const regex = new RegExp(`.*${query}.*`);
        const found = tag.key.match(regex);
        if (found) suggestedTags.push({ id: tag?.id, display: tag.key });
      }
      if (suggestedTags.length > 0) {
        return suggestedTags.concat(suggestion);
      }

      return [suggestion];
    };
    search().then(callback);
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border header underShadow">
        <div role={'presentation'} onMouseDown={() => navigate('/home')}>
          <img loading="lazy" className="headerLogo" src="/assets/logos/whiteclover-logo1.svg" alt="logo" height={32} />
        </div>
      </IonHeader>
      <IonContent>
        <div className={'mainWrapper'} style={{ paddingBottom: '8vw' }}>
          <PostComponent
            post={post}
            wedding={wedding}
            userToken={jwt}
            account={account}
            profile={profile}
            connectedAccounts={connectedAccounts}
            accounts={accounts}
            // showPostEditDeleteModal={(e: any) => setShowEditDeletePostModal(e)}
            // setEditPostCallback={(e: any) => setEditPostCallback(e)}
            // setDeletePostCallback={(e: any) => setDeletePostCallback(e)}
            setEditCommentCallback={(e: any) => setEditCommentCallback(e)}
            // setCreateCommentCallback={(e: any) => setCreateCommentCallback(e)}
            setDeleteCommentCallback={(e: any) => setDeleteCommentCallback(e)}
            setShowLikesModal={() => setShowLikesModal(true)}
            setPostInteractions={(e: any) => setPostInteractions1(e)}
            showEditDeleteCommentModal={(e: any) => setShowEditDeleteCommentModal(e)}
            setShowEditCommentModal={(e: any) => setShowEditCommentModal(e)}
            // setLikesAndCommentsCounters={(e: any) => setLikesAndCommentsCounters(e)}
            setUpdateCommentCallback={setUpdateCommentCallback}
            setUpdateComment={(e: any) => setReply(e)}
            showCommentsModal={() => {
              setShowCommentsModal(true);
              setReply('');
            }}
            // setCommentsForModal={(c: any) => setCommentsForModal(c)}
            isSinglePage={true}
          />
        </div>
      </IonContent>

      {/*-- Footer --*/}
      <div className="footerSectionPlain">
        <div className="profileImg">
          <IonImg
            src="/assets/icons/icon-avatar.svg"
            onClick={() => {
              navigate('/profile');
            }}
          />
          <p>Profile</p>
        </div>
        <div className="homeImg">
          <IonImg
            src="/assets/icons/icon-home.svg"
            onClick={() => {
              // dispatch(setStreamFired(false));
              navigate('/home');
            }}
          />
          <p>Home</p>
        </div>
        <div className="feedImg">
          <IonImg src="/assets/icons/icon-more.svg" onClick={() => navigate('/main')} />
          <p>More</p>
        </div>
      </div>
      {showLikesModal && (
        <LikesModal
          key={`likeModal-${encode(JSON.stringify(accounts, (_, v) => (typeof v === 'bigint' ? v.toString() : v)))}`}
          showModal={showLikesModal}
          setShowModal={setShowLikesModal}
          interactions={postInteractions1}
          accounts={accounts}
          connectedAccounts={connectedAccounts}
        />
      )}
      {showEditDeleteCommentModal && (
        <div className={'editDeletePostModal'}>
          <button
            onClick={() => {
              setShowEditDeleteCommentModal(false);
            }}
          ></button>
          <div>
            <img
              src={'assets/graphics/line.svg'}
              alt={''}
              width={32}
              style={{ alignSelf: 'center', justifySelf: 'center', paddingBottom: 16 }}
            />
            <button
              onClick={() => {
                editCommentCallback();
              }}
            >
              <img src={'assets/icons/icon-edit.svg'} alt={'edit'} width={32} style={{ marginRight: 8 }} />
              Edit Comment
            </button>
            <button
              onClick={() => {
                deleteCommentCallback();
                setShowEditDeleteCommentModal(false);
                setShowCommentsModal(false);
              }}
            >
              <img src={'assets/icons/icon-trash.svg'} alt={'delete'} width={32} style={{ marginRight: 8 }} />
              Delete Comment
            </button>
          </div>
        </div>
      )}
      {showEditCommentModal && (
        <div className={'notification-modal'} style={{ zIndex: '100' }}>
          {' '}
          <button
            style={{ height: '70%', width: '100%', opacity: 0 }}
            onClick={() => {
              setShowEditCommentModal(false);
            }}
          ></button>
          <div style={{ height: '30%', paddingLeft: 16, paddingRight: 8, display: 'flex', justifyContent: 'center' }}>
            <img
              src={'assets/graphics/line.svg'}
              alt={''}
              width={32}
              style={{ alignSelf: 'center', paddingBottom: 8 }}
            />
            <div className={'inputCommentContainer'}>
              <MentionsInput
                allowSuggestionsAboveCursor={true}
                className={'mentions inputComment editComment'}
                value={getPostMessageReadyForEditing(reply)}
                onChange={(event: any) => {
                  if (event.target.value.length >= 250) return;
                  setReply(event.target.value);
                }}
                placeholder={'Say Something...'}
                spellCheck={false}
              >
                <Mention
                  trigger={'@'}
                  data={mentions}
                  markup={'@@@____id__^^^____display__@@@^^^'}
                  style={{
                    backgroundColor: '#daf4fa',
                  }}
                  onAdd={(display) => {
                    setSelectedCommentMentions((mentions: any[]) => [...mentions, display]);
                  }}
                  appendSpaceOnAdd={true}
                />
                <Mention
                  trigger={'#'}
                  data={asyncTags}
                  markup={'$$$____id__~~~____display__$$$~~~'}
                  style={{
                    backgroundColor: '#daf4fa',
                  }}
                  onAdd={async (id, display) => {
                    setSelectedCommentTags(selectedCommentTags.concat({ id: `${id}`, display: display }));
                  }}
                  appendSpaceOnAdd={true}
                />
              </MentionsInput>
              <button
                onClick={() => {
                  updateCommentCallback(reply, selectedCommentMentions, selectedCommentTags);
                  setReply('');
                  setSelectedCommentMentions([]);
                  setSelectedCommentTags([]);

                  setShowEditDeleteCommentModal(false);
                  setShowEditCommentModal(false);
                }}
              >
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={'/assets/icons/icon-send-post.svg'}
                  alt="Send Message"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </IonPage>
  );
};

export default SinglePostPage;
