import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '../../../app/redux/authorization.slice.reducer';
import React, { useEffect, useState } from 'react';
import { AvailableTemplates } from '../../../pkg/protobuf/v2/brain/brain_types_pb';

import styles from './SelectTemplate.module.scss';
import { ScreenType, useTypeOfScreen } from '../../../hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SelectTemplate = (props: any): JSX.Element => {
  const [templates, setTemplates] = useState<Array<AvailableTemplates>>([]);
  const typeOfScreen = useTypeOfScreen();
  const jwt = useSelector(selectAccessToken);
  const listTemplatesHelper = () => {
    BrainHelper.listAvailableTemplates(jwt).then((res) => {
      setTemplates(res.reverse());
      if (res.length > 0) {
        props.setSelectedTemplate(res[0]);
      }
    });
  };

  useEffect(() => {
    listTemplatesHelper();
  }, []);

  if (!props.onlySelectedTemplate)
    return (
      <div
        id="selectTemplate"
        className={styles.SelectTemplate}
        style={typeOfScreen === ScreenType.laptop ? { width: '100%' } : {}}
      >
        <div className={styles.SelectedTemplate}>
          <div>
            <img
              src={
                props.selectedTemplate?.imageUrl
                  ? props.selectedTemplate.imageUrl
                  : '/assets/graphics/defaultTemplate.png'
              }
              alt={'theme'}
            />
          </div>
          <div>
            <p className="fridayH2">Selected Theme</p>
            <p className="didactic">{props.selectedTemplate?.name}</p>
            <p>{props.selectedTemplate?.description}</p>
          </div>
        </div>
        <br />
        <br />
        <p>Available Templates</p>
        <ul>
          {templates.map((template) => {
            return (
              <li key={template.id}>
                <div
                  onKeyDown={undefined}
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    props.setSelectedTemplate(template);
                    window.scrollTo(0, 0);
                  }}
                >
                  <img
                    className={props.selectedTemplate === template ? styles.Selected : ''}
                    src={template?.imageUrl ? template.imageUrl : '/assets/graphics/defaultTemplate.png'}
                    alt={'theme'}
                  />
                  <p className="didactic" style={{ justifySelf: 'center', textAlign: 'center' }}>
                    {template?.name}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );

  return (
    <div
      id="selectTemplate"
      className={styles.SelectedTemplate}
      style={typeOfScreen === ScreenType.laptop ? { width: '100%' } : {}}
    >
      <div>
        <img
          src={
            props.selectedTemplate?.imageUrl ? props.selectedTemplate.imageUrl : '/assets/graphics/defaultTemplate.png'
          }
          alt={'theme'}
        />
      </div>
      <div>
        <p className="fridayH2">Selected Theme</p>
        <p className="didactic">{props.selectedTemplate?.name}</p>
        <p>{props.selectedTemplate?.description}</p>
      </div>
    </div>
  );
};

export default SelectTemplate;
