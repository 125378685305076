import React, { ReactElement, useEffect, useState } from 'react';

import styles from './DebugTemplates.module.scss';
import { TemplateComponents } from '../../../templates';
import { AvailableTemplates } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { selectAccessToken } from '../../../app/redux/authorization.slice.reducer';
import { useSelector } from 'react-redux';
import { TemplatePreview } from '../../dashboard/templatePreview/TemplatePreview';

export const DebugTemplates = (): JSX.Element => {
  const [selectedTemplate, setSelectedTemplate] = useState<AvailableTemplates>();
  const [sectionComponentsList, setSectionComponentsList] = useState<string[]>([]);
  const [componentProps, setComponentProps] = useState<Array<any>>([]);
  const [previewView, setPreviewView] = useState<0 | 1 | 2>(0);
  const [componentList, setComponentList] = useState<Array<ReactElement>>([]);
  const [templates, setTemplates] = useState<Array<AvailableTemplates>>([]);
  const jwt = useSelector(selectAccessToken);

  const listTemplatesHelper = () => {
    BrainHelper.listAvailableTemplates(jwt).then((res) => {
      setTemplates(res.reverse());
      if (res.length > 0) {
        setSelectedTemplate(res[0]);
      }
    });
  };

  useEffect(() => {
    listTemplatesHelper();
  }, []);

  useEffect(() => {
    if (sectionComponentsList && componentProps) {
      const tempComponentList: Array<ReactElement> = [];
      console.log(sectionComponentsList);
      sectionComponentsList?.forEach(
        (component, index) => {
          if (component !== undefined && component[index] !== undefined) {
            if (TemplateComponents(component) !== undefined) {
              tempComponentList.push(TemplateComponents(component, { ...componentProps[index], key: index }, index));
            }
          }
        },
        [componentProps, sectionComponentsList],
      );
      setComponentList(tempComponentList);
    }
  }, [sectionComponentsList, componentProps]);

  if (process.env.REACT_APP_ENABLE_TEMPLATE_DEBUG !== 'true') {
    window.location.href = '/dashboard';
  }

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100vw',
          height: 'fit-content',
          background: '#105E2828',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <span style={{ display: 'flex', gap: 8 }}>
          Template:
          <select
            name="templates"
            id="templates"
            onChange={(e: any) => {
              console.log(e.target.value);
              setSelectedTemplate(JSON.parse(e.target.value));
            }}
          >
            {templates.map((template) => (
              <option key={template.name} value={JSON.stringify(template)}>
                {template.name}
              </option>
            ))}
          </select>
        </span>
      </div>
      <div style={{ height: '100%', marginTop: '20px' }}>
        {componentProps && selectedTemplate && selectedTemplate.sectionComponents && (
          <TemplatePreview
            sectionComponentsList={selectedTemplate.sectionComponents}
            mode="debug"
            componentProps={componentProps}
          />
        )}
      </div>
    </div>
  );
};

export default DebugTemplates;
