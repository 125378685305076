import React, { useEffect, useRef, useState } from 'react';
import styles from './AddToEventModal.module.scss';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import UserInfo from '../userInfo/UserInfo';
import Icon from '../../../../shared/Icon';
import { useSelector } from 'react-redux';
import { selectJwt } from '../../../../app/redux/authorization.slice.reducer';
import { selectWedding } from '../../../../app/redux/wedding.slice.recuder';
import { AllowedVisitor, RSVPEvent } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import FilledButton from '../../table/FilledButton';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { toast } from 'react-toastify';
import { logger } from '../../../../config/Logger';

type AddToEventModalProps = {
  isActive: boolean;
  onClose: any;
  selectedGuestsObjects: Map<string, AllowedVisitor>;
  setSelectedGuestsObjects: any;
  visitors: AllowedVisitor[];
  rsvpEvents: RSVPEvent[];
};
const AddToEventModal = ({
  isActive,
  onClose,
  selectedGuestsObjects,
  setSelectedGuestsObjects,
  visitors,
  rsvpEvents,
}: AddToEventModalProps) => {
  const jwt = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);
  const [sendStep, setSendStep] = useState<number>(0);
  const [sendQuery, setSendQuery] = useState('');
  const [guestList, setGuestList] = useState(Array<AllowedVisitor>());
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<RSVPEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const modalRef = useRef(null);

  useOutsideClick(
    modalRef,
    () => {
      // setGuestList([]);
      // setSendStep(0);
      onClose();
    },
    'guestList',
  );

  useEffect(() => {
    if (sendQuery.length > 0) {
      if (sendQuery.trim() !== '') {
        setGuestList(
          visitors.filter(
            (item) =>
              item.firstName.toLowerCase().includes(sendQuery.toLowerCase()) ||
              item.lastName.toLowerCase().includes(sendQuery.toLowerCase()) ||
              item.email.toLowerCase().includes(sendQuery.toLowerCase()) ||
              item.phone.includes(sendQuery),
          ),
        );
      } else {
        setGuestList([]);
      }
    } else {
      setGuestList([]);
    }
  }, [sendQuery]);

  const filteredArrayToShow = guestList.filter(
    (item) => !Array.from(selectedGuestsObjects.values()).some((excludeItem) => excludeItem.id === item.id),
  );

  return (
    <div className={styles.modal + ' ' + (isActive ? styles.modalActive : '')}>
      <div ref={modalRef}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 className={'fridayH3'}>Add to Events</h3>
          <button
            style={{ border: 'none', background: 'none' }}
            onClick={(e) => {
              onClose();
              e.preventDefault();
            }}
          >
            <img src="/assets/icons/icon-cancel.svg" alt="exit" width={26} />
          </button>
        </div>
        <p className={'didacticP'}>
          You can select multiple guests and send out invitations. Make sure you have selected the ones you need.
        </p>
        <h4 className={'fridayH4'}>Selected Guests</h4>
        <div>
          <div className={styles.searchBar + ' ' + (isSearchActive ? styles.searchBarActive : '')}>
            <img src={'/rsvp/guests/searchIcon' + (isSearchActive ? '-highlighted' : '') + '.svg'} alt="Search" />
            <input
              style={{ width: '100%' }}
              value={sendQuery}
              placeholder="Search user name, email, phone"
              onFocus={() => setIsSearchActive(true)}
              onBlur={() => setIsSearchActive(false)}
              onChange={(e) => {
                setSendQuery(e.target.value);
              }}
            />
          </div>
          {guestList.length > 0 && true ? (
            <div className={styles.modalSearchResults} id={'guestList'}>
              {filteredArrayToShow.length > 0 ? (
                filteredArrayToShow.map((g) => {
                  return (
                    <button
                      key={g.id}
                      id={'guestList' + g.id}
                      className={styles.modalSearchResultsButton}
                      onClick={(e) => {
                        const selectedTemp = new Map(selectedGuestsObjects);
                        selectedTemp.set(g.id, g);
                        setSelectedGuestsObjects(selectedTemp);
                        setGuestList([]);
                        setSendQuery('');
                      }}
                    >
                      <UserInfo
                        userToken={jwt}
                        firstName={g.firstName}
                        lastName={g.lastName}
                        imageSrc={g.connectedAccountDetails?.imageProfileUrl}
                        setSelectedGuest={() => console.log()}
                      />
                    </button>
                  );
                })
              ) : (
                <p className={'didacticP'}>No matches left</p>
              )}
            </div>
          ) : (
            ''
          )}
          <div className={styles.selected}>
            {selectedGuestsObjects &&
              Array.from(selectedGuestsObjects).map((value) => {
                return (
                  <div key={value[0]} className={styles.selectedGuest}>
                    <UserInfo
                      userToken={jwt}
                      firstName={value[1].firstName}
                      lastName={value[1].lastName}
                      imageSrc={value[1].connectedAccountDetails?.imageProfileUrl}
                      showName={false}
                      isSmaller={true}
                    />
                    <div className={styles.selectedGuestTick}>
                      <Icon name={'tick'} size={'s'} />
                    </div>
                    {value[1].firstName}
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          <h4 className={'fridayH4'}>Events</h4>
          <div>
            {rsvpEvents.map((event) => {
              return (
                <button
                  key={event.id}
                  className={styles.event + ' ' + (selectedEvents.includes(event) ? styles.eventSelected : '')}
                  onClick={() => {
                    const selectedTemp = [...selectedEvents];
                    if (selectedTemp.includes(event)) {
                      selectedTemp.splice(selectedTemp.indexOf(event), 1);
                    } else selectedTemp.push(event);
                    setSelectedEvents(selectedTemp);
                  }}
                >
                  <Icon
                    name={wedding.mainEvent?.id === event.id ? 'ceremony' : 'event'}
                    size={'sPlus'}
                    color={'#281B24'}
                  />
                  <div>
                    <p className={'didacticP'}>{event.name}</p>
                    <p className={styles.smallText}>Click to Add</p>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
        <div>
          <FilledButton
            id={'inviteButton'}
            title={'ADD'}
            disabled={selectedGuestsObjects.size <= 0 || selectedEvents.length <= 0}
            icon={'send_filled'}
            _className={styles.sendButton}
            _callback={async () => {
              const hasError: Map<string, boolean> = new Map();
              for (const guest of selectedGuestsObjects) {
                const response = await BrainHelper.attachRSVPEventFromAllowedVisitors(
                  jwt,
                  guest[1].id,
                  wedding.id,
                  selectedEvents.map((e) => e.id),
                );
                if (!response) hasError.set(guest[1].id, true);
              }
              if (hasError.size === 0) {
                toast('Successfully invited!', {
                  style: { backgroundColor: '#A3F5E6' },
                });
              } else {
                logger.error({ message: 'Failed to invite: ' + hasError, functionName: 'AddToEventModal' });
                toast('Failed to invite some users. Please try again later.', {
                  icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
                  style: { backgroundColor: '#F8AAB8' },
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddToEventModal;
