import styles from './ImportedGuestsModal.module.scss';
import { Fragment, useRef, useState } from 'react';
import { logger } from '../../../../config/Logger';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectJwt } from '../../../../app/redux/authorization.slice.reducer';
import { toast, ToastOptions } from 'react-toastify';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ExcelRenderer } from 'react-excel-renderer';
import { RSVPEvent, TaxonomyOption, Wedding } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { useDropzone } from 'react-dropzone';
import Icon from '../../../../shared/Icon';
import Lottie from 'react-lottie';
import location from '../../../../loader-animation.json';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import {
  addTaxonomyOptions,
  selectRsvpEvents,
  selectTaxonomyOptions,
} from '../../../../app/redux/wedding.slice.recuder';
import useMobileCheck from '../../../../hooks/useMobileChjeck';
import { useTypeOfScreen } from '../../../../hooks';

const ImportedGuestsModal = (props: {
  show: boolean;
  setShow: any;
  taxonomyId: string;
  wedding: Wedding;
}): JSX.Element => {
  const typeOfScreen = useTypeOfScreen();
  const dispatch = useDispatch();
  const jwtToken = useSelector(selectJwt);
  const RSVPEvents = useSelector(selectRsvpEvents);
  const taxonomiesList = useSelector(selectTaxonomyOptions);
  const [importedGuests, setImportedGuests] = useState<{ index: number; user: string[]; error: string | undefined }[]>(
    [],
  );
  const [isDropzoneActive, setIsDropzoneActive] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalMessage, setModalMessage] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showModal, setShowModal] = useState<boolean>(false);
  const [saveBtnHover, setSaveBtnHover] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [fileObj, setFileObj] = useState<any>(undefined);
  const [myFiles, setMyFiles] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCanceled, setIsCanceled] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isCanceledRef = useRef(isCanceled);
  const importModalRef = useRef(null);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 10000000,
    accept: {
      'application/vnd.ms-excel': [],
      'text/csv': [],
      'application/vnd.oasis.opendocument.spreadsheet': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDragEnter: (dragEvent) => {
      setIsDropzoneActive(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDragLeave: (dragEvent) => {
      setIsDropzoneActive(false);
    },
    onDropAccepted: async (fileAccepted) => {
      setMyFiles(fileAccepted);
      const fileObj = fileAccepted[0];
      if (
        fileObj.type !== 'application/vnd.ms-excel' &&
        fileObj.type !== 'text/csv' &&
        fileObj.type !== 'application/vnd.oasis.opendocument.spreadsheet' &&
        fileObj.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        return;
      } else setFileObj(fileObj);
    },
    onDropRejected: (fileRejections) => {
      toast('Please only Upload one file of csv/xls/xlsx format of max size 10Mb.', {
        time: 5000,
        icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
        style: { backgroundColor: '#F8AAB8' },
      } as ToastOptions);
      logger.error({ message: fileRejections[0].errors[0].message });
      setShowModal(true);
    },
  });

  const files = myFiles.map((file: any) => (
    <p className={'didacticP'} style={{ display: 'flex', justifyContent: 'space-between' }} key={file.path}>
      {file.path} <img src="/rsvp/delete.svg" alt="delete" />
    </p>
  ));

  const fileHandler = async () => {
    if (fileObj === undefined) return;
    //just pass the fileObj as parameter
    await ExcelRenderer(fileObj, async (err: any, resp: { cols: any; rows: any }) => {
      const imported: { index: number | string; user: string[]; error: string | undefined }[] = [];
      let hasError = false;
      if (err) {
        await logger.error({ message: err, functionName: 'ImportedGuestsModal.tsx.fileHandler' });
        toast('Something went wrong. Check your file and try again later.', {
          time: 5000,
          icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
          style: { backgroundColor: '#F8AAB8' },
        } as ToastOptions);
        return;
      } else {
        const i = resp.rows.findIndex(
          (row: any) =>
            row[0] === 'Firstname' &&
            row[1] === 'Lastname' &&
            row[2] === 'Email' &&
            row[3] === 'Phone' &&
            row[4] === 'AllowedPlus1' &&
            row[5] === 'Tags' &&
            row[6] === 'Events' &&
            row[7] === 'IsHost',
        );
        if (i !== 0) {
          toast('Something went wrong. Missing columns. PLease try again after checking your file.', {
            time: 5000,
            icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
            style: { backgroundColor: '#F8AAB8' },
          } as ToastOptions);
          setMyFiles([]);
          return;
        }
        setIsImporting(true);
        const responses = await fetchInBatches(resp.rows, 20);
        for (const response in responses) {
          if (
            typeof responses[response] === 'string' &&
            (responses[response].includes('email') || responses[response].includes('phone'))
          ) {
            hasError = true;
            setImportedGuests((prevState: any) => [
              ...prevState,
              {
                index: response,
                user: response,
                error: `Email: ${resp.rows[response][2]} or Phone number: ${resp.rows[response][3]} already exists`,
              },
            ]);
          } else if (typeof responses[response] === 'string') {
            hasError = true;
            setImportedGuests((prevState: any) => [
              ...prevState,
              { index: response, user: responses[response], error: 'Something went wrong' },
            ]);
          } else if (responses[response] == undefined) {
            continue;
          } else {
            setImportedGuests((prevState: any) => [
              ...prevState,
              { index: response, user: responses[response], error: undefined },
            ]);
            imported.push({ index: response, user: responses[response], error: undefined });
          }
        }
      }
      setSaveBtnHover(false);
      setIsImporting(false);
      setMyFiles([]);
      setFileObj(undefined);
      if (hasError) setHasError(true);
      if (imported.length > 0)
        toast(`Nice! ${imported.length} guest${imported.length > 1 ? 's have' : ' has'} been imported successfully!`, {
          style: { backgroundColor: '#A3F5E6' },
        });
      if (imported.length === resp.rows.length - 1) {
        closeModal();
      }
    });
  };

  async function fetchInBatches(rows: any[], batchSize: number) {
    // Function to fetch data from a single URL
    async function fetchData(row: any) {
      if (row[0] === 'Firstname') return;
      if (row.length <= 0) return;
      //correct find of taxonomy options
      const tagsToAdd: string[] = [];
      if (row[5]) {
        const tags = row[5]?.split(',');
        tags.map(async (tag: string) => {
          const taxonomy = taxonomiesList?.find((taxonomy) => taxonomy.key === tag.trim());
          if (!taxonomy) {
            const newTaxonomy = await BrainHelper.createTaxonomyOption(
              props.taxonomyId,
              tag.trim(),
              '',
              '',
              '',
              jwtToken,
              props.wedding.id,
              dispatch,
              addTaxonomyOptions,
            );
            if (newTaxonomy && newTaxonomy?.id) tagsToAdd.push(newTaxonomy.id);
            else
              logger.error({
                message: 'ERROR FOR TAG: ' + tag,
                functionName: 'ImportedGuestsModal.tsx.fileHandler',
              });
          } else tagsToAdd.push(taxonomy.id);
        });
      }
      //correct find of invited events
      const rsvpEventsToAdd: string[] = [];
      if (row[6]) {
        const rsvpEvents = row[6]?.split(',');
        rsvpEvents?.forEach((rsvpEvent: string) => {
          const e = RSVPEvents?.find((e) => e.name === rsvpEvent.trim());
          if (!e) {
            logger.error({
              message: 'NOT FOUND EVENT:  ' + e,
              functionName: 'ImportedGuestsModal.tsx.fileHandler',
            });
          } else rsvpEventsToAdd.push(e.id);
        });
      }
      const phone = row[3] ? (row[3]?.toString().includes('+') ? row[3] : '+' + row[3]) : '';

      const user = BrainHelper.createAllowedVisitor(
        jwtToken,
        props.wedding.id,
        row[2], //email
        phone, //phone
        row[0].trim(), //firstname
        row[1].trim(), //lastname
        '',
        '',
        row[4], //max allowed plus 1
        tagsToAdd, //taxonomy options
        rsvpEventsToAdd, //rsvp events
        /(T|t)(R|r)(U|u)(E|e)/gm.test(row[7]), //host
        false,
      );

      console.log(user);
      return await user;
    }

    const results: any[] = [];
    let currentBatch: any[] = [];
    const phones: any[] = [];
    const emails: any[] = [];
    for (const row of rows) {
      if (!phones.includes(row[3]) && !emails.includes(row[2])) {
        if (row[3]) phones.push(row[3]);
        if (row[2]) emails.push(row[2]);
        currentBatch.push(row);
      } else {
        currentBatch.push('error email or phone already exists');
      }

      if (currentBatch.length === batchSize) {
        const batchResults = await Promise.all(
          currentBatch.map((data) => {
            if (data.includes('error')) return data;
            else return fetchData(data);
          }),
        );
        for (const b in batchResults) {
          results.push(batchResults[b]);
        }
        currentBatch = [];
      }
    }

    // Fetch any remaining URLs in the last batch
    if (currentBatch.length > 0) {
      const batchResults = await Promise.all(
        currentBatch.map((data) => {
          if (data.includes('error')) return data;
          else return fetchData(data);
        }),
      );
      for (const b in batchResults) {
        results.push(batchResults[b]);
      }
    }

    return results;
  }

  const closeModal = () => {
    props.setShow(!props.show);
    setImportedGuests([]);
    setHasError(false);
    setFileObj(undefined);
    setMyFiles([]);
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useOutsideClick(importModalRef, () => closeModal());
  const isMobile = useMobileCheck();
  return (
    <div
      id={'importedGuestModal'}
      ref={importModalRef}
      className={props.show ? styles.popUp + ' ' + styles[`popUp${typeOfScreen}`] : styles.hidden}
      style={{ zIndex: 200000002, width: isMobile ? '100%' : '' }}
    >
      {!isImporting && !hasError ? (
        <div style={{ width: isMobile ? '100%' : '' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              padding: '40px 32px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                <img src={'/rsvp/guests/toolbox/importList-dark.svg'} alt="icon" width={24} />
                <h2 className={'fridayH2'}>Imported Guest List</h2>
              </div>
              <button
                id={'importedGuestModalClose'}
                style={{ border: 'none', background: 'none' }}
                onClick={(e) => {
                  closeModal();
                  e.preventDefault();
                }}
              >
                <img src="/assets/icons/icon-cancel.svg" alt="exit" width={24} />
              </button>
            </div>

            <p className={'didacticP'}>
              With import list you can add multiple guests at the same time. Follow the steps bellow to ensure the
              correct import.
            </p>

            <h4 className={'didacticH4'}>Step 1</h4>

            <p className={'didacticP'}>
              Download our template. You can open it with the program of your choice (Microsoft Excel, Google Sheets,
              Numbers etc...) and fill the guest information in the corresponding boxes.
            </p>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginLeft: 18 }}>
                <Icon name={'spreadsheet_file'} size={'s'} />
                <a href={process.env.REACT_APP_ONLINE_DETECTOR + '/samples/sample.csv'} className={'didacticP'}>
                  Download Template(.csv)
                </a>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginLeft: 18 }}>
                <Icon name={'spreadsheet_file'} size={'s'} />
                <a href={process.env.REACT_APP_ONLINE_DETECTOR + '/samples/sample.xlsx'} className={'didacticP'}>
                  Download Template(.xlsx)
                </a>
              </div>
            </div>

            <h4 className={'didacticH4'}>Step 2</h4>

            <p className={'didacticP'}>After filling the template you can upload it here. </p>

            <div className={styles.dragNDrop} style={{ width: '100%', maxWidth: '100%' }}>
              <div
                role={'button'}
                tabIndex={0}
                onMouseDown={() => setIsDropzoneActive(true)}
                onMouseUp={() => setIsDropzoneActive(false)}
                className={styles.dropzone + ' ' + (files?.length > 0 ? styles.dragNDropPathHighlighted : '')}
                {...getRootProps()}
                style={isDropzoneActive || files?.length > 0 ? { border: 'solid 1px #00C9A5' } : {}}
              >
                <input {...getInputProps()} />
                {files?.length > 0 ? (
                  <Fragment>
                    <Icon name={'spreadsheet_file'} color={'#00C9A5'} size={'m'} />
                    <p
                      className={'didacticP'}
                      style={isDropzoneActive ? { color: '#00C9A5!important', width: '100%' } : { width: '100%' }}
                    >
                      {files}
                    </p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div style={{ display: 'flex', gap: 8, width: '100%', maxWidth: '100%' }}>
                      <img src={'/rsvp/upload' + (isDropzoneActive ? '-hovered' : '') + '.svg'} alt="upload" />
                      <p className={'didacticP'} style={isDropzoneActive ? { color: '#00C9A5' } : {}}>
                        Drag & drop here, or{' '}
                        <span className={styles.highlighted} style={isDropzoneActive ? { color: '#00C9A5' } : {}}>
                          browse
                        </span>{' '}
                        your local files.
                      </p>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>

            {/*<div className={styles.importedGuests}>*/}
            {/*  <div className={styles.guest}>*/}
            {/*    <p>#</p>*/}
            {/*    <p>Firstname</p>*/}
            {/*    <p>Lastname</p>*/}
            {/*    <p>Email</p>*/}
            {/*    <p>Phone</p>*/}
            {/*    <p>Error</p>*/}
            {/*  </div>*/}
            {/*  {props.rows*/}
            {/*    .sort((a, b) => a.index - b.index)*/}
            {/*    .map((row) => {*/}
            {/*      return (*/}
            {/*        <div key={row.index} className={styles.guest + ' ' + (row.error ? styles.guestError : '')}>*/}
            {/*          <p>{row.index}</p>*/}
            {/*          <p>{row.user[0]}</p>*/}
            {/*          <p>{row.user[1]}</p>*/}
            {/*          <p>{row.user[2]}</p>*/}
            {/*          <p>+{row.user[3]}</p>*/}
            {/*          <p>{row.error}</p>*/}
            {/*        </div>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*</div>*/}
          </div>
          <button
            className={'btnIconSecondary ' + styles.saveGuestButton}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '56px',
            }}
            onMouseEnter={() => setSaveBtnHover(true)}
            onMouseLeave={() => setSaveBtnHover(false)}
            onClick={async () => {
              await fileHandler();
            }}
          >
            <img
              src={'/rsvp/guests/toolbox/importList-' + (saveBtnHover ? 'light' : 'dark') + '.svg'}
              alt="exit"
              width={16}
              style={{ marginRight: 4, alignSelf: 'center', justifySelf: 'center' }}
            />
            Import Guest List
          </button>
        </div>
      ) : (
        <Fragment>
          {!hasError || isImporting ? (
            <div>
              <div></div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  gap: 16,
                  padding: '40px 32px',
                }}
              >
                <h3 className={'didacticH3'}>Importing Guest List</h3>
                <p className={'didacticP'}>
                  Please give us a minute to check for any mistakes. Do not close this page as we will inform you for
                  the status of your import.
                </p>
                <Lottie options={defaultOptions1} height={72} width={72} />
              </div>
              <div>
                {/*TODO: FIND A WAY TO CANCEL*/}
                {/*<button*/}
                {/*  className={'btnSecondary ' + styles.saveGuestButton}*/}
                {/*  style={{*/}
                {/*    display: 'flex',*/}
                {/*    flexDirection: 'row',*/}
                {/*    justifyContent: 'center',*/}
                {/*    alignItems: 'center',*/}
                {/*    width: '100%',*/}
                {/*    height: '56px',*/}
                {/*  }}*/}
                {/*  onClick={() => {*/}
                {/*    setIsCanceled(true);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Cancel*/}
                {/*</button>*/}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                  padding: '40px 32px',
                }}
              >
                <h3 className={'didacticH3'}>Importing Guest List</h3>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    background: '#F8AAB8',
                    borderRadius: 4,
                    padding: '8px 16px',
                  }}
                >
                  <Icon name={'close'} color={'#98243A'} size={'s'} />
                  <p className={'didacticP'} style={{ color: '#98243A' }}>
                    Guest list import failed.
                  </p>
                </div>
                <p className={'didacticP'}>
                  Make sure you are using our template for your guest list import. You can download it using the button
                  bellow.
                </p>
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginLeft: 18 }}>
                    <Icon name={'spreadsheet_file'} size={'s'} />
                    <a href={process.env.REACT_APP_ONLINE_DETECTOR + '/samples/sample.csv'} className={'didacticP'}>
                      Download Template(.csv)
                    </a>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginLeft: 18 }}>
                    <Icon name={'spreadsheet_file'} size={'s'} />
                    <a href={process.env.REACT_APP_ONLINE_DETECTOR + '/samples/sample.xlsx'} className={'didacticP'}>
                      Download Template(.xlsx)
                    </a>
                  </div>
                </div>
                <h4 className={'didacticH4'} style={{ marginTop: 8 }}>
                  Errors
                </h4>
                {importedGuests.map((row) => {
                  if (row.error && row.index > 0)
                    return (
                      <p key={row.index} className={'didacticP'} style={{ color: '#98243A' }}>
                        {'Row: ' + (Number(row.index) + 1) + ', with Error: ' + row.error}
                      </p>
                    );
                })}
              </div>
              <div>
                <button
                  className={'btn btnMain btnMainWithImg'}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '56px',
                  }}
                  onMouseEnter={() => setSaveBtnHover(true)}
                  onMouseLeave={() => setSaveBtnHover(false)}
                  onClick={async () => {
                    setHasError(false);
                    setIsImporting(false);
                    setMyFiles([]);
                    setFileObj(undefined);
                    setImportedGuests([]);
                  }}
                >
                  <img
                    src={'/rsvp/guests/toolbox/importList-' + (saveBtnHover ? 'light' : 'dark') + '.svg'}
                    alt="exit"
                    width={16}
                    style={{ marginRight: 4, alignSelf: 'center', justifySelf: 'center' }}
                  />
                  Import Guest List
                </button>
                <button
                  className={'btnSecondary ' + styles.saveGuestButton}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '56px',
                  }}
                  onClick={(e) => {
                    closeModal();
                    e.preventDefault();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ImportedGuestsModal;
