import React, { Fragment, useEffect, useState } from 'react';
import styles from './Overview.module.scss';
import { dateTimeFormat } from '../../../config/dateFormats';
import { AllowedVisitor, RSVPEvent } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Link } from '@mui/material';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { useSelector } from 'react-redux';
import { selectJwt } from '../../../app/redux/authorization.slice.reducer';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { Avatar } from '../table/Table';

const OverviewEvent = (props: {
  value?: RSVPEvent;
  isAdd: boolean;
  setShowModal?: any;
  setSelectedRsvpEvent?: any;
  setSelectedColor?: any;
  navigate?: any;
  selectedRsvpEvent?: RSVPEvent;
  color?: string;
}): JSX.Element => {
  const userToken = useSelector(selectJwt);
  const [invited, setInvited] = useState<AllowedVisitor[]>([]);
  const [total, setTotal] = useState<number>(0);
  const wedding = useSelector(selectWedding);

  useEffect(() => {
    if (props.value) listInvitedUsers(props.value.id);
  }, [props.value]);

  async function listInvitedUsers(rsvpId: string) {
    const invitedList = await BrainHelper.getAllowedVisitors(userToken, '', '', undefined, false, rsvpId, 0, 5);
    setInvited(invitedList.allowedVisitors);
    setTotal(Number(invitedList.total));
  }

  return (
    <div
      role={'button'}
      tabIndex={0}
      id={'event' + props.value?.id}
      className={styles.event + (props.isAdd ? ' ' + styles.eventAdd : '')}
      style={
        props.selectedRsvpEvent && props.selectedRsvpEvent?.id === props.value?.id
          ? { border: '1px solid #F78C50', width: '300px' }
          : { width: '300px' }
      }
      onMouseDown={(e) => {
        if (e.button === 2) return;
        if (props.setShowModal) props.setShowModal(true);
        if (props.setSelectedRsvpEvent) props.setSelectedRsvpEvent(props.value);
        if (props.setSelectedColor) props.setSelectedColor(props.color);
        if (props.isAdd && props.navigate)
          props.navigate(`/dashboard/w/${wedding.path}/planning?step=2&eventId=NEW_EVENT`);
      }}
    >
      <div
        className={styles.eventIcon}
        style={{
          backgroundColor: props.color,
        }}
      >
        <img src="/assets/icons/icon-calendar-white.svg" alt="calendar" />
      </div>
      {props.value ? (
        <div style={{ width: '100%' }}>
          <p>
            {props.value?.startTimestamp &&
              dateTimeFormat({ weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' }).format(
                new Date(props.value?.startTimestamp),
              )}
          </p>
          <h4 className={'didacticH4'} style={{ width: '100%', textOverflow: 'ellipsis' }}>
            {props.value?.name}
          </h4>
          <p className={'didacticP'} style={{ marginTop: '48px' }}>
            Invited Guests ({total})
          </p>
          <div className={styles.invited}>
            {invited?.map((v) => {
              return (
                <Avatar
                  key={v.id}
                  name={`${v?.firstName} ${v?.lastName}`}
                  userToken={String(userToken)}
                  connectedAccountDetails={v.connectedAccountDetails}
                  query={''}
                />
              );
            })}
            {total > 5 ? (
              <p className={'didacticP'} style={{ marginLeft: '8px' }}>
                +more
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        <div className={styles.addBtn}>
          <Link className={'didacticH4'} href={`/dashboard/w/${wedding.path}/planning?step=2&eventId=NEW_EVENT`}>
            + Add event
          </Link>
        </div>
      )}
    </div>
  );
};

export default OverviewEvent;
