import React, { Fragment, useEffect, useState } from 'react';
import styles from './Firebase.module.scss';
import Header from '../header/Header';
import firebase from 'firebase';
import { Link, useNavigate } from 'react-router-dom';
import { logger } from '../../../config/Logger';

const VerifyEmailComponent = (props: {
  auth: firebase.auth.Auth;
  actionCode: string;
  continueUrl: string;
  lang: string;
}): JSX.Element => {
  const [errorState, setErrorState] = useState<string | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.auth) setErrorState('Something went wrong. Please try again later.');
    else
      props.auth
        .applyActionCode(props.actionCode)
        .then((resp) => {
          logger.log({ message: resp });
        })
        .catch((error) => {
          setErrorState(error.message);
        });
  }, []);
  return (
    <Fragment>
      <div className={styles.main}>
        <Header title={'Verification'} />
        <div className={styles.graphics}>
          <>
            <img src={'/assets/graphics/entrance-graphics-1.svg'} alt={''} />
            <img src={'/assets/graphics/entrance-graphics-2.svg'} alt={''} />
          </>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h2 className={'didacticH2 ' + (errorState ? styles.error : styles.success)}>
              {errorState
                ? errorState
                : 'Your account has been successfully verified. Click below to redirect to log in page.'}
            </h2>
            <h3 className={'didacticH3'} style={{ marginTop: '24px' }}>
              <Link to={props.continueUrl}>
                <button
                  className={'btn btnMain4th'}
                  onClick={() => {
                    navigate(props.continueUrl);
                  }}
                >
                  Log In
                </button>
              </Link>
            </h3>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VerifyEmailComponent;
