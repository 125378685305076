import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../header/Header';
import styles from './Entrance.module.scss';
import firebase from 'firebase';
import { FirebaseContext } from '../../../context/firebase.context';
import { useDispatch, useSelector } from 'react-redux';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { selectAccount, selectIsLoggedIn, selectUser } from '../../../app/redux/authorization.slice.reducer';
import { Wedding } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import cleanLocalStorage from '../../../app/helper/CleanLocalStorage';
import { transliterate } from 'transliteration';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
const CompleteRegistration = (props: any): JSX.Element => {
  const user = useSelector(selectUser);
  const loggedIn = useSelector(selectIsLoggedIn);
  const account = useSelector(selectAccount);
  const wedding: Wedding = useSelector(selectWedding);
  const userRef = React.useRef<any>(user);
  userRef.current = user;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    FirebaseContext.getAuth();
    if (loggedIn && wedding.path === 'no-marriage-yet') {
      navigate(`/dashboard/choose-event/start-planning`);
    } else if (
      loggedIn &&
      (account?.hostForWeddingId === wedding?.id || account?.moderatorForWeddingId === wedding?.id)
    ) {
      navigate(`/dashboard/w/${transliterate(wedding.path)}/overview`);
    } else {
      firebase.auth().signOut();
      cleanLocalStorage();
    }
  }, []);

  return (
    <div className={styles.main}>
      <Header login={false} />
      <div className={styles.graphics}>
        <>
          <img src={'/assets/graphics/entrance-graphics-3.svg'} alt={''} />
          <img src={'/assets/graphics/entrance-graphics-4.svg'} alt={''} />
        </>
      </div>
      <div className={styles.wrapper} style={{ height: '558px' }}>
        <div className={styles.header}>
          <h1 className="fridayH1">Thank you</h1>
          <p></p>
          <p className="didacticP">Your account was created successfully.</p>
          <p>Please check you email for a verification link to complete the registration.</p>
          <p>(Please also check your spam folder if you can{"'"}t find it in the inbox)</p>
        </div>
      </div>
    </div>
  );
};

export default CompleteRegistration;
