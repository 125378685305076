import React, { useEffect, useRef, useState } from 'react';
import styles from './EmailInvitationModal.module.scss';
import { AllowedVisitor } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import Icon from '../../../../shared/Icon';
import {
  InvitationOptionInvite,
  InvitationOptionReminder,
  InvitationOptionSaveTheDate,
} from '../../../../constants/invitation.constants';
import { toast, ToastOptions } from 'react-toastify';
import Lottie from 'react-lottie';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import { logger } from '../../../../config/Logger';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { useSelector } from 'react-redux';
import { selectJwt } from '../../../../app/redux/authorization.slice.reducer';
import { selectWedding } from '../../../../app/redux/wedding.slice.recuder';
import UserInfo from '../userInfo/UserInfo';
import FilledButton from '../../table/FilledButton';
import { lottieDefaultOptions } from '../../../../shared/Options';
import FilledNoIconButton from '../../table/FilledNoIconButton';
import { QRCode } from 'react-qrcode-logo';

type EmailInvitationModalProps = {
  isActiveSendModal: boolean;
  onClose: any;
  sendOption: string;
  setSendOption: any;
  selectedGuestsObjects: Map<string, AllowedVisitor>;
  setSelectedGuestsObjects: any;
  visitors: AllowedVisitor[];
};

const EmailInvitationModal = ({
  isActiveSendModal,
  onClose,
  sendOption,
  setSendOption,
  selectedGuestsObjects,
  setSelectedGuestsObjects,
  visitors,
}: EmailInvitationModalProps) => {
  const jwt = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);
  const [sendStep, setSendStep] = useState<number>(0);
  const [sendQuery, setSendQuery] = useState('');
  const [guestList, setGuestList] = useState(Array<AllowedVisitor>());
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const sendModalRef = useRef(null);

  const optionsName: { [key: string]: string } = {
    save_the_date: 'a Save the Date',
    invitation: 'an Invitation & RSVP',
    reminder: 'a Reminder',
  };

  useOutsideClick(
    sendModalRef,
    () => {
      setGuestList([]);
      setSendStep(0);
      onClose();
    },
    'guestList',
  );

  useEffect(() => {
    if (sendQuery.length > 0) {
      if (sendQuery.trim() !== '') {
        setGuestList(
          visitors.filter(
            (item) =>
              item.firstName.toLowerCase().includes(sendQuery.toLowerCase()) ||
              item.lastName.toLowerCase().includes(sendQuery.toLowerCase()) ||
              item.email.toLowerCase().includes(sendQuery.toLowerCase()) ||
              item.phone.includes(sendQuery),
          ),
        );
      } else {
        setGuestList([]);
      }
    } else {
      setGuestList([]);
    }
  }, [sendQuery]);

  const downloadCode = () => {
    const canvas = document.getElementById('QR') as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `whiteclover_app.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const filteredArrayToShow = guestList.filter(
    (item) => !Array.from(selectedGuestsObjects.values()).some((excludeItem) => excludeItem.id === item.id),
  );

  const sendSteps = [
    //step 1 INVITATION
    <div key={0} style={{ margin: 'auto', maxWidth: '100vw' }} ref={sendModalRef}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3 className={'didacticH3'}>Send</h3>
        <button
          style={{ border: 'none', background: 'none' }}
          onClick={(e) => {
            onClose();
            setGuestList([]);
            setSendStep(0);
            e.preventDefault();
          }}
        >
          <img src="/assets/icons/icon-cancel.svg" alt="exit" width={26} />
        </button>
      </div>
      <p className={'didacticP'}>
        You can select multiple guests and send out invitations. Make sure you have selected the ones you need.
      </p>
      <h4 className={'didacticH4'}>Selected Guests</h4>
      <div style={{ height: '104px' }}>
        <div className={styles.searchBar + ' ' + (isSearchActive ? styles.searchBarActive : '')}>
          <img src={'/rsvp/guests/searchIcon' + (isSearchActive ? '-highlighted' : '') + '.svg'} alt="Search" />
          <input
            style={{ width: '100%' }}
            value={sendQuery}
            placeholder="Search user name, email, phone"
            onFocus={() => setIsSearchActive(true)}
            onBlur={() => setIsSearchActive(false)}
            onChange={(e) => {
              setSendQuery(e.target.value);
            }}
          />
        </div>
        {guestList.length > 0 && true ? (
          <div className={styles.sendModalSearchResults} id={'guestList'}>
            {filteredArrayToShow.length > 0 ? (
              filteredArrayToShow.map((g) => {
                return (
                  <button
                    key={g.id}
                    id={'guestList' + g.id}
                    className={styles.sendModalSearchResultsButton}
                    onClick={(e) => {
                      const selectedTemp = new Map(selectedGuestsObjects);
                      selectedTemp.set(g.id, g);
                      setSelectedGuestsObjects(selectedTemp);
                      setGuestList([]);
                      setSendQuery('');
                    }}
                  >
                    <UserInfo
                      userToken={jwt}
                      firstName={g.firstName}
                      lastName={g.lastName}
                      imageSrc={g.connectedAccountDetails?.imageProfileUrl}
                      setSelectedGuest={() => console.log()}
                    />
                  </button>
                );
              })
            ) : (
              <p className={'didacticP'}>No matches left</p>
            )}
          </div>
        ) : (
          ''
        )}
        <div className={styles.selected}>
          {selectedGuestsObjects &&
            Array.from(selectedGuestsObjects).map((value) => {
              return (
                <div key={value[0]} className={styles.selectedGuest}>
                  <UserInfo
                    userToken={jwt}
                    firstName={value[1].firstName}
                    lastName={value[1].lastName}
                    imageSrc={value[1].connectedAccountDetails?.imageProfileUrl}
                    showName={false}
                    isSmaller={true}
                  />
                  <div className={styles.selectedGuestTick}>
                    <Icon name={'tick'} size={'s'} />
                  </div>
                  {value[1].firstName}
                </div>
              );
            })}
        </div>
      </div>
      <h4 className={'didacticH4'}>Type</h4>
      <div className={styles.types} style={{ paddingBottom: 8 }}>
        <div>
          <button
            disabled={!wedding.mainEvent}
            className={styles.type + ' ' + (sendOption === InvitationOptionSaveTheDate ? styles.typeSelected : '')}
            style={{ borderColor: sendOption === InvitationOptionSaveTheDate ? '#F9AC80' : '#281B24' }}
            onClick={() => {
              setSendOption(InvitationOptionSaveTheDate);
            }}
          >
            <Icon
              name={InvitationOptionSaveTheDate}
              color={sendOption === InvitationOptionSaveTheDate ? '#F9AC80' : '#281B24'}
              size="m"
            />
          </button>
          <p className={'didacticP'}>Save the Date</p>
        </div>
        <div>
          <button
            disabled={!wedding.mainEvent || !wedding.emailInvitationWebsite}
            className={styles.type + ' ' + (sendOption === InvitationOptionInvite ? styles.typeSelected : '')}
            style={{ borderColor: sendOption === InvitationOptionInvite ? '#F9AC80' : '#281B24' }}
            onClick={(e) => {
              if (e.button === 2) return;
              setSendOption(InvitationOptionInvite);
            }}
          >
            <Icon
              name={'envelope_marker'}
              color={sendOption === InvitationOptionInvite ? '#F9AC80' : '#281B24'}
              size="m"
            />
          </button>
          <p className={'didacticP'}>Invitation & RSVP</p>
        </div>
        <div>
          <button
            disabled={!wedding.mainEvent || !wedding.emailInvitationWebsite}
            className={styles.type + ' ' + (sendOption === InvitationOptionReminder ? styles.typeSelected : '')}
            style={{ borderColor: sendOption === InvitationOptionReminder ? '#F9AC80' : '#281B24' }}
            onMouseDown={(e) => {
              if (e.button === 2) return;
              setSendOption(InvitationOptionReminder);
            }}
          >
            <Icon
              name={InvitationOptionReminder}
              color={sendOption === InvitationOptionReminder ? '#F9AC80' : '#281B24'}
              size="m"
            />
          </button>
          <p className={'didacticP'}>Reminder</p>
        </div>
      </div>
      <FilledButton
        id={'sendButton'}
        title={'Send'}
        // src={'/rsvp/guests/send.svg'}
        icon={'send_filled'}
        _className={styles.sendButton}
        _callback={() => {
          const guestIds: string[] = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const [key, value] of selectedGuestsObjects) {
            guestIds.push(key);
          }
          if (guestIds.length <= 0) {
            toast('You need to select invitees first. PLease go back and add invitees', {
              time: 5000,
              icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
              style: { backgroundColor: '#F8AAB8' },
            } as ToastOptions);
          } else setSendStep(1);
        }}
      />
      {sendOption !== InvitationOptionSaveTheDate && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            outline: 'inherit',
          }}
        >
          <p className={'didacticP'}>Or download and print QR code for RSVP</p>
          <button
            key={'qrCode'}
            onClick={() => downloadCode()}
            style={{
              background: 'none',
              color: 'inherit',
              border: 'none',
            }}
          >
            <QRCode
              value={`https://app.whiteclover.io?code=${wedding.path}&type=qrcode`}
              size={300} // the dimension of the QR code (number)
              logoImage="/assets/logos/purple_simple_logo.svg"
              removeQrCodeBehindLogo={true}
              logoHeight={80}
              logoWidth={80}
              logoOpacity={1}
              enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
              qrStyle="dots" // type of qr code, wether you want dotted ones or the square ones
              eyeRadius={25} // radius of the promocode eye
              id={'QR'}
              fgColor={'#63435b'}
            />
          </button>
        </div>
      )}
    </div>,
    //step 2 CONFIRMATION
    <div key={1} style={{ paddingBottom: '80px', margin: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button
          style={{ display: 'flex', alignItems: 'center', gap: '4px', border: 0, backgroundColor: 'transparent' }}
          onClick={() => setSendStep(0)}
        >
          <Icon name={'arrow_back'} size={'s'} />
          <p className={'didacticp'}>Back</p>
        </button>
        <button
          style={{ border: 'none', background: 'none' }}
          onClick={(e) => {
            onClose();
            setGuestList([]);
            setSendStep(0);
            e.preventDefault();
          }}
        >
          <img src="/assets/icons/icon-cancel.svg" alt="exit" width={26} />
        </button>
      </div>
      <h3 className={'didacticH3'}>Just a final check</h3>
      <p className={'didacticP'}>Those guests will receive {optionsName[sendOption]}. The action cannot be undone.</p>
      <h4 className={'didacticH4'}>Selected Guests</h4>
      <div style={{ height: '104px' }}>
        <div className={styles.selected}>
          {selectedGuestsObjects &&
            Array.from(selectedGuestsObjects).map((value) => {
              return (
                <div key={value[0]} className={styles.selectedGuest}>
                  <UserInfo
                    userToken={jwt}
                    firstName={value[1].firstName}
                    lastName={value[1].lastName}
                    imageSrc={value[1].connectedAccountDetails?.imageProfileUrl}
                    showName={false}
                    isSmaller={true}
                  />
                  <div className={styles.selectedGuestTick}>
                    <Icon name={'tick'} size={'s'} />
                  </div>
                  {value[1].firstName}
                </div>
              );
            })}
        </div>
        {loading ? (
          <div>
            <Lottie options={lottieDefaultOptions} height={100} width={100} />
          </div>
        ) : (
          <FilledButton
            id={'confirmAndSendButton'}
            title={'Confirm & Send'}
            // src={'/rsvp/guests/send.svg'}
            icon={'send_filled'}
            _className={styles.sendButton}
            _callback={() => {
              // console.log('selectedGuestsObjects', selectedGuestsObjects);
              const guestIds: string[] = [];
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              for (const [key, value] of selectedGuestsObjects) {
                guestIds.push(key);
              }
              // console.log('sendOption', sendOption);
              // console.log('weddingID', wedding);
              setLoading(true);
              if (guestIds.length <= 0) {
                toast('You need to select invitees first. PLease go back and add invitees', {
                  time: 5000,
                  icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
                  style: { backgroundColor: '#F8AAB8' },
                } as ToastOptions);
                setLoading(false);
              } else
                BrainHelper.sendInvitations(jwt, wedding.id, guestIds, sendOption)
                  .then(() => {
                    setLoading(false);
                    setSendStep(2);
                  })
                  .catch((e) => {
                    onClose();
                    setSendStep(0);
                    setLoading(false);
                    setGuestList([]);
                    toast('Something went wrong sending emails. PLease try again later', {
                      time: 5000,
                      icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
                      style: { backgroundColor: '#F8AAB8' },
                    } as ToastOptions);
                    logger.error({ message: e, functionName: 'Guests.sendEmail' });
                  });
            }}
          />
        )}
      </div>
    </div>,
    <div key={2} style={{ margin: 'auto', background: '#835978', alignItems: 'center' }}>
      <Icon name={'send'} color={'white'} />
      <h3 className={'didacticH3'} style={{ color: 'white', marginBottom: '24px' }}>
        Sent! Nice!
      </h3>
      <FilledNoIconButton
        title={'Close'}
        _className={styles.sendDoneButton}
        _callback={() => {
          setSendStep(0);
          onClose();
          // setForceRefresh((prevState) => !prevState);
        }}
      />
    </div>,
  ];

  return (
    <div
      className={styles.sendModal + ' ' + (isActiveSendModal ? styles.sendModalActive : '')}
      style={{ background: 'transparent', position: 'absolute' }}
    >
      {sendSteps[sendStep]}
    </div>
  );
};

export default EmailInvitationModal;
