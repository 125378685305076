import { useSelector } from 'react-redux';
import { selectRsvpEvents } from '../../../../app/redux/wedding.slice.recuder';
import React, { Fragment, useEffect, useState } from 'react';
import { AllowedVisitor, Location, RSVPEvent } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import styles from '../Planning.module.scss';
import { dateTimeFormat } from '../../../../config/dateFormats';
import { IonSpinner } from '@ionic/react';
import { formatLocation } from '../../../../shared/Functions/location';

const Preview = (props: {
  selectedDay: any;
  blobId: any;
  loading: boolean;
  percentage: number;
  weddingName: string;
  startDate: string;
  endDate: string;
  countryShort: string;
  weddingLocation: Location | undefined;
  description: string | undefined;
  isExperienceApp?: boolean;
  visitor?: AllowedVisitor;
}): JSX.Element => {
  const rsvpEvents = useSelector(selectRsvpEvents);
  const [eventsState, setEventsState] = useState<Map<string, RSVPEvent[]>>(new Map());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDesktopView, setIsDesktopView] = useState<boolean>(false);

  useEffect(() => {
    const tempEventsArray: RSVPEvent[] = JSON.parse(
      JSON.stringify(rsvpEvents, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
    );
    const tempMap = new Map();
    tempEventsArray
      .sort((a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime())
      .forEach((c) => {
        const date = new Date(c.startTimestamp);
        const index = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();
        const temp = tempMap.get(index);
        if (temp !== undefined) {
          const findIndex = temp.findIndex((value: RSVPEvent) => value.id === c.id);
          if (findIndex < 0) temp.push(c);
        } else {
          tempMap.set(index, [c]);
        }
        setEventsState(tempMap);
      });
  }, [rsvpEvents]);

  return (
    <div className={styles.preview + (props.isExperienceApp ? ' ' + styles.previewApp : '')}>
      {/*{!props.isExperienceApp && (*/}
      {/*  <div className={styles.btns}>*/}
      {/*    /!*<div role={'button'} tabIndex={0} style={{ cursor: 'pointer' }} onMouseDown={() => setIsDesktopView(false)}>*!/*/}
      {/*    /!*  <Icon name={'smartphone'} size={'sPlus'} color={!isDesktopView ? '#f9ac80' : '#c7adc1'} />*!/*/}
      {/*    /!*</div>*!/*/}
      {/*    /!*<div role={'button'} tabIndex={0} style={{ cursor: 'pointer' }} onMouseDown={() => setIsDesktopView(true)}>*!/*/}
      {/*    /!*  <Icon name={'desktop'} size={'sPlus'} color={!isDesktopView ? '#c7adc1' : '#f9ac80'} />*!/*/}
      {/*    /!*</div>*!/*/}
      {/*  </div>*/}
      {/*)}*/}
      <div
        className={
          styles.imgBorder +
          (isDesktopView ? ' ' + styles.imgBorderFull : '') +
          (props.isExperienceApp ? ' ' + styles.imgBorderApp : '')
        }
      >
        <div className={!isDesktopView ? styles.previewWrapper : ''}>
          <div
            className={styles.image}
            style={{
              backgroundImage: `linear-gradient(180deg, #ffffff00 50%, #00000059 85%), url(${props.blobId})`,
              height: props.selectedDay && !isDesktopView ? '40%' : '100%',
              // backgroundPositionY: props.selectedDay ? '20%' : '50%',
              borderRadius: props.selectedDay ? '16px 16px 0 0' : '16px',
              color: props.blobId !== undefined && props.blobId !== '' ? '#FFFFFF' : '#281B24',
              justifyContent: props.blobId !== undefined && props.blobId !== '' ? 'end' : '',
              backgroundSize: 'cover',
            }}
          >
            {!isDesktopView ? (
              <Fragment>
                <h2
                  className={'fridayH2'}
                  style={{ color: props.blobId !== undefined && props.blobId !== '' ? '#FFFFFF' : '#281B24' }}
                >
                  {props.weddingName}
                </h2>
                <h4
                  className={'fridayH4'}
                  style={{ color: props.blobId !== undefined && props.blobId !== '' ? '#FFFFFF' : '#281B24' }}
                >
                  Schedule
                </h4>{' '}
              </Fragment>
            ) : (
              ''
            )}
            {props.loading || props.blobId === undefined || props.blobId === '' ? (
              props.loading ? (
                <div
                  style={{
                    height: '100%',
                    backdropFilter: 'blur(34px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <IonSpinner name="lines" />
                  {props.percentage > 0 && props.percentage < 100 ? <p>{props.percentage.toFixed(2)} %</p> : ''}
                </div>
              ) : (
                <div className={styles.center} style={props.selectedDay ? { margin: 0 } : {}}>
                  <img src="/rsvp/default_photo.svg" alt="default couple" />
                  <h2>Main Image</h2>
                </div>
              )
            ) : (
              ''
            )}
          </div>
          <div className={styles.scroll + (isDesktopView ? ' ' + styles.scrollFull : '')}>
            {isDesktopView ? (
              <Fragment>
                <h2 className={'fridayH2'}>{props.weddingName}</h2>
                <h4 className={'fridayH4'}>Schedule</h4>
              </Fragment>
            ) : (
              ''
            )}
            {Array.from(eventsState).map((eventArray, key) => {
              const mainDate = new Date(eventArray[1][0].startTimestamp);
              const endDate = new Date(eventArray[1][0].endTimestamp);
              const filter = eventArray[1].filter((event) => {
                const find = props.visitor?.invitedToRsvpEvents.find((a) => a?.id === event.id);
                if (!event.isPrivate || find?.id) return event;
              });
              return (
                <Fragment key={key}>
                  {(!props.isExperienceApp || filter.length > 0) &&
                  props.selectedDay &&
                  !isNaN(mainDate.getTime()) &&
                  !isNaN(endDate.getTime()) ? (
                    <div style={{ padding: '24px 0' }}>
                      <h3 className={'fridayH3'}>
                        {`${dateTimeFormat({
                          timeZone: eventArray[1][0].location?.externalQuickCode,
                          weekday: 'short',
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        }).format(mainDate)}`}
                      </h3>
                      {eventArray[1].map((event, k) => {
                        const eventDateStart = new Date(event.startTimestamp);
                        const eventDateEnd = new Date(event.endTimestamp);
                        const find = props.visitor?.invitedToRsvpEvents.find((a) => a?.id === event.id);
                        if (!event.isPrivate || find?.id || !props.isExperienceApp)
                          return (
                            <Fragment key={k}>
                              <div style={{ display: 'flex', gap: '55px', margin: '16px 0' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <p className={'didacticP'}>{`${dateTimeFormat({
                                    timeZone: event.location?.externalQuickCode,
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  }).format(eventDateStart)}`}</p>
                                  <p className={'didacticP'}>-</p>
                                  <p className={'didacticP'}>{`${dateTimeFormat({
                                    timeZone: event.location?.externalQuickCode,
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  }).format(eventDateEnd)}`}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                  <h3 className={'didacticH3'} style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
                                    {event.name}
                                  </h3>
                                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <img src="/rsvp/map.svg" alt="map" />
                                    <a
                                      className={'didacticP ' + styles.highlighted}
                                      style={{
                                        wordWrap: 'break-word',
                                        width: '200px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                      }}
                                      target="_blank"
                                      href={`https://maps.google.com/?q=${event?.location?.point?.latitude},${event?.location?.point?.longitude}`}
                                      rel="noreferrer"
                                    >
                                      {event.location && formatLocation(event.location)}
                                    </a>
                                  </div>
                                  <span
                                    className={'didacticSpan'}
                                    style={{ maxWidth: '100%', wordBreak: 'break-word' }}
                                  >
                                    {event.description}
                                  </span>
                                </div>
                              </div>
                            </Fragment>
                          );
                      })}
                      <hr />
                    </div>
                  ) : (
                    ''
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
