import { motion } from 'framer-motion';
import { useState } from 'react';

interface ImageProps {
  src?: string;
  alt: string;
  classNames?: string;
  style?: any;
  animation?: any;
  onErrorComponent?: any;
  hideSpinner?: boolean;
  fixedSize?: boolean;
}

const Image = ({
  src = '',
  alt,
  classNames = '',
  style,
  animation,
  onErrorComponent,
  hideSpinner = false,
  fixedSize = false,
}: ImageProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const variants = {
    animation,
    stop: { opacity: 0 },
    show: { opacity: 1 },
  };
  return (
    <>
      <motion.div style={{ display: loading ? 'block' : 'none' }}>{!hideSpinner && onErrorComponent}</motion.div>
      <motion.div
        style={{
          width: fixedSize ? 'max-content' : '100%',
          height: fixedSize ? 'max-content' : '100%',
          display: loading ? 'none' : 'flex',
          justifyContent: 'center',
        }}
      >
        <motion.img
          variants={variants}
          animate={animation === undefined ? 'show' : !loading && animation !== undefined ? 'animation' : 'stop'}
          style={style}
          className={classNames}
          src={src}
          alt={alt}
          onLoad={() => setLoading(false)}
        />
      </motion.div>
    </>
  );
};

export default Image;
