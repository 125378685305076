/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AllowedVisitor,
  Answer,
  Comment,
  CommentInteraction,
  Notification,
  Post,
  PostInteraction,
  Question,
  RSVPEvent,
  Tag,
  Taxonomy,
  TaxonomyOption,
  Wedding,
} from '../../pkg/protobuf/v2/brain/brain_types_pb';
import { RootState } from '../store';
import { transliterate } from 'transliteration';

export interface weddingState {
  wedding: Wedding;
  weddings: Wedding[];
  posts: Post[];
  maxNumberOfPosts: number;
  post: Post;
  comments: Comment[];
  postInteractions: PostInteraction[];
  commentInteractions: CommentInteraction[];
  hasNotification: boolean;
  notifications: Notification[];
  tags: Tag[];
  rsvpEvents: RSVPEvent[];
  invitedVisitors: AllowedVisitor[];
  taxonomies: Taxonomy[];
  taxonomyOptions: TaxonomyOption[];
  questions: Question[];
  answers: Answer[];

  isHelpOn: boolean;
  isTutorialOn: boolean;
}

const initialState: weddingState = {
  wedding: new Wedding(),
  weddings: [],
  posts: [],
  maxNumberOfPosts: 0,
  post: new Post(),
  comments: [],
  postInteractions: [],
  commentInteractions: [],
  hasNotification: false,
  notifications: [],
  tags: [],
  rsvpEvents: [],
  invitedVisitors: [],
  taxonomies: [],
  taxonomyOptions: [],
  questions: [],
  answers: [],
  isHelpOn: false,
  isTutorialOn: false,
};

export const weddingSlice = createSlice({
  name: 'wedding',
  initialState,
  reducers: {
    setWedding: (state, action: PayloadAction<Wedding | undefined>) => {
      const wedding = action.payload;
      if (wedding) wedding.path = transliterate(wedding.path);
      state.wedding = wedding ? wedding : new Wedding();
    },
    setWeddings: (state, action: PayloadAction<Wedding[] | undefined>) => {
      const weddings = action.payload;
      if (weddings) {
        for (let i = 0; i < weddings.length; i++) {
          weddings[i].path = transliterate(weddings[i].path);
        }
      }
      state.weddings = weddings ? weddings : [];
    },
    setPostsReducer: (state, action: PayloadAction<Post[] | undefined>) => {
      state.posts = action.payload ? action.payload : [];
    },
    setMaxNumberOfPosts: (state, action: PayloadAction<number>) => {
      state.maxNumberOfPosts = action.payload;
    },
    setPostReducer: (state, action: PayloadAction<Post | undefined>) => {
      state.post = action.payload ? action.payload : new Post();
    },
    setRsvpEventsReducer: (state, action: PayloadAction<RSVPEvent[] | undefined>) => {
      state.rsvpEvents = action.payload ? action.payload : [];
    },
    addRsvpEvents: (state, action: PayloadAction<RSVPEvent[] | undefined>) => {
      const currentState = state.rsvpEvents;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id || p?.name === newElement?.name);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.push(newElement);
        }
      }

      state.rsvpEvents = currentState;
    },
    removeRsvpEvents: (state, action: PayloadAction<RSVPEvent[] | undefined>) => {
      const currentState = state.rsvpEvents;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState.splice(index, 1);
        }
      }

      state.rsvpEvents = currentState;
    },
    setInvitedVisitors: (state, action: PayloadAction<AllowedVisitor[] | undefined>) => {
      state.invitedVisitors = action.payload ? action.payload : [];
    },
    addInvitedVisitors: (state, action: PayloadAction<AllowedVisitor[] | undefined>) => {
      const currentState = state.invitedVisitors;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.push(newElement);
        }
      }

      state.invitedVisitors = currentState;
    },
    setTaxonomies: (state, action: PayloadAction<Taxonomy[] | undefined>) => {
      state.taxonomies = action.payload ? action.payload : [];
    },
    addTaxonomies: (state, action: PayloadAction<Taxonomy[] | undefined>) => {
      const currentState = state.taxonomies;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.push(newElement);
        }
      }

      state.taxonomies = currentState;
    },
    setTaxonomyOptions: (state, action: PayloadAction<TaxonomyOption[] | undefined>) => {
      state.taxonomyOptions = action.payload ? action.payload : [];
    },
    addTaxonomyOptions: (state, action: PayloadAction<TaxonomyOption[] | undefined>) => {
      const currentState = state.taxonomyOptions;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.push(newElement);
        }
      }

      state.taxonomyOptions = currentState;
    },
    addQuestions: (state, action: PayloadAction<Question[] | undefined>) => {
      const currentState = state.questions;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.push(newElement);
        }
      }

      state.questions = currentState;
    },
    addAnswers: (state, action: PayloadAction<Answer[] | undefined>) => {
      const currentState = state.answers;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.push(newElement);
        }
      }

      state.answers = currentState;
    },
    removeTaxonomies: (state, action: PayloadAction<Taxonomy[] | undefined>) => {
      const currentState = state.taxonomies;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState.splice(index, 1);
        }
      }

      state.taxonomies = currentState;
    },
    removeInvitedVisitors: (state, action: PayloadAction<AllowedVisitor[] | undefined>) => {
      const currentState = state.invitedVisitors;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState.splice(index, 1);
        }
      }

      state.invitedVisitors = currentState;
    },

    removeTaxonomyOptions: (state, action: PayloadAction<TaxonomyOption[] | undefined>) => {
      const currentState = state.taxonomyOptions;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState.splice(index, 1);
        }
      }

      state.taxonomyOptions = currentState;
    },
    removeQuestions: (state, action: PayloadAction<Question[] | undefined>) => {
      const currentState = state.questions;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState.splice(index, 1);
        }
      }

      state.questions = currentState;
    },
    removeAnswers: (state, action: PayloadAction<Answer[] | undefined>) => {
      const currentState = state.answers;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > -1) {
          currentState.splice(index, 1);
        }
      }

      state.answers = currentState;
    },
    setCommentsReducer: (state, action: PayloadAction<Comment[] | undefined>) => {
      state.comments = action.payload ? action.payload : [];
    },
    setPostInteractionReducer: (state, action: PayloadAction<PostInteraction[] | undefined>) => {
      const unique: PostInteraction[] = [];
      if (Array.isArray(action.payload)) {
        action.payload.forEach((item) => {
          if (!unique.includes(item)) {
            unique.push(item);
          }
        });
      }
      state.postInteractions = unique;
    },
    setCommentInteractionReducer: (state, action: PayloadAction<CommentInteraction[] | undefined>) => {
      const unique: CommentInteraction[] = [];
      if (Array.isArray(action.payload)) {
        action.payload.forEach((item) => {
          if (!unique.includes(item)) {
            unique.push(item);
          }
        });
      }
      state.commentInteractions = unique;
    },
    setHasNotification: (state, action: PayloadAction<boolean>) => {
      state.hasNotification = action.payload;
    },
    setNotifications: (state, action: PayloadAction<Notification[] | undefined>) => {
      state.notifications = action.payload ? action.payload : [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setWeddingLogout: (state, action) => {
      state.wedding = new Wedding();
      state.posts = [];
      state.post = new Post();
      state.comments = [];
      state.postInteractions = [];
      state.hasNotification = false;
    },
    setTags: (state, action: PayloadAction<Tag[] | undefined>) => {
      state.tags = action.payload ? action.payload : [];
    },
    addTags: (state, action: PayloadAction<Tag[] | undefined>) => {
      const currentState = state.tags;
      if (!Array.isArray(action.payload)) {
        console.log('no update skip', action.payload);
        return;
      }

      for (let i = 0; i < action.payload.length; i++) {
        const newElement = action.payload[i];
        const index = currentState.findIndex((p) => p?.id === newElement?.id);
        // if exists then replace previous value with new one
        if (index > 0) {
          currentState[index] = newElement;
        } else {
          // append
          currentState.concat(newElement);
        }
      }

      state.tags = currentState;
    },
    setIsHelpOn: (state, action: PayloadAction<boolean | undefined>) => {
      state.isHelpOn = action.payload ? action.payload : false;
    },
    setIsTutorialOn: (state, action: PayloadAction<boolean | undefined>) => {
      state.isTutorialOn = action.payload ? action.payload : false;
    },
  },
});

export const {
  setWedding,
  setWeddings,
  setPostsReducer,
  setPostReducer,
  setCommentsReducer,
  setPostInteractionReducer,
  setCommentInteractionReducer,
  setMaxNumberOfPosts,
  setHasNotification,
  setNotifications,
  setWeddingLogout,
  setTags,
  addTags,
  setRsvpEventsReducer,
  addRsvpEvents,
  removeRsvpEvents,
  setInvitedVisitors,
  addInvitedVisitors,
  setTaxonomies,
  addTaxonomies,
  setTaxonomyOptions,
  addTaxonomyOptions,
  addQuestions,
  addAnswers,
  removeInvitedVisitors,
  removeTaxonomies,
  removeTaxonomyOptions,
  removeQuestions,
  removeAnswers,
  setIsHelpOn,
  setIsTutorialOn,
} = weddingSlice.actions;

// used for the plugin to visualise tags and mentions
export interface TagDetail {
  id: string;
  display: string;
}

export const selectWedding = (state: RootState) => state.wedding.wedding;
export const selectWeddings = (state: RootState) => state.wedding.weddings;
export const selectPosts = (state: RootState) => state.wedding.posts;
export const selectPost = (state: RootState) => state.wedding.post;
export const selectRsvpEvents = (state: RootState) => state.wedding.rsvpEvents;
export const selectQuestions = (state: RootState) => state.wedding.questions;
export const selectComments = (state: RootState) => state.wedding.comments;
export const selectPostInteractions = (state: RootState) => state.wedding.postInteractions;
export const selectCommentInteractions = (state: RootState) => state.wedding.commentInteractions;
export const selectHasNotification = (state: RootState) => state.wedding.hasNotification;
export const selectNotifications = (state: RootState) => state.wedding.notifications;
export const selectMaxNumberOfPosts = (state: RootState) => state.wedding.maxNumberOfPosts;
export const selectTags = (state: RootState): TagDetail[] => {
  // state.wedding.tags;
  const tag: TagDetail[] = [];
  for (const t of state.wedding.tags) {
    tag.push({
      id: t?.id,
      display: t?.key,
    });
  }
  return tag;
};

export const selectTaxonomies = (state: RootState): Taxonomy[] => state.wedding.taxonomies;
export const selectTaxonomyOptions = (state: RootState): TaxonomyOption[] => state.wedding.taxonomyOptions;
export const selectIsHelpOn = (state: RootState) => state.wedding.isHelpOn;
export const selectIsTutorialOn = (state: RootState) => state.wedding.isTutorialOn;
export const selectInvitedVisitors = (state: RootState) => state.wedding.invitedVisitors;

export default weddingSlice.reducer;
