import React, { Fragment, useEffect, useState } from 'react';
import styles from './ImYoursQNAComponent.module.scss';

type QuickQuestionsComponentProps = {
  title?: string;
  questions?: Array<{ title: string; description: string }>;
  visible: boolean;
  graphics: string;
};

const defaultValues: QuickQuestionsComponentProps = {
  title: 'Quick Questions',
  questions: [
    {
      title: 'Placeholder Question 1',
      description:
        '|Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit amet velit eget ac vulputate. Laoreet amet consequat diam id morbi a. At at aliquet orci leo in condimentum morbi ut. Tincidunt lacus pellentesque sodales elit et eget adipiscing sapien, massa.',
    },
    {
      title: 'Placeholder Question 2',
      description:
        '|Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit amet velit eget ac vulputate. Laoreet amet consequat diam id morbi a. At at aliquet orci leo in condimentum morbi ut. Tincidunt lacus pellentesque sodales elit et eget adipiscing sapien, massa.',
    },
    {
      title: 'Placeholder Question 3',
      description:
        '|Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit amet velit eget ac vulputate. Laoreet amet consequat diam id morbi a. At at aliquet orci leo in condimentum morbi ut. Tincidunt lacus pellentesque sodales elit et eget adipiscing sapien, massa.',
    },
  ],
  visible: false,
  graphics: '/templates/ImYours/qna-graphic.svg',
};

const ImYoursQNAComponent = ({
  title,
  questions = defaultValues.questions,
  visible,
}: QuickQuestionsComponentProps): JSX.Element => {
  const [accordionState, setAccordionState] = useState<Array<boolean>>([]);

  useEffect(() => {
    const tempAccodionState = accordionState;
    questions?.map((q, index) => {
      tempAccodionState[index] = false;
    });
    setAccordionState(tempAccodionState);
  }, []);

  const toggleAccordion = (index1: number) => {
    setAccordionState((prev) => ({ ...prev, [index1]: prev && prev[index1] ? !prev[index1] : true }));
  };

  if (visible)
    return (
      <section id={`ImYoursQNAComponent`} className={styles.ImYoursQNAComponent}>
        <div>
          <div>
            <img src={defaultValues.graphics} alt="qna graphic" />
            <h2>{title ? title : String(defaultValues.title)}</h2>
            <img src={defaultValues.graphics} alt="qna graphic" />
          </div>
          {questions?.map((q, index) => {
            if (q.title !== '') {
              return (
                <div className={styles.question} key={q.title}>
                  <button className="accordion" onClick={() => toggleAccordion(index)}>
                    {q.title}
                    <img src={'/assets/icons/icon-arrow-down.svg'} alt={'v'} width={16} />
                  </button>
                  <div className={styles.panel} style={{ display: accordionState[index] ? 'inline-flex' : 'none' }}>
                    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: q.description ?? '' }} />
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className={styles.bottomElement}></div>
      </section>
    );
  else return <Fragment />;
};
export default ImYoursQNAComponent;
