// @generated by protoc-gen-es v1.1.1 with parameter "target=js+ts+dts"
// @generated from file brain/brain_types.proto (package brain, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum brain.StreamObjectUpdateAction
 */
export const StreamObjectUpdateAction = proto3.makeEnum(
  "brain.StreamObjectUpdateAction",
  [
    {no: 0, name: "StreamObjectUpdateActionUnknown"},
    {no: 1, name: "StreamObjectUpdateActionCreate"},
    {no: 2, name: "StreamObjectUpdateActionUpdate"},
    {no: 3, name: "StreamObjectUpdateActionRemove"},
    {no: 4, name: "StreamObjectUpdateActionArchive"},
    {no: 5, name: "StreamObjectUpdateActionUnArchive"},
  ],
);

/**
 * @generated from enum brain.InvitationStatus
 */
export const InvitationStatus = proto3.makeEnum(
  "brain.InvitationStatus",
  [
    {no: 0, name: "InvitationStatusUnknown"},
    {no: 1, name: "InvitationStatusPending"},
    {no: 2, name: "InvitationStatusSaveTheDaySend"},
    {no: 3, name: "InvitationStatusRSVPSend"},
    {no: 4, name: "InvitationStatusRSVPCompleted"},
  ],
);

/**
 * @generated from enum brain.TaskStatus
 */
export const TaskStatus = proto3.makeEnum(
  "brain.TaskStatus",
  [
    {no: 0, name: "TaskStatusUnknown"},
    {no: 1, name: "TaskStatusOpen"},
    {no: 2, name: "TaskStatusInProgress"},
    {no: 3, name: "TaskStatusReview"},
    {no: 4, name: "TaskStatusDone"},
  ],
);

/**
 * @generated from enum brain.VisibilityStatus
 */
export const VisibilityStatus = proto3.makeEnum(
  "brain.VisibilityStatus",
  [
    {no: 0, name: "VisibilityStatusUnknown"},
    {no: 1, name: "VisibilityStatusPublic"},
    {no: 2, name: "VisibilityStatusPrivate"},
    {no: 3, name: "VisibilityStatusSharedPrivately"},
  ],
);

/**
 * @generated from enum brain.AttendanceFilter
 */
export const AttendanceFilter = proto3.makeEnum(
  "brain.AttendanceFilter",
  [
    {no: 0, name: "AttendanceFilterUnknown"},
    {no: 1, name: "NotResponded"},
    {no: 2, name: "NotAttending"},
    {no: 3, name: "Attending"},
  ],
);

/**
 * @generated from enum brain.StatusFilter
 */
export const StatusFilter = proto3.makeEnum(
  "brain.StatusFilter",
  [
    {no: 0, name: "StatusFilterUnknown"},
    {no: 1, name: "NotInvited"},
    {no: 2, name: "InvitationSent"},
    {no: 3, name: "SaveTheDateSent"},
    {no: 4, name: "RSVPCompleted"},
  ],
);

/**
 * @generated from enum brain.InvitationOption
 */
export const InvitationOption = proto3.makeEnum(
  "brain.InvitationOption",
  [
    {no: 0, name: "InvitationOptionUnknown"},
    {no: 1, name: "InvitationOptionSaveTheDate"},
    {no: 2, name: "InvitationOptionInvite"},
    {no: 3, name: "InvitationOptionReminder"},
  ],
);

/**
 * @generated from enum brain.RSVPResponded
 */
export const RSVPResponded = proto3.makeEnum(
  "brain.RSVPResponded",
  [
    {no: 0, name: "RSVPRespondedDEFAULT"},
    {no: 1, name: "RSVPRespondedYES"},
    {no: 2, name: "RSVPRespondedNO"},
  ],
);

/**
 * @generated from message brain.BrainStatusRequest
 */
export const BrainStatusRequest = proto3.makeMessageType(
  "brain.BrainStatusRequest",
  [],
);

/**
 * @generated from message brain.GetEventStreamRequest
 */
export const GetEventStreamRequest = proto3.makeMessageType(
  "brain.GetEventStreamRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.BrainHealthStatusResponse
 */
export const BrainHealthStatusResponse = proto3.makeMessageType(
  "brain.BrainHealthStatusResponse",
  () => [
    { no: 2, name: "alive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.GetEventStreamResponse
 */
export const GetEventStreamResponse = proto3.makeMessageType(
  "brain.GetEventStreamResponse",
  () => [
    { no: 1, name: "action", kind: "enum", T: proto3.getEnumType(StreamObjectUpdateAction) },
    { no: 2, name: "noop", kind: "message", T: NOOP, oneof: "object" },
    { no: 3, name: "wedding", kind: "message", T: Wedding, oneof: "object" },
    { no: 5, name: "connected_account", kind: "message", T: ConnectedAccountRef, oneof: "object" },
    { no: 6, name: "post", kind: "message", T: Post, oneof: "object" },
    { no: 7, name: "post_file", kind: "message", T: PostFile, oneof: "object" },
    { no: 8, name: "post_interaction", kind: "message", T: PostInteraction, oneof: "object" },
    { no: 9, name: "comment", kind: "message", T: Comment, oneof: "object" },
    { no: 10, name: "comment_interaction", kind: "message", T: CommentInteraction, oneof: "object" },
    { no: 11, name: "notification", kind: "message", T: Notification, oneof: "object" },
    { no: 12, name: "tag", kind: "message", T: Tag, oneof: "object" },
    { no: 13, name: "taxonomy", kind: "message", T: Taxonomy, oneof: "object" },
    { no: 14, name: "taxonomy_option", kind: "message", T: TaxonomyOption, oneof: "object" },
  ],
);

/**
 * @generated from message brain.GetHostEventStreamRequest
 */
export const GetHostEventStreamRequest = proto3.makeMessageType(
  "brain.GetHostEventStreamRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetHostEventStreamResponse
 */
export const GetHostEventStreamResponse = proto3.makeMessageType(
  "brain.GetHostEventStreamResponse",
  () => [
    { no: 1, name: "action", kind: "enum", T: proto3.getEnumType(StreamObjectUpdateAction) },
    { no: 2, name: "noop", kind: "message", T: NOOP, oneof: "object" },
    { no: 3, name: "wedding", kind: "message", T: Wedding, oneof: "object" },
    { no: 5, name: "connected_account", kind: "message", T: ConnectedAccountRef, oneof: "object" },
    { no: 6, name: "allowed_visitor", kind: "message", T: AllowedVisitor, oneof: "object" },
    { no: 7, name: "notification", kind: "message", T: Notification, oneof: "object" },
    { no: 8, name: "taxonomy", kind: "message", T: Taxonomy, oneof: "object" },
    { no: 9, name: "taxonomy_option", kind: "message", T: TaxonomyOption, oneof: "object" },
    { no: 10, name: "rsvp_event", kind: "message", T: RSVPEvent, oneof: "object" },
    { no: 11, name: "question", kind: "message", T: Question, oneof: "object" },
    { no: 12, name: "answer", kind: "message", T: Answer, oneof: "object" },
    { no: 13, name: "task", kind: "message", T: Task, oneof: "object" },
    { no: 14, name: "wedding_website", kind: "message", T: WeddingWebsite, oneof: "object" },
    { no: 15, name: "page", kind: "message", T: Page, oneof: "object" },
    { no: 16, name: "section_data", kind: "message", T: SectionData, oneof: "object" },
    { no: 17, name: "allowed_visitors", kind: "message", T: AllowedVisitors, oneof: "object" },
  ],
);

/**
 * @generated from message brain.ImageResponse
 */
export const ImageResponse = proto3.makeMessageType(
  "brain.ImageResponse",
  () => [
    { no: 1, name: "cdnURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.NOOP
 */
export const NOOP = proto3.makeMessageType(
  "brain.NOOP",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.RequestPagination
 */
export const RequestPagination = proto3.makeMessageType(
  "brain.RequestPagination",
  () => [
    { no: 1, name: "current_page", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "page_limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message brain.ResponsePagination
 */
export const ResponsePagination = proto3.makeMessageType(
  "brain.ResponsePagination",
  () => [
    { no: 1, name: "current_page", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "page_limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "total_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "previous_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Metadata
 */
export const Metadata = proto3.makeMessageType(
  "brain.Metadata",
  () => [
    { no: 1, name: "pagination", kind: "message", T: ResponsePagination },
  ],
);

/**
 * @generated from message brain.File
 */
export const File = proto3.makeMessageType(
  "brain.File",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Wedding
 */
export const Wedding = proto3.makeMessageType(
  "brain.Wedding",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "feed_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "small_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "base_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "default_taxonomy_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "main_event", kind: "message", T: RSVPEvent },
    { no: 14, name: "email_invitation_website", kind: "message", T: EmailInvitationWebsite },
    { no: 15, name: "social_event_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "coupon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "package", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "final_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message brain.EmailInvitationWebsite
 */
export const EmailInvitationWebsite = proto3.makeMessageType(
  "brain.EmailInvitationWebsite",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.AllowedVisitor
 */
export const AllowedVisitor = proto3.makeMessageType(
  "brain.AllowedVisitor",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "custom_save_invitation_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "custom_save_the_date_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "max_allowed_plus_1", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "status", kind: "enum", T: proto3.getEnumType(InvitationStatus) },
    { no: 14, name: "group_with_visitor_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "linked_with_connected_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "taxonomy_options", kind: "message", T: TaxonomyOption, repeated: true },
    { no: 17, name: "invited_to_rsvp_events", kind: "message", T: RSVPEventSmall, repeated: true },
    { no: 18, name: "attend_to_rsvp_events", kind: "message", T: RSVPEventAttendance, repeated: true },
    { no: 19, name: "arrival_to_rsvp_events", kind: "message", T: RSVPEventArrival, repeated: true },
    { no: 20, name: "answers", kind: "message", T: Answer, repeated: true },
    { no: 21, name: "is_host", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "is_moderator", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "connected_account_details", kind: "message", T: ConnectedAccountRef },
    { no: 24, name: "linked_with_connected_account2_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "is_parent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "is_self_invited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.AllowedVisitors
 */
export const AllowedVisitors = proto3.makeMessageType(
  "brain.AllowedVisitors",
  () => [
    { no: 1, name: "allowed_visitors", kind: "message", T: AllowedVisitor, repeated: true },
  ],
);

/**
 * @generated from message brain.PublicAllowedVisitor
 */
export const PublicAllowedVisitor = proto3.makeMessageType(
  "brain.PublicAllowedVisitor",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "plus1", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "image_profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "taxonomy_options", kind: "message", T: TaxonomyOption, repeated: true },
    { no: 8, name: "arrival", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.ConnectedAccountRef
 */
export const ConnectedAccountRef = proto3.makeMessageType(
  "brain.ConnectedAccountRef",
  () => [
    { no: 1, name: "connected_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "account_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "image_profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "connected_account_phone_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "account_phone_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "visitor", kind: "message", T: AllowedVisitor },
    { no: 11, name: "host_for_wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "moderator_for_wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Taxonomy
 */
export const Taxonomy = proto3.makeMessageType(
  "brain.Taxonomy",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.TaxonomyOption
 */
export const TaxonomyOption = proto3.makeMessageType(
  "brain.TaxonomyOption",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "taxonomy_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.RSVPEventSmall
 */
export const RSVPEventSmall = proto3.makeMessageType(
  "brain.RSVPEventSmall",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "kindly_reminder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.RSVPEventAttendance
 */
export const RSVPEventAttendance = proto3.makeMessageType(
  "brain.RSVPEventAttendance",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rsvp_event", kind: "message", T: RSVPEventSmall },
    { no: 3, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
    { no: 4, name: "plus_ones", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "attend", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "responded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.RSVPEventArrival
 */
export const RSVPEventArrival = proto3.makeMessageType(
  "brain.RSVPEventArrival",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rsvp_event", kind: "message", T: RSVPEventSmall },
    { no: 3, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
    { no: 4, name: "arrival", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.RSVPEvent
 */
export const RSVPEvent = proto3.makeMessageType(
  "brain.RSVPEvent",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "start_timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "end_timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "location", kind: "message", T: Location },
    { no: 9, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "invited_visitor_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "attendes_visitors_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "questions", kind: "message", T: Question, repeated: true },
    { no: 14, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "kindly_reminder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Answer
 */
export const Answer = proto3.makeMessageType(
  "brain.Answer",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "freeText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "single_option_choices", kind: "message", T: SingleOptionChoice, repeated: true },
    { no: 4, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Location
 */
export const Location = proto3.makeMessageType(
  "brain.Location",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "external_quick_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "point", kind: "message", T: Point },
    { no: 6, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Point
 */
export const Point = proto3.makeMessageType(
  "brain.Point",
  () => [
    { no: 1, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message brain.Question
 */
export const Question = proto3.makeMessageType(
  "brain.Question",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "single_option_choices", kind: "message", T: SingleOptionChoice, repeated: true },
    { no: 4, name: "allowMultiple", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "isFreeText", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "allowEditOptions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "answers", kind: "message", T: Answer, repeated: true },
    { no: 8, name: "belongs_to_rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.SingleOptionChoice
 */
export const SingleOptionChoice = proto3.makeMessageType(
  "brain.SingleOptionChoice",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "option", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ConnectedAccount
 */
export const ConnectedAccount = proto3.makeMessageType(
  "brain.ConnectedAccount",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "date_active", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "linked_with_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "host_for_wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "moderator_for_wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * tag
 *
 * @generated from message brain.Tag
 */
export const Tag = proto3.makeMessageType(
  "brain.Tag",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Task
 */
export const Task = proto3.makeMessageType(
  "brain.Task",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "due_day", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "order", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(TaskStatus) },
    { no: 7, name: "created_by_connected_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.SectionData
 */
export const SectionData = proto3.makeMessageType(
  "brain.SectionData",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "frontend_component_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ts_class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "go_struct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "generic_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "data_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "order", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Page
 */
export const Page = proto3.makeMessageType(
  "brain.Page",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "visibility_status", kind: "enum", T: proto3.getEnumType(VisibilityStatus) },
    { no: 4, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "section_datas", kind: "message", T: SectionData, repeated: true },
    { no: 6, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.WeddingWebsite
 */
export const WeddingWebsite = proto3.makeMessageType(
  "brain.WeddingWebsite",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "visibility_status", kind: "enum", T: proto3.getEnumType(VisibilityStatus) },
    { no: 5, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "pages", kind: "message", T: Page, repeated: true },
    { no: 7, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * feed data for db tables
 *
 * @generated from message brain.Post
 */
export const Post = proto3.makeMessageType(
  "brain.Post",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "feed_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "author_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 6, name: "mentions", kind: "message", T: ConnectedAccount, repeated: true },
    { no: 7, name: "files", kind: "message", T: PostFile, repeated: true },
    { no: 8, name: "interactions", kind: "message", T: PostInteraction, repeated: true },
    { no: 9, name: "comments", kind: "message", T: Comment, repeated: true },
    { no: 10, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.PostFile
 */
export const PostFile = proto3.makeMessageType(
  "brain.PostFile",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "file_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.PostInteraction
 */
export const PostInteraction = proto3.makeMessageType(
  "brain.PostInteraction",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "emoticon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.Comment
 */
export const Comment = proto3.makeMessageType(
  "brain.Comment",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 6, name: "mentions", kind: "message", T: ConnectedAccount, repeated: true },
    { no: 7, name: "interactions", kind: "message", T: CommentInteraction, repeated: true },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CommentInteraction
 */
export const CommentInteraction = proto3.makeMessageType(
  "brain.CommentInteraction",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "emoticon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "comment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Wedding
 *
 * @generated from message brain.ListWeddingsRequest
 */
export const ListWeddingsRequest = proto3.makeMessageType(
  "brain.ListWeddingsRequest",
  () => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListWeddingsResponse
 */
export const ListWeddingsResponse = proto3.makeMessageType(
  "brain.ListWeddingsResponse",
  () => [
    { no: 1, name: "weddings", kind: "message", T: Wedding, repeated: true },
    { no: 2, name: "host_weddings", kind: "message", T: Wedding, repeated: true },
    { no: 3, name: "moderator_weddings", kind: "message", T: Wedding, repeated: true },
  ],
);

/**
 * @generated from message brain.GetWeddingRequest
 */
export const GetWeddingRequest = proto3.makeMessageType(
  "brain.GetWeddingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetWeddingByPathRequest
 */
export const GetWeddingByPathRequest = proto3.makeMessageType(
  "brain.GetWeddingByPathRequest",
  () => [
    { no: 1, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetWeddingResponse
 */
export const GetWeddingResponse = proto3.makeMessageType(
  "brain.GetWeddingResponse",
  () => [
    { no: 1, name: "wedding", kind: "message", T: Wedding },
  ],
);

/**
 * @generated from message brain.CreateWeddingRequest
 */
export const CreateWeddingRequest = proto3.makeMessageType(
  "brain.CreateWeddingRequest",
  () => [
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "small_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "base_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "rsvp_request", kind: "message", T: CreateRSVPEventRequest },
    { no: 10, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "social_event_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "coupon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "package", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CreateWeddingResponse
 */
export const CreateWeddingResponse = proto3.makeMessageType(
  "brain.CreateWeddingResponse",
  () => [
    { no: 1, name: "wedding", kind: "message", T: Wedding },
  ],
);

/**
 * @generated from message brain.UpdateWeddingRequest
 */
export const UpdateWeddingRequest = proto3.makeMessageType(
  "brain.UpdateWeddingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "small_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "base_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "coupon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "package", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.UpdateWeddingResponse
 */
export const UpdateWeddingResponse = proto3.makeMessageType(
  "brain.UpdateWeddingResponse",
  () => [
    { no: 1, name: "wedding", kind: "message", T: Wedding },
  ],
);

/**
 * @generated from message brain.ArchiveWeddingRequest
 */
export const ArchiveWeddingRequest = proto3.makeMessageType(
  "brain.ArchiveWeddingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveWeddingResponse
 */
export const ArchiveWeddingResponse = proto3.makeMessageType(
  "brain.ArchiveWeddingResponse",
  [],
);

/**
 * @generated from message brain.DeleteWeddingRequest
 */
export const DeleteWeddingRequest = proto3.makeMessageType(
  "brain.DeleteWeddingRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteWeddingResponse
 */
export const DeleteWeddingResponse = proto3.makeMessageType(
  "brain.DeleteWeddingResponse",
  [],
);

/**
 * AllowedVisitor
 *
 * @generated from message brain.GetInvitedVisitorByPhoneOrEmailRequest
 */
export const GetInvitedVisitorByPhoneOrEmailRequest = proto3.makeMessageType(
  "brain.GetInvitedVisitorByPhoneOrEmailRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetInvitedVisitorByPhoneOrEmailResponse
 */
export const GetInvitedVisitorByPhoneOrEmailResponse = proto3.makeMessageType(
  "brain.GetInvitedVisitorByPhoneOrEmailResponse",
  () => [
    { no: 1, name: "allowed_visitors", kind: "message", T: AllowedVisitor, repeated: true },
  ],
);

/**
 * @generated from message brain.TotalAllowedVisitorsRequest
 */
export const TotalAllowedVisitorsRequest = proto3.makeMessageType(
  "brain.TotalAllowedVisitorsRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "filter", kind: "message", T: Filter },
  ],
);

/**
 * @generated from message brain.TotalAllowedVisitorsResponse
 */
export const TotalAllowedVisitorsResponse = proto3.makeMessageType(
  "brain.TotalAllowedVisitorsResponse",
  () => [
    { no: 1, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "expected_plus_1s", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message brain.ListAllowedVisitorsRequest
 */
export const ListAllowedVisitorsRequest = proto3.makeMessageType(
  "brain.ListAllowedVisitorsRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "filter", kind: "message", T: Filter },
    { no: 4, name: "paging", kind: "message", T: RequestPagination },
    { no: 5, name: "is_host", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "is_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.Filter
 */
export const Filter = proto3.makeMessageType(
  "brain.Filter",
  () => [
    { no: 1, name: "attendance", kind: "enum", T: proto3.getEnumType(AttendanceFilter) },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(StatusFilter) },
    { no: 3, name: "taxonomyOptions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.ListAllowedVisitorsResponse
 */
export const ListAllowedVisitorsResponse = proto3.makeMessageType(
  "brain.ListAllowedVisitorsResponse",
  () => [
    { no: 1, name: "allowed_visitors", kind: "message", T: AllowedVisitor, repeated: true },
    { no: 2, name: "metadata", kind: "message", T: Metadata },
  ],
);

/**
 * @generated from message brain.PublicListAllowedVisitorsResponse
 */
export const PublicListAllowedVisitorsResponse = proto3.makeMessageType(
  "brain.PublicListAllowedVisitorsResponse",
  () => [
    { no: 1, name: "allowed_visitors", kind: "message", T: PublicAllowedVisitor, repeated: true },
    { no: 2, name: "metadata", kind: "message", T: Metadata },
    { no: 3, name: "wedding_img", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "wedding_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "rsvp_event_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetAllowedVisitorRequest
 */
export const GetAllowedVisitorRequest = proto3.makeMessageType(
  "brain.GetAllowedVisitorRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetAllowedVisitorResponse
 */
export const GetAllowedVisitorResponse = proto3.makeMessageType(
  "brain.GetAllowedVisitorResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * @generated from message brain.CreateAllowedVisitorRequest
 */
export const CreateAllowedVisitorRequest = proto3.makeMessageType(
  "brain.CreateAllowedVisitorRequest",
  () => [
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "custom_save_invitation_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "custom_save_the_date_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "taxonomy_option_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "rsvp_event_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "max_allowed_plus_ones", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "is_host", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "is_moderator", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "grouped_allowed_visitors_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "is_parent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "is_self_invited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.CreateAllowedVisitorResponse
 */
export const CreateAllowedVisitorResponse = proto3.makeMessageType(
  "brain.CreateAllowedVisitorResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * @generated from message brain.PublicCreateAllowedVisitorRequest
 */
export const PublicCreateAllowedVisitorRequest = proto3.makeMessageType(
  "brain.PublicCreateAllowedVisitorRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: CreateAllowedVisitorRequest, repeated: true },
  ],
);

/**
 * @generated from message brain.PublicCreateAllowedVisitorResponse
 */
export const PublicCreateAllowedVisitorResponse = proto3.makeMessageType(
  "brain.PublicCreateAllowedVisitorResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: CreateAllowedVisitorResponse, repeated: true },
    { no: 2, name: "failed", kind: "message", T: CreateAllowedVisitorRequest, repeated: true },
  ],
);

/**
 * @generated from message brain.UpdateAllowedVisitorRequest
 */
export const UpdateAllowedVisitorRequest = proto3.makeMessageType(
  "brain.UpdateAllowedVisitorRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "custom_save_invitation_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "custom_save_the_date_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "taxonomy_option_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "rsvp_event_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "max_allowed_plus_ones", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "attend_rsvp_events", kind: "message", T: AttendRsvpEvent, repeated: true },
    { no: 13, name: "is_host", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "is_moderator", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "arrival", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "group_with_visitor_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 17, name: "is_parent", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "is_self_invited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.AttendRsvpEvent
 */
export const AttendRsvpEvent = proto3.makeMessageType(
  "brain.AttendRsvpEvent",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rsvp_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "attend", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "allowed_plus_ones", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "responded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.UpdateAllowedVisitorResponse
 */
export const UpdateAllowedVisitorResponse = proto3.makeMessageType(
  "brain.UpdateAllowedVisitorResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * @generated from message brain.ArchiveAllowedVisitorRequest
 */
export const ArchiveAllowedVisitorRequest = proto3.makeMessageType(
  "brain.ArchiveAllowedVisitorRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveAllowedVisitorResponse
 */
export const ArchiveAllowedVisitorResponse = proto3.makeMessageType(
  "brain.ArchiveAllowedVisitorResponse",
  [],
);

/**
 * @generated from message brain.DeleteAllowedVisitorRequest
 */
export const DeleteAllowedVisitorRequest = proto3.makeMessageType(
  "brain.DeleteAllowedVisitorRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteAllowedVisitorsRequest
 */
export const DeleteAllowedVisitorsRequest = proto3.makeMessageType(
  "brain.DeleteAllowedVisitorsRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteAllowedVisitorResponse
 */
export const DeleteAllowedVisitorResponse = proto3.makeMessageType(
  "brain.DeleteAllowedVisitorResponse",
  [],
);

/**
 * @generated from message brain.AttachTaxonomyOptionsToAllowedVisitorsRequest
 */
export const AttachTaxonomyOptionsToAllowedVisitorsRequest = proto3.makeMessageType(
  "brain.AttachTaxonomyOptionsToAllowedVisitorsRequest",
  () => [
    { no: 1, name: "taxonomy_option_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.AttachTaxonomyOptionsToAllowedVisitorsResponse
 */
export const AttachTaxonomyOptionsToAllowedVisitorsResponse = proto3.makeMessageType(
  "brain.AttachTaxonomyOptionsToAllowedVisitorsResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * @generated from message brain.DetachTaxonomyOptionsFromAllowedVisitorsRequest
 */
export const DetachTaxonomyOptionsFromAllowedVisitorsRequest = proto3.makeMessageType(
  "brain.DetachTaxonomyOptionsFromAllowedVisitorsRequest",
  () => [
    { no: 1, name: "taxonomy_option_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DetachTaxonomyOptionsFromAllowedVisitorsResponse
 */
export const DetachTaxonomyOptionsFromAllowedVisitorsResponse = proto3.makeMessageType(
  "brain.DetachTaxonomyOptionsFromAllowedVisitorsResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * @generated from message brain.CleanAllowedVisitorsTaxonomyOptionsRequest
 */
export const CleanAllowedVisitorsTaxonomyOptionsRequest = proto3.makeMessageType(
  "brain.CleanAllowedVisitorsTaxonomyOptionsRequest",
  () => [
    { no: 1, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CleanAllowedVisitorsTaxonomyOptionsResponse
 */
export const CleanAllowedVisitorsTaxonomyOptionsResponse = proto3.makeMessageType(
  "brain.CleanAllowedVisitorsTaxonomyOptionsResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * @generated from message brain.AttachRSVPEventToAllowedVisitorsRequest
 */
export const AttachRSVPEventToAllowedVisitorsRequest = proto3.makeMessageType(
  "brain.AttachRSVPEventToAllowedVisitorsRequest",
  () => [
    { no: 1, name: "rsvp_event_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.AttachRSVPEventToAllowedVisitorsResponse
 */
export const AttachRSVPEventToAllowedVisitorsResponse = proto3.makeMessageType(
  "brain.AttachRSVPEventToAllowedVisitorsResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * @generated from message brain.DetachRSVPEventFromAllowedVisitorsRequest
 */
export const DetachRSVPEventFromAllowedVisitorsRequest = proto3.makeMessageType(
  "brain.DetachRSVPEventFromAllowedVisitorsRequest",
  () => [
    { no: 1, name: "rsvp_event_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DetachRSVPEventFromAllowedVisitorsResponse
 */
export const DetachRSVPEventFromAllowedVisitorsResponse = proto3.makeMessageType(
  "brain.DetachRSVPEventFromAllowedVisitorsResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * @generated from message brain.CleanAllowedVisitorsRSVPEventsRequest
 */
export const CleanAllowedVisitorsRSVPEventsRequest = proto3.makeMessageType(
  "brain.CleanAllowedVisitorsRSVPEventsRequest",
  () => [
    { no: 1, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CleanAllowedVisitorsRSVPEventsResponse
 */
export const CleanAllowedVisitorsRSVPEventsResponse = proto3.makeMessageType(
  "brain.CleanAllowedVisitorsRSVPEventsResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * rsvp events
 *
 * @generated from message brain.ListRSVPEventsRequest
 */
export const ListRSVPEventsRequest = proto3.makeMessageType(
  "brain.ListRSVPEventsRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListRSVPEventsResponse
 */
export const ListRSVPEventsResponse = proto3.makeMessageType(
  "brain.ListRSVPEventsResponse",
  () => [
    { no: 1, name: "rsvp_events", kind: "message", T: RSVPEvent, repeated: true },
  ],
);

/**
 * @generated from message brain.GetRSVPEventRequest
 */
export const GetRSVPEventRequest = proto3.makeMessageType(
  "brain.GetRSVPEventRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetRSVPEventResponse
 */
export const GetRSVPEventResponse = proto3.makeMessageType(
  "brain.GetRSVPEventResponse",
  () => [
    { no: 1, name: "rsvp_events", kind: "message", T: RSVPEvent, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateRSVPEventRequest
 */
export const CreateRSVPEventRequest = proto3.makeMessageType(
  "brain.CreateRSVPEventRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "start_timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "end_timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "location", kind: "message", T: Location },
    { no: 9, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.CreateRSVPEventResponse
 */
export const CreateRSVPEventResponse = proto3.makeMessageType(
  "brain.CreateRSVPEventResponse",
  () => [
    { no: 1, name: "event", kind: "message", T: RSVPEvent },
  ],
);

/**
 * @generated from message brain.UpdateRSVPEventRequest
 */
export const UpdateRSVPEventRequest = proto3.makeMessageType(
  "brain.UpdateRSVPEventRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "start_timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "end_timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "location", kind: "message", T: Location },
    { no: 9, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "kindly_reminder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.UpdateRSVPEventResponse
 */
export const UpdateRSVPEventResponse = proto3.makeMessageType(
  "brain.UpdateRSVPEventResponse",
  () => [
    { no: 1, name: "event", kind: "message", T: RSVPEvent },
  ],
);

/**
 * @generated from message brain.ArchiveRSVPEventRequest
 */
export const ArchiveRSVPEventRequest = proto3.makeMessageType(
  "brain.ArchiveRSVPEventRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveRSVPEventResponse
 */
export const ArchiveRSVPEventResponse = proto3.makeMessageType(
  "brain.ArchiveRSVPEventResponse",
  [],
);

/**
 * @generated from message brain.DeleteRSVPEventRequest
 */
export const DeleteRSVPEventRequest = proto3.makeMessageType(
  "brain.DeleteRSVPEventRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteRSVPEventResponse
 */
export const DeleteRSVPEventResponse = proto3.makeMessageType(
  "brain.DeleteRSVPEventResponse",
  [],
);

/**
 * attendance
 *
 * @generated from message brain.TotalAttendanceRequest
 */
export const TotalAttendanceRequest = proto3.makeMessageType(
  "brain.TotalAttendanceRequest",
  () => [
    { no: 1, name: "rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.TotalAttendanceResponse
 */
export const TotalAttendanceResponse = proto3.makeMessageType(
  "brain.TotalAttendanceResponse",
  () => [
    { no: 1, name: "attending_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "plus1_sum", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "not_attending_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "responded_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "not_responded_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message brain.CreateOrUpdateAttendanceForAllowedVisitorRequest
 */
export const CreateOrUpdateAttendanceForAllowedVisitorRequest = proto3.makeMessageType(
  "brain.CreateOrUpdateAttendanceForAllowedVisitorRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "attend", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "allowed_plus_ones", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "responded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.CreateOrUpdateAttendanceForAllowedVisitorResponse
 */
export const CreateOrUpdateAttendanceForAllowedVisitorResponse = proto3.makeMessageType(
  "brain.CreateOrUpdateAttendanceForAllowedVisitorResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

/**
 * questions
 *
 * @generated from message brain.ListQuestionsRequest
 */
export const ListQuestionsRequest = proto3.makeMessageType(
  "brain.ListQuestionsRequest",
  () => [
    { no: 1, name: "rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListQuestionsResponse
 */
export const ListQuestionsResponse = proto3.makeMessageType(
  "brain.ListQuestionsResponse",
  () => [
    { no: 1, name: "questions", kind: "message", T: Question, repeated: true },
  ],
);

/**
 * @generated from message brain.GetQuestionRequest
 */
export const GetQuestionRequest = proto3.makeMessageType(
  "brain.GetQuestionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetQuestionResponse
 */
export const GetQuestionResponse = proto3.makeMessageType(
  "brain.GetQuestionResponse",
  () => [
    { no: 1, name: "questions", kind: "message", T: Question, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateQuestionRequest
 */
export const CreateQuestionRequest = proto3.makeMessageType(
  "brain.CreateQuestionRequest",
  () => [
    { no: 1, name: "belongs_to_rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "single_option_choices", kind: "message", T: SingleOptionChoice, repeated: true },
    { no: 4, name: "allowMultiple", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "isFreeText", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "allowEditOptions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CreateQuestionResponse
 */
export const CreateQuestionResponse = proto3.makeMessageType(
  "brain.CreateQuestionResponse",
  () => [
    { no: 1, name: "question", kind: "message", T: Question },
  ],
);

/**
 * @generated from message brain.UpdateQuestionRequest
 */
export const UpdateQuestionRequest = proto3.makeMessageType(
  "brain.UpdateQuestionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "single_option_choices", kind: "message", T: SingleOptionChoice, repeated: true },
    { no: 4, name: "allowMultiple", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "isFreeText", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "allowEditOptions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "belongs_to_rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.UpdateQuestionResponse
 */
export const UpdateQuestionResponse = proto3.makeMessageType(
  "brain.UpdateQuestionResponse",
  () => [
    { no: 1, name: "question", kind: "message", T: Question },
  ],
);

/**
 * @generated from message brain.ArchiveQuestionRequest
 */
export const ArchiveQuestionRequest = proto3.makeMessageType(
  "brain.ArchiveQuestionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveQuestionResponse
 */
export const ArchiveQuestionResponse = proto3.makeMessageType(
  "brain.ArchiveQuestionResponse",
  [],
);

/**
 * @generated from message brain.DeleteQuestionRequest
 */
export const DeleteQuestionRequest = proto3.makeMessageType(
  "brain.DeleteQuestionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteQuestionResponse
 */
export const DeleteQuestionResponse = proto3.makeMessageType(
  "brain.DeleteQuestionResponse",
  [],
);

/**
 * answers
 *
 * @generated from message brain.TotalAnswersRequest
 */
export const TotalAnswersRequest = proto3.makeMessageType(
  "brain.TotalAnswersRequest",
  () => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.TotalAnswersResponse
 */
export const TotalAnswersResponse = proto3.makeMessageType(
  "brain.TotalAnswersResponse",
  () => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message brain.ListAnswersRequest
 */
export const ListAnswersRequest = proto3.makeMessageType(
  "brain.ListAnswersRequest",
  () => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "invited_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "paging", kind: "message", T: RequestPagination },
  ],
);

/**
 * @generated from message brain.ListAnswersResponse
 */
export const ListAnswersResponse = proto3.makeMessageType(
  "brain.ListAnswersResponse",
  () => [
    { no: 1, name: "answers", kind: "message", T: Answer, repeated: true },
    { no: 2, name: "metadata", kind: "message", T: Metadata },
  ],
);

/**
 * @generated from message brain.GetAnswerRequest
 */
export const GetAnswerRequest = proto3.makeMessageType(
  "brain.GetAnswerRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetAnswerResponse
 */
export const GetAnswerResponse = proto3.makeMessageType(
  "brain.GetAnswerResponse",
  () => [
    { no: 1, name: "answers", kind: "message", T: Answer, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateAnswerRequest
 */
export const CreateAnswerRequest = proto3.makeMessageType(
  "brain.CreateAnswerRequest",
  () => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "freeText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "single_option_choices", kind: "message", T: SingleOptionChoice, repeated: true },
    { no: 4, name: "invited_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CreateAnswerResponse
 */
export const CreateAnswerResponse = proto3.makeMessageType(
  "brain.CreateAnswerResponse",
  () => [
    { no: 1, name: "answer", kind: "message", T: Answer },
  ],
);

/**
 * @generated from message brain.UpdateAnswerRequest
 */
export const UpdateAnswerRequest = proto3.makeMessageType(
  "brain.UpdateAnswerRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "freeText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "single_option_choices", kind: "message", T: SingleOptionChoice, repeated: true },
    { no: 4, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "invited_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.UpdateAnswerResponse
 */
export const UpdateAnswerResponse = proto3.makeMessageType(
  "brain.UpdateAnswerResponse",
  () => [
    { no: 1, name: "answer", kind: "message", T: Answer },
  ],
);

/**
 * @generated from message brain.ArchiveAnswerRequest
 */
export const ArchiveAnswerRequest = proto3.makeMessageType(
  "brain.ArchiveAnswerRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveAnswerResponse
 */
export const ArchiveAnswerResponse = proto3.makeMessageType(
  "brain.ArchiveAnswerResponse",
  [],
);

/**
 * @generated from message brain.DeleteAnswerRequest
 */
export const DeleteAnswerRequest = proto3.makeMessageType(
  "brain.DeleteAnswerRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteAnswerResponse
 */
export const DeleteAnswerResponse = proto3.makeMessageType(
  "brain.DeleteAnswerResponse",
  [],
);

/**
 * Taxonomy
 *
 * @generated from message brain.ListTaxonomiesRequest
 */
export const ListTaxonomiesRequest = proto3.makeMessageType(
  "brain.ListTaxonomiesRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListTaxonomiesResponse
 */
export const ListTaxonomiesResponse = proto3.makeMessageType(
  "brain.ListTaxonomiesResponse",
  () => [
    { no: 1, name: "taxonomies", kind: "message", T: Taxonomy, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateTaxonomyRequest
 */
export const CreateTaxonomyRequest = proto3.makeMessageType(
  "brain.CreateTaxonomyRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CreateTaxonomyResponse
 */
export const CreateTaxonomyResponse = proto3.makeMessageType(
  "brain.CreateTaxonomyResponse",
  () => [
    { no: 1, name: "taxonomy", kind: "message", T: Taxonomy },
  ],
);

/**
 * @generated from message brain.GetTaxonomyRequest
 */
export const GetTaxonomyRequest = proto3.makeMessageType(
  "brain.GetTaxonomyRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetTaxonomyResponse
 */
export const GetTaxonomyResponse = proto3.makeMessageType(
  "brain.GetTaxonomyResponse",
  () => [
    { no: 1, name: "taxonomy", kind: "message", T: Taxonomy },
  ],
);

/**
 * Taxonomy Options
 *
 * @generated from message brain.ListTaxonomyOptionsRequest
 */
export const ListTaxonomyOptionsRequest = proto3.makeMessageType(
  "brain.ListTaxonomyOptionsRequest",
  () => [
    { no: 1, name: "taxonomy_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListTaxonomyOptionsResponse
 */
export const ListTaxonomyOptionsResponse = proto3.makeMessageType(
  "brain.ListTaxonomyOptionsResponse",
  () => [
    { no: 1, name: "taxonomy_options", kind: "message", T: TaxonomyOption, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateTaxonomyOptionRequest
 */
export const CreateTaxonomyOptionRequest = proto3.makeMessageType(
  "brain.CreateTaxonomyOptionRequest",
  () => [
    { no: 1, name: "taxonomy_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CreateTaxonomyOptionResponse
 */
export const CreateTaxonomyOptionResponse = proto3.makeMessageType(
  "brain.CreateTaxonomyOptionResponse",
  () => [
    { no: 1, name: "taxonomy_option", kind: "message", T: TaxonomyOption },
  ],
);

/**
 * @generated from message brain.GetTaxonomyOptionRequest
 */
export const GetTaxonomyOptionRequest = proto3.makeMessageType(
  "brain.GetTaxonomyOptionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "taxonomy_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetTaxonomyOptionResponse
 */
export const GetTaxonomyOptionResponse = proto3.makeMessageType(
  "brain.GetTaxonomyOptionResponse",
  () => [
    { no: 1, name: "taxonomy_option", kind: "message", T: TaxonomyOption },
  ],
);

/**
 * @generated from message brain.DeleteTaxonomyOptionRequest
 */
export const DeleteTaxonomyOptionRequest = proto3.makeMessageType(
  "brain.DeleteTaxonomyOptionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteTaxonomyOptionResponse
 */
export const DeleteTaxonomyOptionResponse = proto3.makeMessageType(
  "brain.DeleteTaxonomyOptionResponse",
  [],
);

/**
 * Accounts
 *
 * @generated from message brain.ListConnectedAccountsRequest
 */
export const ListConnectedAccountsRequest = proto3.makeMessageType(
  "brain.ListConnectedAccountsRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListConnectedAccountsResponse
 */
export const ListConnectedAccountsResponse = proto3.makeMessageType(
  "brain.ListConnectedAccountsResponse",
  () => [
    { no: 1, name: "connected_accounts", kind: "message", T: ConnectedAccount, repeated: true },
  ],
);

/**
 * @generated from message brain.GetConnectedAccountRequest
 */
export const GetConnectedAccountRequest = proto3.makeMessageType(
  "brain.GetConnectedAccountRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetConnectedAccountResponse
 */
export const GetConnectedAccountResponse = proto3.makeMessageType(
  "brain.GetConnectedAccountResponse",
  () => [
    { no: 1, name: "connected_account", kind: "message", T: ConnectedAccount },
  ],
);

/**
 * @generated from message brain.CreateConnectedAccountRequest
 */
export const CreateConnectedAccountRequest = proto3.makeMessageType(
  "brain.CreateConnectedAccountRequest",
  () => [
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "max_allowed_plus1", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message brain.CreateConnectedAccountResponse
 */
export const CreateConnectedAccountResponse = proto3.makeMessageType(
  "brain.CreateConnectedAccountResponse",
  () => [
    { no: 1, name: "connected_account", kind: "message", T: ConnectedAccount },
  ],
);

/**
 * @generated from message brain.ListAccountsDetailsRequest
 */
export const ListAccountsDetailsRequest = proto3.makeMessageType(
  "brain.ListAccountsDetailsRequest",
  () => [
    { no: 1, name: "connected_account_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.ListAccountsDetailsResponse
 */
export const ListAccountsDetailsResponse = proto3.makeMessageType(
  "brain.ListAccountsDetailsResponse",
  () => [
    { no: 1, name: "connected_accounts", kind: "message", T: ConnectedAccountRef, repeated: true },
  ],
);

/**
 * @generated from message brain.ConnectAccountRequest
 */
export const ConnectAccountRequest = proto3.makeMessageType(
  "brain.ConnectAccountRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ConnectAccountResponse
 */
export const ConnectAccountResponse = proto3.makeMessageType(
  "brain.ConnectAccountResponse",
  () => [
    { no: 1, name: "connected_account", kind: "message", T: ConnectedAccountRef },
    { no: 2, name: "jwtToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.UpdateConnectedAccountRequest
 */
export const UpdateConnectedAccountRequest = proto3.makeMessageType(
  "brain.UpdateConnectedAccountRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fullname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "image_profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.UpdateConnectedAccountResponse
 */
export const UpdateConnectedAccountResponse = proto3.makeMessageType(
  "brain.UpdateConnectedAccountResponse",
  () => [
    { no: 1, name: "connected_account", kind: "message", T: ConnectedAccountRef },
  ],
);

/**
 * @generated from message brain.ArchiveConnectedAccountRequest
 */
export const ArchiveConnectedAccountRequest = proto3.makeMessageType(
  "brain.ArchiveConnectedAccountRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveConnectedAccountResponse
 */
export const ArchiveConnectedAccountResponse = proto3.makeMessageType(
  "brain.ArchiveConnectedAccountResponse",
  [],
);

/**
 * @generated from message brain.DeleteConnectedAccountRequest
 */
export const DeleteConnectedAccountRequest = proto3.makeMessageType(
  "brain.DeleteConnectedAccountRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteConnectedAccountResponse
 */
export const DeleteConnectedAccountResponse = proto3.makeMessageType(
  "brain.DeleteConnectedAccountResponse",
  [],
);

/**
 * @generated from message brain.SetNotificationDeviceRequest
 */
export const SetNotificationDeviceRequest = proto3.makeMessageType(
  "brain.SetNotificationDeviceRequest",
  () => [
    { no: 1, name: "device_player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.SetNotificationDeviceResponse
 */
export const SetNotificationDeviceResponse = proto3.makeMessageType(
  "brain.SetNotificationDeviceResponse",
  [],
);

/**
 * @generated from message brain.DeleteNotificationDeviceRequest
 */
export const DeleteNotificationDeviceRequest = proto3.makeMessageType(
  "brain.DeleteNotificationDeviceRequest",
  () => [
    { no: 1, name: "device_player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteNotificationDeviceResponse
 */
export const DeleteNotificationDeviceResponse = proto3.makeMessageType(
  "brain.DeleteNotificationDeviceResponse",
  [],
);

/**
 * @generated from message brain.Notification
 */
export const Notification = proto3.makeMessageType(
  "brain.Notification",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "connected_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "date_created", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "date_updated", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListNotificationsRequest
 */
export const ListNotificationsRequest = proto3.makeMessageType(
  "brain.ListNotificationsRequest",
  [],
);

/**
 * @generated from message brain.ListNotificationsResponse
 */
export const ListNotificationsResponse = proto3.makeMessageType(
  "brain.ListNotificationsResponse",
  () => [
    { no: 1, name: "notifications", kind: "message", T: Notification, repeated: true },
  ],
);

/**
 * @generated from message brain.DeleteNotificationRequest
 */
export const DeleteNotificationRequest = proto3.makeMessageType(
  "brain.DeleteNotificationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteNotificationResponse
 */
export const DeleteNotificationResponse = proto3.makeMessageType(
  "brain.DeleteNotificationResponse",
  [],
);

/**
 * tags
 *
 * @generated from message brain.ListTagsRequest
 */
export const ListTagsRequest = proto3.makeMessageType(
  "brain.ListTagsRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListTagsResponse
 */
export const ListTagsResponse = proto3.makeMessageType(
  "brain.ListTagsResponse",
  () => [
    { no: 1, name: "tags", kind: "message", T: Tag, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateTagRequest
 */
export const CreateTagRequest = proto3.makeMessageType(
  "brain.CreateTagRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tag_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CreateTagResponse
 */
export const CreateTagResponse = proto3.makeMessageType(
  "brain.CreateTagResponse",
  () => [
    { no: 1, name: "tag", kind: "message", T: Tag },
  ],
);

/**
 * @generated from message brain.GetTagRequest
 */
export const GetTagRequest = proto3.makeMessageType(
  "brain.GetTagRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tag_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetTagResponse
 */
export const GetTagResponse = proto3.makeMessageType(
  "brain.GetTagResponse",
  () => [
    { no: 1, name: "tag", kind: "message", T: Tag },
  ],
);

/**
 * Feed posts
 *
 * @generated from message brain.ListPostsRequest
 */
export const ListPostsRequest = proto3.makeMessageType(
  "brain.ListPostsRequest",
  () => [
    { no: 1, name: "feed_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "paging", kind: "message", T: RequestPagination },
  ],
);

/**
 * @generated from message brain.ListPostsResponse
 */
export const ListPostsResponse = proto3.makeMessageType(
  "brain.ListPostsResponse",
  () => [
    { no: 1, name: "posts", kind: "message", T: Post, repeated: true },
    { no: 2, name: "metadata", kind: "message", T: Metadata },
  ],
);

/**
 * @generated from message brain.GetPostRequest
 */
export const GetPostRequest = proto3.makeMessageType(
  "brain.GetPostRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.GetPostResponse
 */
export const GetPostResponse = proto3.makeMessageType(
  "brain.GetPostResponse",
  () => [
    { no: 1, name: "post", kind: "message", T: Post },
  ],
);

/**
 * @generated from message brain.CreatePostRequest
 */
export const CreatePostRequest = proto3.makeMessageType(
  "brain.CreatePostRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "feed_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "files", kind: "message", T: File, repeated: true },
    { no: 5, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 6, name: "mention_connected_account_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.CreatePostResponse
 */
export const CreatePostResponse = proto3.makeMessageType(
  "brain.CreatePostResponse",
  () => [
    { no: 1, name: "post", kind: "message", T: Post },
  ],
);

/**
 * @generated from message brain.UpdatePostRequest
 */
export const UpdatePostRequest = proto3.makeMessageType(
  "brain.UpdatePostRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 6, name: "mention_connected_account_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.UpdatePostResponse
 */
export const UpdatePostResponse = proto3.makeMessageType(
  "brain.UpdatePostResponse",
  () => [
    { no: 1, name: "post", kind: "message", T: Post },
  ],
);

/**
 * @generated from message brain.ArchivePostRequest
 */
export const ArchivePostRequest = proto3.makeMessageType(
  "brain.ArchivePostRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchivePostResponse
 */
export const ArchivePostResponse = proto3.makeMessageType(
  "brain.ArchivePostResponse",
  [],
);

/**
 * @generated from message brain.DeletePostRequest
 */
export const DeletePostRequest = proto3.makeMessageType(
  "brain.DeletePostRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeletePostResponse
 */
export const DeletePostResponse = proto3.makeMessageType(
  "brain.DeletePostResponse",
  [],
);

/**
 * Feed post files
 *
 * @generated from message brain.AddFilesToPostRequest
 */
export const AddFilesToPostRequest = proto3.makeMessageType(
  "brain.AddFilesToPostRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "files", kind: "message", T: File, repeated: true },
  ],
);

/**
 * @generated from message brain.AddFilesToPostResponse
 */
export const AddFilesToPostResponse = proto3.makeMessageType(
  "brain.AddFilesToPostResponse",
  [],
);

/**
 * @generated from message brain.ListPostFilesRequest
 */
export const ListPostFilesRequest = proto3.makeMessageType(
  "brain.ListPostFilesRequest",
  () => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListPostFilesResponse
 */
export const ListPostFilesResponse = proto3.makeMessageType(
  "brain.ListPostFilesResponse",
  () => [
    { no: 1, name: "post_files", kind: "message", T: PostFile, repeated: true },
  ],
);

/**
 * @generated from message brain.GetPostFileRequest
 */
export const GetPostFileRequest = proto3.makeMessageType(
  "brain.GetPostFileRequest",
  () => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetPostFileResponse
 */
export const GetPostFileResponse = proto3.makeMessageType(
  "brain.GetPostFileResponse",
  () => [
    { no: 1, name: "post_file", kind: "message", T: PostFile },
  ],
);

/**
 * @generated from message brain.CreatePostFileRequest
 */
export const CreatePostFileRequest = proto3.makeMessageType(
  "brain.CreatePostFileRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message brain.CreatePostFileResponse
 */
export const CreatePostFileResponse = proto3.makeMessageType(
  "brain.CreatePostFileResponse",
  () => [
    { no: 1, name: "post_file", kind: "message", T: PostFile },
  ],
);

/**
 * @generated from message brain.DeletePostFileRequest
 */
export const DeletePostFileRequest = proto3.makeMessageType(
  "brain.DeletePostFileRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeletePostFileResponse
 */
export const DeletePostFileResponse = proto3.makeMessageType(
  "brain.DeletePostFileResponse",
  [],
);

/**
 * @generated from message brain.ListPostInteractionsRequest
 */
export const ListPostInteractionsRequest = proto3.makeMessageType(
  "brain.ListPostInteractionsRequest",
  () => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListPostInteractionsResponse
 */
export const ListPostInteractionsResponse = proto3.makeMessageType(
  "brain.ListPostInteractionsResponse",
  () => [
    { no: 1, name: "post_interactions", kind: "message", T: PostInteraction, repeated: true },
  ],
);

/**
 * @generated from message brain.CreatePostInteractionRequest
 */
export const CreatePostInteractionRequest = proto3.makeMessageType(
  "brain.CreatePostInteractionRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "emoticon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CreatePostInteractionResponse
 */
export const CreatePostInteractionResponse = proto3.makeMessageType(
  "brain.CreatePostInteractionResponse",
  () => [
    { no: 1, name: "post_interaction", kind: "message", T: PostInteraction },
  ],
);

/**
 * @generated from message brain.UpdatePostInteractionRequest
 */
export const UpdatePostInteractionRequest = proto3.makeMessageType(
  "brain.UpdatePostInteractionRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "emoticon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.UpdatePostInteractionResponse
 */
export const UpdatePostInteractionResponse = proto3.makeMessageType(
  "brain.UpdatePostInteractionResponse",
  () => [
    { no: 1, name: "post_interaction", kind: "message", T: PostInteraction },
  ],
);

/**
 * @generated from message brain.DeletePostInteractionRequest
 */
export const DeletePostInteractionRequest = proto3.makeMessageType(
  "brain.DeletePostInteractionRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeletePostInteractionResponse
 */
export const DeletePostInteractionResponse = proto3.makeMessageType(
  "brain.DeletePostInteractionResponse",
  [],
);

/**
 * Comments
 *
 * @generated from message brain.ListPostCommentsRequest
 */
export const ListPostCommentsRequest = proto3.makeMessageType(
  "brain.ListPostCommentsRequest",
  () => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "full", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.ListPostCommentsResponse
 */
export const ListPostCommentsResponse = proto3.makeMessageType(
  "brain.ListPostCommentsResponse",
  () => [
    { no: 1, name: "comments", kind: "message", T: Comment, repeated: true },
  ],
);

/**
 * @generated from message brain.GetCommentRequest
 */
export const GetCommentRequest = proto3.makeMessageType(
  "brain.GetCommentRequest",
  () => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "full", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.GetCommentResponse
 */
export const GetCommentResponse = proto3.makeMessageType(
  "brain.GetCommentResponse",
  () => [
    { no: 1, name: "comment", kind: "message", T: Comment },
  ],
);

/**
 * @generated from message brain.CreateCommentRequest
 */
export const CreateCommentRequest = proto3.makeMessageType(
  "brain.CreateCommentRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 5, name: "mention_connected_account_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateCommentResponse
 */
export const CreateCommentResponse = proto3.makeMessageType(
  "brain.CreateCommentResponse",
  () => [
    { no: 1, name: "comment", kind: "message", T: Comment },
  ],
);

/**
 * @generated from message brain.UpdateCommentRequest
 */
export const UpdateCommentRequest = proto3.makeMessageType(
  "brain.UpdateCommentRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "tags", kind: "message", T: Tag, repeated: true },
    { no: 6, name: "mention_connected_account_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.UpdateCommentResponse
 */
export const UpdateCommentResponse = proto3.makeMessageType(
  "brain.UpdateCommentResponse",
  () => [
    { no: 1, name: "comment", kind: "message", T: Comment },
  ],
);

/**
 * @generated from message brain.ArchiveCommentRequest
 */
export const ArchiveCommentRequest = proto3.makeMessageType(
  "brain.ArchiveCommentRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveCommentResponse
 */
export const ArchiveCommentResponse = proto3.makeMessageType(
  "brain.ArchiveCommentResponse",
  [],
);

/**
 * @generated from message brain.DeleteCommentRequest
 */
export const DeleteCommentRequest = proto3.makeMessageType(
  "brain.DeleteCommentRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteCommentResponse
 */
export const DeleteCommentResponse = proto3.makeMessageType(
  "brain.DeleteCommentResponse",
  [],
);

/**
 * @generated from message brain.ListCommentInteractionsRequest
 */
export const ListCommentInteractionsRequest = proto3.makeMessageType(
  "brain.ListCommentInteractionsRequest",
  () => [
    { no: 1, name: "comment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListCommentInteractionsResponse
 */
export const ListCommentInteractionsResponse = proto3.makeMessageType(
  "brain.ListCommentInteractionsResponse",
  () => [
    { no: 1, name: "comment_interactions", kind: "message", T: CommentInteraction, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateCommentInteractionRequest
 */
export const CreateCommentInteractionRequest = proto3.makeMessageType(
  "brain.CreateCommentInteractionRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "comment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "emoticon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CreateCommentInteractionResponse
 */
export const CreateCommentInteractionResponse = proto3.makeMessageType(
  "brain.CreateCommentInteractionResponse",
  () => [
    { no: 1, name: "comment_interaction", kind: "message", T: CommentInteraction },
  ],
);

/**
 * @generated from message brain.DeleteCommentInteractionRequest
 */
export const DeleteCommentInteractionRequest = proto3.makeMessageType(
  "brain.DeleteCommentInteractionRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "comment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteCommentInteractionResponse
 */
export const DeleteCommentInteractionResponse = proto3.makeMessageType(
  "brain.DeleteCommentInteractionResponse",
  [],
);

/**
 * @generated from message brain.UpdateEmailInvitationWebsiteRequest
 */
export const UpdateEmailInvitationWebsiteRequest = proto3.makeMessageType(
  "brain.UpdateEmailInvitationWebsiteRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.UpdateEmailInvitationWebsiteResponse
 */
export const UpdateEmailInvitationWebsiteResponse = proto3.makeMessageType(
  "brain.UpdateEmailInvitationWebsiteResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Tasks
 *
 * @generated from message brain.ListTasksRequest
 */
export const ListTasksRequest = proto3.makeMessageType(
  "brain.ListTasksRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "paging", kind: "message", T: RequestPagination },
  ],
);

/**
 * @generated from message brain.ListTasksResponse
 */
export const ListTasksResponse = proto3.makeMessageType(
  "brain.ListTasksResponse",
  () => [
    { no: 1, name: "tasks", kind: "message", T: Task, repeated: true },
    { no: 2, name: "metadata", kind: "message", T: Metadata },
  ],
);

/**
 * @generated from message brain.GetTaskRequest
 */
export const GetTaskRequest = proto3.makeMessageType(
  "brain.GetTaskRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetTaskResponse
 */
export const GetTaskResponse = proto3.makeMessageType(
  "brain.GetTaskResponse",
  () => [
    { no: 1, name: "task", kind: "message", T: Task },
  ],
);

/**
 * @generated from message brain.CreateTaskRequest
 */
export const CreateTaskRequest = proto3.makeMessageType(
  "brain.CreateTaskRequest",
  () => [
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "due_day", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "order", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(TaskStatus) },
  ],
);

/**
 * @generated from message brain.CreateTaskResponse
 */
export const CreateTaskResponse = proto3.makeMessageType(
  "brain.CreateTaskResponse",
  () => [
    { no: 1, name: "task", kind: "message", T: Task },
  ],
);

/**
 * @generated from message brain.UpdateTaskRequest
 */
export const UpdateTaskRequest = proto3.makeMessageType(
  "brain.UpdateTaskRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "due_day", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "order", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(TaskStatus) },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.UpdateTaskResponse
 */
export const UpdateTaskResponse = proto3.makeMessageType(
  "brain.UpdateTaskResponse",
  () => [
    { no: 1, name: "task", kind: "message", T: Task },
  ],
);

/**
 * @generated from message brain.ArchiveTaskRequest
 */
export const ArchiveTaskRequest = proto3.makeMessageType(
  "brain.ArchiveTaskRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveTaskResponse
 */
export const ArchiveTaskResponse = proto3.makeMessageType(
  "brain.ArchiveTaskResponse",
  () => [
    { no: 1, name: "task", kind: "message", T: Task },
  ],
);

/**
 * @generated from message brain.DeleteTaskRequest
 */
export const DeleteTaskRequest = proto3.makeMessageType(
  "brain.DeleteTaskRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteTaskResponse
 */
export const DeleteTaskResponse = proto3.makeMessageType(
  "brain.DeleteTaskResponse",
  [],
);

/**
 * wedding website template
 *
 * @generated from message brain.ListWeddingWebsitesRequest
 */
export const ListWeddingWebsitesRequest = proto3.makeMessageType(
  "brain.ListWeddingWebsitesRequest",
  () => [
    { no: 1, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListWeddingWebsitesResponse
 */
export const ListWeddingWebsitesResponse = proto3.makeMessageType(
  "brain.ListWeddingWebsitesResponse",
  () => [
    { no: 1, name: "wedding_websites", kind: "message", T: WeddingWebsite, repeated: true },
  ],
);

/**
 * @generated from message brain.GetWeddingWebsiteRequest
 */
export const GetWeddingWebsiteRequest = proto3.makeMessageType(
  "brain.GetWeddingWebsiteRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetWeddingWebsiteResponse
 */
export const GetWeddingWebsiteResponse = proto3.makeMessageType(
  "brain.GetWeddingWebsiteResponse",
  () => [
    { no: 1, name: "wedding_website", kind: "message", T: WeddingWebsite },
  ],
);

/**
 * @generated from message brain.CreateWeddingWebsiteRequest
 */
export const CreateWeddingWebsiteRequest = proto3.makeMessageType(
  "brain.CreateWeddingWebsiteRequest",
  () => [
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "visibility_status", kind: "enum", T: proto3.getEnumType(VisibilityStatus) },
    { no: 6, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "pages", kind: "message", T: CreateWeddingWebsitePageRequest, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateWeddingWebsiteResponse
 */
export const CreateWeddingWebsiteResponse = proto3.makeMessageType(
  "brain.CreateWeddingWebsiteResponse",
  () => [
    { no: 1, name: "wedding_website", kind: "message", T: WeddingWebsite },
  ],
);

/**
 * @generated from message brain.UpdateWeddingWebsiteRequest
 */
export const UpdateWeddingWebsiteRequest = proto3.makeMessageType(
  "brain.UpdateWeddingWebsiteRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "visibility_status", kind: "enum", T: proto3.getEnumType(VisibilityStatus) },
    { no: 6, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "pages", kind: "message", T: UpdateWeddingWebsitePageRequest, repeated: true },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.UpdateWeddingWebsiteResponse
 */
export const UpdateWeddingWebsiteResponse = proto3.makeMessageType(
  "brain.UpdateWeddingWebsiteResponse",
  () => [
    { no: 1, name: "wedding_website", kind: "message", T: WeddingWebsite },
  ],
);

/**
 * @generated from message brain.ArchiveWeddingWebsiteRequest
 */
export const ArchiveWeddingWebsiteRequest = proto3.makeMessageType(
  "brain.ArchiveWeddingWebsiteRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveWeddingWebsiteResponse
 */
export const ArchiveWeddingWebsiteResponse = proto3.makeMessageType(
  "brain.ArchiveWeddingWebsiteResponse",
  () => [
    { no: 1, name: "wedding_website", kind: "message", T: WeddingWebsite },
  ],
);

/**
 * @generated from message brain.DeleteWeddingWebsiteRequest
 */
export const DeleteWeddingWebsiteRequest = proto3.makeMessageType(
  "brain.DeleteWeddingWebsiteRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteWeddingWebsiteResponse
 */
export const DeleteWeddingWebsiteResponse = proto3.makeMessageType(
  "brain.DeleteWeddingWebsiteResponse",
  [],
);

/**
 * @generated from message brain.ListWeddingWebsitePagesRequest
 */
export const ListWeddingWebsitePagesRequest = proto3.makeMessageType(
  "brain.ListWeddingWebsitePagesRequest",
  () => [
    { no: 1, name: "wedding_website_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListWeddingWebsitePagesResponse
 */
export const ListWeddingWebsitePagesResponse = proto3.makeMessageType(
  "brain.ListWeddingWebsitePagesResponse",
  () => [
    { no: 1, name: "pages", kind: "message", T: Page, repeated: true },
  ],
);

/**
 * @generated from message brain.GetWeddingWebsitePageRequest
 */
export const GetWeddingWebsitePageRequest = proto3.makeMessageType(
  "brain.GetWeddingWebsitePageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetWeddingWebsitePageResponse
 */
export const GetWeddingWebsitePageResponse = proto3.makeMessageType(
  "brain.GetWeddingWebsitePageResponse",
  () => [
    { no: 1, name: "page", kind: "message", T: Page },
  ],
);

/**
 * @generated from message brain.CreateWeddingWebsitePageRequest
 */
export const CreateWeddingWebsitePageRequest = proto3.makeMessageType(
  "brain.CreateWeddingWebsitePageRequest",
  () => [
    { no: 2, name: "wedding_website_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "visibility_status", kind: "enum", T: proto3.getEnumType(VisibilityStatus) },
    { no: 6, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "section_datas", kind: "message", T: CreateWeddingWebsitePageSectionRequest, repeated: true },
  ],
);

/**
 * @generated from message brain.CreateWeddingWebsitePageResponse
 */
export const CreateWeddingWebsitePageResponse = proto3.makeMessageType(
  "brain.CreateWeddingWebsitePageResponse",
  () => [
    { no: 1, name: "page", kind: "message", T: Page },
  ],
);

/**
 * @generated from message brain.UpdateWeddingWebsitePageRequest
 */
export const UpdateWeddingWebsitePageRequest = proto3.makeMessageType(
  "brain.UpdateWeddingWebsitePageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_website_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "visibility_status", kind: "enum", T: proto3.getEnumType(VisibilityStatus) },
    { no: 6, name: "path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "section_datas", kind: "message", T: UpdateWeddingWebsitePageSectionRequest, repeated: true },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.UpdateWeddingWebsitePageResponse
 */
export const UpdateWeddingWebsitePageResponse = proto3.makeMessageType(
  "brain.UpdateWeddingWebsitePageResponse",
  () => [
    { no: 1, name: "page", kind: "message", T: Page },
  ],
);

/**
 * @generated from message brain.ArchiveWeddingWebsitePageRequest
 */
export const ArchiveWeddingWebsitePageRequest = proto3.makeMessageType(
  "brain.ArchiveWeddingWebsitePageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveWeddingWebsitePageResponse
 */
export const ArchiveWeddingWebsitePageResponse = proto3.makeMessageType(
  "brain.ArchiveWeddingWebsitePageResponse",
  () => [
    { no: 1, name: "page", kind: "message", T: Page },
  ],
);

/**
 * @generated from message brain.DeleteWeddingWebsitePageRequest
 */
export const DeleteWeddingWebsitePageRequest = proto3.makeMessageType(
  "brain.DeleteWeddingWebsitePageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteWeddingWebsitePageResponse
 */
export const DeleteWeddingWebsitePageResponse = proto3.makeMessageType(
  "brain.DeleteWeddingWebsitePageResponse",
  [],
);

/**
 * @generated from message brain.ListWeddingWebsitePageSectionsRequest
 */
export const ListWeddingWebsitePageSectionsRequest = proto3.makeMessageType(
  "brain.ListWeddingWebsitePageSectionsRequest",
  () => [
    { no: 1, name: "wedding_website_page_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ListWeddingWebsitePageSectionsResponse
 */
export const ListWeddingWebsitePageSectionsResponse = proto3.makeMessageType(
  "brain.ListWeddingWebsitePageSectionsResponse",
  () => [
    { no: 1, name: "section_datas", kind: "message", T: SectionData, repeated: true },
  ],
);

/**
 * @generated from message brain.GetWeddingWebsitePageSectionRequest
 */
export const GetWeddingWebsitePageSectionRequest = proto3.makeMessageType(
  "brain.GetWeddingWebsitePageSectionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.GetWeddingWebsitePageSectionResponse
 */
export const GetWeddingWebsitePageSectionResponse = proto3.makeMessageType(
  "brain.GetWeddingWebsitePageSectionResponse",
  () => [
    { no: 1, name: "section_data", kind: "message", T: SectionData },
  ],
);

/**
 * @generated from message brain.CreateWeddingWebsitePageSectionRequest
 */
export const CreateWeddingWebsitePageSectionRequest = proto3.makeMessageType(
  "brain.CreateWeddingWebsitePageSectionRequest",
  () => [
    { no: 2, name: "wedding_website_page_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "frontend_component_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ts_class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "go_struct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "generic_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "data_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "order", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message brain.CreateWeddingWebsitePageSectionResponse
 */
export const CreateWeddingWebsitePageSectionResponse = proto3.makeMessageType(
  "brain.CreateWeddingWebsitePageSectionResponse",
  () => [
    { no: 1, name: "section_data", kind: "message", T: SectionData },
  ],
);

/**
 * @generated from message brain.UpdateWeddingWebsitePageSectionRequest
 */
export const UpdateWeddingWebsitePageSectionRequest = proto3.makeMessageType(
  "brain.UpdateWeddingWebsitePageSectionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_website_page_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "frontend_component_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ts_class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "go_struct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "generic_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "data_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "order", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.UpdateWeddingWebsitePageSectionResponse
 */
export const UpdateWeddingWebsitePageSectionResponse = proto3.makeMessageType(
  "brain.UpdateWeddingWebsitePageSectionResponse",
  () => [
    { no: 1, name: "section_data", kind: "message", T: SectionData },
  ],
);

/**
 * @generated from message brain.ArchiveWeddingWebsitePageSectionRequest
 */
export const ArchiveWeddingWebsitePageSectionRequest = proto3.makeMessageType(
  "brain.ArchiveWeddingWebsitePageSectionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.ArchiveWeddingWebsitePageSectionResponse
 */
export const ArchiveWeddingWebsitePageSectionResponse = proto3.makeMessageType(
  "brain.ArchiveWeddingWebsitePageSectionResponse",
  () => [
    { no: 1, name: "section_data", kind: "message", T: SectionData },
  ],
);

/**
 * @generated from message brain.DeleteWeddingWebsitePageSectionRequest
 */
export const DeleteWeddingWebsitePageSectionRequest = proto3.makeMessageType(
  "brain.DeleteWeddingWebsitePageSectionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DeleteWeddingWebsitePageSectionResponse
 */
export const DeleteWeddingWebsitePageSectionResponse = proto3.makeMessageType(
  "brain.DeleteWeddingWebsitePageSectionResponse",
  [],
);

/**
 * @generated from message brain.GlobalAvailableTemplatesRequest
 */
export const GlobalAvailableTemplatesRequest = proto3.makeMessageType(
  "brain.GlobalAvailableTemplatesRequest",
  [],
);

/**
 * @generated from message brain.GlobalAvailableTemplatesResponse
 */
export const GlobalAvailableTemplatesResponse = proto3.makeMessageType(
  "brain.GlobalAvailableTemplatesResponse",
  () => [
    { no: 1, name: "available_templates", kind: "message", T: AvailableTemplates, repeated: true },
  ],
);

/**
 * @generated from message brain.AvailableTemplates
 */
export const AvailableTemplates = proto3.makeMessageType(
  "brain.AvailableTemplates",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "price_in_euro", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "discount_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "discounted_price_in_euro", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "section_components", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message brain.SendInvitationsRequest
 */
export const SendInvitationsRequest = proto3.makeMessageType(
  "brain.SendInvitationsRequest",
  () => [
    { no: 1, name: "invited_visitor_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "invitation_option", kind: "enum", T: proto3.getEnumType(InvitationOption) },
    { no: 3, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.SendInvitationsResponse
 */
export const SendInvitationsResponse = proto3.makeMessageType(
  "brain.SendInvitationsResponse",
  [],
);

/**
 * @generated from message brain.WeddingRef
 */
export const WeddingRef = proto3.makeMessageType(
  "brain.WeddingRef",
  () => [
    { no: 1, name: "wedding", kind: "message", T: Wedding },
    { no: 2, name: "hosts", kind: "message", T: ConnectedAccountRef, repeated: true },
  ],
);

/**
 * @generated from message brain.ListAllWeddingsRequest
 */
export const ListAllWeddingsRequest = proto3.makeMessageType(
  "brain.ListAllWeddingsRequest",
  () => [
    { no: 1, name: "paging", kind: "message", T: RequestPagination },
  ],
);

/**
 * @generated from message brain.ListAllWeddingsResponse
 */
export const ListAllWeddingsResponse = proto3.makeMessageType(
  "brain.ListAllWeddingsResponse",
  () => [
    { no: 1, name: "weddings_ref", kind: "message", T: WeddingRef, repeated: true },
    { no: 2, name: "metadata", kind: "message", T: Metadata },
  ],
);

/**
 * @generated from message brain.TotalAllWeddingsRequest
 */
export const TotalAllWeddingsRequest = proto3.makeMessageType(
  "brain.TotalAllWeddingsRequest",
  [],
);

/**
 * @generated from message brain.TotalAllWeddingsResponse
 */
export const TotalAllWeddingsResponse = proto3.makeMessageType(
  "brain.TotalAllWeddingsResponse",
  () => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message brain.PublicUpdateArrivalForAllowedVisitorRequest
 */
export const PublicUpdateArrivalForAllowedVisitorRequest = proto3.makeMessageType(
  "brain.PublicUpdateArrivalForAllowedVisitorRequest",
  () => [
    { no: 1, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rsvp_event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "arrival", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message brain.PublicUpdateArrivalForAllowedVisitorResponse
 */
export const PublicUpdateArrivalForAllowedVisitorResponse = proto3.makeMessageType(
  "brain.PublicUpdateArrivalForAllowedVisitorResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: PublicAllowedVisitor },
  ],
);

/**
 * @generated from message brain.DownloadGuestListRequest
 */
export const DownloadGuestListRequest = proto3.makeMessageType(
  "brain.DownloadGuestListRequest",
  () => [
    { no: 1, name: "file_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DownloadGuestListResponse
 */
export const DownloadGuestListResponse = proto3.makeMessageType(
  "brain.DownloadGuestListResponse",
  () => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message brain.DownloadRSVPGuestListRequest
 */
export const DownloadRSVPGuestListRequest = proto3.makeMessageType(
  "brain.DownloadRSVPGuestListRequest",
  () => [
    { no: 1, name: "file_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "rsvp_responded", kind: "enum", T: proto3.getEnumType(RSVPResponded) },
  ],
);

/**
 * @generated from message brain.DownloadRSVPGuestListResponse
 */
export const DownloadRSVPGuestListResponse = proto3.makeMessageType(
  "brain.DownloadRSVPGuestListResponse",
  () => [
    { no: 1, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message brain.CouponCheckRequest
 */
export const CouponCheckRequest = proto3.makeMessageType(
  "brain.CouponCheckRequest",
  () => [
    { no: 1, name: "coupon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.CouponCheckResponse
 */
export const CouponCheckResponse = proto3.makeMessageType(
  "brain.CouponCheckResponse",
  () => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "coupon", kind: "message", T: Coupon },
  ],
);

/**
 * @generated from message brain.Coupon
 */
export const Coupon = proto3.makeMessageType(
  "brain.Coupon",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "discount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message brain.ListAllPackagesRequest
 */
export const ListAllPackagesRequest = proto3.makeMessageType(
  "brain.ListAllPackagesRequest",
  [],
);

/**
 * @generated from message brain.ListAllPackagesResponse
 */
export const ListAllPackagesResponse = proto3.makeMessageType(
  "brain.ListAllPackagesResponse",
  () => [
    { no: 1, name: "packages", kind: "message", T: Package, repeated: true },
  ],
);

/**
 * @generated from message brain.Package
 */
export const Package = proto3.makeMessageType(
  "brain.Package",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "original_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "discount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message brain.DetachAllowedVisitorFromAllowedVisitorsRequest
 */
export const DetachAllowedVisitorFromAllowedVisitorsRequest = proto3.makeMessageType(
  "brain.DetachAllowedVisitorFromAllowedVisitorsRequest",
  () => [
    { no: 1, name: "allowed_visitor_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "allowed_visitor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wedding_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message brain.DetachAllowedVisitorFromAllowedVisitorsResponse
 */
export const DetachAllowedVisitorFromAllowedVisitorsResponse = proto3.makeMessageType(
  "brain.DetachAllowedVisitorFromAllowedVisitorsResponse",
  () => [
    { no: 1, name: "allowed_visitor", kind: "message", T: AllowedVisitor },
  ],
);

