import React from 'react';
import templateStyles from '../sepiaTemplate.module.scss';
import styles from './SepiaOurStoryComponent.module.scss';

interface OurStoryComponentProps {
  title?: string;
  description?: string;
  graphicsImageUrl?: string;
}

const defaultValues = {
  title: 'Our Story',
  description:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit amet velit eget ac vulputate. Laoreet amet consequat diam id morbi a. At at aliquet orci leo in condimentum morbi ut. Tincidunt lacus pellentesque sodales elit et eget adipiscing sapien, massa. Feugiat aliquet amet praesent viverra mattis quam. Tellus massa mauris, pulvinar maecenas. Ullamcorper sit phasellus augue sapien risus. Sagittis vitae vel amet scelerisque scelerisque. Quisque tortor urna imperdiet amet feugiat vestibulum ultricies. Mauris erat ut diam turpis ut non. Sed pulvinar donec purus, diam elementum consequat vivamus. Dui sem turpis lectus elit quisque id. Suscipit risus aliquam eu ac mi et tristique elementum. Nulla in viverra massa magna auctor quis.</p>',
  graphicsImageUrl: '/templates/sepia/ourStoryComponentGraphics.svg',
};

const SepiaOurStoryComponent = ({ title, description, graphicsImageUrl }: OurStoryComponentProps): JSX.Element => {
  return (
    <section id={`SepiaOurStoryComponent`} className={styles.OurStoryComponent}>
      <h3 className={templateStyles.fridayH2}>{String(title ? title : defaultValues.title).toUpperCase()}</h3>
      <div
        style={{ textAlign: 'justify' }}
        dangerouslySetInnerHTML={{ __html: description ? description : defaultValues.description }}
      />
      <img src={graphicsImageUrl ? graphicsImageUrl : defaultValues.graphicsImageUrl} alt="our story graphics" />
    </section>
  );
};
export default SepiaOurStoryComponent;
