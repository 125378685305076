import './CreatePostModal.scss';

import { css } from '@emotion/react';
import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const GenericModal = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [caption, setCaption] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className={props.showModal ? 'show-modal' : 'default-modal'}>
      <div className={'create-post'}>
        <div>
          <p>{props.message}</p>
        </div>

        <div>
          <button
            className={'post-btn'}
            onMouseDown={() => {
              props.setShowModal(false);
              if (props.isRedirect) {
                //TODO: CHANGE TO OVERVIEW
                window.location.pathname = props.redirectPath;
              }
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
