import React, { Fragment, useEffect, useState } from 'react';
import { selectRsvpEvents, selectWedding, setRsvpEventsReducer } from '../../app/redux/wedding.slice.recuder';
import { useDispatch, useSelector } from 'react-redux';
import { selectJwt, selectProfile, user } from '../../app/redux/authorization.slice.reducer';
import { BrainHelper } from '../../pkg/apiHelpers/brainHelper';
import { Imagehelper } from '../../app/helper/Imagehelper';
import { IonContent, IonHeader, IonImg, IonPage } from '@ionic/react';
import styles from './Main.module.scss';
import { dateTimeFormat } from '../../config/dateFormats';
import jwt_decode from 'jwt-decode';
import {
  ConnectedAccountRef,
  GetInvitedVisitorByPhoneOrEmailResponse,
  RSVPEvent,
} from '../../pkg/protobuf/v2/brain/brain_types_pb';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar } from '../../component/dashboard/table/Table';
import Icon from '../../shared/Icon';
import GenericConfirmModal from '../../component/modals/GenericConfirmModal';
import GetUI, { EventType } from '../../utils/uiContent';
import Image from '../../shared/Image/Image';

export const Main = (): JSX.Element => {
  //GET WEDDING IMAGE
  const wedding = useSelector(selectWedding);
  const rsvpEvents = useSelector(selectRsvpEvents);
  const userToken = useSelector(selectJwt);
  const profile = useSelector(selectProfile);
  const [eventsState, setEventsState] = useState<RSVPEvent[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileUrl, setFileurl] = useState<string>('');
  const [fileId, setFileId] = useState<string | undefined>('');
  const [blobId, setBlobId] = useState<string>('');
  const [daysToWedding, setDaysToWedding] = useState(0);
  const [invited, setInvited] = useState<GetInvitedVisitorByPhoneOrEmailResponse | undefined>(undefined);
  const [comingSoonModal, setComingSoonModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();

  useEffect(() => {
    if (wedding?.mainEvent?.icon) setFileId(wedding.mainEvent.icon);
    if (wedding?.mainEvent?.startTimestamp)
      setDaysToWedding(
        Math.ceil(
          (new Date(wedding?.mainEvent.startTimestamp).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
        ),
      );
    getRsvpEvents();
    getInvitedVisitor();
  }, []);

  useEffect(() => {
    const tempEventsArray: RSVPEvent[] = JSON.parse(
      JSON.stringify(rsvpEvents, (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
    );
    const sorted = tempEventsArray.sort(
      (a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime(),
    );
    setEventsState(sorted);
  }, [rsvpEvents]);

  useEffect(() => {
    if (fileId !== '' && fileId !== undefined) fetchPostImage(fileId);
  }, [fileId]);

  // get image
  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlobId(result);
  };

  const getRsvpEvents = async () => {
    const rsvpEvents = await BrainHelper.listRSVPEvents(userToken, wedding.id);
    dispatch(setRsvpEventsReducer(rsvpEvents?.rsvpEvents));
  };

  const getInvitedVisitor = async () => {
    const jwtDecode: user = jwt_decode(userToken);
    const invitedVisitor = await BrainHelper.getInvitedVisitorByPhoneOrEmail(
      userToken,
      wedding.id,
      jwtDecode?.phoneNumber,
      jwtDecode.email ? jwtDecode.email : '',
    );
    setInvited(invitedVisitor);
  };

  return (
    <IonPage>
      <IonHeader className="header">
        <img loading="lazy" className="headerLogo" src="/assets/logos/whiteclover-logo1.svg" alt="logo" />
        <button onClick={() => navigate('/profile')}>
          <Avatar
            query={''}
            name={`${profile.accountFirstName} ${profile.accountLastName}`}
            userToken={userToken}
            connectedAccountDetails={
              new ConnectedAccountRef({
                accountFirstName: profile.accountFirstName,
                accountLastName: profile.accountLastName,
                imageProfileUrl: profile.imageProfileUrl,
              })
            }
          />
        </button>
      </IonHeader>
      <IonContent>
        <div className={styles.Main}>
          <div className={styles.imgWrapper}>
            <Image src={blobId} alt={'wedding'} fixedSize />
            <h1 className={'fridayH1'}>{wedding.name}</h1>
            <div>
              <img src={GetUI(wedding.socialEventType as EventType).icon} alt="Social Event" width={32} />
              {daysToWedding <= 0 ? <p>{daysToWedding === 0 ? 'Today' : ''}</p> : <p>{daysToWedding} Days to go</p>}
            </div>
          </div>
          <div className={styles.eventsWrapper}>
            <h3 className={'didacticH3'}>Events</h3>
            <div className={styles.events}>
              {(invited?.allowedVisitors[0].invitedToRsvpEvents &&
                invited?.allowedVisitors[0].invitedToRsvpEvents.length > 0) ||
              rsvpEvents?.findIndex((r) => !r.isPrivate) > -1 ? (
                eventsState.map((e, key) => {
                  if (
                    (invited?.allowedVisitors[0].invitedToRsvpEvents &&
                      invited?.allowedVisitors[0].invitedToRsvpEvents.length > 0) ||
                    !e.isPrivate
                  ) {
                    const index = invited?.allowedVisitors[0].invitedToRsvpEvents.findIndex((i) => i.id === e.id);
                    return (index !== undefined && index > -1) || !e.isPrivate ? (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      <div
                        role={'button'}
                        tabIndex={0}
                        key={key}
                        className={styles.event}
                        onClick={() =>
                          (index !== undefined && index > -1) || !e.isPrivate ? navigate('/rsvp?event=' + e.id) : ''
                        }
                      >
                        <h4 className={'didacticH4'} style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                          {e.name}
                        </h4>
                        <p>
                          {`${dateTimeFormat({
                            timeZone: e.location?.externalQuickCode,
                            weekday: 'short',
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          }).format(new Date(e.startTimestamp))}`}
                        </p>
                        <div>
                          <img src="/rsvp/map.svg" alt="map" />
                          <p
                            className={'didacticP ' + styles.highlighted}
                            style={{
                              wordWrap: 'break-word',
                              width: '250px',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {e.location?.name +
                              (e.location?.name && e.location?.name !== e.location?.address ? ', ' : '') +
                              (e.location?.name !== e.location?.address ? e.location?.address : '')}
                          </p>
                        </div>
                        <div>
                          <img src="/rsvp/clock-dark.svg" alt="clock" />
                          <p>
                            {!isNaN(new Date(e.startTimestamp).getTime()) &&
                            !isNaN(new Date(e.endTimestamp).getTime()) ? (
                              <p className={'didacticP'}>{`${dateTimeFormat({
                                timeZone: e.location?.externalQuickCode,
                                hour: 'numeric',
                                minute: 'numeric',
                              }).format(new Date(e.startTimestamp))} - ${dateTimeFormat({
                                timeZone: e.location?.externalQuickCode,
                                hour: 'numeric',
                                minute: 'numeric',
                              }).format(new Date(e.endTimestamp))}`}</p>
                            ) : (
                              ''
                            )}
                          </p>
                        </div>
                        {invited?.allowedVisitors[0].attendToRsvpEvents.find((a) => a?.rsvpEvent?.id === e.id) ? (
                          <Fragment>
                            {invited?.allowedVisitors[0].attendToRsvpEvents.find(
                              (a) => a?.rsvpEvent?.id === e.id && a.attend,
                            ) ? (
                              <div>
                                <img src="/assets/icons/icon-going.svg" alt="going" />
                                <p className={'didacticP'} style={{ color: '#00C9A5' }}>
                                  Going
                                </p>
                              </div>
                            ) : (
                              <div>
                                <img src="/assets/icons/icon-not-going.svg" alt="not going" />
                                <p className={'didacticP'} style={{ color: '#D54F68' }}>
                                  Not Going
                                </p>
                              </div>
                            )}
                          </Fragment>
                        ) : (
                          <div>
                            <img src="/assets/icons/icon-not-responded.svg" alt="not responded" />
                            <p className={'didacticP'}>Not Responded</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    );
                  }
                })
              ) : (
                <div>No Events to attend to yet. Check back later.</div>
              )}
            </div>
          </div>
          {wedding.emailInvitationWebsite && (
            <div className={styles.websiteWrapper}>
              <button
                className={styles.websiteButton}
                style={{ backgroundImage: 'url(/assets/website-gifts.jpg)' }}
                onClick={(e) => {
                  if (e.button === 2) return;
                  setIsModalOpen(true);
                }}
              >
                <div className={styles.blur} />
                <h4 className={'didacticH4'}>
                  For Gifts, Contacts and more, click here to be transferred to our website
                </h4>
              </button>
            </div>
          )}
        </div>
      </IonContent>
      <div className="footerSectionMainPlain">
        <button
          className={'website'}
          style={!wedding.emailInvitationWebsite ? { opacity: '.5' } : { cursor: 'pointer' }}
          onClick={(e) => {
            if (e.button === 2) return;
            if (wedding.emailInvitationWebsite) setIsModalOpen(true);
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-browser.svg" alt={'website'} />
          <p>Website</p>
        </button>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/schedule');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-calendar.svg" alt={'schedule'} />
          <p>Schedule</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/main');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-filled-apps.svg" alt={'main'} />
          <p>Main</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/rsvp');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-envelope-marker.svg" alt={'rsvp'} />
          <p>RSVP</p>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          role={'button'}
          tabIndex={0}
          onMouseDown={(e) => {
            if (e.button === 2) return;
            navigate('/home');
          }}
        >
          <IonImg className="plainImg" src="/assets/icons/icon-camera.svg" alt={'photo app'} />
          <p>Photo App</p>
        </div>
      </div>
      {comingSoonModal && (
        <div className={'notification-modal'}>
          <button
            style={{ height: '85%', width: '100%', opacity: 0 }}
            onClick={() => {
              setComingSoonModal(false);
            }}
          ></button>
          <div style={{ height: '15%', display: 'flex', justifyContent: 'center' }}>
            <div className={'inputCommentContainer'} style={{ display: 'flex', justifyContent: 'center' }}>
              Coming Soon
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <GenericConfirmModal
          setShow={setIsModalOpen}
          title={'Confirm Action'}
          text={"You are about to leave this app and open a new tab to continue on the couple's website."}
          confirmBtnFunction={() => {
            setIsModalOpen(false);
            window.open(
              (window.location.href.includes('uk')
                ? process.env.REACT_APP_NEXTJS_URL_UK
                : process.env.REACT_APP_NEXTJS_URL_GR) +
                'm/' +
                wedding.path,
              '_blank', // <- This is what makes it open in a new window.
            );
          }}
          confirmBtnText={'Continue'}
          confirmBtnIcon={''}
          // html={t}
        />
      )}
    </IonPage>
  );
};
