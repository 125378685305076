import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectJwt, selectProfile } from '../../../app/redux/authorization.slice.reducer';

import { Node, Edge } from 'reactflow';

import 'reactflow/dist/style.css';
import FlowWithTheme from './flow/Flow';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { logger } from '../../../config/Logger';
import {
  ConnectedAccountRef,
  TotalAllowedVisitorsResponse,
  Wedding,
  WeddingWebsite,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { DataGrid, GridToolbar, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import './DashboardAdmin.module.scss';

import SplitPane from 'react-split-pane';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

type DashboardAdminProps = any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DashboardAdmin = (props: DashboardAdminProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const userToken = useSelector(selectJwt);
  const [nodes, setNodes] = useState<Node[]>();
  const [edges, setEdges] = useState<Edge[]>();

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    const nodesArray: Node[] = [];
    const edgesArray: Edge[] = [];
    const position = { x: 0, y: 0 };
    BrainHelper.listAllWeddings(userToken)
      .then(async (rsp) => {
        if (!rsp || !rsp.weddingsRef || !Array.isArray(rsp.weddingsRef)) return;
        console.log('all weddings', rsp);
        const nodesAccountArray: Node[] = [];
        const edgesAccountArray: Edge[] = [];
        let countWeddingNode = 0;
        let countHostNode = 0;
        let countAccountNode = 0;
        let activeWeddings = 0;
        let inactiveWeddings = 0;
        let testWeddings = 0;
        // ended and ongoing is general status
        let endedWeddings = 0;
        let ongoingWeddings = 0;
        nodesArray.push({
          id: 'weddingCount',
          type: 'statusNode',
          data: `#weddings: ${rsp?.weddingsRef?.length}`,
          position: position,
        });
        for (const weddingRef of rsp.weddingsRef) {
          // number of invited visitors per wedding
          let visitorStats: TotalAllowedVisitorsResponse | undefined = undefined;
          // find main website
          let mainWebsite: WeddingWebsite | undefined = undefined;
          // find RSVP events
          let rsvpEventsTotal = 0;
          let status = 'inactive';
          let dateStatus = 'ended';
          if (weddingRef?.wedding?.id) {
            visitorStats = await BrainHelper.totalAllowedVisitors(weddingRef.wedding.id, userToken);
            const websites = await BrainHelper.listWeddingWebsites(
              weddingRef.wedding.id,
              weddingRef.wedding.path,
              userToken,
            );
            if (websites && websites.length > 0) {
              mainWebsite = websites[0];
            }
            const rsvpResults = await BrainHelper.listRSVPEvents(userToken, weddingRef.wedding.id, null, null);
            if (rsvpResults && rsvpResults.rsvpEvents.length > 0) {
              rsvpEventsTotal = rsvpResults.rsvpEvents.length;
            }
            // if we have more than 1 visitor or wedding website or rsvp events then the wedding account is active
            if (weddingRef?.wedding?.path?.includes('test')) {
              status = 'test';
              testWeddings++;
            } else if ((visitorStats && visitorStats.total > 1) || mainWebsite?.id || rsvpEventsTotal > 1) {
              status = 'active';
              activeWeddings++;
            } else {
              status = 'inactive';
              inactiveWeddings++;
            }
            // if we have ceremony date that it is in the past then the wedding is ended, otherwise it is ongoing
            if (
              weddingRef?.wedding?.mainEvent?.endTimestamp &&
              new Date(weddingRef?.wedding?.mainEvent?.endTimestamp) < new Date()
            ) {
              dateStatus = 'ended';
              endedWeddings++;
            } else {
              dateStatus = 'ongoing';
              ongoingWeddings++;
            }
          }
          nodesArray.push({
            id: weddingRef?.wedding?.id || `${countWeddingNode}`,
            type: 'weddingNode',
            data: {
              wedding: weddingRef?.wedding,
              visitorStats: visitorStats,
              weddingWebsite: mainWebsite,
              rsvpEventsTotal: rsvpEventsTotal,
              status: status,
              dateStatus: dateStatus,
              hosts: weddingRef?.hosts,
            },
            position: position,
          });
          if (!weddingRef.hosts) continue;
          let countHostInternal = 1;
          for (const host of weddingRef.hosts) {
            nodesArray.push({
              id: host?.connectedAccountId || host?.connectedAccountPhoneId || `${countHostNode}`,
              type: 'hostNode',
              data: host,
              position: position,
            });
            edgesArray.push({
              id: `edgeHostConAcc${countHostNode}`,
              source: weddingRef?.wedding?.id || `${countWeddingNode}`,
              target: host?.connectedAccountId || host?.connectedAccountPhoneId || `${countHostNode}`,
              type: 'default',
              label: `host (${countHostInternal}/${weddingRef.hosts.length})`,
            });
            countHostInternal++;

            // create accounts and links
            const accountNds: Node[] = [];
            if (host && host.accountId) {
              accountNds.push({
                id: host.accountId,
                type: 'accountNode',
                data: {
                  id: host.accountId,
                  fullName: `${host?.accountFirstName} ${host?.accountLastName}`,
                  imageProfileUrl: host?.imageProfileUrl,
                  email: host?.email,
                },
                position: position,
              });
            }
            if (host && host.accountPhoneId) {
              accountNds.push({
                id: host.accountPhoneId,
                type: 'accountNode',
                data: {
                  id: host.accountPhoneId,
                  fullName: `${host?.accountFirstName} ${host?.accountLastName}`,
                  imageProfileUrl: host?.imageProfileUrl,
                  phoneNumber: host?.phoneNumber,
                },
                position: position,
              });
            }
            nodesAccountArray.push(...accountNds);

            let countAccountInternal = 1;
            for (const accountNode of accountNds) {
              edgesAccountArray.push({
                id: `edgeAccount${countAccountNode}`,
                source: host?.connectedAccountId || host?.connectedAccountPhoneId || `${countHostNode}`,
                target: accountNode.id,
                type: 'default',
                label: `auth account (${countAccountInternal}/${accountNds.length})`,
              });
              countAccountInternal++;
              countAccountNode++;
            }
            countHostNode++;
          }
          countWeddingNode++;
          // TODO: remove it
          // if (countWeddingNode === 1) break;
        }
        nodesArray.unshift({
          id: 'activeWeddings',
          type: 'statusNode',
          data: `#active: ${activeWeddings}`,
          position: position,
        });
        nodesArray.unshift({
          id: 'inactiveWeddings',
          type: 'statusNode',
          data: `#inactive: ${inactiveWeddings}`,
          position: position,
        });
        nodesArray.unshift({
          id: 'testWeddings',
          type: 'statusNode',
          data: `#test: ${testWeddings}`,
          position: position,
        });
        nodesArray.unshift({
          id: 'endedWeddings',
          type: 'statusNode',
          data: `#ended: ${endedWeddings}`,
          position: position,
        });
        nodesArray.unshift({
          id: 'ongoingWeddings',
          type: 'statusNode',
          data: `#ongoing: ${ongoingWeddings}`,
          position: position,
        });
        setNodes(nodesArray.concat(...nodesAccountArray));
        setEdges(edgesArray.concat(...edgesAccountArray));

        // build data grid datasets
        const rowsLocal = [];
        // skip the first 6 nodes as they are status nodes
        const weddingNodes = nodesArray.slice(6);
        let rowCount = 1;
        for (const weddingNode of weddingNodes) {
          const data: {
            wedding: Wedding;
            visitorStats: TotalAllowedVisitorsResponse | undefined;
            weddingWebsite: WeddingWebsite | undefined;
            rsvpEventsTotal: number;
            status: string;
            dateStatus: string;
            hosts: ConnectedAccountRef[];
          } = weddingNode.data;

          if (!data || !data?.wedding || !data?.wedding?.id) continue;

          rowsLocal.push({
            id: rowCount++,
            weddingID: data?.wedding?.id,
            emailHost: data?.hosts.map((host) => host?.email).join(', '),
            name: data?.wedding?.name,
            path: data?.wedding?.path,
            dateCreated: data?.wedding?.dateCreated,
            status: data?.status,
            dateStatus: data?.dateStatus,
            visitorStats: data?.visitorStats?.total.toString(),
            websiteVisibilityStatus: data?.weddingWebsite?.visibilityStatus === 1 ? 'public' : 'private',
            rsvpEventsTotal: data?.rsvpEventsTotal,
            socialEventType: data?.wedding?.socialEventType,
            mainEvent: data?.wedding?.mainEvent?.name,
            mainEventStartTimestamp: data?.wedding?.mainEvent?.startTimestamp,
            mainEventLocation: data?.wedding?.mainEvent?.location?.name,
            coupon: data?.wedding?.coupon,
            package: data?.wedding?.package,
          });
        }
        setRows(rowsLocal);

        const columns: GridColDef[] = [
          { field: 'weddingID', headerName: 'Wedding ID', width: 150 },
          { field: 'name', headerName: 'Wedding Name', width: 150 },
          { field: 'emailHost', headerName: 'Host Email', width: 150 },
          { field: 'path', headerName: 'Wedding Path', width: 150 },
          { field: 'dateCreated', headerName: 'Date Created', width: 150 },
          { field: 'status', headerName: 'Status', width: 150 },
          { field: 'dateStatus', headerName: 'Date Status', width: 150 },
          { field: 'visitorStats', headerName: 'Visitor Stats', width: 150 },
          { field: 'websiteVisibilityStatus', headerName: 'Website Visibility Status', width: 150 },
          { field: 'rsvpEventsTotal', headerName: 'RSVP Events Total', width: 150 },
          { field: 'socialEventType', headerName: 'Social Event Type', width: 150 },
          { field: 'mainEvent', headerName: 'Main Event', width: 150 },
          { field: 'mainEventStartTimestamp', headerName: 'Main Event Start Timestamp', width: 150 },
          { field: 'mainEventLocation', headerName: 'Main Event Location', width: 150 },
          { field: 'coupon', headerName: 'Coupon', width: 150 },
          { field: 'package', headerName: 'Package', width: 150 },
        ];
        setColumns(columns);
      })
      .catch((e) => {
        logger.error({ message: e, functionName: 'DashboardAdmin.useEffect' });
      });
  }, [profile]);

  // useEffect(() => {
  //   console.log('DashboardAdmin', { nodes, edges });
  // }, [nodes, edges]);

  useEffect(() => {
    console.log('DashboardAdmin', { rows, columns });
  }, [rows, columns]);

  return (
    <SplitPane
      split="vertical"
      defaultSize={'35vw'}
      allowResize={true}
      resizerStyle={{
        background: 'rgb(251 204 178)',
        opacity: '0.7',
        zIndex: '1',
        boxSizing: 'border-box',
        backgroundClip: 'padding-box',
        width: '7px',
        // margin: '0 -5px',
        borderLeft: '3px solid rgb(251 204 178)',
        borderRight: '3px solid rgb(251 204 178)',
        cursor: 'col-resize',
      }}
    >
      <div style={{ color: 'white', height: '100%', width: '100%' }}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <div style={{ color: 'white', height: '100%', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cellContent': {
                  color: 'white',
                },
                '& .MuiDataGrid-columnHeaderTitle': { color: 'white' },
                '& .MuiTypography-root': { color: 'white' },
              }}
            />
          </div>
        </ThemeProvider>
      </div>
      <div style={{ height: '100%' }}>
        {/*<ReactFlow nodes={initialNodes} edges={initialEdges} />*/}
        {nodes && nodes.length > 0 && edges && edges?.length > 0 ? (
          <FlowWithTheme flowProps={{ nodes: nodes, edges: edges }} />
        ) : (
          <h1 style={{ color: 'white' }}>Loading...</h1>
        )}
      </div>
    </SplitPane>
  );
};

export default DashboardAdmin;
