import { logger } from '../../../config/Logger';
import { Icon } from '../../../shared';
import { SVGIconName } from '../../../shared/Icon/Icons';

const FlatButton = (props: {
  id?: string;
  title: string;
  icon: SVGIconName;
  iconColor: string;
  _callback?: any;
  extraClass?: string;
  disabled?: boolean;
  rotate?: string;
}): JSX.Element => {
  return (
    <div className={props.extraClass ? props.extraClass : ''}>
      <button
        id={props.id}
        className={'btnFlat'}
        disabled={props.disabled}
        onClick={
          !props.disabled
            ? () => props._callback()
            : () => {
                logger.info({ message: 'disabled toolbox button' });
              }
        }
      >
        <Icon
          id={props.id && props.id + 'Icon'}
          name={props.icon}
          color={props.iconColor}
          size={'s'}
          rotate={props.rotate && props.rotate}
        />
        <span id={props.id && props.id + 'Span'}>{props.title}</span>
      </button>
    </div>
  );
};

export default FlatButton;
