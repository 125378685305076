import styles from '../guests/Guests.module.scss';
import React from 'react';

const FilledNoIconButton = (props: { title: string; _callback: any; _className?: any }) => (
  <button
    className={' btnSecondary ' + styles.filledButton + ' ' + (props._className ? props._className : '')}
    style={{ marginLeft: 20, marginRight: 20 }}
    onClick={() => props._callback(true)}
  >
    <h4 className={'didacticH4'}>{props.title}</h4>
  </button>
);

export default FilledNoIconButton;
