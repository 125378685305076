import styles from './RecessesOfTheSoulGiftComponent.module.scss';
import templateStyles from '../RecessesOfTheSoul.module.scss';
import React, { Fragment, useRef, useState } from 'react';
import Icon from '../../../shared/Icon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { toast, ToastContainer } from 'react-toastify';

interface RecessesOfTheSoulGiftComponentProps {
  RecessesOfTheSoulGiftComponentCustomGraphics?: string;
  RecessesOfTheSoulGiftComponentCustomVisible: boolean;
  RecessesOfTheSoulGiftComponentCustomGiftDetails: Array<{
    bankName: string;
    iban: string;
    ownerName: string;
    link: string;
    type: string;
  }>;
}

const defaultValues = {
  RecessesOfTheSoulGiftComponentCustomGraphics: '/templates/RecessesOfTheSoul/giftComponentGraphic.svg',
  RecessesOfTheSoulGiftComponentCustomVisible: true,
  RecessesOfTheSoulGiftComponentCustomGiftDetails: [],
};

const RecessesOfTheSoulGiftComponent = ({
  RecessesOfTheSoulGiftComponentCustomGraphics,
  RecessesOfTheSoulGiftComponentCustomVisible,
  RecessesOfTheSoulGiftComponentCustomGiftDetails,
}: RecessesOfTheSoulGiftComponentProps): JSX.Element => {
  const [hoverGift, setHoverGift] = useState<boolean>(false);
  const [showGiftMethods, setShowGiftMethods] = useState<boolean>(false);
  const giftsRef = useRef(null);

  useOutsideClick(giftsRef, () => setShowGiftMethods(false));

  if (RecessesOfTheSoulGiftComponentCustomVisible)
    return (
      <section id={`RecessesOfTheSoulGiftComponent`} className={styles.RecessesOfTheSoulGiftComponent}>
        <div className={styles.Inside}>
          <img
            src={
              RecessesOfTheSoulGiftComponentCustomGraphics
                ? RecessesOfTheSoulGiftComponentCustomGraphics
                : defaultValues.RecessesOfTheSoulGiftComponentCustomGraphics
            }
            alt="gift"
          />
          <div className={styles.InsideInfo}>
            <h2>Send A Gift</h2>
            <p className={templateStyles.didacticP}>
              Click the button to send us a gift by using the bank options and accounts listed. Thank you so much for
              this kind gesture and we’re looking forward to seeing you at our wedding.
            </p>
            <div className={templateStyles.flex} style={{ position: 'relative' }}>
              <button
                className={'btnSimple btnBordered'}
                style={{
                  backgroundColor: hoverGift || showGiftMethods ? '#000' : 'transparent',
                  borderColor: '#000',
                  borderRadius: 0,
                }}
                onMouseEnter={() => setHoverGift(true)}
                onMouseLeave={() => setHoverGift(false)}
                onClick={() => setShowGiftMethods((prevState) => !prevState)}
              >
                <Icon name={'gift'} size={'sPlus'} color={hoverGift || showGiftMethods ? '#FFFFFF' : '#000'} />
              </button>
              {showGiftMethods && (
                <div className={styles.giftModal} ref={giftsRef}>
                  <div className={templateStyles.flex}>
                    <h3 className={templateStyles.didacticH3}>Send a gift</h3>
                    <button
                      className={'btnXSmall btnBorderless ' + styles.center}
                      onClick={() => setShowGiftMethods(false)}
                    >
                      <Icon name={'cancel'} color={'#fff'} size={'m'} />
                    </button>
                  </div>
                  {RecessesOfTheSoulGiftComponentCustomGiftDetails?.map((detail, index) => {
                    return <GiftDetails key={'giftDetails' + index} detail={detail} />;
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <ToastContainer
          closeButton={false}
          style={{ width: 'fit-content', borderRadius: 8 }}
          icon={<Icon name={'accept'} color={'#006150'} size={'s'} />}
          position="bottom-center"
          autoClose={5000}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="colored"
        /> */}
      </section>
    );
  else return <Fragment />;
};
export default RecessesOfTheSoulGiftComponent;

const GiftDetails = (props: {
  detail: {
    bankName: string;
    iban: string;
    ownerName: string;
    link: string;
    type: string;
  };
}) => {
  const [hoverCopy, setHoverCopy] = useState<boolean>(false);

  const giftDetails: any = {
    bank: { name: 'Bank', color: '#63435B' },
    paypal: { name: 'Paypal', color: '#0079C1' },
    revolut: { name: 'Revolut', color: '#000000' },
  };
  return (
    <div className={styles.giftModalDetails}>
      <Icon name={giftDetails[props.detail.type]?.name?.toLowerCase()} color={'#fdf6eb'} size={'sPlus'} />
      <div>
        <p className={templateStyles.didacticP}>
          <span className={styles.highlighted}>{props.detail.bankName}</span>
          {props.detail.ownerName}
        </p>
        <p>{props.detail.iban ? props.detail.iban : props.detail.link}</p>
      </div>
      <CopyToClipboard
        text={props.detail.iban ? props.detail.iban : props.detail.link}
        onCopy={() => {
          toast(`Copied on clipboard successfully!`, {
            style: { backgroundColor: '#A3F5E6', color: '#006150 !important' },
          });
        }}
      >
        <button
          className={'btnXSmall btnBordered'}
          style={hoverCopy ? { backgroundColor: '#fdf6eb', borderColor: '#63435b' } : { borderColor: '#FDF6EB' }}
          onMouseEnter={() => setHoverCopy(true)}
          onMouseLeave={() => setHoverCopy(false)}
        >
          <Icon name={'copy'} size={'s'} color={hoverCopy ? '#63435b' : '#FDF6EB'} />
        </button>
      </CopyToClipboard>
    </div>
  );
};
