import { Comment, ConnectedAccountRef, Wedding } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import dayjs from 'dayjs';
import { CommentComponent } from '../index';
import { useSelector } from 'react-redux';
import { selectComments } from '../../../app/redux/wedding.slice.recuder';

type CommentListProps = {
  setShowEditCommentModal: any;
  setEditCommentCallback: any;
  setCreateCommentCallback: any;
  setUpdateCommentCallback: any;
  setDeleteCommentCallback: any;
  setShowEditDeleteCommentModal: any;
  setReply: any;
  account: ConnectedAccountRef;
  accounts: ConnectedAccountRef[];
  connectedAccounts: ConnectedAccountRef[];
  wedding: Wedding;
  jwt: any;
  userToken: string;
  profile: ConnectedAccountRef;
  commentsForModal: any;
  post: any;
};
const CommentsList = (props: CommentListProps): JSX.Element => {
  const comments = useSelector((state: any) => {
    const allComments = selectComments(state);
    const filtedComments = allComments?.filter((comment: Comment) => comment?.postId === props.post);
    // sort them by created timestamp desc

    const sortedComments = filtedComments.sort((a: Comment, b: Comment) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      const result = dayjs(b.dateCreated).diff(dayjs(a.dateCreated));
      return result;
    });

    const doubleSortedComments = [];
    for (let i = 0; i < sortedComments.length; i++) {
      if (i < 2) {
        doubleSortedComments.push(sortedComments[i]);
      } else {
        continue;
      }
    }

    return sortedComments;
  });

  const comment = (
    t: any,
    accounts: ConnectedAccountRef[],
    connectedAccounts: ConnectedAccountRef[],
    weddingId: string,
    userToken: string,
    profile: ConnectedAccountRef,
  ) => {
    let dateToShow = '';

    const d = dayjs(t.dateUpdated);
    const now = dayjs();

    const diffHours: number = now.diff(d, 'hour');

    if (diffHours < 24) {
      dateToShow = dayjs(d).format('HH:mm');
    } else {
      dateToShow = dayjs(d).format('DD MMMM YYYY');
    }

    return (
      <CommentComponent
        account={props.account}
        accounts={accounts}
        connectedAccounts={connectedAccounts}
        weddingId={weddingId}
        comment={t}
        userToken={props.jwt}
        date={dateToShow}
        profile={profile}
        setShowEditCommentModal={(e: any) => props.setShowEditCommentModal(e)}
        setEditCommentCallback={(e: any) => props.setEditCommentCallback(e)}
        setCreateCommentCallback={props.setCreateCommentCallback}
        setUpdateCommentCallback={(e: any) => props.setUpdateCommentCallback(e)}
        setDeleteCommentCallback={(e: any) => props.setDeleteCommentCallback(e)}
        setUpdateComment={(e: any) => props.setReply(e)}
        showModal={(e: any) => {
          props.setShowEditDeleteCommentModal(e);
        }}
      />
    );
  };

  return (
    <div style={{ overflowY: 'scroll', overflowX: 'hidden', scrollbarColor: '#FBCCB1', height: '100%' }}>
      {comments && comments.length > 0 ? (
        Array.isArray(comments) &&
        comments.map((c: any, index: any) => {
          return (
            <div key={index}>
              {comment(c, props.accounts, props.connectedAccounts, props.wedding?.id, props.userToken, props.profile)}
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <img src="/assets/graphics/empty-comments.svg" alt={'No comments yet.'} />
          <p>No comments yet.</p>
          <p>It`s time to add some!</p>
        </div>
      )}
    </div>
  );
};

export default CommentsList;
