import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Fragment } from 'react';
import { RSVPEvent } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Icon } from '../../../shared';
import templateStyles from '../CallMeByYourNameTemplate.module.scss';
import styles from './CallMeByYourNameScheduleComponent.module.scss';
interface ScheduleComponentProps {
  title?: string;
  schedule?: Array<RSVPEvent>;
  mainEvent?: string;
  description?: string;
  graphics?: string;
}

const defaultValues = {
  title: 'Schedule',
  mainEvent: '',
  description:
    'This part will be filled when your planning \n' +
    'is completed. Every public event you create will \n' +
    'appear here.',
  graphics: '/templates/CallMeByYourName/scheduleComponentGraphics.svg',
};

const CallMeByYourNameScheduleComponent = ({
  title,
  schedule,
  description,
  graphics,
  mainEvent = '',
}: ScheduleComponentProps): JSX.Element => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const eventsGrouped = new Map<string, Array<RSVPEvent>>();
  // let mainEvent = new RSVPEventsC
  schedule?.map((eventRSVP) => {
    const tempArray = eventsGrouped.get(dayjs(eventRSVP.startTimestamp).format('dddd, DD MMMM YYYY'));
    if (tempArray !== undefined) {
      eventsGrouped.set(
        dayjs(eventRSVP.startTimestamp)
          .tz(eventRSVP.location?.externalQuickCode ? eventRSVP.location?.externalQuickCode : dayjs.tz.guess())
          .format('dddd, DD MMMM YYYY'),
        [...tempArray, eventRSVP].sort(
          (a, b) => Number(dayjs(a.startTimestamp).format('HHmm')) - Number(dayjs(b.startTimestamp).format('HHmm')),
        ),
      );
    } else {
      eventsGrouped.set(
        dayjs(eventRSVP.startTimestamp)
          .tz(eventRSVP.location?.externalQuickCode ? eventRSVP.location?.externalQuickCode : dayjs.tz.guess())
          .format('dddd, DD MMMM YYYY'),
        [eventRSVP].sort(
          (a, b) => Number(dayjs(a.startTimestamp).format('HHmm')) - Number(dayjs(b.startTimestamp).format('HHmm')),
        ),
      );
    }
  });

  const renderDate = () => {
    let i = 0;
    let tempSchedule: JSX.Element[] = [];
    eventsGrouped.forEach((events, date) => {
      const tempEvents: JSX.Element[] = [];
      tempEvents.push(
        <h2 key={i + ` ` + (Math.random() % 100)} style={{ textAlign: 'right' }}>
          {date.split(',')[0]}
          <br />
          {date.split(',')[1]}
        </h2>,
      );
      events.forEach((e) => {
        tempEvents.push(renderEvent(e, date));
      });

      tempSchedule.push(
        <div
          key={i + ` ` + (Math.random() % 100)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyItems: 'space-between',
            gap: 100,
            width: '100%',
          }}
        >
          {tempEvents}
        </div>,
      );
      i++;
    });
    // console.log(tempSchedule);
    // console.log(String(tempSchedule[0].key));
    // console.log(dayjs(String(tempSchedule[0].key)).format('YYYYMMDD'));
    tempSchedule = tempSchedule.sort(
      (a: JSX.Element, b: JSX.Element) =>
        Number(dayjs(String(a.key)).format('YYYYMMDD')) - Number(dayjs(String(b.key)).format('YYYYMMDD')),
    );
    // console.log(tempSchedule);
    return tempSchedule;
  };

  const renderEvent = (e: RSVPEvent, date: string) => {
    return (
      <Fragment key={String(e.id) + String(date)}>
        <div key={String(e.id) + String(date)}>
          <div key={String(date) + ` ` + (Math.random() % 100)}>
            {dayjs(e.startTimestamp)
              .tz(e.location?.externalQuickCode ? e.location?.externalQuickCode : dayjs.tz.guess())
              .format('HH:mm')}{' '}
            -
            {dayjs(e.endTimestamp)
              .tz(e.location?.externalQuickCode ? e.location?.externalQuickCode : dayjs.tz.guess())
              .format('HH:mm')}
          </div>
          <div style={{ fontSize: '24px', marginTop: -4 }}>{e.name}</div>
          <a
            key={String(date) + ` ` + (Math.random() % 100)}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px',
              paddingTop: 4,
              textOverflow: 'ellipsis',
              width: '250px',
            }}
            target="blank"
            href={`https://maps.google.com/?q=${e?.location?.point?.latitude},${e?.location?.point?.longitude}`}
          >
            <Icon name="location" size="s" />
            <p
              style={{
                display: 'block',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {e?.location?.name ? e?.location?.name + ', ' + e?.location?.address : e?.location?.address}
            </p>
          </a>
        </div>
      </Fragment>
    );
  };

  return (
    <section className={styles.ScheduleComponentWrapper}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyItems: 'center',
        }}
      >
        <h2 className={styles.title} style={{ width: '100%' }}>
          {String(title ? title : defaultValues.title)}
          {mainEvent && new Date(mainEvent).getTime() - new Date().getTime() > 0 && (
            <span className={styles.title} style={{ paddingLeft: '50px' }}>
              {Math.ceil((new Date(mainEvent).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} days to go
            </span>
          )}
        </h2>
      </div>
      <section className={styles.ScheduleComponent}>
        {schedule ? <Fragment>{renderDate()}</Fragment> : <p>{defaultValues.description}</p>}
      </section>
      <div
        style={{ textAlign: 'center', paddingBottom: '16px', paddingTop: '16px' }}
        className={styles.schedule}
        dangerouslySetInnerHTML={{ __html: description ? description : '' }}
      />
    </section>
  );
};

export default CallMeByYourNameScheduleComponent;
