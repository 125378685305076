import './SignIn.scss';
import 'react-phone-input-2/lib/style.css';

import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import {
  selectIsLoggedIn,
  setAccessToken,
  setAccessTokenAfterOTP,
  setConnectedAccount,
  setJwt,
  setLoggedIn,
  setMarriageCode,
  setTokenExpiryDate,
  user,
} from '../../../app/redux/authorization.slice.reducer';
import { logger } from '../../../config/Logger';
import { FirebaseContext } from '../../../context/firebase.context';

import { TooManyTriesModal } from '../../modals';
import { SignInButton } from './SignInButton';
import moment from 'moment-timezone';
import timezoneData from 'moment-timezone/data/meta/latest.json';
import Lottie from 'react-lottie';
import location from '../../../loader-animation.json';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { toast, ToastContainer } from 'react-toastify';
import Icon from '../../../shared/Icon';
import { GetDefaultRolesRequest, LoginRequest, RoleDto } from '../../../pkg/protobuf/v2/auth/auth_types_pb';
import { AuthHelper } from '../../../pkg/apiHelpers/authHelper';
import { setWedding } from '../../../app/redux/wedding.slice.recuder';
import jwt_decode from 'jwt-decode';
import { lottieDefaultOptions } from '../../../shared/Options';

const SignIn = (): JSX.Element => {
  const [phone, setPhone] = useState('');
  const [phoneData, setPhoneData] = useState<CountryData | Record<string, unknown> | undefined>(undefined);
  const [otp, setOtp] = useState('');
  const [marriageCode, setCode] = useState('');
  const [terms, setTerms] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  const [phoneInputCountry, setPhoneInputCountry] = useState<string>('gr');

  const [role, setRole] = useState(new RoleDto());

  useEffect(() => {
    const timeZoneToCountry = new Map<string, string>();
    const countryName = moment.tz.guess(true);
    Object.values(timezoneData.zones).forEach((z) => {
      timeZoneToCountry.set(z.name, z.countries[0]);
    });
    const countryCode = timeZoneToCountry.get(countryName);
    setPhoneInputCountry(countryCode?.toLowerCase() || 'gr');
  }, []);

  useEffect(() => {
    if (isLoggedIn) navigate('/main');
    FirebaseContext.getAuth();
    const query = new URLSearchParams(location.search);
    setTerms(Boolean(query.get('demo')) || false);
    setPhone(query.get('phone') || '');
    setOtp(query.get('otp') || '');
    setCode(query.get('code') || '');
  }, []);

  useEffect(() => {
    async function fetchStatus() {
      const requestMessage: GetDefaultRolesRequest = new GetDefaultRolesRequest();
      const resp = await AuthHelper.publicGetDefaultRolesPromise(requestMessage);
      resp?.roles.map((value) => {
        logger.debug({ message: value.name });
        if (value.name === 'defaultVisitorRoleAll') setRole(value);
      });
    }

    if (terms && phone !== '' && otp !== '' && marriageCode !== '') {
      fetchStatus().then(() => onSignInSubmit());
    }
  }, [marriageCode, phone, otp, terms]);

  const setUpRecaptcha = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    logger.info({ message: window.recaptchaVerifier });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: async function () {
        console.log('Captcha Resolved');
        // await onSignInSubmit();
      },
      defaultCountry: 'GR',
    });
  };

  const onSignInSubmit = async () => {
    if (await checkSubmit()) {
      setLoading(true);
      try {
        let phoneNumber = phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
        if (phoneData && phone.split(' ')[0] !== `+${phoneData?.dialCode}`)
          phoneNumber = `+${phoneData?.dialCode} ${phone.slice(1, phone.length)}`;
        // if phoneNumber is +304192321234 (whiteclover demo user) and token is empty then allow login without validating the
        // firebase token as this is a demo account, and we want to avoid calling firebase api
        // otherwise validate
        if (phoneNumber === '+304192321234') {
          dispatch(setMarriageCode(marriageCode));

          const loginRequest = new LoginRequest();
          loginRequest.phoneNumber = phoneNumber;
          loginRequest.roleId = role.id;
          const resp = await AuthHelper.loginRegisterFirebaseUser(loginRequest);
          if (!resp?.jwtToken) {
            // TODO: react an nextjs error?
            logger.error({
              message: 'empty user token for DEMO account',
              functionName: 'SignIn.onSignInSubmit.loginRegisterFirebaseUser',
            });
            throw new Error('empty user token for DEMO account');
          }

          //TODO: GET ENDPOINT FOR EXISTING MARRIAGE
          // const marriageCode = useSelector(selectMarriageCode);
          // const loggedInResp = await AuthHelper.isMarriageExist(marriageCode);

          const weddingData = await BrainHelper.getWedding(
            resp.jwtToken,
            marriageCode,
            dispatch,
            null,
            null,
            setWedding,
          );
          const decoded = jwt_decode(resp.jwtToken) as user;

          const connectAccountResponse = await BrainHelper.connectAccount(
            resp.jwtToken,
            weddingData?.id,
            decoded.accountId,
            dispatch,
            null,
            null,
            null,
            setConnectedAccount,
            setJwt,
          );
          if (connectAccountResponse == null) {
            setLoading(false);
            setOtp('');
            toast(
              'The mobile number you entered is not associated with an account. Please make sure you are using the mobile number that you registered or were invited with.',
              {
                icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
                style: { backgroundColor: '#F8AAB8' },
              },
            );
            return;
          }

          // read jwt token
          // const decoded: any = jwt_decode(resp?.jwtToken);
          const expiredIn = decoded.exp;

          // store token data in redux state
          dispatch(setLoggedIn(true));
          dispatch(setAccessToken(resp?.jwtToken));
          dispatch(setAccessTokenAfterOTP(resp?.jwtToken));
          dispatch(setTokenExpiryDate(Number(expiredIn)));
          setLoading(false);
          if (resp?.needsProfileSetup) navigate('/intro');
          else navigate('/main');
        } else {
          setUpRecaptcha();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const appVerifier = window.recaptchaVerifier;
          const confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
          console.log(confirmationResult);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.recaptchaVerifier.clear();
          // SMS sent. Prompt user to type the marriageCode from the message, then sign the
          // user in with confirmationResult.confirm(marriageCode).
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.confirmationResult = confirmationResult;
          dispatch(setMarriageCode(marriageCode));
          navigate('/otp' + (otp !== '' ? '?otp=' + otp : ''));
        }
      } catch (error: any) {
        logger.error({ message: error, functionName: 'SignIn.onSignInSubmit' });
        setModalMessage('Too many tries, please try again later');
        setShowModal(true);
        setLoading(false);
      }
    }
  };

  const checkSubmit = async () => {
    let response = true;
    let el: HTMLElement | null;
    if (phone === '') {
      response = false;
      el = document.getElementById('formPhone');
      if (el !== null) el.style.borderColor = 'red';
    }
    if (marriageCode === '') {
      response = false;
      el = document.getElementById('formCode');
      if (el !== null) el.style.borderColor = 'red';
    }
    try {
      await BrainHelper.getPublicWedding('', marriageCode);
    } catch (e) {
      response = false;
      el = document.getElementById('formCode');
      if (el !== null) el.style.borderColor = 'red';
      toast("No wedding found. Check your couple's code and try again.", {
        icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
        style: { backgroundColor: '#F8AAB8' },
      });
    }
    if (!terms) {
      response = false;
      el = document.getElementById('terms');
      if (el !== null) el.style.outline = 'solid 1px red';
    }
    return response;
  };

  return (
    <div className="signIn">
      <div className="form">
        <div className="signIn-logo">
          <img src="/assets/logos/whiteclover-logo1.svg" alt="" />
        </div>
        <div id="recaptcha-container" />
        <PhoneInput
          country={phoneInputCountry}
          placeholder={'Phone Number'}
          value={phone}
          onChange={(value, data, event, formattedValue) => setPhone(formattedValue)}
          onBlur={(event, data: CountryData | Record<string, unknown>) => {
            if (data && event.target.value.split(' ')[0] !== `+${data.dialCode}`)
              setPhone(`+${data.dialCode} ${event.target.value.slice(1, event.target.value.length)}`);
            setPhoneData(data);
          }}
        />
        <input
          id="formCode"
          type="text"
          placeholder={"Couple's Code"}
          value={marriageCode}
          onInput={(e) => {
            return (e.currentTarget.value = ('' + e.currentTarget.value).toLowerCase());
          }}
          onChange={(event) => {
            event.target.style.borderColor = 'black';
            setCode(event.target.value);
          }}
        />
        <p className={'didactic'}>
          Couple Code is the couple’s unique password. You can find it in your email invitation or in their website.
        </p>
        <label>
          <input
            id="terms"
            type="checkbox"
            checked={terms}
            onChange={(event) => {
              event.target.style.outline = 'black';
              setTerms(!terms);
            }}
          />
          Agree to <a href="https://whiteclover.io/privacy-policy">Privacy Policy</a> and our{' '}
          <a href="https://whiteclover.io/terms">Terms & Conditions</a>
        </label>

        {/*<button*/}
        {/*  disabled={!terms || !(phone.length > 0) || !(marriageCode.length > 0)}*/}
        {/*  className={'btnMain signInBtn'}*/}
        {/*  onClick={(e) => onSignInSubmit()}*/}
        {/*>*/}
        {/*  Sign In{' '}*/}
        {/*</button>*/}
        {loading && <Lottie options={lottieDefaultOptions} height={40} width={240} />}
        {!loading && (
          <SignInButton
            disabled={!terms || !(phone.length > 0) || !(marriageCode.length > 0)}
            onClick={onSignInSubmit}
          />
        )}
      </div>
      <div className="footerImg">
        <img src="/assets/graphics/signin-flower.svg" alt="photos app" />
      </div>
      {showModal ? <TooManyTriesModal showModal={showModal} message={modalMessage} setShowModal={setShowModal} /> : ''}
      <ToastContainer
        closeButton={false}
        style={{ width: 'fit-content', borderRadius: 8 }}
        icon={<Icon name={'accept'} color={'#006150'} size={'s'} />}
        position="bottom-center"
        autoClose={10000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default SignIn;
