import React, { Fragment, useEffect, useState } from 'react';
import templateStyles from '../ImYours.module.scss';
import { Icon } from '../../../shared';
import { SVGIconName } from '../../../shared/Icon/Icons';
import { createTheme, Switch, ThemeProvider } from '@mui/material';
import ReactTooltip from 'react-tooltip';

type InputComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

const InputImYoursGiftComponent = ({
  // componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: // autoSave,
InputComponentProps): JSX.Element => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [bankDetails, setBankDetails] = useState<
    Array<{
      bankName: string;
      iban: string;
      ownerName: string;
      type: string;
      link: string;
    }>
  >([]);
  const [hoverBank, setHoverBank] = useState<boolean>(false);
  const [hoverPaypal, setHoverPaypal] = useState<boolean>(false);
  const [hoverRevolut, setHoverRevolut] = useState<boolean>(false);
  const [hoverAdd, setHoverAdd] = useState<boolean>(false);
  const [hoverSave, setHoverSave] = useState<boolean>(false);
  const [showGiftMethods, setShowGiftMethods] = useState<boolean>(false);
  const [newBankAccount, setNewBankAccount] = useState<
    | {
        bankName: string;
        iban: string;
        ownerName: string;
        type: string;
        link: string;
      }
    | undefined
  >(undefined);
  const [key, setKey] = useState<number>(-1);

  const giftDetails: any = {
    bank: { name: 'Bank', color: '#63435B' },
    paypal: { name: 'Paypal', color: '#0079C1' },
    revolut: { name: 'Revolut', color: '#000000' },
  };

  useEffect(() => {
    if (componentProps && componentProps[componentIndex]) {
      setToggle(componentProps[componentIndex]['ImYoursGiftComponentCustomVisible']);
      setBankDetails(componentProps[componentIndex]['ImYoursGiftComponentCustomGiftDetails'] || []);
      setShowGiftMethods(componentProps[componentIndex]['ImYoursGiftComponentCustomGiftDetails']?.length <= 0);
    }
  }, [componentProps]);

  const emptyComponentValues = {
    ImYoursGiftComponentCustomGraphics: '/templates/ImYours/giftComponentGraphic.svg',
    ImYoursGiftComponentCustomImage: '/templates/ImYours/wc-app-promo-image.png',
    ImYoursGiftComponentCustomVisible: false,
    ImYoursGiftComponentCustomGiftDetails: [],
  };

  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  }, []);

  const removeHoverEffects = () => {
    setHoverBank(false);
    setHoverPaypal(false);
    setHoverRevolut(false);
    setHoverSave(false);
  };

  const updateProps = (input: any, key: string) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      tempProps[componentIndex][key] = input;
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex][key] = input;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  if (!componentProps[componentIndex]) {
    <></>;
  }

  const updateBankDetails = (input: any, key: number, remove = false) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      const tempArray = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      if (tempArray['ImYoursGiftComponentCustomGiftDetails'] && key < 0)
        tempArray['ImYoursGiftComponentCustomGiftDetails'].push(input);
      else if (tempArray['ImYoursGiftComponentCustomGiftDetails'] && key >= 0 && remove)
        tempArray['ImYoursGiftComponentCustomGiftDetails'].splice(key, 1);
      else if (tempArray['ImYoursGiftComponentCustomGiftDetails'] && key >= 0 && !remove)
        tempArray['ImYoursGiftComponentCustomGiftDetails'][key] = input;
      else tempArray['ImYoursGiftComponentCustomGiftDetails'] = [input];
      tempProps[componentIndex] = tempArray;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  const moveUp = (index: number) => {
    if (
      componentProps &&
      componentProps[componentIndex] &&
      componentProps[componentIndex]['ImYoursGiftComponentCustomGiftDetails'] &&
      componentProps[componentIndex]['ImYoursGiftComponentCustomGiftDetails'].length > 0
    ) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const element = tempProps['ImYoursGiftComponentCustomGiftDetails'][index];
      tempProps['ImYoursGiftComponentCustomGiftDetails'].splice(index, 1);
      tempProps['ImYoursGiftComponentCustomGiftDetails'].splice(index - 1, 0, element);
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  };

  const moveDown = (index: number) => {
    if (
      componentProps &&
      componentProps[componentIndex] &&
      componentProps[componentIndex]['ImYoursGiftComponentCustomGiftDetails'] &&
      componentProps[componentIndex]['ImYoursGiftComponentCustomGiftDetails'].length > 0
    ) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const element = tempProps['ImYoursGiftComponentCustomGiftDetails'][index];
      tempProps['ImYoursGiftComponentCustomGiftDetails'].splice(index, 1);
      tempProps['ImYoursGiftComponentCustomGiftDetails'].splice(index + 1, 0, element);
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  };

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            height: 32,
            width: 52,
            padding: 0,
            border: '1px solid #DAD8D9',
            borderRadius: '50px',
          },
          switchBase: {
            // Controls default (unchecked) color for the thumb
            padding: 3,
          },
          thumb: {
            color: '#FFFFFF',
            width: 24,
            height: 24,
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: '.7 !important',
            height: 32,
            width: 52,
            backgroundColor: '#835978 !important',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              opacity: '1 !important',
            },
          },
        },
      },
    },
  });

  return (
    <section style={{ display: 'flex', flexDirection: 'column', gap: 8, paddingBottom: 40 }}>
      <div className={templateStyles.flex}>
        <h2 className={templateStyles.didacticH2}>Gift Section</h2>
        <ThemeProvider theme={theme}>
          <Switch
            checked={toggle}
            onChange={(event, checked) => {
              updateProps(checked, 'ImYoursGiftComponentCustomVisible');
              setToggle((prevState) => !prevState);
            }}
          />
        </ThemeProvider>
      </div>
      <p className={templateStyles.didacticP}>
        To receive payment gifts from your guests, enable this section and enter your bank account, Revolut, or PayPal
        account.
      </p>
      <p className={templateStyles.didacticP}>
        Choose one or more of the following payment methods and fill up your details.
      </p>

      {!newBankAccount ? (
        <div>
          <div className={templateStyles.Gifts}>
            {bankDetails.map((detail, index) => {
              return (
                <div key={'detail' + index} className={templateStyles.GiftsDetail}>
                  <div className={templateStyles.flex} style={{ justifyContent: 'unset', gap: 16 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 32,
                        height: 32,
                        background: giftDetails[detail.type].color,
                        borderRadius: 50,
                        padding: 4,
                      }}
                    >
                      <Icon name={detail.type as SVGIconName} size={'s'} color={'#FFFFFF'} />
                    </div>
                    <h4 className={templateStyles.didacticH4}>{giftDetails[detail.type].name} Account</h4>
                  </div>
                  <div className={templateStyles.flex}>
                    <div>
                      <p className={templateStyles.didacticP} style={{ color: '#63435B' }}>
                        <span style={{ color: '#F78C50' }}>{detail.type === 'bank' ? detail.bankName : ''}</span>
                        {' ' + detail.ownerName}
                      </p>
                      <p className={templateStyles.didacticP} style={{ color: '#63435B' }}>
                        {detail.type === 'bank' ? detail.iban : detail.link}
                      </p>
                    </div>
                    <div>
                      {index > 0 && (
                        <button
                          className={'btnXSmall btnBordered '}
                          style={{ marginRight: 16 }}
                          onClick={() => {
                            moveUp(index);
                          }}
                        >
                          <Icon name={'arrow_up'} size={'s'} color={'#63435B'} />
                        </button>
                      )}
                      {index < bankDetails.length - 1 && (
                        <button
                          className={'btnXSmall btnBordered '}
                          style={{ marginRight: 16 }}
                          onClick={() => {
                            moveDown(index);
                          }}
                        >
                          <Icon name={'arrow_down'} size={'s'} color={'#63435B'} />
                        </button>
                      )}
                      <button
                        className={'btnXSmall btnBordered'}
                        style={{ marginRight: 16 }}
                        onClick={() => {
                          setKey(index);
                          setNewBankAccount(detail);
                        }}
                      >
                        <Icon name={'edit'} size={'s'} color={'#63435B'} />
                      </button>
                      <button
                        className={'btnXSmall btnBordered '}
                        onClick={() => {
                          updateBankDetails(undefined, index, true);
                        }}
                      >
                        <Icon name={'trash'} size={'s'} color={'#63435B'} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {bankDetails?.length > 0 && !showGiftMethods ? (
            <button
              className={'btnMain btnMainWithImg btnMedium'}
              onMouseEnter={() => setHoverAdd(true)}
              onMouseLeave={() => setHoverAdd(false)}
              onClick={() => setShowGiftMethods(true)}
            >
              <Icon name={'plus'} size={'s'} color={hoverAdd ? '#FFFFFF' : '#63435B'} />
              Add Another Payment Method
            </button>
          ) : (
            <div className={templateStyles.flex} style={{ gap: 24, justifyContent: 'unset' }}>
              <button
                className={'btnSm btnBordered btnBorderedRound'}
                style={{ background: hoverBank ? '#63435B' : '#FFFFFF' }}
                onMouseEnter={() => setHoverBank(true)}
                onMouseLeave={() => setHoverBank(false)}
                onClick={() => {
                  setNewBankAccount({ bankName: '', iban: '', ownerName: '', link: '', type: 'bank' });
                  setKey(-1);
                }}
              >
                <Icon name={'bank'} size={'s'} color={hoverBank ? '#FFFFFF' : '#281B24'} />
              </button>
              <button
                className={'btnSm btnBordered btnBorderedRound'}
                style={{ background: hoverPaypal ? '#0079C1' : '#FFFFFF' }}
                onMouseEnter={() => setHoverPaypal(true)}
                onMouseLeave={() => setHoverPaypal(false)}
                onClick={() => {
                  setNewBankAccount({ bankName: 'Paypal', iban: '', ownerName: '', link: '', type: 'paypal' });
                  setKey(-1);
                }}
              >
                <Icon name={'paypal'} size={'s'} color={hoverPaypal ? '#FFFFFF' : '#281B24'} />
              </button>
              <button
                className={'btnSm btnBordered btnBorderedRound'}
                style={{ background: hoverRevolut ? '#000000' : '#FFFFFF' }}
                onMouseEnter={() => setHoverRevolut(true)}
                onMouseLeave={() => setHoverRevolut(false)}
                onClick={() => {
                  setNewBankAccount({ bankName: 'Revolut', iban: '', ownerName: '', link: '', type: 'revolut' });
                  setKey(-1);
                }}
              >
                <Icon name={'revolut'} size={'s'} color={hoverRevolut ? '#FFFFFF' : '#281B24'} />
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className={templateStyles.flex} style={{ justifyContent: 'unset', gap: 16 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 32,
                height: 32,
                background: giftDetails[newBankAccount.type].color,
                borderRadius: 50,
                padding: 4,
              }}
            >
              <Icon name={newBankAccount.type as SVGIconName} size={'s'} color={'#FFFFFF'} />
            </div>
            <h4 className={templateStyles.didacticH4}>{giftDetails[newBankAccount.type].name} Account</h4>
          </div>
          {newBankAccount.type === 'bank' ? (
            <Fragment>
              <h3 className={templateStyles.didacticH4} style={{ paddingBottom: '8px' }}>
                Bank Name*
              </h3>
              <input
                style={{ marginBottom: '24px', width: '100%' }}
                className={templateStyles.input}
                placeholder={'ex Eurobank...'}
                value={newBankAccount.bankName}
                onChange={(e) => {
                  const temp = JSON.parse(JSON.stringify(newBankAccount));
                  temp.bankName = e.target.value;
                  setNewBankAccount(temp);
                }}
              />
              <h3 className={templateStyles.didacticH4} style={{ paddingBottom: '8px' }}>
                IBAN*
              </h3>
              <input
                style={{ marginBottom: '24px', width: '100%' }}
                className={templateStyles.input}
                placeholder={'ex GR000...'}
                value={newBankAccount.iban}
                onChange={(e) => {
                  const temp = JSON.parse(JSON.stringify(newBankAccount));
                  temp.iban = e.target.value.trim();
                  setNewBankAccount(temp);
                }}
              />
              <h3 className={templateStyles.didacticH4} style={{ paddingBottom: '8px' }}>
                Full Name*
              </h3>
              <input
                style={{ marginBottom: '24px', width: '100%' }}
                className={templateStyles.input}
                placeholder={'ex Jane Doe...'}
                value={newBankAccount.ownerName}
                onChange={(e) => {
                  const temp = JSON.parse(JSON.stringify(newBankAccount));
                  temp.ownerName = e.target.value;
                  setNewBankAccount(temp);
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <h3 className={templateStyles.didacticH4} style={{ paddingBottom: '8px' }}>
                Full Name*
              </h3>
              <input
                style={{ marginBottom: '24px', width: '100%' }}
                className={templateStyles.input}
                placeholder={'ex Jane Doe...'}
                value={newBankAccount.ownerName}
                onChange={(e) => {
                  const temp = JSON.parse(JSON.stringify(newBankAccount));
                  temp.ownerName = e.target.value;
                  setNewBankAccount(temp);
                }}
              />
              <h3 className={templateStyles.didacticH4} style={{ paddingBottom: '8px' }}>
                {giftDetails[newBankAccount.type].name} Link*
              </h3>
              <input
                style={{ marginBottom: '24px', width: '100%' }}
                className={templateStyles.input}
                placeholder={'ex Jane Doe...'}
                value={newBankAccount.link}
                onChange={(e) => {
                  const temp = JSON.parse(JSON.stringify(newBankAccount));
                  temp.link = e.target.value.trim();
                  setNewBankAccount(temp);
                }}
              />
            </Fragment>
          )}
          <div className={templateStyles.flex} style={{ justifyContent: 'unset', gap: 8 }}>
            <button
              data-tip="Please fill all your info to enable saving."
              className={
                templateStyles.btn +
                ' ' +
                templateStyles.btnMain +
                ' ' +
                templateStyles.btnMainWithImg +
                (!(newBankAccount.bankName !== '' && newBankAccount.iban !== '' && newBankAccount.ownerName !== '') &&
                !(newBankAccount.link !== '' && newBankAccount.ownerName !== '')
                  ? ' ' + templateStyles.btnMainDisabled
                  : '')
              }
              style={{ maxWidth: 131, padding: 12, gap: 8 }}
              onMouseEnter={() => setHoverSave(true)}
              onMouseLeave={() => setHoverSave(false)}
              onClick={() => {
                updateBankDetails(newBankAccount, key);
                removeHoverEffects();
                setNewBankAccount(undefined);
                setKey(-1);
              }}
            >
              <Icon name={'save'} size={'s'} color={hoverSave ? '#FFFFFF' : '#63435B'} />
              Save
            </button>
            {!(newBankAccount.bankName !== '' && newBankAccount.iban !== '' && newBankAccount.ownerName !== '') &&
              !(newBankAccount.link !== '' && newBankAccount.ownerName !== '') && (
                <ReactTooltip
                  effect={'solid'}
                  place={'top'}
                  multiline={true}
                  border={true}
                  textColor={'#63435B'}
                  backgroundColor={'#FDF6EB'}
                  borderColor={'#63435B'}
                />
              )}
            <button
              className={templateStyles.btn + ' ' + templateStyles.btnSecondary}
              style={{ maxWidth: 131, padding: 12 }}
              onClick={() => {
                removeHoverEffects();
                setNewBankAccount(undefined);
                setKey(-1);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default InputImYoursGiftComponent;
