import { Action, createStore, ThunkAction } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';

import { AuthorizationState } from './redux/authorization.slice.reducer';
import rootReducers from './redux/RootReducers';
import { weddingState } from './redux/wedding.slice.recuder';
import { WebsiteBuilderState } from './redux/websiteBuilder.slice.reducer';

// convert object to string and store in localStorage
function saveToLocalStorage(state: any) {
  try {
    const serialisedState = JSON.stringify(state, (_, v) => (typeof v === 'bigint' ? v.toString() : v));
    localStorage.setItem('persistantState', serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

// load string from localStorage and convert into an Object
// invalid output must be undefined
function loadFromLocalStorage():
  | { authorization: AuthorizationState; wedding: weddingState; websiteBuilder: WebsiteBuilderState }
  | undefined {
  try {
    const serialisedState = localStorage.getItem('persistantState');
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

export const store = createStore(
  rootReducers,
  loadFromLocalStorage(),
  composeWithDevTools(),

  // other store enhancers if any
);

// listen for store changes and use saveToLocalStorage to
// save them to localStorage
store.subscribe(() => saveToLocalStorage(store.getState()));

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
