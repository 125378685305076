import { SVGIconName } from '../../../shared/Icon/Icons';
import React, { useState } from 'react';
import styles from '../guests/Guests.module.scss';
import Icon from '../../../shared/Icon';

const FilledButton = (props: {
  id: string;
  src?: string;
  icon?: SVGIconName;
  title: string;
  _callback: any;
  _className?: any;
  style?: any;
  disabled?: boolean;
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <button
      style={props.style}
      id={props.id}
      className={' btnSecondary ' + styles.filledButton + ' ' + (props._className ? props._className : '')}
      disabled={props.disabled}
      onClick={() => props._callback(true)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {props?.src && <img src={props.src} alt={props.title} width={26} />}
      {props?.icon && <Icon name={props.icon} size={'sPlus'} color={isHover ? '#ffffff' : '#63435b'} />}
      <h4 className={'didacticH4'}>{props.title}</h4>
    </button>
  );
};

export default FilledButton;
