import React from 'react';
import 'react-quill/dist/quill.snow.css';

// SEPIA TEMPLATE
import {
  InputSepiaOurStoryComponent,
  InputSepiaMainComponent,
  InputSepiaQNAComponent,
  InputSepiaAppPromoteComponent,
  InputSepiaScheduleComponent,
  InputSepiaGiftComponent,
  InputSepiaThanksComponent,
  InputSepiaContactInfoComponent,
  SepiaMainComponent,
  SepiaOurStoryComponent,
  SepiaThanksComponent,
  SepiaScheduleComponent,
  SepiaAppPromoteComponent,
  SepiaQNAComponent,
  SepiaGiftComponent,
  SepiaContactInfoComponent,
} from './sepia';

// CallMeByYourName TEMPLATE
import {
  InputCallMeByYourNameOurStoryComponent,
  InputCallMeByYourNameMainComponent,
  InputCallMeByYourNameQNAComponent,
  InputCallMeByYourNameAppPromoteComponent,
  InputCallMeByYourNameScheduleComponent,
  InputCallMeByYourNameGiftComponent,
  InputCallMeByYourNameThanksComponent,
  InputCallMeByYourNameContactInfoComponent,
  CallMeByYourNameMainComponent,
  CallMeByYourNameScheduleComponent,
  CallMeByYourNameQNAComponent,
  CallMeByYourNameThanksComponent,
  CallMeByYourNameAppPromoteComponent,
  CallMeByYourNameGiftComponent,
  CallMeByYourNameOurStoryComponent,
  CallMeByYourNameContactInfoComponent,
} from './CallMeByYourName';

// RecessesOfTheSoul TEMPLATE
import {
  InputRecessesOfTheSoulOurStoryComponent,
  InputRecessesOfTheSoulMainComponent,
  InputRecessesOfTheSoulQNAComponent,
  InputRecessesOfTheSoulAppPromoteComponent,
  InputRecessesOfTheSoulScheduleComponent,
  InputRecessesOfTheSoulGiftComponent,
  InputRecessesOfTheSoulThanksComponent,
  InputRecessesOfTheSoulContactInfoComponent,
  RecessesOfTheSoulMainComponent,
  RecessesOfTheSoulScheduleComponent,
  RecessesOfTheSoulQNAComponent,
  RecessesOfTheSoulThanksComponent,
  RecessesOfTheSoulAppPromoteComponent,
  RecessesOfTheSoulGiftComponent,
  RecessesOfTheSoulOurStoryComponent,
  RecessesOfTheSoulContactInfoComponent,
} from './RecessesOfTheSoul';

// Baptism2 TEMPLATE
import {
  InputBaptism2OurStoryComponent,
  InputBaptism2PaletteComponent,
  InputBaptism2MainComponent,
  InputBaptism2QNAComponent,
  InputBaptism2AppPromoteComponent,
  InputBaptism2ScheduleComponent,
  InputBaptism2GiftComponent,
  InputBaptism2ThanksComponent,
  InputBaptism2ContactInfoComponent,
  Baptism2MainComponent,
  Baptism2ScheduleComponent,
  Baptism2QNAComponent,
  Baptism2ThanksComponent,
  Baptism2AppPromoteComponent,
  Baptism2GiftComponent,
  Baptism2OurStoryComponent,
  Baptism2PaletteComponent,
  Baptism2ContactInfoComponent,
} from './baptism2';

// TheArtist TEMPLATE
import {
  InputTheArtistOurStoryComponent,
  InputTheArtistMainComponent,
  InputTheArtistQNAComponent,
  InputTheArtistAppPromoteComponent,
  InputTheArtistScheduleComponent,
  InputTheArtistGiftComponent,
  InputTheArtistThanksComponent,
  InputTheArtistContactInfoComponent,
  TheArtistMainComponent,
  TheArtistScheduleComponent,
  TheArtistQNAComponent,
  TheArtistThanksComponent,
  TheArtistAppPromoteComponent,
  TheArtistGiftComponent,
  TheArtistOurStoryComponent,
  TheArtistContactInfoComponent,
} from './TheArtist';

// ImYours TEMPLATE
import {
  InputImYoursOurStoryComponent,
  InputImYoursMainComponent,
  InputImYoursQNAComponent,
  InputImYoursAppPromoteComponent,
  InputImYoursScheduleComponent,
  InputImYoursGiftComponent,
  InputImYoursThanksComponent,
  InputImYoursContactInfoComponent,
  ImYoursMainComponent,
  ImYoursScheduleComponent,
  ImYoursQNAComponent,
  ImYoursThanksComponent,
  ImYoursAppPromoteComponent,
  ImYoursGiftComponent,
  ImYoursOurStoryComponent,
  ImYoursContactInfoComponent,
} from './ImYours';

export const GetAppropriateInput = (
  componentName: string,
  componentIndex: number,
  componentProps: Array<any>,
  updateComponentProps: (a: any) => void,
  autoSave: () => void,
): JSX.Element | undefined => {
  switch (componentName) {
    //ImYours TEMPLATE
    case 'ImYoursMainComponent':
      return React.createElement(InputImYoursMainComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'ImYoursQNAComponent':
      return React.createElement(InputImYoursQNAComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'ImYoursScheduleComponent':
      return React.createElement(InputImYoursScheduleComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'ImYoursThanksComponent':
      return React.createElement(InputImYoursThanksComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'ImYoursOurStoryComponent':
      return React.createElement(InputImYoursOurStoryComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'ImYoursAppPromoteComponent':
      return React.createElement(InputImYoursAppPromoteComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'ImYoursGiftComponent':
      return React.createElement(InputImYoursGiftComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'ImYoursContactInfoComponent':
      return React.createElement(InputImYoursContactInfoComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    //TheArtist TEMPLATE
    case 'TheArtistMainComponent':
      return React.createElement(InputTheArtistMainComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'TheArtistQNAComponent':
      return React.createElement(InputTheArtistQNAComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'TheArtistScheduleComponent':
      return React.createElement(InputTheArtistScheduleComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'TheArtistThanksComponent':
      return React.createElement(InputTheArtistThanksComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'TheArtistOurStoryComponent':
      return React.createElement(InputTheArtistOurStoryComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'TheArtistAppPromoteComponent':
      return React.createElement(InputTheArtistAppPromoteComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'TheArtistGiftComponent':
      return React.createElement(InputTheArtistGiftComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'TheArtistContactInfoComponent':
      return React.createElement(InputTheArtistContactInfoComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    //Baptism2 TEMPLATE
    case 'Baptism2PaletteComponent':
      return React.createElement(InputBaptism2PaletteComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'Baptism2MainComponent':
      return React.createElement(InputBaptism2MainComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'Baptism2QNAComponent':
      return React.createElement(InputBaptism2QNAComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'Baptism2ScheduleComponent':
      return React.createElement(InputBaptism2ScheduleComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'Baptism2ThanksComponent':
      return React.createElement(InputBaptism2ThanksComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'Baptism2OurStoryComponent':
      return React.createElement(InputBaptism2OurStoryComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'Baptism2AppPromoteComponent':
      return React.createElement(InputBaptism2AppPromoteComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'Baptism2GiftComponent':
      return React.createElement(InputBaptism2GiftComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'Baptism2ContactInfoComponent':
      return React.createElement(InputBaptism2ContactInfoComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    //RecessesOfTheSoul TEMPLATE
    case 'RecessesOfTheSoulMainComponent':
      return React.createElement(InputRecessesOfTheSoulMainComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'RecessesOfTheSoulQNAComponent':
      return React.createElement(InputRecessesOfTheSoulQNAComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'RecessesOfTheSoulScheduleComponent':
      return React.createElement(InputRecessesOfTheSoulScheduleComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'RecessesOfTheSoulThanksComponent':
      return React.createElement(InputRecessesOfTheSoulThanksComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'RecessesOfTheSoulOurStoryComponent':
      return React.createElement(InputRecessesOfTheSoulOurStoryComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'RecessesOfTheSoulAppPromoteComponent':
      return React.createElement(InputRecessesOfTheSoulAppPromoteComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'RecessesOfTheSoulGiftComponent':
      return React.createElement(InputRecessesOfTheSoulGiftComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'RecessesOfTheSoulContactInfoComponent':
      return React.createElement(InputRecessesOfTheSoulContactInfoComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    //CallMeByYourName TEMPLATE
    case 'CallMeByYourNameMainComponent':
      return React.createElement(InputCallMeByYourNameMainComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'CallMeByYourNameQNAComponent':
      return React.createElement(InputCallMeByYourNameQNAComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'CallMeByYourNameScheduleComponent':
      return React.createElement(InputCallMeByYourNameScheduleComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'CallMeByYourNameThanksComponent':
      return React.createElement(InputCallMeByYourNameThanksComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'CallMeByYourNameOurStoryComponent':
      return React.createElement(InputCallMeByYourNameOurStoryComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'CallMeByYourNameAppPromoteComponent':
      return React.createElement(InputCallMeByYourNameAppPromoteComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'CallMeByYourNameGiftComponent':
      return React.createElement(InputCallMeByYourNameGiftComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'CallMeByYourNameContactInfoComponent':
      return React.createElement(InputCallMeByYourNameContactInfoComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    //SEPIA TEMPLATE
    case 'SepiaMainComponent':
      return React.createElement(InputSepiaMainComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'SepiaQNAComponent':
      return React.createElement(InputSepiaQNAComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'SepiaScheduleComponent':
      return React.createElement(InputSepiaScheduleComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'SepiaOurStoryComponent':
      return React.createElement(InputSepiaOurStoryComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'LPThanksToComponent':
    case 'SepiaThanksComponent':
      return React.createElement(InputSepiaThanksComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });

    case 'SepiaAppPromoteComponent':
      return React.createElement(InputSepiaAppPromoteComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'SepiaGiftComponent':
      return React.createElement(InputSepiaGiftComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    case 'SepiaContactInfoComponent':
      return React.createElement(InputSepiaContactInfoComponent, {
        componentName: componentName,
        componentIndex: componentIndex,
        componentProps: componentProps,
        updateComponentProps: updateComponentProps,
        autoSave: autoSave,
        key: componentIndex,
      });
    default:
      break;
  }
};

export const TemplateComponents: any = (componentName: string, componentProps: any) => {
  switch (componentName) {
    case 'SepiaMainComponent':
      return React.createElement(SepiaMainComponent, componentProps);
    case 'SepiaScheduleComponent':
      return React.createElement(SepiaScheduleComponent, componentProps);
    case 'SepiaQNAComponent':
      return React.createElement(SepiaQNAComponent, componentProps);
    case 'SepiaThanksComponent':
      return React.createElement(SepiaThanksComponent, componentProps);
    case 'SepiaAppPromoteComponent':
      return React.createElement(SepiaAppPromoteComponent, componentProps);
    case 'SepiaOurStoryComponent':
      return React.createElement(SepiaOurStoryComponent, componentProps);
    case 'SepiaGiftComponent':
      return React.createElement(SepiaGiftComponent, componentProps);
    case 'SepiaContactInfoComponent':
      return React.createElement(SepiaContactInfoComponent, componentProps);
    // Baptism2
    case 'Baptism2PaletteComponent':
      return React.createElement(Baptism2PaletteComponent, componentProps);
    case 'Baptism2MainComponent':
      return React.createElement(Baptism2MainComponent, componentProps);
    case 'Baptism2ScheduleComponent':
      return React.createElement(Baptism2ScheduleComponent, componentProps);
    case 'Baptism2QNAComponent':
      return React.createElement(Baptism2QNAComponent, componentProps);
    case 'Baptism2ThanksComponent':
      return React.createElement(Baptism2ThanksComponent, componentProps);
    case 'Baptism2AppPromoteComponent':
      return React.createElement(Baptism2AppPromoteComponent, componentProps);
    case 'Baptism2OurStoryComponent':
      return React.createElement(Baptism2OurStoryComponent, componentProps);
    case 'Baptism2GiftComponent':
      return React.createElement(Baptism2GiftComponent, componentProps);
    case 'Baptism2ContactInfoComponent':
      return React.createElement(Baptism2ContactInfoComponent, componentProps);
    // CALL ME BY YOUR NAME
    case 'CallMeByYourNameMainComponent':
      return React.createElement(CallMeByYourNameMainComponent, componentProps);
    case 'CallMeByYourNameScheduleComponent':
      return React.createElement(CallMeByYourNameScheduleComponent, componentProps);
    case 'CallMeByYourNameQNAComponent':
      return React.createElement(CallMeByYourNameQNAComponent, componentProps);
    case 'CallMeByYourNameThanksComponent':
      return React.createElement(CallMeByYourNameThanksComponent, componentProps);
    case 'CallMeByYourNameAppPromoteComponent':
      return React.createElement(CallMeByYourNameAppPromoteComponent, componentProps);
    case 'CallMeByYourNameOurStoryComponent':
      return React.createElement(CallMeByYourNameOurStoryComponent, componentProps);
    case 'CallMeByYourNameGiftComponent':
      return React.createElement(CallMeByYourNameGiftComponent, componentProps);
    case 'CallMeByYourNameContactInfoComponent':
      return React.createElement(CallMeByYourNameContactInfoComponent, componentProps);
    // RECESSESOFTHESOUL
    case 'RecessesOfTheSoulMainComponent':
      return React.createElement(RecessesOfTheSoulMainComponent, componentProps);
    case 'RecessesOfTheSoulScheduleComponent':
      return React.createElement(RecessesOfTheSoulScheduleComponent, componentProps);
    case 'RecessesOfTheSoulQNAComponent':
      return React.createElement(RecessesOfTheSoulQNAComponent, componentProps);
    case 'RecessesOfTheSoulThanksComponent':
      return React.createElement(RecessesOfTheSoulThanksComponent, componentProps);
    case 'RecessesOfTheSoulAppPromoteComponent':
      return React.createElement(RecessesOfTheSoulAppPromoteComponent, componentProps);
    case 'RecessesOfTheSoulOurStoryComponent':
      return React.createElement(RecessesOfTheSoulOurStoryComponent, componentProps);
    case 'RecessesOfTheSoulGiftComponent':
      return React.createElement(RecessesOfTheSoulGiftComponent, componentProps);
    case 'RecessesOfTheSoulContactInfoComponent':
      return React.createElement(RecessesOfTheSoulContactInfoComponent, componentProps);
    // THEARTIST
    case 'TheArtistMainComponent':
      return React.createElement(TheArtistMainComponent, componentProps);
    case 'TheArtistScheduleComponent':
      return React.createElement(TheArtistScheduleComponent, componentProps);
    case 'TheArtistQNAComponent':
      return React.createElement(TheArtistQNAComponent, componentProps);
    case 'TheArtistThanksComponent':
      return React.createElement(TheArtistThanksComponent, componentProps);
    case 'TheArtistAppPromoteComponent':
      return React.createElement(TheArtistAppPromoteComponent, componentProps);
    case 'TheArtistOurStoryComponent':
      return React.createElement(TheArtistOurStoryComponent, componentProps);
    case 'TheArtistGiftComponent':
      return React.createElement(TheArtistGiftComponent, componentProps);
    case 'TheArtistContactInfoComponent':
      return React.createElement(TheArtistContactInfoComponent, componentProps);
    // IMYOURS
    case 'ImYoursMainComponent':
      return React.createElement(ImYoursMainComponent, componentProps);
    case 'ImYoursScheduleComponent':
      return React.createElement(ImYoursScheduleComponent, componentProps);
    case 'ImYoursQNAComponent':
      return React.createElement(ImYoursQNAComponent, componentProps);
    case 'ImYoursThanksComponent':
      return React.createElement(ImYoursThanksComponent, componentProps);
    case 'ImYoursAppPromoteComponent':
      return React.createElement(ImYoursAppPromoteComponent, componentProps);
    case 'ImYoursOurStoryComponent':
      return React.createElement(ImYoursOurStoryComponent, componentProps);
    case 'ImYoursGiftComponent':
      return React.createElement(ImYoursGiftComponent, componentProps);
    case 'ImYoursContactInfoComponent':
      return React.createElement(ImYoursContactInfoComponent, componentProps);
    default:
      return undefined;
  }
};

//TODO Use Custom Validation in any input component
export const CheckValidation: any = (
  componentName: string,
  componentProps: any,
  index: number,
  componentPropsInit: any,
) => {
  let errorMessage = '';

  if (componentProps[index] === undefined) {
    console.log('CheckValidation ComponentProps:', componentProps);
    componentPropsInit();
    return 'Internal Error';
  }

  switch (componentName) {
    case 'SepiaMainComponent':
      if (!componentProps[index]['SepiaMainComponentMainImage']) {
        errorMessage += 'Main Image cannot be empty\n';
      }
      if (!componentProps[index]['SepiaMainComponentCoupleName']) {
        errorMessage += 'Couple Name cannot not be empty\n';
      }
      return errorMessage;
    case 'SepiaScheduleComponent':
      return errorMessage;
    case 'SepiaQNAComponent':
      if (!componentProps[index]['questions']) errorMessage += 'Question cannot be empty\n';
      return errorMessage;
    case 'SepiaThanksComponent':
      // if (!componentProps[index]['SepiaMainComponentMainImage']) errorMessage += 'Question cannot be empty\n';
      // if (
      //   !componentProps[index]['SepiaMainComponentCoupleName'] &&
      //   !componentProps[index]['SepiaMainComponentTypographyLogo']
      // )
      //   return false;
      return errorMessage;
    case 'SepiaAppPromoteComponent':
      return errorMessage;
    case 'SepiaOurStoryComponent':
      if (!componentProps[index]['imagesLayout']) return false;
      if (componentProps[index]['imagesLayout'] == 0) {
        if (!componentProps[index]['image1'] || !componentProps[index]['image2']) {
          errorMessage += 'You have to upload 2 images with this layout';
        }
      }
      if (componentProps[index]['imagesLayout'] == 1) {
        if (!componentProps[index]['image1'] || !componentProps[index]['image2'] || !componentProps[index]['image3']) {
          errorMessage += 'You have to upload 3 images with this layout';
        }
      }
      if (componentProps[index]['imagesLayout'] == 2) {
        if (
          !componentProps[index]['image1'] ||
          !componentProps[index]['image2'] ||
          !componentProps[index]['image3'] ||
          !componentProps[index]['image4']
        ) {
          errorMessage += 'You should upload 4 images with this layout';
        }
      }
      return errorMessage;
    default:
      return errorMessage;
  }
};
