/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function useAutosave(callback: any, delay = 10, deps: Array<any> = []): void {
  const savedCallback = React.useRef<any>(); // to save the current "fresh" callback

  // keep callback ref up to date
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // create the interval
  React.useEffect(() => {
    // function to call the callback
    function runCallback() {
      savedCallback.current();
    }
    if (typeof delay === 'number') {
      // run the interval
      const tempDelay = delay * 1000; //make it seconds
      const interval = setInterval(runCallback, tempDelay);
      // clean up on unmount or dependency change
      return () => clearInterval(interval);
    }
  }, [delay, ...deps]);
}

export default useAutosave;
