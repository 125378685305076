import React, { useEffect, useState } from 'react';
import { FirebaseContext } from '../../../context/firebase.context';
import firebase from 'firebase';
import VerifyEmailComponent from './VerifyEmailComponent';
import ResetPasswordComponent from './ResetPasswordComponent';

const FirebaseTemplatesComponent = (): JSX.Element => {
  const [mode, setMode] = useState<any>();
  const [actionCode, setActionCode] = useState<any>();
  const [continueUrl, setContinueUrl] = useState<any>();
  const [lang, setLang] = useState<any>();
  const [auth, setAuth] = useState<firebase.auth.Auth | undefined>();

  useEffect(() => {
    // Get the action to complete.
    const query = new URLSearchParams(location.search);
    setMode(query.get('mode'));
    // Get the one-time code from the query parameter.
    setActionCode(query.get('oobCode'));
    // (Optional) Get the continue URL from the query parameter if available.
    setContinueUrl('/dashboard');
    // (Optional) Get the language code if available.
    setLang(query.get('lang') || 'en');

    const auth = FirebaseContext.getAuth();
    setAuth(auth);

    // Handle the user management action.
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        // handleResetPassword(auth, actionCode, continueUrl, lang);
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, lang);
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        // handleVerifyEmail(auth, actionCode, continueUrl, lang);
        break;
      default:
      // Error: invalid mode.
    }
  }, []);
  return (
    <div>
      {mode === 'verifyEmail' && auth && (
        <VerifyEmailComponent auth={auth} actionCode={actionCode} continueUrl={continueUrl} lang={lang} />
      )}
      {mode === 'resetPassword' && auth && (
        <ResetPasswordComponent auth={auth} actionCode={actionCode} continueUrl={continueUrl} lang={lang} />
      )}
    </div>
  );
};

export default FirebaseTemplatesComponent;
