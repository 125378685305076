export const errorState = '#D54F68';
export const mainStrongAccent = '#281B24';
export const mainOperationalAccent = '#A9A4A7';
export const mainBackgroundAccent = '#F4F3F4';
export const secondaryStrongAccent = '#63435B';
export const secondaryOperationalAccent = '#835978';
export const secondaryHoverAccent = '#C7ADC1';
export const brandStrongAccent = '#F78C50';
export const brandOperationalAccent = '#F9AC80';
export const brandHoverAccent = '#FBCCB1';
export const white = '#FFFFFF';
export const black = '#000000';
export const brandBackgroundAccent = '#FDF6EB';
export const greenLight = '#c1c6a8';
export const successStrongAccent = '#006150';
export const successState = '#00C9A5';
export const successPlaceholder = '#A3F5E6';
export const warningStrongAccent = '#B48F08';
export const warningState = '#F5C92E';
export const warningPlaceholder = '#FAE28F';
export const errorStrongAccent = '#98243A';
export const errorPlaceholder = '#F8AAB8';
