import React, { useEffect, useRef, useState } from 'react';
import styles from './EventEditor.module.scss';
import CustomDatePicker from '../../../datePicker/CustomDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';

import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import { Location, Point, RSVPEvent } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import LocationInputComponent from '../locationInput/LocationInputComponent';
import {
  addRsvpEvents,
  removeRsvpEvents,
  selectRsvpEvents,
  selectWedding,
  setWedding,
} from '../../../../app/redux/wedding.slice.recuder';
import { useDispatch, useSelector } from 'react-redux';
import { dateTimeFormat } from '../../../../config/dateFormats';
import dayjs from 'dayjs';
import { BrainHelper } from '../../../../pkg/apiHelpers/brainHelper';
import ReactTooltip from 'react-tooltip';
import Icon from '../../../../shared/Icon';
import { TimePicker } from '@mui/x-date-pickers';
import { formatLocation } from '../../../../shared/Functions/location';
import { useSearchParams } from 'react-router-dom';
import { useTypeOfScreen, ScreenType, useMobileCheck } from '../../../../hooks';
import GenericConfirmModal from '../../../modals/GenericConfirmModal';
import useOutsideClick from '../../../../hooks/useOutsideClick';

const EventEditor = (props: {
  eventDateStart: string;
  eventDateEnd: string;
  event: RSVPEvent;
  userToken: string;
  setIsEdit: any;
  isEdit?: boolean;
  onBackCeremony?: () => void;
}): JSX.Element => {
  const rsvpEvents = useSelector(selectRsvpEvents);
  const wedding = useSelector(selectWedding);
  const [isEdit, setIsEdit] = useState<boolean>(!!props.isEdit);
  const [eventName, setEventName] = useState<string>(props.event.name);
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [selectedDay, setSelectedDay] = useState<DayValue | null>(
    props.eventDateStart !== ''
      ? {
          year: Number(props.eventDateStart.split('T')[0].split('-')[0]),
          month: Number(props.eventDateStart.split('T')[0].split('-')[1]),
          day: Number(props.eventDateStart.split('T')[0].split('-')[2]),
        }
      : undefined,
  );
  const defaultTimeStamp = '21:00:00';
  const [startDate, setStartDate] = useState<string>(
    props.event?.location?.externalQuickCode
      ? dayjs
          .tz(
            props.eventDateStart || new Date(),
            props.event?.location?.externalQuickCode ? props.event.location.externalQuickCode : dayjs.tz.guess(),
          )
          .format('YYYY-MM-DDTHH:mm:00')
      : dayjs(props.eventDateStart || new Date()).format('YYYY-MM-DDTHH:mm:00'),
  );
  const [endDate, setEndDate] = useState<string>(
    props.event?.location?.externalQuickCode
      ? dayjs
          .tz(
            props.eventDateEnd || new Date(),
            props.event?.location?.externalQuickCode ? props.event.location?.externalQuickCode : dayjs.tz.guess(),
          )
          .format('YYYY-MM-DDTHH:mm:00')
      : dayjs(props.eventDateEnd || new Date()).format('YYYY-MM-DDTHH:mm:00'),
  );

  const [datePickerStart, setDatePickerStart] = useState<string>(
    dayjs
      .utc(props.event?.startTimestamp || new Date())
      .tz(props.event?.location?.externalQuickCode ? props.event?.location?.externalQuickCode : dayjs.tz.guess())
      .format('YYYY-MM-DDTHH:mm:00'),
  );
  const [datePickerEnd, setDatePickerEnd] = useState<string>(
    dayjs
      .utc(props.event?.endTimestamp || new Date())
      .tz(props.event?.location?.externalQuickCode ? props.event.location?.externalQuickCode : dayjs.tz.guess())
      .format('YYYY-MM-DDTHH:mm:00'),
  );
  const [weddingLocation, setWeddingLocation] = useState<Location | undefined>(props.event?.location);
  const [countryShort, setCountryShort] = useState<string | undefined>(props.event?.location?.externalQuickCode);
  const [description, setDescription] = useState<string | undefined>(props.event.description);
  const [isPrivate, setIsPrivate] = useState<boolean | undefined>(props.event.isPrivate);
  const [isInToolTip, setIsInToolTip] = useState<boolean | undefined>(false);
  const [googleApiError, setGoogleApiError] = useState(false);
  const [locationError, setLocationError] = useState('');
  const [dateError, setDateError] = useState('');
  const [eventNameError, setEventNameError] = useState('');
  const formRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const isMobile = useMobileCheck();
  useEffect(() => {
    const handleKeyboardEscapeOutside = (event: any) => {
      if (event.key === 'Escape') {
        onCancel();
      }
    };
    document.addEventListener('keydown', handleKeyboardEscapeOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyboardEscapeOutside);
    };
  }, [isEdit]);

  useEffect(() => {
    if (searchParams.get('eventId') === eventName) {
      setIsEdit(true);
    }
    searchParams.delete('eventId');
    setSearchParams(searchParams);
    setEventName(props.event.name);
    setSelectedDay(
      props.eventDateStart !== ''
        ? {
            year: Number(props.eventDateStart.split('T')[0].split('-')[0]),
            month: Number(props.eventDateStart.split('T')[0].split('-')[1]),
            day: Number(props.eventDateStart.split('T')[0].split('-')[2]),
          }
        : undefined,
    );

    setStartDate(
      dayjs
        .tz(
          props.eventDateStart || new Date(),
          props.event?.location?.externalQuickCode ? props.event?.location?.externalQuickCode : dayjs.tz.guess(),
        )
        .format('YYYY-MM-DDTHH:mm:ss'),
    );
    setDatePickerStart(
      dayjs
        .utc(props.event?.startTimestamp || defaultTimeStamp)
        .tz(props.event?.location?.externalQuickCode ? props.event?.location?.externalQuickCode : dayjs.tz.guess())
        .format('YYYY-MM-DDTHH:mm:ss'),
    );
    setEndDate(
      dayjs
        .tz(
          props.eventDateEnd || new Date(),
          props.event?.location?.externalQuickCode ? props.event?.location?.externalQuickCode : dayjs.tz.guess(),
        )
        .format('YYYY-MM-DDTHH:mm:ss'),
    );
    setDatePickerEnd(
      dayjs
        .utc(props.event?.endTimestamp || defaultTimeStamp)
        .tz(props.event?.location?.externalQuickCode ? props.event?.location?.externalQuickCode : dayjs.tz.guess())
        .format('YYYY-MM-DDTHH:mm:ss'),
    );
    setWeddingLocation(props.event.location);
    setCountryShort(weddingLocation?.externalQuickCode);
    setDescription(props.event.description);
  }, [props.event]);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const onSaveEvent = async () => {
    let allGood = false;
    const rsvpEvent = new RSVPEvent();
    rsvpEvent.name = eventName;
    rsvpEvent.icon = props.event.icon;
    rsvpEvent.weddingId = wedding.id;
    rsvpEvent.isDefault = props.event.isDefault;
    if (eventName.trim() === '') {
      setEventNameError('event name should be defined');
    } else if (weddingLocation === undefined || googleApiError) {
      setEventNameError('');
      setLocationError(
        googleApiError ? 'you need to select a location from the dropdown' : 'location should be defined',
      );
    } else if (!selectedDay) {
      setLocationError('');
      setDateError('date should be defined');
    } else if (!startDate) {
      setLocationError('');
      setDateError('date should be defined');
    } else if (!endDate) {
      setLocationError('');
      setDateError('date should be defined');
    } else {
      rsvpEvent.id = props.event.id || '';
      const location = new Location();
      const point = new Point();
      rsvpEvent.isPrivate = isPrivate || false;
      point.longitude = weddingLocation.point?.longitude || 0;
      point.latitude = weddingLocation.point?.latitude || 0;
      location.address = weddingLocation.address;
      location.externalQuickCode = weddingLocation.externalQuickCode;
      location.point = point;
      location.name = weddingLocation.name;
      rsvpEvent.location = location;
      const tzStart = dayjs.tz(
        selectedDay?.year +
          '-' +
          selectedDay?.month +
          '-' +
          selectedDay?.day +
          ' ' +
          dateTimeFormat({
            hour: 'numeric',
          }).format(new Date(startDate)) +
          ':' +
          new Date(startDate).getMinutes() +
          ':' +
          new Date(startDate).getSeconds(),
        countryShort,
      );
      const tzEnd = dayjs.tz(
        selectedDay?.year +
          '-' +
          selectedDay?.month +
          '-' +
          selectedDay?.day +
          ' ' +
          dateTimeFormat({
            hour: 'numeric',
          }).format(new Date(endDate)) +
          ':' +
          new Date(endDate).getMinutes() +
          ':' +
          new Date(endDate).getSeconds(),
        countryShort,
      );

      rsvpEvent.startTimestamp = tzStart.toJSON();
      rsvpEvent.endTimestamp = tzEnd.toJSON();
      rsvpEvent.description = description || '';
      rsvpEvent.kindlyReminder = props.event.kindlyReminder;

      const request = rsvpEvent;
      if (request.id === undefined || request.id === null || request.id === '') {
        const response = await BrainHelper.createRsvpEvent(
          props.userToken,
          request.weddingId,
          request.name,
          request.startTimestamp,
          request.endTimestamp,
          request.location as Location,
          request.isDefault,
          request.isPrivate,
          request.icon,
          request.description,
        );
        if (response && response?.event) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(addRsvpEvents([response.event]));
          allGood = true;
        }
      } else {
        const response = await BrainHelper.updateRsvpEvent(
          props.userToken,
          request.id,
          request.weddingId,
          request.name,
          request.startTimestamp,
          request.endTimestamp,
          request.location as Location,
          request.isDefault,
          request.isPrivate,
          request.icon,
          request.description,
          request.kindlyReminder,
        );
        if (response && response?.event) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(addRsvpEvents([response.event]));
          allGood = true;
        }
      }

      if (props.event.isDefault) {
        const newWedding = JSON.parse(JSON.stringify(wedding, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
        newWedding.mainEvent = request;
        dispatch(setWedding(newWedding));
      }
    }
    return allGood;
  };

  const onCancel = () => {
    props.setIsEdit(false);
    setIsEdit(false);
    props.setIsEdit(false);
    const newEvents = rsvpEvents.filter((v: RSVPEvent) => v.id === '');
    dispatch(removeRsvpEvents(newEvents));
    setEventNameError('');
    setLocationError('');
    setDateError('');
  };

  // useOutsideClick(formRef, () => {
  //   onCancel();
  // });
  const typeOfScreen = useTypeOfScreen();
  return !isEdit ? (
    <div className={styles.event}>
      {showDeleteModal && (
        <GenericConfirmModal
          setShow={setShowDeleteModal}
          title={'Just a final check'}
          text={'Are you sure you want to delete this event?'}
          confirmBtnFunction={async () => {
            await BrainHelper.deleteRsvpEvent(props.userToken, props.event.id, wedding.id);
            const eventsToBeRemoved = rsvpEvents.filter((v: RSVPEvent) => v.id === '');
            eventsToBeRemoved.push(props.event);
            dispatch(removeRsvpEvents(eventsToBeRemoved));
            props.setIsEdit(false);
            setShowDeleteModal(false);
          }}
          confirmBtnText={'Delete'}
          confirmBtnIcon={'trash'}
          // html={t}
        />
      )}
      {!isNaN(new Date(props.eventDateStart).getTime()) && !isNaN(new Date(props.eventDateEnd).getTime()) ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p className={'didacticP'}>
            <b>
              {`${dayjs
                .tz(
                  props.eventDateStart,
                  props.event.location?.externalQuickCode ? props.event.location?.externalQuickCode : dayjs.tz.guess(),
                )
                .format('HH:mm')} - ${dayjs
                .tz(
                  props.eventDateEnd,
                  props.event.location?.externalQuickCode ? props.event.location?.externalQuickCode : dayjs.tz.guess(),
                )
                .format('HH:mm')}`}{' '}
            </b>
            (Event&apos;s Timezone)
          </p>
        </div>
      ) : (
        ''
      )}
      <div className={styles.locationWithOptions}>
        <h3
          className={'didacticH3'}
          style={
            props.event.isDefault
              ? {
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: 8,
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: '100%',
                  wordBreak: 'break-word',
                }
              : { maxWidth: '100%', wordBreak: 'break-word' }
          }
        >
          {props.event.name}
          {props.event.isDefault && (
            <div className={styles.popUpGlobalMobileModal}>
              <div
                className={styles.warning}
                style={
                  !isWarningOpen
                    ? { backgroundColor: 'white', filter: 'drop-shadow(0px 0px 0px transparent)' }
                    : { padding: 16 }
                }
              >
                <button onClick={() => setIsWarningOpen((prevState) => !prevState)}>
                  <Icon name={'info'} size={'s'} color={!isWarningOpen ? '#B48F08' : '#63435B'} />
                </button>
                {isWarningOpen && (
                  <p className={'didacticP'}>
                    This is the main event and it is always public by default. You cannot delete it.
                  </p>
                )}
              </div>
            </div>
          )}
        </h3>
        <button
          className={'btnPlain'}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            if (!props.event.isDefault) {
              setIsEdit(true);
              props.setIsEdit(true);
            } else {
              if (props.onBackCeremony) props.onBackCeremony();
            }
          }}
        >
          <img style={{ alignSelf: 'center', marginLeft: 'auto', cursor: 'pointer' }} src="/rsvp/edit.svg" alt="edit" />
        </button>
        {!props.event.isDefault ? (
          <button className={'btnPlain'} onClick={() => setShowDeleteModal(true)}>
            <img
              style={{ alignSelf: 'center', marginLeft: 'auto', cursor: 'pointer' }}
              src="/rsvp/delete.svg"
              alt="edit"
            />
          </button>
        ) : (
          ''
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <img src="/rsvp/map.svg" alt="map" style={{ width: '16px', height: '16px' }} />
        <a
          className={'didacticP'}
          style={{
            wordWrap: 'break-word',
            width: '250px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          target="_blank"
          href={`https://maps.google.com/?q=${props.event?.location?.point?.latitude},${props.event?.location?.point?.longitude}`}
          rel="noreferrer"
        >
          {props.event.location && formatLocation(props.event.location)}
        </a>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Icon name="calendar" color={!props.event.isPrivate ? '#47817E' : '#D40000'} size="s" />
        <p className={'didacticP'} style={{ color: !props.event.isPrivate ? '#47817E' : '#D40000' }}>
          {props.event.isPrivate ? 'Private' : 'Public'} Event
        </p>
      </div>
      <hr className={styles.hr} />
    </div>
  ) : (
    <div className={styles.modalWrapper}>
      <form ref={formRef} action="#" onSubmit={(e) => e.preventDefault()} className={styles.form}>
        <div className={styles.header}>
          <h2 className={'didacticH2'}>Event Planning</h2>
          <button style={{ background: 'none', border: 'none' }} onClick={() => onCancel()}>
            <Icon name={'cancel'} color={'#A9A4A7'} size={'sPlus'} />
          </button>
        </div>
        <div>
          {eventNameError !== '' ? <p style={{ color: 'red' }}>{eventNameError}</p> : <></>}
          <h4 className={'didacticH4'}>Event Name*</h4>
          <input
            style={{ maxWidth: '100%' }}
            className={'input'}
            type="text"
            placeholder={'Example... Dinner'}
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
        </div>
        {dateError ? <p style={{ color: 'red' }}>{dateError}</p> : <></>}
        <CustomDatePicker
          setCalendarOpened={setCalendarOpened}
          disableCalendar={false}
          selectedDay={selectedDay}
          calendarOpened={calendarOpened}
          setSelectedDay={setSelectedDay}
          name={'Event Date*'}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack className={styles.timeDiv}>
            <div>
              <h4 className={'fridayH4'}>
                <span style={{ fontSize: '14px' }}>(Event&apos;s Timezone)</span> From*
              </h4>
              {/*<input*/}
              {/*  className="input"*/}
              {/*  style={{ width: '160px', cursor: 'pointer' }}*/}
              {/*  value={datePickerStart !== '' ? datePickerStart.split('T')[1] : startDate.split('T')[1]}*/}
              {/*  onChange={(newValue) => {*/}
              {/*    setDatePickerStart('');*/}
              {/*    console.log(newValue.target.value);*/}
              {/*    setStartDate(`${startDate.split('T')[0]}T${String(newValue.target.value)}:00`);*/}
              {/*  }}*/}
              {/*  type="time"*/}
              {/*  required*/}
              {/*/>*/}
              <TimePicker
                className={styles.timePickerDiv}
                ampm={false}
                value={
                  datePickerStart !== '' && Date.parse(datePickerStart)
                    ? new Date(datePickerStart)
                    : new Date(startDate)
                }
                onChange={(newValue: any) => {
                  if (Date.parse(newValue)) {
                    setDatePickerStart('');
                    setStartDate(`${startDate.split('T')[0]}T${newValue.toString().split(' ')[4]}`);
                  }
                }}
              />
            </div>
            <div>
              <p style={{ color: '#ffffff' }}>-</p>
              <p>-</p>
            </div>
            <div>
              <h4 className={'fridayH4'}>To*</h4>
              {/*<input*/}
              {/*  className="input"*/}
              {/*  style={{ width: '160px', cursor: 'pointer' }}*/}
              {/*  value={datePickerEnd !== '' ? datePickerEnd.split('T')[1] : endDate.split('T')[1]}*/}
              {/*  onChange={(newValue) => {*/}
              {/*    setDatePickerEnd('');*/}
              {/*    console.log(newValue.target.value);*/}
              {/*    setEndDate(`${endDate.split('T')[0]}T${String(newValue.target.value)}:00`);*/}
              {/*  }}*/}
              {/*  type="time"*/}
              {/*  required*/}
              {/*/>*/}
              <TimePicker
                className={styles.timePickerDiv}
                ampm={false}
                value={datePickerEnd !== '' && Date.parse(datePickerEnd) ? new Date(datePickerEnd) : new Date(endDate)}
                onChange={(newValue: any) => {
                  if (Date.parse(newValue)) {
                    setDatePickerEnd('');
                    setEndDate(`${endDate.split('T')[0]}T${newValue.toString().split(' ')[4]}`);
                  }
                }}
              />
            </div>
          </Stack>
        </LocalizationProvider>
        <div>
          {locationError !== '' || googleApiError ? <p style={{ color: 'red' }}>{locationError}</p> : <></>}
          <LocationInputComponent
            label={'Event Location*'}
            location={weddingLocation}
            setLocation={setWeddingLocation}
            setCountryShort={setCountryShort}
            required={true}
            setError={setGoogleApiError}
          />
        </div>
        <div>
          <h4 className={'fridayH4'}>Description</h4>
          <textarea
            style={
              typeOfScreen === ScreenType.mobile
                ? { width: 'calc(100% - 16px)', minWidth: 'calc(100% - 4px)', maxWidth: '100%' }
                : { maxWidth: '100%' }
            }
            className={'textarea'}
            rows={4}
            cols={10}
            placeholder={'A small paragraph...'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {!props.event.isDefault ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <input
              type="checkbox"
              className={'input checkbox'}
              name="isPrivate"
              checked={isPrivate}
              onChange={() => setIsPrivate((prev) => !prev)}
            />
            <label htmlFor="isPrivate">Private Event</label>
            <img
              data-tip="All events are by default public.
                      <br/>
                      A private event is shown only to
                      <br/>
                      the invited guests and will not
                      <br/>
                      appear in your website."
              src={'/rsvp/icon-info' + (isInToolTip ? '-active' : '') + '.svg'}
              alt="info"
              onMouseEnter={() => setIsInToolTip(true)}
              onMouseLeave={() => setIsInToolTip(false)}
            />
            <ReactTooltip
              effect={'solid'}
              place={'top'}
              multiline={true}
              border={true}
              textColor={'#63435B'}
              backgroundColor={'#FDF6EB'}
              borderColor={'#63435B'}
            />
          </div>
        ) : (
          ''
        )}
        <div className={styles.btns}>
          <button
            className={'btn btnMain'}
            onClick={async () => {
              if (await onSaveEvent()) onCancel();
            }}
          >
            Save
          </button>
          <button className={'btn btnSecondary'} onClick={() => onCancel()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EventEditor;
