import React from 'react';
import templateStyles from '../Baptism2Template.module.scss';
import styles from './Baptism2OurStoryComponent.module.scss';
import { color1, color2, color3, color6 } from '../Baptism2Palettes';

interface OurStoryComponentProps {
  title?: string;
  description?: string;
  graphicsImageUrl?: string;
  Palette?: number;
}

const defaultValues = {
  title: 'Our Story',
  description:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit amet velit eget ac vulputate. Laoreet amet consequat diam id morbi a. At at aliquet orci leo in condimentum morbi ut. Tincidunt lacus pellentesque sodales elit et eget adipiscing sapien, massa. Feugiat aliquet amet praesent viverra mattis quam. Tellus massa mauris, pulvinar maecenas. Ullamcorper sit phasellus augue sapien risus. Sagittis vitae vel amet scelerisque scelerisque. Quisque tortor urna imperdiet amet feugiat vestibulum ultricies. Mauris erat ut diam turpis ut non. Sed pulvinar donec purus, diam elementum consequat vivamus. Dui sem turpis lectus elit quisque id. Suscipit risus aliquam eu ac mi et tristique elementum. Nulla in viverra massa magna auctor quis.</p>',
  graphicsImageUrl: '/templates/Baptism2/flowerStory.svg',
};

const Baptism2OurStoryComponent = ({
  title,
  description,
  graphicsImageUrl,
  Palette,
}: OurStoryComponentProps): JSX.Element => {
  const colorPalette = Palette === 2 ? 'Girl' : Palette === 1 ? 'Boy' : 'Neutral';
  return (
    <section
      id={`Baptism2OurStoryComponent`}
      className={styles.OurStoryComponent + ' ' + templateStyles['bg3' + colorPalette]}
    >
      <div>
        <h3 className={templateStyles.fridayH2 + ' ' + templateStyles['color6' + colorPalette]}>
          {String(title ? title : defaultValues.title)}
        </h3>

        <div
          style={{ textAlign: 'justify', maxWidth: 537 }}
          dangerouslySetInnerHTML={{ __html: description ? description : defaultValues.description }}
        />
      </div>
      <svg width="347" height="331" viewBox="0 0 347 331" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M190.339 0C174.823 0 165.001 1.53185 159.35 2.39673C143.893 4.74215 136.519 6.09076 120.519 12.8485L120.512 12.8338C108.096 18.0304 108.491 16.7551 98.472 22.3255C47.2465 50.9616 35.6734 72.9719 35.7686 72.84L34.2221 74.9288C18.4932 96.2795 5.07295 128.331 1.0271 158.58C1.28363 157.898 1.09307 156.799 0.609322 163.813C-0.607364 181.579 -0.40947 195.388 5.05096 212.268C4.92636 212.158 7.60894 220.059 11.0318 228.994C11.6988 233.641 13.15 236.726 13.106 236.602C17.8628 249.289 24.5253 261.529 37.4398 275.47C57.4711 297.165 85.3963 311.398 81.6509 309.295L81.9368 309.456C99.139 319.124 92.777 314.975 114.003 320.993C128.127 324.995 129.93 325.288 152.109 328.41C189.87 333.607 214.167 329.803 240.538 322.297C255.938 317.9 274.129 306.371 272.847 307.016C282.272 301.115 297.298 287.988 308.644 272.596C341.516 228.055 352.672 167.397 344.36 128.221C338.885 102.671 333.329 72.73 315.819 52.2809C315.848 52.4055 315.482 51.8998 312.198 48.0298C291.874 24.1432 285.05 24.1871 268.786 16.5279C261.926 13.2956 258.73 12.2915 258.796 12.3135C252.126 9.84344 250.529 8.13568 241.565 5.95883C224.971 1.85435 224.055 2.22815 224.069 2.23548C222.464 2.0669 215.956 1.71509 216.08 1.72975C207.505 0.571696 198.468 0 190.339 0Z"
          fill={color2[colorPalette]}
        />
        <path
          d="M289.777 154.05C289.652 154.036 285.291 153.376 285.416 153.391C278.848 152.511 272.347 152.753 266.887 152.98L267.121 153.009C259.946 153.053 251.964 155.465 245.148 157.876C232.937 163.982 223.453 172.344 220.176 176.442C237.965 189.012 236.777 187.201 246.496 190.697C251.62 192.537 253.401 192.662 253.283 192.64C253.408 192.654 257.564 193.241 257.307 193.204C271.306 195.19 289.014 192.163 308.386 181.191C313.421 177.241 320.208 169.787 320.34 169.669C312.974 163.183 299.737 155.23 289.777 154.05ZM309.764 171.553C299.693 180.165 281.069 186.314 268.103 186.981C271.072 176.31 270.339 167.111 267.59 160.119C285.196 160.273 293.419 158.675 310.16 171.186L309.764 171.553Z"
          fill={color6[colorPalette]}
        />
        <path
          d="M100.795 164.407C85.7333 156.872 64.1334 158.477 48.06 163.146C36.1423 168.584 25.6099 174.814 15.29 187.069L15.7225 187.406C16.4921 187.802 17.3569 188.242 18.1558 188.777C23.4697 192.31 32.0451 194.89 36.4135 196.619C38.004 197.25 39.6897 197.719 41.1703 198.137L42.2477 198.444L43.9188 198.862C49.819 200.24 54.5392 200.453 54.246 200.438L55.9904 200.511C68.663 201.252 73.9769 200.255 73.8523 200.262C74.6145 200.196 75.2815 200.138 75.9192 200.02C102.408 194.86 111.46 187.436 120.204 179.051L120.27 178.846C115.396 173.7 108.953 168.599 100.795 164.407ZM89.7425 189.466C89.6179 189.503 88.8849 189.737 89.0022 189.7C70.62 195.483 65.2255 194.113 63.5251 194.303L62.1471 194.274C63.5984 190.353 63.6204 186.71 63.4958 182.005C63.3712 177.358 62.477 172.432 60.7619 166.943C84.9564 163.153 97.8195 168.262 110.236 179.051L110.602 179.388C108.007 180.414 103.588 185.068 89.7425 189.466Z"
          fill={color6[colorPalette]}
        />
        <path
          d="M200.175 204.924C199.78 203.546 198.556 202.688 197.236 202.688C195.411 202.688 194.019 204.403 194.187 205.737C195.199 213.367 193.264 221.305 191.6 222.295C190.413 221.987 190.545 222.155 183.662 206.632C183.142 205.444 182.695 204.184 181.478 203.517C179.345 202.351 176.941 203.766 176.633 205.532C176.179 208.039 176.795 214.606 171.422 232.358C171.107 233.413 170.521 234.96 170.352 235.319C163.543 223.958 166.27 204.499 161.923 201.911C160.56 201.105 158.742 201.296 157.694 202.534C156.426 204.059 155.818 207.467 154.748 210.201C152.094 216.175 150.475 220.074 149.221 221.378C139.253 201.816 168.27 83.028 141.775 57.0525C133.58 49.0047 126.038 43.8301 101.939 50.0382C91.1428 52.816 73.772 64.1913 65.9881 71.9385C54.8694 82.9913 41.0094 102.172 29.1064 129.431C27.7358 132.538 30.3304 134.715 32.6685 134.07C34.7574 133.498 34.1784 133.044 38.9645 122.776C48.0603 103.279 63.2689 81.7527 75.3185 72.1584C90.8862 60.2921 106.117 52.281 124.917 54.2013C130.157 55.1615 135.339 59.5811 138.703 63.7442C154.315 85.2928 141.357 168.973 141.115 188.249C140.851 208.398 140.646 222.449 145.403 226.663C146.304 227.455 147.557 228.18 148.796 228.144C154.293 227.975 156.331 222.023 158.874 216.38C159.644 223.805 161.388 233.633 165.69 239.724C168.534 243.77 172.294 243.55 174.068 241.38C177.513 237.173 180.613 222.317 181.449 217.772C184.183 223.775 186.228 229.58 193.117 228.393C199.105 227.396 200.835 215.28 200.645 209.219C200.615 207.775 200.403 206.346 200.175 204.924ZM191.043 222.243H191.065C191.058 222.243 191.05 222.243 191.043 222.243ZM191.072 222.251C191.065 222.243 191.065 222.243 191.072 222.251C191.182 222.265 191.3 222.28 191.409 222.295C191.292 222.287 191.182 222.265 191.072 222.251Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M222.024 108.857C219.29 109.436 216.828 112.324 216.85 116.311C216.183 119.426 218.382 122.922 223.527 123.501C232.278 124.476 233.905 106.321 222.024 108.857Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M251.892 121.852C251.159 121.757 249.73 121.449 247.868 122.394C243.368 122.658 242.745 127.869 243.368 132.326C243.661 134.415 245.178 136.496 248.227 137.588C252.449 139.098 259.595 133.447 257.712 126.931C257.433 125.949 254.531 122.226 251.892 121.852Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M267.606 102.224C262.351 102.224 260.401 107.486 261.537 111.092C262.322 113.628 264.565 115.886 267.225 115.886C274.386 116.633 276.79 102.224 267.606 102.224Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M246.82 95.554C246.08 98.8303 247.04 101.689 250.089 103.873C251.005 104.525 252.244 104.679 253.321 104.767C257.609 105.119 261.603 99.1601 258.752 93.8683C255.945 88.6571 248.366 88.745 246.82 95.554Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M275.207 78.9087C274.137 78.9087 272.055 79.3631 271.19 80.4625C270.039 81.9138 269.175 83.1304 268.552 84.8529C266.858 89.5437 272.121 94.5424 278.028 91.5666C283.848 88.6349 282.118 78.9087 275.207 78.9087Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M304.239 119.924C297.027 121.265 297.862 135.572 307.969 133.718C310.447 133.256 311.905 131.123 312.719 128.932C312.997 128.184 313.034 127.385 313.137 126.601C313.679 122.343 308.636 117.938 304.239 119.924Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M201.883 54.8094C198.585 54.9633 197.192 57.0083 196.144 59.5589C194.18 64.367 196.364 68.0537 200.402 68.9479C209.579 70.9782 212.452 54.3257 201.883 54.8094Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M258.657 43.3022C254.728 43.3022 251.76 47.4434 252.478 50.9469C251.907 55.1686 257.037 58.3936 260.944 56.8544C268.105 54.0179 266.932 43.3022 258.657 43.3022Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M278.183 97.4523C276.028 101.352 275.31 104.232 279.216 107.171C281.1 108.593 285.212 108.945 287.777 106.592C290.767 103.858 290.929 99.3946 288.261 96.3529C285.651 93.3625 280.513 93.2452 278.183 97.4523ZM287.132 101.923C287.154 101.901 287.147 101.901 287.154 101.894C287.147 101.901 287.139 101.909 287.132 101.923Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M302.78 79.4217C303.616 78.7694 304.056 77.6846 304.437 76.7464C306.005 72.9718 302.912 67.9364 298.925 68.0171C296.924 68.0317 294.579 69.417 293.846 70.8902C293.098 72.3854 292.468 73.844 292.409 75.5517C292.292 78.7767 295.825 81.965 299.958 80.9975C301.102 80.7336 301.886 80.1326 302.78 79.4217Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M248.044 77.0103C248.967 76.2334 249.722 74.2838 249.92 73.2357C251.247 66.3313 243.368 61.7724 239.051 67.7312C238.398 68.6328 238.288 69.3217 238.171 69.5563C234.976 75.7936 242.063 81.987 248.044 77.0103Z"
          fill={color3[colorPalette]}
        />
        <path
          d="M98.8532 250.945L97.6951 250.689C91.1719 249.304 77.7078 247.134 77.5832 247.119C77.6711 246.995 90.2045 220.594 89.193 216.57C88.9585 215.566 87.8884 214.819 86.9062 214.819C85.3231 214.819 82.2447 217.523 82.4939 217.325C82.3693 217.421 82.0321 217.684 82.1567 217.589L82.3986 217.662L82.0248 217.706L82.2154 217.794C72.5185 224.134 62.5138 236.909 63.2101 236.03C63.2248 233.223 57.5811 213.998 56.7383 212.077C56.1666 210.729 53.5719 210.076 52.4798 212.246C51.7029 213.726 44.6593 238.647 44.6227 238.771C42.5265 236.426 22.8909 220.711 19.2628 220.711C18.7131 220.711 18.1561 220.865 17.6943 221.151C15.5028 222.419 16.6389 225.542 17.5551 227.865C19.3801 232.431 29.1136 250.755 29.1869 250.879C25.7934 252.133 10.3576 260.613 7.8509 265.033C7.30852 265.941 7.27187 267.092 7.76294 267.884C9.93979 271.871 26.6949 266.821 37.7917 267.92C36.9708 271.827 34.3028 298.11 35.8567 301.636C36.2964 302.647 37.2713 303.329 38.2827 303.329C40.2837 303.329 41.7935 301.064 46.609 294.915C49.1376 291.624 59.047 278.717 61.2532 274.744C71.6097 290.759 75.1864 293.222 80.0239 300.155C80.8228 301.357 82.2667 303.373 83.8059 302.625C84.3043 302.435 84.6268 302.332 84.8906 302.032C84.9859 301.907 85.4257 301.343 85.3304 301.467C87.4119 296.454 80.6469 278.291 76.4618 263.655C76.5424 263.867 87.3093 261.815 93.3561 259.799C93.4807 259.763 95.5183 259.11 95.401 259.147C95.5256 259.11 95.9581 258.978 95.8335 259.015C103.317 256.67 105.039 257.417 105.86 255.834C106.161 255.321 106.234 254.742 106.073 254.112C105.604 251.957 102.217 251.693 98.8532 250.945Z"
          fill={color1[colorPalette]}
        />
        <path
          d="M310.886 246.379L310.219 245.976C310.351 245.866 327.055 228.041 327.737 224.984C328.103 223.57 327.143 222.016 325.501 222.016C323.075 222.016 306.65 230.188 304.525 231.251C300.86 232.974 299.482 233.443 296.198 235.04L296.242 234.769C291.713 204.697 290.877 188.169 277.633 225.967C277.552 226.201 277.406 226.634 277.45 226.494C276.16 230.166 275.009 233.809 274.027 237.335C269.996 234.835 248.381 222.639 245.464 222.639C244.386 222.639 243.272 223.438 242.965 224.545C242.723 225.417 243.045 226.26 243.434 227.044C244.731 229.668 257.396 248.9 260.665 253.239L260.423 253.276C255.249 255.929 233.282 265.971 235.019 272.157C235.862 275.206 240.7 274.766 252.845 274.466C256.275 274.378 260.306 274.018 260.233 274.026C262.388 273.85 265.29 273.652 267.504 273.908C266.199 278.651 262.38 299.488 262.08 302.017C261.765 305.293 263.172 306.502 264.931 306.502C267.335 306.502 266.595 304.633 280.806 285.753C285.431 279.596 284.119 281.45 286.194 278.658C287.447 281.084 289.895 287.849 292.827 293.669C292.885 293.793 295.077 298.316 294.938 298.03C296.374 301.013 298.294 304.941 301.234 303.453C303.337 302.442 303.506 299.298 303.784 297.157C304.415 291.668 305.045 264.974 305.06 264.666L305.624 264.945C311.209 266.674 325.655 267.627 328.594 267.268C338.76 265.949 319.132 251.319 310.886 246.379Z"
          fill={color1[colorPalette]}
        />
      </svg>
    </section>
  );
};
export default Baptism2OurStoryComponent;
