import styles from './GenericConfirmModal.module.scss';
import Icon from '../../shared/Icon';
import React, { ReactElement, useRef, useState } from 'react';
import { SVGIconName } from '../../shared/Icon/Icons';
import useOutsideClick from '../../hooks/useOutsideClick';

const GenericConfirmModal = (props: {
  setShow: any;
  title: string;
  text: string;
  html?: ReactElement;
  confirmBtnText: string;
  confirmBtnIcon: string;
  confirmBtnFunction: any;
}): JSX.Element => {
  const [isConfirmBtnHovered, setIsConfirmBtnHovered] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => props.setShow(false));
  return (
    <div className={styles.wrapper}>
      <div ref={wrapperRef} className={styles.modalBox}>
        <h3 className={'didacticH3'}>{props.title}</h3>
        {props.html ? props.html : <p className={'didacticP'}>{props.text}</p>}
        <div className={styles.btns}>
          <button
            className={'btn btnMain btnMainWithImg'}
            onMouseEnter={() => setIsConfirmBtnHovered(true)}
            onMouseLeave={() => setIsConfirmBtnHovered(false)}
            onClick={() => props.confirmBtnFunction()}
          >
            <Icon
              name={props.confirmBtnIcon as SVGIconName}
              size={'s'}
              color={isConfirmBtnHovered ? '#FDF6EB' : '#63435B'}
            />
            {props.confirmBtnText}
          </button>
          <button
            className={'btn btnSecondary'}
            onClick={() => {
              props.setShow(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenericConfirmModal;
