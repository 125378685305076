import React, { useState } from 'react';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker, { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import styles from './CustomDatePicker.module.scss';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useTypeOfScreen, ScreenType } from '../../hooks';

const CustomDatePicker = (props: {
  setCalendarOpened: any;
  disableCalendar: any;
  selectedDay: any;
  calendarOpened: any;
  setSelectedDay: any;
  name: string;
}): JSX.Element => {
  const typeOfScreen = useTypeOfScreen();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [focus, setFocus] = useState('startDate');
  const day = new Date();
  // render regular HTML input element
  const renderCustomInput = ({ ref }: any) => {
    useOutsideClick(ref, () => props.setCalendarOpened(false));
    return (
      <input
        readOnly
        disabled={props.disableCalendar}
        value={props.selectedDay ? `${props.selectedDay.day}/${props.selectedDay.month}/${props.selectedDay.year}` : ''}
        onClick={() => props.setCalendarOpened(!props.disableCalendar)}
        ref={ref} // necessary
        placeholder="Choose Date"
        style={{
          width: typeOfScreen === ScreenType.mobile ? 'calc(100vw - 16px)' : '',
          backgroundImage: 'url(/rsvp/calendar' + (props.calendarOpened ? '_active' : '') + '.svg)',
        }}
        className={'input' + ' ' + styles.datePicker} // a styling class
      />
    );
  };

  // render regular HTML input element
  const renderFooter = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem 2rem' }}>
        <button
          type="button"
          onClick={() => {
            props.setSelectedDay(undefined);
          }}
          className={'btn btnMain'}
          style={{ padding: '0.5rem 1rem' }}
        >
          Reset Date
        </button>
      </div>
    );
  };

  return (
    <div style={{ zIndex: 8 }}>
      <h4 className={'fridayH4'}>{props.name}</h4>
      <DatePicker
        minimumDate={{ year: day.getFullYear(), month: day.getMonth() + 1, day: day.getDate() }}
        value={props.selectedDay}
        renderInput={renderCustomInput}
        onChange={(e: DayValue) => {
          // console.log(e);
          props.setSelectedDay(e);
        }}
        renderFooter={renderFooter}
        calendarClassName={styles.calendar}
        calendarSelectedDayClassName={styles.calendarSelected}
        calendarTodayClassName={styles.calendarToday}
        wrapperClassName={styles.wrapper}
      />
    </div>
  );
};

export default CustomDatePicker;
