import React, { useEffect, useState } from 'react';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { Wedding } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { setIsTutorialOn, setWedding } from '../../../app/redux/wedding.slice.recuder';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessTokenAfterOTP,
  selectUser,
  setAccessTokenAfterOTP,
  setConnectedAccount,
  setJwt,
  setLoggedIn,
  setMarriageCode,
  setProfile,
} from '../../../app/redux/authorization.slice.reducer';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import Lottie from 'react-lottie';
import styles from './WeddingCard.module.scss';
import location from '../../../loader-animation.json';
import { logger } from '../../../config/Logger';
import { transliterate } from 'transliteration';
import { motion } from 'framer-motion';
import Image from '../../../shared/Image/Image';
import GetUI, { EventType } from '../../../utils/uiContent';
const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const WeddingCard = (props: { wedding: Wedding; userToken: string }): JSX.Element => {
  const [weddingImage, setWeddingImage] = useState<string>('');
  const dispatch = useDispatch();
  const [state, setState] = useState<any>({});
  const user = useSelector(selectUser);
  const stateRef = React.useRef<any>();
  const userRef = React.useRef<any>(user);
  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(false);
  userRef.current = user;
  stateRef.current = state;

  useEffect(() => {
    console.log(props.wedding);
  }, [props.wedding]);

  const accessToken = useSelector(selectAccessTokenAfterOTP);

  const fetchWeddingImage = async (weddingId: string, fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, props.userToken, 1000);
    if (typeof result === 'string') {
      setWeddingImage(result);
    }
    return result;
  };

  const setTheWedding = async (weddingPath: string) => {
    let weddingData: Wedding | null = null;
    if (weddingPath) {
      weddingData = await BrainHelper.getWedding(
        props.userToken,
        weddingPath,
        dispatch,
        stateRef,
        setState,
        setWedding,
      );
      dispatch(setWedding(weddingData));
      dispatch(setIsTutorialOn(true));
      logger.info({
        message: `setup marriageCode: ${transliterate(weddingData.path)}`,
        functionName: 'WeddingCard.setTheWedding',
      });
      console.log('setWeddingData', weddingData);
      dispatch(setMarriageCode(transliterate(weddingData.path)));
    } else {
      logger.info({ message: `emptying marriageCode`, functionName: 'WeddingCard.setTheWedding' });
      dispatch(setWedding(new Wedding()));
      dispatch(setMarriageCode(''));
    }
    return weddingData;
  };

  useEffect(() => {
    if (props?.wedding?.mainEvent) fetchWeddingImage(props?.wedding?.id, props?.wedding?.mainEvent?.icon);
  }, [props.wedding]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      className={styles.weddingCard}
      onClick={async () => {
        try {
          setIsSecondButtonLoading(true);
          const weddingData = await setTheWedding(transliterate(props.wedding.path));
          if (weddingData) {
            const connectedResponse = await BrainHelper.connectAccount(
              accessToken,
              weddingData.id,
              user.accountId,
              dispatch,
              stateRef,
              setState,
              userRef,
              setConnectedAccount,
              setJwt,
            );
            if (connectedResponse !== null) {
              dispatch(setLoggedIn(true));
              dispatch(setAccessTokenAfterOTP(connectedResponse.jwtToken));
              dispatch(setProfile(connectedResponse.connectedAccount));

              setIsSecondButtonLoading(false);

              window.scrollTo(0, 0);
              window.location.pathname = `/dashboard/w/${transliterate(weddingData.path)}/overview`;
            } else {
              setIsSecondButtonLoading(false);
              setTheWedding('');
            }
          } else {
            setIsSecondButtonLoading(false);
            setTheWedding('');
          }
        } catch (e) {
          setIsSecondButtonLoading(false);
          setTheWedding('');
          logger.error({ message: e, functionName: 'WeddingCard.returnRender' });
        }
      }}
      role="presentation"
    >
      {weddingImage ? (
        <Image
          src={
            weddingImage
              ? weddingImage
              : 'https://images.unsplash.com/photo-1650500341895-782ef870ea94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1010&q=80'
          }
          alt={'wedding'}
          fixedSize
          classNames={styles.weddingImage}
          onErrorComponent={<img src={'assets/graphics/chs_wedding-placeholder-img.png'} alt="Couple" />}
        />
      ) : (
        <img src={'assets/graphics/chs_wedding-placeholder-img.png'} alt="Couple" />
      )}
      <div>
        <h4
          className={'fridayH4'}
          style={{
            textAlign: 'center',
            marginTop: '16px',
            display: 'flex',
            gap: 16,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={GetUI(props.wedding.socialEventType as EventType).icon} alt="Social Event" width={32} />
          {props.wedding.name}
        </h4>
        <p style={{ textAlign: 'center' }}>{props.wedding.year}</p>
      </div>

      {!isSecondButtonLoading ? (
        <></>
      ) : (
        <div className={styles.loadingBox}>
          <Lottie options={defaultOptions1} height={80} width={300} />
        </div>
      )}
    </motion.div>
  );
};
