import { useState } from 'react';
import { Icon } from '../../../shared';

import styles from './Table.module.scss';
import { SVGIconName } from '../../../shared/Icon/Icons';

const DropdownButton = (props: {
  title: string;
  icon?: boolean;
  _callback?: any;
  disabled?: boolean;
  iconName?: SVGIconName;
}): JSX.Element => {
  // let isActive = false;
  const [isActive, setIsActive] = useState(false);
  return (
    <button
      disabled={props.disabled}
      className={styles.dropdownButton + ' ' + (isActive ? styles.dropdownButtonActive : '')}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onClick={() => {
        setIsActive((prevState) => !prevState);
        props._callback();
      }}
    >
      {props.iconName ? (
        <Icon name={props.iconName} color={'#63435B'} size={'s'} />
      ) : (
        <Icon name={props.icon ? 'ceremony' : 'event'} color={'#63435B'} size={'s'} />
      )}
      <span className={'didacticH4'}>{props.title}</span>
    </button>
  );
};

export default DropdownButton;
