import React, { useEffect, useState } from 'react';
import { AvailableTemplates } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { GetAppropriateInput } from '../../../templates';

type WebsiteDetailsFormProps = {
  selectedTemplate: AvailableTemplates;
  sectionComponentsList: Array<string>;
  componentProps: Array<any>;
  updateComponentProps: any;
  autoSave: (forceSave?: boolean) => void;
};

const WebsiteDetailsForm = ({
  sectionComponentsList,
  selectedTemplate,
  componentProps,
  updateComponentProps,
  autoSave,
}: WebsiteDetailsFormProps): JSX.Element => {
  return (
    <div
      style={{
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: 'fit-content',
        width: '100%',
      }}
    >
      {sectionComponentsList?.map((component, componentIndex) => {
        return GetAppropriateInput(component, componentIndex, componentProps, updateComponentProps, autoSave);
      })}
    </div>
  );
};

export default WebsiteDetailsForm;
