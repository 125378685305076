import React, { useEffect, useState } from 'react';
import styles from './RecessesOfTheSoulMainComponent.module.scss';
import '../RecessesOfTheSoul.module.scss';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';

interface RecessesOfTheSoulMainComponentProps {
  RecessesOfTheSoulMainComponentTypographyLogo?: string;
  RecessesOfTheSoulMainComponentCoupleName?: string;
  RecessesOfTheSoulMainComponentMainImage?: string;
}

const defaultValues = {
  typographyLogo: '/templates/RecessesOfTheSoul/mainComponentTypographyLogo.svg',
  mainImage: '/templates/RecessesOfTheSoul/mainComponentMainImage.png',
  coupleName: 'Jane&George',
};

const RecessesOfTheSoulMainComponent = ({
  RecessesOfTheSoulMainComponentTypographyLogo,
  RecessesOfTheSoulMainComponentCoupleName,
  RecessesOfTheSoulMainComponentMainImage,
}: RecessesOfTheSoulMainComponentProps): JSX.Element => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [blob, setBlob] = useState('');

  const fetchPostImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob(result);
  };

  useEffect(() => {
    if (RecessesOfTheSoulMainComponentMainImage) {
      fetchPostImage(RecessesOfTheSoulMainComponentMainImage);
    }
  }, [RecessesOfTheSoulMainComponentMainImage]);

  return (
    <section
      id={`RecessesOfTheSoulMainComponent`}
      className={styles.RecessesOfTheSoulMainComponent}
      style={{ backgroundImage: `url(${blob ? blob : defaultValues.mainImage})` }}
    >
      <h4 className={styles.typographyLogo}>
        {RecessesOfTheSoulMainComponentCoupleName && RecessesOfTheSoulMainComponentCoupleName.length > 0
          ? RecessesOfTheSoulMainComponentCoupleName
          : defaultValues.coupleName}
      </h4>
    </section>
  );
};
export default RecessesOfTheSoulMainComponent;
