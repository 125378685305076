const getPostMessageReadyForEditing = (message: string): string => {
  // TODO: uncomment the following when we will have a page per user
  // newContent = message.split('<a href="/user/').join('@@@__');
  // newContent = message.split(`">@`).join('^^^__');
  // newContent = message.split('</a>').join('@@@^^^');
  message = message.split('<a href="#?caid=').join('@@@__');
  message = message.split(`"><span>@`).join('^^^__');
  message = message.split('</span></a>').join('@@@^^^');

  // TODO: uncomment the following when we will have a page per tag
  // newContent = newContent.split('<a href="/tag/').join('$$$__');
  // newContent = newContent.split(`">#`).join('~~~__');
  // newContent = newContent.split('</a>').join('$$$~~~');
  message = message.split('<a href="#?tagid=').join('$$$__');
  message = message.split(`">#`).join('~~~__');
  message = message.split('</a>').join('$$$~~~');

  return message;
};

export default getPostMessageReadyForEditing;
