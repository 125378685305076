import React, { useEffect, useState } from 'react';
import { AllowedVisitor, RSVPEvent } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import UserInfo from '../userInfo/UserInfo';
import Icon from '../../../../shared/Icon';
import styles from './UserDetails.module.scss';
import { useSelector } from 'react-redux';
import { selectWedding } from '../../../../app/redux/wedding.slice.recuder';
import { StatusEnum } from '../../../../shared/Enums';
import { useNavigate } from 'react-router-dom';

type UserDetailsProps = {
  guest: AllowedVisitor | undefined;
  userToken: string;
  allEvents: RSVPEvent[];
  setShowGuestModal?: any;
  onAddToEvent?: any;
};

const attendanceResponse = [
  { str: 'Not responded', backgroundColor: '#F4F3F4', color: '#A9A4A7' },
  { str: 'Not attending', backgroundColor: '#F8AAB8', color: '#98243A' },
  { str: 'Attending', backgroundColor: '#A3F5E6', color: '#006150' },
];

const UserDetails = ({ guest, userToken, allEvents, setShowGuestModal, onAddToEvent }: UserDetailsProps) => {
  const wedding = useSelector(selectWedding);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();

  if (guest !== undefined)
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <UserInfo
              firstName={guest.firstName}
              lastName={guest.lastName}
              email={guest.email}
              imageSrc={guest.connectedAccountDetails?.imageProfileUrl}
              userToken={userToken}
              isBigger={true}
            />
            <button
              style={{ borderRadius: 4, borderColor: '#281B24' }}
              className={'btn btnIconSecondary btnXSmall'}
              onMouseEnter={() => setIsEdit(true)}
              onMouseLeave={() => setIsEdit(false)}
              onClick={() => setShowGuestModal(true)}
            >
              <Icon name={'edit'} size={'s'} color={isEdit ? '#ffffff' : '#281B24'} />
            </button>
          </div>
          <div>
            <h4 className={'didacticH4'}>Phone</h4>
            <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
              <Icon name={'phone_call'} color={'#281B24'} size={'s'} />
              <p className={'didacticP'} style={{ width: 'fit-content', borderBottom: '1px solid #281B24' }}>
                {guest.phone}
              </p>
            </div>
          </div>
          <div>
            <h4 className={'didacticH4'}>Status</h4>
            <div>
              <p
                className={styles.status + ' didacticP'}
                style={{
                  backgroundColor: StatusEnum[guest.status].backgroundColor,
                  color: StatusEnum[guest.status].color,
                }}
              >
                {StatusEnum[guest.status].name}
              </p>
            </div>
          </div>
          <div>
            <h4 className={'didacticH4'}>RSVP Results</h4>
            <div className={styles.rsvpResults}>
              {[...allEvents]
                .sort((a, b) => new Date(a.startTimestamp).getTime() - new Date(b.startTimestamp).getTime())
                .map((rsvpEvent) => {
                  const attendToEvents = guest?.attendToRsvpEvents.find((a) => a.rsvpEvent?.id === rsvpEvent.id);
                  const invitedToEvents = guest?.invitedToRsvpEvents.find((a) => a?.id === rsvpEvent.id);
                  if (rsvpEvent.isPrivate && !invitedToEvents && !attendToEvents) return;
                  const response = attendToEvents?.responded ? (attendToEvents?.attend ? 2 : 1) : 0;
                  return (
                    <button
                      key={rsvpEvent.id}
                      style={{
                        display: 'flex',
                        width: '100%',
                        gap: 8,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '16px 24px',
                        backgroundColor: attendanceResponse[response].backgroundColor,
                        marginTop: 16,
                        borderRadius: 8,
                        fontSize: 16,
                        border: 0,
                      }}
                      onClick={() =>
                        navigate(`/dashboard/w/${wedding.path}/rsvp?rsvpEvent=${rsvpEvent.id}&user=${guest?.id}`)
                      }
                    >
                      <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
                        <Icon
                          name={wedding.mainEvent?.id === rsvpEvent.id ? 'ceremony' : 'event'}
                          size={'sPlus'}
                          color={'black'}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <p className={'didacticP'}>{rsvpEvent.name}</p>
                          <span className={'didacticSpan'} style={{ color: attendanceResponse[response].color }}>
                            {attendanceResponse[response].str}
                          </span>
                        </div>
                      </div>
                      <Icon name={'arrow_right'} size={'s'} color={'black'} />
                    </button>
                  );
                })}
            </div>
          </div>
          <div>
            <button className={'btn btnIconSecondary'} onClick={() => onAddToEvent()}>
              Add to Event
            </button>
          </div>
        </div>
      </div>
    );
  else return <div>No Guest Selected</div>;
};

export default UserDetails;
