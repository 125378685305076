import './PostComponent.scss';

import { IonImg } from '@ionic/react';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import ShowMoreText from 'react-show-more-text';

import success from '../../../1127-success.json';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import {
  selectAccountsCombinedWithConnectedAccountsForMentions,
  selectJwt,
  selectListAccounts,
  selectListConnectAccounts,
  selectProfileImages,
} from '../../../app/redux/authorization.slice.reducer';
import {
  selectComments,
  selectPostInteractions,
  selectWedding,
  setPostInteractionReducer,
  setTags,
  TagDetail,
} from '../../../app/redux/wedding.slice.recuder';
import { Backend } from '../../../config/backend';
import { logger } from '../../../config/Logger';
import location from '../../../loader-animation.json';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import {
  Comment,
  ConnectedAccountRef,
  CreateCommentRequest,
  CreatePostInteractionRequest,
  DeletePostInteractionRequest,
  DeletePostRequest,
  Post,
  PostInteraction,
  Tag,
  UpdatePostRequest,
  Wedding,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { CommentComponent } from '../../shared';
import { LoveButton } from '../../../component/loveButton/LoveButton';
import AvatarProfile from '../AvatarProfile/AvatarProfile';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type PostComponentProps = {
  post: Post;
  wedding: Wedding;
  userToken: string;
  account: ConnectedAccountRef;
  profile: ConnectedAccountRef;
  connectedAccounts: ConnectedAccountRef[];
  accounts: ConnectedAccountRef[];
  showPostEditDeleteModal?: (e: any) => void;
  setEditPostCallback?: (e: any) => void;
  setDeletePostCallback?: (e: any) => void;
  setEditCommentCallback: (e: any) => void;
  setCreateCommentCallback?: any;
  createCommentCallback?: any;
  setDeleteCommentCallback: any;
  setShowLikesModal: any;
  setPostInteractions: any;
  showEditDeleteCommentModal: any;
  setShowEditCommentModal: any;
  setLikesAndCommentsCounters?: any;
  setUpdateCommentCallback: any;
  setUpdateComment: any;
  showCommentsModal: any;
  setCommentsForModal?: any;
  setCurrentPost?: any;
  isSinglePage?: boolean;
};

const PostComponent = (props: PostComponentProps): JSX.Element => {
  const [file, setFile] = useState('');
  const [authorImage, setAuthorImage] = useState<string | undefined>();
  const [fullName, setFullName] = useState<string | undefined>('');
  const [date, setDate] = useState<string | undefined>('');
  const [heart, setHeart] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState<string>(props.post.message);
  const [reply, setReply] = useState('');

  const last2comments = useSelector((state: any) => {
    const allComments = selectComments(state);
    const filtedComments = allComments?.filter((comment: Comment) => comment?.postId === props.post?.id);
    // sort them by created timestamp desc

    const sortedComments = filtedComments.sort((a: Comment, b: Comment) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      const result = dayjs(a.dateCreated).diff(dayjs(b.dateCreated));
      return result;
    });

    const doubleSortedComments = [];
    for (let i = sortedComments.length - 1; i >= 0; i--) {
      if (!props.isSinglePage && i >= sortedComments.length - 2) {
        doubleSortedComments.push(sortedComments[i]);
      } else if (props.isSinglePage) {
        doubleSortedComments.push(sortedComments[i]);
      } else break;
    }

    return doubleSortedComments;
  });
  const comments = useSelector((state: any) => {
    const allComments = selectComments(state);
    const filtedComments = allComments?.filter((comment: Comment) => comment?.postId === props.post?.id);
    // sort them by created timestamp desc

    const sortedComments = filtedComments.sort((a: Comment, b: Comment) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      const result = dayjs(b.dateCreated).diff(dayjs(a.dateCreated));
      return result;
    });

    const doubleSortedComments = [];
    for (let i = 0; i < sortedComments.length; i++) {
      if (i < 2) {
        doubleSortedComments.push(sortedComments[i]);
      } else {
        continue;
      }
    }

    return sortedComments;
  });

  const connectedAccounts = useSelector(selectListConnectAccounts);
  const accounts = useSelector(selectListAccounts);
  const postInteractions = useSelector((state: any) => {
    const allPostInteractions = selectPostInteractions(state);
    // all post interactions
    const currentPostInteractions = allPostInteractions?.filter(
      (postInteraction) => postInteraction?.postId === props.post?.id,
    );
    return currentPostInteractions;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [content, setContent] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [likedUser, setLikedUser] = useState<string>('');
  const profileImages = useSelector(selectProfileImages);

  // start loading bullets by default

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initFileLoading, setInitFileLoading] = useState<boolean>(false);
  const [completedFile, setCompletedFile] = useState<boolean>(false);

  // take control over the style of a component

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [style, setStyle] = useState({});

  // used as input data for the mention plugin
  const mentions = useSelector(selectAccountsCombinedWithConnectedAccountsForMentions);
  const jwt = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);

  // selected tags
  const [selectedTags, setSelectedTags] = useState<TagDetail[]>([]);
  // selected mentions
  const [selectedMentions, setSelectedMentions] = useState<string[]>([]);

  // selected tags
  const [selectedCommentTags, setSelectedCommentTags] = useState<TagDetail[]>([]);
  // selected mentions
  const [selectedCommentMentions, setSelectedCommentMentions] = useState<string[]>([]);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   document.addEventListener('scroll', () => {
  //     // const el: HTMLElement | null = document.getElementById('editImg');
  //   });
  // }, []);

  useEffect(() => {
    const account = fetchAccount(props.post, connectedAccounts);
    setFullName(`${account?.accountFirstName} ${account?.accountLastName}`);
    const d = dayjs(props.post.dateUpdated);
    const now = dayjs();

    const diffHours: number = now.diff(d, 'hour');

    if (diffHours < 24) {
      setDate(dayjs(d).format('HH:mm'));
    } else {
      setDate(dayjs(d).format('DD MMMM YYYY'));
    }

    if (account && profileImages.size > 0) {
      setAuthorImage(profileImages.get(account?.accountId || account?.accountPhoneId));
    }

    // TODO: fix here for multiple image support
    if (Array.isArray(props.post.files) && props.post.files.length > 0)
      fetchImage(props.post.files[0].fileId).then(() => {
        // close loading page
        setCompletedFile(true);
      });
    if (
      (account?.accountId === props.profile?.accountId && account?.accountId) ||
      (account?.accountPhoneId === props.profile?.accountPhoneId && account?.accountPhoneId)
    )
      setIsEditable(true);
    else setIsEditable(false);
    setMessage(props.post.message);
  }, [props.post, profileImages]);

  useEffect(() => {
    if (Array.isArray(postInteractions) && postInteractions.length > 0) {
      const connectedAcc = connectedAccounts.find(
        (a) =>
          a?.connectedAccountId === postInteractions[0].accountId ||
          a?.connectedAccountPhoneId === postInteractions[0].accountId,
      );
      setLikedUser(`${connectedAcc?.accountFirstName} ${connectedAcc?.accountLastName}` || '');
    } else setLikedUser('');
  }, [postInteractions]);

  const fetchAccount = (post: Post, connectedAccounts: ConnectedAccountRef[]) => {
    const authorConnectedAccount: ConnectedAccountRef | undefined = connectedAccounts.find(
      (a) => a?.connectedAccountId === post?.authorAccountId || a?.connectedAccountPhoneId === post?.authorAccountId,
    );
    return authorConnectedAccount;
  };

  //use effect to set heart svg red when there is a like connected user made
  useEffect(() => {
    setHeart(
      postInteractions?.find(
        (interaction) =>
          interaction.accountId === props.account?.connectedAccountId ||
          interaction.accountId === props.account?.connectedAccountPhoneId,
      ) !== undefined,
    );
  }, [postInteractions]);

  const fetchImage = async (fileID: string) => {
    const newFile = await Imagehelper.getFileFromFilestoreId(fileID, props.userToken, Imagehelper.windowSize());
    if (typeof newFile === 'string') setFile(newFile);
  };

  const createComment = async (
    message: string,
    selectedCommentMentions: string[],
    selectedCommentTags: TagDetail[],
  ) => {
    let newContent = message;

    // TODO: uncomment the following when we will have a page per user
    // newContent = newContent.split('@@@__').join('<a href="/user/');
    // newContent = newContent.split('^^^__').join(`">@`);
    // newContent = newContent.split('@@@^^^').join('</a>');
    newContent = newContent.split('@@@__').join('<a href="#?caid=');
    newContent = newContent.split('^^^__').join(`"><span>@`);
    newContent = newContent.split('@@@^^^').join('</span></a>');

    // TODO: uncomment the following when we will have a page per tag
    // newContent = newContent.split('$$$__').join('<a href="/tag/');
    // newContent = newContent.split('~~~__').join(`">#`);
    // newContent = newContent.split('$$$~~~').join('</a>');
    newContent = newContent.split('$$$__').join('<a href="#?tagid=');
    newContent = newContent.split('~~~__').join(`">#`);
    newContent = newContent.split('$$$~~~').join('</a>');

    if (!newContent) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const body = newContent.trim();

    try {
      const createCommentRequest = new CreateCommentRequest();
      createCommentRequest.weddingId = props.wedding?.id;
      createCommentRequest.postId = props.post?.id;
      createCommentRequest.message = newContent;
      createCommentRequest.mentionConnectedAccountIds = selectedCommentMentions;
      // tags request
      const tagListRequest: Tag[] = [];
      for (const tagDetail of selectedCommentTags) {
        const tagRequest = new Tag();
        tagRequest.id = tagDetail?.id;
        tagRequest.weddingId = props.wedding?.id;
        tagRequest.key = tagDetail.display;
        tagListRequest.push(tagRequest);
      }
      createCommentRequest.tags = tagListRequest;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${props.userToken}`);
      await Backend.brainClientV2.createComment(createCommentRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'PostComponent.CreateComment' });
    }
  };

  const createInteraction = async (
    isDoubleTap: boolean,
    weddingId: string,
    postId: string,
    accountId: string,
    postInteractions: PostInteraction[],
    userToken: string,
  ) => {
    try {
      const postInteractionRequest = new CreatePostInteractionRequest();
      postInteractionRequest.weddingId = weddingId;
      postInteractionRequest.postId = postId;
      postInteractionRequest.emoticon = '/heart.svg';

      logger.debug({ message: `connectedAccountId: ${accountId}` });
      logger.debug({
        message: `postInteractions: ${JSON.stringify(postInteractionRequest, (_, v) =>
          typeof v === 'bigint' ? v.toString() : v,
        )}`,
      });

      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      const responseMessage = await Backend.brainClientV2.createPostInteraction(postInteractionRequest, { headers });
      logger.debug({ message: responseMessage });
      if (responseMessage) {
        setHeart(true);
      }
    } catch (e) {
      // if (!isDoubleTap) await deleteInteraction(weddingId, postId, accountId, postInteractions, userToken);
      logger.error({ message: e, functionName: 'PostComponent.createInteraction' });
    }
  };

  const deleteInteraction = async (
    weddingId: string,
    postId: string,
    accountId: string,
    account2Id: string,
    postInteractions: PostInteraction[],
    userToken: string,
    dispatch: any,
    setPostInteractionReducer: any,
  ) => {
    const postInteractionsForCurrentAccount = postInteractions.filter(
      (interaction) => interaction.accountId === accountId || interaction.accountId === account2Id,
    );

    for (let i = 0; i < postInteractionsForCurrentAccount.length; i++) {
      const postInteraction = postInteractionsForCurrentAccount[i];
      try {
        const deletePostInteractionRequest = new DeletePostInteractionRequest();
        deletePostInteractionRequest.weddingId = weddingId;
        deletePostInteractionRequest.postId = postId;

        deletePostInteractionRequest.id = postInteraction?.id || '';

        logger.debug({ message: deletePostInteractionRequest });

        const headers = new Headers();
        headers.set('Authorization', `Bearer ${userToken}`);
        const responseMessage = await Backend.brainClientV2.deletePostInteraction(deletePostInteractionRequest, {
          headers,
        });
        logger.debug({ message: responseMessage });
      } catch (e) {
        // clean store from post interactions ids that fail to be deleted
        const newList = postInteractions.filter((x) => {
          return x?.id != postInteraction?.id;
        });
        dispatch(setPostInteractionReducer(newList));
        logger.error({ message: e, functionName: 'PostComponent.deleteInteraction' });
      }
    }
  };

  const comment = (
    t: any,
    accounts: ConnectedAccountRef[],
    connectedAccounts: ConnectedAccountRef[],
    weddingId: string,
    userToken: string,
    profile: ConnectedAccountRef,
  ) => {
    let dateToShow = '';

    const d = dayjs(t.dateUpdated);
    const now = dayjs();

    const diffHours: number = now.diff(d, 'hour');

    if (diffHours < 24) {
      dateToShow = dayjs(d).format('HH:mm');
    } else {
      dateToShow = dayjs(d).format('DD MMMM YYYY');
    }

    return (
      <CommentComponent
        account={props.account}
        accounts={accounts}
        connectedAccounts={connectedAccounts}
        weddingId={weddingId}
        comment={t}
        userToken={userToken}
        date={dateToShow}
        profile={profile}
        showModal={(e: any) => props.showEditDeleteCommentModal(e)}
        setEditCommentCallback={(e: any) => props.setEditCommentCallback(e)}
        setShowEditCommentModal={(e: any) => props.setShowEditCommentModal(e)}
        setDeleteCommentCallback={(e: any) => props.setDeleteCommentCallback(e)}
        setUpdateCommentCallback={props.setUpdateCommentCallback}
        setUpdateComment={(e: any) => props.setUpdateComment(e)}
      />
    );
  };

  const updatePost = async (message: string, postId: string, weddingId: string, userToken: string) => {
    let newContent = message;

    // TODO: uncomment the following when we will have a page per user
    // newContent = newContent.split('@@@__').join('<a href="/user/');
    // newContent = newContent.split('^^^__').join(`">@`);
    // newContent = newContent.split('@@@^^^').join('</a>');
    newContent = newContent.split('@@@__').join('<a href="#?caid=');
    newContent = newContent.split('^^^__').join(`"><span>@`);
    newContent = newContent.split('@@@^^^').join('</span></a>');

    // TODO: uncomment the following when we will have a page per tag
    // newContent = newContent.split('$$$__').join('<a href="/tag/');
    // newContent = newContent.split('~~~__').join(`">#`);
    // newContent = newContent.split('$$$~~~').join('</a>');
    newContent = newContent.split('$$$__').join('<a href="#?tagid=');
    newContent = newContent.split('~~~__').join(`">#`);
    newContent = newContent.split('$$$~~~').join('</a>');

    if (!newContent) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const body = newContent.trim();

    try {
      const updatePostRequest = new UpdatePostRequest();
      updatePostRequest.id = postId;
      updatePostRequest.weddingId = weddingId;
      updatePostRequest.message = newContent;
      updatePostRequest.mentionConnectedAccountIds = selectedMentions;
      // tags request
      const tagListRequest: Tag[] = [];
      for (const tagDetail of selectedTags) {
        const tagRequest = new Tag();
        tagRequest.id = tagDetail?.id;
        tagRequest.weddingId = props.wedding?.id;
        tagRequest.key = tagDetail?.display;
        tagListRequest.push(tagRequest);
      }
      updatePostRequest.tags = tagListRequest;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);

      await Backend.brainClientV2.updatePost(updatePostRequest, { headers });
    } catch (e) {
      logger.error({ message: e, functionName: 'PostComponent.updatePost' });
    }
  };

  const deletePost = async (postId: string, weddingId: string, userToken: string) => {
    try {
      const deletePostRequest = new DeletePostRequest();
      deletePostRequest.id = postId;
      deletePostRequest.weddingId = weddingId;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${userToken}`);
      await Backend.brainClientV2.deletePost(deletePostRequest, { headers });
      setIsShowEdit(false);
      setEdit(false);
    } catch (e) {
      logger.error({ message: e, functionName: 'PostComponent.deletePost' });
    }
  };

  function useOutsideEdit(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsShowEdit(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  function useOutsideMessage(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setEdit(false);
          setMessage(props.post.message);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  const wrapperRef1 = useRef(null);
  useOutsideEdit(wrapperRef);
  useOutsideMessage(wrapperRef1);

  const getPostMessageReadyForEditing = (message: string): string => {
    // TODO: uncomment the following when we will have a page per user
    // newContent = message.split('<a href="/user/').join('@@@__');
    // newContent = message.split(`">@`).join('^^^__');
    // newContent = message.split('</a>').join('@@@^^^');
    message = message.split('<a href="#?caid=').join('@@@__');
    message = message.split(`"><span>@`).join('^^^__');
    message = message.split('</span></a>').join('@@@^^^');

    // TODO: uncomment the following when we will have a page per tag
    // newContent = newContent.split('<a href="/tag/').join('$$$__');
    // newContent = newContent.split(`">#`).join('~~~__');
    // newContent = newContent.split('</a>').join('$$$~~~');
    message = message.split('<a href="#?tagid=').join('$$$__');
    message = message.split(`">#`).join('~~~__');
    message = message.split('</a>').join('$$$~~~');

    return message;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async function asyncTags(query: any, callback: any): any {
    if (!query) return [];

    const globalTags = await BrainHelper.listTags(jwt, wedding?.id, dispatch, setTags);

    const search = async () => {
      const suggestedTags: TagDetail[] = [];
      const suggestion = { id: uuid(), display: query };
      for (const tag of globalTags) {
        const regex = new RegExp(`.*${query}.*`);
        const found = tag.key.match(regex);
        if (found) suggestedTags.push({ id: tag?.id, display: tag.key });
      }
      if (suggestedTags.length > 0) {
        return suggestedTags.concat(suggestion);
      }

      return [suggestion];
    };
    search().then(callback);
  }

  return (
    <div className="feed">
      <div className="postContainer" style={props.isSinglePage ? { paddingBottom: '24px' } : {}}>
        <div className="profileContainer">
          <div className="innerProfileContainer">
            <AvatarProfile
              src={authorImage}
              alt="ProfilePicture"
              classNames="profileContainerImg"
              accountFirstName={fullName?.split(' ')[0] ? fullName?.split(' ')[0] : undefined}
              accountLastName={fullName?.split(' ')[1] ? fullName?.split(' ')[1] : undefined}
            />
            {/*TODO: REMOVE JOHN DOE WHEN DESIGN IS READY*/}
            <div className="profileNameContainer">
              <h3 className="didacticP">{fullName || 'John Doe'}</h3>
              <p>{date}</p>
            </div>
          </div>
          {isEditable ? (
            <div className="post-edit">
              <IonImg
                id={'editImg'}
                src="edit.svg"
                alt="edit"
                onClick={() => {
                  if (props.showPostEditDeleteModal) props.showPostEditDeleteModal(true);
                  if (props.setEditPostCallback) {
                    props.setEditPostCallback(() => () => {
                      setEdit(true);
                      if (props.showPostEditDeleteModal) props.showPostEditDeleteModal(false);
                    });
                  }

                  if (props.setDeletePostCallback) {
                    props.setDeletePostCallback(
                      /* eslint-disable */
                      () => () => deletePost(props?.post?.id, props.wedding?.id, props.userToken),
                    );
                  }
                }}
              />
              {isShowEdit ? (
                <>
                  <div className="comment-edit-dropdown" style={style} ref={wrapperRef}>
                    <button
                      onClick={() => {
                        setEdit(true);
                        setIsShowEdit(false);
                      }}
                    >
                      <img className="imgIcon" src="/icon-edit.svg" alt="edit" /> Edit Post
                    </button>
                    <button onClick={() => deletePost(props.post?.id, props.wedding?.id, props.userToken)}>
                      <img className="imgIcon" src="/icon-delete.svg" alt="delete" /> Remove
                    </button>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="captionArea">
          {edit ? (
            <div className="textAreaContainer" ref={wrapperRef1}>
              <MentionsInput
                className={'mentions textArea'}
                value={getPostMessageReadyForEditing(message)}
                onChange={(event: any) => {
                  if (event.target.value.length >= 250) return;
                  setMessage(event.target.value);
                }}
                placeholder={'Say something...'}
                spellCheck={false}
              >
                <Mention
                  trigger={'@'}
                  data={mentions}
                  markup={'@@@____id__^^^____display__@@@^^^'}
                  style={{
                    backgroundColor: '#daf4fa',
                  }}
                  onAdd={(display) => {
                    setSelectedMentions((mentions: any[]) => [...mentions, display]);
                  }}
                  appendSpaceOnAdd={true}
                />
                <Mention
                  trigger={'#'}
                  data={asyncTags}
                  markup={'$$$____id__~~~____display__$$$~~~'}
                  style={{
                    backgroundColor: '#daf4fa',
                  }}
                  onAdd={async (id, display) => {
                    setSelectedTags(selectedTags.concat({ id: `${id}`, display: display }));
                  }}
                  appendSpaceOnAdd={true}
                />
              </MentionsInput>
              <button
                onClick={() => {
                  updatePost(message, props.post?.id, props.wedding?.id, props.userToken).then(() => setEdit(false));
                  setIsShowEdit(false);
                }}
              >
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={'/assets/icons/icon-send-post.svg'}
                  alt="Send Message"
                />
              </button>
            </div>
          ) : (
            <div className={'post-caption'}>
              <ShowMoreText
                /* Default options */
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="my-anchor-css-class"
                expanded={false}
                truncatedEndingComponent={'... '}
              >
                {parse(props.post.message, {
                  replace: (domNode: any) => {
                    if (domNode.name === 'a') {
                      const node = domNode.children[0];
                      let data = node?.data;
                      if (!data) data = '@';
                      return (
                        <Link to={domNode.attribs.href} className={data[0] === '#' ? 'hashtagLink' : 'mentionLink'}>
                          {data === '@' ? (
                            <span style={{ wordBreak: 'break-all' }}>{node?.children[0].data}</span>
                          ) : (
                            node.data
                          )}
                        </Link>
                      );
                    }
                  },
                }) || ''}
              </ShowMoreText>
            </div>
          )}
        </div>
        <div className="content">
          {!completedFile ? (
            <div className="loadingPostImage">
              {!initFileLoading ? (
                <Lottie options={defaultOptions1} height={200} width={200} />
              ) : (
                <Lottie options={defaultOptions2} height={200} width={200} />
              )}
            </div>
          ) : (
            // {file !== '' ? (
            <img
              loading="lazy"
              onContextMenu={(e) => e.preventDefault()}
              onDoubleClick={() => {
                if (!heart)
                  createInteraction(
                    true,
                    props.wedding?.id,
                    props.post?.id,
                    props.account?.connectedAccountId || props.account?.connectedAccountPhoneId,
                    postInteractions,
                    props.userToken,
                  );
              }}
              src={file}
              alt={'test'}
            />
          )}
        </div>
        <div className="interaction">
          <div>
            <LoveButton
              heart={heart}
              onClick={async () => {
                if (heart) {
                  await deleteInteraction(
                    props.wedding?.id,
                    props.post?.id,
                    props.account?.connectedAccountId,
                    props.account?.connectedAccountPhoneId,
                    postInteractions,
                    props.userToken,
                    dispatch,
                    setPostInteractionReducer,
                  );
                } else {
                  await createInteraction(
                    false,
                    props.wedding?.id,
                    props.post?.id,
                    props.account?.connectedAccountId || props.account?.connectedAccountPhoneId,
                    postInteractions,
                    props.userToken,
                  );
                }
              }}
            />
            <button
              onClick={() => {
                props.setShowLikesModal(true);
                props.setPostInteractions(postInteractions);
              }}
            >
              <p className={heart ? 'loves-loved' : 'loves'}>
                {postInteractions !== undefined ? postInteractions.length : '0'} Loves
              </p>
            </button>
          </div>
          <div>
            <button
              onMouseDown={(e) => {
                if (e.button === 2 || props.isSinglePage) return;
                props.showCommentsModal();
                props.setCurrentPost(props.post.id);
                props.setCommentsForModal(comments);
                props.setCreateCommentCallback(
                  () => (input: string, selectedCommentMentions: string[], selectedCommentTags: TagDetail[]) => {
                    createComment(input, selectedCommentMentions, selectedCommentTags);
                  },
                );
                props.setLikesAndCommentsCounters([postInteractions.length, comments.length]);
                props.setPostInteractions(postInteractions);
              }}
              style={{ display: 'flex', cursor: props.isSinglePage ? 'default' : 'pointer' }}
            >
              <img
                onContextMenu={(e) => e.preventDefault()}
                loading="lazy"
                src={content ? 'assets/icons/icon-comments.svg' : 'assets/icons/icon-comments.svg'}
                width={24}
                height={24}
                alt="comment"
              />
              <p className={'comments'}>{comments !== undefined ? comments.length : '0'} Comments</p>
            </button>
          </div>
        </div>
        <div id="comments" className={'commentsContainer'}>
          <div>
            {comments.length > 0}
            <div className={'inputCommentArea'}>
              {Array.isArray(last2comments) &&
                last2comments.map((c, index) => {
                  return (
                    <div key={index}>
                      {comment(c, accounts, connectedAccounts, props.wedding?.id, props.userToken, props.profile)}
                    </div>
                  );
                })}
            </div>
            {props.isSinglePage && (
              <div
                style={{
                  marginTop: 8,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderTop: '1px solid #E5E5E5',
                }}
              >
                <div style={{ width: '100%', paddingTop: 8, paddingBottom: 8 }}>
                  <div className={'inputCommentContainer'}>
                    <MentionsInput
                      allowSuggestionsAboveCursor={true}
                      className={'mentions inputComment editComment'}
                      value={reply}
                      onChange={(event: any) => {
                        if (event.target.value.length >= 250) return;
                        setReply(event.target.value);
                      }}
                      placeholder={'Say Something...'}
                      spellCheck={false}
                    >
                      <Mention
                        trigger={'@'}
                        data={mentions}
                        markup={'@@@____id__^^^____display__@@@^^^'}
                        style={{
                          backgroundColor: '#daf4fa',
                        }}
                        onAdd={(display) => {
                          setSelectedCommentMentions((mentions: any[]) => [...mentions, display]);
                        }}
                        appendSpaceOnAdd={true}
                      />
                      <Mention
                        trigger={'#'}
                        data={asyncTags}
                        markup={'$$$____id__~~~____display__$$$~~~'}
                        style={{
                          backgroundColor: '#daf4fa',
                        }}
                        onAdd={async (id, display) => {
                          setSelectedCommentTags(selectedCommentTags.concat({ id: `${id}`, display: display }));
                        }}
                        appendSpaceOnAdd={true}
                      />
                    </MentionsInput>
                    <button
                      onClick={() => {
                        createComment(reply, selectedCommentMentions, selectedCommentTags);
                        setReply('');
                        // props.setShowCommentsModal(false);
                      }}
                    >
                      <img
                        onContextMenu={(e) => e.preventDefault()}
                        src={'/assets/icons/icon-send-post.svg'}
                        alt="Send Message"
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/*<div className={'inputCommentContainer'}>*/}
            {/*<TextareaAutosize*/}
            {/*  className="inputComment"*/}
            {/*  placeholder={'Type Something...'}*/}
            {/*  onChange={(event) => {*/}
            {/*    const tar: any = event.target;*/}
            {/*    setReply(tar.value);*/}
            {/*  }}*/}
            {/*  value={reply}*/}
            {/*/>*/}
            {/*<MentionsInput*/}
            {/*  className={'mentions inputComment'}*/}
            {/*  value={reply}*/}
            {/*  onChange={(event: any) => {*/}
            {/*    setReply(event.target.value);*/}
            {/*  }}*/}
            {/*  placeholder={'Type Something...'}*/}
            {/*  spellCheck={false}*/}
            {/*>*/}
            {/*  <Mention*/}
            {/*    trigger={'@'}*/}
            {/*    data={mentions}*/}
            {/*    markup={'@@@____id__^^^____display__@@@^^^'}*/}
            {/*    style={{*/}
            {/*      backgroundColor: '#daf4fa',*/}
            {/*    }}*/}
            {/*    onAdd={(display) => {*/}
            {/*      setSelectedCommentMentions((mentions: any[]) => [...mentions, display]);*/}
            {/*    }}*/}
            {/*    appendSpaceOnAdd={true}*/}
            {/*  />*/}
            {/*  <Mention*/}
            {/*    trigger={'#'}*/}
            {/*    data={asyncTags}*/}
            {/*    markup={'$$$____id__~~~____display__$$$~~~'}*/}
            {/*    style={{*/}
            {/*      backgroundColor: '#daf4fa',*/}
            {/*    }}*/}
            {/*    onAdd={async (id, display) => {*/}
            {/*      setSelectedCommentTags(selectedTags.concat({ id: `${id}`, display: display }));*/}
            {/*    }}*/}
            {/*    appendSpaceOnAdd={true}*/}
            {/*  />*/}
            {/*</MentionsInput>*/}
            {/*<button*/}
            {/*  onClick={() => {*/}
            {/*    createComment(reply).then(() => setEdit(false));*/}
            {/*    setIsShowEdit(false);*/}
            {/*    setReply('');*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <img onContextMenu={(e) => e.preventDefault()} src={'/icon-send.svg'} alt="Send Message" />*/}
            {/*</button>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostComponent;
