import { Backend } from '../../config/backend';
import {
  GetDefaultRolesRequest,
  GetDefaultRolesResponse,
  LoginRequest,
  LoginResponse,
  PublicRemoteLoggerRequest,
  PublicRemoteLoggerResponse,
} from '../protobuf/v2/auth/auth_types_pb';

export class AuthHelper {
  private static client = Backend.authClientV2;

  public static async loginRegisterFirebaseUser(loginRequest: LoginRequest): Promise<LoginResponse | null> {
    return await AuthHelper.client.publicLoginAndRegister(loginRequest);
  }
  public static async publicGetDefaultRolesPromise(
    requestMessage: GetDefaultRolesRequest,
  ): Promise<GetDefaultRolesResponse | null> {
    return await AuthHelper.client.publicGetDefaultRoles(requestMessage);
  }

  public static async publicRemoteLog(
    requestMessage: PublicRemoteLoggerRequest,
  ): Promise<PublicRemoteLoggerResponse | null> {
    try {
      return await AuthHelper.client.publicRemoteLogger(requestMessage);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
