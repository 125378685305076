import { CustomButtonImgAndIcon } from '../../../shared';
import React, { useEffect } from 'react';
import templateStyles from '../TheArtist.module.scss';
import { createTheme, Switch, ThemeProvider } from '@mui/material';
import ReactQuill from 'react-quill';

type InputComponentProps = {
  componentName: string;
  componentIndex: number;
  componentProps: any;
  updateComponentProps: any;
  autoSave: any;
};

const InputTheArtistQNAComponent = ({
  // componentName,
  componentIndex,
  componentProps,
  updateComponentProps,
  autoSave,
}: InputComponentProps): any => {
  // const [toggle, setToggle] = useState<boolean>(false);
  const emptyComponentValues = {
    questions: [],
  };

  useEffect(() => {
    let tempProps = componentProps[componentIndex];
    if (tempProps === undefined) {
      tempProps = emptyComponentValues;
      updateComponentProps(tempProps, componentIndex);
    }
  }, []);
  const updateProps1 = (input: any, key: string) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex]) {
      tempProps[componentIndex][key] = input;
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex][key] = input;
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };
  const updateProps = (input: any, key: string, index: number) => {
    const tempProps = JSON.parse(JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)));
    if (tempProps[componentIndex] && tempProps[componentIndex]['questions']) {
      if (tempProps[componentIndex]['questions'][index]) {
        tempProps[componentIndex]['questions'][index][key] = input;
      } else {
        tempProps[componentIndex]['questions'][index] = new Object();
        tempProps[componentIndex]['questions'][index][key] = input;
      }
    } else {
      tempProps[componentIndex] = new Object();
      tempProps[componentIndex]['questions'] = new Array<any>();
      tempProps[componentIndex]['questions'].push({ title: '', description: '' });
    }
    updateComponentProps(tempProps[componentIndex], componentIndex);
    autoSave();
  };

  const createQuestionWithIndex = (index: number) => {
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['questions']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      tempProps['questions'].splice(index, 0, { title: '', description: '' });
      updateComponentProps(tempProps, componentIndex);
      autoSave();
    }
  };

  const removeQuestionWithIndex = (index: number) => {
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['questions']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      if (index <= tempProps['questions'].length) {
        tempProps['questions'].splice(index, 1);
      }
      updateComponentProps(tempProps, componentIndex);
      autoSave(true);
    }
  };

  const createQuestion = () => {
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['questions']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      tempProps['questions'].push({ title: '', description: '' });
      updateComponentProps(tempProps, componentIndex);
      autoSave(true);
    }
  };

  const moveDown = (index: number) => {
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['questions']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const element = tempProps['questions'][index];
      tempProps['questions'].splice(index, 1);
      tempProps['questions'].splice(index + 1, 0, element);
      updateComponentProps(tempProps, componentIndex);
      autoSave(true);
    }
  };
  const moveUp = (index: number) => {
    if (componentProps && componentProps[componentIndex] && componentProps[componentIndex]['questions']) {
      const tempProps = JSON.parse(
        JSON.stringify(componentProps[componentIndex], (_, v) => (typeof v === 'bigint' ? v.toString() : v)),
      );
      const element = tempProps['questions'][index];
      tempProps['questions'].splice(index, 1);
      tempProps['questions'].splice(index - 1, 0, element);
      updateComponentProps(tempProps, componentIndex);
      autoSave(true);
    }
  };

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            height: 32,
            width: 52,
            padding: 0,
            border: '1px solid #DAD8D9',
            borderRadius: '50px',
          },
          switchBase: {
            // Controls default (unchecked) color for the thumb
            padding: 3,
          },
          thumb: {
            color: '#FFFFFF',
            width: 24,
            height: 24,
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: '.7 !important',
            height: 32,
            width: 52,
            backgroundColor: '#835978 !important',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              opacity: '1 !important',
            },
          },
        },
      },
    },
  });

  if (!componentProps[componentIndex]) {
    return null;
  }
  return (
    <section style={{ marginBottom: '64px' }}>
      <div className={templateStyles.flex}>
        <h2 className={templateStyles.didacticH2}>Questions Section</h2>
        <ThemeProvider theme={theme}>
          <Switch
            checked={componentProps[componentIndex]['visible']}
            onChange={(event, checked) => {
              updateProps1(checked, 'visible');
            }}
          />
        </ThemeProvider>
      </div>
      <p className={templateStyles.didacticP} style={{ paddingBottom: '24px' }}>
        Now is the time to save yourself and your guests some trouble, and avoid the “parking” question. I’m sure you
        have more.
      </p>
      <h3 className={templateStyles.fridayH3} style={{ paddingBottom: '8px' }}>
        Section Title*
      </h3>
      <input
        style={{ marginBottom: '24px', width: '100%' }}
        className={templateStyles.input}
        value={componentProps[componentIndex]['title']}
        defaultValue={'Quick Questions'}
        onChange={(e) => updateProps1(e.target.value, 'title')}
      />
      {componentProps &&
        componentProps[componentIndex] &&
        componentProps[componentIndex]['questions'] &&
        componentProps[componentIndex]['questions'].map((e: any, index: number) => {
          return (
            <div key={index} style={{ paddingBottom: '32px' }}>
              <h3 className={templateStyles.fridayH3} style={{ marginBottom: '8px' }}>
                Question #{String(Number(String(index)) + 1)}
              </h3>
              <input
                style={{ marginBottom: '24px' }}
                onChange={(e) => {
                  updateProps(e.target.value, 'title', index);
                }}
                value={componentProps[componentIndex]['questions'][index]['title']}
                className={templateStyles.input + ' ' + templateStyles.wfill}
              />
              <h3 className={templateStyles.fridayH3} style={{ marginBottom: '8px' }}>
                Answer #{String(Number(String(index)) + 1)}
              </h3>
              <ReactQuill
                theme="snow"
                onChange={(e) => updateProps(e, 'description', index)}
                value={componentProps[componentIndex]['questions'][index]['description']}
              />
              {/* <textarea
                style={{ minHeight: '56px', minWidth: '100%', maxWidth: '100%' }}
                onChange={(e) => {
                  updateProps(e.target.value, 'description', index);
                }}
                value={componentProps[componentIndex]['questions'][index]['description']}
                className={templateStyles.input + ' ' + templateStyles.wfill}
              /> */}
              <div style={{ paddingTop: '16px', display: 'flex', justifyContent: 'space-between', gap: 8 }}>
                <CustomButtonImgAndIcon
                  title="New QA"
                  icon="plus"
                  callback={() => createQuestionWithIndex(index + 1)}
                />
                {index > 0 && (
                  <CustomButtonImgAndIcon title="" icon="arrow_up" callback={() => moveUp(index)} isSmall />
                )}
                {index < componentProps[componentIndex]['questions'].length - 1 && (
                  <CustomButtonImgAndIcon title="" icon="arrow_down" callback={() => moveDown(index)} isSmall />
                )}

                <CustomButtonImgAndIcon
                  title={`Remove QA #${index + 1}`}
                  icon="trash"
                  callback={() => removeQuestionWithIndex(index)}
                />
              </div>
            </div>
          );
        })}
      {componentProps &&
        componentProps[componentIndex] &&
        componentProps[componentIndex]['questions'] &&
        componentProps[componentIndex]['questions'].length == 0 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <CustomButtonImgAndIcon title="New QA" icon="plus" callback={() => createQuestion()} />
          </div>
        )}
    </section>
  );
  return null;
};

export default InputTheArtistQNAComponent;
