import React, { useState } from 'react';
import { logger } from '../../config/Logger';

const ImageWithFallback: React.FC<{ src: string; fallbackSrc: string; alt: string }> = ({ src, fallbackSrc, alt }) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [hasError, setHasError] = useState(false);

  const handleImageError = () => {
    if (!hasError) {
      // Ensure we don't fall into an infinite error loop if fallbackSrc also fails
      setImageSrc(fallbackSrc);
      setHasError(true);
    }
  };

  return (
    <img
      style={!hasError ? { width: '100%', height: '100%' } : {}}
      src={imageSrc}
      alt={alt}
      onLoad={() => logger.debug({ message: 'Image loaded successfully!', functionName: 'ImageWithFallback' })}
      onError={handleImageError}
    />
  );
};

export default ImageWithFallback;
