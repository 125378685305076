import React from 'react';
import ColorDarknessChecker from '../../lib/ColorDarknessChecker/ColorDarknessChecker';

function stringToColour(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Avatar = (props: any): JSX.Element => {
  const letters = String(props?.initials).split(' ');
  const Letter1 = letters.length > 0 ? letters[0] : '';
  const Letter2 = letters.length > 1 ? letters[1] : '';

  const colorToUse = stringToColour(String(Letter1[0] + Letter2[0]));

  return (
    <div
      style={{
        width: props?.size,
        height: props?.size,
        borderRadius: '50%',
        backgroundColor: String(colorToUse),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p style={{ color: ColorDarknessChecker(colorToUse) === 'light' ? 'black' : 'white' }}>
        {String(Letter1[0] ? Letter1[0] : '').toUpperCase() +
          String(Letter2[0] ? Letter2[0] : Letter1.length > 2 ? Letter1[1] : '').toUpperCase()}
      </p>
    </div>
  );
};

export default Avatar;
