/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Fragment } from 'react';
import { RSVPEvent } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Icon } from '../../../shared';
import styles from './ImYoursScheduleComponent.module.scss';
interface ScheduleComponentProps {
  title?: string;
  schedule?: Array<RSVPEvent>;
  mainEvent?: string;
  description?: string;
  ImYoursScheduleComponentCustomMarriageCode?: string;
}

const defaultValues = {
  title: 'Schedule',
  mainEvent: '',
  description:
    'This part will be filled when your planning \n' +
    'is completed. Every public event you create will \n' +
    'appear here.',
  graphicBig: '/templates/ImYours/schedule_graphic_big.svg',
  graphicSmall: '/templates/ImYours/schedule_graphic_small.svg',
  graphicSmaller: '/templates/ImYours/schedule_graphic_smaller.svg',
  ImYoursScheduleComponentCustomMarriageCode: '/home',
};

const ImYoursScheduleComponent = ({
  title,
  schedule,
  description,
  mainEvent = '',
  ImYoursScheduleComponentCustomMarriageCode,
}: ScheduleComponentProps): JSX.Element => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const eventsGrouped = new Map<string, Array<RSVPEvent>>();

  schedule?.map((eventRSVP) => {
    const tempArray = eventsGrouped.get(dayjs(eventRSVP.startTimestamp).format('dddd, DD MMMM YYYY'));
    if (tempArray !== undefined) {
      eventsGrouped.set(
        dayjs(eventRSVP.startTimestamp)
          .tz(eventRSVP.location?.externalQuickCode ? eventRSVP.location?.externalQuickCode : dayjs.tz.guess())
          .format('dddd, DD MMMM YYYY'),
        [...tempArray, eventRSVP].sort(
          (a, b) => Number(dayjs(a.startTimestamp).format('HHmm')) - Number(dayjs(b.startTimestamp).format('HHmm')),
        ),
      );
    } else {
      eventsGrouped.set(
        dayjs(eventRSVP.startTimestamp)
          .tz(eventRSVP.location?.externalQuickCode ? eventRSVP.location?.externalQuickCode : dayjs.tz.guess())
          .format('dddd, DD MMMM YYYY'),
        [eventRSVP].sort(
          (a, b) => Number(dayjs(a.startTimestamp).format('HHmm')) - Number(dayjs(b.startTimestamp).format('HHmm')),
        ),
      );
    }
  });

  const renderDate = () => {
    let i = 0;
    let tempSchedule: JSX.Element[] = [];
    eventsGrouped.forEach((events, date) => {
      const tempEvents: JSX.Element[] = [];
      tempEvents.push(
        <h2 key={i + ` ` + (Math.random() % 100)} style={{ textAlign: 'center' }}>
          {date.split(',')[0] + ','}
          <br />
          {date.split(',')[1]}
        </h2>,
      );
      events.forEach((e) => {
        tempEvents.push(renderEvent(e, date));
      });

      tempSchedule.push(
        <div
          key={i + ` ` + (Math.random() % 100)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'space-between',
            gap: 32,
            textAlign: 'center',
            paddingInline: '50px',
            paddingBlock: '20px',
          }}
        >
          {tempEvents}
        </div>,
      );
      i++;
    });
    tempSchedule = tempSchedule.sort(
      (a: JSX.Element, b: JSX.Element) =>
        Number(dayjs(String(a.key)).format('YYYYMMDD')) - Number(dayjs(String(b.key)).format('YYYYMMDD')),
    );
    return tempSchedule;
  };

  const renderEvent = (e: RSVPEvent, date: string) => {
    return (
      <Fragment key={String(e.id) + String(date)}>
        <div key={String(e.id) + String(date)}>
          <div key={String(date) + ` ` + (Math.random() % 100)}>
            {dayjs(e.startTimestamp)
              .tz(e.location?.externalQuickCode ? e.location?.externalQuickCode : dayjs.tz.guess())
              .format('HH:mm')}{' '}
            -
            {dayjs(e.endTimestamp)
              .tz(e.location?.externalQuickCode ? e.location?.externalQuickCode : dayjs.tz.guess())
              .format('HH:mm')}
          </div>
          <div>{e.name}</div>
          <a
            key={String(date) + ` ` + (Math.random() % 100)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '4px',
              paddingTop: 4,
            }}
            target="blank"
            href={`https://maps.google.com/?q=${e?.location?.point?.latitude},${e?.location?.point?.longitude}`}
          >
            <Icon name="location" size="s" />
            <p
              style={{
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {e?.location?.name ? e?.location?.name + ', ' + e?.location?.address : e?.location?.address}
            </p>
          </a>
        </div>
      </Fragment>
    );
  };

  return (
    <section className={styles.ScheduleComponentWrapper}>
      <div className={styles.graphicsWrapper}>
        <img src={defaultValues.graphicBig} alt="schedule big graphic" />
        <img src={defaultValues.graphicBig} alt="schedule big graphic" />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyItems: 'center',
        }}
      >
        <h2 className={styles.title} style={{ width: '100%' }}>
          {String(title ? title : defaultValues.title)}
        </h2>
        {mainEvent && new Date(mainEvent).getTime() - new Date().getTime() > 0 && (
          <p>{Math.ceil((new Date(mainEvent).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} days to go</p>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <img src={defaultValues.graphicSmaller} style={{ maxWidth: '30px' }} alt="schedule smaller graphic" />
        <section className={styles.ScheduleComponent}>
          <img src={defaultValues.graphicSmall} style={{ maxWidth: '100px' }} alt="schedule small graphic" />
          {schedule ? <Fragment>{renderDate()}</Fragment> : <p>{defaultValues.description}</p>}
          <img src={defaultValues.graphicSmall} style={{ maxWidth: '100px' }} alt="schedule small graphic" />
        </section>
        <img
          src={defaultValues.graphicSmaller}
          style={{ maxWidth: '30px', transform: 'scaleY(-1)' }}
          alt="schedule smaller graphic"
        />
      </div>
      <div className={styles.schedule} dangerouslySetInnerHTML={{ __html: description ? description : '' }} />
    </section>
  );
};

export default ImYoursScheduleComponent;
