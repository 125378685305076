import ui from './ui.json';

export interface SocialEventType {
  startPlanningEventType: string;
  assetsRoot: string;
  ceremonyName: string;
  icon: string;
}
interface uiType {
  wedding: SocialEventType;
  baptism: SocialEventType;
  party: SocialEventType;
}
export enum EventType {
  wedding = 'wedding',
  baptism = 'baptism',
  party = 'party',
}

const map: uiType = ui;

const GetUI = (type: EventType): SocialEventType => {
  switch (type) {
    case EventType.wedding:
      return map.wedding;
    case EventType.baptism:
      return map.baptism;
    case EventType.party:
      return map.party;
    default:
      return map.wedding;
  }
};

export default GetUI;
