import React, { useEffect, useRef, useState } from 'react';
import styles from './Guests.module.scss';
import Icon from '../../../shared/Icon';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectJwt, selectUser } from '../../../app/redux/authorization.slice.reducer';
import {
  addInvitedVisitors,
  removeInvitedVisitors,
  selectInvitedVisitors,
  selectIsHelpOn,
  selectWedding,
  setInvitedVisitors,
  setIsHelpOn,
  setRsvpEventsReducer,
  setTaxonomies,
  setTaxonomyOptions,
} from '../../../app/redux/wedding.slice.recuder';
import { useNavigate } from 'react-router-dom';
import {
  AllowedVisitor,
  RSVPEvent,
  TaxonomyOption,
  TotalAllowedVisitorsResponse,
  TotalAttendanceResponse,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import Table from './table/Table';
import GuestHeader from './guestHeader/GuestHeader';
import { ToastContainer } from 'react-toastify';
import Lottie from 'react-lottie';
import Card from './card/Card';
import location from '../../../loader-animation.json';
import Toolbox from './toolbox/Toolbox';
import GenericConfirmModal from '../../modals/GenericConfirmModal';
import QuickActions from './quickActions/QuickActions';
import UserDetails from './userDetails/UserDetails';
import GuestModal from './guestModal/GuestModal';
import EmailInvitationModal from './emailInvitationModal/EmailInvitationModal';
import FiltersModal from './filtersModal/FiltersModal';
import ImportedGuestsModal from './ImportedGuestsModal.tsx/ImportedGuestsModal';
import { ScreenType, useTypeOfScreen } from '../../../hooks';
import { useSetState } from 'react-use';
import { AppState } from '../context/types';
import { brandOperationalAccent, mainBackgroundAccent, secondaryStrongAccent } from '../../../styles/colors';
import Joyride, { CallBackProps } from 'react-joyride';
import { Tooltip } from '../tutorial/TutorialComponents';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { logger } from '../../../config/Logger';
import AddToEventModal from './addToEventModal/AddToEventModal';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Guests = (): JSX.Element => {
  const isHelpOn = useSelector(selectIsHelpOn);
  const jwt = useSelector(selectJwt);
  const wedding = useSelector(selectWedding);
  const user = useSelector(selectUser);
  const reduxInvitedVisitors = useSelector(selectInvitedVisitors);
  const [isLoading, setIsLoading] = useState(false);
  const [allVisitors, setAllVisitors] = useState<AllowedVisitor[]>([]);
  const [attendingGuests, setAttendingGuests] = useState<number>(0);
  const [attendingPlusOnes, setAttendingPlusOnes] = useState<number>(0);
  const [allEvents, setAllEvents] = useState<RSVPEvent[]>([]);
  const [query, setQuery] = useState<string>('');
  const [isActiveSendModal, setIsActiveSendModal] = useState(false);
  const [isActiveAddToeEventModal, setIsActiveAddToeEventModal] = useState(false);
  const [sendOption, setSendOption] = useState('');
  const [eventSelector, setEventSelector] = useState('all');

  const requestNumber = useRef(0);

  const [selectedGuestsObjects, setSelectedGuestsObjects] = useState(new Map<string, AllowedVisitor>());
  const [selectedGuest, setSelectedGuest] = useState<AllowedVisitor | undefined>(undefined);
  const [selectedTaxonomyOptions, setSelectedTaxonomyOptions] = useState<Array<TaxonomyOption>>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showImportedGuestsModal, setShowImportedGuestsModal] = useState(false);

  const [totalAttendance, setTotalAttendance] = useState<Map<string, TotalAttendanceResponse | undefined>>(new Map());
  const [totalVisitors, setTotalVisitors] = useState<TotalAllowedVisitorsResponse | undefined>();

  const [showGuestModal, setShowGuestModal] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [filters, setFilters] = useState<{
    attendance: 0 | 1 | 2 | 3;
    status: 0 | 1 | 2 | 3 | 4;
    selectedTaxonomies: TaxonomyOption[];
  }>({
    attendance: 0,
    status: 0,
    selectedTaxonomies: [],
  });

  const timeoutId = useRef<number | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const typeOfScreen = useTypeOfScreen();

  const sideViewRef = useRef(null);
  // useOutsideClick(sideViewRef, () => setSelectedGuest(undefined));

  const listEvents = async () => {
    const response = await BrainHelper.listRSVPEvents(jwt, wedding.id, dispatch, setRsvpEventsReducer);
    if (response) {
      setAllEvents(response.rsvpEvents);
      response.rsvpEvents.forEach((e) => {
        listAttendance(e.id);
      });
    }
  };

  async function listAttendance(rsvpId: string) {
    const attendance = await BrainHelper.totalAttendance(rsvpId, jwt);
    setTotalAttendance((prevState) => {
      return prevState.set(rsvpId, attendance);
    });
  }

  async function listTotalAttendance(weddingId: string) {
    const total = await BrainHelper.totalAllowedVisitors(weddingId, jwt);
    setTotalVisitors(total);
  }

  const helperTaxonomies = async () => {
    await BrainHelper.listTaxonomyOptions(wedding.defaultTaxonomyId, jwt, dispatch, setTaxonomyOptions);
    await BrainHelper.listTaxonomies(wedding.id, jwt, dispatch, setTaxonomies);
  };

  const addTagsToRows = async (newTaxonomies: TaxonomyOption[], removedTaxonomies: TaxonomyOption[]) => {
    for (const [selectedGuestId] of selectedGuestsObjects) {
      if (newTaxonomies.length > 0) {
        try {
          await BrainHelper.attachTaxonomyOptionsToAllowedVisitor(
            newTaxonomies.map((option) => option.id),
            selectedGuestId,
            jwt,
            wedding.id,
            dispatch,
            addInvitedVisitors,
          );
          await BrainHelper.detachTaxonomyOptionsFromAllowedVisitors(
            jwt,
            wedding.id,
            selectedGuestId,
            removedTaxonomies.map((option) => option.id),
            dispatch,
            addInvitedVisitors,
          );
        } catch (e) {
          logger.error({ message: e, functionName: 'Guests.addTagsToRows' });
        }
      }
    }
  };

  const editRows = () => {
    setShowGuestModal((prevState) => !prevState);
  };

  const deleteRows = () => {
    if (selectedGuestsObjects.size > 0) {
      const tempArr = Array<string>();
      const tempObjs = Array<AllowedVisitor>();
      for (const [selectedGuestId, object] of selectedGuestsObjects) {
        if (object.email === user.email) {
          continue;
        }
        if (object.isHost) {
          continue;
        }
        tempArr.push(selectedGuestId);
        tempObjs.push(object);
      }

      if (tempObjs.length > 1) {
        BrainHelper.deleteAllowedVisitors(wedding.id, tempObjs, jwt, dispatch, removeInvitedVisitors).then(() => {
          setSelectedGuestsObjects(new Map());
        });
      } else {
        BrainHelper.deleteAllowedVisitor(wedding.id, tempObjs[0], jwt, dispatch, removeInvitedVisitors).then(() => {
          setSelectedGuestsObjects(new Map());
        });
      }
      setShowDeleteModal(false);
    }
  };

  const toggleImportModal = () => {
    setShowImportedGuestsModal((prevState) => !prevState);
  };

  useEffect(() => {
    if (!wedding || wedding.id.trim() === '') navigate('/');
    listTotalAttendance(wedding.id);
    listEvents();
    helperTaxonomies();
  }, []);

  useEffect(() => {
    let isCancelled = false;
    requestNumber.current += 1;
    const currentRequest = requestNumber.current;

    setIsLoading(true);
    const process = async () => {
      setSelectedGuestsObjects(new Map());
      setSelectedGuest(undefined);
      // filters,
      const response = await BrainHelper.getAllowedVisitors(
        jwt,
        wedding.id,
        query,
        filters,
        undefined,
        eventSelector === 'all' ? undefined : eventSelector,
        undefined,
        undefined,
        dispatch,
        setInvitedVisitors,
      );
      if (!isCancelled && currentRequest === requestNumber.current) {
        setAllVisitors(response.allowedVisitors);
        const attendingPlusOnes = response.allowedVisitors
          .map((guest) => {
            if (guest) {
              const mapi = guest.attendToRsvpEvents.map((e) => {
                if (e.attend) return Number(e?.plusOnes) || 0;
                else return 0;
              });
              const nonZero = mapi.filter((e) => e > 0);
              const sum = mapi.reduce((a, b) => a + b, 0);
              if (nonZero.length === 0) return 0;
              else return sum / nonZero.length;
            } else return 0;
          })
          .reduce((accum, current) => {
            if (current) return accum + current;
            else return accum;
          }, 0);
        const attending = response.allowedVisitors.reduce((count, visitor) => {
          const attended = visitor.attendToRsvpEvents.some(
            (event) =>
              ((eventSelector === 'all' && event.rsvpEvent?.id === wedding.mainEvent?.id) ||
                event.rsvpEvent?.id === eventSelector) &&
              event.attend,
          );
          return count + (attended ? 1 : 0);
        }, 0);

        setAttendingPlusOnes(Math.floor(attendingPlusOnes));
        setAttendingGuests(Math.floor(attending));
        setIsLoading(false);
      }
    };

    // Clear the previous timeout
    if (timeoutId.current !== null) {
      clearTimeout(timeoutId.current);
    }

    // Set the new timeout
    timeoutId.current = window.setTimeout(process, 1200);

    return () => {
      isCancelled = true;
    };
  }, [eventSelector, query, filters]);

  useEffect(() => {
    setAllVisitors([...reduxInvitedVisitors].filter((el) => !!el));
    if (selectedGuest) setSelectedGuest(reduxInvitedVisitors.find((riv) => riv.id === selectedGuest?.id));
  }, [reduxInvitedVisitors]);

  useEffect(() => {
    if (selectedGuestsObjects.size <= 0) setShowAddTag(false);
  }, [selectedGuestsObjects]);

  const onClose = () => {
    setIsActiveSendModal(false);
    setSelectedGuestsObjects(new Map());
  };

  // helper
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ run, steps, stepIndex }, setState] = useSetState<AppState>({
    run: false,
    stepIndex: 0,
    tourActive: false,
    steps: [
      {
        content: (
          <>
            <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              Invite your guests
            </h3>
            <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              Click here if you want to add one record or import via csv, excel many guest in one go.
            </p>
          </>
        ),
        target: '#inviteGuestButton',
        disableBeacon: true,
      },
      {
        content: (
          <>
            <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              Batch import guests
            </h3>
          </>
        ),
        target: '#importListButton',
      },
      {
        content: (
          <>
            <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              Download our template add your guests details and then upload the final file. After first batch import you
              can still use it if you want to add new guest records but not existing ones as duplicate phones and emails
              are not allowed.
            </p>
          </>
        ),
        target: '#importedGuestModal',
        placement: 'left',
      },
      {
        content: (
          <>
            <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              Send options
            </h3>
            <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              Select guests and send them invitations.
            </p>
          </>
        ),
        target: '#sendGuestButton',
      },
      {
        content: (
          <>
            <h3 className={'fridayH3'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              Send options
            </h3>
            <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              1. After you have setup your main ceremony event then you can select multiple guests to send them Save The
              Date email.
            </p>
            <p className={'didacticP'} style={{ color: mainBackgroundAccent, marginBottom: '16px' }}>
              2. After you have setup your RSVP then you can select multiple guest and send them invitation for your
              wedding.
            </p>
          </>
        ),
        target: '#sendDropdownModal',
      },
    ],
  });

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setState({ run: true, tourActive: true });
    dispatch(setIsHelpOn(true));
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;

    if (action === 'close') {
      dispatch(setIsHelpOn(false));
      setState({ run: false, stepIndex: 0, tourActive: false });
      return;
    }

    if (type === 'step:after' && index === 0 /* or step.target === '#home' */) {
      setState({ run: true, stepIndex: 1 });
      return;
    } else if (type === 'step:after' && index === 1) {
      if (action === 'next') {
        document.getElementById('importListButton')?.click();
        setState({ run: true, stepIndex: 2 });
      } else {
        setState({ run: true, stepIndex: 0 });
      }
      return;
    } else if (type === 'step:after' && index === 2) {
      if (action === 'next') {
        document.getElementById('importedGuestModalClose')?.click();
        setState({ run: true, stepIndex: 3 });
      } else {
        setState({ run: true, stepIndex: 1 });
      }
      return;
    } else if (type === 'step:after' && index === 3) {
      if (action === 'next') {
        document.getElementById('sendGuestButton')?.click();
        setState({ run: true, stepIndex: 4 });
      } else {
        document.getElementById('importListButton')?.click();
        setState({ run: true, stepIndex: 2 });
      }
      return;
    } else if (type === 'step:after' && index === 4) {
      if (action === 'next') {
        setState({ run: true, stepIndex: 5 });
        // navigate(`/dashboard/w/${wedding.path}/overview`);
      } else {
        setState({ run: true, stepIndex: 3 });
      }
      return;
    }

    if (type === 'tour:end' || action === 'reset' || lifecycle === 'complete') {
      setState({ run: false, stepIndex: 0, tourActive: false });
      dispatch(setIsHelpOn(false));
      return;
    }
  };

  // end of helper

  return (
    <>
      <div className={styles.guestsPage + ' ' + styles[`guestsPage${typeOfScreen}`]}>
        <GuestHeader
          setSearch={setQuery}
          setIsActiveSendModal={setIsActiveSendModal}
          setSendOption={setSendOption}
          wedding={wedding}
          setShowGuestModal={setShowGuestModal}
          setSelectedGuest={setSelectedGuest}
        />
        {isActiveSendModal && (
          <EmailInvitationModal
            isActiveSendModal={isActiveSendModal}
            onClose={onClose}
            sendOption={sendOption}
            setSendOption={setSendOption}
            selectedGuestsObjects={selectedGuestsObjects}
            setSelectedGuestsObjects={setSelectedGuestsObjects}
            visitors={allVisitors}
          />
        )}
        {isActiveAddToeEventModal && allEvents.length > 0 && (
          <AddToEventModal
            isActive={isActiveAddToeEventModal}
            onClose={() => {
              setIsActiveAddToeEventModal(false);
              setSelectedGuestsObjects(new Map());
            }}
            selectedGuestsObjects={selectedGuestsObjects}
            setSelectedGuestsObjects={setSelectedGuestsObjects}
            visitors={allVisitors}
            rsvpEvents={allEvents}
          />
        )}
        <div className={styles.guestsEvents}>
          <Card
            title={'Total Guests'}
            body={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <h3 className={'fridayH3'}>
                  {Number(totalVisitors?.total || 0) + Number(totalVisitors?.expectedPlus1s || 0)}
                </h3>{' '}
                <p className={'fridayP'}>{`(${Number(totalVisitors?.expectedPlus1s || 0)} plus ones)`}</p>
              </div>
            }
            svgIcon={'users'}
            active={eventSelector === 'all'}
            fn={() => setEventSelector('all')}
          />
          {allEvents.map((rsvpEvent) => {
            return (
              <Card
                key={rsvpEvent.id}
                title={rsvpEvent.name}
                body={
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <h3 className={'fridayH3'}>
                      {Number(totalAttendance.get(rsvpEvent.id)?.attendingCount || 0) +
                        Number(totalAttendance.get(rsvpEvent.id)?.plus1Sum || 0)}{' '}
                      /&nbsp;
                      {Number(totalAttendance.get(rsvpEvent.id)?.attendingCount || 0) +
                        Number(totalAttendance.get(rsvpEvent.id)?.notAttendingCount || 0) +
                        Number(totalAttendance.get(rsvpEvent.id)?.notRespondedCount || 0) +
                        Number(totalAttendance.get(rsvpEvent.id)?.plus1Sum || 0)}
                    </h3>
                    <p className={'fridayP'}>{`(${Number(
                      totalAttendance.get(rsvpEvent.id)?.plus1Sum || 0,
                    )} plus ones)`}</p>
                  </div>
                }
                svgIcon={wedding.mainEvent?.id === rsvpEvent.id ? 'ceremony' : 'event'}
                active={eventSelector === rsvpEvent.id}
                fn={() => setEventSelector(rsvpEvent.id)}
              />
            );
          })}
        </div>
        <Toolbox
          selectedRows={selectedGuestsObjects}
          selectedTaxonomyOptions={selectedTaxonomyOptions}
          setSelectedTaxonomyOptions={(newTaxonomies: TaxonomyOption[]) => setSelectedTaxonomyOptions(newTaxonomies)}
          addTags={(newTaxonomies: TaxonomyOption[], removedTaxonomies: TaxonomyOption[]) =>
            addTagsToRows(newTaxonomies, removedTaxonomies)
          }
          deleteRows={() => setShowDeleteModal(true)}
          editRows={() => editRows()}
          importRows={() => toggleImportModal()}
          showImportedGuestsModal={showImportedGuestsModal}
          weddingId={wedding.id}
          userToken={jwt}
          query={query}
          setShowFiltersModal={setShowFiltersModal}
          showGuestModal={showGuestModal}
          showFiltersModal={showFiltersModal}
          showAddTag={showAddTag}
          setShowAddTag={setShowAddTag}
        />
        <div style={{ width: '100%', display: 'flex', gap: 16, flexGrow: 1, justifyContent: 'center' }}>
          {(selectedGuest === undefined && typeOfScreen === ScreenType.mobile) || typeOfScreen !== ScreenType.mobile ? (
            !isLoading ? (
              allVisitors.length > 0 ? (
                <Table
                  data={allVisitors}
                  userToken={jwt}
                  wedding={wedding}
                  selectedGuestsObjects={selectedGuestsObjects}
                  setSelectedGuestsObjects={setSelectedGuestsObjects}
                  selectedGuest={selectedGuest}
                  setSelectedGuest={setSelectedGuest}
                  eventSelector={eventSelector}
                />
              ) : (
                <div style={{ width: '100%', height: typeOfScreen !== ScreenType.mobile ? '70vh' : '45vh' }}>
                  No guests found
                </div>
              )
            ) : (
              <div style={{ width: '100%', height: typeOfScreen !== ScreenType.mobile ? '70vh' : '45vh' }}>
                <Lottie options={defaultOptions1} height={100} width={100} />
              </div>
            )
          ) : (
            ''
          )}
          <div
            ref={sideViewRef}
            className={styles.sideView + ' ' + styles[`sideView${typeOfScreen}`] + ' ' + (selectedGuest && styles.show)}
            style={selectedGuest !== undefined ? { padding: 24 } : {}}
          >
            {selectedGuest === undefined ? (
              <QuickActions
                canUseActions={selectedGuestsObjects.size > 0}
                deleteRows={() => setShowDeleteModal(true)}
                setShowAddTag={setShowAddTag}
                setIsActiveSendModal={setIsActiveSendModal}
                setSendOption={setSendOption}
                setIsActiveAddToeEventModal={setIsActiveAddToeEventModal}
              />
            ) : (
              <UserDetails
                guest={selectedGuest}
                userToken={jwt}
                allEvents={allEvents}
                setShowGuestModal={setShowGuestModal}
                onAddToEvent={() => {
                  setIsActiveAddToeEventModal((prevState) => !prevState);
                  setSelectedGuestsObjects(new Map().set(selectedGuest.id, selectedGuest));
                }}
              />
            )}
            {typeOfScreen === ScreenType.mobile && (
              <button
                style={{ borderRadius: 4, borderColor: '#281B24' }}
                className={'btn btnIconSecondary btnXSmall ' + styles.sideViewClose}
                onClick={() => setSelectedGuest(undefined)}
              >
                <Icon name={'close'} size={'s'} color={'#281B24'} />
              </button>
            )}
          </div>
        </div>
        {/*{allVisitors.length > 0 && (*/}
        {/*  <div className={styles.pagination}>*/}
        {/*    <p style={{ textAlign: 'left', padding: '8px', fontWeight: 600 }}>*/}
        {/*      Invited Results: {allVisitors.length} and plus1:*/}
        {/*      {allVisitors.reduce((sum, visitor) => sum + Number(visitor.maxAllowedPlus1), 0)} Total:{' '}*/}
        {/*      {allVisitors*/}
        {/*        .map((guest) => {*/}
        {/*          return guest?.maxAllowedPlus1 || BigInt(0);*/}
        {/*        })*/}
        {/*        .reduce((accumulator, currentValue) => {*/}
        {/*          return accumulator + Number(currentValue);*/}
        {/*        }, 0) + allVisitors.length}*/}
        {/*    </p>*/}
        {/*    <p style={{ textAlign: 'left', padding: '8px', fontWeight: 600 }}>*/}
        {/*      Attending Results: {attendingGuests} and plus1: {attendingPlusOnes} Total:{' '}*/}
        {/*      {attendingGuests + attendingPlusOnes}*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*modals*/}
        {showGuestModal && (
          <GuestModal
            guest={selectedGuest}
            jwt={jwt}
            showGuestModal={showGuestModal}
            setShowGuestModal={setShowGuestModal}
            rsvpEvents={allEvents}
          />
        )}
        {showFiltersModal && (
          <FiltersModal
            isOpen={showFiltersModal}
            setIsOpen={setShowFiltersModal}
            eventSelector={eventSelector}
            filters={filters}
            setFilters={setFilters}
          />
        )}
        {showDeleteModal && (
          <GenericConfirmModal
            setShow={setShowDeleteModal}
            title={'Just a final check'}
            text={'Are you sure you want to delete these visitors?'}
            confirmBtnFunction={() => deleteRows()}
            confirmBtnText={'Delete'}
            confirmBtnIcon={'trash'}
            // html={t}
          />
        )}
        {showImportedGuestsModal ? (
          <ImportedGuestsModal
            show={showImportedGuestsModal}
            setShow={setShowImportedGuestsModal}
            taxonomyId={wedding.defaultTaxonomyId}
            wedding={wedding}
          />
        ) : (
          ''
        )}
      </div>
      {steps && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={isHelpOn}
          stepIndex={stepIndex}
          steps={steps}
          showProgress={true}
          scrollToFirstStep={true}
          tooltipComponent={Tooltip}
          styles={{
            options: {
              arrowColor: secondaryStrongAccent,
              backgroundColor: secondaryStrongAccent,
              primaryColor: brandOperationalAccent,
              textColor: mainBackgroundAccent,
              zIndex: 101,
            },
          }}
        />
      )}
      <button
        onClick={handleClickStart}
        style={{
          position: 'fixed',
          bottom: typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop ? '20px' : '203px',
          right: typeOfScreen === ScreenType.desktop || typeOfScreen === ScreenType.laptop ? '173px' : '2px',

          borderRadius: '28px',
          zIndex: '1000',
          backgroundColor: '#c7adc1',
          borderWidth: 'none',
          borderStyle: 'none',
          paddingRight: '16px',
          paddingLeft: '16px',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <span>Help</span>
      </button>
      <ToastContainer
        closeButton={false}
        style={{ width: 'fit-content', borderRadius: 8 }}
        icon={<Icon name={'accept'} color={'#006150'} size={'s'} />}
        position="bottom-center"
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default Guests;
