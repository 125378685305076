import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import styles from './Profile.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import success from '../../../1127-success.json';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessTokenAfterOTP,
  selectMarriageCode,
  selectProfile,
  selectProfileImages,
  setProfile,
  setProfileImages,
} from '../../../app/redux/authorization.slice.reducer';
import {
  AllowedVisitor,
  ConnectedAccountRef,
  UpdateConnectedAccountRequest,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Avatar } from '../table/Table';
import Icon from '../../../shared/Icon';
import { MobileBottomModal } from '../../../experienceApp';
import { Backend } from '../../../config/backend';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { FirebaseContext } from '../../../context/firebase.context';
import { logger, toJsonString } from '../../../config/Logger';
import ReactTooltip from 'react-tooltip';
import GenericConfirmModal from '../../modals/GenericConfirmModal';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import moment from 'moment-timezone';
import timezoneData from 'moment-timezone/data/meta/latest.json';
import { useTypeOfScreen, ScreenType } from '../../../hooks';
import cleanLocalStorage from '../../../app/helper/CleanLocalStorage';
import firebase from 'firebase';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const DashboardProfile = (): JSX.Element => {
  const typeOfScreen = useTypeOfScreen();
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const marriageCode = useSelector(selectMarriageCode);
  const wedding = useSelector(selectWedding);
  const profile = useSelector(selectProfile);
  const profileImages = useSelector(selectProfileImages);
  const inputFile = useRef<HTMLInputElement>(null);
  const [me, setMe] = useState<AllowedVisitor | undefined>(undefined);
  const [percentage, setPercentage] = useState<number>(0);
  const [accountDetails, setAccountDetails] = useState<ConnectedAccountRef>(
    new ConnectedAccountRef({
      accountFirstName: profile.accountFirstName,
      accountLastName: profile.accountLastName,
      imageProfileUrl: profile.imageProfileUrl,
    }),
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(true);
  // start loading bullets by default
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initFileLoading, setInitFileLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [completedFile, setCompletedFile] = useState<boolean>(true);
  const [fileUrl, setFileurl] = useState<string>(profile.imageProfileUrl);
  const [fileId, setFileId] = useState<string | undefined>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [email, setEmail] = useState<string | undefined>(profile.email);
  const [emailClicked, setEmailClicked] = useState<boolean>(false);
  const [phoneClicked, setPhoneClicked] = useState<boolean>(false);
  const [name, setName] = useState<string | undefined>(`${profile.accountFirstName} ${profile.accountLastName}`);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startingName, setStartingName] = useState<string>(`${profile.accountFirstName} ${profile.accountLastName}`);
  const [nameEditing, setNameEditing] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [phoneData, setPhoneData] = useState<CountryData | Record<string, unknown> | undefined>(undefined);
  const [phoneEditing, setPhoneEditing] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [isSaveBtnHovered, setIsSaveBtnHovered] = useState(false);

  const [isResetBtnHovered, setIsResetBtnHovered] = useState(false);

  const [showBottomModal, setShowBottomModal] = useState<boolean>(false);
  const [showHostReviewModal, setHostReviewModal] = useState<boolean>(false);
  const [showHostDeleteModal, setHostDeleteModal] = useState<boolean>(false);

  const [isAddHostBtnHovered, setIsAddHostBtnHovered] = useState(false);
  const [isAddHostBtnEditing, setIsAddHostBtnEditing] = useState(false);
  const [hostFullName, setHostFullName] = useState<string | undefined>('');
  const [hostEmail, setHostEmail] = useState<string | undefined>('');
  const [hostPhone, setHostPhone] = useState<string | undefined>('');
  const [hostPhoneData, setHostPhoneData] = useState<CountryData | Record<string, unknown> | undefined>(undefined);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorPhone, setErrorPhone] = useState('');

  const [hosts, setHosts] = useState<AllowedVisitor[]>([]);
  const [hostToDelete, setHostToDelete] = useState<AllowedVisitor>();

  const [phoneInputCountry, setPhoneInputCountry] = useState<string>('gr');

  const dispatch = useDispatch();

  useEffect(() => {
    const timeZoneToCountry = new Map<string, string>();
    const countryName = moment.tz.guess(true);
    Object.values(timezoneData.zones).forEach((z) => {
      timeZoneToCountry.set(z.name, z.countries[0]);
    });
    const countryCode = timeZoneToCountry.get(countryName);
    setPhoneInputCountry(countryCode?.toLowerCase() || 'gr');
    getHosts();
    getAllowedVisitorOfMyProfile();
    // get latest profile datails
    BrainHelper.listConnectedAccountDetails(
      userToken,
      [profile.connectedAccountId || profile.connectedAccountPhoneId],
      dispatch,
      null,
    )
      .then((profileResp) => {
        if (Array.isArray(profileResp) && profileResp.length > 0) dispatch(setProfile(profileResp[0]));
      })
      .catch((e) => {
        logger.error({ message: e, functionName: 'DashboardProfile.useEffect' });
      });
  }, []);

  const getHosts = async () => {
    const visitors = await BrainHelper.getAllowedVisitors(userToken, wedding.id, '', undefined, true);
    setHosts(visitors.allowedVisitors);
  };

  const getAllowedVisitorOfMyProfile = async () => {
    const result = await BrainHelper.getInvitedVisitorByPhoneOrEmail(userToken, wedding.id, '', profile.email);
    setMe(result?.allowedVisitors[0]);
    setPhone(result?.allowedVisitors[0].phone);
  };

  useEffect(() => {
    if (profileImages.size > 0) setFileId(profileImages.get(profile?.accountId || profile?.accountPhoneId));
  }, [profile]);

  useEffect(() => {
    if (fileUrl !== profile.imageProfileUrl) updateProfile(userToken);
  }, [fileUrl]);

  const onButtonClick = async () => {
    // `current` points to the mounted file input element
    inputFile.current?.click();
  };

  const updateProfile = async (jwt: string) => {
    const profileRequest = new UpdateConnectedAccountRequest();
    profileRequest.id = profile.connectedAccountId || profile.connectedAccountPhoneId;
    profileRequest.accountId = profile.accountId || profile.accountPhoneId;
    profileRequest.weddingId = profile.weddingId;
    if (name !== undefined && name.trim() !== '') profileRequest.fullname = name;
    profileRequest.imageProfileUrl = fileUrl === '' ? ' ' : fileUrl;
    // if (phone !== undefined && phone.trim() !== '') profileRequest.phoneNumber = phone;
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${jwt}`);
    const responseMessage = await Backend.brainClientV2.updateConnectedAccount(profileRequest, { headers });
    if (responseMessage && responseMessage.connectedAccount) {
      if (profileImages.size > 0) {
        const tempList: any = new Map();
        profileImages.forEach((value, key) => {
          tempList.set(key, value);
        });
        tempList.set(
          responseMessage.connectedAccount.accountId || responseMessage.connectedAccount.accountPhoneId,
          fileId,
        );
        dispatch(setProfileImages(tempList));
      }

      setAccountDetails(responseMessage.connectedAccount);
      dispatch(setProfile(responseMessage.connectedAccount));
      toast('Profile updated!', {
        style: { backgroundColor: '#A3F5E6' },
      });
    }
    if ((phone !== undefined && phone.trim() !== '') || (name !== undefined && name.trim() !== '')) {
      if (me) {
        let finalPhone = phone?.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
        if (phoneData && phone?.split(' ')[0] !== `+${phoneData?.dialCode}`)
          finalPhone = `+${phoneData?.dialCode} ${phone?.slice(1, phone.length)}`;
        await BrainHelper.updateAllowedVisitor(
          jwt,
          me.id,
          wedding.id,
          name ? name.split(' ')[0] : me.firstName,
          name ? name.split(' ')[1] : me.lastName,
          me.email,
          finalPhone ? finalPhone : me.phone,
          Number(me.maxAllowedPlus1.toString() || 0),
          me.invitedToRsvpEvents.map((r) => r.id),
          me.taxonomyOptions.map((t) => t.id),
          me.isHost,
          me.isParent,
          me.groupWithVisitorIds,
        );
      }
    }
  };

  const sendResetPasswordLink = (email: string) => {
    if (validateEmail(email)) {
      const auth = FirebaseContext.getAuth();
      auth
        .sendPasswordResetEmail(email)
        .then((resp) => {
          logger.log({ message: resp });
          toast('An email has been sent to your email to reset your password!', {
            style: { backgroundColor: '#A3F5E6' },
          });
        })
        .catch((error) => {
          toast(error, {
            icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
            style: { backgroundColor: '#F8AAB8' },
          });
          logger.error({ message: error, functionName: 'Profile.sendResetPasswordLink' });
        });
    } else {
      toast('Email is invalid', {
        icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
        style: { backgroundColor: '#F8AAB8' },
      });
    }
  };

  const validateEmail = (email: string) => {
    return Boolean(
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
    );
  };

  const validatePhone = (phone: string) => {
    return /^[+]?[(]?[0-9]{3}[)]?[-|.]?[0-9]{3}[-|.]?[0-9]{4,8}$/im.test(phone);
  };

  const onCheckHostDate = () => {
    let errors = 0;
    if (!hostFullName || hostFullName.length < 1) {
      setErrorName('full name cannot be empty\n');
      errors++;
    }
    if (!hostEmail || hostEmail.length < 1 || !validateEmail(hostEmail)) {
      setErrorEmail('email is invalid');
      errors++;
    }
    if (
      !hostPhone ||
      hostPhone.length < 4 ||
      !validatePhone(hostPhone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''))
    ) {
      setErrorPhone('phone is invalid\n');
      errors++;
    }
    return errors <= 0;
  };

  const onCancel = () => {
    setErrorName('');
    setErrorEmail('');
    setErrorPhone('');
    setHostFullName('');
    setHostEmail('');
    setHostPhone('');
    setIsAddHostBtnEditing(false);
  };
  const logout = () => {
    logger.info({ message: 'clear local storage', functionName: 'Header.logout' });
    firebase.auth().signOut();
    cleanLocalStorage();
    window.location.pathname = '/dashboard';
  };

  const onNewHost = () => {
    if (hostEmail && hostFullName && hostPhone) {
      let finalPhone = hostPhone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
      if (hostPhoneData && hostPhone.split(' ')[0] !== `+${hostPhoneData?.dialCode}`)
        finalPhone = `+${hostPhoneData?.dialCode} ${hostPhone.slice(1, hostPhone.length)}`;
      BrainHelper.createAllowedVisitor(
        userToken,
        wedding.id,
        hostEmail,
        finalPhone,
        hostFullName.split(' ')[0],
        hostFullName.split(' ')[1],
        '',
        '',
        0,
        [],
        [],
        true,
        false,
      )
        .then((e) => {
          if (
            typeof e === 'string' &&
            toJsonString(e).includes('email') &&
            toJsonString(e).includes('already exists')
          ) {
            toast('Email or phone is being used by another user. Please use something else', {
              icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
              style: { backgroundColor: '#F8AAB8' },
            });
            return false;
          } else {
            toast('Host added successfully!', {
              style: { backgroundColor: '#A3F5E6' },
            });
            onCancel();
            setHostReviewModal(false);
          }
        })
        .catch((e) => {
          logger.error({ message: e, functionName: 'InviteGuestModal.onNewHost.createAllowedVisitor' });
          toast('Something went wrong with adding new host. Please try again later.', {
            icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
            style: { backgroundColor: '#F8AAB8' },
          });
          onCancel();
          setHostReviewModal(false);
        });
    }
  };

  const t = (
    <Fragment>
      <p className={'didacticP'}>Are you sure you want to add this host?</p>
      <p className={'didacticP'}>Name: {hostFullName}</p>
      <p className={'didacticP'}>Email: {hostEmail}</p>
      <p className={'didacticP'}>Phone: {hostPhone}</p>
    </Fragment>
  );

  const onDeleteHost = async () => {
    if (hostToDelete !== undefined) await BrainHelper.deleteAllowedVisitors(wedding.id, [hostToDelete], userToken);
    else
      toast('Something went wrong with deleting the selected host. Please try again later.', {
        icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
        style: { backgroundColor: '#F8AAB8' },
      });
    getHosts();
    setHostToDelete(undefined);
    setHostDeleteModal(false);
  };

  return (
    <div className={styles.profileWrapper}>
      <header>
        <h1 className={'didacticH1'}>My Profile</h1>
      </header>
      <hr />
      <section className={styles.flexColumn}>
        <div className={styles.changeImageContainer}>
          <Avatar
            query={''}
            name={`${profile.accountFirstName} ${profile.accountLastName}`}
            userToken={userToken}
            connectedAccountDetails={accountDetails}
            _className={styles.avatar}
          />
          <button className={styles.changeImageButton} onClick={() => onButtonClick()}>
            <Icon name={'image'} size={'s'} color={'#63435B'} />
            <p className={'didacticP'}>Upload Profile Image</p>
          </button>
          <input
            id="file"
            type="file"
            ref={inputFile}
            accept="image/jpeg, image/png, image/tiff, image/heic, image/heif"
            multiple={false}
            style={{ display: 'none' }}
            onChange={async (event) => {
              await Imagehelper.handleImageUpload(
                event,
                userToken,
                marriageCode,
                setPercentage,
                percentage,
                setLoading,
                setFileurl,
                setFileId,
                setCompletedFile,
                false,
              );
              // enable loading
              setCompletedFile(false);
            }}
          />
        </div>
        <div className={styles.floatingInputContainer + ' ' + styles.floatingInputContainerDisabled}>
          <input
            className={styles.fieldInput}
            id="inputEmail"
            name="inputEmail"
            type="text"
            placeholder=" "
            // disabled={true}
            value={email}
          />
          <label htmlFor={'inputEmail'} className={styles.floatingInputPlaceholder}>
            Email
          </label>
          <div
            className={styles.floatingInputEdit}
            tabIndex={0}
            role={'button'}
            onMouseDown={() => setEmailClicked((prevState) => !prevState)}
          >
            <button
              className={styles.bordered + ' ' + (emailClicked ? styles.clicked : '')}
              data-tip="If you wish to change your email please send a message to the support"
            >
              <Icon name={'question'} size={'s'} color={emailClicked ? '#FDF6EB' : '#63435B'} />
            </button>

            <ReactTooltip
              className={styles.tooltip}
              effect={'solid'}
              place={'top'}
              multiline={true}
              border={true}
              event="click"
              offset={{ right: 90 }}
              textColor={'#FFFFFF !important'}
              backgroundColor={'#835978'}
              borderColor={'#835978'}
            />
          </div>
        </div>
        <div
          className={
            styles.floatingInputContainer +
            (nameError ? ' error ' : ' ') +
            (!nameEditing ? styles.floatingInputContainerDisabled : '')
          }
        >
          <input
            className={styles.fieldInput}
            id="inputName"
            name="inputName"
            type="text"
            placeholder=" "
            disabled={!nameEditing}
            onChange={(event) => {
              setName(event.target.value);
              setNameError(false);
            }}
            value={name}
          />
          <label htmlFor={'inputName'} className={styles.floatingInputPlaceholder}>
            Full Name*
          </label>
          {!nameEditing && (
            <div className={styles.floatingInputEdit}>
              <button className={styles.bordered} onClick={() => setNameEditing(true)}>
                <Icon name={'edit'} size={'s'} color={'#281B24'} />
              </button>
            </div>
          )}
        </div>
        {profile.accountPhoneId === '' ? (
          <div
            key={'div'}
            className={
              styles.floatingInputContainer +
              (phoneError ? ' error ' : ' ') +
              (!phoneEditing ? styles.floatingInputContainerDisabled : '')
            }
          >
            <PhoneInput
              country={phoneInputCountry}
              placeholder={'Phone Number'}
              value={phone}
              disabled={!phoneEditing}
              onChange={(value, data, event, formattedValue) => {
                setErrorPhone('');
                setPhone(formattedValue);
              }}
              onBlur={(event, data: CountryData | Record<string, unknown>) => {
                if (data && event.target.value?.split(' ')[0] !== `+${data.dialCode}`)
                  setPhone(`+${data.dialCode} ${event.target.value?.slice(1, event.target.value.length)}`);
                setPhoneData(data);
              }}
              containerStyle={{ position: 'absolute', borderRadius: '8px', margin: '0 !important', top: 0, right: 0 }}
            />
            {!phoneEditing && (
              <div className={styles.floatingPhoneInputEdit}>
                <button className={styles.bordered} onClick={() => setPhoneEditing(true)}>
                  <Icon name={'edit'} size={'s'} color={'#281B24'} />
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.floatingInputContainer + ' ' + styles.floatingInputContainerDisabled}>
            <input
              className={styles.fieldInput}
              id="inputPhone"
              name="inputPhone"
              type="text"
              placeholder=" "
              // disabled={true}
              value={phone}
            />
            <label htmlFor={'inputEmail'} className={styles.floatingInputPlaceholder}>
              Phone Number*
            </label>
            <div
              className={styles.floatingInputEdit}
              tabIndex={0}
              role={'button'}
              onMouseDown={() => setPhoneClicked((prevState) => !prevState)}
            >
              <button
                className={styles.bordered + ' ' + (phoneClicked ? styles.clicked : '')}
                data-tip="If you wish to change your phone number please send a message to the support"
              >
                <Icon name={'question'} size={'s'} color={phoneClicked ? '#FDF6EB' : '#63435B'} />
              </button>

              <ReactTooltip
                className={styles.tooltip}
                effect={'solid'}
                place={'top'}
                multiline={true}
                border={true}
                event="click"
                offset={{ right: 90 }}
                textColor={'#FFFFFF !important'}
                backgroundColor={'#835978'}
                borderColor={'#835978'}
              />
            </div>
          </div>
        )}
        <p className={'didacticP'}>Add your phone number to gain access to your experience app.</p>
        {(phoneEditing || nameEditing) && (
          <div style={{ display: 'flex' }}>
            <button
              className={'btn btnMain btnMainWithImg'}
              style={{ maxWidth: '141px', margin: 0 }}
              onMouseEnter={() => setIsSaveBtnHovered(true)}
              onMouseLeave={() => setIsSaveBtnHovered(false)}
              onClick={() => {
                updateProfile(userToken);
                setPhoneEditing(false);
                setNameEditing(false);
              }}
            >
              <Icon name={'image'} size={'s'} color={isSaveBtnHovered ? '#FDF6EB' : '#63435B'} />
              Save
            </button>
            <button
              className={'btn btnSecondary'}
              style={{ maxWidth: '123px', margin: 0 }}
              onClick={() => {
                setPhoneEditing(false);
                setNameEditing(false);
                setName(`${profile.accountFirstName} ${profile.accountLastName}`);
                setPhone(profile.phoneNumber);
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </section>
      <section className={styles.flexColumn}>
        <h2 className={'didacticH2'}>Reset Password</h2>
        <p className={'didacticP'}>Click the button and we will send in your email a link to reset your password.</p>
        <button
          className={'btn btnMain btnMainWithImg btnSmall'}
          onMouseEnter={() => setIsResetBtnHovered(true)}
          onMouseLeave={() => setIsResetBtnHovered(false)}
          onClick={() => {
            if (profile.email) {
              sendResetPasswordLink(profile.email);
            }
          }}
        >
          <Icon name={'lock'} size={'s'} color={isResetBtnHovered ? '#FDF6EB' : '#63435B'} />
          Reset Password
        </button>
      </section>
      <hr />
      <section className={styles.flexColumn}>
        <h2 className={'didacticH2'}>Add Host</h2>
        <p className={'didacticP'} style={{ maxWidth: 500 }}>
          Social Events are not made alone. Invite the people who can help you manage your events. They will gain access
          to the planner tool and all it’s features.
        </p>
        <div className={styles.flexColumn} style={{ width: '100%', overflowX: 'scroll' }}>
          {hosts.map((host, key) => {
            if (
              host.connectedAccountDetails?.accountId !== profile.accountId &&
              host.connectedAccountDetails?.accountPhoneId !== profile.accountPhoneId
            )
              return (
                <div key={key} className={styles.flexRow} style={{ minWidth: 'fit-content', paddingBottom: 16 }}>
                  <span style={{ width: 40 }}>
                    <Avatar
                      query={''}
                      name={host.firstName + ' ' + host.lastName}
                      userToken={userToken}
                      connectedAccountDetails={host.connectedAccountDetails}
                      _className={styles.avatarSmall}
                    />
                  </span>
                  <p className={'didacticP'} style={{ minWidth: 'fit-content' }}>
                    {host.firstName + ' ' + host.lastName}
                  </p>
                  <p className={'didacticP'} style={{ minWidth: 'fit-content' }}>
                    {host.email}
                  </p>
                  <p className={'didacticP'} style={{ minWidth: 'fit-content' }}>
                    {host.phone}
                  </p>
                  <p
                    className={
                      'didacticP ' + (host.connectedAccountDetails?.accountId ? styles.confirmed : styles.pending)
                    }
                    style={{ minWidth: 'fit-content' }}
                  >
                    {host.connectedAccountDetails?.accountId ? 'Confirmed' : 'Pending'}
                  </p>
                  <button
                    className={'btnXSmall btnBordered'}
                    onClick={() => {
                      setHostDeleteModal(true);
                      setHostToDelete(host);
                    }}
                  >
                    <Icon name={'trash'} color={'#281B24'} size={'s'} />
                  </button>
                </div>
              );
          })}
        </div>
        {!isAddHostBtnEditing ? (
          <button
            className={'btn btnMain btnMainWithImg btnSmall'}
            onMouseEnter={() => setIsAddHostBtnHovered(true)}
            onMouseLeave={() => setIsAddHostBtnHovered(false)}
            onClick={() => {
              setIsAddHostBtnEditing(true);
              setIsAddHostBtnHovered(false);
            }}
          >
            <Icon name={'lock'} size={'s'} color={isAddHostBtnHovered ? '#FDF6EB' : '#63435B'} />
            Add host
          </button>
        ) : (
          <Fragment>
            <div className={styles.floatingInputContainer} style={errorName ? { borderColor: 'red' } : {}}>
              <input
                className={styles.fieldInput}
                id="hostFullName"
                name="hostFullName"
                type="text"
                placeholder=" "
                onChange={(event) => {
                  setHostFullName(event.target.value);
                }}
                value={hostFullName}
              />
              <label htmlFor={'hostFullName'} className={styles.floatingInputPlaceholder}>
                Full Name*
              </label>
            </div>
            {errorName !== '' && <p style={{ color: 'red' }}>{errorName}</p>}
            <div className={styles.floatingInputContainer} style={errorEmail ? { borderColor: 'red' } : {}}>
              <input
                className={styles.fieldInput}
                id="hostEmail"
                name="hostEmail"
                type="text"
                placeholder=" "
                onChange={(event) => {
                  setHostEmail(event.target.value);
                }}
                value={hostEmail}
              />
              <label htmlFor={'hostEmail'} className={styles.floatingInputPlaceholder}>
                Email*
              </label>
            </div>
            {errorEmail !== '' && <p style={{ color: 'red' }}>{errorEmail}</p>}
            <div>
              <PhoneInput
                containerClass={styles.phoneInput + ' ' + (errorPhone ? styles.phoneInputError : '')}
                country={phoneInputCountry}
                placeholder={'Phone Number'}
                value={hostPhone}
                onChange={(value, data, event, formattedValue) => {
                  setErrorPhone('');
                  setHostPhone(formattedValue);
                }}
                onBlur={(event, data: CountryData | Record<string, unknown>) => {
                  if (data && event.target.value?.split(' ')[0] !== `+${data.dialCode}`)
                    setHostPhone(`+${data.dialCode} ${event.target.value?.slice(1, event.target.value.length)}`);
                  setHostPhoneData(data);
                }}
                containerStyle={{ borderRadius: '8px', width: 'fit-content' }}
              />
              {errorPhone !== '' && <p style={{ color: 'red' }}>{errorPhone}</p>}
            </div>
            <div style={{ display: 'flex' }}>
              <button
                className={'btn btnMain btnMainWithImg'}
                style={{ maxWidth: '171px', margin: 0 }}
                onClick={() => {
                  setErrorName('');
                  setErrorEmail('');
                  setErrorPhone('');
                  if (onCheckHostDate()) setHostReviewModal(true);
                }}
              >
                <Icon name={'add_user'} size={'s'} />
                Add host
              </button>
              <button
                className={'btn btnSecondary'}
                style={{ maxWidth: '123px', margin: 0 }}
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </Fragment>
        )}
      </section>
      {(typeOfScreen === ScreenType.mobile || typeOfScreen === ScreenType.tablet) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: '32px',
          }}
        >
          <button
            style={{ width: '200px', padding: '8px 16px' }}
            onClick={() => (window.location.pathname = '/dashboard/choose-event')}
            className="btn btnMain"
          >
            Change Social Event
          </button>
          <button
            style={{
              width: '200px',
              padding: '8px 16px',
              background: '#C7ADC1',
              color: 'black',
              borderColor: 'grey',
            }}
            onClick={() => logout()}
            className="btn btnMain"
          >
            Logout
          </button>
        </div>
      )}
      {showHostReviewModal && (
        <GenericConfirmModal
          setShow={setHostReviewModal}
          title={'Just a final check'}
          text={'Are you sure you want to add this host?'}
          confirmBtnFunction={() => onNewHost()}
          confirmBtnText={'Confirm & Add'}
          confirmBtnIcon={'add_user'}
          html={t}
        />
      )}
      {showHostDeleteModal && (
        <GenericConfirmModal
          setShow={setHostDeleteModal}
          title={'Just a final check'}
          text={'Are you sure you want to delete this host?'}
          confirmBtnFunction={() => onDeleteHost()}
          confirmBtnText={'Delete'}
          confirmBtnIcon={'trash'}
        />
      )}
      {showBottomModal && (
        <MobileBottomModal
          title={'Are you sure you want to change your profile name?'}
          callback={() => {
            updateProfile(userToken);
            setShowBottomModal(false);
          }}
          exitCallback={() => {
            setShowBottomModal(false);
            setName(startingName);
            setNameEditing(false);
          }}
        />
      )}
      <ToastContainer
        closeButton={false}
        style={{ width: 'fit-content', borderRadius: 8 }}
        icon={<Icon name={'accept'} color={'#006150'} size={'s'} />}
        position="bottom-center"
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default DashboardProfile;
