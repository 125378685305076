import { useState, useEffect } from 'react';

export enum ScreenType {
  mobile = 'Mobile',
  tablet = 'Tablet',
  laptop = 'Laptop',
  desktop = '',
}

function useMobileCheck(): ScreenType {
  const [typeOfScreen, setTypeOfScreen] = useState<ScreenType>(ScreenType.desktop);
  // choose the screen size
  const checkScreen = (): void => {
    //BIG SCREEN
    if (window.innerWidth >= 1025) {
      setTypeOfScreen(ScreenType.desktop);
    }
    //SMALL SCREEN
    else if (window.innerWidth > 840 && window.innerWidth <= 1024) {
      setTypeOfScreen(ScreenType.laptop);
    }
    //TABLET (up to iPad Pro 11")
    else if (window.innerWidth <= 840 && window.innerWidth > 480) {
      setTypeOfScreen(ScreenType.tablet);
    }
    //MOBILE
    else if (window.innerWidth <= 480) {
      setTypeOfScreen(ScreenType.mobile);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', checkScreen);
    window.addEventListener('onload', checkScreen);
  });

  useEffect(() => {
    checkScreen();
  });

  return typeOfScreen;
}

export default useMobileCheck;
