import React from 'react';

const useOutsideClick = (ref: React.MutableRefObject<any>, callback: () => void, excludedName?: string): any => {
  // const ref = React.useRef();

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target) && !event.target?.id.includes(excludedName)) {
        callback();
      }
    };
    const handleKeyboardEscapeOutside = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyboardEscapeOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyboardEscapeOutside);
    };
  }, [ref]);

  return ref;
};

export default useOutsideClick;
