import { Fragment, useState } from 'react';
import Avatar from '../Avatar/Avatar';

interface ImageProps {
  src?: string;
  alt?: string;
  size?: number;
  accountFirstName?: string;
  accountLastName?: string;
  fullName?: string;
  classNames?: string;
}

const AvatarProfile = ({
  src = '',
  classNames = '',
  size = 40,
  alt = 'ProfileAvatar',
  accountFirstName,
  accountLastName,
  fullName,
}: ImageProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      <div style={{ display: loading ? 'block' : 'none' }}>
        <Avatar
          size={size}
          initials={
            fullName
              ? fullName
              : `${accountFirstName ? accountFirstName : ''} ${accountLastName ? accountLastName : ''}`
          }
        />
      </div>
      <div
        style={{
          display: loading ? 'none' : 'block',
        }}
      >
        <img
          className={classNames}
          src={src}
          alt={alt}
          onError={() => setLoading(true)}
          onLoad={() => setLoading(false)}
        />
      </div>
    </>
  );
};

export default AvatarProfile;
