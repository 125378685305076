import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  addEdge,
  Connection,
  Controls,
  Edge,
  MiniMap,
  Node,
  Position,
  SelectionMode,
  useEdgesState,
  useNodesState,
  Viewport,
} from 'reactflow';
import styled, { ThemeProvider } from 'styled-components';

import { darkTheme, lightTheme } from './theme';
import { MemoizedWeddingNode } from './WeddingNode';

import 'reactflow/dist/style.css';
import { MemoizedConnectedAccountRefNode } from './ConnectedAccountRefNode';
import { MemoizedAccountNode } from './AccountNode';
import dagre from 'dagre';
import { MemoizedStatusNode } from './StatusNode';

const nodeTypes = {
  weddingNode: MemoizedWeddingNode,
  hostNode: MemoizedConnectedAccountRefNode,
  accountNode: MemoizedAccountNode,
  statusNode: MemoizedStatusNode,
};

const ReactFlowStyled = styled(ReactFlow)`
  background-color: ${(props: any) => props.theme.bg};
`;

const MiniMapStyled = styled(MiniMap)`
  background-color: ${(props: any) => props.theme.bg};

  .react-flow__minimap-mask {
    fill: ${(props: any) => props.theme.minimapMaskBg};
  }

  .react-flow__minimap-node {
    fill: ${(props: any) => props.theme.nodeBg};
    stroke: none;
  }
`;

const ControlsStyled = styled(Controls)`
  button {
    background-color: ${(props: any) => props.theme.controlsBg};
    color: ${(props: any) => props.theme.controlsColor};
    border-bottom: 1px solid ${(props: any) => props.theme.controlsBorder};

    &:hover {
      background-color: ${(props: any) => props.theme.controlsBgHover};
    }

    path {
      fill: currentColor;
    }
  }
`;

const dagreGraph = new dagre.graphlib.Graph({ directed: true, multigraph: true, compound: true });
dagreGraph.setDefaultEdgeLabel(() => ({}));

// const nodeWidth = 800;
// const nodeHeight = 700;
// const panOnDrag = [1, 2];
const defaultViewport: Viewport = { x: 400, y: 400, zoom: 0.6 };

const getNodeDimensions = (node: Node) => {
  let nodeWidth = 0;
  let nodeHeight = 0;
  switch (node.type) {
    case 'weddingNode':
      nodeWidth = 700;
      nodeHeight = 800;
      break;
    case 'hostNode':
      nodeWidth = 800;
      nodeHeight = 600;
      break;
    case 'accountNode':
      nodeWidth = 800;
      nodeHeight = 500;
      break;
    case 'statusNode':
      nodeWidth = 500;
      nodeHeight = 300;
      break;
  }
  return { nodeWidth, nodeHeight };
};

const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  let countNode = 0;
  nodes.forEach((node) => {
    const { nodeWidth, nodeHeight } = getNodeDimensions(node);
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight, order: countNode, rank: countNode });
    countNode++;
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: Node) => {
    const { nodeWidth, nodeHeight } = getNodeDimensions(node);
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left,
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth,
      y: nodeWithPosition.y - nodeHeight,
    };

    return node;
  });

  return { nodes, edges };
};

type FlowProps = {
  nodes: Node[];
  edges: Edge[];
};
const Flow = (props: FlowProps) => {
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(props.nodes, props.edges, 'LR');
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConnect = useCallback((params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)), []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onLayout = useCallback(
    (direction: string) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges],
  );

  const checkScreenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
  }, []);

  return (
    <ReactFlowStyled
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      // onConnect={onConnect}
      nodeTypes={nodeTypes}
      // fitView
      defaultViewport={defaultViewport}
      panOnScroll={screenWidth > 800 ? true : false}
      selectionOnDrag={screenWidth > 800 ? true : false}
      panOnDrag={screenWidth > 800 ? false : true}
      selectionMode={SelectionMode.Partial}
    >
      {screenWidth > 800 ? <MiniMapStyled /> : ''}
      <ControlsStyled />
    </ReactFlowStyled>
  );
};

type FlowWithThemeProps = {
  flowProps: FlowProps;
  // themeProps
};

const FlowWithTheme = (props: FlowWithThemeProps): JSX.Element => {
  const [mode, setMode] = useState('dark');
  const theme = mode === 'light' ? lightTheme : darkTheme;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isHovered, setIsHovered] = useState(false);

  const toggleMode = () => {
    setMode((m) => (m === 'light' ? 'dark' : 'light'));
  };

  // useEffect(() => {
  //   console.log('FlowWithTheme', props);
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <button
        onClick={toggleMode}
        className={`btn btnMain`}
        style={{
          position: 'absolute',
          zIndex: 100,
          right: 10,
          top: 10,
          width: '200px',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        switch mode
      </button>
      <Flow nodes={props.flowProps.nodes} edges={props.flowProps.edges} />
    </ThemeProvider>
  );
};

export default FlowWithTheme;
