import { combineReducers } from 'redux';

import authorizationReducer from './authorization.slice.reducer';
import weddingReducer from './wedding.slice.recuder';
import websiteBuilderSliceReducer from './websiteBuilder.slice.reducer';

// The key of this object will be the name of the store
const rootReducers = combineReducers({
  authorization: authorizationReducer,
  wedding: weddingReducer,
  websiteBuilder: websiteBuilderSliceReducer,
});

export default rootReducers;
