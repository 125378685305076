import React, { useEffect, useState } from 'react';
import styles from './UserInfo.module.scss';
import { Imagehelper } from '../../../../app/helper/Imagehelper';
import ImageWithFallback from '../../../../shared/imageWithFallback/ImageWithFallback';
import Icon from '../../../../shared/Icon';

interface UserProfileProps {
  imageSrc?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  userToken: string;
  setSelectedGuest?: any;
  isBigger?: boolean;
  isSmaller?: boolean;
  showName?: boolean;
  isHost?: boolean;
}

const UserInfo: React.FC<UserProfileProps> = ({
  imageSrc,
  firstName = '',
  lastName = '',
  email,
  userToken,
  setSelectedGuest,
  isBigger,
  isSmaller,
  showName = true,
  isHost,
}) => {
  const [image, setImage] = useState('');
  const helper = async () => {
    if (imageSrc && userToken) await Imagehelper.fetchImage(imageSrc, setImage, userToken, 200);
    else setImage('');
  };
  const renderImageOrInitials = () => {
    if (image && image !== '') {
      return <ImageWithFallback src={image} fallbackSrc={'/assets/icons/icon-profile.svg'} alt="Description" />;
      // return <img src={image ? image : '/rsvp/guests/svg '} alt={`${firstName} ${lastName}`} className="userImage" />;
    } else if (firstName || lastName) {
      const initials = `${firstName.charAt(0) || ''}${lastName.charAt(0) || ''}`;
      return <span className="userInitials">{initials.toUpperCase()}</span>;
    }
    return <span className="userInitials">N/A</span>;
  };

  useEffect(() => {
    helper();
  }, [imageSrc]);

  return (
    <button
      className={styles.userProfileContainer}
      style={!setSelectedGuest ? { cursor: 'default' } : {}}
      onClick={() => setSelectedGuest()}
    >
      <div
        className={
          styles.userImageContainer +
          (isBigger ? ' ' + styles.userImageContainerBigger : '') +
          (isSmaller ? ' ' + styles.userImageContainerSmaller : '')
        }
      >
        {renderImageOrInitials()}
      </div>
      {showName && (
        <div className={styles.userInfo}>
          <div className={styles.userName}>
            {isHost && <Icon name={'crown'} size={'s'} />}
            <p className={isBigger ? ' didacticH4' : ' didacticP'}>{`${firstName} ${lastName}`.trim() || 'Unknown'}</p>
          </div>
          {email && <div className={styles.userEmail + (isBigger ? ' didacticP' : ' didacticSpan')}>{email}</div>}
        </div>
      )}
    </button>
  );
};

export default UserInfo;
