import { memo } from 'react';
import styled from 'styled-components';

type StyledProps = {
  selected: boolean;
};
const Node = styled.div<StyledProps>`
  padding: 10px 20px;
  border-radius: 5px;
  background: #a3f5e6;
  color: ${(props) => props.theme.nodeBg};
  border: 1px solid #dad8d9;

  .react-flow__handle {
    background: ${(props) => props.theme.primary};
    width: 8px;
    height: 10px;
    border-radius: 3px;
  }

  .nodeDesign {
    color: ${(props) => props.theme.nodeColor};

    h2 {
      color: ${(props) => props.theme.nodeBg};
    }
    p {
      color: ${(props) => props.theme.nodeColor};
      font-size: 14px;
    }
  }
`;

type StatusNodeProps = {
  data: string;
  selected: boolean;
};
const StatusNode = ({ data, selected }: StatusNodeProps) => {
  // useEffect(() => {
  //   console.log('StatusNode', { data, selected });
  // }, []);

  return (
    <Node selected={selected}>
      {/*<Handle type="target" position={Position.Left} />*/}
      <div className={'nodeDesign'}>
        <h2>{data}</h2>
      </div>
      {/*<Handle type="source" position={Position.Right} />*/}
    </Node>
  );
};
export const MemoizedStatusNode = memo(StatusNode);
