import '@ionic/react/css/core.css';
import '../experienceApp/screens/Profile/Profile.scss';

import { IonContent, IonHeader, IonImg, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  selectAccessTokenAfterOTP,
  selectIsLoggedIn,
  selectProfile,
  setStreamFired,
} from '../app/redux/authorization.slice.reducer';
import { selectWedding } from '../app/redux/wedding.slice.recuder';
import { Backend } from '../config/backend';
import { CreateAllowedVisitorRequest } from '../pkg/protobuf/v2/brain/brain_types_pb';

export const AddVisitorSecretPage = (): JSX.Element => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const user = useSelector(selectProfile);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const wedding = useSelector(selectWedding);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) navigate('/');
  }, [user]);

  const addAllowedVisitors = async (jwt: string) => {
    if (phoneNumber !== undefined && phoneNumber.trim() !== '') {
      const createAllowedVisitorRequest = new CreateAllowedVisitorRequest();
      createAllowedVisitorRequest.weddingId = wedding.id;
      createAllowedVisitorRequest.phone = phoneNumber;
      const headers = new Headers();
      headers.set('Authorization', `Bearer ${jwt}`);
      await Backend.brainClientV2.createAllowedVisitor(createAllowedVisitorRequest, {
        headers,
      });
      window.location.reload();
    }
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border header">
        <img className="headerLogo" src="/logo-whiteclover.svg" alt="logo" />
      </IonHeader>
      <IonContent>
        <div className="profile-page">
          <h1>Add new visitor to guest list</h1>
          <div className="floatingInputContainer">
            <input
              className="field-input"
              id="inputPhone"
              name="inputPhone"
              type="text"
              placeholder=""
              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
              value={phoneNumber}
            />
            <label className="floatingInputPlaceholder" htmlFor="inputPhone">
              Add multiple phone separated by comma
            </label>
          </div>
          <button className="saveBtn" onClick={() => addAllowedVisitors(userToken)}>
            Save Changes
          </button>
        </div>
      </IonContent>
      <div className="footerSectionPlain">
        <IonImg className="profileImg" src="/icon-avatar.svg" />
        <IonImg
          className="homeImg"
          src="/icon-home.svg"
          onClick={() => {
            dispatch(setStreamFired(false));
            navigate('/home');
          }}
        />
        <IonImg
          className="feedImg"
          src="/icon-website.svg"
          onClick={() => window.open('https://whiteclover.io/miltos-semina-310721', '_blank')}
        />
      </div>
    </IonPage>
  );
};
