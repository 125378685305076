import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addRsvpEvents, selectWedding } from '../../../app/redux/wedding.slice.recuder';
import { selectAccessTokenAfterOTP, selectJwt } from '../../../app/redux/authorization.slice.reducer';
import styles from './Planning.module.scss';
import { Location, RSVPEvent } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import { useDropzone } from 'react-dropzone';
import { GenericModal } from '../../modals/GenericModal';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Preview from './preview/Preview';
import useMobileCheck from '../../../hooks/useMobileChjeck';
import { ScreenType, useTypeOfScreen } from '../../../hooks';

const Planning = (props: { wedding?: any }): JSX.Element => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const wedding = useSelector(selectWedding);
  const jwt = useSelector(selectJwt);
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [weddingName, setWeddingName] = useState<string>(wedding.name);
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [selectedDay, setSelectedDay] = useState<DayValue>(undefined);
  const [weddingLocation, setWeddingLocation] = useState<Location | undefined>(wedding?.mainEvent?.location);
  const [countryShort, setCountryShort] = useState<string>(props.wedding?.mainEvent?.location?.externalQuickCode || '');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileUrl, setFileurl] = useState<string>('');
  const [fileId, setFileId] = useState<string | undefined>('');
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [completedFile, setCompletedFile] = useState<boolean>(true);
  const [percentage, setPercentage] = useState<number>(0);
  const [blobId, setBlobId] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const defaultTimeStamp = '2020-01-01T12:00:00';
  const typeOfScreen = useTypeOfScreen();

  const [startDateString, setStartDateString] = useState<string>(
    wedding?.mainEvent?.startTimestamp || defaultTimeStamp,
  );
  const [endDateString, setEndDateString] = useState<string>(wedding?.mainEvent?.endTimestamp || defaultTimeStamp);

  const [description, setDescription] = useState<string | undefined>(wedding?.mainEvent?.description);
  const [step, setStep] = useState<number>(1);
  const [isDropzoneActive, setIsDropzoneActive] = useState<boolean>(false);
  const isMobile = useMobileCheck();
  const [showPreviewMobile, setShowPreviewMobile] = useState<boolean>(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 20000000,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/gif': [],
      'image/webp': [],
      'image/heif': [],
      'image/heic': [],
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDragEnter: (dragEvent) => {
      setIsDropzoneActive(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDragLeave: (dragEvent) => {
      setIsDropzoneActive(false);
    },
    onDropAccepted: async (fileAccepted) => {
      setLoading(true);
      await Imagehelper.handleCompressedImageUpload(
        fileAccepted[0],
        userToken,
        wedding.path,
        setPercentage,
        percentage,
        setLoading,
        setFileurl,
        setFileId,
        setCompletedFile,
        true,
      );
    },
    onFileDialogCancel: () => {
      setIsDropzoneActive(false);
    },
    onDropRejected: (fileRejections) => {
      setIsDropzoneActive(false);
      setModalMessage(
        fileRejections[0].errors[0].message +
          '. Please only Upload one image of jpg/jpeg/png/gif format of max size 10Mb.',
      );
      setShowModal(true);
    },
  });

  const dispatch = useDispatch();

  const stepsMap: any = {
    1: (
      <FirstStep
        userToken={userToken}
        wedding={wedding}
        weddingName={weddingName}
        setWeddingName={setWeddingName}
        weddingLocation={weddingLocation}
        setCalendarOpened={setCalendarOpened}
        selectedDay={selectedDay}
        calendarOpened={calendarOpened}
        setSelectedDay={setSelectedDay}
        setWeddingLocation={setWeddingLocation}
        countryShort={countryShort}
        setCountryShort={setCountryShort}
        fileId={fileId}
        setStartDate={setStartDateString}
        startDate={startDateString}
        setEndDate={setEndDateString}
        endDate={endDateString}
        setDescription={setDescription}
        description={description}
        setStep={setStep}
        acceptedFiles={acceptedFiles}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        isDropzoneActive={isDropzoneActive}
        setIsDropzoneActive={setIsDropzoneActive}
        setShowPreview={setShowPreviewMobile}
      />
    ),
    2: <SecondStep setStep={setStep} userToken={userToken} setShowPreview={setShowPreviewMobile} />,
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const query = new URLSearchParams(location.search);
    !!wedding.mainEvent && setStep(Number(query.get('step')) || 1);
    if (wedding?.mainEvent?.startTimestamp !== '' && wedding?.mainEvent?.startTimestamp !== undefined) {
      const selectedDayFormatted = wedding?.mainEvent?.location?.externalQuickCode
        ? startDateString
          ? dayjs(startDateString ? startDateString : new Date())
              .tz(
                wedding?.mainEvent?.location?.externalQuickCode ? wedding?.mainEvent?.location?.externalQuickCode : '',
              )
              .format('YYYY-MM-DDTHH:mm:ss')
          : ''
        : dayjs(startDateString || new Date()).format('YYYY-MM-DDTHH:mm:ss');
      setSelectedDay({
        year: Number(selectedDayFormatted.split('T')[0].split('-')[0]),
        month: Number(selectedDayFormatted.split('T')[0].split('-')[1]),
        day: Number(selectedDayFormatted.split('T')[0].split('-')[2]),
      });
    }
    if (wedding?.mainEvent?.icon) setFileId(wedding.mainEvent.icon);
    if (wedding?.mainEvent?.location?.externalQuickCode)
      setCountryShort(wedding?.mainEvent?.location?.externalQuickCode);
    getRsvpEvents();
    if (query.get('eventId') === 'NEW_EVENT') {
      const newRsvpEvent = new RSVPEvent();
      dispatch(addRsvpEvents([newRsvpEvent]));
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const getRsvpEvents = async () => {
    const rsvpEvents = await BrainHelper.listRSVPEvents(userToken, wedding.id);
    // dispatch(setRsvpEventsReducer(rsvpEvents?.rsvpEvents));
    if (rsvpEvents?.rsvpEvents && rsvpEvents.rsvpEvents?.length > 1) {
      setStep(2);
    }
  };

  useEffect(() => {
    if (selectedDay && startDateString && endDateString) {
      const tempStartDate = `${String(selectedDay.year)}-${String(
        selectedDay.month.toLocaleString('en-UK', { minimumIntegerDigits: 2 }),
      )}-${selectedDay.day.toLocaleString('en-UK', { minimumIntegerDigits: 2 })}T`;
      setStartDateString(tempStartDate + startDateString.split('T')[1]);
    }
  }, [selectedDay]);

  useEffect(() => {
    //TODO: MUST BE FIXED
    if (fileId !== '' && fileId !== undefined) fetchPostImage(fileId);
  }, [fileId]);

  // get image
  const fetchPostImage = async (fileID: string) => {
    setLoading(true);
    setBlobId('');
    const result = await Imagehelper.getFileFromFilestoreId(fileID, jwt, 800);
    if (typeof result === 'string') {
      setLoading(false);
      setBlobId(result);
    }
  };

  useEffect(() => {
    setStartDateString(wedding?.mainEvent?.startTimestamp || defaultTimeStamp);
    setEndDateString(wedding?.mainEvent?.endTimestamp || defaultTimeStamp);
  }, [wedding.mainEvent]);

  return (
    <div
      className={styles.planningWrapper}
      style={{
        flexDirection: isMobile ? 'column' : 'row',
        height:
          typeOfScreen === ScreenType.tablet || typeOfScreen === ScreenType.mobile ? 'calc(100svh + 150px)' : '100svh',
        padding:
          typeOfScreen === ScreenType.tablet || typeOfScreen === ScreenType.mobile
            ? '60px 8px 158px 8px'
            : typeOfScreen === ScreenType.laptop
            ? '0px 20px 0px 0px'
            : '0px',
      }}
    >
      {!showPreviewMobile && (
        <div className={styles.main}>
          <header style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className={'fridayH1'}>Planning</h1>
            {isMobile && (
              <button
                className={'btn btnMain ' + styles.nextBtn}
                style={{ marginBottom: '8px' }}
                onClick={() => setShowPreviewMobile(true)}
              >
                Preview
              </button>
            )}
          </header>
          <hr />
          {stepsMap[step]}
        </div>
      )}
      {(!isMobile || showPreviewMobile) && (
        <Preview
          selectedDay={selectedDay}
          loading={loading}
          percentage={percentage}
          blobId={blobId}
          weddingName={weddingName}
          startDate={startDateString}
          endDate={endDateString}
          countryShort={countryShort}
          weddingLocation={weddingLocation}
          description={description}
        />
      )}
      {showPreviewMobile && (
        <button
          style={{ position: 'absolute', right: 20, top: 20 }}
          className={'btn btnMain ' + styles.nextBtn}
          onClick={() => setShowPreviewMobile(false)}
        >
          Exit
        </button>
      )}

      {showModal ? <GenericModal showModal={showModal} message={modalMessage} setShowModal={setShowModal} /> : ''}
    </div>
  );
};

export default Planning;
