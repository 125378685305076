import React, { Fragment, useEffect, useState } from 'react';
import { CreateAllowedVisitorRequest, Wedding } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import styles from './SelfInvite.module.scss';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { Icon } from '../../../shared';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import timezoneData from 'moment-timezone/data/meta/latest.json';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import { google, outlook, yahoo, ics } from 'calendar-link';
import GetUI, { EventType } from '../../../utils/uiContent';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const SelfInvite = (): JSX.Element => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [wedding, setWedding] = useState<Wedding | null>(null);
  const [plusOnes, setPlusOnes] = useState<number>(-1);
  const [newVisitors, setNewVisitors] = useState<Array<CreateAllowedVisitorRequest>>([
    new CreateAllowedVisitorRequest(),
  ]);
  const [phoneData, setPhoneData] = useState<Array<CountryData | Record<string, unknown>>>([]);
  const [microsoftHover, setMicrosoftHover] = useState<boolean>(false);
  const [yahooHover, setYahooHover] = useState<boolean>(false);
  const [appleHover, setAppleHover] = useState<boolean>(false);
  const [googleHover, setGoogleHover] = useState<boolean>(false);

  const [success, setSuccess] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [iconToggle, toggleIcon] = useState<boolean>(false);
  const [clearErrorState, setClearErrorState] = useState<boolean>(true);
  const [phoneInputCountry, setPhoneInputCountry] = useState<string>('gr');
  const [phoneError, setPhoneError] = useState<Array<string>>(['']);
  const [emailError, setEmailError] = useState<Array<string>>(['']);

  const { weddingId, maxAllowed } = useParams();

  const validatePhone = (phone: string) => {
    return /^[+]?[(]?[0-9]{3}[)]?[-|.]?[0-9]{3}[-|.]?[0-9]{4,8}$/im.test(phone);
  };

  const validateEmail = (email: string) => {
    return Boolean(
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
    );
  };

  const validate = () => {
    const res = newVisitors.map((element) => {
      return !(
        !element.email ||
        element.email.length < 4 ||
        !element.phone ||
        element.phone.length < 4 ||
        !element.firstName ||
        element.firstName.length < 2
      );
    });
    return !res.includes(false);
  };

  useEffect(() => {
    if (newVisitors.length > 0) {
      setIsValid(validate());
    }
  }, [newVisitors.length]);

  useEffect(() => {
    const timeZoneToCountry = new Map<string, string>();
    const countryName = moment.tz.guess(true);
    Object.values(timezoneData.zones).forEach((z) => {
      timeZoneToCountry.set(z.name, z.countries[0]);
    });
    const countryCode = timeZoneToCountry.get(countryName);
    setPhoneInputCountry(countryCode?.toLowerCase() || 'gr');
  }, []);

  useEffect(() => {
    const getWedding = (id: string) => {
      const wed = BrainHelper.getPublicWedding(id);
      wed.then((w) => setWedding(w)).catch((error) => console.log(error));
    };
    if (weddingId && wedding === null) {
      getWedding(weddingId);
    }

    if (maxAllowed && Number(maxAllowed) && Number(maxAllowed) > -1) {
      setPlusOnes(Number(maxAllowed));
    } else {
      setPlusOnes(-1);
    }
  }, [weddingId, maxAllowed]);

  const setVisitor = (key: 'fullName' | 'email' | 'phone', value: string, index: number) => {
    setClearErrorState(true);
    setPhoneError([]);
    const tempVisitors = newVisitors.slice();
    if (key === 'fullName') {
      let firstName = '';
      let lastName = '';
      const words = value.split(' ');
      if (words.length > 0) {
        firstName = words[0];
      }
      if (words.length > 1) {
        for (let i = 1; i < words.length; i++) {
          if (lastName !== '') {
            lastName += ` ${words[i]}`;
          } else {
            lastName += words[i];
          }
        }
      }
      tempVisitors[index].firstName = firstName;
      tempVisitors[index].lastName = lastName;
    }
    if (key === 'email') {
      tempVisitors[index].email = value;
    }
    if (key === 'phone') {
      tempVisitors[index].phone = value.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
    }
    setNewVisitors(tempVisitors);
    setIsValid(validate());
  };

  const removeVisitor = (index: number) => {
    setClearErrorState(true);
    setPhoneError([]);
    setNewVisitors([...newVisitors.slice(0, index), ...newVisitors.slice(index + 1, newVisitors.length)]);
  };
  const addNewVisitor = () => {
    setClearErrorState(true);
    setPhoneError([]);
    setNewVisitors([...newVisitors, new CreateAllowedVisitorRequest()]);
  };
  const joinWedding = async () => {
    setClearErrorState(false);
    if (!wedding?.id) return;
    try {
      let errorCounter = 0;
      const tempPhoneErrors = JSON.parse(JSON.stringify(phoneError));
      const tempEmailErrors = JSON.parse(JSON.stringify(emailError));
      const tempVisitors: Array<CreateAllowedVisitorRequest> = [];
      for (const r of newVisitors) {
        tempVisitors.push(r);
      }
      newVisitors.forEach((_visitor, index) => {
        if (
          phoneData[index] &&
          typeof phoneData[index].dialCode === 'string' &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newVisitors[index].phone.slice(0, phoneData[index].dialCode.length + 1) !== `+${phoneData[index]?.dialCode}`
        ) {
          if (tempPhoneErrors.length > index) tempPhoneErrors[index] = "phones' country code is invalid or missing";
          else tempPhoneErrors.push("phones' country code is invalid or missing");
          errorCounter++;
        } else {
          if (tempPhoneErrors.length > index) tempPhoneErrors[index] = '';
          else tempPhoneErrors.push('');
        }
        if (
          tempPhoneErrors[index] === '' &&
          !validatePhone(
            newVisitors[index].phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
          )
        ) {
          tempPhoneErrors[index] = 'phone is invalid';
          errorCounter++;
        }
        if (tempEmailErrors[index] === '' && !validateEmail(newVisitors[index].email.replaceAll(' ', ''))) {
          tempEmailErrors[index] = 'email is invalid';
          errorCounter++;
        }
        tempVisitors[index].phone = tempVisitors[index].phone
          .replaceAll(' ', '')
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll('-', '');
        tempVisitors[index].weddingId = wedding.id;
        if (newVisitors.length > 2) {
          tempVisitors[index].maxAllowedPlusOnes = plusOnes > 0 ? BigInt(plusOnes) : BigInt(newVisitors.length - 1);
        }
      });
      setPhoneError(tempPhoneErrors);
      setEmailError(tempPhoneErrors);
      if (errorCounter > 0) return;
      const res = await BrainHelper.createPublicAllowedVisitor(tempVisitors);
      if (res && res.failed.length === 0) {
        setSuccess(true);
        setNewVisitors([new CreateAllowedVisitorRequest()]);
        toast(`You joined the event successfully!`, {
          style: { backgroundColor: '#A3F5E6', color: '#006150 !important' },
        });
      } else {
        throw `The given email or phone are already submitted in this social event for ${res?.failed
          ?.map((f) => f?.firstName + ' ' + f?.lastName)
          ?.join(', ')}`;
      }
    } catch (e) {
      toast(`${e}`, {
        time: 5000,
        icon: <Icon name={'cancel'} color={'#006150'} size={'s'} />,
        style: { backgroundColor: '#D54F68', color: '#FFF !important' },
      } as ToastOptions);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgColor}></div>
      <div style={{ margin: '0 auto' }}>
        <img loading="lazy" className={styles.logo} src="/assets/logos/whiteclover-logo1.svg" alt="logo" height={42} />
      </div>
      {success && wedding && wedding.mainEvent && (
        <div className={styles.board} style={{ gap: 12, height: 'fit-content' }}>
          <h1 className="fridayH1">Thank you</h1>
          <p>In the meantime keep an eye out in your mail for updates and invitation details.</p>
          <h1 className="fridayH1" style={{ color: '#969E6B' }}>
            {dayjs(wedding.mainEvent?.startTimestamp ?? new Date())
              .tz(
                wedding?.mainEvent?.location?.externalQuickCode
                  ? wedding?.mainEvent?.location?.externalQuickCode
                  : undefined,
              )
              .toDate()
              .getDate()
              .toLocaleString('en-UK', { minimumIntegerDigits: 2 })}{' '}
            {(
              dayjs(wedding.mainEvent?.startTimestamp ?? new Date())
                .tz(
                  wedding?.mainEvent?.location?.externalQuickCode
                    ? wedding?.mainEvent?.location?.externalQuickCode
                    : undefined,
                )
                .toDate()
                .getMonth() + 1
            ).toLocaleString('en-UK', {
              minimumIntegerDigits: 2,
            })}{' '}
            {dayjs(wedding.mainEvent?.startTimestamp ?? new Date())
              .tz(
                wedding?.mainEvent?.location?.externalQuickCode
                  ? wedding?.mainEvent?.location?.externalQuickCode
                  : undefined,
              )
              .toDate()
              .getFullYear()}
          </h1>
          <h4 className="didacticH4">Add to your Calendar:</h4>
          <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
            <a
              href={`${google({
                title: wedding.name,
                description: wedding.mainEvent.description,
                start: wedding.mainEvent.startTimestamp,
                end: wedding.mainEvent.endTimestamp,
                location: wedding.mainEvent.location?.address,
              })}`}
              target="_blank"
              rel="noreferrer"
              className="btnMain"
              style={{
                borderRadius: '8px',
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '12px 21px',
                color: !googleHover ? '#63435B' : '#fff',
              }}
              onTouchStart={() => setGoogleHover(true)}
              onMouseEnter={() => setGoogleHover(true)}
              onTouchEnd={() => setGoogleHover(false)}
              onMouseLeave={() => setGoogleHover(false)}
            >
              <Icon name="google" color={!googleHover ? '#63435B' : '#fff'} size="s" />
              Google
            </a>
            <a
              href={`${outlook({
                title: wedding.name,
                description: wedding.mainEvent.description,
                start: wedding.mainEvent.startTimestamp,
                end: wedding.mainEvent.endTimestamp,
                location: wedding.mainEvent.location?.address,
              })}`}
              target="_blank"
              rel="noreferrer"
              className="btnMain"
              style={{
                borderRadius: '8px',
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '12px 21px',
                color: !microsoftHover ? '#63435B' : '#fff',
              }}
              onTouchStart={() => setMicrosoftHover(true)}
              onMouseEnter={() => setMicrosoftHover(true)}
              onTouchEnd={() => setMicrosoftHover(false)}
              onMouseLeave={() => setMicrosoftHover(false)}
            >
              <Icon name="microsoft" color={!microsoftHover ? '#63435B' : '#fff'} size="s" />
              Outlook
            </a>
            <a
              href={`${yahoo({
                title: wedding.name,
                description: wedding.mainEvent.description,
                start: wedding.mainEvent.startTimestamp,
                end: wedding.mainEvent.endTimestamp,
                location: wedding.mainEvent.location?.address,
              })}`}
              target="_blank"
              rel="noreferrer"
              className="btnMain"
              style={{
                borderRadius: '8px',
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '12px 21px',
                color: !yahooHover ? '#63435B' : '#fff',
              }}
              onTouchStart={() => setYahooHover(true)}
              onMouseEnter={() => setYahooHover(true)}
              onTouchEnd={() => setYahooHover(false)}
              onMouseLeave={() => setYahooHover(false)}
            >
              <Icon name="yahoo" color={!yahooHover ? '#63435B' : '#fff'} size="s" />
              Yahoo
            </a>
            <a
              href={`${ics({
                title: wedding.name,
                description: wedding.mainEvent.description,
                start: wedding.mainEvent.startTimestamp,
                end: wedding.mainEvent.endTimestamp,
                location: wedding.mainEvent.location?.address,
              })}`}
              target="_blank"
              rel="noreferrer"
              className="btnMain"
              style={{
                borderRadius: '8px',
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '12px 21px',
                color: !appleHover ? '#63435B' : '#fff',
              }}
              onTouchStart={() => setAppleHover(true)}
              onMouseEnter={() => setAppleHover(true)}
              onTouchEnd={() => setAppleHover(false)}
              onMouseLeave={() => setAppleHover(false)}
            >
              <Icon name="apple" color={!appleHover ? '#63435B' : '#fff'} size="s" />
              Apple
            </a>
          </div>
          <div style={{ margin: 12 }}>- or -</div>
          <a
            href={`${ics({
              title: wedding.name,
              description: wedding.mainEvent.description,
              start: wedding.mainEvent.startTimestamp,
              end: wedding.mainEvent.endTimestamp,
              location: wedding.mainEvent.location?.address,
            })}`}
            target="_self"
            rel="noreferrer"
            className="btnSecondary btnBordered"
            style={{
              borderRadius: '50px',
              display: 'flex',
              gap: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 16px',
              color: '#63435B',
            }}
          >
            <Icon name="calendar" color="#63435B" size="s" />
            Download .ics file
          </a>
          <p className={'didacticP'} style={{ color: '#A9A4A7', marginTop: 12 }}>
            An ICS file is a calendar file saved in a universal calendar format used by several email and calendar
            programs, including Microsoft Outlook, Google Calendar, and Apple Calendar.
          </p>
        </div>
      )}
      {!success && new Date(wedding?.mainEvent?.startTimestamp || '') < new Date() && (
        <p style={{ textAlign: 'center', zIndex: 1 }}>
          {' '}
          This wedding has passed! <br /> <br />
          <b>
            <a href="https://whiteclover.io/">Whiteclover</a>
          </b>
        </p>
      )}
      {!success && new Date(wedding?.mainEvent?.startTimestamp || '') >= new Date() && wedding && (
        <section className={styles.board}>
          <div className={styles.sticky} style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex', gap: 24, justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ maxHeight: '128px' }}>
                <h1 className="fridayH1">
                  {dayjs(wedding.mainEvent?.startTimestamp ?? new Date())
                    .tz(
                      wedding?.mainEvent?.location?.externalQuickCode
                        ? wedding?.mainEvent?.location?.externalQuickCode
                        : undefined,
                    )
                    .toDate()
                    .getDate()
                    .toLocaleString('en-UK', { minimumIntegerDigits: 2 })}{' '}
                  {(
                    dayjs(wedding.mainEvent?.startTimestamp ?? new Date())
                      .tz(
                        wedding?.mainEvent?.location?.externalQuickCode
                          ? wedding?.mainEvent?.location?.externalQuickCode
                          : undefined,
                      )
                      .toDate()
                      .getMonth() + 1
                  ).toLocaleString('en-UK', {
                    minimumIntegerDigits: 2,
                  })}
                </h1>
                <h1 className="fridayH1">
                  {dayjs(wedding.mainEvent?.startTimestamp ?? new Date())
                    .tz(
                      wedding?.mainEvent?.location?.externalQuickCode
                        ? wedding?.mainEvent?.location?.externalQuickCode
                        : undefined,
                    )
                    .toDate()
                    .getFullYear()}
                </h1>
              </div>
              <div style={{ maxHeight: '128px' }}>
                <svg width="2" height="112" viewBox="0 0 2 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 0L1 112" stroke="black" />
                </svg>
              </div>
              <div style={{ maxHeight: '128px' }}>
                <h1 className="fridayH1" style={{ textAlign: 'left' }}>
                  Save
                </h1>
                <h1 className="fridayH1" style={{ textAlign: 'left' }}>
                  Our date
                </h1>
              </div>
            </div>
            <br />

            <h2 className={'didacticH2'}>{wedding?.name}</h2>
            <h4 className={'didacticH4'}>invited you to save this date</h4>
            <br />
            <a
              className={'didacticH4'}
              style={{ color: '#969E6B' }}
              target="_blank"
              href={`https://maps.google.com/?q=${wedding.mainEvent?.location?.point?.latitude},${wedding.mainEvent?.location?.point?.longitude}`}
              rel="noreferrer"
            >
              {wedding.mainEvent?.location?.address}
            </a>
            <br />
            <br />
            <h4 className={'didacticH4'} style={{ width: '100%', textAlign: 'left', paddingBottom: '8px' }}>
              Join the {GetUI(wedding.socialEventType as EventType).startPlanningEventType}
            </h4>
            <p className={'didacticP'} style={{ width: '100%', textAlign: 'left', paddingBottom: '8px' }}>
              Please complete the form below to gain access to our experience app and receive updates.
            </p>
            <input
              placeholder="Full Name"
              className="input"
              style={{
                width: '100%',
                border:
                  !clearErrorState && newVisitors[0].firstName.length < 3 ? '1px dashed #D54F68' : '1px solid grey',
                marginBottom: '16px',
              }}
              onChange={(e) => setVisitor('fullName', e.target.value, 0)}
            />
            {emailError.length > 0 && emailError[0] !== '' ? <p style={{ color: 'red' }}>{emailError[0]}</p> : ''}
            <input
              placeholder="Email"
              className="input"
              style={{
                width: '100%',
                border:
                  (!clearErrorState && newVisitors[0].email.length < 4) ||
                  (emailError.length > 0 && emailError[0] !== '')
                    ? '1px dashed #D54F68'
                    : '1px solid grey',
                marginBottom: '16px',
              }}
              onChange={(e) => setVisitor('email', e.target.value, 0)}
            />
            {phoneError.length > 0 && phoneError[0] !== '' ? <p style={{ color: 'red' }}>{phoneError[0]}</p> : ''}
            <PhoneInput
              placeholder={'Phone Number'}
              containerStyle={{
                border:
                  (!clearErrorState && newVisitors[0].phone.length < 4) ||
                  (phoneError.length > 0 && phoneError[0] !== '')
                    ? '1px dashed #D54F68'
                    : '1px solid grey',
                borderRadius: '8px',
                width: '100%',
              }}
              value={newVisitors[0].phone}
              onChange={(value, data, event, formattedValue) => setVisitor('phone', formattedValue, 0)}
              onBlur={(event, data: CountryData | Record<string, unknown>) => {
                if (data && event.target.value.split(' ')[0] !== `+${data.dialCode}`)
                  setVisitor('phone', `+${data.dialCode} ${event.target.value.slice(1, event.target.value.length)}`, 0);
                setPhoneData((prevState) => {
                  const copyWithSpread = prevState.slice();
                  copyWithSpread[0] = data;
                  return copyWithSpread;
                });
              }}
              country={phoneInputCountry}
            />
            {newVisitors.length > 1 &&
              newVisitors.map((newVisitor, index) => (
                <Fragment key={index}>
                  {index !== 0 && (
                    <Fragment>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4
                          className={'didacticH4'}
                          style={{ width: '100%', textAlign: 'left', marginTop: '8px', marginBottom: '8px' }}
                        >
                          Plus one&apos;s info
                        </h4>
                        <button
                          className="btnMainImg"
                          style={{
                            background: 'transparent',
                            border: 'none',
                            borderRadius: '50px',
                            padding: '4px 5px',
                            display: 'flex',
                            gap: 8,
                            justifyContent: 'center',
                          }}
                          onClick={() => removeVisitor(index)}
                        >
                          <Icon name="trash" size="s" />
                        </button>
                      </div>
                      <input
                        onChange={(e) => setVisitor('fullName', e.target.value, index)}
                        placeholder="Full Name"
                        className="input"
                        style={{
                          width: '100%',
                          border:
                            !clearErrorState && newVisitors[index].firstName.length < 2
                              ? '1px dashed #D54F68'
                              : '1px solid grey',
                          marginBottom: '16px',
                        }}
                      />
                      {emailError.length > index && emailError[index] !== '' ? (
                        <p style={{ color: 'red' }}>{emailError[index]}</p>
                      ) : (
                        ''
                      )}
                      <input
                        onChange={(e) => setVisitor('email', e.target.value, index)}
                        placeholder="Email"
                        className="input"
                        type="email"
                        style={{
                          width: '100%',
                          border:
                            (!clearErrorState && newVisitors[index].email.length < 4) ||
                            (emailError.length > 0 && emailError[index] !== '')
                              ? '1px dashed #D54F68'
                              : '1px solid grey',
                          marginBottom: '16px',
                        }}
                      />
                      {phoneError.length > index && phoneError[index] !== '' ? (
                        <p style={{ color: 'red' }}>{phoneError[index]}</p>
                      ) : (
                        ''
                      )}
                      <PhoneInput
                        placeholder={'Phone Number'}
                        containerStyle={{
                          border:
                            (!clearErrorState && newVisitors[index].phone.length < 4) ||
                            (phoneError.length > 0 && phoneError[index] !== '')
                              ? '1px dashed #D54F68'
                              : '1px solid grey',
                          borderRadius: '8px',
                          width: '100%',
                        }}
                        value={newVisitors[index].phone}
                        onChange={(value, data, event, formattedValue) => setVisitor('phone', formattedValue, index)}
                        onBlur={(event, data: CountryData | Record<string, unknown>) => {
                          if (data && event.target.value.split(' ')[0] !== `+${data?.dialCode}`)
                            setVisitor(
                              'phone',
                              `+${data?.dialCode} ${event.target.value.slice(1, event.target.value.length)}`,
                              index,
                            );
                          setPhoneData((prevState) => {
                            const copyWithSpread = prevState.slice();
                            copyWithSpread[index] = data;
                            return copyWithSpread;
                          });
                        }}
                        country={phoneInputCountry}
                      />
                    </Fragment>
                  )}
                </Fragment>
              ))}
            {Number(maxAllowed) !== 0 && (plusOnes === -1 || newVisitors.length < plusOnes + 1) && (
              <button
                className="btnMain didacticP"
                style={{
                  borderRadius: '50px',
                  padding: '8px 16px',
                  display: 'flex',
                  gap: 8,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onMouseEnter={() => toggleIcon(true)}
                onMouseLeave={() => toggleIcon(false)}
                onClick={addNewVisitor}
              >
                <Icon color={iconToggle ? 'white' : 'black'} name="add_user" size="s" />
                Add Plus One
              </button>
            )}

            <p className="didacticP" style={{ textAlign: 'left', fontSize: '12px', padding: '16px 0px' }}>
              *All of your information is only shared with the event planner.
            </p>
            <button
              disabled={!isValid}
              className="btnMain input"
              style={{ width: '100%', border: 'none' }}
              onClick={joinWedding}
            >
              Join the {GetUI(wedding.socialEventType as EventType).startPlanningEventType}
            </button>
          </div>
        </section>
      )}
      <ToastContainer
        closeButton={false}
        style={{ width: 'fit-content', borderRadius: 8 }}
        icon={<Icon name={'accept'} color={'#006150'} size={'s'} />}
        position="bottom-center"
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default SelfInvite;
