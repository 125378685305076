import React, { CSSProperties, useState, useEffect } from 'react';
import { Variants } from 'framer-motion';
import { motion, useAnimation } from 'framer-motion';

const RED_COLOR = `#f9ac80`;

const outerCircleVariants: Variants = {
  circle: {
    transform: 'scale(1)',
    opacity: 1,
    // boxShadow: `0px 0px 0px 0px ${RED_COLOR}`,
  },
  largeCircle: {
    transform: 'scale(1.1)',
    opacity: 1,
    // boxShadow: `0px 0px 0px 4px ${RED_COLOR}`,
  },
  pulseIn: {
    transform: 'scale(1.02)',
    opacity: 1,
    // boxShadow: `0px 0px 0px 8px ${RED_COLOR}`,
  },
  pulseOut: {
    transform: 'scale(1)',
    opacity: 1,
    // boxShadow: `0px 0px 0px 4px ${RED_COLOR}`,
  },
};

// const innerCircleVariants: Variants = {
//   circle: {
//     transform: 'scale(1)',
//     borderRadius: '100%',
//   },
//   square: {
//     transform: 'scale(0.8)',
//     borderRadius: '30%',
//   },
//   invisible: {
//     transform: 'scale(0)',
//     borderRadius: '100%',
//   },
// };

type SignInButtonProps = {
  disabled: boolean;
  onClick: (event: any) => Promise<void>;
};
export const SignInButton = (props: SignInButtonProps): JSX.Element => {
  const [hover, setHover] = useState<boolean>(false);
  const innerCircleAnimation = useAnimation();
  const outerCircleAnimation = useAnimation();

  useEffect(() => {
    (async () => {
      if (hover) {
        await outerCircleAnimation.start('largeCircle');
        await outerCircleAnimation.start(['pulseOut', 'pulseIn'], {
          repeat: Infinity,
          repeatType: 'mirror',
        });
      } else {
        await outerCircleAnimation.start('circle');
      }
    })();
  }, [hover]);

  useEffect(() => {
    (async () => {
      if (hover) {
        await innerCircleAnimation.start('square');
        await innerCircleAnimation.start('invisible');
      } else {
        await innerCircleAnimation.start('circle');
      }
    })();
  }, [hover]);

  return (
    <motion.div drag style={styles.container} onClick={() => setHover(true)}>
      <motion.button
        initial="circle"
        animate={outerCircleAnimation}
        variants={outerCircleVariants}
        disabled={props.disabled}
        className={'btnMain signInBtn'}
        onClick={async (event: any) => {
          event.preventDefault();
          await props.onClick(event);
          setHover(false);
        }}
      >
        Sign In{' '}
      </motion.button>
    </motion.div>
  );
};

const styles: Record<string, CSSProperties> = {
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    position: 'absolute',
    backgroundColor: RED_COLOR,
  },
  outerCircle: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '10px',
  },
  innerCircle: {
    width: '90%',
    height: '90%',
    overflow: 'hidden',
    backgroundColor: RED_COLOR,
  },
};
