import React, { useEffect } from 'react';
import WeddingCardList from '../weddingCardList/WeddingCardList';
import Header from '../header/Header';
import './ChooseWedding.scss';
import Icon from '../../../shared/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectUser } from '../../../app/redux/authorization.slice.reducer';
import { setRsvpEventsReducer, setWedding } from '../../../app/redux/wedding.slice.recuder';
import {
  removeComponentProps,
  removeCurrentWeddingWebsite,
  removeSelectedTemplate,
  removesetWeddingWebsites,
} from '../../../app/redux/websiteBuilder.slice.reducer';

const ChooseWedding = (): JSX.Element => {
  const user = useSelector(selectUser);
  const userToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWedding(undefined));
    dispatch(setRsvpEventsReducer(undefined));
    dispatch(removeSelectedTemplate(undefined));
    dispatch(removeCurrentWeddingWebsite(undefined));
    dispatch(removeComponentProps(undefined));
    dispatch(removesetWeddingWebsites(undefined));
  }, []);

  return (
    <section>
      {/*HEADER*/}
      <Header simple logout />
      <div className={'mainClass'}>
        {/*Greetings*/}
        <div className={'chooseWeddingGreetings'}>
          <p className={'fridayH1'}>Hello, {user.tenantName}</p>
          <p>Choose Social Event</p>
        </div>
        {/*TOOLBAR*/}
        <div className={'chooseWeddingToolbar'}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a style={{ cursor: 'pointer' }} href={'/dashboard/choose-event/start-planning'}>
            <Icon color="#63435B" name={'add'} size={'s'} />
            Create a Social Event
          </a>
        </div>
        {/*CHOOSE WEDDING */}
        <div className={'chooseWeddingCarousel'}>
          <WeddingCardList accountId={user.accountId} userToken={userToken} />
        </div>
      </div>
    </section>
  );
};

export default ChooseWedding;
