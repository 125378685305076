import React, { useEffect, useState } from 'react';
import templateStyles from '../sepiaTemplate.module.scss';
import styles from './SepiaThanksComponent.module.scss';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';
import { Imagehelper } from '../../../app/helper/Imagehelper';

enum ItsAllThanksToImagesLayout {
  layout_2_images = 0,
  layout_2_cols_3_images = 1,
  layout_2_cols_4_images = 2,
}

type ItsAllThanksToComponentProps = {
  title?: string;
  imagesLayout?: ItsAllThanksToImagesLayout;
  image1?: string;
  image2?: string;
  image3?: string;
  image4?: string;
  fields?: Array<{ title: string; description: string }>;
};

const defaultValues: ItsAllThanksToComponentProps = {
  title: `It's all thanks to`,
  imagesLayout: ItsAllThanksToImagesLayout.layout_2_images,

  image1: '/templates/sepia/ItsAllThanksToImage1.png',
  image2: '/templates/sepia/ItsAllThanksToImage2.png',
  image3: '/templates/sepia/ItsAllThanksToImage3.png',
  image4: '/templates/sepia/ItsAllThanksToImage4.png',

  fields: [],
};

const SepiaThanksComponent = ({
  title,
  imagesLayout = ItsAllThanksToImagesLayout.layout_2_images,
  image1,
  image2,
  image3,
  image4,
  fields,
}: ItsAllThanksToComponentProps): JSX.Element => {
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const [blob1, setBlob1] = useState('');
  const [blob2, setBlob2] = useState('');
  const [blob3, setBlob3] = useState('');
  const [blob4, setBlob4] = useState('');

  const fetchPostImage1 = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob1(result);
  };
  const fetchPostImage2 = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob2(result);
  };
  const fetchPostImage3 = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob3(result);
  };
  const fetchPostImage4 = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') setBlob4(result);
  };

  useEffect(() => {
    // console.log('IMAGE1');
    if (image1 && image1 != defaultValues.image1) {
      fetchPostImage1(image1);
    }
  }, [image1]);

  useEffect(() => {
    // console.log('IMAGE2');
    if (image2 && image2 != defaultValues.image1) {
      fetchPostImage2(image2);
    }
  }, [image2]);

  useEffect(() => {
    // console.log('IMAGE3');
    if (image3 && image3 != defaultValues.image1) {
      fetchPostImage3(image3);
    }
  }, [image3]);

  useEffect(() => {
    // console.log('IMAGE4');
    if (image4 && image4 != defaultValues.image1) {
      fetchPostImage4(image4);
    }
  }, [image4]);

  return (
    <section id={`SepiaThanksComponent`} className={styles.ItsAllThanksToComponent}>
      <h2 className={templateStyles.fridayH2}>
        {String(title ? title.toUpperCase() : defaultValues.title).toUpperCase()}
      </h2>
      {imagesLayout === ItsAllThanksToImagesLayout.layout_2_images && (
        <div className={styles.layout_2_images}>
          <img src={blob1 ? blob1 : defaultValues.image1} alt="small logo" />
          <img src={blob2 ? blob2 : defaultValues.image2} alt="small logo" />
        </div>
      )}
      {imagesLayout === ItsAllThanksToImagesLayout.layout_2_cols_3_images && (
        <div className={styles.layout_2_cols_3_images}>
          <img src={blob1 ? blob1 : defaultValues.image1} alt="small logo" />
          <div>
            <img src={blob2 ? blob2 : defaultValues.image2} alt="small logo" />
            <img src={blob3 ? blob3 : defaultValues.image3} alt="small logo" />
          </div>
        </div>
      )}
      {imagesLayout === ItsAllThanksToImagesLayout.layout_2_cols_4_images && (
        <div className={styles.layout_2_cols_4_images}>
          <img src={blob1 ? blob1 : defaultValues.image1} alt="small logo" />
          <div>
            <img src={blob2 ? blob2 : defaultValues.image2} alt="small logo" />
            <div>
              <img src={blob3 ? blob3 : defaultValues.image3} alt="small logo" />
              <img src={blob4 ? blob4 : defaultValues.image4} alt="small logo" />
            </div>
          </div>
        </div>
      )}
      <div>
        {fields?.map((q, index) => {
          if (q.title !== '') {
            return (
              <div key={index} className={styles.fields}>
                <p className={templateStyles.fridayH3}>{q.title}</p>
                <p className={templateStyles.didacticP}>{q.description}</p>
              </div>
            );
          }
        })}
      </div>
    </section>
  );
};
export default SepiaThanksComponent;
