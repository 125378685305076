import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../header/Header';
import styles from './Entrance.module.scss';
import { logger } from '../../../config/Logger';
import firebase from 'firebase';
import { FirebaseContext } from '../../../context/firebase.context';
import jwt_decode from 'jwt-decode';
import { GetDefaultRolesRequest, LoginRequest, RoleDto } from '../../../pkg/protobuf/v2/auth/auth_types_pb';
import { AuthHelper } from '../../../pkg/apiHelpers/authHelper';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectWedding, setIsTutorialOn, setWedding, setWeddings } from '../../../app/redux/wedding.slice.recuder';
import {
  selectAccount,
  selectIsLoggedIn,
  selectUser,
  setAccessToken,
  setAccessTokenAfterOTP,
  setConnectedAccount,
  setJwt,
  setLoggedIn,
  setProfile,
  setTokenExpiryDate,
  setUser,
} from '../../../app/redux/authorization.slice.reducer';
import { Wedding } from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import location from '../../../loader-animation.json';
import Lottie from 'react-lottie';
import cleanLocalStorage from '../../../app/helper/CleanLocalStorage';
import { transliterate } from 'transliteration';
import { motion } from 'framer-motion';
import Icon from '../../../shared/Icon';
import { lottieDefaultOptions } from '../../../shared/Options';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Entrance = (props: any): JSX.Element => {
  const [state, setState] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [confirmationResult, setConfirmationResult] = useState<any>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [repeatPasswordType, setRepeatPasswordType] = useState('password');
  const [role, setRole] = useState(new RoleDto());
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(false);
  const stateRef = React.useRef<any>();
  const user = useSelector(selectUser);
  const loggedIn = useSelector(selectIsLoggedIn);
  const account = useSelector(selectAccount);
  const wedding: Wedding = useSelector(selectWedding);
  const userRef = React.useRef<any>(user);
  userRef.current = user;
  stateRef.current = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [completedStyle, setCompletedStyle] = useState({ top: 0, left: 0, width: 0, height: 0 });

  useEffect(() => {
    FirebaseContext.getAuth();
    if (loggedIn && wedding.path === 'no-marriage-yet') {
      navigate(`/dashboard/choose-event/start-planning`);
    } else if (
      loggedIn &&
      wedding?.path !== '' &&
      (account?.hostForWeddingId === wedding?.id || account?.moderatorForWeddingId === wedding?.id)
    ) {
      navigate(`/dashboard/w/${transliterate(wedding.path)}/overview`);
    } else if (loggedIn && wedding?.path === '') {
      navigate('/dashboard/choose-event');
    } else {
      firebase.auth().signOut();
      cleanLocalStorage();
    }

    async function fetchStatus() {
      try {
        const requestMessage: GetDefaultRolesRequest = new GetDefaultRolesRequest();
        const resp = await AuthHelper.publicGetDefaultRolesPromise(requestMessage);
        resp?.roles.map((value: RoleDto) => {
          logger.debug({ message: value.name });
          if (value.name === 'defaultHostRoleAll') setRole(value);
        });
      } catch (e: any) {
        logger.error({ message: e.message, functionName: 'Entrance.publicGetDefaultRolesPromise' });
        throw e;
      }
    }

    fetchStatus();
  }, []);

  const onSignInSubmit = async () => {
    setIsSecondButtonLoading(true);
    try {
      const confirmationResult = await firebase.auth().signInWithEmailAndPassword(email, password);

      try {
        setConfirmationResult(confirmationResult);
        const jwtFirebaseAuthToken = await confirmationResult.user?.getIdTokenResult(true);

        if (jwtFirebaseAuthToken) {
          const loginRequest = new LoginRequest();
          loginRequest.jwtToken = jwtFirebaseAuthToken.token;
          loginRequest.roleId = role.id;
          const resp = await AuthHelper.loginRegisterFirebaseUser(loginRequest);

          if (!resp?.jwtToken) {
            logger.error({ message: 'empty user token', functionName: 'Entrance.onSignInSubmit' });
            throw new Error('empty user token');
          }

          setState({ ...state, isSignedIn: true });

          // read jwt token
          const decoded: any = jwt_decode(resp?.jwtToken);
          dispatch(setUser(decoded));
          const expiredIn = decoded.exp;

          const weddingData = await BrainHelper.getWedding(
            resp?.jwtToken,
            'no-marriage-yet',
            dispatch,
            stateRef,
            setState,
            setWedding,
          );
          const firebaseTokenDecoded: any = jwt_decode(jwtFirebaseAuthToken.token);
          if (firebaseTokenDecoded.email_verified) {
            const connectedResponse = await BrainHelper.connectAccount(
              resp?.jwtToken,
              weddingData.id,
              decoded.accountId,
              dispatch,
              stateRef,
              setState,
              userRef,
              setConnectedAccount,
              setJwt,
            );
            if (connectedResponse !== null && connectedResponse?.connectedAccount?.accountId) {
              // const headers = new Headers();
              // headers.set('Authorization', `Bearer ${resp.jwtToken}`);
              // const listAccountDetailsRequest = new ListAccountsDetailsRequest();
              // listAccountDetailsRequest.accountIds.push(connectedResponse?.connectedAccount?.accountId);
              // const responseMessage = await Backend.authClientV2.listAccountsDetails(listAccountDetailsRequest, {
              //   headers,
              // });
              dispatch(setProfile(connectedResponse?.connectedAccount));
              dispatch(setLoggedIn(true));
              dispatch(setAccessToken(resp?.jwtToken));
              dispatch(setAccessTokenAfterOTP(connectedResponse.jwtToken));
              dispatch(setTokenExpiryDate(Number(expiredIn)));

              const listWeddings = await BrainHelper.listHostAndModeratorWeddings(
                decoded.accountId,
                connectedResponse.jwtToken,
                '',
                email,
              );
              dispatch(setWeddings(listWeddings));

              if (listWeddings.length > 1) {
                navigate(`/dashboard/choose-event`);
              } else if (listWeddings.length === 1) {
                dispatch(setWedding(listWeddings[0]));
                dispatch(setIsTutorialOn(true));
                const connectedResponseToSpecificWedding = await BrainHelper.connectAccount(
                  connectedResponse?.jwtToken,
                  listWeddings[0].id,
                  decoded.accountId,
                  dispatch,
                  stateRef,
                  setState,
                  userRef,
                  setConnectedAccount,
                  setJwt,
                );
                if (connectedResponseToSpecificWedding !== null) {
                  dispatch(setLoggedIn(true));
                  dispatch(setAccessTokenAfterOTP(connectedResponseToSpecificWedding.jwtToken));
                  dispatch(setProfile(connectedResponseToSpecificWedding.connectedAccount));

                  setIsSecondButtonLoading(false);

                  navigate(`/dashboard/w/${transliterate(listWeddings[0]?.path)}/overview`);
                }
              } else {
                navigate('/dashboard/choose-event/start-planning');
              }
            } else {
              logger.error({
                message: 'connectedResponse error',
                functionName: 'Entrance.onSignInSubmit.catchSendConfirmation',
              });
              setModalMessage('Something went wrong. Please try again later');
              setShowModal(true);
              setIsSecondButtonLoading(false);
              setCompleted(false);
            }
          } else {
            throw new Error('Email is not verified');
          }
        }
      } catch (e: any) {
        console.error(e);
        logger.error({ message: e, functionName: 'Entrance.onSignInSubmit.catchSendConfirmation' });
        setModalMessage(e?.message ? e.message : 'Something went wrong');
        setShowModal(true);
        setIsSecondButtonLoading(false);
        setCompleted(false);
      }
    } catch (error: any) {
      logger.error({ message: error, functionName: 'Entrance.onSignInSubmit.signInWithEmailOrPhone' });
      setModalMessage(error?.message ? error.message : 'Something went wrong');
      setShowModal(true);
      setIsSecondButtonLoading(false);
      setCompleted(false);
    }
  };

  const onSignUpSubmit = async () => {
    if (checkSignUpSubmit()) {
      try {
        const confirmationResult = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password.replaceAll(' ', ''));

        setConfirmationResult(confirmationResult);
        const jwtFirebaseAuthToken = await confirmationResult.user?.getIdTokenResult(true);
        const loginRequest = new LoginRequest();
        if (jwtFirebaseAuthToken) loginRequest.jwtToken = jwtFirebaseAuthToken.token;
        loginRequest.roleId = role.id;
        await AuthHelper.loginRegisterFirebaseUser(loginRequest);
        confirmationResult.user?.sendEmailVerification();
        navigate('/dashboard/complete');
      } catch (err: any) {
        console.log(err);
        logger.error({ message: err, functionName: 'Entrance.onSignUpSubmit' });
        setModalMessage(err.message);
        setShowModal(true);
        setCompleted(false);
      }
    } else setCompleted(false);
  };

  const checkSignUpSubmit = () => {
    let response = true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let el: HTMLElement | null;
    if (password.trim() === '' || repeatPassword.trim() === '') {
      response = false;
      setModalMessage('Password field is empty');
      setShowModal(true);
    }
    if (password.replaceAll(' ', '') !== repeatPassword.replaceAll(' ', '')) {
      response = false;
      setModalMessage('Both password fields should match');
      setShowModal(true);
    }
    return response;
  };

  const createAccountValues = {
    login: false,
    title: 'Create Account',
    description: `Welcome! Just a few clicks away from your
    perfect event. `,
    repeatPass: true,
    smallP: (
      <Fragment>
        <p style={{ marginBottom: '32px', textAlign: 'center' }} className="didacticP">
          - Already with us? -
        </p>
        <button
          className={'btn btnMain3rd'}
          onClick={() => {
            navigate('/dashboard/login');
          }}
          onMouseDown={() => onClearValues()}
          style={{ fontSize: '1em' }}
        >
          Log in
        </button>
      </Fragment>
    ),
    btnText: "Let's start",
  };
  const loginValues = {
    login: true,
    title: 'Log in',
    description: 'Hey! Enter you details to get signed in to your account.',
    repeatPass: false,
    smallP: (
      <Fragment>
        <p style={{ marginBottom: '32px', textAlign: 'center' }} className="didacticP">
          - Or use below actions -
        </p>
        <div className={styles.smallPValues}>
          <button
            className={'btn btnMain3rd'}
            onClick={() => {
              navigate('/dashboard/create-account');
            }}
            style={{ fontSize: '1em' }}
            onMouseDown={() => onClearValues()}
          >
            Create New Account
          </button>
          <button
            className={'btn btnMain4th'}
            onClick={() => {
              navigate('/reset');
            }}
            style={{ fontSize: '1em' }}
            onMouseDown={() => onClearValues()}
          >
            Reset Password
          </button>
        </div>
      </Fragment>
    ),
    btnText: 'Login',
  };

  useEffect(() => {
    const s = props.log ? loginValues : createAccountValues;
    setState(s);
  }, [props.log]);

  const onClearValues = () => {
    setEmail('');
    setPassword('');
    setRepeatPassword('');
  };

  return (
    <div className={styles.main}>
      <Header login={Boolean(state.login)} />
      <div className={styles.graphics}>
        {state.login ? (
          <>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              src={'/assets/graphics/entrance-graphics-1.svg'}
            />
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.5 }}
              src={'/assets/graphics/entrance-graphics-2.svg'}
              alt={''}
            />
          </>
        ) : (
          <>
            <motion.img
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5 }}
              src={'/assets/graphics/entrance-graphics-3.svg'}
              alt={''}
            />
            <motion.img
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5 }}
              src={'/assets/graphics/entrance-graphics-4.svg'}
              alt={''}
            />
          </>
        )}
      </div>
      <motion.div className={styles.wrapper}>
        <div className={styles.header}>
          <h1 className="fridayH1">{state.title}</h1>
          <p className="didacticP">{state ? state.description : ''}</p>
        </div>
        {showModal ? <p style={{ color: 'red' }}>{modalMessage}</p> : ''}
        {/*{showModal ? <GenericModal showModal={showModal} message={modalMessage} setShowModal={setShowModal} /> : ''}*/}
        <form ref={formRef} action="#" className={styles.form}>
          <input
            className={'input'}
            type="text"
            name={'email'}
            placeholder={'Email'}
            value={email}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              setEmail(e.target.value);
              setShowModal(false);
            }}
          />
          <div style={{ position: 'relative' }}>
            <input
              className={'input'}
              type={passwordType}
              name={'password'}
              placeholder={'Password'}
              value={password}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                setPassword(e.target.value);
                setShowModal(false);
              }}
            />
            <button
              style={{ position: 'absolute', right: 20, top: 21, background: 0, border: 0 }}
              onClick={async (event) => {
                event.preventDefault();
                setPasswordType((prev) => {
                  if (prev === 'password') return 'text';
                  else return 'password';
                });
              }}
            >
              <Icon name={passwordType === 'password' ? 'eye' : 'eye_filled'} color={'#63435B'} size={'s'} />
            </button>
          </div>

          {state.repeatPass ? (
            <div style={{ position: 'relative' }}>
              <input
                className={'input'}
                type={repeatPasswordType}
                name={'passwordRepeat'}
                placeholder={'Repeat password'}
                value={repeatPassword}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
              <button
                style={{ position: 'absolute', right: 20, top: 21, background: 0, border: 0 }}
                onClick={async (event) => {
                  event.preventDefault();
                  setRepeatPasswordType((prev) => {
                    if (prev === 'password') return 'text';
                    else return 'password';
                  });
                }}
              >
                <Icon name={repeatPasswordType === 'password' ? 'eye' : 'eye_filled'} color={'#63435B'} size={'s'} />
              </button>
            </div>
          ) : (
            <></>
          )}

          {/*{!isSecondButtonLoading ? (*/}
          {/*  <button*/}
          {/*    className={'btn btnMain '}*/}
          {/*    type={'submit'}*/}
          {/*    onClick={async (event) => {*/}
          {/*      event.preventDefault();*/}
          {/*      props.log ? onSignInSubmit() : onSignUpSubmit();*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {state.btnText}*/}
          {/*  </button>*/}
          {/*) : (*/}
          {/*  <Lottie options={lottieDefaultOptions} height={50} width={200} />*/}
          {/*)}*/}
          <button
            // btnText={state.btnText}
            className={'btn btnMain '}
            style={{ marginBottom: '24px' }}
            onClick={async (event) => {
              event.preventDefault();
              setCompleted(true);
              setCompletedStyle({
                top: (formRef?.current?.offsetTop || 0) - 5,
                left: formRef?.current?.offsetLeft || 0,
                width: formRef?.current?.offsetWidth || 0,
                height: (formRef?.current?.offsetHeight || 0) - 5,
              });
              props.log ? await onSignInSubmit() : await onSignUpSubmit();
            }}
          >
            {state.btnText}
          </button>
        </form>
        {completed && (
          <div
            className="loadingView"
            style={{
              position: 'absolute',
              top: completedStyle.top,
              left: completedStyle.left,
              width: completedStyle.width,
              height: completedStyle.height,
              backdropFilter: 'blur(1px)',
            }}
          >
            <Lottie options={lottieDefaultOptions} height={300} width={300} />
          </div>
        )}
        {state ? state.smallP : ''}
      </motion.div>
    </div>
  );
};

export default Entrance;
