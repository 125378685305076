import { memo, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import styled, { ThemeContext } from 'styled-components';
import {
  TotalAllowedVisitorsResponse,
  Wedding,
  WeddingWebsite,
} from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Icon } from '../../../../shared';
import { TextField } from '@mui/material';
import { transliterate } from 'transliteration';
import GetUI, { EventType } from '../../../../utils/uiContent';

type StyledProps = {
  selected: boolean;
};
const Node = styled.div<StyledProps>`
  border-radius: 25px;
  background: ${(props) => props.theme.nodeBg};
  color: ${(props) => props.theme.nodeColor};
  border: 1px solid ${(props) => props.theme.weddingNodeColor};
  padding: 24px;
  //border-image: url('assets/icons/ceremony.svg');

  .react-flow__handle {
    background: ${(props) => props.theme.weddingNodeColor};
    width: 8px;
    height: 10px;
    border-radius: 3px;
  }

  .nodeHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    gap: 12px;
    margin-bottom: 16px;

    h3 {
      color: ${(props) => props.theme.weddingNodeColor};
    }

    p {
      color: ${(props) => props.theme.weddingNodeColor};
    }

    b {
      color: ${(props) => props.theme.weddingNodeColor};
    }
  }

  .nodeDesign {
    color: ${(props) => props.theme.weddingNodeColor};
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      color: ${(props) => props.theme.weddingNodeColor};
    }
    p {
      color: ${(props) => props.theme.weddingNodeColor};
      font-size: 14px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.weddingNodeColor} !important;
  }

  .MuiFilledInput-root {
    border-bottom: 1px solid ${(props) => props.theme.weddingNodeColor} !important;
  }

  .MuiInput-root {
    border-bottom: 1px solid ${(props) => props.theme.weddingNodeColor} !important;
  }

  .Mui-focused {
    color: ${(props) => props.theme.weddingNodeColor};
  }

  .MuiInputLabel-root {
    color: ${(props) => props.theme.weddingNodeColor} !important;
    font-family: 'DidactGothic-Regular';
  }
`;

type WeddingNodeProps = {
  data: {
    wedding: Wedding;
    visitorStats: TotalAllowedVisitorsResponse | undefined;
    weddingWebsite: WeddingWebsite | undefined;
    rsvpEventsTotal: number;
    status: string;
    dateStatus: string;
  };
  selected: boolean;
};
const WeddingNode = ({ data, selected }: WeddingNodeProps) => {
  const themeContext = useContext(ThemeContext);

  // useEffect(() => {
  //   console.log('WeddingNode', { data, selected });
  // }, []);

  return (
    <Node selected={selected}>
      <Handle type="target" position={Position.Left} />
      <div className={'nodeHeader'}>
        <Icon name={'ceremony'} size={'m'} color={themeContext?.weddingNodeColor} />
        <h3>{GetUI(data?.wedding?.socialEventType as EventType).startPlanningEventType}</h3>
        <div>
          <p>
            visitors: <b>{data?.visitorStats?.total.toString()}</b>
          </p>
          <p>
            website: <b>{data?.weddingWebsite?.visibilityStatus === 1 ? 'public' : 'private'}</b>
          </p>
          <p>
            events: <b>{data?.rsvpEventsTotal}</b>
          </p>
          <p>
            status: <b>{data?.status}</b>
          </p>
          <p>
            date status: <b>{data?.dateStatus}</b>
          </p>
        </div>
      </div>
      <div className={'nodeDesign'}>
        <TextField
          value={data?.wedding?.name}
          focused={true}
          style={{ width: '350px' }}
          InputProps={{
            readOnly: true,
            style: {
              color: themeContext?.weddingNodeColor,
              borderColor: themeContext?.weddingNodeColor,
              fontFamily: 'DidactGothic-Regular',
            },
          }}
          label={'Name'}
          // fullWidth={true}
        ></TextField>
        <TextField
          value={transliterate(data?.wedding?.path)}
          focused={true}
          style={{ width: '350px' }}
          InputProps={{
            readOnly: true,
            style: {
              color: themeContext?.weddingNodeColor,
              borderColor: themeContext?.weddingNodeColor,
              fontFamily: 'DidactGothic-Regular',
            },
          }}
          label={'Path'}
          // fullWidth={true}
        ></TextField>
        <TextField
          value={data?.wedding?.dateCreated}
          focused={true}
          style={{ width: '350px' }}
          InputProps={{
            readOnly: true,
            style: {
              color: themeContext?.weddingNodeColor,
              borderColor: themeContext?.weddingNodeColor,
              fontFamily: 'DidactGothic-Regular',
            },
          }}
          label={'Date Created'}
          // fullWidth={true}
        ></TextField>

        {data && data?.wedding.mainEvent ? (
          <>
            <TextField
              value={data?.wedding?.mainEvent?.name}
              focused={true}
              style={{ width: '350px' }}
              InputProps={{
                readOnly: true,
                style: {
                  color: themeContext?.weddingNodeColor,
                  borderColor: themeContext?.weddingNodeColor,
                  fontFamily: 'DidactGothic-Regular',
                },
              }}
              label={'Event Name'}
              // fullWidth={true}
            ></TextField>
            <TextField
              value={data?.wedding?.mainEvent?.startTimestamp}
              focused={true}
              style={{ width: '350px' }}
              InputProps={{
                readOnly: true,
                style: {
                  color: themeContext?.weddingNodeColor,
                  borderColor: themeContext?.weddingNodeColor,
                  fontFamily: 'DidactGothic-Regular',
                },
              }}
              label={'Event Start Date'}
              // fullWidth={true}
            ></TextField>
            <TextField
              value={data?.wedding?.mainEvent?.location?.name}
              focused={true}
              style={{ width: '350px' }}
              InputProps={{
                readOnly: true,
                style: {
                  color: themeContext?.weddingNodeColor,
                  borderColor: themeContext?.weddingNodeColor,
                  fontFamily: 'DidactGothic-Regular',
                },
              }}
              label={'Event Location'}
              // fullWidth={true}
            ></TextField>
            <TextField
              value={data?.wedding?.coupon}
              focused={true}
              style={{ width: '350px' }}
              InputProps={{
                readOnly: true,
                style: {
                  color: themeContext?.weddingNodeColor,
                  borderColor: themeContext?.weddingNodeColor,
                  fontFamily: 'DidactGothic-Regular',
                },
              }}
              label={'Coupon'}
              // fullWidth={true}
            ></TextField>
            <TextField
              value={data?.wedding?.package}
              focused={true}
              style={{ width: '350px' }}
              InputProps={{
                readOnly: true,
                style: {
                  color: themeContext?.weddingNodeColor,
                  borderColor: themeContext?.weddingNodeColor,
                  fontFamily: 'DidactGothic-Regular',
                },
              }}
              label={'Package'}
              // fullWidth={true}
            ></TextField>
          </>
        ) : (
          ''
        )}
      </div>
      <Handle type="source" position={Position.Right} />
    </Node>
  );
};
export const MemoizedWeddingNode = memo(WeddingNode);
