import React, { Fragment, useEffect, useState } from 'react';
import styles from './Firebase.module.scss';
import Header from '../header/Header';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import { logger } from '../../../config/Logger';

const ResetPasswordComponent = (props: {
  auth: firebase.auth.Auth;
  actionCode: string;
  continueUrl: string;
  lang: string;
}): JSX.Element => {
  const [errorState, setErrorState] = useState<string | undefined>();
  const [message, setMessage] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [passwordConfirm, setPasswordConfirm] = useState<string | undefined>();

  useEffect(() => {
    props.auth
      .verifyPasswordResetCode(props.actionCode)
      .then((email) => {
        logger.log({ message: 'resetting password for: ' + email });
      })
      .catch((error) => {
        setMessage(undefined);
        setErrorState(error.message);
        logger.error({ message: error.message, functionName: 'ResetPasswordComponent.useEffect' });
      });
  }, []);

  const handleResetPassword = (password: string) => {
    setMessage(undefined);
    setErrorState(undefined);
    if (!password || !passwordConfirm || password !== passwordConfirm) {
      setErrorState("Your passwords don't match");
      logger.error({
        message: "Your passwords don't match",
        functionName: 'ResetPasswordComponent.handleResetPassword',
      });
      return;
    } else
      props.auth
        .confirmPasswordReset(props.actionCode, password)
        .then((resp) => {
          logger.log({ message: resp });
          setMessage('Your password was reset successfully. Got to log in page.');
        })
        .catch((error) => {
          setMessage(undefined);
          setErrorState(error.message);
          setTimeout(() => setErrorState(undefined), 1500);
          logger.error({ message: error.message, functionName: 'ResetPasswordComponent.handleResetPassword' });
        });
  };
  return (
    <Fragment>
      <div className={styles.main}>
        <Header login={true} />
        <div className={styles.wrapper}>
          <div className={styles.form}>
            <h2 className={'fridayH2'} style={{ marginBottom: '36px' }}>
              Create a new password
            </h2>
            <div style={{ marginBottom: '16px' }}>
              <label htmlFor="password" className={'didacticH4'}>
                Enter a password
              </label>
              <input
                type="password"
                name={'password'}
                className={'input'}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="passwordConfirm" className={'didacticH4'}>
                Confirm your password
              </label>
              <input
                type="password"
                name={'passwordConfirm'}
                className={'input'}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </div>
            <p className={'didacticP ' + styles.error}>{errorState && errorState}</p>
            <p className={'didacticP ' + styles.success}>{message && message}</p>
            <button
              className={'btn btnMain'}
              disabled={Boolean(!password)}
              onClick={() => password && handleResetPassword(password)}
            >
              Complete Reset
            </button>
            <h4 className={'didacticH4'}>
              Click <Link to={props.continueUrl}>here</Link> to login
            </h4>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPasswordComponent;
