import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectJwt, selectProfile, selectUser } from '../../../app/redux/authorization.slice.reducer';
import {
  addInvitedVisitors,
  removeInvitedVisitors,
  selectInvitedVisitors,
  selectRsvpEvents,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectTaxonomies,
  selectTaxonomyOptions,
  selectWedding,
  setInvitedVisitors,
  setRsvpEventsReducer,
} from '../../../app/redux/wedding.slice.recuder';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import styles from './Table.module.scss';
import {
  AllowedVisitor,
  ConnectedAccountRef,
  RSVPEvent,
  TaxonomyOption,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import TaxonomyListWithSelection from '../guests/taxonomyListWithSelection/TaxonomyListWithSelection';
import { Imagehelper } from '../../../app/helper/Imagehelper';
import { logger } from '../../../config/Logger';
import Lottie from 'react-lottie';
import location from '../../../loader-animation.json';

import ImportedGuestsModal from '../guests/ImportedGuestsModal.tsx/ImportedGuestsModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '../../../shared/Icon';
import TaxonomyMultipleSelect from '../guests/taxonomyMultipleSelect/TaxonomyMultipleSelect';
import { SVGIconName } from '../../../shared/Icon/Icons';
import useOutsideClick from '../../../hooks/useOutsideClick';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import Checkbox from '../../../shared/Checkbox/CheckboxFilled/CheckboxFilled';
import { ScreenType, useTypeOfScreen } from '../../../hooks';
import GenericConfirmModal from '../../modals/GenericConfirmModal';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Table = (props: {
  _RSVPEvents: any;
  _NumberOfEvents: number;
  _NumberOfGuests?: any;
  _ShowModalInviteGuests: boolean;
  _SetShowModalInviteGuests: any;
  _RefreshCallback: any;
  query: string;
  totalGuests: number;
  selectedGuestsObjects: Map<string, AllowedVisitor>;
  setSelectedGuestsObjects: any;
  forceRefresh: boolean;
}): JSX.Element => {
  const typeOfScreen = useTypeOfScreen();
  //JWT AND WEDDING ID
  const profile = useSelector(selectProfile);
  const jwtToken = useSelector(selectJwt);
  const user = useSelector(selectUser);
  const wedding = useSelector(selectWedding);

  //GUESTS AND TAXONOMY ID
  const [guestList, setGuestList] = useState(Array<AllowedVisitor>());
  const [guestListCheckboxes, setGuestListCheckboxes] = useState(new Map<string, boolean>());
  const [taxonomyId, setTaxonomyId] = useState('');
  const [taxonomiesList, setTaxonomiesList] = useState<TaxonomyOption[] | undefined>(Array);
  const selectedInvitedVisitors = useSelector(selectInvitedVisitors);
  const [RSVPEvents, setRSVPEvents] = useState<RSVPEvent[] | undefined>(Array);

  const [attendingGuests, setAttendingGuests] = useState<number>(0);
  const [attendingPlusOnes, setAttendingPlusOnes] = useState<number>(0);

  //SELECTED GUESTS
  const [selectedGuests, setSelectedGuests] = useState(Array<any>());
  const selectedGuestsRef = useRef<Array<any>>(selectedGuests);
  selectedGuestsRef.current = selectedGuests;
  const [selectedGuestsObjects, setSelectedGuestsObjects] = useState(new Map<string, AllowedVisitor>());
  const selectedGuestsObjectsRef = useRef<Map<string, AllowedVisitor>>(selectedGuestsObjects);
  selectedGuestsObjectsRef.current = selectedGuestsObjects;
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  //ADD TAGS TO GUEST
  const [selectedTaxonomyOptions1, setSelectedTaxonomyOptions1] = useState<Array<TaxonomyOption>>([]);
  const [showAddTag, setShowAddTag] = useState(false);

  //EDIT GUEST MODAL
  const [showEditGuestModal, setShowEditGuestModal] = useState(false);

  // loading while search
  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(false);

  const [importedGuests, setImportedGuests] = useState<{ index: number; user: string[]; error: string | undefined }[]>(
    [],
  );
  const [showImportedGuestsModal, setShowImportedGuestsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const storedTaxonomyOptions = useSelector(selectTaxonomyOptions);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableHead, setTableHead] = useState(
    Array<string>(
      'All',
      'Full name',
      'Plus 1s',
      'Tags',
      'Status',
      'Invited',
      'Attending',
      'Phone',
      'Email',
      'Host',
      // 'Moderator',
    ),
  );
  const dispatch = useDispatch();

  const storedRSVPEvents = useSelector(selectRsvpEvents);

  const taxonomyHelper = async () => {
    //Get Wedding Taxonomy
    if (wedding && wedding.defaultTaxonomyId && storedTaxonomyOptions) {
      setTaxonomyId(wedding.defaultTaxonomyId);
    } else {
      logger.error({
        message: `Taxonomy Helper: on Table guests `,
        functionName: 'TaxonomyListWithSelection.AddNewTag.helper',
      });
    }
  };

  useEffect(() => {
    taxonomyHelper();
  }, [storedTaxonomyOptions]);

  const checkAndEdit = (guestId: string) => {
    // console.log(`checkandedit`, guestId);
    toggleSelectedGuest(guestId);
    editRows();
  };

  const RSVPEventsHelper = async () => {
    try {
      if (!storedRSVPEvents) {
        const rsvpEvents = await BrainHelper.listRSVPEvents(jwtToken, wedding.id, dispatch, setRsvpEventsReducer);
        const theEvents = rsvpEvents?.rsvpEvents || [];
        setRSVPEvents(theEvents);
      } else {
        setRSVPEvents(storedRSVPEvents);
      }
    } catch (e) {
      logger.error({ message: e, functionName: 'InviteGuestModal.helper' });
    }
  };

  useEffect(() => {
    RSVPEventsHelper();
  }, [storedRSVPEvents]);

  const helper = async () => {
    setIsSecondButtonLoading(true);
    setTaxonomyId(wedding.defaultTaxonomyId);
    setTaxonomiesList(storedTaxonomyOptions);
    await taxonomyHelper();
    //get AllowedVisitors
    const res = await BrainHelper.getAllowedVisitors(
      jwtToken,
      wedding.id,
      props.query,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      dispatch,
      setInvitedVisitors,
    );
    setGuestList(res.allowedVisitors);

    setIsSecondButtonLoading(false);
  };

  const helperGetVisitors = async () => {
    setIsSecondButtonLoading(true);
    //get AllowedVisitors
    const res = await BrainHelper.getAllowedVisitors(
      jwtToken,
      wedding.id,
      props.query,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    setGuestList(res.allowedVisitors);

    setIsSecondButtonLoading(false);
  };

  const getSelectedGuestsObject = () => {
    const tempMap = new Map<string, AllowedVisitor>();
    for (const guest of guestList) {
      if (guest && guest.id && selectedGuests.includes(guest?.id)) {
        tempMap.set(guest.id, guest);
      }
    }
    setSelectedGuestsObjects(tempMap);
  };

  const diselectAllSelectedGuests = () => {
    setSelectedGuests([]);
    setSelectedGuestsObjects(new Map<string, AllowedVisitor>());
    setGuestListCheckboxes(new Map<string, boolean>());
    setSelectAllCheckbox(false);
  };

  const toggleAllSelectedGuests = () => {
    const tempArr: Array<string> = [];
    const tempMap = new Map<string, boolean>();
    const tempGuests = new Map<string, AllowedVisitor>();
    if (selectAllCheckbox) {
      setSelectedGuests([]);
      setGuestListCheckboxes(new Map<string, boolean>());
      props.setSelectedGuestsObjects(new Map<string, AllowedVisitor>());
    } else {
      for (const guest of guestList) {
        tempArr.push(guest.id);
        tempGuests.set(guest.id, guest);
        tempMap.set(guest.id, true);
      }
      setSelectedGuests(tempArr);
      setGuestListCheckboxes(tempMap);
      props.setSelectedGuestsObjects(tempGuests);
    }
    setSelectAllCheckbox(!selectAllCheckbox);
  };

  const toggleSelectedGuest = (value: string) => {
    if (selectedGuests.includes(value)) {
      let tempArr = selectedGuests;
      const tempObjects = new Map<string, AllowedVisitor>(props.selectedGuestsObjects);
      tempArr = tempArr.filter((value1) => {
        return value1 != value;
      });
      selectedGuestsObjects.forEach((selected) => {
        if (selected?.id === value) tempObjects.delete(selected.id);
      });
      props.setSelectedGuestsObjects(tempObjects);
      setSelectedGuests(tempArr);
      const tempMap = new Map(guestListCheckboxes);
      tempMap.delete(value);
      setGuestListCheckboxes(tempMap);
    } else {
      const tempArr = selectedGuests;
      tempArr.push(value);
      setSelectedGuests(tempArr);

      const tempObjects = selectedGuestsObjects;
      const tempObject = guestList.filter((v) => {
        if (v && v.id) return v?.id === value;
      });
      if (tempObject[0]) {
        tempObjects.set(value, tempObject[0]);
        props.setSelectedGuestsObjects(tempObjects);
      }
      const tempMap = guestListCheckboxes;
      tempMap.set(value, true);
      setGuestListCheckboxes(tempMap);
    }
    getSelectedGuestsObject();
  };

  const checkboxSelectedGuest = (value: string) => {
    return guestListCheckboxes.has(value);
  };

  const addTagsToRows = async (newTaxonomies: TaxonomyOption[], removedTaxonomies: TaxonomyOption[]) => {
    for (const guest of selectedGuests) {
      if (newTaxonomies.length > 0) {
        try {
          // const taxonomyIds: string[] = storedTaxonomyOptions
          //   .map((tax: TaxonomyOption) => tax.id)
          //   .filter((option: string) => !newTaxonomies.map((tax) => tax.id).includes(option));
          await BrainHelper.attachTaxonomyOptionsToAllowedVisitor(
            newTaxonomies.map((option) => option.id),
            guest,
            jwtToken,
            wedding.id,
            dispatch,
            addInvitedVisitors,
          );
          await BrainHelper.detachTaxonomyOptionsFromAllowedVisitors(
            jwtToken,
            wedding.id,
            guest,
            removedTaxonomies.map((option) => option.id),
            dispatch,
            addInvitedVisitors,
          );
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const deleteRows = () => {
    if (selectedGuests.length > 0) {
      const tempArr = Array<string>();
      const tempObjs = Array<AllowedVisitor>();
      for (const [selectedGuestId, object] of props.selectedGuestsObjects) {
        if (object.email === user.email) {
          continue;
        }
        if (object.isHost) {
          continue;
        }
        tempArr.push(selectedGuestId);
        tempObjs.push(object);
      }

      if (tempObjs.length > 1) {
        console.log(1);
        BrainHelper.deleteAllowedVisitors(wedding.id, tempObjs, jwtToken, dispatch, removeInvitedVisitors).then(() => {
          setSelectedGuests([]);
          // helper();
        });
      } else {
        console.log(2);
        BrainHelper.deleteAllowedVisitor(wedding.id, tempObjs[0], jwtToken, dispatch, removeInvitedVisitors).then(
          () => {
            setSelectedGuests([]);
            // helper();
          },
        );
      }
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    if (selectedGuests.length > 1 || selectedGuests.length === 0) {
      setShowEditGuestModal(false);
    }
  }, [selectedGuests, selectedGuestsObjects, props.selectedGuestsObjects]);

  useEffect(() => {
    setSelectedGuestsObjects(props.selectedGuestsObjects);
    const tempMap = new Map<string, boolean>();
    for (const guest of Array.from(props.selectedGuestsObjects)) {
      tempMap.set(guest[0], true);
    }
    if (tempMap.size <= 0) diselectAllSelectedGuests();
    setGuestListCheckboxes(tempMap);
  }, [props.selectedGuestsObjects]);

  const editRows = () => {
    setShowEditGuestModal(true);
  };

  useEffect(() => {
    helper();
  }, [props.forceRefresh]);

  useEffect(() => {
    if (props.query.length > 0) {
      const timer = setTimeout(() => {
        if (props.query) {
          helperGetVisitors();
        }
      }, 600);

      return () => clearTimeout(timer);
    } else {
      setGuestList(selectedInvitedVisitors);
    }
  }, [props.query]);

  useEffect(() => {
    if (selectedInvitedVisitors) {
      setGuestList(selectedInvitedVisitors);
    }
    if (selectedGuestsObjects.size > 0) {
      const tempMap = new Map<string, AllowedVisitor>();
      for (const guest of selectedInvitedVisitors) {
        if (guest && guest.id && selectedGuestsObjects.get(guest.id)) {
          tempMap.set(guest.id, guest);
        }
      }
      setSelectedGuestsObjects(tempMap);
    }
  }, [selectedInvitedVisitors]);

  useEffect(() => {
    // calculate attending guests. If they are attending to at least one event, they are attending guests
    // const attendingGuests = guestList
    //   .map((guest) => {
    //     if (guest) {
    //       return guest.attendToRsvpEvents
    //         .map((e) => {
    //           if (e.attend) return 1;
    //           else return 0;
    //         })
    //         .reduce((a, b) => a + b, 0);
    //     } else return 0;
    //   })
    //   .reduce((accum, current) => {
    //     if (current) return accum + 1;
    //     else return accum;
    //   }, 0);
    // setAttendingGuests(attendingGuests);
    // console.log('attendingGuests', attendingGuests);

    // calculate attending plus ones guests. If they are attending to at least one event, they are attending guests
    const attendingPlusOnes = guestList
      .map((guest) => {
        if (guest) {
          const mapi = guest.attendToRsvpEvents.map((e) => {
            if (e.attend) return Number(e?.plusOnes) || 0;
            else return 0;
          });
          const nonZero = mapi.filter((e) => e > 0);
          const sum = mapi.reduce((a, b) => a + b, 0);
          if (nonZero.length === 0) return 0;
          else return sum / nonZero.length;
        } else return 0;
      })
      .reduce((accum, current) => {
        if (current) return accum + current;
        else return accum;
      }, 0);
    console.log('attendingPlusOnes', Math.floor(attendingPlusOnes));

    setAttendingPlusOnes(Math.floor(attendingPlusOnes));
  }, [guestList]);

  const toggleImportModal = () => {
    setShowImportedGuestsModal((prevState) => !prevState);
  };

  return (
    <>
      {/*{props._ShowModalInviteGuests && (*/}
      {/*  <InviteGuestsModal*/}
      {/*    show={props._ShowModalInviteGuests}*/}
      {/*    clearSelected={diselectAllSelectedGuests}*/}
      {/*    setShow={(b: boolean) => props._SetShowModalInviteGuests(b)}*/}
      {/*    _callback={(e: boolean) => props._SetShowModalInviteGuests(e)}*/}
      {/*    RSVPEvents={props._RSVPEvents}*/}
      {/*    refreshCallback={helper}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{showEditGuestModal && (*/}
      {/*  <EditGuestModal*/}
      {/*    selectedGuests={selectedGuestsRef}*/}
      {/*    selectedGuestsObjects={selectedGuestsObjectsRef}*/}
      {/*    clearSelected={diselectAllSelectedGuests}*/}
      {/*    show={showEditGuestModal}*/}
      {/*    setShow={() => setShowEditGuestModal(false)}*/}
      {/*    jwtToken={jwtToken}*/}
      {/*    helper={() => helper()}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{showImportedGuestsModal ? (*/}
      {/*  <ImportedGuestsModal*/}
      {/*    rows={importedGuests}*/}
      {/*    show={showImportedGuestsModal}*/}
      {/*    setShow={setShowImportedGuestsModal}*/}
      {/*    setImportedGuests={setImportedGuests}*/}
      {/*    taxonomiesList={taxonomiesList}*/}
      {/*    taxonomyId={taxonomyId}*/}
      {/*    wedding={wedding}*/}
      {/*    RSVPEvents={RSVPEvents}*/}
      {/*    helper={helper}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  ''*/}
      {/*)}*/}
      <Toolbox
        selectedRows={selectedGuests}
        showAddTag={showAddTag && selectedGuests.length > 0}
        setShowAddTag={(value: boolean) => setShowAddTag(value)}
        selectedTaxonomyOptions={selectedTaxonomyOptions1}
        setSelectedTaxonomyOptions={(newTaxonomies: TaxonomyOption[]) => setSelectedTaxonomyOptions1(newTaxonomies)}
        diselectAllSelectedGuests={() => diselectAllSelectedGuests()}
        addTags={(newTaxonomies: TaxonomyOption[], removedTaxonomies: TaxonomyOption[]) =>
          addTagsToRows(newTaxonomies, removedTaxonomies)
        }
        delete={() => setShowDeleteModal(true)}
        edit={() => editRows()}
        import={() => toggleImportModal()}
        showImportedGuestsModal={showImportedGuestsModal}
        weddingId={wedding.id}
        userToken={jwtToken}
        updateHelper={() => helper()}
        query={props.query}
      />
      <div className={styles.tableContainer + ' ' + styles[`tableContainer${typeOfScreen}`]}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.head}>
              <th key={'All'}>
                <Checkbox
                  value={selectAllCheckbox}
                  onClick={() => {
                    toggleAllSelectedGuests();
                  }}
                />
              </th>
              {tableHead.map((columnHead, index) => {
                if (index === 0) {
                  return (
                    <th key={columnHead} style={{ minWidth: '60px' }}>
                      <span style={{ paddingLeft: '10px' }}>{columnHead}</span>
                    </th>
                  );
                } else {
                  return (
                    <th key={columnHead} style={columnHead === 'Tags' ? { minWidth: '300px' } : { minWidth: '120px' }}>
                      {columnHead}
                    </th>
                  );
                }
              })}
            </tr>
            {isSecondButtonLoading ? (
              <tr
                style={{
                  width: 'calc(100vw - 220px)',
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Lottie options={defaultOptions1} height={200} width={200} />
              </tr>
            ) : (
              guestList.map((value: AllowedVisitor) => {
                if (value && value.id)
                  return (
                    <tr
                      key={value.id}
                      className={checkboxSelectedGuest(value.id) ? styles.selectedRow : styles.nonSelectedRow}
                    >
                      <td>
                        <Checkbox
                          value={checkboxSelectedGuest(value.id)}
                          onClick={() => {
                            toggleSelectedGuest(value.id);
                          }}
                        />
                      </td>
                      <td style={{ padding: '0px 12px' }}>
                        <button style={{ background: 'none', border: 'none' }} onClick={() => checkAndEdit(value.id)}>
                          <Avatar
                            name={`${value.firstName} ${value.lastName}`}
                            userToken={String(jwtToken)}
                            connectedAccountDetails={value.connectedAccountDetails}
                            query={props.query}
                          />
                        </button>
                      </td>
                      <td>
                        <button
                          className={'didacticP'}
                          style={{ background: 'none', border: 'none', textAlign: 'left' }}
                          onClick={() => checkAndEdit(value.id)}
                        >
                          {value?.firstName
                            ? value?.firstName + ' ' + value?.lastName
                            : value?.connectedAccountDetails?.connectedAccountId === profile.connectedAccountId ||
                              value?.connectedAccountDetails?.connectedAccountPhoneId ===
                                profile.connectedAccountPhoneId
                            ? 'Set up your profile'
                            : ''}
                        </button>
                      </td>
                      <td>{String(value.maxAllowedPlus1)}</td>
                      <td>
                        <TaxonomyMultipleSelect
                          weddingId={wedding.id}
                          userToken={jwtToken}
                          guest={value}
                          pageRefresh={helper}
                        />
                      </td>
                      <td>
                        <StatusLabel status={Number(value.status)} />
                      </td>
                      <td>
                        <InvitedLabel
                          invitedEventsNumber={value?.invitedToRsvpEvents.length}
                          numberOfRsvpEvents={props._NumberOfEvents}
                        />
                      </td>
                      <td>
                        <AttendingLabel
                          attendingEventsNumber={
                            value?.attendToRsvpEvents.filter((event) => {
                              return event.attend;
                            }).length
                          }
                          tooltipText={value?.attendToRsvpEvents
                            .filter((event) => {
                              return event.attend;
                            })
                            .map((event) => {
                              return event?.rsvpEvent?.name;
                            })
                            .join(', ')}
                          numberOfRsvpEvents={props._NumberOfEvents}
                        />
                      </td>
                      <td>
                        {value.phone.length > 0
                          ? value.connectedAccountDetails?.phoneNumber
                            ? value.connectedAccountDetails.phoneNumber
                            : value.phone
                          : ''}
                      </td>
                      <td>
                        {value.connectedAccountDetails?.email ? value.connectedAccountDetails.email : value.email}
                      </td>
                      <td style={{ padding: '12px' }}>
                        {value.isHost ? (
                          <img src="/assets/icons/icon-accept.svg" width={16} alt="Yes" />
                        ) : (
                          <img src="/assets/icons/icon-cancel.svg" width={16} alt="No" />
                        )}
                      </td>
                      {/*<td>*/}
                      {/*  {value.isModerator ? (*/}
                      {/*    <img src="/assets/icons/icon-accept.svg" width={30} alt="Yes" />*/}
                      {/*  ) : (*/}
                      {/*    <img src="/assets/icons/icon-cancel.svg" width={30} alt="No" />*/}
                      {/*  )}*/}
                      {/*</td>*/}
                    </tr>
                  );
                // }
              })
            )}
            <tr>
              <td>
                {guestList.length === 0 && props.query !== '' && (
                  <div
                    className={styles.emptyTable}
                    style={
                      typeOfScreen !== ScreenType.desktop &&
                      typeOfScreen !== ScreenType.laptop &&
                      typeOfScreen !== ScreenType.tablet
                        ? { left: 16, maxWidth: `calc(100vw - 32px)` }
                        : {}
                    }
                  >
                    <h3>No Results for [ {props.query} ]</h3>
                    <p>{`Our trained monkeys couldn't find any results`}</p>
                  </div>
                )}
                {guestList.length === 0 && props.query === '' && !isSecondButtonLoading && (
                  <div className={styles.emptyTable}>
                    <h3>No invited guests</h3>
                    <p>
                      Let’s make a fuzz! Add your guests, organize them in categories
                      <br /> and explore the massive planning options
                    </p>
                    <div
                      role="button"
                      tabIndex={0}
                      className={styles.pointer}
                      onKeyPress={(e) => {
                        props._SetShowModalInviteGuests(true);
                        e.preventDefault();
                      }}
                      onClick={(e) => {
                        props._SetShowModalInviteGuests(true);
                        e.preventDefault();
                      }}
                    >
                      <img src="/rsvp/table/add.svg" alt="Add" />
                    </div>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*{guestList.length > 0 && (*/}
      {/*  <div className={styles.pagination}>*/}
      {/*    <p style={{ textAlign: 'left', padding: '8px', fontWeight: 600 }}>*/}
      {/*      Invited Results: {guestList.length} and plus1: /!*{guestList*!/*/}
      {/*      /!*  .map((guest) => {*!/*/}
      {/*      /!*    return guest?.maxAllowedPlus1 || 0;*!/*/}
      {/*      /!*  })*!/*/}
      {/*      /!*  .reduce((accumulator, currentValue) => {*!/*/}
      {/*      /!*    return accumulator + Number(currentValue);*!/*/}
      {/*      /!*  }, 0)}{' '}*!/*/}
      {/*      Total:{' '}*/}
      {/*      {guestList*/}
      {/*        .map((guest) => {*/}
      {/*          return guest?.maxAllowedPlus1 || BigInt(0);*/}
      {/*        })*/}
      {/*        .reduce((accumulator, currentValue) => {*/}
      {/*          return accumulator + Number(currentValue);*/}
      {/*        }, 0) + guestList.length}*/}
      {/*    </p>*/}
      {/*    <p style={{ textAlign: 'left', padding: '8px', fontWeight: 600 }}>*/}
      {/*      Attending Results: {attendingGuests} and plus1: {attendingPlusOnes} Total:{' '}*/}
      {/*      {attendingGuests + attendingPlusOnes}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*)}*/}

      {showDeleteModal && (
        <GenericConfirmModal
          setShow={setShowDeleteModal}
          title={'Just a final check'}
          text={'Are you sure you want to delete these visitors?'}
          confirmBtnFunction={() => deleteRows()}
          confirmBtnText={'Delete'}
          confirmBtnIcon={'trash'}
          // html={t}
        />
      )}

      <ToastContainer
        closeButton={false}
        style={{ width: 'fit-content', borderRadius: 8 }}
        icon={<Icon name={'accept'} color={'#006150'} size={'s'} />}
        position="bottom-center"
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

type AvatarProps = {
  query: string;
  name: string;
  connectedAccountDetails?: ConnectedAccountRef;
  userToken?: string;
  _className?: string;
  _id?: string;
};

export const Avatar = (props: AvatarProps): JSX.Element => {
  const matchColor = (str: string) => {
    const checkNum = str[0].charCodeAt(0);
    if (checkNum % 4 == 0) {
      return '#C1C6A8';
    }
    if (checkNum % 4 == 1) {
      return '#F9AC80';
    }
    if (checkNum % 4 == 2) {
      return '#C7ADC1';
    }
    return '#C7ADC1';
  };

  const [image, setImage] = useState('');
  const helper = async () => {
    if (props.connectedAccountDetails && props.connectedAccountDetails.imageProfileUrl && props.userToken)
      await Imagehelper.fetchImage(props?.connectedAccountDetails?.imageProfileUrl, setImage, props.userToken, 200);
    // const res = await Imagehelper.getFileByUrl(props.userToken, props?.connectedAccountDetails?.imageProfileUrl, 40);
  };

  const fakeAvatar = () => {
    if (props.connectedAccountDetails?.accountFirstName && props.connectedAccountDetails?.accountLastName) {
      return (
        <div
          id={props._id}
          className={props._className ? props._className : ''}
          style={{
            borderRadius: '50%',
            backgroundColor: matchColor(
              String(props.connectedAccountDetails?.accountFirstName[0]).toUpperCase() +
                String(props.connectedAccountDetails?.accountLastName[0]).toUpperCase(),
            ),
            width: 40,
            height: 40,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p id={props._id + 'P'}>
            {String(props.connectedAccountDetails?.accountFirstName[0]).toUpperCase() +
              String(props.connectedAccountDetails?.accountLastName[0]).toUpperCase()}
          </p>
        </div>
      );
    }

    const name = props.name.trim();
    const names = name.split(' ');
    if (Array.isArray(names) && names.length >= 2) {
      return (
        <div
          id={props._id}
          className={props._className ? props._className : ''}
          style={{
            borderRadius: '50%',
            backgroundColor: matchColor(String(names[0][0]).toUpperCase() + String(names[1][0]).toUpperCase()),
            width: 40,
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p id={props._id + 'P'}>{String(names[0][0]).toUpperCase() + String(names[1][0]).toUpperCase()}</p>
        </div>
      );
    }

    return (
      <div
        id={props._id}
        className={props._className ? props._className : ''}
        style={{
          borderRadius: '50%',
          backgroundColor: matchColor(String(name[0] ? name[0] : '-').toUpperCase()),
          width: 40,
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p id={props._id + 'P'}>{String(name[0] ? name[0] : '-').toUpperCase()}</p>
      </div>
    );
  };

  useEffect(() => {
    helper();
  }, [props.connectedAccountDetails]);

  if (image && image !== '') {
    return (
      <img
        id={props._id}
        alt={'G'}
        style={{ borderRadius: '50%', backgroundColor: 'black', width: 40, height: 40, objectFit: 'cover' }}
        src={image ? image : '/rsvp/guests/svg '}
        onError={() => {
          setImage('');
        }}
      />
    );
  } else {
    return fakeAvatar();
  }
};

type ToolboxProps = {
  selectedRows: any;
  showAddTag: boolean;
  setShowAddTag: any;
  selectedTaxonomyOptions: Array<TaxonomyOption>;
  setSelectedTaxonomyOptions: any;
  diselectAllSelectedGuests: any;
  addTags: any;
  delete: any;
  edit: any;
  import: any;
  showImportedGuestsModal: boolean;
  weddingId: string;
  userToken: string;
  updateHelper: any;
  query: string;
};
const Toolbox = (props: ToolboxProps) => {
  const typeOfScreen = useTypeOfScreen();
  const rsvpEvents = useSelector(selectRsvpEvents);
  const wedding = useSelector(selectWedding);
  const [showShareGuestsModal, setShowShareGuestsModal] = useState(false);
  const [showShareGuestsDropdown, setShowShareGuestsDropdown] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showDownloadGuestsModal, setShowDownloadGuestsModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<RSVPEvent | undefined>(undefined);
  const [dashboardURL, setDashboardURL] = useState('https://app.whiteclover.uk/dashboard');
  const modalRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setDashboardURL(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard'
        : `https://${window.location.host}/dashboard`,
    );
  }, []);

  useOutsideClick(modalRef, () => {
    setShowShareGuestsModal(false);
    setSelectedEvent(undefined);
  });
  // useOutsideClick(dropdownRef, () => setShowShareGuestsDropdown(false));

  const toggleShareModal = () => {
    setShowShareGuestsModal((prevState) => !prevState);
  };

  const downloadList = async () => {
    const fileData = await BrainHelper.downloadGuestList(props.userToken, wedding.id, props.query, 'csv');
    if (fileData) {
      const blob = new Blob([fileData.content], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileData.fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      if (fileData?.fileName && fileData.fileName.trim() !== '') link.click();
      else toast('No file to download');

      // Clean up and remove the link
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link.parentNode.removeChild(link);
    }
  };

  const dispatch = useDispatch();
  const updateVisitorsHelper = async (value: string[]) => {
    const attatchTaxonomies = value;
    props.selectedRows.forEach(async (row: any) => {
      await BrainHelper.attachTaxonomyOptionsToAllowedVisitor(
        attatchTaxonomies,
        row,
        props.userToken,
        props.weddingId,
        dispatch,
        addInvitedVisitors,
      );
      // console.log(res);
    });
    // props.updateHelper();
  };
  useEffect(() => {
    if (props.showAddTag === false) {
      props.setSelectedTaxonomyOptions([]);
    }
  }, [props.showAddTag]);
  return (
    <div className={styles.toolbox + ' ' + styles[`toolbox${typeOfScreen}`]} style={{ zIndex: 99 }}>
      <FlatButton
        disabled={props.selectedRows?.length == 0}
        extraClass={props.showAddTag ? styles.isSelected : ''}
        title={!props.showAddTag ? 'Add Tags' : 'Back'}
        iconColor={props.selectedRows?.length == 0 ? '#C7ADC1' : '#63435B'}
        icon={!props.showAddTag ? 'label' : 'arrow_back'}
        _callback={() => props.setShowAddTag(!props.showAddTag)}
      />

      {props.showAddTag ? (
        <TaxonomyListWithSelection
          selected={props.selectedTaxonomyOptions}
          callback={(newTaxonomies: Array<TaxonomyOption>, removedTaxonomies: Array<TaxonomyOption>) => {
            props.setSelectedTaxonomyOptions(newTaxonomies);
            props.addTags(newTaxonomies, removedTaxonomies);
            updateVisitorsHelper(newTaxonomies.map((item: TaxonomyOption) => item.id));
          }}
          updateCallback={() => {
            props.setShowAddTag(false);
          }}
          weddingId={props.weddingId}
        />
      ) : (
        <></>
      )}
      <div className={styles.addToolbox + ' ' + (props.showAddTag ? styles.openAddTag : '')}>
        <FlatButton
          title={typeOfScreen !== ScreenType.mobile ? 'Edit' : ''}
          icon="edit"
          iconColor={props.selectedRows?.length == 0 ? '#C7ADC1' : '#63435B'}
          _callback={() => props.edit()}
          disabled={props.selectedRows?.length != 1}
        />
        <FlatButton
          title={typeOfScreen !== ScreenType.mobile ? 'Delete' : ''}
          icon="trash"
          iconColor={props.selectedRows?.length == 0 ? '#C7ADC1' : '#63435B'}
          _callback={() => props.delete()}
          disabled={props.selectedRows?.length <= 0}
        />
        {typeOfScreen !== ScreenType.mobile && (
          <FlatButton
            id={'importListButton'}
            title={'Import List'}
            icon={'upload'}
            iconColor={props.showImportedGuestsModal ? '#FDF6EB' : '#63435B'}
            extraClass={props.showImportedGuestsModal ? styles.highlighted : ''}
            _callback={() => props.import()}
          />
        )}
        <FlatButton
          id={'shareListButton'}
          title={typeOfScreen !== ScreenType.mobile ? 'Share List' : ''}
          icon={'share'}
          iconColor={showShareGuestsModal ? '#FDF6EB' : '#63435B'}
          extraClass={showShareGuestsModal ? styles.highlighted : ''}
          _callback={() => toggleShareModal()}
        />
        <FlatButton
          id={'downloadListButton'}
          title="Export List"
          icon={'spreadsheet_file'}
          iconColor={showDownloadGuestsModal ? '#FDF6EB' : '#63435B'}
          extraClass={showDownloadGuestsModal ? styles.highlighted : styles.padding}
          _callback={() => downloadList()}
        />
        {showShareGuestsModal ? (
          <div className={styles.shareListWrapper + ' ' + styles[`shareListWrapper${typeOfScreen}`]} ref={modalRef}>
            <h4 className={'didacticH4'}>Share List</h4>
            <p className={'didacticP'}>Choose the event guest-list you wish to share.</p>
            <div style={{ position: 'relative', width: '100%' }}>
              <OutlinedButton
                title={selectedEvent ? selectedEvent.name : 'Choose Event'}
                isActive={showShareGuestsDropdown}
                setIsActive={setShowShareGuestsDropdown}
                icon={true}
                _callback={() => {
                  setShowShareGuestsDropdown((prevState) => !prevState);
                }}
              />
              {showShareGuestsDropdown && (
                <div
                  id={'sendDropdownModal'}
                  className={styles.dropdown + ' ' + (showShareGuestsDropdown ? styles.dropdownActive : '')}
                  ref={dropdownRef}
                >
                  {rsvpEvents.map((rsvpEvent) => {
                    return (
                      <DropdownButton
                        key={'dropdown' + rsvpEvent.id}
                        title={rsvpEvent.name}
                        icon={rsvpEvent.name.toLowerCase() === 'ceremony'}
                        _callback={() => {
                          setSelectedEvent(rsvpEvent);
                          setShowShareGuestsDropdown(false);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            {selectedEvent && (
              <Fragment>
                <p className={'didacticP'}>You can copy and share the link bellow. </p>
                <CopyToClipboard
                  text={
                    selectedEvent
                      ? dashboardURL.replace('/dashboard', '') + '/share-list/' + wedding.id + '/' + selectedEvent.id
                      : ''
                  }
                  onCopy={() => {
                    toast(`Copied on clipboard successfully!`, {
                      style: { backgroundColor: '#A3F5E6', color: '#006150 !important' },
                    });
                  }}
                >
                  <button className={styles.shareButton}>
                    <Icon name={'link'} color={'#63435B'} size={'s'} />
                    <h4 className={'didacticH4'}>
                      {selectedEvent
                        ? dashboardURL.replace('/dashboard', '') + '/share-list/' + wedding.id + '/' + selectedEvent.id
                        : ''}
                    </h4>
                    <Icon name={'copy'} color={'#63435B'} size={'sPlus'} />
                  </button>
                </CopyToClipboard>
              </Fragment>
            )}
          </div>
        ) : (
          ''
        )}
        {/*<FlatButton disabled={true} title="Events" icon="/rsvp/guests/toolbox/events.svg" />*/}
      </div>
    </div>
  );
};

const OutlinedButton = (props: {
  id?: string;
  title: string;
  isActive: boolean;
  setIsActive: any;
  icon?: boolean;
  _callback?: any;
}) => {
  return (
    <button
      id={props.id}
      className={styles.outlinedButton + ' ' + (props.isActive ? styles.outlinedButtonActive : '')}
      onClick={() => {
        props._callback();
      }}
    >
      <h4 className={'didacticH4'}>{props.title}</h4>
      {props.icon && (
        <Icon name={'arrow_down'} color={'#63435B'} rotate={props.isActive ? '180deg' : '0deg'} size={'s'} />
      )}
    </button>
  );
};

const DropdownButton = (props: { title: string; icon?: boolean; _callback?: any; disabled?: boolean }) => {
  // let isActive = false;
  const [isActive, setIsActive] = useState(false);
  return (
    <button
      disabled={props.disabled}
      className={styles.dropdownButton + ' ' + (isActive ? styles.dropdownButtonActive : '')}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onClick={() => {
        setIsActive((prevState) => !prevState);
        props._callback();
      }}
    >
      <Icon name={props.icon ? 'ceremony' : 'event'} color={'#63435B'} size={'s'} />
      <span className={'didacticH4'}>{props.title}</span>
    </button>
  );
};

const FlatButton = (props: {
  id?: string;
  title: string;
  icon: SVGIconName;
  iconColor: string;
  _callback?: any;
  extraClass?: string;
  disabled?: boolean;
}) => {
  return (
    <span
      className={
        (props.disabled === true ? styles.disabled : '') + ' ' + (props.extraClass ? props.extraClass : '') + ' '
      }
      style={{ minWidth: 'fit-content' }}
    >
      <button
        id={props.id}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        className={styles.flatButton + ' '}
        disabled={props.disabled}
        onClick={
          !props.disabled
            ? () => props._callback()
            : () => {
                logger.info({ message: 'disabled toolbox button' });
              }
        }
      >
        <Icon name={props.icon} color={props.iconColor} size={'s'} />
        {/*<img src={props.icon} height={16} alt="icon" />*/}
        <span style={{ height: '32px' }}>{props.title}</span>
      </button>
    </span>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FlatButtonInput = (props: {
  title: string;
  icon: string;
  _callback?: any;
  extraClass?: string;
  disabled?: boolean;
}) => (
  <label
    className={
      ' ' + (props?.disabled == true ? styles.disabled : '') + (props.extraClass ? props.extraClass : '') + ' '
    }
  >
    <div className={styles.flatButton + ' '}>
      <img src={props.icon} alt="icon" />
      <input
        disabled={props.disabled}
        onChange={
          !props.disabled
            ? (e) => props._callback(e)
            : () => {
                logger.info({ message: 'disabled toolbox button' });
              }
        }
      />
      <span>{props.title}</span>
    </div>
  </label>
);

const StatusEnum = ['RSVP Unknown', 'Pending', 'Save the Date', 'RSVP Sent'];

const StatusLabel = (props: { status: number }) => (
  <div>
    <img src={'/rsvp/table/status.svg'} alt={'STATUS'} />
    {' ' + StatusEnum[props.status]}
  </div>
);

const InvitedOrAttendingEnum = ['event', 'events', 'all events'];

const InvitedLabel = (props: { invitedEventsNumber: number; numberOfRsvpEvents: number }) => (
  <div>
    {props.invitedEventsNumber == props.numberOfRsvpEvents
      ? InvitedOrAttendingEnum[2]
      : String(Number(isNaN(props.invitedEventsNumber) ? 0 : props.invitedEventsNumber)) +
        '  ' +
        (props.invitedEventsNumber == 1 ? InvitedOrAttendingEnum[0] : InvitedOrAttendingEnum[1])}
  </div>
);

const AttendingLabel = (props: { attendingEventsNumber: number; tooltipText: string; numberOfRsvpEvents: number }) => (
  <div data-tooltip-id={'footerPartnerTooltip'} data-tooltip-content={props.tooltipText}>
    {props.attendingEventsNumber == props.numberOfRsvpEvents
      ? InvitedOrAttendingEnum[2]
      : String(Number(isNaN(props.attendingEventsNumber) ? 0 : props.attendingEventsNumber)) +
        '  ' +
        (props.attendingEventsNumber == 1 ? InvitedOrAttendingEnum[0] : InvitedOrAttendingEnum[1])}
  </div>
);

export default Table;
