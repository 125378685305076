import { Fragment, useEffect, useState } from 'react';
import Icon from '../../../shared/Icon';
import { Imagehelper } from '../../../app/helper/Imagehelper';

import styles from './ImYoursContactInfoComponent.module.scss';
import templateStyles from '../ImYours.module.scss';
import { useSelector } from 'react-redux';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';
import { SVGIconName } from '../../../shared/Icon/Icons';

interface ImYoursContactInfoComponentProps {
  ImYoursContactInfoComponentCustomVisible: boolean;
  ImYoursContactInfoComponentDetails:
    | Array<{
        title: string;
        name: string;
        image: string;
        email?: string;
        whatsapp?: string;
        phone?: string;
        messenger?: string;
        viber?: string;
      }>
    | undefined;
}

const defaultValues = {
  ImYoursAppComponentCustomGraphics: '/templates/ImYours/contact-graphic.svg',
};

const ImYoursContactInfoComponent = ({
  ImYoursContactInfoComponentCustomVisible,
  ImYoursContactInfoComponentDetails,
}: ImYoursContactInfoComponentProps): JSX.Element => {
  const [blobs, setBlobs] = useState<Map<string, string>>(new Map());
  const userToken = useSelector(selectAccessTokenAfterOTP);

  const fetchImage = async (fileID: string) => {
    const result = await Imagehelper.getFileFromFilestoreId(fileID, userToken, 1000);
    if (typeof result === 'string') {
      setBlobs((prevState) => {
        const tempMap = new Map(prevState);
        tempMap.set(fileID, result);
        return tempMap;
      });
    }
  };

  useEffect(() => {
    if (ImYoursContactInfoComponentDetails !== undefined && ImYoursContactInfoComponentDetails.length > 0) {
      ImYoursContactInfoComponentDetails?.map((contact) => {
        fetchImage(contact.image);
      });
    }
  }, []);

  useEffect(() => {
    if (ImYoursContactInfoComponentDetails !== undefined && ImYoursContactInfoComponentDetails.length > 0) {
      ImYoursContactInfoComponentDetails?.map((contact) => {
        console.log('image', contact.image);
        fetchImage(contact.image);
      });
    }
  }, [ImYoursContactInfoComponentDetails]);

  if (ImYoursContactInfoComponentCustomVisible)
    return (
      <section id={`ImYoursContactInfoComponent`} className={styles.ImYoursContactInfoComponent}>
        <h1>Contact</h1>
        <div className={styles.contentWrapper}>
          <img src={defaultValues.ImYoursAppComponentCustomGraphics} alt="contact graphic" />
          <div>
            {ImYoursContactInfoComponentDetails !== undefined &&
              ImYoursContactInfoComponentDetails?.map((contact) => {
                return (
                  <div className={styles.Card} key={contact.name}>
                    <div>
                      {blobs.get(contact.image) !== undefined ? (
                        <img
                          src={blobs.get(contact.image)}
                          alt="Contact"
                          style={{ objectFit: 'cover', borderRadius: '50px', width: 72, height: 72 }}
                        />
                      ) : (
                        <div
                          style={{
                            borderRadius: '50px',
                            width: 72,
                            height: 72,
                            backgroundColor: '#1C3924',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#FFF',
                          }}
                        >
                          {contact.name[0].toUpperCase()}
                        </div>
                      )}
                      <div>
                        <h4>{contact.title}</h4>
                        <h3>{contact.name}</h3>
                      </div>
                    </div>
                    <div className={templateStyles.flex} style={{ justifyContent: 'flex-start', gap: 24 }}>
                      {contact.email !== undefined && contact.email.trim().length > 0 && (
                        <CircleIcon
                          hoverBackgroundColor="#B26336"
                          iconColor="#FFF9F6"
                          backgroundColor="transparent"
                          hoverIconColor="#FFF"
                          icon="envelope"
                          link={`mailto:` + contact.email}
                        />
                      )}
                      {contact.phone !== undefined && (
                        <CircleIcon
                          hoverBackgroundColor="#B26336"
                          iconColor="#FFF9F6"
                          backgroundColor="transparent"
                          hoverIconColor="#FFF"
                          icon="phone_call"
                          link={`tel:+` + contact.phone}
                        />
                      )}
                      {contact.whatsapp !== undefined && (
                        <CircleIcon
                          hoverBackgroundColor="#25D366"
                          iconColor="#FFF9F6"
                          backgroundColor="transparent"
                          hoverIconColor="#FFF"
                          icon="whats_app"
                          link={`https://wa.me/` + contact.whatsapp}
                        />
                      )}
                      {contact.messenger !== undefined && (
                        <CircleIcon
                          hoverBackgroundColor="linear-gradient(202.14deg, #FF6968 11.92%, #A334FA 47.72%, #645BFC 72.38%, #0695FF 88.29%)"
                          iconColor="#FFF9F6"
                          backgroundColor="transparent"
                          hoverIconColor="#FFF"
                          icon="messenger"
                          link={
                            contact.messenger.includes('facebook')
                              ? contact.messenger
                              : contact.messenger.includes('m.me') || contact.messenger.includes('messenger')
                              ? contact.messenger
                              : 'https://m.me/' + contact.messenger
                          }
                        />
                      )}
                      {contact.viber !== undefined && (
                        <CircleIcon
                          hoverBackgroundColor="#7360F2"
                          hoverIconColor="#FFF"
                          backgroundColor="transparent"
                          iconColor="#FFF9F6"
                          icon="viber"
                          link={`viber://add?number=` + contact.viber}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          <img src={defaultValues.ImYoursAppComponentCustomGraphics} alt="contact graphic" />
        </div>
      </section>
    );
  else return <Fragment />;
};
export default ImYoursContactInfoComponent;

const CircleIcon = ({
  icon,
  onHoverIcon,
  hoverBackgroundColor,
  backgroundColor,
  hoverIconColor,
  iconColor,
  link,
}: {
  icon: SVGIconName;
  onHoverIcon?: SVGIconName;
  hoverBackgroundColor: string;
  backgroundColor: string;
  hoverIconColor: string;
  iconColor: string;
  link?: string;
}) => {
  const [hover, setHover] = useState(false);

  const renderBorder = () => {
    if (!hover) {
      return '1px solid ' + iconColor;
    } else {
      return '1px solid #fff';
    }
  };
  if (link)
    return (
      <a
        style={{
          display: 'flex',
          border: renderBorder(),
          borderRadius: '50px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 8,
          background: hover ? hoverBackgroundColor : backgroundColor,
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onTouchStart={() => {
          setHover(true);
        }}
        onTouchEnd={() => {
          setHover(false);
        }}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <Icon
          name={onHoverIcon ? (hover ? onHoverIcon : icon) : icon}
          size="s"
          color={hover ? hoverIconColor : iconColor}
        />
      </a>
    );
  return <></>;
};
