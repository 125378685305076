import { memo, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import styled, { ThemeContext } from 'styled-components';
import { ConnectedAccountRef } from '../../../../pkg/protobuf/v2/brain/brain_types_pb';
import { Icon } from '../../../../shared';
import { TextField } from '@mui/material';

type StyledProps = {
  selected: boolean;
};
const Node = styled.div<StyledProps>`
  border-radius: 25px;
  background: ${(props) => props.theme.nodeBg};
  color: ${(props) => props.theme.nodeColor};
  border: 1px solid ${(props) => props.theme.connectedAccountNodeColor};
  padding: 24px;

  .react-flow__handle {
    background: ${(props) => props.theme.connectedAccountNodeColor};
    width: 8px;
    height: 10px;
    border-radius: 3px;
  }

  .nodeHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    gap: 12px;

    h3 {
      color: ${(props) => props.theme.connectedAccountNodeColor};
    }
  }

  .nodeDesign {
    color: ${(props) => props.theme.connectedAccountNodeColor};
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      color: ${(props) => props.theme.connectedAccountNodeColor};
    }
    p {
      color: ${(props) => props.theme.connectedAccountNodeColor};
      font-size: 14px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.connectedAccountNodeColor} !important;
  }

  .MuiFilledInput-root {
    border-bottom: 1px solid ${(props) => props.theme.connectedAccountNodeColor} !important;
  }

  .MuiInput-root {
    border-bottom: 1px solid ${(props) => props.theme.connectedAccountNodeColor} !important;
  }

  .Mui-focused {
    color: ${(props) => props.theme.connectedAccountNodeColor};
  }

  .MuiInputLabel-root {
    color: ${(props) => props.theme.connectedAccountNodeColor} !important;
    font-family: 'DidactGothic-Regular';
  }
`;

type ConnectedAccountNodeProps = {
  data: ConnectedAccountRef;
  selected: boolean;
};
const ConnectedAccountRefNode = ({ data, selected }: ConnectedAccountNodeProps) => {
  const themeContext = useContext(ThemeContext);

  // useEffect(() => {
  //   console.log('ConnectedAccountRefNode', { data, selected });
  // }, []);

  return (
    <Node selected={selected}>
      <Handle type="target" position={Position.Left} />
      <div className={'nodeHeader'}>
        <Icon name={'profile_filled'} size={'m'} color={themeContext.connectedAccountNodeColor} />
        <Icon name={'profile'} size={'m'} color={themeContext.connectedAccountNodeColor} />
        <h3>Merged Connected Account</h3>
      </div>
      <div className={'nodeDesign'}>
        {data && data.accountFirstName ? (
          <TextField
            value={data?.accountFirstName}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.connectedAccountNodeColor,
                borderColor: themeContext?.connectedAccountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'Firstname'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
        {data && data.accountLastName ? (
          <TextField
            value={data?.accountLastName}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.connectedAccountNodeColor,
                borderColor: themeContext?.connectedAccountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'Lastname'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}

        {data && data.email ? (
          <TextField
            value={data?.email}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.connectedAccountNodeColor,
                borderColor: themeContext?.connectedAccountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'Email'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
        {data && data.connectedAccountId ? (
          <TextField
            value={data?.connectedAccountId}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.connectedAccountNodeColor,
                borderColor: themeContext?.connectedAccountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'ConnectedAccountID'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
        {data && data.accountId ? (
          <TextField
            value={data?.accountId}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.connectedAccountNodeColor,
                borderColor: themeContext?.connectedAccountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'AccountID'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}

        {data && data.phoneNumber ? (
          <TextField
            value={data?.phoneNumber}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.connectedAccountNodeColor,
                borderColor: themeContext?.connectedAccountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'Phone'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
        {data && data.connectedAccountPhoneId ? (
          <TextField
            value={data?.connectedAccountPhoneId}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.connectedAccountNodeColor,
                borderColor: themeContext?.connectedAccountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'ConnectedAccountPhoneID'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
        {data && data.accountPhoneId ? (
          <TextField
            value={data?.accountPhoneId}
            focused={true}
            style={{ width: '350px' }}
            InputProps={{
              readOnly: true,
              style: {
                color: themeContext?.connectedAccountNodeColor,
                borderColor: themeContext?.connectedAccountNodeColor,
                fontFamily: 'DidactGothic-Regular',
              },
            }}
            label={'AccountPhoneID'}
            // fullWidth={true}
          ></TextField>
        ) : (
          ''
        )}
      </div>
      <Handle type="source" position={Position.Right} />
    </Node>
  );
};
export const MemoizedConnectedAccountRefNode = memo(ConnectedAccountRefNode);
