import './App.scss';

import { IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Detector } from 'react-detect-offline';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { selectAccessToken } from './app/redux/authorization.slice.reducer';
import Custom404 from './component/errors/404';
import { AddVisitorSecretPage } from './component/AddVisitorsSecretPage';
import Entrance from './component/dashboard/entrance/Entrance';

import {
  HomeScreen,
  OtpScreen,
  SignInScreen,
  ProfileScreen,
  FinishProfileScreen,
  IntroScreen,
  SinglePostScreen,
} from './experienceApp/screens';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from './routes/protectedRoute/ProtectedRoute';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ProtectedHostRoute from './routes/protectedRoute/ProtectedHostRoute';
import ScrollToTop from './routes/ScrollToTop';
import GuestsOld from './component/dashboard/guests/Guests';
import { selectWedding } from './app/redux/wedding.slice.recuder';
import Planning from './component/dashboard/planning/Planning';
import Rsvp from './component/dashboard/rsvp/Rsvp';
import Overview from './component/dashboard/overview/Overview';
import ChooseWedding from './component/dashboard/chooseWedding/ChooseWedding';
import StartPlanning from './component/dashboard/planning/StartPlanning';
import ProtectedLoggedInHostRoute from './routes/protectedRoute/ProtectedLoggedInHostRoute';
import ChooseTemplate from './component/dashboard/chooseTemplate/ChooseTemplate';
import WebsiteDetails from './component/dashboard/websiteDetails/WebsiteDetails';
import FirebaseTemplatesComponent from './component/dashboard/firebaseTemplates/FirebaseTemplatesComponent';
import ResetPageComponent from './component/dashboard/firebaseTemplates/ResetPageComponent';
import { RsvpMain } from './experienceApp/screens/rsvp/Rsvp';
import CompleteRegistration from './component/dashboard/entrance/CompleteRegistration';
import DownloadICS from './experienceApp/screens/DownloadICS/DownloadICS';
import { Main } from './experienceApp/Main/Main';
import Schedule from './experienceApp/screens/Schedule/Schedule';
import styles from './App.module.scss';
import Icon from './shared/Icon';
import DashboardProfile from './component/dashboard/profile/Profile';
import { isChrome, isChromeOS, isEdge, isIosSafari, isOpera, isFirefox } from '@braintree/browser-detection';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DashboardAdmin from './component/dashboard/admin/DashboardAdmin';
import ShareList from './component/dashboard/shareList/ShareList';
import SelfInvite from './component/dashboard/selfInvite/SelfInvite';
import DebugTemplates from './component/debug/debugTemplates/DebugTemplates';

export const App = (): JSX.Element => {
  // init
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showWarningMobileUsage, setShowWarningMobileUsage] = useState<boolean>(false);
  const [showWarningNonMobileUsage, setShowWarningNonMobileUsage] = useState<boolean>(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [showWarningNonSupportedBrowser, setShowWarningNonSupportedBrowser] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  // get auto updated if userToken is changed and save in redux
  const userToken = useSelector(selectAccessToken);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const wedding = useSelector(selectWedding);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const defaultState: Readonly<any> = {
    status: 'failed to contact the backend',
    streamEvents: [],
    userToken: userToken,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = React.useState(defaultState);
  // create mutable state, so we can send it to other functions
  const stateRef = React.useRef<any>();
  stateRef.current = state;

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setToastMessage('there are some new updates');
    setShowToast(true);
    setWaitingWorker(registration.waiting);
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowToast(false);
    window.location.reload();
  };

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  }, []);

  // check if path contains dashboard and if yes then show a modal that the dashboard is not design for screens less than 1024 pixels
  const checkScreenSize = () => {
    if (window.location.pathname.includes('invitation') || window.location.pathname.includes('share-list')) {
      setShowWarningMobileUsage(false);
      return;
    }

    if (
      window.innerWidth < 1024 &&
      window.location.pathname.includes('dashboard') &&
      !window.location.pathname.includes('share-list') &&
      !window.location.pathname.includes('invitation')
    ) {
      setShowWarningMobileUsage(true);
    } else if (
      window.innerWidth >= 1024 &&
      (window.location.pathname.includes('dashboard') || window.location.pathname.includes('debug'))
    ) {
      setShowWarningMobileUsage(false);
    } else if (
      window.innerWidth > 820 &&
      !window.location.pathname.includes('dashboard') &&
      !window.location.pathname.includes('verify') &&
      !window.location.pathname.includes('reset') &&
      !window.location.pathname.includes('downloadICS')
    ) {
      setShowWarningNonMobileUsage(true);
    } else if (
      (window.innerWidth <= 820 &&
        !window.location.pathname.includes('dashboard') &&
        !window.location.pathname.includes('verify') &&
        !window.location.pathname.includes('reset') &&
        !window.location.pathname.includes('downloadICS')) ||
      window.location.pathname.includes('share-list') ||
      window.location.pathname.includes('invitation')
    ) {
      setShowWarningNonMobileUsage(false);
    }
  };

  const checkBrowserCompatibility = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const userAgent = window.navigator.userAgent;
    // Detect Chrome
    const chromeAgent = isChrome();
    const safariAgent = isIosSafari();
    const braveAgent = isChromeOS();
    const operaAgent = isOpera();
    const edgeAgent = isEdge();
    const firefoxAgent = isFirefox();

    if (
      chromeAgent == false &&
      safariAgent == false &&
      braveAgent == false &&
      operaAgent == false &&
      edgeAgent == false &&
      firefoxAgent == false &&
      userAgent.indexOf('Safari') == -1
    ) {
      setShowWarningNonSupportedBrowser(false);
      // setShowWarningNonSupportedBrowser(true);
    }
  };

  useEffect(() => {
    checkBrowserCompatibility();
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path={'/'} element={<SignInScreen />} />
          <Route path={'/verify'} element={<FirebaseTemplatesComponent />} />
          <Route path={'/reset'} element={<ResetPageComponent />} />
          <Route path={'/signin'} element={<SignInScreen />} />
          <Route path={'/otp'} element={<OtpScreen />} />
          <Route path={'/downloadICS'} element={<DownloadICS />} />
          <Route path={'/dashboard/complete'} element={<CompleteRegistration />} />
          <Route path={'/dashboard/create-account'} element={<Entrance log={false} />} />
          <Route path={'/dashboard'} element={<Entrance log={true} />} />
          <Route path={'/dashboard/login'} element={<Entrance log={true} />} />
          <Route path={'/dashboard/share-list/:weddingId/:eventId'} element={<ShareList />} />
          <Route path={'/share-list/:weddingId/:eventId'} element={<ShareList />} />
          <Route path={'/invitation/:weddingId/:maxAllowed'} element={<SelfInvite />} />
          <Route element={<ProtectedLoggedInHostRoute />}>
            <Route path={'/debug/templates'} element={<DebugTemplates />} />
            <Route path={'/dashboard/admin'} element={<DashboardAdmin />} />
            <Route path={'/dashboard/choose-event'} element={<ChooseWedding />} />
            <Route path={'/dashboard/choose-event/start-planning'} element={<StartPlanning />} />
            <Route path={'*'} element={<Custom404 />} />
          </Route>
          <Route path={'/dashboard/w/:weddingPathCode'} element={<ProtectedHostRoute />}>
            <Route path={'/dashboard/w/:weddingPathCode/guests'} element={<GuestsOld />} />
            <Route path={'/dashboard/w/:weddingPathCode/website'} element={<WebsiteDetails />} />
            <Route path={'/dashboard/w/:weddingPathCode/create-website'} element={<WebsiteDetails />} />
            <Route path={'/dashboard/w/:weddingPathCode/edit-website'} element={<WebsiteDetails />} />
            <Route path={'/dashboard/w/:weddingPathCode/website/choose-template'} element={<ChooseTemplate />} />
            <Route path={'/dashboard/w/:weddingPathCode/planning'} element={<Planning />} />
            <Route path={'/dashboard/w/:weddingPathCode/rsvp'} element={<Rsvp />} />
            <Route path={'/dashboard/w/:weddingPathCode/overview'} element={<Overview />} />
            <Route path={'/dashboard/w/:weddingPathCode/profile'} element={<DashboardProfile />} />
            <Route path={'*'} element={<Custom404 />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path={'/home'} element={<HomeScreen />} />
            <Route path={'/intro'} element={<IntroScreen />} />
            <Route path={'/main'} element={<Main />} />
            <Route path={'/schedule'} element={<Schedule />} />
            <Route path={'/rsvp'} element={<RsvpMain />} />
            <Route path={'/profile'} element={<ProfileScreen />} />
            <Route path={'/finish'} element={<FinishProfileScreen />} />
            <Route path={'/post'} element={<SinglePostScreen />} />
            {/*// TODO: to be removed when we implemented correctly*/}
            <Route path={'/ckrqoxe4i000007mce02mdjfl-add-allowed-visitor'} element={<AddVisitorSecretPage />} />
            <Route path={'*'} element={<Custom404 />} />
          </Route>
          <Route path={'*'} element={<Custom404 />} />
        </Routes>
      </BrowserRouter>
      <div>
        <Detector
          polling={{ enabled: true, interval: 60000, url: window.location.origin }}
          onChange={(e: boolean) => {
            if (e) setToastMessage('your back online');
            else setToastMessage('you are offline');
            setShowToast(true);
          }}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={(e: any) => {
            // console.log('detect network change', e);
            return <></>;
          }}
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          position="top"
          cssClass={'custom-toast'}
          buttons={[
            {
              side: 'end',
              text: 'Refresh',
              handler: () => {
                reloadPage();
              },
            },
            {
              text: 'Done',
              role: 'cancel',
              handler: () => {
                // console.log('Cancel clicked');
              },
            },
          ]}
        />
        {/* <div className={showWarningMobileUsage ? styles.popUpGlobalModal : styles.hiddenGlobalModal}> */}
        <div className={styles.hiddenGlobalModal}>
          <div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                <h2 className={'fridayH2'}>Small screen detected</h2>
                <button
                  style={{ border: 'none', background: 'none' }}
                  onClick={(e) => {
                    setShowWarningMobileUsage(false);
                    e.preventDefault();
                  }}
                >
                  <Icon size={'m'} name={'cancel'} />
                  {/*<img src="/assets/icons/icon-cancel.svg" alt="exit" width={32} />*/}
                </button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '90%',
                  margin: '0 auto',
                  alignItems: 'center',
                }}
              >
                <div>
                  <svg width="150" height="150" viewBox="0 0 62 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22 34H9"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M15 40L9 34L15 28"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M40 34H53"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M47 28L53 34L47 40"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M35 21H27V47H35V21Z"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M9 8H13"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M19 8H23"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M42 8H53"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M1 15H61"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M61 1H1V53H61V1Z"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h2 style={{ wordWrap: 'break-word' }}>
                    Looks like you’re on a mobile or small device. We’re not quite ready for you.
                  </h2>
                  <h4 style={{ wordWrap: 'break-word' }}>
                    {`We’re working hard to introduce a better experience for smaller devices. In the meantime, we recommend you continue to use Whiteclover on a larger screen.`}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={showWarningNonMobileUsage ? styles.popUpGlobalMobileModal : styles.hiddenGlobalModal}>
          <div
            className={styles.warning}
            style={
              !isWarningOpen
                ? { backgroundColor: 'white', filter: 'drop-shadow(0px 0px 0px transparent)' }
                : { padding: 24 }
            }
          >
            <button onClick={() => setIsWarningOpen((prevState) => !prevState)}>
              <Icon name={'info'} size={'sPlus'} color={!isWarningOpen ? '#B48F08' : '#63435B'} />
            </button>
            {isWarningOpen && (
              <p className={'didacticP'}>
                Our app is designed to work for mobiles and tablets. We suggest you to open it from a mobile device for
                for better experience.
              </p>
            )}
          </div>
        </div>
        {/*<div className={showWarningNonMobileUsage ? styles.popUpGlobalModal : styles.hiddenGlobalModal}>*/}
        {/*  <div>*/}
        {/*    <div>*/}
        {/*      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>*/}
        {/*        <h2 className={'fridayH2'}>Big screen detected</h2>*/}
        {/*        <button*/}
        {/*          style={{ border: 'none', background: 'none' }}*/}
        {/*          onClick={(e) => {*/}
        {/*            setShowWarningNonMobileUsage(false);*/}
        {/*            e.preventDefault();*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <Icon size={'m'} name={'cancel'} />*/}
        {/*          /!*<img src="/assets/icons/icon-cancel.svg" alt="exit" width={32} />*!/*/}
        {/*        </button>*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          display: 'flex',*/}
        {/*          flexDirection: 'column',*/}
        {/*          width: '90%',*/}
        {/*          margin: '0 auto',*/}
        {/*          alignItems: 'center',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <div>*/}
        {/*          <svg width="150" height="150" viewBox="0 0 62 54" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*            <path*/}
        {/*              d="M22 34H9"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M15 40L9 34L15 28"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M40 34H53"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M47 28L53 34L47 40"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M35 21H27V47H35V21Z"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M9 8H13"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M19 8H23"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M42 8H53"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M1 15H61"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*            <path*/}
        {/*              d="M61 1H1V53H61V1Z"*/}
        {/*              stroke="#63445b"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*            ></path>*/}
        {/*          </svg>*/}
        {/*        </div>*/}
        {/*        <div>*/}
        {/*          <h2 style={{ wordWrap: 'break-word' }}>*/}
        {/*            Looks like you’re on a big screen. We’re not quite ready for you.*/}
        {/*          </h2>*/}
        {/*          <h4 style={{ wordWrap: 'break-word', marginBottom: '24px' }}>*/}
        {/*            {`Our app is designed to work for mobiles and tablets. We suggest you to open it from a mobile device for better experience.`}*/}
        {/*          </h4>*/}
        {/*          <h3 style={{ wordWrap: 'break-word' }}>*/}
        {/*            {`Send the below url to your mobile device and continue from there.`}*/}
        {/*          </h3>*/}
        {/*          <div className={`${styles.urlPathCode} ${styles.pointerClick}`}>*/}
        {/*            <svg viewBox="0 0 12.32 9.33" width={16}>*/}
        {/*              <g>*/}
        {/*                <line className="st1" x1="7.6" y1="8.9" x2="7.6" y2="6.9"></line>*/}
        {/*                <rect width="1.9" height="1.9"></rect>*/}
        {/*                <rect x="1.9" y="1.9" width="1.9" height="1.9"></rect>*/}
        {/*                <rect x="3.7" y="3.7" width="1.9" height="1.9"></rect>*/}
        {/*                <rect x="1.9" y="5.6" width="1.9" height="1.9"></rect>*/}
        {/*                <rect y="7.5" width="1.9" height="1.9"></rect>*/}
        {/*              </g>*/}
        {/*            </svg>*/}
        {/*            <CopyToClipboard text={window.location.href} onCopy={() => setCopied(true)}>*/}
        {/*              <span>{window.location.href}</span>*/}
        {/*            </CopyToClipboard>*/}

        {/*            {copied ? <span style={{ color: '#835978', marginLeft: '10px' }}>Copied.</span> : null}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div
          className={showWarningNonSupportedBrowser ? styles.popUpGlobalModal : styles.hiddenGlobalModal}
          style={{ zIndex: '1001 !important' }}
        >
          <div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                <h2 className={'fridayH2'}>Unsupported browser detected</h2>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '90%',
                  margin: '0 auto',
                  alignItems: 'center',
                }}
              >
                <div>
                  <svg width="150" height="150" viewBox="0 0 62 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M22 34H9"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M15 40L9 34L15 28"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M40 34H53"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M47 28L53 34L47 40"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M35 21H27V47H35V21Z"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M9 8H13"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M19 8H23"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M42 8H53"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M1 15H61"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M61 1H1V53H61V1Z"
                      stroke="#63445b"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h2 style={{ wordWrap: 'break-word' }}>Looks like your are using an unsupported browser.</h2>
                  <h4 style={{ wordWrap: 'break-word', marginBottom: '24px' }}>
                    {`Our app is designed to work with Chrome, Safari, Brave, Opera and Edge.`}
                  </h4>
                  <h3 style={{ wordWrap: 'break-word' }}>{`Please open the below url by using a supported browser`}</h3>
                  <div className={`${styles.urlPathCode} ${styles.pointerClick}`}>
                    <svg viewBox="0 0 12.32 9.33" width={16}>
                      <g>
                        <line className="st1" x1="7.6" y1="8.9" x2="7.6" y2="6.9"></line>
                        <rect width="1.9" height="1.9"></rect>
                        <rect x="1.9" y="1.9" width="1.9" height="1.9"></rect>
                        <rect x="3.7" y="3.7" width="1.9" height="1.9"></rect>
                        <rect x="1.9" y="5.6" width="1.9" height="1.9"></rect>
                        <rect y="7.5" width="1.9" height="1.9"></rect>
                      </g>
                    </svg>
                    <CopyToClipboard text={window.location.href} onCopy={() => setCopied(true)}>
                      <span>{window.location.href}</span>
                    </CopyToClipboard>

                    {copied ? <span style={{ color: '#835978', marginLeft: '10px' }}>Copied.</span> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
