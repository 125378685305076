/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import styles from './WebsiteDetails.module.scss';
import TemplatePreview from '../templatePreview/TemplatePreview';
import {
  AvailableTemplates,
  CreateWeddingWebsitePageRequest,
  CreateWeddingWebsitePageSectionRequest,
  UpdateWeddingWebsitePageRequest,
  UpdateWeddingWebsitePageSectionRequest,
  WeddingWebsite,
} from '../../../pkg/protobuf/v2/brain/brain_types_pb';
import WebsiteDetailsForm from '../websiteDetailsForm/WebsiteDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectWedding } from '../../../app/redux/wedding.slice.recuder';
import Lottie from 'react-lottie';
import location from '../../../loader-animation.json';
import { BrainHelper } from '../../../pkg/apiHelpers/brainHelper';
import { useNavigate } from 'react-router-dom';
import {
  selectComponentProps,
  selectCurrentWeddingWebsite,
  selectSelectedTemplate,
  setComponentProps,
  setCurrentWeddingWebsite,
  setSelectedTemplate,
  setWeddingWebsites,
} from '../../../app/redux/websiteBuilder.slice.reducer';
import { selectAccessTokenAfterOTP } from '../../../app/redux/authorization.slice.reducer';
import { logger } from '../../../config/Logger';
import Icon from '../../../shared/Icon';
import { ScreenType, useTypeOfScreen } from '../../../hooks';
import { toast, ToastContainer } from 'react-toastify';
import { utilSave } from './utils/save';
import useAutosave from './utils/autoSave';
import { lottieDefaultOptions } from '../../../shared/Options';
import { transliterate } from 'transliteration';

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const WebsiteDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTemplate, SetSelectedTemplate] = useState<AvailableTemplates>();
  const selectedTemplateRef = React.useRef<AvailableTemplates | undefined>(selectedTemplate);
  selectedTemplateRef.current = selectedTemplate;
  const wedding = useSelector(selectWedding);
  const userToken = useSelector(selectAccessTokenAfterOTP);
  const componentProps = useSelector(selectComponentProps);
  const selectedTemplateUse = useSelector(selectSelectedTemplate);
  const componentPropsRef = React.useRef<any[]>(componentProps);
  componentPropsRef.current = componentProps;
  const [sectionComponentsList, SetSectionComponentsList] = useState<Array<string>>([]);
  const selectedTemplateFromStore = useSelector(selectSelectedTemplate);
  const currentWeddingWebsite = useSelector(selectCurrentWeddingWebsite);

  const [componentProps1, setComponentProps1] = useState<Array<any>>([]);
  const componentProps1Ref = React.useRef<any[]>(componentProps);
  componentProps1Ref.current = componentProps1;

  const [tempComponentProps1, setTempComponentProps1] = useState<Array<any>>([]);
  const tempComponentPropsRef = React.useRef<any[]>(tempComponentProps1);
  tempComponentPropsRef.current = tempComponentProps1;
  const [isSecondButtonLoading, setIsSecondButtonLoading] = React.useState(false);

  const [published, setPublic] = useState(currentWeddingWebsite?.visibilityStatus === 1);
  const [publicURLDomain, setPublicURLDomain] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const autoSave = (forceSave?: boolean) => {
    console.log('autoSave()...', componentProps1Ref.current);
    if (forceSave) {
      utilSave(
        currentWeddingWebsite,
        componentProps1Ref,
        tempComponentPropsRef,
        setIsSaving,
        setTempComponentProps1,
        justSave,
        save,
        true,
      );
    } else {
      setIsSaving(true);
      dispatch(setComponentProps(componentProps1Ref.current));
    }
  };
  const justSave = async (props: any, isAutosave = false) => {
    console.log('justSave()...', props);
    setIsSaving(true);
    setIsSecondButtonLoading(true);
    dispatch(setComponentProps(props));
    await updateWebsite(props, isAutosave);
    setIsSecondButtonLoading(false);
  };

  const overviewReroute = () => {
    setTimeout(() => {
      const overviewUrl = `/dashboard/w/${transliterate(wedding.path)}/overview`;
      navigate(overviewUrl);
      window.location.href = overviewUrl;
    }, 5000);
  };

  const updateWebsite = async (componentProps2: any[], isAutosave = false) => {
    if (
      currentWeddingWebsite !== undefined &&
      currentWeddingWebsite?.pages.length > 0 &&
      currentWeddingWebsite?.pages[0].id !== undefined
    )
      try {
        const updatePageList = new Array<UpdateWeddingWebsitePageRequest>();
        const updatePage = new UpdateWeddingWebsitePageRequest();
        const newSectionDatasList = new Array<UpdateWeddingWebsitePageSectionRequest>();
        currentWeddingWebsite?.pages[0]?.sectionDatas?.map((section: any, index: number) => {
          const newSection = new UpdateWeddingWebsitePageSectionRequest();
          if (componentProps2[index] === undefined) {
            newSection.dataJson = JSON.stringify({});
          } else {
            newSection.dataJson = JSON.stringify(componentProps2[index], (_, v) =>
              typeof v === 'bigint' ? v.toString() : v,
            );
          }
          newSection.weddingWebsitePageId = currentWeddingWebsite.pages[0].id ? currentWeddingWebsite?.pages[0].id : '';
          newSection.id = section.id;
          newSection.weddingWebsitePageId = section.weddingWebsitePageId;
          newSection.frontendComponentName = section.frontendComponentName;
          newSection.genericType = section.frontendComponentName;
          newSection.goStruct = section.goStruct;
          newSection.tsClass = section.tsClass;
          newSection.order = section.order;
          newSectionDatasList.push(newSection);
        });
        let reload = false;
        selectedTemplateRef.current?.sectionComponents.map((name, index) => {
          if (newSectionDatasList.findIndex((section) => section.frontendComponentName === name) < 0) {
            reload = true;
            const newSection = new UpdateWeddingWebsitePageSectionRequest();
            newSection.dataJson = JSON.stringify({});
            newSection.weddingWebsitePageId = currentWeddingWebsite?.pages[0]?.id
              ? currentWeddingWebsite?.pages[0]?.id
              : '';
            newSection.frontendComponentName = name;
            newSection.genericType = name;
            newSection.goStruct = name;
            newSection.tsClass = name;
            newSection.order = index as unknown as bigint;
            newSectionDatasList.push(newSection);
          }
        });
        updatePage.weddingWebsiteId = currentWeddingWebsite?.id;
        updatePage.id = currentWeddingWebsite?.pages[0]?.id;
        updatePage.sectionDatas = newSectionDatasList;
        updatePage.path = 'home';
        const existingPageVisibility =
          Array.isArray(currentWeddingWebsite?.pages) && currentWeddingWebsite?.pages.length > 0
            ? currentWeddingWebsite?.pages[0].visibilityStatus
            : currentWeddingWebsite?.visibilityStatus;
        updatePage.visibilityStatus = existingPageVisibility;
        const existingPageDescription =
          Array.isArray(currentWeddingWebsite?.pages) && currentWeddingWebsite?.pages.length > 0
            ? currentWeddingWebsite?.pages[0].description
            : currentWeddingWebsite?.name || wedding.name;
        updatePage.description = existingPageDescription;
        updatePageList.push(updatePage);

        const res = await BrainHelper.updateWeddingWebsite(
          currentWeddingWebsite?.id,
          wedding.id,
          currentWeddingWebsite?.name || `${wedding.name}-website`,
          currentWeddingWebsite?.description || `${wedding.name}-website`,
          currentWeddingWebsite?.visibilityStatus,
          currentWeddingWebsite?.path || wedding.path,
          currentWeddingWebsite?.archived,
          userToken,
          updatePageList,
        );
        if (res) {
          // console.log('SAVED: wedding page updated successfully');

          toast('Wedding page updated successfully');

          dispatch(setCurrentWeddingWebsite(res));
          if (reload) getWeddingPage();
        }
        setIsSaving(false);
      } catch (e) {
        // console.log('SAVED: updated failed');
        setIsSaving(false);

        toast.error('Wedding page updated unsuccessfully, redirecting you to Overview', {
          style: { backgroundColor: '#D54F68', color: '#281B24' },
          icon: <Icon name={'close'} size={'s'} color={'#281B24'}></Icon>,
        });

        logger.error({ message: e, functionName: 'WebsiteDetails.updateWebsite' });
        overviewReroute();
      }
  };

  const updateComponentProps = (newProps: any, index: number) => {
    setComponentProps1((prevState) => ({
      ...prevState,
      [index]: newProps,
    }));
  };

  const componentPropsInit = (input?: Array<any>) => {
    if (!input) {
      setComponentProps1(new Array<any>());
      setTempComponentProps1(new Array<any>());
    } else {
      setComponentProps1(input);
      setTempComponentProps1(input);
    }
  };

  const getWeddingPage = async () => {
    const res = await BrainHelper.listWeddingWebsites(wedding.id, wedding.path, userToken);
    if (res.length > 0) {
      dispatch(setWeddingWebsites(res));
      dispatch(setCurrentWeddingWebsite(res[0]));
      setPublic(res[0]?.visibilityStatus === 1);
      console.log(selectedTemplateUse);
      SetSelectedTemplate(selectedTemplateUse);

      if (res[0].pages && res[0].pages[0] && res[0].pages[0].sectionDatas && res[0].pages[0].sectionDatas.length > 0) {
        const loadedSectionNames: Array<string> = [];
        const loadedComponentProps: Array<any> = [];
        res[0].pages[0].sectionDatas.map((section) => {
          loadedComponentProps.push(JSON.parse(section.dataJson));
          loadedSectionNames.push(section.frontendComponentName);
        });

        if (Array.isArray(loadedComponentProps) && loadedComponentProps.length > 0) {
          setComponentProps1(loadedComponentProps);
        } else {
          componentPropsInit(componentProps);
        }
        SetSectionComponentsList(loadedSectionNames);
      }
    } else {
      if (selectedTemplateFromStore) {
        if (selectedTemplateFromStore.id !== '') {
          console.log(selectedTemplateFromStore);
          componentPropsInit(componentProps);
          SetSelectedTemplate(selectedTemplateFromStore);
        } else {
          navigate(`/dashboard/w/${wedding.path}/website/choose-template`);
        }
      } else {
        navigate(`/dashboard/w/${wedding.path}/website/choose-template`);
      }
    }
  };

  useEffect(() => {
    const CANONICAL_DOMAIN = window.location.hostname;
    if (CANONICAL_DOMAIN.includes('whiteclover.uk')) {
      setPublicURLDomain('https://whiteclover.io');
    } else if (CANONICAL_DOMAIN.includes('whiteclover.gr')) {
      setPublicURLDomain('https://whiteclover.io');
    } else if (CANONICAL_DOMAIN.includes('localhost')) {
      setPublicURLDomain('http://localhost:3001');
    } else {
      setPublicURLDomain('https://whiteclover.io');
    }

    getWeddingPage();
  }, []);

  // pass ref here
  const createWebsite = async (isAutosave = false) => {
    try {
      if (currentWeddingWebsite?.id) {
        throw new Error('Could create wedding currentWeddingWebsite exists');
      }
      if (!selectedTemplateRef?.current) {
        throw new Error('Could create wedding selectedTemplate is null');
      }
      if (!componentProps1Ref?.current) {
        throw new Error('Could create wedding componetnProps1 is null');
      }
      const pageList = new Array<CreateWeddingWebsitePageRequest>();
      const page = new CreateWeddingWebsitePageRequest();
      const section_datas = new Array<CreateWeddingWebsitePageSectionRequest>();
      selectedTemplateRef?.current?.sectionComponents.map((componentName, index) => {
        const section = new CreateWeddingWebsitePageSectionRequest();
        if (!componentProps1Ref?.current[index]) {
          section.dataJson = JSON.stringify({});
        } else {
          section.dataJson = JSON.stringify(componentProps1Ref?.current[index], (_, v) =>
            typeof v === 'bigint' ? v.toString() : v,
          );
        }
        section.frontendComponentName = componentName;
        section.genericType = componentName;
        section.goStruct = componentName;
        section.tsClass = componentName;
        section.order = index as unknown as bigint;
        section_datas.push(section);
      });
      page.sectionDatas = section_datas;
      page.path = 'home';
      page.visibilityStatus = 1;
      page.description = `${wedding.name}-website`;
      pageList.push(page);

      const res = await BrainHelper.createWeddingWebsite(
        wedding.id,
        `${wedding.name}-website`,
        `${wedding.name}-website`,
        2,
        wedding.path,
        pageList,
        userToken,
      );
      if (res) {
        // console.log('SAVED:first wedding page created successfully');
        toast('Wedding page updated successfully');
        dispatch(setCurrentWeddingWebsite(res));
      }
      setIsSaving(false);
    } catch (e) {
      // console.log('SAVED: create website failed');
      setIsSaving(false);
      toast.error('Wedding page creation unsuccessfully, redirecting you to Overview', {
        style: { backgroundColor: '#D54F68', color: '#281B24' },
        icon: <Icon name={'close'} size={'s'} color={'#281B24'}></Icon>,
      });
      logger.error({ message: e, functionName: 'WebsiteDetails.createWebsite' });
      overviewReroute();
    }
  };

  const unpublishWebsite = async () => {
    try {
      if (currentWeddingWebsite) {
        const res = await BrainHelper.updateWeddingWebsite(
          currentWeddingWebsite?.id,
          wedding.id,
          currentWeddingWebsite?.name || `${wedding.name}-website`,
          currentWeddingWebsite?.description || `${wedding.name}-website`,
          2,
          currentWeddingWebsite?.path || wedding.path,
          currentWeddingWebsite?.archived,
          userToken,
          [],
        );
        if (res) {
          dispatch(setCurrentWeddingWebsite(res));
          // change public button
          setPublic(false);
        }
      }
    } catch (e) {
      toast.error('Wedding page unpublished unsuccessfully, redirecting you to Overview', {
        style: { backgroundColor: '#D54F68', color: '#281B24' },
        icon: <Icon name={'close'} size={'s'} color={'#281B24'}></Icon>,
      });
      logger.error({ message: e, functionName: 'WebsiteDetails.unpublishWebsite' });
      overviewReroute();
    }
  };

  const publishWebsite = async () => {
    try {
      if (currentWeddingWebsite) {
        const res = await BrainHelper.updateWeddingWebsite(
          currentWeddingWebsite?.id,
          wedding.id,
          currentWeddingWebsite?.name || `${wedding.name}-website`,
          currentWeddingWebsite?.description || `${wedding.name}-website`,
          1,
          currentWeddingWebsite?.path || wedding.path,
          currentWeddingWebsite?.archived,
          userToken,
          [],
        );
        if (res) {
          dispatch(setCurrentWeddingWebsite(res));
          // change public button
          setPublic(true);
        }
      }
    } catch (e) {
      toast.error('Wedding page published unsuccessfully, redirecting you to Overview', {
        style: { backgroundColor: '#D54F68', color: '#281B24' },
        icon: <Icon name={'close'} size={'s'} color={'#281B24'}></Icon>,
      });
      logger.error({ message: e, functionName: 'WebsiteDetails.publishWebsite' });
      overviewReroute();
    }
  };

  const save = async (isAutosaved = false) => {
    setIsSaving(true);
    setIsSecondButtonLoading(true);
    console.log({ TEST: 'TEST', currentWeddingWebsite });
    if (!currentWeddingWebsite) await createWebsite(isAutosaved);
    else await updateWebsite(componentProps1Ref?.current, isAutosaved);
    setIsSecondButtonLoading(false);
  };

  useAutosave(
    () => {
      utilSave(
        currentWeddingWebsite,
        componentProps1Ref,
        tempComponentPropsRef,
        setIsSaving,
        setTempComponentProps1,
        justSave,
        save,
      );
    },
    10,
    [currentWeddingWebsite, componentProps1Ref.current, tempComponentPropsRef.current],
  ); //set seconds for autoSave

  useEffect(() => {
    if (
      JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)) !==
      JSON.stringify(tempComponentProps1, (_, v) => (typeof v === 'bigint' ? v.toString() : v))
    )
      setIsSecondButtonLoading(true);
    else setIsSecondButtonLoading(false);
  }, [componentProps, tempComponentProps1]);

  const typeOfScreen = useTypeOfScreen();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showPreviewMobileWarning, setShowPreviewMobileWarning] = useState<boolean>(true);
  if (showPreviewMobileWarning && typeOfScreen !== ScreenType.desktop && typeOfScreen !== ScreenType.laptop) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
            padding: '16px',
            alignItems: 'center',
          }}
        >
          <p className="didacticH3" style={{ textAlign: 'center' }}>
            Website builder currently works only on bigger screen devices
          </p>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className={styles.WebsiteDetails}>
        {!selectedTemplateFromStore ? (
          <div>
            <Lottie options={lottieDefaultOptions} height={300} width={300} />
          </div>
        ) : (
          <Fragment>
            <div id="top">
              <h2 className="fridayH2">Website</h2>
              <svg width="376" height="1" viewBox="0 0 376 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.5" y1="0.5" x2="375.5" y2="0.5" stroke="#DAD8D9" strokeLinecap="round" />
              </svg>
              {/* <div style={{ paddingTop: '24px' }}>
              <SelectTemplate onlySelectedTemplate />
            </div> */}
              <div style={{ paddingTop: 24, paddingBottom: 60 }}>
                {selectedTemplate && componentProps1 && (
                  <WebsiteDetailsForm
                    sectionComponentsList={
                      sectionComponentsList && sectionComponentsList.length > 0
                        ? sectionComponentsList
                        : selectedTemplate
                        ? selectedTemplate.sectionComponents
                        : []
                    }
                    selectedTemplate={selectedTemplate}
                    componentProps={componentProps1}
                    updateComponentProps={updateComponentProps}
                    autoSave={autoSave}
                  />
                )}
              </div>
            </div>
            <div>
              <div>
                <p style={{ color: 'transparent', cursor: 'default' }}>{selectedTemplate?.sectionComponents.length}</p>
                <p style={{ color: 'transparent', cursor: 'default' }}>{sectionComponentsList.length}</p>
                {/* SAVE BUTTON */}
                <button
                  className={`btnMain didacticH4 ${styles.customButton} ${
                    JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)) ===
                    JSON.stringify(tempComponentProps1, (_, v) => (typeof v === 'bigint' ? v.toString() : v))
                      ? 'disabled'
                      : null
                  }`}
                  onClick={() => {
                    utilSave(
                      currentWeddingWebsite,
                      componentProps1Ref,
                      tempComponentPropsRef,
                      setIsSaving,
                      setTempComponentProps1,
                      justSave,
                      save,
                      false,
                    );
                  }}
                  disabled={
                    JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)) ===
                    JSON.stringify(tempComponentProps1, (_, v) => (typeof v === 'bigint' ? v.toString() : v))
                  }
                >
                  {JSON.stringify(componentProps, (_, v) => (typeof v === 'bigint' ? v.toString() : v)) !==
                  JSON.stringify(tempComponentProps1, (_, v) => (typeof v === 'bigint' ? v.toString() : v)) ? (
                    <>
                      <img src="/assets/icons/icon-disk.svg" width={16} alt={'select theme'} />
                      <span>{'Save'}</span>
                    </>
                  ) : !isSecondButtonLoading ? (
                    <>
                      <img src="/assets/icons/icon-disk.svg" width={16} alt={'select theme'} />
                      <span>Saved</span>
                    </>
                  ) : (
                    <Lottie options={lottieDefaultOptions} height={50} width={200} />
                  )}
                </button>
                {/* VIEW WEBSITE if published */}
                {published && publicURLDomain && (
                  <button
                    className={`btnSecondary didacticH4 ${styles.customButton} ${styles.viewWebsite} ${
                      !(published && publicURLDomain) && styles.disabled
                    }`}
                    onClick={() => {
                      openInNewTab(`${publicURLDomain}/m/${wedding.path}`);
                    }}
                    disabled={!(published && publicURLDomain)}
                  >
                    <Icon name="eye" size="s" />
                    {'View Website'}
                  </button>
                )}

                {/* PUBLISH WEBSITE / UNPUBLISH */}
                <button
                  className={`btnSecondary didacticH4 ${styles.customButton}  ${
                    published && publicURLDomain ? styles.unpublish : styles.published
                  }`}
                  onClick={() => {
                    published && publicURLDomain ? unpublishWebsite() : publishWebsite();
                  }}
                >
                  <img src="/assets/icons/icon-browser.svg" width={16} alt={'view site'} />
                  {published && publicURLDomain ? 'Make It Private' : 'Make It Public'}
                </button>
              </div>
              {componentProps1 && (
                <TemplatePreview
                  sectionComponentsList={
                    sectionComponentsList && sectionComponentsList.length > 0
                      ? sectionComponentsList
                      : selectedTemplate
                      ? selectedTemplate.sectionComponents
                      : []
                  }
                  mode="compact"
                  componentProps={componentProps1}
                />
              )}
            </div>
          </Fragment>
        )}
      </div>
      <ToastContainer
        closeButton={false}
        style={{ width: '80%', borderRadius: 8, position: 'absolute' }}
        icon={<img src={'/assets/icons/icon-accept.svg'} alt={'accept'} style={{ height: 16, width: 16 }} />}
        position="bottom-center"
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Fragment>
  );
};

export default WebsiteDetails;
