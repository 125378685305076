import styles from './CheckboxFilled.module.scss';
import { light } from '@mui/material/styles/createPalette';

const Checkbox = ({
  value,
  onClick,
  label,
  light,
  disabled,
}: {
  value: boolean;
  onClick?: any;
  label?: string;
  size?: 's' | 'm';
  light?: boolean;
  disabled?: boolean;
}): JSX.Element => {
  if (label) {
    return (
      <div style={{ display: 'flex', gap: '16px' }}>
        <label className={styles.container}>
          <input type={'checkbox'} checked={value} onClick={onClick ?? undefined} disabled={disabled} />
          <span className={styles.checkmark + (light ? ' ' + styles.checkmarkLight : '')}></span>
        </label>
        <button
          style={{
            background: 'none',
            border: 'none',
            fontSize: '16px',
            textDecoration: !value ? 'none' : 'line-through',
          }}
          onClick={onClick ?? undefined}
          disabled={disabled}
        >
          {label}
        </button>
      </div>
    );
  }

  return (
    <label className={styles.container}>
      <input type={'checkbox'} checked={value} onChange={onClick ?? undefined} disabled={disabled} />
      <span className={styles.checkmark + (light ? ' ' + styles.checkmarkLight : '')}></span>
    </label>
  );
};

export default Checkbox;
