import './index.scss';

import React from 'react';
import { Provider } from 'react-redux';

import { App } from './App';
import { store } from './app/store';
import ErrorBoundaryFallback from './component/errors/ErrorBoundaries';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { logger } from './config/Logger';
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import cleanLocalStorage from './app/helper/CleanLocalStorage';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://6520ba1a0b8249bcaa01b33bd1f2df61@o4503988319879168.ingest.sentry.io/4503988322107392',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

root.render(
  // <React.StrictMode>
  <React.Fragment>
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onReset={() => {
        console.log('onReset');
      }}
      onError={(error) => {
        console.log('onError');
        logger.error({ message: error, functionName: 'ErrorBoundary.componentDidCatch' });
        if (error.message.includes('token')) {
          console.log('CLEAR');
          logger.info({ message: 'clear local storage', functionName: 'ErrorBoundary.componentDidCatch' });
          cleanLocalStorage();
          window.location.pathname = window.location.pathname.includes('/dashboard/') ? '/dashboard' : '/';
        } else {
          window.location.pathname = window.location.pathname.includes('/dashboard/') ? '/dashboard' : '/';
        }
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.Fragment>,
  // </React.StrictMode>,
);
