import firebase from 'firebase';

export class FirebaseContext {
  private static firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  private static firebaseApp: firebase.app.App;

  public static getApp(): firebase.app.App {
    if (!this.firebaseApp) this.firebaseApp = firebase.initializeApp(this.firebaseConfig);
    return this.firebaseApp;
  }

  public static getAuth(): firebase.auth.Auth {
    if (!this.firebaseApp) this.getApp();

    return this.firebaseApp.auth();
  }
}
